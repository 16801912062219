import { lazy, memo, Suspense, useMemo } from 'react';

import { css } from '@emotion/react';
import { NoSsr } from '@mui/material';
import { Box, BoxProps } from '@mui/system';

import { Others } from './others';
import { Solutions } from './solutions';
import { Sports } from './sports';

export const Icons = { Solutions, Sports, Others };

type ValueOf<T> = T[keyof T];

type IconLoaderProps = BoxProps & {
  icon:
    | ValueOf<typeof Sports>
    | ValueOf<typeof Solutions>
    | ValueOf<typeof Others>;
  title?: string;
};

export const IconLoader = memo(({ icon, title, ...rest }: IconLoaderProps) => {
  const IconComponent = useMemo(() => {
    return lazy(async () => {
      try {
        return await import(`./${icon}`);
      } catch (err) {
        return Promise.resolve({ default: () => <div /> });
      }
    });
  }, [icon]);

  return (
    <Box
      css={css`
        user-select: none;
        flex-shrink: 0;
        stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill-rule: evenodd;

        svg {
          width: 100%;
          height: 100%;
        }
      `}
      aria-hidden={title ? undefined : true}
      role={title ? 'img' : undefined}
      {...rest}
    >
      <NoSsr>
        <Suspense>
          <IconComponent />
        </Suspense>
      </NoSsr>
    </Box>
  );
});
