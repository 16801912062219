export const Solutions = {
  Advertisers: 'solutions/advertisers' as const,
  Cities: 'solutions/cities' as const,
  Clubhouse: 'solutions/clubhouse' as const,
  Clubs: 'solutions/clubs' as const,
  Fans: 'solutions/fans' as const,
  Federations: 'solutions/federations' as const,
  Medias: 'solutions/medias' as const,
  MonSiteClub: 'solutions/mon-site-club' as const,
  Tournoi: 'solutions/tournoi' as const,
  Widgets: 'solutions/widgets' as const,
  Convocations: 'solutions/convocations' as const,
};
