import { css } from '@emotion/react';
import { Box, IconButton } from '@mui/material';
import { Image } from '@scorenco/components';
import {
  Locale,
  RouteLink,
  useRouteInfo,
  useTranslation,
} from '@scorenco/core';

export const AuthMenuCh = () => {
  const routeInfo = useRouteInfo();
  const { locale } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <RouteLink href={routeInfo.href} locale={Locale.fr_FR}>
        <IconButton
          component="a"
          disabled={locale === Locale.fr_FR}
          sx={{
            opacity: 0.5,
            '&.Mui-disabled': {
              opacity: 1,
            },
          }}
        >
          <Image
            css={css`
              width: 2rem;
            `}
            src={`/images/icons/flag-${Locale.fr_FR}.svg`}
            alt="Voir le site en version Française."
          />
        </IconButton>
      </RouteLink>
      <RouteLink href={routeInfo.href} locale={Locale.de_CH}>
        <IconButton
          component="a"
          disabled={locale === Locale.de_CH}
          sx={{
            opacity: 0.5,
            '&.Mui-disabled': {
              opacity: 1,
            },
          }}
        >
          <Image
            css={css`
              width: 2rem;
            `}
            src={`/images/icons/flag-${Locale.de_CH}.svg`}
            alt="Siehe die Seite in deutscher Version."
          />
        </IconButton>
      </RouteLink>
    </Box>
  );
};
