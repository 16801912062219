import { useTheme } from '@emotion/react';
import { Types, getStrapiMenu } from '@scorenco/core';
import { StrapiMenu } from '@scorenco/core/src/data/strapi/useStrapiMenuGet';
import {
  DefaultLayout,
  DefaultLayoutOptions,
  GetLayout,
  GetServerSidePropsWithPrevious,
  NavigationBurgerDrawer,
} from '@scorenco/next';
import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';

import { Footer } from '../../components';
import { getConfiguration } from '../../utils';

import { Configuration } from './Configuration';
import { MobileHeaderMenu } from './MobileHeaderMenu';
import { WebLayoutAppBarActions } from './WebLayoutAppBarActions';

const ProgressBar = () => {
  const theme = useTheme();

  return <NextNProgress color={theme.vars.palette.secondary.main} />;
};

const getLayout = (): GetLayout => (page) => (
  <>
    {process.env.NODE_ENV === 'production' && (
      <Head>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "opo7czyflw");
        `,
          }}
        />
      </Head>
    )}
    <Configuration />
    <ProgressBar />
    <NavigationBurgerDrawer noPermanent>
      <MobileHeaderMenu />
    </NavigationBurgerDrawer>
    {page}
    <Footer />
  </>
);

export type WebLayoutPageProps = {
  menu: StrapiMenu;
  footer: StrapiMenu;
  configuration: {
    mainMessage?: string;
  };
};

const serverSideProps: GetServerSidePropsWithPrevious = async () => {
  const menu = await getStrapiMenu('main');
  const footer = await getStrapiMenu('footer');
  const configuration = await getConfiguration(Types.PublicationState.Live);

  return {
    props: {
      menu,
      footer,
      configuration,
    },
  };
};

export const WebLayout = {
  ...DefaultLayout,

  buildGetServerSideProps: (
    options?: DefaultLayoutOptions,
    list?: GetServerSidePropsWithPrevious[]
  ) =>
    DefaultLayout.buildGetServerSideProps(options, [
      serverSideProps,
      ...(list || []),
    ]),

  buildGetLayout: (
    options?: DefaultLayoutOptions & { strapiMenu?: boolean },
    getLayouts?: GetLayout[]
  ) => {
    return DefaultLayout.buildGetLayout(
      {
        ...(options || {}),
        navigation: {
          AppBarActions: () => (
            <WebLayoutAppBarActions strapiMenu={!!options?.strapiMenu} />
          ),
          ...(options?.navigation || {}),
        },
        amplify: {
          protectedPage: false,
          ...(options?.amplify || {}),
        },
      },
      [getLayout(), ...(getLayouts || [])]
    );
  },
};
