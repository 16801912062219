import { normalize } from './useStrapiMenuGet';

export const getStrapiMenu = async (slug: String) => {
  try {
    const response = await fetch(
      process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT_CACHED +
        '/strapimenuget/' +
        slug,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data = await response.json();
    return normalize(data);
  } catch (error) {
    console.error(error);
    return null;
  }
};
