export const getConfiguration = async (publicationState: String) => {
  try {
    const response = await fetch(
      process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT_CACHED +
        '/getconfiguration/' +
        publicationState,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data = await response.json();
    return data?.configuration?.data?.attributes;
  } catch (error) {
    console.error(error);
    return null;
  }
};
