/* eslint-disable */

/*
 * ⚠️ DO NOT EDIT ⚠️
 * This file is automatically generated, run "yarn run codegen"
 */

export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** A string used to identify an i18n locale */
  I18NLocaleCode: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  PagesChHomeBodyDynamicZoneInput: { input: any; output: any };
  PagesHomeBodyDynamicZoneInput: { input: any; output: any };
  PagesLandingBodyDynamicZoneInput: { input: any; output: any };
  PagesLiveBodyDynamicZoneInput: { input: any; output: any };
  PagesSportBodyDynamicZoneInput: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
  bigint: { input: any; output: any };
  date: { input: any; output: any };
  float8: { input: any; output: any };
  json: { input: any; output: any };
  jsonb: { input: any; output: any };
  smallint: { input: any; output: any };
  timestamptz: { input: any; output: any };
  timetz: { input: any; output: any };
  uuid: { input: any; output: any };
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type ComponentFeatureListFeature = {
  __typename: 'ComponentFeatureListFeature';
  content?: Maybe<Scalars['String']['output']>;
  ctaLink?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ComponentFeatureListFeatureFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentFeatureListFeatureFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  ctaLink?: InputMaybe<StringFilterInput>;
  ctaText?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentFeatureListFeatureFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentFeatureListFeatureFiltersInput>>>;
};

export type ComponentKeysFiguresKeyFigure = {
  __typename: 'ComponentKeysFiguresKeyFigure';
  figure?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentKeysFiguresKeyFigureFiltersInput = {
  and?: InputMaybe<
    Array<InputMaybe<ComponentKeysFiguresKeyFigureFiltersInput>>
  >;
  figure?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ComponentKeysFiguresKeyFigureFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentKeysFiguresKeyFigureFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
};

export type ComponentLinkListLink = {
  __typename: 'ComponentLinkListLink';
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentLinkListLinkFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentLinkListLinkFiltersInput>>>;
  link?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentLinkListLinkFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentLinkListLinkFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentPricingProduct = {
  __typename: 'ComponentPricingProduct';
  cta?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
  is_favorite?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Enum_Componentpricingproduct_Type>;
};

export type ComponentPricingProductFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentPricingProductFiltersInput>>>;
  cta?: InputMaybe<StringFilterInput>;
  features?: InputMaybe<StringFilterInput>;
  is_favorite?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentPricingProductFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentPricingProductFiltersInput>>>;
  type?: InputMaybe<StringFilterInput>;
};

export type ComponentReviewsReview = {
  __typename: 'ComponentReviewsReview';
  avis?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nom?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['Float']['output']>;
};

export type ComponentReviewsReviewFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentReviewsReviewFiltersInput>>>;
  avis?: InputMaybe<StringFilterInput>;
  nom?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentReviewsReviewFiltersInput>;
  note?: InputMaybe<FloatFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentReviewsReviewFiltersInput>>>;
};

export type ComponentSharedButton = {
  __typename: 'ComponentSharedButton';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Enum_Componentsharedbutton_Type>;
};

export type ComponentSharedButtonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedButtonFiltersInput>>>;
  label?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedButtonFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedButtonFiltersInput>>>;
  type?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocial = {
  __typename: 'ComponentSharedMetaSocial';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  socialNetwork?: Maybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedMetaSocialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialFiltersInput>>>;
  socialNetwork?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentSharedMetaSocialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  socialNetwork?: InputMaybe<Enum_Componentsharedmetasocial_Socialnetwork>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentSharedSeo = {
  __typename: 'ComponentSharedSeo';
  canonicalURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaImage?: Maybe<UploadFileEntityResponse>;
  metaRobots?: Maybe<Scalars['String']['output']>;
  metaSocial?: Maybe<Array<Maybe<ComponentSharedMetaSocial>>>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  structuredData?: Maybe<Scalars['JSON']['output']>;
};

export type ComponentSharedSeoMetaSocialArgs = {
  filters?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedSeoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  canonicalURL?: InputMaybe<StringFilterInput>;
  keywords?: InputMaybe<StringFilterInput>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaRobots?: InputMaybe<StringFilterInput>;
  metaSocial?: InputMaybe<ComponentSharedMetaSocialFiltersInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentSharedSeoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedSeoFiltersInput>>>;
  structuredData?: InputMaybe<JsonFilterInput>;
};

export type ComponentSharedSeoInput = {
  canonicalURL?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  keywords?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaImage?: InputMaybe<Scalars['ID']['input']>;
  metaRobots?: InputMaybe<Scalars['String']['input']>;
  metaSocial?: InputMaybe<Array<InputMaybe<ComponentSharedMetaSocialInput>>>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  structuredData?: InputMaybe<Scalars['JSON']['input']>;
};

export type ComponentSlicesAdvertising = {
  __typename: 'ComponentSlicesAdvertising';
  advertising_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentSlicesButton = {
  __typename: 'ComponentSlicesButton';
  buttons?: Maybe<Array<Maybe<ComponentSharedButton>>>;
  id: Scalars['ID']['output'];
};

export type ComponentSlicesButtonButtonsArgs = {
  filters?: InputMaybe<ComponentSharedButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSlicesFaq = {
  __typename: 'ComponentSlicesFaq';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesFeaturesList = {
  __typename: 'ComponentSlicesFeaturesList';
  features?: Maybe<Array<Maybe<ComponentFeatureListFeature>>>;
  id: Scalars['ID']['output'];
};

export type ComponentSlicesFeaturesListFeaturesArgs = {
  filters?: InputMaybe<ComponentFeatureListFeatureFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSlicesHero = {
  __typename: 'ComponentSlicesHero';
  chapeau?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<UploadFileRelationResponseCollection>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesHeroImagesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSlicesHtml = {
  __typename: 'ComponentSlicesHtml';
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentSlicesImage = {
  __typename: 'ComponentSlicesImage';
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
};

export type ComponentSlicesImageWithText = {
  __typename: 'ComponentSlicesImageWithText';
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  imagePosition?: Maybe<Enum_Componentslicesimagewithtext_Imageposition>;
  imageSize?: Maybe<Enum_Componentslicesimagewithtext_Imagesize>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesKeysFigures = {
  __typename: 'ComponentSlicesKeysFigures';
  figures?: Maybe<Array<Maybe<ComponentKeysFiguresKeyFigure>>>;
  id: Scalars['ID']['output'];
};

export type ComponentSlicesKeysFiguresFiguresArgs = {
  filters?: InputMaybe<ComponentKeysFiguresKeyFigureFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSlicesLinkList = {
  __typename: 'ComponentSlicesLinkList';
  color?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  liens?: Maybe<Array<Maybe<ComponentLinkListLink>>>;
};

export type ComponentSlicesLinkListLiensArgs = {
  filters?: InputMaybe<ComponentLinkListLinkFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSlicesLivesSlider = {
  __typename: 'ComponentSlicesLivesSlider';
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
  sportSlug?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesNewsletter = {
  __typename: 'ComponentSlicesNewsletter';
  buttonText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  signedText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesPricing = {
  __typename: 'ComponentSlicesPricing';
  id: Scalars['ID']['output'];
  products?: Maybe<Array<Maybe<ComponentPricingProduct>>>;
};

export type ComponentSlicesPricingProductsArgs = {
  filters?: InputMaybe<ComponentPricingProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSlicesReviews = {
  __typename: 'ComponentSlicesReviews';
  id: Scalars['ID']['output'];
  reviews?: Maybe<Array<Maybe<ComponentReviewsReview>>>;
};

export type ComponentSlicesReviewsReviewsArgs = {
  filters?: InputMaybe<ComponentReviewsReviewFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSlicesSection = {
  __typename: 'ComponentSlicesSection';
  id: Scalars['ID']['output'];
  theme?: Maybe<Enum_Componentslicessection_Theme>;
};

export type ComponentSlicesSportList = {
  __typename: 'ComponentSlicesSportList';
  id: Scalars['ID']['output'];
  sports?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesStoreGrade = {
  __typename: 'ComponentSlicesStoreGrade';
  androidDescription?: Maybe<Scalars['String']['output']>;
  androidLink?: Maybe<Scalars['String']['output']>;
  androidNote?: Maybe<Scalars['String']['output']>;
  ctaLabel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  iosDescription?: Maybe<Scalars['String']['output']>;
  iosLink?: Maybe<Scalars['String']['output']>;
  iosNote?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesTabEnd = {
  __typename: 'ComponentSlicesTabEnd';
  id: Scalars['ID']['output'];
};

export type ComponentSlicesTabStart = {
  __typename: 'ComponentSlicesTabStart';
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesTeam = {
  __typename: 'ComponentSlicesTeam';
  id: Scalars['ID']['output'];
  members?: Maybe<Array<Maybe<ComponentTeamMember>>>;
};

export type ComponentSlicesTeamMembersArgs = {
  filters?: InputMaybe<ComponentTeamMemberFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSlicesTestimonial = {
  __typename: 'ComponentSlicesTestimonial';
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  name?: Maybe<Scalars['String']['output']>;
  review?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type ComponentSlicesText = {
  __typename: 'ComponentSlicesText';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mode?: Maybe<Enum_Componentslicestext_Mode>;
};

export type ComponentSlicesVerticalTab = {
  __typename: 'ComponentSlicesVerticalTab';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentTeamMember = {
  __typename: 'ComponentTeamMember';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<UploadFileEntityResponse>;
  role?: Maybe<Scalars['String']['output']>;
};

export type ComponentTeamMemberFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentTeamMemberFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentTeamMemberFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentTeamMemberFiltersInput>>>;
  role?: InputMaybe<StringFilterInput>;
};

export type Configuration = {
  __typename: 'Configuration';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ConfigurationRelationResponseCollection>;
  mainMessage?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ConfigurationLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type ConfigurationEntity = {
  __typename: 'ConfigurationEntity';
  attributes?: Maybe<Configuration>;
  id: Scalars['ID']['output'];
};

export type ConfigurationEntityResponse = {
  __typename: 'ConfigurationEntityResponse';
  data?: Maybe<ConfigurationEntity>;
};

export type ConfigurationInput = {
  mainMessage?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConfigurationRelationResponseCollection = {
  __typename: 'ConfigurationRelationResponseCollection';
  data?: Maybe<Array<ConfigurationEntity>>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum Enum_Componentpricingproduct_Type {
  Cityscore = 'cityscore',
  Clubhouse = 'clubhouse',
  Convocation = 'convocation',
  Grandchelem = 'grandchelem',
  Monsiteclub = 'monsiteclub',
  Simple = 'simple',
  Tournoi = 'tournoi',
  WidgetsPremium = 'widgets_premium',
}

export enum Enum_Componentsharedbutton_Type {
  Cta = 'cta',
  Lien = 'lien',
  Principal = 'principal',
  Secondaire = 'secondaire',
}

export enum Enum_Componentsharedmetasocial_Socialnetwork {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
}

export enum Enum_Componentslicesimagewithtext_Imageposition {
  Droite = 'droite',
  Gauche = 'gauche',
}

export enum Enum_Componentslicesimagewithtext_Imagesize {
  Grande = 'grande',
  Petite = 'petite',
}

export enum Enum_Componentslicessection_Theme {
  FondBlanc = 'fond_blanc',
  FondBleuClair = 'fond_bleu_clair',
  FondBleuFonce = 'fond_bleu_fonce',
  FondVert = 'fond_vert',
}

export enum Enum_Componentslicestext_Mode {
  Article = 'article',
  Landing = 'landing',
}

export enum Enum_Menusmenuitem_Target {
  Blank = 'blank',
  Parent = 'parent',
  Self = 'self',
  Top = 'top',
}

export type Error = {
  __typename: 'Error';
  code?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type GenericMorph =
  | ComponentFeatureListFeature
  | ComponentKeysFiguresKeyFigure
  | ComponentLinkListLink
  | ComponentPricingProduct
  | ComponentReviewsReview
  | ComponentSharedButton
  | ComponentSharedMetaSocial
  | ComponentSharedSeo
  | ComponentSlicesAdvertising
  | ComponentSlicesButton
  | ComponentSlicesFaq
  | ComponentSlicesFeaturesList
  | ComponentSlicesHero
  | ComponentSlicesHtml
  | ComponentSlicesImage
  | ComponentSlicesImageWithText
  | ComponentSlicesKeysFigures
  | ComponentSlicesLinkList
  | ComponentSlicesLivesSlider
  | ComponentSlicesNewsletter
  | ComponentSlicesPricing
  | ComponentSlicesReviews
  | ComponentSlicesSection
  | ComponentSlicesSportList
  | ComponentSlicesStoreGrade
  | ComponentSlicesTabEnd
  | ComponentSlicesTabStart
  | ComponentSlicesTeam
  | ComponentSlicesTestimonial
  | ComponentSlicesText
  | ComponentSlicesVerticalTab
  | ComponentTeamMember
  | Configuration
  | I18NLocale
  | MenusMenu
  | MenusMenuItem
  | PagesChHome
  | PagesClub
  | PagesHome
  | PagesLanding
  | PagesLive
  | PagesSport
  | UploadFile
  | UploadFolder;

export type I18NLocale = {
  __typename: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id: Scalars['ID']['output'];
};

export type I18NLocaleEntityResponse = {
  __typename: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename: 'I18NLocaleEntityResponseCollection';
  data?: Maybe<Array<I18NLocaleEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Int']['input']>>;
  _eq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _gte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['Int']['input']>>;
  _lte?: InputMaybe<Array<Scalars['Int']['input']>>;
  _neq?: InputMaybe<Array<Scalars['Int']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Int']['input']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type MenusMenu = {
  __typename: 'MenusMenu';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  items?: Maybe<MenusMenuItemRelationResponseCollection>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MenusMenuItemsArgs = {
  filters?: InputMaybe<MenusMenuItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type MenusMenuEntity = {
  __typename: 'MenusMenuEntity';
  attributes?: Maybe<MenusMenu>;
  id: Scalars['ID']['output'];
};

export type MenusMenuEntityResponse = {
  __typename: 'MenusMenuEntityResponse';
  data?: Maybe<MenusMenuEntity>;
};

export type MenusMenuEntityResponseCollection = {
  __typename: 'MenusMenuEntityResponseCollection';
  data?: Maybe<Array<MenusMenuEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type MenusMenuFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<MenusMenuFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  items?: InputMaybe<MenusMenuItemFiltersInput>;
  not?: InputMaybe<MenusMenuFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MenusMenuFiltersInput>>>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type MenusMenuInput = {
  items?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type MenusMenuItem = {
  __typename: 'MenusMenuItem';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  main_icon?: Maybe<Scalars['String']['output']>;
  main_text?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<MenusMenuItemEntityResponse>;
  root_menu?: Maybe<MenusMenuEntityResponse>;
  target?: Maybe<Enum_Menusmenuitem_Target>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MenusMenuItemEntity = {
  __typename: 'MenusMenuItemEntity';
  attributes?: Maybe<MenusMenuItem>;
  id: Scalars['ID']['output'];
};

export type MenusMenuItemEntityResponse = {
  __typename: 'MenusMenuItemEntityResponse';
  data?: Maybe<MenusMenuItemEntity>;
};

export type MenusMenuItemEntityResponseCollection = {
  __typename: 'MenusMenuItemEntityResponseCollection';
  data?: Maybe<Array<MenusMenuItemEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type MenusMenuItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<MenusMenuItemFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  main_icon?: InputMaybe<StringFilterInput>;
  main_text?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<MenusMenuItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MenusMenuItemFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
  parent?: InputMaybe<MenusMenuItemFiltersInput>;
  root_menu?: InputMaybe<MenusMenuFiltersInput>;
  target?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type MenusMenuItemInput = {
  main_icon?: InputMaybe<Scalars['String']['input']>;
  main_text?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  root_menu?: InputMaybe<Scalars['ID']['input']>;
  target?: InputMaybe<Enum_Menusmenuitem_Target>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type MenusMenuItemRelationResponseCollection = {
  __typename: 'MenusMenuItemRelationResponseCollection';
  data?: Maybe<Array<MenusMenuItemEntity>>;
};

export type PagesChHome = {
  __typename: 'PagesChHome';
  body?: Maybe<Array<Maybe<PagesChHomeBodyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ctaLabel?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<PagesChHomeRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PagesChHomeLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type PagesChHomeBodyDynamicZone =
  | ComponentSlicesAdvertising
  | ComponentSlicesFaq
  | ComponentSlicesFeaturesList
  | ComponentSlicesImage
  | ComponentSlicesImageWithText
  | ComponentSlicesLinkList
  | ComponentSlicesLivesSlider
  | ComponentSlicesNewsletter
  | ComponentSlicesSection
  | ComponentSlicesSportList
  | ComponentSlicesStoreGrade
  | ComponentSlicesTabEnd
  | ComponentSlicesTabStart
  | ComponentSlicesTestimonial
  | ComponentSlicesText
  | ComponentSlicesVerticalTab
  | Error;

export type PagesChHomeEntity = {
  __typename: 'PagesChHomeEntity';
  attributes?: Maybe<PagesChHome>;
  id: Scalars['ID']['output'];
};

export type PagesChHomeEntityResponse = {
  __typename: 'PagesChHomeEntityResponse';
  data?: Maybe<PagesChHomeEntity>;
};

export type PagesChHomeInput = {
  body?: InputMaybe<Array<Scalars['PagesChHomeBodyDynamicZoneInput']['input']>>;
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PagesChHomeRelationResponseCollection = {
  __typename: 'PagesChHomeRelationResponseCollection';
  data?: Maybe<Array<PagesChHomeEntity>>;
};

export type PagesClub = {
  __typename: 'PagesClub';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PagesClubEntity = {
  __typename: 'PagesClubEntity';
  attributes?: Maybe<PagesClub>;
  id: Scalars['ID']['output'];
};

export type PagesClubEntityResponse = {
  __typename: 'PagesClubEntityResponse';
  data?: Maybe<PagesClubEntity>;
};

export type PagesClubEntityResponseCollection = {
  __typename: 'PagesClubEntityResponseCollection';
  data?: Maybe<Array<PagesClubEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type PagesClubFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PagesClubFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<PagesClubFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PagesClubFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sport?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PagesClubInput = {
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sport?: InputMaybe<Scalars['String']['input']>;
};

export type PagesHome = {
  __typename: 'PagesHome';
  body?: Maybe<Array<Maybe<PagesHomeBodyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ctaLabel?: Maybe<Scalars['String']['output']>;
  images?: Maybe<UploadFileRelationResponseCollection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<PagesHomeRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PagesHomeImagesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PagesHomeLocalizationsArgs = {
  publicationState?: InputMaybe<PublicationState>;
};

export type PagesHomeBodyDynamicZone =
  | ComponentSlicesAdvertising
  | ComponentSlicesFaq
  | ComponentSlicesFeaturesList
  | ComponentSlicesImage
  | ComponentSlicesImageWithText
  | ComponentSlicesLinkList
  | ComponentSlicesLivesSlider
  | ComponentSlicesNewsletter
  | ComponentSlicesReviews
  | ComponentSlicesSection
  | ComponentSlicesSportList
  | ComponentSlicesStoreGrade
  | ComponentSlicesTabEnd
  | ComponentSlicesTabStart
  | ComponentSlicesTestimonial
  | ComponentSlicesText
  | ComponentSlicesVerticalTab
  | Error;

export type PagesHomeEntity = {
  __typename: 'PagesHomeEntity';
  attributes?: Maybe<PagesHome>;
  id: Scalars['ID']['output'];
};

export type PagesHomeEntityResponse = {
  __typename: 'PagesHomeEntityResponse';
  data?: Maybe<PagesHomeEntity>;
};

export type PagesHomeInput = {
  body?: InputMaybe<Array<Scalars['PagesHomeBodyDynamicZoneInput']['input']>>;
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PagesHomeRelationResponseCollection = {
  __typename: 'PagesHomeRelationResponseCollection';
  data?: Maybe<Array<PagesHomeEntity>>;
};

export type PagesLanding = {
  __typename: 'PagesLanding';
  body?: Maybe<Array<Maybe<PagesLandingBodyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<PagesLandingRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PagesLandingLocalizationsArgs = {
  filters?: InputMaybe<PagesLandingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PagesLandingBodyDynamicZone =
  | ComponentSlicesAdvertising
  | ComponentSlicesFaq
  | ComponentSlicesFeaturesList
  | ComponentSlicesHero
  | ComponentSlicesHtml
  | ComponentSlicesImage
  | ComponentSlicesImageWithText
  | ComponentSlicesKeysFigures
  | ComponentSlicesLinkList
  | ComponentSlicesLivesSlider
  | ComponentSlicesNewsletter
  | ComponentSlicesPricing
  | ComponentSlicesReviews
  | ComponentSlicesSection
  | ComponentSlicesSportList
  | ComponentSlicesStoreGrade
  | ComponentSlicesTabEnd
  | ComponentSlicesTabStart
  | ComponentSlicesTeam
  | ComponentSlicesTestimonial
  | ComponentSlicesText
  | ComponentSlicesVerticalTab
  | Error;

export type PagesLandingEntity = {
  __typename: 'PagesLandingEntity';
  attributes?: Maybe<PagesLanding>;
  id: Scalars['ID']['output'];
};

export type PagesLandingEntityResponse = {
  __typename: 'PagesLandingEntityResponse';
  data?: Maybe<PagesLandingEntity>;
};

export type PagesLandingEntityResponseCollection = {
  __typename: 'PagesLandingEntityResponseCollection';
  data?: Maybe<Array<PagesLandingEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type PagesLandingFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PagesLandingFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PagesLandingFiltersInput>;
  not?: InputMaybe<PagesLandingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PagesLandingFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PagesLandingInput = {
  body?: InputMaybe<
    Array<Scalars['PagesLandingBodyDynamicZoneInput']['input']>
  >;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type PagesLandingRelationResponseCollection = {
  __typename: 'PagesLandingRelationResponseCollection';
  data?: Maybe<Array<PagesLandingEntity>>;
};

export type PagesLive = {
  __typename: 'PagesLive';
  body?: Maybe<Array<Maybe<PagesLiveBodyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<PagesLiveRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  sport?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PagesLiveLocalizationsArgs = {
  filters?: InputMaybe<PagesLiveFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PagesLiveBodyDynamicZone =
  | ComponentSlicesAdvertising
  | ComponentSlicesFaq
  | ComponentSlicesFeaturesList
  | ComponentSlicesHero
  | ComponentSlicesImage
  | ComponentSlicesImageWithText
  | ComponentSlicesLinkList
  | ComponentSlicesLivesSlider
  | ComponentSlicesNewsletter
  | ComponentSlicesSection
  | ComponentSlicesStoreGrade
  | ComponentSlicesTabEnd
  | ComponentSlicesTabStart
  | ComponentSlicesTeam
  | ComponentSlicesTestimonial
  | ComponentSlicesText
  | ComponentSlicesVerticalTab
  | Error;

export type PagesLiveEntity = {
  __typename: 'PagesLiveEntity';
  attributes?: Maybe<PagesLive>;
  id: Scalars['ID']['output'];
};

export type PagesLiveEntityResponse = {
  __typename: 'PagesLiveEntityResponse';
  data?: Maybe<PagesLiveEntity>;
};

export type PagesLiveEntityResponseCollection = {
  __typename: 'PagesLiveEntityResponseCollection';
  data?: Maybe<Array<PagesLiveEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type PagesLiveFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PagesLiveFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PagesLiveFiltersInput>;
  not?: InputMaybe<PagesLiveFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PagesLiveFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  sport?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PagesLiveInput = {
  body?: InputMaybe<Array<Scalars['PagesLiveBodyDynamicZoneInput']['input']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  sport?: InputMaybe<Scalars['String']['input']>;
};

export type PagesLiveRelationResponseCollection = {
  __typename: 'PagesLiveRelationResponseCollection';
  data?: Maybe<Array<PagesLiveEntity>>;
};

export type PagesSport = {
  __typename: 'PagesSport';
  body?: Maybe<Array<Maybe<PagesSportBodyDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  images?: Maybe<UploadFileRelationResponseCollection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<PagesSportRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  seo?: Maybe<ComponentSharedSeo>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PagesSportImagesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PagesSportLocalizationsArgs = {
  filters?: InputMaybe<PagesSportFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PagesSportBodyDynamicZone =
  | ComponentSlicesAdvertising
  | ComponentSlicesButton
  | ComponentSlicesFaq
  | ComponentSlicesFeaturesList
  | ComponentSlicesHtml
  | ComponentSlicesImage
  | ComponentSlicesImageWithText
  | ComponentSlicesKeysFigures
  | ComponentSlicesLinkList
  | ComponentSlicesLivesSlider
  | ComponentSlicesNewsletter
  | ComponentSlicesSection
  | ComponentSlicesSportList
  | ComponentSlicesStoreGrade
  | ComponentSlicesTabEnd
  | ComponentSlicesTabStart
  | ComponentSlicesTestimonial
  | ComponentSlicesText
  | ComponentSlicesVerticalTab
  | Error;

export type PagesSportEntity = {
  __typename: 'PagesSportEntity';
  attributes?: Maybe<PagesSport>;
  id: Scalars['ID']['output'];
};

export type PagesSportEntityResponse = {
  __typename: 'PagesSportEntityResponse';
  data?: Maybe<PagesSportEntity>;
};

export type PagesSportEntityResponseCollection = {
  __typename: 'PagesSportEntityResponseCollection';
  data?: Maybe<Array<PagesSportEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type PagesSportFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PagesSportFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PagesSportFiltersInput>;
  not?: InputMaybe<PagesSportFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PagesSportFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  seo?: InputMaybe<ComponentSharedSeoFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PagesSportInput = {
  body?: InputMaybe<Array<Scalars['PagesSportBodyDynamicZoneInput']['input']>>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  seo?: InputMaybe<ComponentSharedSeoInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PagesSportRelationResponseCollection = {
  __typename: 'PagesSportRelationResponseCollection';
  data?: Maybe<Array<PagesSportEntity>>;
};

export type Pagination = {
  __typename: 'Pagination';
  page?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['Int']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type PennylaneProducts = {
  product_id: Scalars['String']['input'];
  product_name: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type ResponseCollectionMeta = {
  __typename: 'ResponseCollectionMeta';
  pagination?: Maybe<Pagination>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type TaskMembers = {
  id: Scalars['Int']['input'];
  member_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UploadFile = {
  __typename: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id: Scalars['ID']['output'];
};

export type UploadFileEntityResponse = {
  __typename: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename: 'UploadFileEntityResponseCollection';
  data?: Maybe<Array<UploadFileEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename: 'UploadFileRelationResponseCollection';
  data?: Maybe<Array<UploadFileEntity>>;
};

export type UploadFolder = {
  __typename: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<UploadFolderEntityResponse>;
  path?: Maybe<Scalars['String']['output']>;
  pathId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id: Scalars['ID']['output'];
};

export type UploadFolderEntityResponse = {
  __typename: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename: 'UploadFolderEntityResponseCollection';
  data?: Maybe<Array<UploadFolderEntity>>;
  meta?: Maybe<ResponseCollectionMeta>;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename: 'UploadFolderRelationResponseCollection';
  data?: Maybe<Array<UploadFolderEntity>>;
};

export type Affected_Rows_Mutation_Response = {
  __typename: 'affected_rows_mutation_response';
  affected_rows?: Maybe<Scalars['smallint']['output']>;
  clubhouse_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  screens?: Maybe<Array<Clubhouse_Screen>>;
  /** An aggregate relationship */
  screens_aggregate?: Maybe<Clubhouse_Screen_Aggregate>;
};

export type Affected_Rows_Mutation_ResponseScreensArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Screen_Order_By>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

export type Affected_Rows_Mutation_ResponseScreens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Screen_Order_By>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

export type Affected_Rows_Response = {
  __typename: 'affected_rows_response';
  affected_rows?: Maybe<Scalars['smallint']['output']>;
};

/** columns and relationships of "apps.version" */
export type Apps_Version = {
  __typename: 'apps_version';
  app?: Maybe<Scalars['String']['output']>;
  current_events_refresh_interval?: Maybe<Scalars['smallint']['output']>;
  event_cache_invalidation_interval?: Maybe<Scalars['smallint']['output']>;
  force_update?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  update?: Maybe<Scalars['Boolean']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "apps.version". All fields are combined with a logical 'AND'. */
export type Apps_Version_Bool_Exp = {
  _and?: InputMaybe<Array<Apps_Version_Bool_Exp>>;
  _not?: InputMaybe<Apps_Version_Bool_Exp>;
  _or?: InputMaybe<Array<Apps_Version_Bool_Exp>>;
  app?: InputMaybe<String_Comparison_Exp>;
  current_events_refresh_interval?: InputMaybe<Smallint_Comparison_Exp>;
  event_cache_invalidation_interval?: InputMaybe<Smallint_Comparison_Exp>;
  force_update?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  update?: InputMaybe<Boolean_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "apps.version". */
export type Apps_Version_Order_By = {
  app?: InputMaybe<Order_By>;
  current_events_refresh_interval?: InputMaybe<Order_By>;
  event_cache_invalidation_interval?: InputMaybe<Order_By>;
  force_update?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  update?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** select columns of table "apps.version" */
export enum Apps_Version_Select_Column {
  /** column name */
  App = 'app',
  /** column name */
  CurrentEventsRefreshInterval = 'current_events_refresh_interval',
  /** column name */
  EventCacheInvalidationInterval = 'event_cache_invalidation_interval',
  /** column name */
  ForceUpdate = 'force_update',
  /** column name */
  Id = 'id',
  /** column name */
  Update = 'update',
  /** column name */
  Version = 'version',
}

/** Streaming cursor of the table "apps_version" */
export type Apps_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Apps_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Apps_Version_Stream_Cursor_Value_Input = {
  app?: InputMaybe<Scalars['String']['input']>;
  current_events_refresh_interval?: InputMaybe<Scalars['smallint']['input']>;
  event_cache_invalidation_interval?: InputMaybe<Scalars['smallint']['input']>;
  force_update?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  update?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type Best_Reporters_Response = {
  __typename: 'best_reporters_response';
  nb_lives?: Maybe<Scalars['Int']['output']>;
  spectator?: Maybe<Lives_Spectator>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
  spectator_name?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "billing.club_customer" */
export type Billing_Club_Customer = {
  __typename: 'billing_club_customer';
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  club_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  customer?: Maybe<Billing_Customer>;
  customer_ptr_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "billing.club_customer". All fields are combined with a logical 'AND'. */
export type Billing_Club_Customer_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_Club_Customer_Bool_Exp>>;
  _not?: InputMaybe<Billing_Club_Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_Club_Customer_Bool_Exp>>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  customer?: InputMaybe<Billing_Customer_Bool_Exp>;
  customer_ptr_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "billing.club_customer" */
export enum Billing_Club_Customer_Constraint {
  /** unique or primary key constraint on columns "customer_ptr_id" */
  ClubCustomerPkey = 'club_customer_pkey',
}

/** input type for incrementing numeric columns in table "billing.club_customer" */
export type Billing_Club_Customer_Inc_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "billing.club_customer" */
export type Billing_Club_Customer_Insert_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  customer?: InputMaybe<Billing_Customer_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "billing.club_customer" */
export type Billing_Club_Customer_Mutation_Response = {
  __typename: 'billing_club_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Billing_Club_Customer>>;
};

/** on_conflict condition type for table "billing.club_customer" */
export type Billing_Club_Customer_On_Conflict = {
  constraint: Billing_Club_Customer_Constraint;
  update_columns?: Array<Billing_Club_Customer_Update_Column>;
  where?: InputMaybe<Billing_Club_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "billing.club_customer". */
export type Billing_Club_Customer_Order_By = {
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_id?: InputMaybe<Order_By>;
  customer?: InputMaybe<Billing_Customer_Order_By>;
  customer_ptr_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: billing.club_customer */
export type Billing_Club_Customer_Pk_Columns_Input = {
  customer_ptr_id: Scalars['Int']['input'];
};

/** select columns of table "billing.club_customer" */
export enum Billing_Club_Customer_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CustomerPtrId = 'customer_ptr_id',
}

/** input type for updating data in table "billing.club_customer" */
export type Billing_Club_Customer_Set_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
};

/** Streaming cursor of the table "billing_club_customer" */
export type Billing_Club_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Club_Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_Club_Customer_Stream_Cursor_Value_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  customer_ptr_id?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "billing.club_customer" */
export enum Billing_Club_Customer_Update_Column {
  /** column name */
  ClubId = 'club_id',
}

export type Billing_Club_Customer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Billing_Club_Customer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_Club_Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billing_Club_Customer_Bool_Exp;
};

/** columns and relationships of "billing.customer" */
export type Billing_Customer = {
  __typename: 'billing_customer';
  id: Scalars['Int']['output'];
  pennylane_id?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "billing.customer". All fields are combined with a logical 'AND'. */
export type Billing_Customer_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_Customer_Bool_Exp>>;
  _not?: InputMaybe<Billing_Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_Customer_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  pennylane_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "billing.customer" */
export enum Billing_Customer_Constraint {
  /** unique or primary key constraint on columns "pennylane_id" */
  CustomerPennylaneIdKey = 'customer_pennylane_id_key',
  /** unique or primary key constraint on columns "id" */
  CustomerPkey = 'customer_pkey',
}

/** input type for inserting data into table "billing.customer" */
export type Billing_Customer_Insert_Input = {
  pennylane_id?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "billing.customer" */
export type Billing_Customer_Mutation_Response = {
  __typename: 'billing_customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Billing_Customer>>;
};

/** input type for inserting object relation for remote table "billing.customer" */
export type Billing_Customer_Obj_Rel_Insert_Input = {
  data: Billing_Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Billing_Customer_On_Conflict>;
};

/** on_conflict condition type for table "billing.customer" */
export type Billing_Customer_On_Conflict = {
  constraint: Billing_Customer_Constraint;
  update_columns?: Array<Billing_Customer_Update_Column>;
  where?: InputMaybe<Billing_Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "billing.customer". */
export type Billing_Customer_Order_By = {
  id?: InputMaybe<Order_By>;
  pennylane_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: billing.customer */
export type Billing_Customer_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "billing.customer" */
export enum Billing_Customer_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PennylaneId = 'pennylane_id',
}

/** input type for updating data in table "billing.customer" */
export type Billing_Customer_Set_Input = {
  pennylane_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "billing_customer" */
export type Billing_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_Customer_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  pennylane_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "billing.customer" */
export enum Billing_Customer_Update_Column {
  /** column name */
  PennylaneId = 'pennylane_id',
}

export type Billing_Customer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_Customer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billing_Customer_Bool_Exp;
};

export type Calendar_Links_Response = {
  __typename: 'calendar_links_response';
  google?: Maybe<Scalars['String']['output']>;
  ics?: Maybe<Scalars['String']['output']>;
  office?: Maybe<Scalars['String']['output']>;
  outlook?: Maybe<Scalars['String']['output']>;
  yahoo?: Maybe<Scalars['String']['output']>;
};

export type Championship_Response = {
  __typename: 'championship_response';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Championship_Response_With_Exceptions = {
  __typename: 'championship_response_with_exceptions';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nb_exceptions?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Club_Teams_Team_Detail_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Clubhouse_Add_Screen_Insert_Input = {
  challenge_id?: InputMaybe<Scalars['Int']['input']>;
  clubhouse_id?: InputMaybe<Scalars['Int']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  news?: InputMaybe<News_Insert_Input>;
  phase_id?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  sponsors_category_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "clubhouse.club_house" */
export type Clubhouse_Club_House = {
  __typename: 'clubhouse_club_house';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  admins?: Maybe<Array<Clubs_Club_Admin>>;
  banner?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  screens?: Maybe<Array<Clubhouse_Screen>>;
  /** An aggregate relationship */
  screens_aggregate?: Maybe<Clubhouse_Screen_Aggregate>;
};

/** columns and relationships of "clubhouse.club_house" */
export type Clubhouse_Club_HouseAdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "clubhouse.club_house" */
export type Clubhouse_Club_HouseScreensArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Screen_Order_By>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

/** columns and relationships of "clubhouse.club_house" */
export type Clubhouse_Club_HouseScreens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Screen_Order_By>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

/** order by aggregate values of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Aggregate_Order_By = {
  avg?: InputMaybe<Clubhouse_Club_House_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Clubhouse_Club_House_Max_Order_By>;
  min?: InputMaybe<Clubhouse_Club_House_Min_Order_By>;
  stddev?: InputMaybe<Clubhouse_Club_House_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Clubhouse_Club_House_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Clubhouse_Club_House_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Clubhouse_Club_House_Sum_Order_By>;
  var_pop?: InputMaybe<Clubhouse_Club_House_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Clubhouse_Club_House_Var_Samp_Order_By>;
  variance?: InputMaybe<Clubhouse_Club_House_Variance_Order_By>;
};

/** order by avg() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Avg_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clubhouse.club_house". All fields are combined with a logical 'AND'. */
export type Clubhouse_Club_House_Bool_Exp = {
  _and?: InputMaybe<Array<Clubhouse_Club_House_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Clubhouse_Club_House_Bool_Exp>;
  _or?: InputMaybe<Array<Clubhouse_Club_House_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  banner?: InputMaybe<String_Comparison_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  screens?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
  screens_aggregate?: InputMaybe<Clubhouse_Screen_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "clubhouse.club_house" */
export enum Clubhouse_Club_House_Constraint {
  /** unique or primary key constraint on columns "name", "club_id" */
  ClubHouseClubIdNameC5b8d88fUniq = 'club_house_club_id_name_c5b8d88f_uniq',
  /** unique or primary key constraint on columns "id" */
  ClubHousePkey = 'club_house_pkey',
}

/** input type for inserting data into table "clubhouse.club_house" */
export type Clubhouse_Club_House_Insert_Input = {
  banner?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  banner?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  banner?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clubhouse.club_house" */
export type Clubhouse_Club_House_Mutation_Response = {
  __typename: 'clubhouse_club_house_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Clubhouse_Club_House>>;
};

/** on_conflict condition type for table "clubhouse.club_house" */
export type Clubhouse_Club_House_On_Conflict = {
  constraint: Clubhouse_Club_House_Constraint;
  update_columns?: Array<Clubhouse_Club_House_Update_Column>;
  where?: InputMaybe<Clubhouse_Club_House_Bool_Exp>;
};

/** Ordering options when selecting data from "clubhouse.club_house". */
export type Clubhouse_Club_House_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  banner?: InputMaybe<Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  screens_aggregate?: InputMaybe<Clubhouse_Screen_Aggregate_Order_By>;
};

/** primary key columns input for table: clubhouse.club_house */
export type Clubhouse_Club_House_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "clubhouse.club_house" */
export enum Clubhouse_Club_House_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Banner = 'banner',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "clubhouse.club_house" */
export type Clubhouse_Club_House_Set_Input = {
  banner?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Stddev_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Stddev_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Stddev_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "clubhouse_club_house" */
export type Clubhouse_Club_House_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubhouse_Club_House_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubhouse_Club_House_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  banner?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Sum_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "clubhouse.club_house" */
export enum Clubhouse_Club_House_Update_Column {
  /** column name */
  Banner = 'banner',
  /** column name */
  Name = 'name',
}

export type Clubhouse_Club_House_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clubhouse_Club_House_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clubhouse_Club_House_Bool_Exp;
};

/** order by var_pop() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Var_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Var_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "clubhouse.club_house" */
export type Clubhouse_Club_House_Variance_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "clubhouse.screen" */
export type Clubhouse_Screen = {
  __typename: 'clubhouse_screen';
  /** An object relationship */
  challenge?: Maybe<Tournaments_Challenge>;
  challenge_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  club_house?: Maybe<Clubhouse_Club_House>;
  clubhouse_id?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['smallint']['output']>;
  duration?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  news?: Maybe<News_News>;
  news_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  phase?: Maybe<Tournaments_Phase>;
  phase_id?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  sponsors_category?: Maybe<Sponsors_Category>;
  sponsors_category_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team_in_season?: Maybe<Teams_Team_In_Season>;
  /** An object relationship */
  template?: Maybe<Clubhouse_Template>;
  template_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tournament?: Maybe<Tournaments_Tournament>;
  tournament_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "clubhouse.screen" */
export type Clubhouse_Screen_Aggregate = {
  __typename: 'clubhouse_screen_aggregate';
  aggregate?: Maybe<Clubhouse_Screen_Aggregate_Fields>;
  nodes?: Maybe<Array<Clubhouse_Screen>>;
};

export type Clubhouse_Screen_Aggregate_Bool_Exp = {
  count?: InputMaybe<Clubhouse_Screen_Aggregate_Bool_Exp_Count>;
};

export type Clubhouse_Screen_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clubhouse.screen" */
export type Clubhouse_Screen_Aggregate_Fields = {
  __typename: 'clubhouse_screen_aggregate_fields';
  avg?: Maybe<Clubhouse_Screen_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Clubhouse_Screen_Max_Fields>;
  min?: Maybe<Clubhouse_Screen_Min_Fields>;
  stddev?: Maybe<Clubhouse_Screen_Stddev_Fields>;
  stddev_pop?: Maybe<Clubhouse_Screen_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clubhouse_Screen_Stddev_Samp_Fields>;
  sum?: Maybe<Clubhouse_Screen_Sum_Fields>;
  var_pop?: Maybe<Clubhouse_Screen_Var_Pop_Fields>;
  var_samp?: Maybe<Clubhouse_Screen_Var_Samp_Fields>;
  variance?: Maybe<Clubhouse_Screen_Variance_Fields>;
};

/** aggregate fields of "clubhouse.screen" */
export type Clubhouse_Screen_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "clubhouse.screen" */
export type Clubhouse_Screen_Aggregate_Order_By = {
  avg?: InputMaybe<Clubhouse_Screen_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Clubhouse_Screen_Max_Order_By>;
  min?: InputMaybe<Clubhouse_Screen_Min_Order_By>;
  stddev?: InputMaybe<Clubhouse_Screen_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Clubhouse_Screen_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Clubhouse_Screen_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Clubhouse_Screen_Sum_Order_By>;
  var_pop?: InputMaybe<Clubhouse_Screen_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Clubhouse_Screen_Var_Samp_Order_By>;
  variance?: InputMaybe<Clubhouse_Screen_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Clubhouse_Screen_Avg_Fields = {
  __typename: 'clubhouse_screen_avg_fields';
  challenge_id?: Maybe<Scalars['Float']['output']>;
  clubhouse_id?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  news_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
  sponsors_category_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  tournament_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Avg_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clubhouse.screen". All fields are combined with a logical 'AND'. */
export type Clubhouse_Screen_Bool_Exp = {
  _and?: InputMaybe<Array<Clubhouse_Screen_Bool_Exp>>;
  _not?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
  _or?: InputMaybe<Array<Clubhouse_Screen_Bool_Exp>>;
  challenge?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
  challenge_id?: InputMaybe<Int_Comparison_Exp>;
  club_house?: InputMaybe<Clubhouse_Club_House_Bool_Exp>;
  clubhouse_id?: InputMaybe<Int_Comparison_Exp>;
  day?: InputMaybe<Smallint_Comparison_Exp>;
  duration?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  news?: InputMaybe<News_News_Bool_Exp>;
  news_id?: InputMaybe<Int_Comparison_Exp>;
  phase?: InputMaybe<Tournaments_Phase_Bool_Exp>;
  phase_id?: InputMaybe<Int_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  sponsors_category?: InputMaybe<Sponsors_Category_Bool_Exp>;
  sponsors_category_id?: InputMaybe<Int_Comparison_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  template?: InputMaybe<Clubhouse_Template_Bool_Exp>;
  template_id?: InputMaybe<Int_Comparison_Exp>;
  tournament?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
  tournament_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "clubhouse.screen" */
export type Clubhouse_Screen_Inc_Input = {
  day?: InputMaybe<Scalars['smallint']['input']>;
  duration?: InputMaybe<Scalars['smallint']['input']>;
  phase_id?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  sponsors_category_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Clubhouse_Screen_Max_Fields = {
  __typename: 'clubhouse_screen_max_fields';
  challenge_id?: Maybe<Scalars['Int']['output']>;
  clubhouse_id?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['smallint']['output']>;
  duration?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  news_id?: Maybe<Scalars['Int']['output']>;
  phase_id?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  sponsors_category_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  tournament_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Max_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Clubhouse_Screen_Min_Fields = {
  __typename: 'clubhouse_screen_min_fields';
  challenge_id?: Maybe<Scalars['Int']['output']>;
  clubhouse_id?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['smallint']['output']>;
  duration?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  news_id?: Maybe<Scalars['Int']['output']>;
  phase_id?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  sponsors_category_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  tournament_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Min_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clubhouse.screen" */
export type Clubhouse_Screen_Mutation_Response = {
  __typename: 'clubhouse_screen_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Clubhouse_Screen>>;
};

/** Ordering options when selecting data from "clubhouse.screen". */
export type Clubhouse_Screen_Order_By = {
  challenge?: InputMaybe<Tournaments_Challenge_Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  club_house?: InputMaybe<Clubhouse_Club_House_Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news?: InputMaybe<News_News_Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase?: InputMaybe<Tournaments_Phase_Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category?: InputMaybe<Sponsors_Category_Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Order_By>;
  template?: InputMaybe<Clubhouse_Template_Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament?: InputMaybe<Tournaments_Tournament_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clubhouse.screen */
export type Clubhouse_Screen_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "clubhouse.screen" */
export enum Clubhouse_Screen_Select_Column {
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  ClubhouseId = 'clubhouse_id',
  /** column name */
  Day = 'day',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  NewsId = 'news_id',
  /** column name */
  PhaseId = 'phase_id',
  /** column name */
  Rank = 'rank',
  /** column name */
  SponsorsCategoryId = 'sponsors_category_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  TournamentId = 'tournament_id',
}

/** input type for updating data in table "clubhouse.screen" */
export type Clubhouse_Screen_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  day?: InputMaybe<Scalars['smallint']['input']>;
  duration?: InputMaybe<Scalars['smallint']['input']>;
  phase_id?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  sponsors_category_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Clubhouse_Screen_Stddev_Fields = {
  __typename: 'clubhouse_screen_stddev_fields';
  challenge_id?: Maybe<Scalars['Float']['output']>;
  clubhouse_id?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  news_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
  sponsors_category_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  tournament_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Stddev_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Clubhouse_Screen_Stddev_Pop_Fields = {
  __typename: 'clubhouse_screen_stddev_pop_fields';
  challenge_id?: Maybe<Scalars['Float']['output']>;
  clubhouse_id?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  news_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
  sponsors_category_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  tournament_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Stddev_Pop_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Clubhouse_Screen_Stddev_Samp_Fields = {
  __typename: 'clubhouse_screen_stddev_samp_fields';
  challenge_id?: Maybe<Scalars['Float']['output']>;
  clubhouse_id?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  news_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
  sponsors_category_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  tournament_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Stddev_Samp_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "clubhouse_screen" */
export type Clubhouse_Screen_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubhouse_Screen_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubhouse_Screen_Stream_Cursor_Value_Input = {
  challenge_id?: InputMaybe<Scalars['Int']['input']>;
  clubhouse_id?: InputMaybe<Scalars['Int']['input']>;
  day?: InputMaybe<Scalars['smallint']['input']>;
  duration?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  news_id?: InputMaybe<Scalars['Int']['input']>;
  phase_id?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  sponsors_category_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  template_id?: InputMaybe<Scalars['Int']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Clubhouse_Screen_Sum_Fields = {
  __typename: 'clubhouse_screen_sum_fields';
  challenge_id?: Maybe<Scalars['Int']['output']>;
  clubhouse_id?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['smallint']['output']>;
  duration?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  news_id?: Maybe<Scalars['Int']['output']>;
  phase_id?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  sponsors_category_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  template_id?: Maybe<Scalars['Int']['output']>;
  tournament_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Sum_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

export type Clubhouse_Screen_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Clubhouse_Screen_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clubhouse_Screen_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clubhouse_Screen_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Clubhouse_Screen_Var_Pop_Fields = {
  __typename: 'clubhouse_screen_var_pop_fields';
  challenge_id?: Maybe<Scalars['Float']['output']>;
  clubhouse_id?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  news_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
  sponsors_category_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  tournament_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Var_Pop_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Clubhouse_Screen_Var_Samp_Fields = {
  __typename: 'clubhouse_screen_var_samp_fields';
  challenge_id?: Maybe<Scalars['Float']['output']>;
  clubhouse_id?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  news_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
  sponsors_category_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  tournament_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Var_Samp_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Clubhouse_Screen_Variance_Fields = {
  __typename: 'clubhouse_screen_variance_fields';
  challenge_id?: Maybe<Scalars['Float']['output']>;
  clubhouse_id?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  news_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  rank?: Maybe<Scalars['Float']['output']>;
  sponsors_category_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  template_id?: Maybe<Scalars['Float']['output']>;
  tournament_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "clubhouse.screen" */
export type Clubhouse_Screen_Variance_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  clubhouse_id?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  news_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_category_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "clubhouse.template" */
export type Clubhouse_Template = {
  __typename: 'clubhouse_template';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "clubhouse.template". All fields are combined with a logical 'AND'. */
export type Clubhouse_Template_Bool_Exp = {
  _and?: InputMaybe<Array<Clubhouse_Template_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Clubhouse_Template_Bool_Exp>;
  _or?: InputMaybe<Array<Clubhouse_Template_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  emoji?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "clubhouse.template". */
export type Clubhouse_Template_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** select columns of table "clubhouse.template" */
export enum Clubhouse_Template_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

/** Streaming cursor of the table "clubhouse_template" */
export type Clubhouse_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubhouse_Template_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubhouse_Template_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "clubs.admin" */
export type Clubs_Admin = {
  __typename: 'clubs_admin';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  clubs?: Maybe<Array<Clubs_Club_Admin>>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  user?: Maybe<Users_User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "clubs.admin" */
export type Clubs_AdminClubsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "clubs.admin". All fields are combined with a logical 'AND'. */
export type Clubs_Admin_Bool_Exp = {
  _and?: InputMaybe<Array<Clubs_Admin_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Clubs_Admin_Bool_Exp>;
  _or?: InputMaybe<Array<Clubs_Admin_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  clubs?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "clubs.admin". */
export type Clubs_Admin_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  clubs_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "clubs.admin" */
export enum Clubs_Admin_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "clubs_admin" */
export type Clubs_Admin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubs_Admin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Admin_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_Club = {
  __typename: 'clubs_club';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  acronym?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  admins?: Maybe<Array<Clubs_Club_Admin>>;
  algolia_secured_api_key?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  club_customer?: Maybe<Billing_Club_Customer>;
  /** An array relationship */
  clubhouses?: Maybe<Array<Clubhouse_Club_House>>;
  color_primary?: Maybe<Scalars['String']['output']>;
  color_secondary?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  convocation_tasks?: Maybe<Array<Tasks_Task>>;
  /** An aggregate relationship */
  convocation_tasks_aggregate?: Maybe<Tasks_Task_Aggregate>;
  /** An object relationship */
  country?: Maybe<Places_Country>;
  country_id?: Maybe<Scalars['Int']['output']>;
  customer_clubhouse_start?: Maybe<Scalars['date']['output']>;
  customer_monsiteclub_start?: Maybe<Scalars['date']['output']>;
  customer_planner_start?: Maybe<Scalars['date']['output']>;
  customer_premium_start?: Maybe<Scalars['date']['output']>;
  customer_tournament_start?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  department?: Maybe<Places_Department>;
  department_id?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  equipments?: Maybe<Array<Places_Equipment>>;
  facebook_page?: Maybe<Scalars['String']['output']>;
  generated_presentation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instagram_account?: Maybe<Scalars['String']['output']>;
  is_customer?: Maybe<Scalars['date']['output']>;
  linkedin_account?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  members?: Maybe<Array<Members_Member>>;
  /** An aggregate relationship */
  members_aggregate?: Maybe<Members_Member_Aggregate>;
  name?: Maybe<Scalars['String']['output']>;
  nb_fans?: Maybe<Scalars['smallint']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  organizations?: Maybe<Array<Clubs_Organization>>;
  pilot?: Maybe<Scalars['Boolean']['output']>;
  planner_convocation_link?: Maybe<Scalars['String']['output']>;
  planner_email_copy?: Maybe<Scalars['String']['output']>;
  planner_email_footer_text?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  planner_settings?: Maybe<Clubs_Planner_Settings>;
  presentation?: Maybe<Scalars['String']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  search_keywords?: Maybe<Scalars['String']['output']>;
  share_picture?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  sms_credit?: Maybe<Sms_Club_Sms_Credit>;
  /** An object relationship */
  sport?: Maybe<Clubs_Sport>;
  sport_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  teams?: Maybe<Array<Teams_Team>>;
  ticket_office?: Maybe<Scalars['String']['output']>;
  tiktok_account?: Maybe<Scalars['String']['output']>;
  twitter_account?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  youtube_account?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubAdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubClubhousesArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Club_House_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Club_House_Order_By>>;
  where?: InputMaybe<Clubhouse_Club_House_Bool_Exp>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubConvocation_TasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Task_Order_By>>;
  where?: InputMaybe<Tasks_Task_Bool_Exp>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubConvocation_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Task_Order_By>>;
  where?: InputMaybe<Tasks_Task_Bool_Exp>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubEquipmentsArgs = {
  distinct_on?: InputMaybe<Array<Places_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Equipment_Order_By>>;
  where?: InputMaybe<Places_Equipment_Bool_Exp>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubMembersArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_Order_By>>;
  where?: InputMaybe<Members_Member_Bool_Exp>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_Order_By>>;
  where?: InputMaybe<Members_Member_Bool_Exp>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Organization_Order_By>>;
  where?: InputMaybe<Clubs_Organization_Bool_Exp>;
};

/** columns and relationships of "clubs.club" */
export type Clubs_ClubTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Order_By>>;
  where?: InputMaybe<Teams_Team_Bool_Exp>;
};

/** columns and relationships of "clubs.club_admin" */
export type Clubs_Club_Admin = {
  __typename: 'clubs_club_admin';
  /** An object relationship */
  admin?: Maybe<Clubs_Admin>;
  admin_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
};

/** order by aggregate values of table "clubs.club_admin" */
export type Clubs_Club_Admin_Aggregate_Order_By = {
  avg?: InputMaybe<Clubs_Club_Admin_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Clubs_Club_Admin_Max_Order_By>;
  min?: InputMaybe<Clubs_Club_Admin_Min_Order_By>;
  stddev?: InputMaybe<Clubs_Club_Admin_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Clubs_Club_Admin_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Clubs_Club_Admin_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Clubs_Club_Admin_Sum_Order_By>;
  var_pop?: InputMaybe<Clubs_Club_Admin_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Clubs_Club_Admin_Var_Samp_Order_By>;
  variance?: InputMaybe<Clubs_Club_Admin_Variance_Order_By>;
};

/** order by avg() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Avg_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clubs.club_admin". All fields are combined with a logical 'AND'. */
export type Clubs_Club_Admin_Bool_Exp = {
  _and?: InputMaybe<Array<Clubs_Club_Admin_Bool_Exp>>;
  _not?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  _or?: InputMaybe<Array<Clubs_Club_Admin_Bool_Exp>>;
  admin?: InputMaybe<Clubs_Admin_Bool_Exp>;
  admin_id?: InputMaybe<Int_Comparison_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Max_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Min_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "clubs.club_admin". */
export type Clubs_Club_Admin_Order_By = {
  admin?: InputMaybe<Clubs_Admin_Order_By>;
  admin_id?: InputMaybe<Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "clubs.club_admin" */
export enum Clubs_Club_Admin_Select_Column {
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
}

/** order by stddev() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Stddev_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Stddev_Pop_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Stddev_Samp_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "clubs_club_admin" */
export type Clubs_Club_Admin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubs_Club_Admin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Club_Admin_Stream_Cursor_Value_Input = {
  admin_id?: InputMaybe<Scalars['Int']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Sum_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Var_Pop_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Var_Samp_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "clubs.club_admin" */
export type Clubs_Club_Admin_Variance_Order_By = {
  admin_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregated selection of "clubs.club" */
export type Clubs_Club_Aggregate = {
  __typename: 'clubs_club_aggregate';
  aggregate?: Maybe<Clubs_Club_Aggregate_Fields>;
  nodes?: Maybe<Array<Clubs_Club>>;
};

export type Clubs_Club_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Clubs_Club_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Clubs_Club_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Clubs_Club_Aggregate_Bool_Exp_Count>;
};

export type Clubs_Club_Aggregate_Bool_Exp_Bool_And = {
  arguments: Clubs_Club_Select_Column_Clubs_Club_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Clubs_Club_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Clubs_Club_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Clubs_Club_Select_Column_Clubs_Club_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Clubs_Club_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Clubs_Club_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Clubs_Club_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Clubs_Club_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "clubs.club" */
export type Clubs_Club_Aggregate_Fields = {
  __typename: 'clubs_club_aggregate_fields';
  avg?: Maybe<Clubs_Club_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Clubs_Club_Max_Fields>;
  min?: Maybe<Clubs_Club_Min_Fields>;
  stddev?: Maybe<Clubs_Club_Stddev_Fields>;
  stddev_pop?: Maybe<Clubs_Club_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Clubs_Club_Stddev_Samp_Fields>;
  sum?: Maybe<Clubs_Club_Sum_Fields>;
  var_pop?: Maybe<Clubs_Club_Var_Pop_Fields>;
  var_samp?: Maybe<Clubs_Club_Var_Samp_Fields>;
  variance?: Maybe<Clubs_Club_Variance_Fields>;
};

/** aggregate fields of "clubs.club" */
export type Clubs_Club_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clubs_Club_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "clubs.club" */
export type Clubs_Club_Aggregate_Order_By = {
  avg?: InputMaybe<Clubs_Club_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Clubs_Club_Max_Order_By>;
  min?: InputMaybe<Clubs_Club_Min_Order_By>;
  stddev?: InputMaybe<Clubs_Club_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Clubs_Club_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Clubs_Club_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Clubs_Club_Sum_Order_By>;
  var_pop?: InputMaybe<Clubs_Club_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Clubs_Club_Var_Samp_Order_By>;
  variance?: InputMaybe<Clubs_Club_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Clubs_Club_Avg_Fields = {
  __typename: 'clubs_club_avg_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  nb_fans?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "clubs.club" */
export type Clubs_Club_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clubs.club". All fields are combined with a logical 'AND'. */
export type Clubs_Club_Bool_Exp = {
  _and?: InputMaybe<Array<Clubs_Club_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Clubs_Club_Bool_Exp>;
  _or?: InputMaybe<Array<Clubs_Club_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  acronym?: InputMaybe<String_Comparison_Exp>;
  admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  algolia_secured_api_key?: InputMaybe<String_Comparison_Exp>;
  club_customer?: InputMaybe<Billing_Club_Customer_Bool_Exp>;
  clubhouses?: InputMaybe<Clubhouse_Club_House_Bool_Exp>;
  color_primary?: InputMaybe<String_Comparison_Exp>;
  color_secondary?: InputMaybe<String_Comparison_Exp>;
  convocation_tasks?: InputMaybe<Tasks_Task_Bool_Exp>;
  convocation_tasks_aggregate?: InputMaybe<Tasks_Task_Aggregate_Bool_Exp>;
  country?: InputMaybe<Places_Country_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  customer_clubhouse_start?: InputMaybe<Date_Comparison_Exp>;
  customer_monsiteclub_start?: InputMaybe<Date_Comparison_Exp>;
  customer_planner_start?: InputMaybe<Date_Comparison_Exp>;
  customer_premium_start?: InputMaybe<Date_Comparison_Exp>;
  customer_tournament_start?: InputMaybe<Date_Comparison_Exp>;
  department?: InputMaybe<Places_Department_Bool_Exp>;
  department_id?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  equipments?: InputMaybe<Places_Equipment_Bool_Exp>;
  facebook_page?: InputMaybe<String_Comparison_Exp>;
  generated_presentation?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  instagram_account?: InputMaybe<String_Comparison_Exp>;
  is_customer?: InputMaybe<Date_Comparison_Exp>;
  linkedin_account?: InputMaybe<String_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  members?: InputMaybe<Members_Member_Bool_Exp>;
  members_aggregate?: InputMaybe<Members_Member_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_fans?: InputMaybe<Smallint_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  organizations?: InputMaybe<Clubs_Organization_Bool_Exp>;
  pilot?: InputMaybe<Boolean_Comparison_Exp>;
  planner_convocation_link?: InputMaybe<String_Comparison_Exp>;
  planner_email_copy?: InputMaybe<String_Comparison_Exp>;
  planner_email_footer_text?: InputMaybe<String_Comparison_Exp>;
  planner_settings?: InputMaybe<Clubs_Planner_Settings_Bool_Exp>;
  presentation?: InputMaybe<String_Comparison_Exp>;
  scraping_id?: InputMaybe<Int_Comparison_Exp>;
  search_keywords?: InputMaybe<String_Comparison_Exp>;
  share_picture?: InputMaybe<String_Comparison_Exp>;
  short_name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sms_credit?: InputMaybe<Sms_Club_Sms_Credit_Bool_Exp>;
  sport?: InputMaybe<Clubs_Sport_Bool_Exp>;
  sport_id?: InputMaybe<Int_Comparison_Exp>;
  teams?: InputMaybe<Teams_Team_Bool_Exp>;
  ticket_office?: InputMaybe<String_Comparison_Exp>;
  tiktok_account?: InputMaybe<String_Comparison_Exp>;
  twitter_account?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  website?: InputMaybe<String_Comparison_Exp>;
  youtube_account?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "clubs.club_detail" */
export type Clubs_Club_Detail = {
  __typename: 'clubs_club_detail';
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  /** An array relationship */
  equipments?: Maybe<Array<Places_Equipment>>;
  id: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  sport?: Maybe<Clubs_Sport>;
  sport_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "clubs.club_detail" */
export type Clubs_Club_DetailEquipmentsArgs = {
  distinct_on?: InputMaybe<Array<Places_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Equipment_Order_By>>;
  where?: InputMaybe<Places_Equipment_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "clubs.club_detail". All fields are combined with a logical 'AND'. */
export type Clubs_Club_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Clubs_Club_Detail_Bool_Exp>>;
  _not?: InputMaybe<Clubs_Club_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Clubs_Club_Detail_Bool_Exp>>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  equipments?: InputMaybe<Places_Equipment_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sport?: InputMaybe<Clubs_Sport_Bool_Exp>;
  sport_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "clubs.club_detail". */
export type Clubs_Club_Detail_Order_By = {
  club?: InputMaybe<Clubs_Club_Order_By>;
  equipments_aggregate?: InputMaybe<Places_Equipment_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport?: InputMaybe<Clubs_Sport_Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** select columns of table "clubs.club_detail" */
export enum Clubs_Club_Detail_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Slug = 'slug',
  /** column name */
  SportId = 'sport_id',
}

/** Streaming cursor of the table "clubs_club_detail" */
export type Clubs_Club_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubs_Club_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Club_Detail_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for incrementing numeric columns in table "clubs.club" */
export type Clubs_Club_Inc_Input = {
  custom_country_id?: InputMaybe<Scalars['Int']['input']>;
  custom_department_id?: InputMaybe<Scalars['Int']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Clubs_Club_Max_Fields = {
  __typename: 'clubs_club_max_fields';
  acronym?: Maybe<Scalars['String']['output']>;
  color_primary?: Maybe<Scalars['String']['output']>;
  color_secondary?: Maybe<Scalars['String']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  department_id?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebook_page?: Maybe<Scalars['String']['output']>;
  generated_presentation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instagram_account?: Maybe<Scalars['String']['output']>;
  linkedin_account?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nb_fans?: Maybe<Scalars['smallint']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  presentation?: Maybe<Scalars['String']['output']>;
  share_picture?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  ticket_office?: Maybe<Scalars['String']['output']>;
  tiktok_account?: Maybe<Scalars['String']['output']>;
  twitter_account?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  youtube_account?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "clubs.club" */
export type Clubs_Club_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  acronym?: InputMaybe<Order_By>;
  algolia_secured_api_key?: InputMaybe<Order_By>;
  color_primary?: InputMaybe<Order_By>;
  color_secondary?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  customer_clubhouse_start?: InputMaybe<Order_By>;
  customer_monsiteclub_start?: InputMaybe<Order_By>;
  customer_planner_start?: InputMaybe<Order_By>;
  customer_premium_start?: InputMaybe<Order_By>;
  customer_tournament_start?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  facebook_page?: InputMaybe<Order_By>;
  generated_presentation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram_account?: InputMaybe<Order_By>;
  is_customer?: InputMaybe<Order_By>;
  linkedin_account?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  planner_convocation_link?: InputMaybe<Order_By>;
  planner_email_copy?: InputMaybe<Order_By>;
  planner_email_footer_text?: InputMaybe<Order_By>;
  presentation?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  search_keywords?: InputMaybe<Order_By>;
  share_picture?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
  ticket_office?: InputMaybe<Order_By>;
  tiktok_account?: InputMaybe<Order_By>;
  twitter_account?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
  youtube_account?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Clubs_Club_Min_Fields = {
  __typename: 'clubs_club_min_fields';
  acronym?: Maybe<Scalars['String']['output']>;
  color_primary?: Maybe<Scalars['String']['output']>;
  color_secondary?: Maybe<Scalars['String']['output']>;
  country_id?: Maybe<Scalars['Int']['output']>;
  department_id?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebook_page?: Maybe<Scalars['String']['output']>;
  generated_presentation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instagram_account?: Maybe<Scalars['String']['output']>;
  linkedin_account?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nb_fans?: Maybe<Scalars['smallint']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  presentation?: Maybe<Scalars['String']['output']>;
  share_picture?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  ticket_office?: Maybe<Scalars['String']['output']>;
  tiktok_account?: Maybe<Scalars['String']['output']>;
  twitter_account?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  youtube_account?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "clubs.club" */
export type Clubs_Club_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  acronym?: InputMaybe<Order_By>;
  algolia_secured_api_key?: InputMaybe<Order_By>;
  color_primary?: InputMaybe<Order_By>;
  color_secondary?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  customer_clubhouse_start?: InputMaybe<Order_By>;
  customer_monsiteclub_start?: InputMaybe<Order_By>;
  customer_planner_start?: InputMaybe<Order_By>;
  customer_premium_start?: InputMaybe<Order_By>;
  customer_tournament_start?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  facebook_page?: InputMaybe<Order_By>;
  generated_presentation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram_account?: InputMaybe<Order_By>;
  is_customer?: InputMaybe<Order_By>;
  linkedin_account?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  planner_convocation_link?: InputMaybe<Order_By>;
  planner_email_copy?: InputMaybe<Order_By>;
  planner_email_footer_text?: InputMaybe<Order_By>;
  presentation?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  search_keywords?: InputMaybe<Order_By>;
  share_picture?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
  ticket_office?: InputMaybe<Order_By>;
  tiktok_account?: InputMaybe<Order_By>;
  twitter_account?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
  youtube_account?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "clubs.club" */
export type Clubs_Club_Mutation_Response = {
  __typename: 'clubs_club_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Clubs_Club>>;
};

/** Ordering options when selecting data from "clubs.club". */
export type Clubs_Club_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  acronym?: InputMaybe<Order_By>;
  admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  algolia_secured_api_key?: InputMaybe<Order_By>;
  club_customer?: InputMaybe<Billing_Club_Customer_Order_By>;
  clubhouses_aggregate?: InputMaybe<Clubhouse_Club_House_Aggregate_Order_By>;
  color_primary?: InputMaybe<Order_By>;
  color_secondary?: InputMaybe<Order_By>;
  convocation_tasks_aggregate?: InputMaybe<Tasks_Task_Aggregate_Order_By>;
  country?: InputMaybe<Places_Country_Order_By>;
  country_id?: InputMaybe<Order_By>;
  customer_clubhouse_start?: InputMaybe<Order_By>;
  customer_monsiteclub_start?: InputMaybe<Order_By>;
  customer_planner_start?: InputMaybe<Order_By>;
  customer_premium_start?: InputMaybe<Order_By>;
  customer_tournament_start?: InputMaybe<Order_By>;
  department?: InputMaybe<Places_Department_Order_By>;
  department_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  equipments_aggregate?: InputMaybe<Places_Equipment_Aggregate_Order_By>;
  facebook_page?: InputMaybe<Order_By>;
  generated_presentation?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instagram_account?: InputMaybe<Order_By>;
  is_customer?: InputMaybe<Order_By>;
  linkedin_account?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Members_Member_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  organizations_aggregate?: InputMaybe<Clubs_Organization_Aggregate_Order_By>;
  pilot?: InputMaybe<Order_By>;
  planner_convocation_link?: InputMaybe<Order_By>;
  planner_email_copy?: InputMaybe<Order_By>;
  planner_email_footer_text?: InputMaybe<Order_By>;
  planner_settings?: InputMaybe<Clubs_Planner_Settings_Order_By>;
  presentation?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  search_keywords?: InputMaybe<Order_By>;
  share_picture?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sms_credit?: InputMaybe<Sms_Club_Sms_Credit_Order_By>;
  sport?: InputMaybe<Clubs_Sport_Order_By>;
  sport_id?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Teams_Team_Aggregate_Order_By>;
  ticket_office?: InputMaybe<Order_By>;
  tiktok_account?: InputMaybe<Order_By>;
  twitter_account?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  website?: InputMaybe<Order_By>;
  youtube_account?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clubs.club */
export type Clubs_Club_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "clubs.club" */
export enum Clubs_Club_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Acronym = 'acronym',
  /** column name */
  AlgoliaSecuredApiKey = 'algolia_secured_api_key',
  /** column name */
  ColorPrimary = 'color_primary',
  /** column name */
  ColorSecondary = 'color_secondary',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CustomerClubhouseStart = 'customer_clubhouse_start',
  /** column name */
  CustomerMonsiteclubStart = 'customer_monsiteclub_start',
  /** column name */
  CustomerPlannerStart = 'customer_planner_start',
  /** column name */
  CustomerPremiumStart = 'customer_premium_start',
  /** column name */
  CustomerTournamentStart = 'customer_tournament_start',
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Email = 'email',
  /** column name */
  FacebookPage = 'facebook_page',
  /** column name */
  GeneratedPresentation = 'generated_presentation',
  /** column name */
  Id = 'id',
  /** column name */
  InstagramAccount = 'instagram_account',
  /** column name */
  IsCustomer = 'is_customer',
  /** column name */
  LinkedinAccount = 'linkedin_account',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  NbFans = 'nb_fans',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Pilot = 'pilot',
  /** column name */
  PlannerConvocationLink = 'planner_convocation_link',
  /** column name */
  PlannerEmailCopy = 'planner_email_copy',
  /** column name */
  PlannerEmailFooterText = 'planner_email_footer_text',
  /** column name */
  Presentation = 'presentation',
  /** column name */
  ScrapingId = 'scraping_id',
  /** column name */
  SearchKeywords = 'search_keywords',
  /** column name */
  SharePicture = 'share_picture',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Slug = 'slug',
  /** column name */
  SportId = 'sport_id',
  /** column name */
  TicketOffice = 'ticket_office',
  /** column name */
  TiktokAccount = 'tiktok_account',
  /** column name */
  TwitterAccount = 'twitter_account',
  /** column name */
  Url = 'url',
  /** column name */
  Website = 'website',
  /** column name */
  YoutubeAccount = 'youtube_account',
}

/** select "clubs_club_aggregate_bool_exp_bool_and_arguments_columns" columns of table "clubs.club" */
export enum Clubs_Club_Select_Column_Clubs_Club_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Pilot = 'pilot',
}

/** select "clubs_club_aggregate_bool_exp_bool_or_arguments_columns" columns of table "clubs.club" */
export enum Clubs_Club_Select_Column_Clubs_Club_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Pilot = 'pilot',
}

/** input type for updating data in table "clubs.club" */
export type Clubs_Club_Set_Input = {
  acronym?: InputMaybe<Scalars['String']['input']>;
  color_primary?: InputMaybe<Scalars['String']['input']>;
  color_secondary?: InputMaybe<Scalars['String']['input']>;
  custom_country_id?: InputMaybe<Scalars['Int']['input']>;
  custom_department_id?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook_page?: InputMaybe<Scalars['String']['input']>;
  instagram_account?: InputMaybe<Scalars['String']['input']>;
  linkedin_account?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planner_convocation_link?: InputMaybe<Scalars['String']['input']>;
  planner_email_copy?: InputMaybe<Scalars['String']['input']>;
  planner_email_footer_text?: InputMaybe<Scalars['String']['input']>;
  presentation?: InputMaybe<Scalars['String']['input']>;
  search_keywords?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  ticket_office?: InputMaybe<Scalars['String']['input']>;
  tiktok_account?: InputMaybe<Scalars['String']['input']>;
  twitter_account?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  youtube_account?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Clubs_Club_Stddev_Fields = {
  __typename: 'clubs_club_stddev_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  nb_fans?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "clubs.club" */
export type Clubs_Club_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Clubs_Club_Stddev_Pop_Fields = {
  __typename: 'clubs_club_stddev_pop_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  nb_fans?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "clubs.club" */
export type Clubs_Club_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Clubs_Club_Stddev_Samp_Fields = {
  __typename: 'clubs_club_stddev_samp_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  nb_fans?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "clubs.club" */
export type Clubs_Club_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "clubs_club" */
export type Clubs_Club_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubs_Club_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Club_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  acronym?: InputMaybe<Scalars['String']['input']>;
  algolia_secured_api_key?: InputMaybe<Scalars['String']['input']>;
  color_primary?: InputMaybe<Scalars['String']['input']>;
  color_secondary?: InputMaybe<Scalars['String']['input']>;
  country_id?: InputMaybe<Scalars['Int']['input']>;
  customer_clubhouse_start?: InputMaybe<Scalars['date']['input']>;
  customer_monsiteclub_start?: InputMaybe<Scalars['date']['input']>;
  customer_planner_start?: InputMaybe<Scalars['date']['input']>;
  customer_premium_start?: InputMaybe<Scalars['date']['input']>;
  customer_tournament_start?: InputMaybe<Scalars['date']['input']>;
  department_id?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook_page?: InputMaybe<Scalars['String']['input']>;
  generated_presentation?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  instagram_account?: InputMaybe<Scalars['String']['input']>;
  is_customer?: InputMaybe<Scalars['date']['input']>;
  linkedin_account?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nb_fans?: InputMaybe<Scalars['smallint']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  pilot?: InputMaybe<Scalars['Boolean']['input']>;
  planner_convocation_link?: InputMaybe<Scalars['String']['input']>;
  planner_email_copy?: InputMaybe<Scalars['String']['input']>;
  planner_email_footer_text?: InputMaybe<Scalars['String']['input']>;
  presentation?: InputMaybe<Scalars['String']['input']>;
  scraping_id?: InputMaybe<Scalars['Int']['input']>;
  search_keywords?: InputMaybe<Scalars['String']['input']>;
  share_picture?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  ticket_office?: InputMaybe<Scalars['String']['input']>;
  tiktok_account?: InputMaybe<Scalars['String']['input']>;
  twitter_account?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  youtube_account?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Clubs_Club_Sum_Fields = {
  __typename: 'clubs_club_sum_fields';
  country_id?: Maybe<Scalars['Int']['output']>;
  department_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  nb_fans?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "clubs.club" */
export type Clubs_Club_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

export type Clubs_Club_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Clubs_Club_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clubs_Club_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clubs_Club_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Clubs_Club_Var_Pop_Fields = {
  __typename: 'clubs_club_var_pop_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  nb_fans?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "clubs.club" */
export type Clubs_Club_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Clubs_Club_Var_Samp_Fields = {
  __typename: 'clubs_club_var_samp_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  nb_fans?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "clubs.club" */
export type Clubs_Club_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Clubs_Club_Variance_Fields = {
  __typename: 'clubs_club_variance_fields';
  country_id?: Maybe<Scalars['Float']['output']>;
  department_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  nb_fans?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "clubs.club" */
export type Clubs_Club_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  department_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

export type Clubs_Get_Club_Args = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "clubs.organization" */
export type Clubs_Organization = {
  __typename: 'clubs_organization';
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  club_ptr_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "clubs.organization" */
export type Clubs_Organization_Aggregate_Order_By = {
  avg?: InputMaybe<Clubs_Organization_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Clubs_Organization_Max_Order_By>;
  min?: InputMaybe<Clubs_Organization_Min_Order_By>;
  stddev?: InputMaybe<Clubs_Organization_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Clubs_Organization_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Clubs_Organization_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Clubs_Organization_Sum_Order_By>;
  var_pop?: InputMaybe<Clubs_Organization_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Clubs_Organization_Var_Samp_Order_By>;
  variance?: InputMaybe<Clubs_Organization_Variance_Order_By>;
};

/** order by avg() on columns of table "clubs.organization" */
export type Clubs_Organization_Avg_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clubs.organization". All fields are combined with a logical 'AND'. */
export type Clubs_Organization_Bool_Exp = {
  _and?: InputMaybe<Array<Clubs_Organization_Bool_Exp>>;
  _not?: InputMaybe<Clubs_Organization_Bool_Exp>;
  _or?: InputMaybe<Array<Clubs_Organization_Bool_Exp>>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_ptr_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "clubs.organization" */
export type Clubs_Organization_Max_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "clubs.organization" */
export type Clubs_Organization_Min_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "clubs.organization". */
export type Clubs_Organization_Order_By = {
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_ptr_id?: InputMaybe<Order_By>;
};

/** select columns of table "clubs.organization" */
export enum Clubs_Organization_Select_Column {
  /** column name */
  ClubPtrId = 'club_ptr_id',
}

/** order by stddev() on columns of table "clubs.organization" */
export type Clubs_Organization_Stddev_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "clubs.organization" */
export type Clubs_Organization_Stddev_Pop_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "clubs.organization" */
export type Clubs_Organization_Stddev_Samp_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "clubs_organization" */
export type Clubs_Organization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubs_Organization_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Organization_Stream_Cursor_Value_Input = {
  club_ptr_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "clubs.organization" */
export type Clubs_Organization_Sum_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "clubs.organization" */
export type Clubs_Organization_Var_Pop_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "clubs.organization" */
export type Clubs_Organization_Var_Samp_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "clubs.organization" */
export type Clubs_Organization_Variance_Order_By = {
  club_ptr_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "clubs.planner_settings" */
export type Clubs_Planner_Settings = {
  __typename: 'clubs_planner_settings';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  club_id?: Maybe<Scalars['Int']['output']>;
  convocation_link?: Maybe<Scalars['String']['output']>;
  email_copy?: Maybe<Scalars['String']['output']>;
  email_footer_text?: Maybe<Scalars['String']['output']>;
  email_reply_to?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  nb_days_before_game_first_notif?: Maybe<Scalars['smallint']['output']>;
  send_email?: Maybe<Scalars['Boolean']['output']>;
  send_sms?: Maybe<Scalars['Boolean']['output']>;
  show_licence?: Maybe<Scalars['Boolean']['output']>;
};

/** Boolean expression to filter rows from the table "clubs.planner_settings". All fields are combined with a logical 'AND'. */
export type Clubs_Planner_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Clubs_Planner_Settings_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Clubs_Planner_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Clubs_Planner_Settings_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  convocation_link?: InputMaybe<String_Comparison_Exp>;
  email_copy?: InputMaybe<String_Comparison_Exp>;
  email_footer_text?: InputMaybe<String_Comparison_Exp>;
  email_reply_to?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  nb_days_before_game_first_notif?: InputMaybe<Smallint_Comparison_Exp>;
  send_email?: InputMaybe<Boolean_Comparison_Exp>;
  send_sms?: InputMaybe<Boolean_Comparison_Exp>;
  show_licence?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "clubs.planner_settings" */
export type Clubs_Planner_Settings_Inc_Input = {
  nb_days_before_game_first_notif?: InputMaybe<Scalars['smallint']['input']>;
};

/** response of any mutation on the table "clubs.planner_settings" */
export type Clubs_Planner_Settings_Mutation_Response = {
  __typename: 'clubs_planner_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Clubs_Planner_Settings>>;
};

/** Ordering options when selecting data from "clubs.planner_settings". */
export type Clubs_Planner_Settings_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_id?: InputMaybe<Order_By>;
  convocation_link?: InputMaybe<Order_By>;
  email_copy?: InputMaybe<Order_By>;
  email_footer_text?: InputMaybe<Order_By>;
  email_reply_to?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_days_before_game_first_notif?: InputMaybe<Order_By>;
  send_email?: InputMaybe<Order_By>;
  send_sms?: InputMaybe<Order_By>;
  show_licence?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clubs.planner_settings */
export type Clubs_Planner_Settings_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "clubs.planner_settings" */
export enum Clubs_Planner_Settings_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  ConvocationLink = 'convocation_link',
  /** column name */
  EmailCopy = 'email_copy',
  /** column name */
  EmailFooterText = 'email_footer_text',
  /** column name */
  EmailReplyTo = 'email_reply_to',
  /** column name */
  Id = 'id',
  /** column name */
  NbDaysBeforeGameFirstNotif = 'nb_days_before_game_first_notif',
  /** column name */
  SendEmail = 'send_email',
  /** column name */
  SendSms = 'send_sms',
  /** column name */
  ShowLicence = 'show_licence',
}

/** input type for updating data in table "clubs.planner_settings" */
export type Clubs_Planner_Settings_Set_Input = {
  convocation_link?: InputMaybe<Scalars['String']['input']>;
  email_copy?: InputMaybe<Scalars['String']['input']>;
  email_footer_text?: InputMaybe<Scalars['String']['input']>;
  email_reply_to?: InputMaybe<Scalars['String']['input']>;
  nb_days_before_game_first_notif?: InputMaybe<Scalars['smallint']['input']>;
  send_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_sms?: InputMaybe<Scalars['Boolean']['input']>;
  show_licence?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "clubs_planner_settings" */
export type Clubs_Planner_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubs_Planner_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Planner_Settings_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  convocation_link?: InputMaybe<Scalars['String']['input']>;
  email_copy?: InputMaybe<Scalars['String']['input']>;
  email_footer_text?: InputMaybe<Scalars['String']['input']>;
  email_reply_to?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  nb_days_before_game_first_notif?: InputMaybe<Scalars['smallint']['input']>;
  send_email?: InputMaybe<Scalars['Boolean']['input']>;
  send_sms?: InputMaybe<Scalars['Boolean']['input']>;
  show_licence?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Clubs_Planner_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Clubs_Planner_Settings_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clubs_Planner_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clubs_Planner_Settings_Bool_Exp;
};

/** columns and relationships of "clubs.season" */
export type Clubs_Season = {
  __typename: 'clubs_season';
  current?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  next?: Maybe<Scalars['Boolean']['output']>;
  previous?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "clubs.season". All fields are combined with a logical 'AND'. */
export type Clubs_Season_Bool_Exp = {
  _and?: InputMaybe<Array<Clubs_Season_Bool_Exp>>;
  _not?: InputMaybe<Clubs_Season_Bool_Exp>;
  _or?: InputMaybe<Array<Clubs_Season_Bool_Exp>>;
  current?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  next?: InputMaybe<Boolean_Comparison_Exp>;
  previous?: InputMaybe<Boolean_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "clubs.season". */
export type Clubs_Season_Order_By = {
  current?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  next?: InputMaybe<Order_By>;
  previous?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** select columns of table "clubs.season" */
export enum Clubs_Season_Select_Column {
  /** column name */
  Current = 'current',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Next = 'next',
  /** column name */
  Previous = 'previous',
  /** column name */
  Slug = 'slug',
}

/** Streaming cursor of the table "clubs_season" */
export type Clubs_Season_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubs_Season_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Season_Stream_Cursor_Value_Input = {
  current?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  next?: InputMaybe<Scalars['Boolean']['input']>;
  previous?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "clubs.sport" */
export type Clubs_Sport = {
  __typename: 'clubs_sport';
  /** An array relationship */
  clubs?: Maybe<Array<Clubs_Club>>;
  /** An aggregate relationship */
  clubs_aggregate?: Maybe<Clubs_Club_Aggregate>;
  emoji?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  main?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  use_in_tournaments?: Maybe<Scalars['Boolean']['output']>;
};

/** columns and relationships of "clubs.sport" */
export type Clubs_SportClubsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Order_By>>;
  where?: InputMaybe<Clubs_Club_Bool_Exp>;
};

/** columns and relationships of "clubs.sport" */
export type Clubs_SportClubs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Order_By>>;
  where?: InputMaybe<Clubs_Club_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "clubs.sport". All fields are combined with a logical 'AND'. */
export type Clubs_Sport_Bool_Exp = {
  _and?: InputMaybe<Array<Clubs_Sport_Bool_Exp>>;
  _not?: InputMaybe<Clubs_Sport_Bool_Exp>;
  _or?: InputMaybe<Array<Clubs_Sport_Bool_Exp>>;
  clubs?: InputMaybe<Clubs_Club_Bool_Exp>;
  clubs_aggregate?: InputMaybe<Clubs_Club_Aggregate_Bool_Exp>;
  emoji?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  main?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  use_in_tournaments?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "clubs.sport". */
export type Clubs_Sport_Order_By = {
  clubs_aggregate?: InputMaybe<Clubs_Club_Aggregate_Order_By>;
  emoji?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  main?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  use_in_tournaments?: InputMaybe<Order_By>;
};

/** select columns of table "clubs.sport" */
export enum Clubs_Sport_Select_Column {
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Main = 'main',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  UseInTournaments = 'use_in_tournaments',
}

/** Streaming cursor of the table "clubs_sport" */
export type Clubs_Sport_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clubs_Sport_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clubs_Sport_Stream_Cursor_Value_Input = {
  emoji?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  main?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  use_in_tournaments?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Competition_Detail_Competitions_Round_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Add_Fan_Response = {
  __typename: 'competitions_add_fan_response';
  competition_id?: Maybe<Scalars['Int']['output']>;
  fan_id?: Maybe<Scalars['Int']['output']>;
  nb_fans?: Maybe<Scalars['Int']['output']>;
};

export type Competitions_Age_Category_Response = {
  __typename: 'competitions_age_category_response';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "competitions.closest_event_with_same_teams" */
export type Competitions_Closest_Event_With_Same_Teams = {
  __typename: 'competitions_closest_event_with_same_teams';
  diff?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  event?: Maybe<Competitions_Event>;
  event_id?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.closest_event_with_same_teams". All fields are combined with a logical 'AND'. */
export type Competitions_Closest_Event_With_Same_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Closest_Event_With_Same_Teams_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Closest_Event_With_Same_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Closest_Event_With_Same_Teams_Bool_Exp>>;
  diff?: InputMaybe<Int_Comparison_Exp>;
  event?: InputMaybe<Competitions_Event_Bool_Exp>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  event_with_same_teams_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitions.closest_event_with_same_teams". */
export type Competitions_Closest_Event_With_Same_Teams_Order_By = {
  diff?: InputMaybe<Order_By>;
  event?: InputMaybe<Competitions_Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  event_with_same_teams_id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.closest_event_with_same_teams" */
export enum Competitions_Closest_Event_With_Same_Teams_Select_Column {
  /** column name */
  Diff = 'diff',
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventWithSameTeamsId = 'event_with_same_teams_id',
}

/** Streaming cursor of the table "competitions_closest_event_with_same_teams" */
export type Competitions_Closest_Event_With_Same_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Closest_Event_With_Same_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Closest_Event_With_Same_Teams_Stream_Cursor_Value_Input =
  {
    diff?: InputMaybe<Scalars['Int']['input']>;
    event_id?: InputMaybe<Scalars['Int']['input']>;
    event_with_same_teams_id?: InputMaybe<Scalars['Int']['input']>;
  };

/** columns and relationships of "competitions.competition" */
export type Competitions_Competition = {
  __typename: 'competitions_competition';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  added_by_user?: Maybe<Scalars['Boolean']['output']>;
  /** Journee la plus proche */
  current_round?: Maybe<Array<Competitions_Round>>;
  /** A computed field, executes function "competitions.competition_detail" */
  detail?: Maybe<Array<Competitions_Competition_Detail>>;
  fede_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  level_for_season?: Maybe<Competitions_Level_For_Season>;
  level_in_season_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nb_fans?: Maybe<Scalars['smallint']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  pool?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  /** A computed field, executes function "competitions.ranking_by_competition_id" */
  rankings?: Maybe<Array<Competitions_Ranking_Detail>>;
  /** An array relationship */
  rounds?: Maybe<Array<Competitions_Round>>;
  /** An aggregate relationship */
  rounds_aggregate?: Maybe<Competitions_Round_Aggregate>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  statistics?: Maybe<Competitions_Competition_Statistics>;
  /** An array relationship */
  teams?: Maybe<Array<Competitions_Competition_Has_Teams>>;
  to_delete?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "competitions.competition" */
export type Competitions_CompetitionCurrent_RoundArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

/** columns and relationships of "competitions.competition" */
export type Competitions_CompetitionDetailArgs = {
  args?: InputMaybe<Detail_Competitions_Competition_Args>;
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

/** columns and relationships of "competitions.competition" */
export type Competitions_CompetitionRankingsArgs = {
  args?: InputMaybe<Rankings_Competitions_Competition_Args>;
  distinct_on?: InputMaybe<Array<Competitions_Ranking_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Ranking_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Ranking_Detail_Bool_Exp>;
};

/** columns and relationships of "competitions.competition" */
export type Competitions_CompetitionRoundsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

/** columns and relationships of "competitions.competition" */
export type Competitions_CompetitionRounds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

/** columns and relationships of "competitions.competition" */
export type Competitions_CompetitionTeamsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Has_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Has_Teams_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
};

/** order by aggregate values of table "competitions.competition" */
export type Competitions_Competition_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Competition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Competition_Max_Order_By>;
  min?: InputMaybe<Competitions_Competition_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Competition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Competition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Competition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Competition_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Competition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Competition_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Competition_Variance_Order_By>;
};

/** order by avg() on columns of table "competitions.competition" */
export type Competitions_Competition_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.competition". All fields are combined with a logical 'AND'. */
export type Competitions_Competition_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Competition_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Competition_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Competition_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  added_by_user?: InputMaybe<Boolean_Comparison_Exp>;
  current_round?: InputMaybe<Competitions_Round_Bool_Exp>;
  fede_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  level_for_season?: InputMaybe<Competitions_Level_For_Season_Bool_Exp>;
  level_in_season_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_fans?: InputMaybe<Smallint_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  phase?: InputMaybe<String_Comparison_Exp>;
  pool?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  rounds?: InputMaybe<Competitions_Round_Bool_Exp>;
  rounds_aggregate?: InputMaybe<Competitions_Round_Aggregate_Bool_Exp>;
  scraping_id?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  statistics?: InputMaybe<Competitions_Competition_Statistics_Bool_Exp>;
  teams?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
  to_delete?: InputMaybe<Boolean_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "competitions.competition_detail" */
export type Competitions_Competition_Detail = {
  __typename: 'competitions_competition_detail';
  age_category?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  competition?: Maybe<Competitions_Competition>;
  family?: Maybe<Scalars['String']['output']>;
  fede_url?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  league?: Maybe<Competitions_League>;
  league_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  level?: Maybe<Competitions_Level>;
  level_id?: Maybe<Scalars['Int']['output']>;
  level_in_season_id?: Maybe<Scalars['Int']['output']>;
  level_slug?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  rankings?: Maybe<Array<Competitions_Ranking_Detail>>;
  /** An array relationship */
  rounds?: Maybe<Array<Competitions_Round>>;
  /** An aggregate relationship */
  rounds_aggregate?: Maybe<Competitions_Round_Aggregate>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  sport?: Maybe<Clubs_Sport>;
  sport_id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "competitions.competition_detail" */
export type Competitions_Competition_DetailRankingsArgs = {
  args?: InputMaybe<Rankings_Competitions_Competition_Detail_Args>;
  distinct_on?: InputMaybe<Array<Competitions_Ranking_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Ranking_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Ranking_Detail_Bool_Exp>;
};

/** columns and relationships of "competitions.competition_detail" */
export type Competitions_Competition_DetailRoundsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

/** columns and relationships of "competitions.competition_detail" */
export type Competitions_Competition_DetailRounds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "competitions.competition_detail". All fields are combined with a logical 'AND'. */
export type Competitions_Competition_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Competition_Detail_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Competition_Detail_Bool_Exp>>;
  age_category?: InputMaybe<String_Comparison_Exp>;
  competition?: InputMaybe<Competitions_Competition_Bool_Exp>;
  family?: InputMaybe<String_Comparison_Exp>;
  fede_url?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  league?: InputMaybe<Competitions_League_Bool_Exp>;
  league_id?: InputMaybe<Int_Comparison_Exp>;
  level?: InputMaybe<Competitions_Level_Bool_Exp>;
  level_id?: InputMaybe<Int_Comparison_Exp>;
  level_in_season_id?: InputMaybe<Int_Comparison_Exp>;
  level_slug?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  rounds?: InputMaybe<Competitions_Round_Bool_Exp>;
  rounds_aggregate?: InputMaybe<Competitions_Round_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sport?: InputMaybe<Clubs_Sport_Bool_Exp>;
  sport_id?: InputMaybe<Int_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

export type Competitions_Competition_Detail_By_Id_Args = {
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Competition_Detail_By_Level_For_Season_Id_Args = {
  level_for_season_id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Competition_Detail_By_Level_Id_Args = {
  level_id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options when selecting data from "competitions.competition_detail". */
export type Competitions_Competition_Detail_Order_By = {
  age_category?: InputMaybe<Order_By>;
  competition?: InputMaybe<Competitions_Competition_Order_By>;
  family?: InputMaybe<Order_By>;
  fede_url?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  league?: InputMaybe<Competitions_League_Order_By>;
  league_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Competitions_Level_Order_By>;
  level_id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  level_slug?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rounds_aggregate?: InputMaybe<Competitions_Round_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  sport?: InputMaybe<Clubs_Sport_Order_By>;
  sport_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.competition_detail" */
export enum Competitions_Competition_Detail_Select_Column {
  /** column name */
  AgeCategory = 'age_category',
  /** column name */
  Family = 'family',
  /** column name */
  FedeUrl = 'fede_url',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LeagueId = 'league_id',
  /** column name */
  LevelId = 'level_id',
  /** column name */
  LevelInSeasonId = 'level_in_season_id',
  /** column name */
  LevelSlug = 'level_slug',
  /** column name */
  Name = 'name',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Slug = 'slug',
  /** column name */
  SportId = 'sport_id',
  /** column name */
  Url = 'url',
}

/** Streaming cursor of the table "competitions_competition_detail" */
export type Competitions_Competition_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Competition_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Competition_Detail_Stream_Cursor_Value_Input = {
  age_category?: InputMaybe<Scalars['String']['input']>;
  family?: InputMaybe<Scalars['String']['input']>;
  fede_url?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  league_id?: InputMaybe<Scalars['Int']['input']>;
  level_id?: InputMaybe<Scalars['Int']['input']>;
  level_in_season_id?: InputMaybe<Scalars['Int']['input']>;
  level_slug?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "competitions.competition_has_fans" */
export type Competitions_Competition_Has_Fans = {
  __typename: 'competitions_competition_has_fans';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  competition?: Maybe<Competitions_Competition>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  fan?: Maybe<Competitions_Fan>;
  fan_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "competitions.competition_has_fans". All fields are combined with a logical 'AND'. */
export type Competitions_Competition_Has_Fans_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Competition_Has_Fans_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Competition_Has_Fans_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Competition_Has_Fans_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  competition?: InputMaybe<Competitions_Competition_Bool_Exp>;
  competition_id?: InputMaybe<Int_Comparison_Exp>;
  fan?: InputMaybe<Competitions_Fan_Bool_Exp>;
  fan_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitions.competition_has_fans". */
export type Competitions_Competition_Has_Fans_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition?: InputMaybe<Competitions_Competition_Order_By>;
  competition_id?: InputMaybe<Order_By>;
  fan?: InputMaybe<Competitions_Fan_Order_By>;
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.competition_has_fans" */
export enum Competitions_Competition_Has_Fans_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  FanId = 'fan_id',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "competitions_competition_has_fans" */
export type Competitions_Competition_Has_Fans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Competition_Has_Fans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Competition_Has_Fans_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  competition_id?: InputMaybe<Scalars['Int']['input']>;
  fan_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams = {
  __typename: 'competitions_competition_has_teams';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  auto_team_in_season?: Maybe<Teams_Team_In_Season>;
  /** An object relationship */
  competition?: Maybe<Competitions_Competition>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  ranking?: Maybe<Competitions_Ranking>;
  /** An object relationship */
  team_in_season?: Maybe<Teams_Team_In_Season>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Competition_Has_Teams_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Competition_Has_Teams_Max_Order_By>;
  min?: InputMaybe<Competitions_Competition_Has_Teams_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Competition_Has_Teams_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Competition_Has_Teams_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Competition_Has_Teams_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Competition_Has_Teams_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Competition_Has_Teams_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Competition_Has_Teams_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Competition_Has_Teams_Variance_Order_By>;
};

/** order by avg() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Avg_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.competition_has_teams". All fields are combined with a logical 'AND'. */
export type Competitions_Competition_Has_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Competition_Has_Teams_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Competition_Has_Teams_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  auto_team_in_season?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  competition?: InputMaybe<Competitions_Competition_Bool_Exp>;
  competition_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ranking?: InputMaybe<Competitions_Ranking_Bool_Exp>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  team_in_season_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Inc_Input = {
  custom_team_in_season_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Mutation_Response = {
  __typename: 'competitions_competition_has_teams_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Competitions_Competition_Has_Teams>>;
};

/** Ordering options when selecting data from "competitions.competition_has_teams". */
export type Competitions_Competition_Has_Teams_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  auto_team_in_season?: InputMaybe<Teams_Team_In_Season_Order_By>;
  competition?: InputMaybe<Competitions_Competition_Order_By>;
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ranking?: InputMaybe<Competitions_Ranking_Order_By>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions.competition_has_teams */
export type Competitions_Competition_Has_Teams_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "competitions.competition_has_teams" */
export enum Competitions_Competition_Has_Teams_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamInSeasonId = 'team_in_season_id',
}

/** input type for updating data in table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Set_Input = {
  custom_team_in_season_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Stddev_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Stddev_Pop_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Stddev_Samp_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_competition_has_teams" */
export type Competitions_Competition_Has_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Competition_Has_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Competition_Has_Teams_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  competition_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  team_in_season_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Sum_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

export type Competitions_Competition_Has_Teams_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Competitions_Competition_Has_Teams_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Competitions_Competition_Has_Teams_Set_Input>;
  /** filter the rows which have to be updated */
  where: Competitions_Competition_Has_Teams_Bool_Exp;
};

/** order by var_pop() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Var_Pop_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Var_Samp_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.competition_has_teams" */
export type Competitions_Competition_Has_Teams_Variance_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by max() on columns of table "competitions.competition" */
export type Competitions_Competition_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  fede_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  phase?: InputMaybe<Order_By>;
  pool?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.competition" */
export type Competitions_Competition_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  fede_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  phase?: InputMaybe<Order_By>;
  pool?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions.competition". */
export type Competitions_Competition_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  added_by_user?: InputMaybe<Order_By>;
  current_round_aggregate?: InputMaybe<Competitions_Round_Aggregate_Order_By>;
  fede_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_for_season?: InputMaybe<Competitions_Level_For_Season_Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  phase?: InputMaybe<Order_By>;
  pool?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rounds_aggregate?: InputMaybe<Competitions_Round_Aggregate_Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  statistics?: InputMaybe<Competitions_Competition_Statistics_Order_By>;
  teams_aggregate?: InputMaybe<Competitions_Competition_Has_Teams_Aggregate_Order_By>;
  to_delete?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.competition" */
export enum Competitions_Competition_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AddedByUser = 'added_by_user',
  /** column name */
  FedeUrl = 'fede_url',
  /** column name */
  Id = 'id',
  /** column name */
  LevelInSeasonId = 'level_in_season_id',
  /** column name */
  Name = 'name',
  /** column name */
  NbFans = 'nb_fans',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Phase = 'phase',
  /** column name */
  Pool = 'pool',
  /** column name */
  Rank = 'rank',
  /** column name */
  ScrapingId = 'scraping_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  ToDelete = 'to_delete',
  /** column name */
  Url = 'url',
}

/** columns and relationships of "competitions.competition_statistics" */
export type Competitions_Competition_Statistics = {
  __typename: 'competitions_competition_statistics';
  competition_id?: Maybe<Scalars['Int']['output']>;
  nb_events?: Maybe<Scalars['bigint']['output']>;
  nb_events_without_score?: Maybe<Scalars['bigint']['output']>;
  nb_rounds?: Maybe<Scalars['bigint']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.competition_statistics". All fields are combined with a logical 'AND'. */
export type Competitions_Competition_Statistics_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Competition_Statistics_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Competition_Statistics_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Competition_Statistics_Bool_Exp>>;
  competition_id?: InputMaybe<Int_Comparison_Exp>;
  nb_events?: InputMaybe<Bigint_Comparison_Exp>;
  nb_events_without_score?: InputMaybe<Bigint_Comparison_Exp>;
  nb_rounds?: InputMaybe<Bigint_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitions.competition_statistics". */
export type Competitions_Competition_Statistics_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  nb_events?: InputMaybe<Order_By>;
  nb_events_without_score?: InputMaybe<Order_By>;
  nb_rounds?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.competition_statistics" */
export enum Competitions_Competition_Statistics_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  NbEvents = 'nb_events',
  /** column name */
  NbEventsWithoutScore = 'nb_events_without_score',
  /** column name */
  NbRounds = 'nb_rounds',
}

/** Streaming cursor of the table "competitions_competition_statistics" */
export type Competitions_Competition_Statistics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Competition_Statistics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Competition_Statistics_Stream_Cursor_Value_Input = {
  competition_id?: InputMaybe<Scalars['Int']['input']>;
  nb_events?: InputMaybe<Scalars['bigint']['input']>;
  nb_events_without_score?: InputMaybe<Scalars['bigint']['input']>;
  nb_rounds?: InputMaybe<Scalars['bigint']['input']>;
};

/** order by stddev() on columns of table "competitions.competition" */
export type Competitions_Competition_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.competition" */
export type Competitions_Competition_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.competition" */
export type Competitions_Competition_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_competition" */
export type Competitions_Competition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Competition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Competition_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  added_by_user?: InputMaybe<Scalars['Boolean']['input']>;
  fede_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  level_in_season_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nb_fans?: InputMaybe<Scalars['smallint']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  pool?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  scraping_id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  to_delete?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "competitions.competition" */
export type Competitions_Competition_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "competitions.competition" */
export type Competitions_Competition_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.competition" */
export type Competitions_Competition_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.competition" */
export type Competitions_Competition_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

export type Competitions_Delete_Fan_Response = {
  __typename: 'competitions_delete_fan_response';
  competition_id?: Maybe<Scalars['Int']['output']>;
  nb_fans?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "competitions.event" */
export type Competitions_Event = {
  __typename: 'competitions_event';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  closest_date?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  demo?: Maybe<Scalars['Boolean']['output']>;
  /** A computed field, executes function "competitions.event_detail" */
  detail?: Maybe<Array<Competitions_Event_Detail>>;
  /** An object relationship */
  event_with_same_team?: Maybe<Competitions_Event_With_Same_Teams>;
  event_with_same_teams_id?: Maybe<Scalars['Int']['output']>;
  game_sheet_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  /** An object relationship */
  live_hit_counts?: Maybe<Lives_Live_Hit_Count>;
  /** An array relationship */
  live_hits?: Maybe<Array<Lives_Live_Hit>>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  place?: Maybe<Places_Place>;
  place_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  playing_teams?: Maybe<Array<Competitions_Playing_Team>>;
  /** An aggregate relationship */
  playing_teams_aggregate?: Maybe<Competitions_Playing_Team_Aggregate>;
  /** An array relationship */
  referees?: Maybe<Array<Competitions_Event_Referee>>;
  /** An object relationship */
  round?: Maybe<Competitions_Round>;
  round_day_id?: Maybe<Scalars['Int']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  share_image_url?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  to_delete?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  tournament_game?: Maybe<Tournaments_Game>;
};

/** columns and relationships of "competitions.event" */
export type Competitions_EventDetailArgs = {
  args?: InputMaybe<Detail_Competitions_Event_Args>;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

/** columns and relationships of "competitions.event" */
export type Competitions_EventLive_HitsArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Hit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Hit_Order_By>>;
  where?: InputMaybe<Lives_Live_Hit_Bool_Exp>;
};

/** columns and relationships of "competitions.event" */
export type Competitions_EventPlaying_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

/** columns and relationships of "competitions.event" */
export type Competitions_EventPlaying_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

/** columns and relationships of "competitions.event" */
export type Competitions_EventRefereesArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Referee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Referee_Order_By>>;
  where?: InputMaybe<Competitions_Event_Referee_Bool_Exp>;
};

/** aggregated selection of "competitions.event" */
export type Competitions_Event_Aggregate = {
  __typename: 'competitions_event_aggregate';
  aggregate?: Maybe<Competitions_Event_Aggregate_Fields>;
  nodes?: Maybe<Array<Competitions_Event>>;
};

export type Competitions_Event_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Competitions_Event_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Competitions_Event_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Competitions_Event_Aggregate_Bool_Exp_Count>;
};

export type Competitions_Event_Aggregate_Bool_Exp_Bool_And = {
  arguments: Competitions_Event_Select_Column_Competitions_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Competitions_Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Competitions_Event_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Competitions_Event_Select_Column_Competitions_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Competitions_Event_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Competitions_Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Competitions_Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "competitions.event" */
export type Competitions_Event_Aggregate_Fields = {
  __typename: 'competitions_event_aggregate_fields';
  avg?: Maybe<Competitions_Event_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Competitions_Event_Max_Fields>;
  min?: Maybe<Competitions_Event_Min_Fields>;
  stddev?: Maybe<Competitions_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Competitions_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Competitions_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Competitions_Event_Sum_Fields>;
  var_pop?: Maybe<Competitions_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Competitions_Event_Var_Samp_Fields>;
  variance?: Maybe<Competitions_Event_Variance_Fields>;
};

/** aggregate fields of "competitions.event" */
export type Competitions_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions.event" */
export type Competitions_Event_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Event_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Event_Max_Order_By>;
  min?: InputMaybe<Competitions_Event_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Event_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Event_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Event_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Event_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Event_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Event_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Event_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Competitions_Event_Avg_Fields = {
  __typename: 'competitions_event_avg_fields';
  closest_date?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  place_id?: Maybe<Scalars['Float']['output']>;
  round_day_id?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
  to_delete?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "competitions.event" */
export type Competitions_Event_Avg_Order_By = {
  event_with_same_teams_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.event". All fields are combined with a logical 'AND'. */
export type Competitions_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Event_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Event_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  closest_date?: InputMaybe<Int_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  demo?: InputMaybe<Boolean_Comparison_Exp>;
  event_with_same_team?: InputMaybe<Competitions_Event_With_Same_Teams_Bool_Exp>;
  event_with_same_teams_id?: InputMaybe<Int_Comparison_Exp>;
  game_sheet_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_hit_counts?: InputMaybe<Lives_Live_Hit_Count_Bool_Exp>;
  live_hits?: InputMaybe<Lives_Live_Hit_Bool_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  place?: InputMaybe<Places_Place_Bool_Exp>;
  place_id?: InputMaybe<Int_Comparison_Exp>;
  playing_teams?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
  playing_teams_aggregate?: InputMaybe<Competitions_Playing_Team_Aggregate_Bool_Exp>;
  referees?: InputMaybe<Competitions_Event_Referee_Bool_Exp>;
  round?: InputMaybe<Competitions_Round_Bool_Exp>;
  round_day_id?: InputMaybe<Int_Comparison_Exp>;
  scraping_id?: InputMaybe<Int_Comparison_Exp>;
  share_image_url?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  time?: InputMaybe<Timestamptz_Comparison_Exp>;
  to_delete?: InputMaybe<Smallint_Comparison_Exp>;
  tournament_game?: InputMaybe<Tournaments_Game_Bool_Exp>;
};

/** columns and relationships of "competitions.event_closest_date" */
export type Competitions_Event_Closest_Date = {
  __typename: 'competitions_event_closest_date';
  competition_id?: Maybe<Scalars['Int']['output']>;
  diff?: Maybe<Scalars['Int']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  round?: Maybe<Competitions_Round>;
  round_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.event_closest_date". All fields are combined with a logical 'AND'. */
export type Competitions_Event_Closest_Date_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Event_Closest_Date_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Event_Closest_Date_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Event_Closest_Date_Bool_Exp>>;
  competition_id?: InputMaybe<Int_Comparison_Exp>;
  diff?: InputMaybe<Int_Comparison_Exp>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  round?: InputMaybe<Competitions_Round_Bool_Exp>;
  round_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitions.event_closest_date". */
export type Competitions_Event_Closest_Date_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  diff?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  round?: InputMaybe<Competitions_Round_Order_By>;
  round_id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.event_closest_date" */
export enum Competitions_Event_Closest_Date_Select_Column {
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  Diff = 'diff',
  /** column name */
  EventId = 'event_id',
  /** column name */
  RoundId = 'round_id',
}

/** Streaming cursor of the table "competitions_event_closest_date" */
export type Competitions_Event_Closest_Date_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Event_Closest_Date_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Event_Closest_Date_Stream_Cursor_Value_Input = {
  competition_id?: InputMaybe<Scalars['Int']['input']>;
  diff?: InputMaybe<Scalars['Int']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  round_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "competitions.event_detail" */
export type Competitions_Event_Detail = {
  __typename: 'competitions_event_detail';
  age_category?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  competition?: Maybe<Competitions_Competition>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  event?: Maybe<Competitions_Event>;
  family?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  level_in_season_id?: Maybe<Scalars['Int']['output']>;
  level_name?: Maybe<Scalars['String']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  place?: Maybe<Places_Place>;
  place_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  playing_teams?: Maybe<Array<Competitions_Playing_Team>>;
  /** An aggregate relationship */
  playing_teams_aggregate?: Maybe<Competitions_Playing_Team_Aggregate>;
  /** An object relationship */
  round?: Maybe<Competitions_Round>;
  round_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  sport?: Maybe<Clubs_Sport>;
  sport_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  teams?: Maybe<Scalars['jsonb']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "competitions.event_detail" */
export type Competitions_Event_DetailPlaying_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

/** columns and relationships of "competitions.event_detail" */
export type Competitions_Event_DetailPlaying_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

/** columns and relationships of "competitions.event_detail" */
export type Competitions_Event_DetailTeamsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "competitions.event_detail" */
export type Competitions_Event_Detail_Aggregate = {
  __typename: 'competitions_event_detail_aggregate';
  aggregate?: Maybe<Competitions_Event_Detail_Aggregate_Fields>;
  nodes?: Maybe<Array<Competitions_Event_Detail>>;
};

/** aggregate fields of "competitions.event_detail" */
export type Competitions_Event_Detail_Aggregate_Fields = {
  __typename: 'competitions_event_detail_aggregate_fields';
  avg?: Maybe<Competitions_Event_Detail_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Competitions_Event_Detail_Max_Fields>;
  min?: Maybe<Competitions_Event_Detail_Min_Fields>;
  stddev?: Maybe<Competitions_Event_Detail_Stddev_Fields>;
  stddev_pop?: Maybe<Competitions_Event_Detail_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Competitions_Event_Detail_Stddev_Samp_Fields>;
  sum?: Maybe<Competitions_Event_Detail_Sum_Fields>;
  var_pop?: Maybe<Competitions_Event_Detail_Var_Pop_Fields>;
  var_samp?: Maybe<Competitions_Event_Detail_Var_Samp_Fields>;
  variance?: Maybe<Competitions_Event_Detail_Variance_Fields>;
};

/** aggregate fields of "competitions.event_detail" */
export type Competitions_Event_Detail_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions.event_detail" */
export type Competitions_Event_Detail_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Event_Detail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Event_Detail_Max_Order_By>;
  min?: InputMaybe<Competitions_Event_Detail_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Event_Detail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Event_Detail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Event_Detail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Event_Detail_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Event_Detail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Event_Detail_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Event_Detail_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Competitions_Event_Detail_Avg_Fields = {
  __typename: 'competitions_event_detail_avg_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  level_in_season_id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  round_id?: Maybe<Scalars['Float']['output']>;
  sport_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Avg_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.event_detail". All fields are combined with a logical 'AND'. */
export type Competitions_Event_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Event_Detail_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Event_Detail_Bool_Exp>>;
  age_category?: InputMaybe<String_Comparison_Exp>;
  competition?: InputMaybe<Competitions_Competition_Bool_Exp>;
  competition_id?: InputMaybe<Int_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  event?: InputMaybe<Competitions_Event_Bool_Exp>;
  family?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  level_in_season_id?: InputMaybe<Int_Comparison_Exp>;
  level_name?: InputMaybe<String_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  place?: InputMaybe<Places_Place_Bool_Exp>;
  place_id?: InputMaybe<Int_Comparison_Exp>;
  playing_teams?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
  playing_teams_aggregate?: InputMaybe<Competitions_Playing_Team_Aggregate_Bool_Exp>;
  round?: InputMaybe<Competitions_Round_Bool_Exp>;
  round_id?: InputMaybe<Int_Comparison_Exp>;
  sport?: InputMaybe<Clubs_Sport_Bool_Exp>;
  sport_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  teams?: InputMaybe<Jsonb_Comparison_Exp>;
  time?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

export type Competitions_Event_Detail_By_Club_Id_Args = {
  date_filter?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Event_Detail_By_Club_Id_Between_Dates_Args = {
  date_end?: InputMaybe<Scalars['timestamptz']['input']>;
  date_start?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  use_equipment_sort?: InputMaybe<Scalars['Boolean']['input']>;
  use_home_away_sort?: InputMaybe<Scalars['Boolean']['input']>;
  use_planner_info?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Competitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_Rank_Args =
  {
    date_end?: InputMaybe<Scalars['timestamptz']['input']>;
    date_start?: InputMaybe<Scalars['timestamptz']['input']>;
    id?: InputMaybe<Scalars['Int']['input']>;
    locale?: InputMaybe<Scalars['String']['input']>;
    use_planner_info?: InputMaybe<Scalars['Boolean']['input']>;
  };

export type Competitions_Event_Detail_By_Id_Args = {
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Event_Detail_By_Live_Rating_And_Sport_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
  query_limit?: InputMaybe<Scalars['Int']['input']>;
  sport_slug?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Event_Detail_By_Live_Rating_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
  query_limit?: InputMaybe<Scalars['Int']['input']>;
};

export type Competitions_Event_Detail_By_Round_Day_Id_Args = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  round_day_id?: InputMaybe<Scalars['Int']['input']>;
};

export type Competitions_Event_Detail_By_Team_Id_Args = {
  date_filter?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Event_Detail_By_Team_In_Season_Id_Args = {
  date_filter?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Event_Detail_By_User_Favorite_Teams_Args = {
  date_filter?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Competitions_Event_Detail_Max_Fields = {
  __typename: 'competitions_event_detail_max_fields';
  age_category?: Maybe<Scalars['String']['output']>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  level_in_season_id?: Maybe<Scalars['Int']['output']>;
  level_name?: Maybe<Scalars['String']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['Int']['output']>;
  round_id?: Maybe<Scalars['Int']['output']>;
  sport_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Max_Order_By = {
  age_category?: InputMaybe<Order_By>;
  competition_id?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  family?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  level_name?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Event_Detail_Min_Fields = {
  __typename: 'competitions_event_detail_min_fields';
  age_category?: Maybe<Scalars['String']['output']>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  level_in_season_id?: Maybe<Scalars['Int']['output']>;
  level_name?: Maybe<Scalars['String']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['Int']['output']>;
  round_id?: Maybe<Scalars['Int']['output']>;
  sport_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Min_Order_By = {
  age_category?: InputMaybe<Order_By>;
  competition_id?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  family?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  level_name?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions.event_detail". */
export type Competitions_Event_Detail_Order_By = {
  age_category?: InputMaybe<Order_By>;
  competition?: InputMaybe<Competitions_Competition_Order_By>;
  competition_id?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event?: InputMaybe<Competitions_Event_Order_By>;
  family?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  level_name?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  place?: InputMaybe<Places_Place_Order_By>;
  place_id?: InputMaybe<Order_By>;
  playing_teams_aggregate?: InputMaybe<Competitions_Playing_Team_Aggregate_Order_By>;
  round?: InputMaybe<Competitions_Round_Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport?: InputMaybe<Clubs_Sport_Order_By>;
  sport_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  teams?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.event_detail" */
export enum Competitions_Event_Detail_Select_Column {
  /** column name */
  AgeCategory = 'age_category',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  Date = 'date',
  /** column name */
  Family = 'family',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LevelInSeasonId = 'level_in_season_id',
  /** column name */
  LevelName = 'level_name',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  RoundId = 'round_id',
  /** column name */
  SportId = 'sport_id',
  /** column name */
  Status = 'status',
  /** column name */
  Teams = 'teams',
  /** column name */
  Time = 'time',
  /** column name */
  Url = 'url',
}

/** aggregate stddev on columns */
export type Competitions_Event_Detail_Stddev_Fields = {
  __typename: 'competitions_event_detail_stddev_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  level_in_season_id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  round_id?: Maybe<Scalars['Float']['output']>;
  sport_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Stddev_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Competitions_Event_Detail_Stddev_Pop_Fields = {
  __typename: 'competitions_event_detail_stddev_pop_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  level_in_season_id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  round_id?: Maybe<Scalars['Float']['output']>;
  sport_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Stddev_Pop_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Competitions_Event_Detail_Stddev_Samp_Fields = {
  __typename: 'competitions_event_detail_stddev_samp_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  level_in_season_id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  round_id?: Maybe<Scalars['Float']['output']>;
  sport_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Stddev_Samp_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_event_detail" */
export type Competitions_Event_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Event_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Event_Detail_Stream_Cursor_Value_Input = {
  age_category?: InputMaybe<Scalars['String']['input']>;
  competition_id?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  family?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  level_in_season_id?: InputMaybe<Scalars['Int']['input']>;
  level_name?: InputMaybe<Scalars['String']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['Int']['input']>;
  round_id?: InputMaybe<Scalars['Int']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Scalars['jsonb']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Competitions_Event_Detail_Sum_Fields = {
  __typename: 'competitions_event_detail_sum_fields';
  competition_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  level_in_season_id?: Maybe<Scalars['Int']['output']>;
  place_id?: Maybe<Scalars['Int']['output']>;
  round_id?: Maybe<Scalars['Int']['output']>;
  sport_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Sum_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Competitions_Event_Detail_Var_Pop_Fields = {
  __typename: 'competitions_event_detail_var_pop_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  level_in_season_id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  round_id?: Maybe<Scalars['Float']['output']>;
  sport_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Var_Pop_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Competitions_Event_Detail_Var_Samp_Fields = {
  __typename: 'competitions_event_detail_var_samp_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  level_in_season_id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  round_id?: Maybe<Scalars['Float']['output']>;
  sport_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Var_Samp_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Competitions_Event_Detail_Variance_Fields = {
  __typename: 'competitions_event_detail_variance_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  level_in_season_id?: Maybe<Scalars['Float']['output']>;
  place_id?: Maybe<Scalars['Float']['output']>;
  round_id?: Maybe<Scalars['Float']['output']>;
  sport_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "competitions.event_detail" */
export type Competitions_Event_Detail_Variance_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_in_season_id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "competitions.event" */
export type Competitions_Event_Inc_Input = {
  place_id?: InputMaybe<Scalars['Int']['input']>;
  to_delete?: InputMaybe<Scalars['smallint']['input']>;
};

/** columns and relationships of "competitions.event_last_update" */
export type Competitions_Event_Last_Update = {
  __typename: 'competitions_event_last_update';
  /** An object relationship */
  event?: Maybe<Competitions_Event>;
  event_id?: Maybe<Scalars['Int']['output']>;
  last_score_update?: Maybe<Scalars['timestamptz']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.event_last_update". All fields are combined with a logical 'AND'. */
export type Competitions_Event_Last_Update_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Event_Last_Update_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Event_Last_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Event_Last_Update_Bool_Exp>>;
  event?: InputMaybe<Competitions_Event_Bool_Exp>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  last_score_update?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitions.event_last_update". */
export type Competitions_Event_Last_Update_Order_By = {
  event?: InputMaybe<Competitions_Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  last_score_update?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.event_last_update" */
export enum Competitions_Event_Last_Update_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  LastScoreUpdate = 'last_score_update',
}

/** Streaming cursor of the table "competitions_event_last_update" */
export type Competitions_Event_Last_Update_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Event_Last_Update_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Event_Last_Update_Stream_Cursor_Value_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
  last_score_update?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Competitions_Event_Max_Fields = {
  __typename: 'competitions_event_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  closest_date?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Int']['output']>;
  game_sheet_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['Int']['output']>;
  round_day_id?: Maybe<Scalars['Int']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  share_image_url?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  to_delete?: Maybe<Scalars['smallint']['output']>;
};

/** order by max() on columns of table "competitions.event" */
export type Competitions_Event_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_with_same_teams_id?: InputMaybe<Order_By>;
  game_sheet_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  share_image_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Event_Min_Fields = {
  __typename: 'competitions_event_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  closest_date?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Int']['output']>;
  game_sheet_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  place_id?: Maybe<Scalars['Int']['output']>;
  round_day_id?: Maybe<Scalars['Int']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  share_image_url?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  to_delete?: Maybe<Scalars['smallint']['output']>;
};

/** order by min() on columns of table "competitions.event" */
export type Competitions_Event_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_with_same_teams_id?: InputMaybe<Order_By>;
  game_sheet_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  share_image_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions.event" */
export type Competitions_Event_Mutation_Response = {
  __typename: 'competitions_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Competitions_Event>>;
};

/** Ordering options when selecting data from "competitions.event". */
export type Competitions_Event_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  closest_date?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  demo?: InputMaybe<Order_By>;
  event_with_same_team?: InputMaybe<Competitions_Event_With_Same_Teams_Order_By>;
  event_with_same_teams_id?: InputMaybe<Order_By>;
  game_sheet_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  live_hit_counts?: InputMaybe<Lives_Live_Hit_Count_Order_By>;
  live_hits_aggregate?: InputMaybe<Lives_Live_Hit_Aggregate_Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  place?: InputMaybe<Places_Place_Order_By>;
  place_id?: InputMaybe<Order_By>;
  playing_teams_aggregate?: InputMaybe<Competitions_Playing_Team_Aggregate_Order_By>;
  referees_aggregate?: InputMaybe<Competitions_Event_Referee_Aggregate_Order_By>;
  round?: InputMaybe<Competitions_Round_Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  share_image_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
  tournament_game?: InputMaybe<Tournaments_Game_Order_By>;
};

/** primary key columns input for table: competitions.event */
export type Competitions_Event_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "competitions.event_referee" */
export type Competitions_Event_Referee = {
  __typename: 'competitions_event_referee';
  /** An object relationship */
  event?: Maybe<Competitions_Event>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  referee_type?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "competitions.event_referee" */
export type Competitions_Event_Referee_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Event_Referee_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Event_Referee_Max_Order_By>;
  min?: InputMaybe<Competitions_Event_Referee_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Event_Referee_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Event_Referee_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Event_Referee_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Event_Referee_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Event_Referee_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Event_Referee_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Event_Referee_Variance_Order_By>;
};

/** order by avg() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Avg_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.event_referee". All fields are combined with a logical 'AND'. */
export type Competitions_Event_Referee_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Event_Referee_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Event_Referee_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Event_Referee_Bool_Exp>>;
  event?: InputMaybe<Competitions_Event_Bool_Exp>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  referee_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "competitions.event_referee" */
export enum Competitions_Event_Referee_Constraint {
  /** unique or primary key constraint on columns "referee_type", "event_id", "name" */
  EventRefereeEventIdNameRefereeType_7a35661eUniq = 'event_referee_event_id_name_referee_type_7a35661e_uniq',
  /** unique or primary key constraint on columns "id" */
  EventRefereePkey = 'event_referee_pkey',
}

/** input type for incrementing numeric columns in table "competitions.event_referee" */
export type Competitions_Event_Referee_Inc_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "competitions.event_referee" */
export type Competitions_Event_Referee_Insert_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  referee_type?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Max_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  referee_type?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Min_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  referee_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions.event_referee" */
export type Competitions_Event_Referee_Mutation_Response = {
  __typename: 'competitions_event_referee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Competitions_Event_Referee>>;
};

/** on_conflict condition type for table "competitions.event_referee" */
export type Competitions_Event_Referee_On_Conflict = {
  constraint: Competitions_Event_Referee_Constraint;
  update_columns?: Array<Competitions_Event_Referee_Update_Column>;
  where?: InputMaybe<Competitions_Event_Referee_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions.event_referee". */
export type Competitions_Event_Referee_Order_By = {
  event?: InputMaybe<Competitions_Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  referee_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions.event_referee */
export type Competitions_Event_Referee_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "competitions.event_referee" */
export enum Competitions_Event_Referee_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RefereeType = 'referee_type',
}

/** input type for updating data in table "competitions.event_referee" */
export type Competitions_Event_Referee_Set_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  referee_type?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Stddev_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Stddev_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Stddev_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_event_referee" */
export type Competitions_Event_Referee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Event_Referee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Event_Referee_Stream_Cursor_Value_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  referee_type?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Sum_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "competitions.event_referee" */
export enum Competitions_Event_Referee_Update_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Name = 'name',
  /** column name */
  RefereeType = 'referee_type',
}

export type Competitions_Event_Referee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Competitions_Event_Referee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Competitions_Event_Referee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Competitions_Event_Referee_Bool_Exp;
};

/** order by var_pop() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Var_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Var_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.event_referee" */
export type Competitions_Event_Referee_Variance_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.event" */
export enum Competitions_Event_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Date = 'date',
  /** column name */
  Demo = 'demo',
  /** column name */
  EventWithSameTeamsId = 'event_with_same_teams_id',
  /** column name */
  GameSheetUrl = 'game_sheet_url',
  /** column name */
  Id = 'id',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  RoundDayId = 'round_day_id',
  /** column name */
  ScrapingId = 'scraping_id',
  /** column name */
  ShareImageUrl = 'share_image_url',
  /** column name */
  Status = 'status',
  /** column name */
  Time = 'time',
  /** column name */
  ToDelete = 'to_delete',
}

/** select "competitions_event_aggregate_bool_exp_bool_and_arguments_columns" columns of table "competitions.event" */
export enum Competitions_Event_Select_Column_Competitions_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Demo = 'demo',
}

/** select "competitions_event_aggregate_bool_exp_bool_or_arguments_columns" columns of table "competitions.event" */
export enum Competitions_Event_Select_Column_Competitions_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Demo = 'demo',
}

/** input type for updating data in table "competitions.event" */
export type Competitions_Event_Set_Input = {
  date?: InputMaybe<Scalars['date']['input']>;
  demo?: InputMaybe<Scalars['Boolean']['input']>;
  game_sheet_url?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  to_delete?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate stddev on columns */
export type Competitions_Event_Stddev_Fields = {
  __typename: 'competitions_event_stddev_fields';
  closest_date?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  place_id?: Maybe<Scalars['Float']['output']>;
  round_day_id?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
  to_delete?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "competitions.event" */
export type Competitions_Event_Stddev_Order_By = {
  event_with_same_teams_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Competitions_Event_Stddev_Pop_Fields = {
  __typename: 'competitions_event_stddev_pop_fields';
  closest_date?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  place_id?: Maybe<Scalars['Float']['output']>;
  round_day_id?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
  to_delete?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "competitions.event" */
export type Competitions_Event_Stddev_Pop_Order_By = {
  event_with_same_teams_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Competitions_Event_Stddev_Samp_Fields = {
  __typename: 'competitions_event_stddev_samp_fields';
  closest_date?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  place_id?: Maybe<Scalars['Float']['output']>;
  round_day_id?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
  to_delete?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "competitions.event" */
export type Competitions_Event_Stddev_Samp_Order_By = {
  event_with_same_teams_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_event" */
export type Competitions_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Event_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  demo?: InputMaybe<Scalars['Boolean']['input']>;
  event_with_same_teams_id?: InputMaybe<Scalars['Int']['input']>;
  game_sheet_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['Int']['input']>;
  round_day_id?: InputMaybe<Scalars['Int']['input']>;
  scraping_id?: InputMaybe<Scalars['Int']['input']>;
  share_image_url?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  to_delete?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate sum on columns */
export type Competitions_Event_Sum_Fields = {
  __typename: 'competitions_event_sum_fields';
  closest_date?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  place_id?: Maybe<Scalars['Int']['output']>;
  round_day_id?: Maybe<Scalars['Int']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  to_delete?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "competitions.event" */
export type Competitions_Event_Sum_Order_By = {
  event_with_same_teams_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

export type Competitions_Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Competitions_Event_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Competitions_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Competitions_Event_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Competitions_Event_Var_Pop_Fields = {
  __typename: 'competitions_event_var_pop_fields';
  closest_date?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  place_id?: Maybe<Scalars['Float']['output']>;
  round_day_id?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
  to_delete?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "competitions.event" */
export type Competitions_Event_Var_Pop_Order_By = {
  event_with_same_teams_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Competitions_Event_Var_Samp_Fields = {
  __typename: 'competitions_event_var_samp_fields';
  closest_date?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  place_id?: Maybe<Scalars['Float']['output']>;
  round_day_id?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
  to_delete?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "competitions.event" */
export type Competitions_Event_Var_Samp_Order_By = {
  event_with_same_teams_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Competitions_Event_Variance_Fields = {
  __typename: 'competitions_event_variance_fields';
  closest_date?: Maybe<Scalars['Int']['output']>;
  event_with_same_teams_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  place_id?: Maybe<Scalars['Float']['output']>;
  round_day_id?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
  to_delete?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "competitions.event" */
export type Competitions_Event_Variance_Order_By = {
  event_with_same_teams_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  round_day_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitions.event_with_same_teams" */
export type Competitions_Event_With_Same_Teams = {
  __typename: 'competitions_event_with_same_teams';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  events?: Maybe<Array<Competitions_Event>>;
  /** An aggregate relationship */
  events_aggregate?: Maybe<Competitions_Event_Aggregate>;
  id: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  team_ids?: Maybe<Array<Scalars['Int']['output']>>;
};

/** columns and relationships of "competitions.event_with_same_teams" */
export type Competitions_Event_With_Same_TeamsEventsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Order_By>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

/** columns and relationships of "competitions.event_with_same_teams" */
export type Competitions_Event_With_Same_TeamsEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Order_By>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "competitions.event_with_same_teams". All fields are combined with a logical 'AND'. */
export type Competitions_Event_With_Same_Teams_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Event_With_Same_Teams_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Event_With_Same_Teams_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Event_With_Same_Teams_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  events?: InputMaybe<Competitions_Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Competitions_Event_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  team_ids?: InputMaybe<Int_Array_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitions.event_with_same_teams". */
export type Competitions_Event_With_Same_Teams_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Competitions_Event_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  team_ids?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.event_with_same_teams" */
export enum Competitions_Event_With_Same_Teams_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  TeamIds = 'team_ids',
}

/** Streaming cursor of the table "competitions_event_with_same_teams" */
export type Competitions_Event_With_Same_Teams_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Event_With_Same_Teams_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Event_With_Same_Teams_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  team_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** columns and relationships of "competitions.fan" */
export type Competitions_Fan = {
  __typename: 'competitions_fan';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  picture_url?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.fan". All fields are combined with a logical 'AND'. */
export type Competitions_Fan_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Fan_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Fan_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Fan_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  picture_url?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitions.fan". */
export type Competitions_Fan_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.fan" */
export enum Competitions_Fan_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PictureUrl = 'picture_url',
}

/** Streaming cursor of the table "competitions_fan" */
export type Competitions_Fan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Fan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Fan_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture_url?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Gender_Response = {
  __typename: 'competitions_gender_response';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "competitions.league" */
export type Competitions_League = {
  __typename: 'competitions_league';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  area?: Maybe<Scalars['smallint']['output']>;
  /** A computed field, executes function "competitions.league_detail" */
  detail?: Maybe<Array<Competitions_League_Detail>>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  levels?: Maybe<Array<Competitions_Level>>;
  name?: Maybe<Scalars['String']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  sport?: Maybe<Clubs_Sport>;
  sport_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "competitions.league" */
export type Competitions_LeagueDetailArgs = {
  args?: InputMaybe<Detail_Competitions_League_Args>;
  distinct_on?: InputMaybe<Array<Competitions_League_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Detail_Order_By>>;
  where?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
};

/** columns and relationships of "competitions.league" */
export type Competitions_LeagueLevelsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_Order_By>>;
  where?: InputMaybe<Competitions_Level_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "competitions.league". All fields are combined with a logical 'AND'. */
export type Competitions_League_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_League_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_League_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_League_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  area?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  levels?: InputMaybe<Competitions_Level_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  scraping_id?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sport?: InputMaybe<Clubs_Sport_Bool_Exp>;
  sport_id?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "competitions.league_detail" */
export type Competitions_League_Detail = {
  __typename: 'competitions_league_detail';
  area?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sport_id?: Maybe<Scalars['Int']['output']>;
  sport_slug?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.league_detail". All fields are combined with a logical 'AND'. */
export type Competitions_League_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_League_Detail_Bool_Exp>>;
  _not?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_League_Detail_Bool_Exp>>;
  area?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sport_id?: InputMaybe<Int_Comparison_Exp>;
  sport_slug?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

export type Competitions_League_Detail_By_Id_Args = {
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_League_Detail_By_Sport_Slug_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
  sport_slug?: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options when selecting data from "competitions.league_detail". */
export type Competitions_League_Detail_Order_By = {
  area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_id?: InputMaybe<Order_By>;
  sport_slug?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.league_detail" */
export enum Competitions_League_Detail_Select_Column {
  /** column name */
  Area = 'area',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Slug = 'slug',
  /** column name */
  SportId = 'sport_id',
  /** column name */
  SportSlug = 'sport_slug',
  /** column name */
  Url = 'url',
}

/** Streaming cursor of the table "competitions_league_detail" */
export type Competitions_League_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_League_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_League_Detail_Stream_Cursor_Value_Input = {
  area?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  sport_slug?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options when selecting data from "competitions.league". */
export type Competitions_League_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  levels_aggregate?: InputMaybe<Competitions_Level_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport?: InputMaybe<Clubs_Sport_Order_By>;
  sport_id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.league" */
export enum Competitions_League_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Area = 'area',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Rank = 'rank',
  /** column name */
  ScrapingId = 'scraping_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  SportId = 'sport_id',
}

/** Streaming cursor of the table "competitions_league" */
export type Competitions_League_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_League_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_League_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  area?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  scraping_id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "competitions.level" */
export type Competitions_Level = {
  __typename: 'competitions_level';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  age_category?: Maybe<Scalars['String']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  league?: Maybe<Competitions_League>;
  league_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  seasons?: Maybe<Array<Competitions_Level_For_Season>>;
  short_name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sport_sub_category_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "competitions.level" */
export type Competitions_LevelSeasonsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Level_For_Season_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Bool_Exp>;
};

/** order by aggregate values of table "competitions.level" */
export type Competitions_Level_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Level_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Level_Max_Order_By>;
  min?: InputMaybe<Competitions_Level_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Level_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Level_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Level_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Level_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Level_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Level_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Level_Variance_Order_By>;
};

/** order by avg() on columns of table "competitions.level" */
export type Competitions_Level_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.level". All fields are combined with a logical 'AND'. */
export type Competitions_Level_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Level_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Level_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Level_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  age_category?: InputMaybe<String_Comparison_Exp>;
  family?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  league?: InputMaybe<Competitions_League_Bool_Exp>;
  league_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  seasons?: InputMaybe<Competitions_Level_For_Season_Bool_Exp>;
  short_name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sport_sub_category_id?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "competitions.level_for_season" */
export type Competitions_Level_For_Season = {
  __typename: 'competitions_level_for_season';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  competitions?: Maybe<Array<Competitions_Competition>>;
  /** A computed field, executes function "competitions.level_for_season_detail" */
  detail?: Maybe<Array<Competitions_Level_For_Season_Detail>>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  level?: Maybe<Competitions_Level>;
  level_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  season?: Maybe<Clubs_Season>;
  season_id?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "competitions.level_for_season" */
export type Competitions_Level_For_SeasonCompetitionsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Competition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Bool_Exp>;
};

/** columns and relationships of "competitions.level_for_season" */
export type Competitions_Level_For_SeasonDetailArgs = {
  args?: InputMaybe<Detail_Competitions_Level_For_Season_Args>;
  distinct_on?: InputMaybe<
    Array<Competitions_Level_For_Season_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
};

/** order by aggregate values of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Level_For_Season_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Level_For_Season_Max_Order_By>;
  min?: InputMaybe<Competitions_Level_For_Season_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Level_For_Season_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Level_For_Season_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Level_For_Season_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Level_For_Season_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Level_For_Season_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Level_For_Season_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Level_For_Season_Variance_Order_By>;
};

/** order by avg() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.level_for_season". All fields are combined with a logical 'AND'. */
export type Competitions_Level_For_Season_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Level_For_Season_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Level_For_Season_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Level_For_Season_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  competitions?: InputMaybe<Competitions_Competition_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  level?: InputMaybe<Competitions_Level_Bool_Exp>;
  level_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  scraping_id?: InputMaybe<Int_Comparison_Exp>;
  season?: InputMaybe<Clubs_Season_Bool_Exp>;
  season_id?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "competitions.level_for_season_detail" */
export type Competitions_Level_For_Season_Detail = {
  __typename: 'competitions_level_for_season_detail';
  id: Scalars['Int']['output'];
  /** An object relationship */
  league?: Maybe<Competitions_League>;
  league_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  level?: Maybe<Competitions_Level>;
  level_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  season?: Maybe<Clubs_Season>;
  season_id?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.level_for_season_detail". All fields are combined with a logical 'AND'. */
export type Competitions_Level_For_Season_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  league?: InputMaybe<Competitions_League_Bool_Exp>;
  league_id?: InputMaybe<Int_Comparison_Exp>;
  level?: InputMaybe<Competitions_Level_Bool_Exp>;
  level_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  season?: InputMaybe<Clubs_Season_Bool_Exp>;
  season_id?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

export type Competitions_Level_For_Season_Detail_By_Id_Args = {
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Level_For_Season_Detail_By_League_Id_Args = {
  league_id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Competitions_Level_For_Season_Detail_By_Level_And_Season_Args = {
  level_id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  season_slug?: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options when selecting data from "competitions.level_for_season_detail". */
export type Competitions_Level_For_Season_Detail_Order_By = {
  id?: InputMaybe<Order_By>;
  league?: InputMaybe<Competitions_League_Order_By>;
  league_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Competitions_Level_Order_By>;
  level_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  season?: InputMaybe<Clubs_Season_Order_By>;
  season_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.level_for_season_detail" */
export enum Competitions_Level_For_Season_Detail_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LeagueId = 'league_id',
  /** column name */
  LevelId = 'level_id',
  /** column name */
  Name = 'name',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  SeasonId = 'season_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Url = 'url',
}

/** Streaming cursor of the table "competitions_level_for_season_detail" */
export type Competitions_Level_For_Season_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Level_For_Season_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Level_For_Season_Detail_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  league_id?: InputMaybe<Scalars['Int']['input']>;
  level_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  season_id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions.level_for_season". */
export type Competitions_Level_For_Season_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competitions_aggregate?: InputMaybe<Competitions_Competition_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Competitions_Level_Order_By>;
  level_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season?: InputMaybe<Clubs_Season_Order_By>;
  season_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.level_for_season" */
export enum Competitions_Level_For_Season_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  LevelId = 'level_id',
  /** column name */
  Name = 'name',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  ScrapingId = 'scraping_id',
  /** column name */
  SeasonId = 'season_id',
  /** column name */
  Slug = 'slug',
}

/** order by stddev() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_level_for_season" */
export type Competitions_Level_For_Season_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Level_For_Season_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Level_For_Season_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  level_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  scraping_id?: InputMaybe<Scalars['Int']['input']>;
  season_id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.level_for_season" */
export type Competitions_Level_For_Season_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  level_id?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
};

/** order by max() on columns of table "competitions.level" */
export type Competitions_Level_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_category?: InputMaybe<Order_By>;
  family?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.level" */
export type Competitions_Level_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_category?: InputMaybe<Order_By>;
  family?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions.level". */
export type Competitions_Level_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_category?: InputMaybe<Order_By>;
  family?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  league?: InputMaybe<Competitions_League_Order_By>;
  league_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  seasons_aggregate?: InputMaybe<Competitions_Level_For_Season_Aggregate_Order_By>;
  short_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.level" */
export enum Competitions_Level_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AgeCategory = 'age_category',
  /** column name */
  Family = 'family',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  LeagueId = 'league_id',
  /** column name */
  Name = 'name',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Rank = 'rank',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Slug = 'slug',
  /** column name */
  SportSubCategoryId = 'sport_sub_category_id',
}

/** order by stddev() on columns of table "competitions.level" */
export type Competitions_Level_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.level" */
export type Competitions_Level_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.level" */
export type Competitions_Level_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_level" */
export type Competitions_Level_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Level_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Level_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  age_category?: InputMaybe<Scalars['String']['input']>;
  family?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  league_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_sub_category_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "competitions.level" */
export type Competitions_Level_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "competitions.level" */
export type Competitions_Level_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.level" */
export type Competitions_Level_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.level" */
export type Competitions_Level_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  league_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitions.playing_team" */
export type Competitions_Playing_Team = {
  __typename: 'competitions_playing_team';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  data?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  event?: Maybe<Competitions_Event>;
  /** Récupère event_detail associé à une équipe */
  event_detail?: Maybe<Array<Competitions_Event_Detail>>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  info?: Maybe<Competitions_Playing_Team_Info>;
  role?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  share_image_url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  team_in_season?: Maybe<Teams_Team_In_Season>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "competitions.playing_team" */
export type Competitions_Playing_TeamDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "competitions.playing_team" */
export type Competitions_Playing_TeamEvent_DetailArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

/** aggregated selection of "competitions.playing_team" */
export type Competitions_Playing_Team_Aggregate = {
  __typename: 'competitions_playing_team_aggregate';
  aggregate?: Maybe<Competitions_Playing_Team_Aggregate_Fields>;
  nodes?: Maybe<Array<Competitions_Playing_Team>>;
};

export type Competitions_Playing_Team_Aggregate_Bool_Exp = {
  count?: InputMaybe<Competitions_Playing_Team_Aggregate_Bool_Exp_Count>;
};

export type Competitions_Playing_Team_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "competitions.playing_team" */
export type Competitions_Playing_Team_Aggregate_Fields = {
  __typename: 'competitions_playing_team_aggregate_fields';
  avg?: Maybe<Competitions_Playing_Team_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Competitions_Playing_Team_Max_Fields>;
  min?: Maybe<Competitions_Playing_Team_Min_Fields>;
  stddev?: Maybe<Competitions_Playing_Team_Stddev_Fields>;
  stddev_pop?: Maybe<Competitions_Playing_Team_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Competitions_Playing_Team_Stddev_Samp_Fields>;
  sum?: Maybe<Competitions_Playing_Team_Sum_Fields>;
  var_pop?: Maybe<Competitions_Playing_Team_Var_Pop_Fields>;
  var_samp?: Maybe<Competitions_Playing_Team_Var_Samp_Fields>;
  variance?: Maybe<Competitions_Playing_Team_Variance_Fields>;
};

/** aggregate fields of "competitions.playing_team" */
export type Competitions_Playing_Team_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions.playing_team" */
export type Competitions_Playing_Team_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Playing_Team_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Playing_Team_Max_Order_By>;
  min?: InputMaybe<Competitions_Playing_Team_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Playing_Team_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Playing_Team_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Playing_Team_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Playing_Team_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Playing_Team_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Playing_Team_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Playing_Team_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Competitions_Playing_Team_Avg_Fields = {
  __typename: 'competitions_playing_team_avg_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Avg_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager = {
  __typename: 'competitions_playing_team_bar_manager';
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message?: Maybe<Emails_Message>;
  message_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message_sms?: Maybe<Sms_Message>;
  /** An object relationship */
  playing_team_info?: Maybe<Competitions_Playing_Team_Info>;
  playing_team_info_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Max_Order_By>;
  min?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Arr_Rel_Insert_Input = {
  data: Array<Competitions_Playing_Team_Bar_Manager_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Competitions_Playing_Team_Bar_Manager_On_Conflict>;
};

/** order by avg() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.playing_team_bar_manager". All fields are combined with a logical 'AND'. */
export type Competitions_Playing_Team_Bar_Manager_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Playing_Team_Bar_Manager_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Playing_Team_Bar_Manager_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<Emails_Message_Bool_Exp>;
  message_id?: InputMaybe<Int_Comparison_Exp>;
  message_sms?: InputMaybe<Sms_Message_Bool_Exp>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
  playing_team_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "competitions.playing_team_bar_manager" */
export enum Competitions_Playing_Team_Bar_Manager_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlayingTeamBarManagerPkey = 'playing_team_bar_manager_pkey',
  /** unique or primary key constraint on columns "member_id", "playing_team_info_id" */
  PlayingTeamBarManagerPlayingTeamInfoIdMem_10e603bbUniq = 'playing_team_bar_manager_playing_team_info_id_mem_10e603bb_uniq',
}

/** input type for incrementing numeric columns in table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Inc_Input = {
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Insert_Input = {
  member?: InputMaybe<Members_Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Mutation_Response = {
  __typename: 'competitions_playing_team_bar_manager_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Competitions_Playing_Team_Bar_Manager>>;
};

/** on_conflict condition type for table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_On_Conflict = {
  constraint: Competitions_Playing_Team_Bar_Manager_Constraint;
  update_columns?: Array<Competitions_Playing_Team_Bar_Manager_Update_Column>;
  where?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions.playing_team_bar_manager". */
export type Competitions_Playing_Team_Bar_Manager_Order_By = {
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  message?: InputMaybe<Emails_Message_Order_By>;
  message_id?: InputMaybe<Order_By>;
  message_sms?: InputMaybe<Sms_Message_Order_By>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions.playing_team_bar_manager */
export type Competitions_Playing_Team_Bar_Manager_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "competitions.playing_team_bar_manager" */
export enum Competitions_Playing_Team_Bar_Manager_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  PlayingTeamInfoId = 'playing_team_info_id',
}

/** input type for updating data in table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Playing_Team_Bar_Manager_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Playing_Team_Bar_Manager_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  message_id?: InputMaybe<Scalars['Int']['input']>;
  playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "competitions.playing_team_bar_manager" */
export enum Competitions_Playing_Team_Bar_Manager_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  MemberId = 'member_id',
}

export type Competitions_Playing_Team_Bar_Manager_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Set_Input>;
  /** filter the rows which have to be updated */
  where: Competitions_Playing_Team_Bar_Manager_Bool_Exp;
};

/** order by var_pop() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.playing_team_bar_manager" */
export type Competitions_Playing_Team_Bar_Manager_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.playing_team". All fields are combined with a logical 'AND'. */
export type Competitions_Playing_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Playing_Team_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Playing_Team_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  event?: InputMaybe<Competitions_Event_Bool_Exp>;
  event_detail?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  info?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
  share_image_url?: InputMaybe<String_Comparison_Exp>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  team_in_season_id?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "competitions.playing_team_info" */
export type Competitions_Playing_Team_Info = {
  __typename: 'competitions_playing_team_info';
  /** An array relationship */
  bar_managers?: Maybe<Array<Competitions_Playing_Team_Bar_Manager>>;
  date?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  equipment?: Maybe<Places_Equipment>;
  equipment_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  jobs?: Maybe<Array<Tasks_Job>>;
  meeting_time?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  playing_team?: Maybe<Competitions_Playing_Team>;
  playing_team_id?: Maybe<Scalars['Int']['output']>;
  private_comment?: Maybe<Scalars['String']['output']>;
  public_comment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  referees?: Maybe<Array<Competitions_Playing_Team_Referee>>;
  /** An array relationship */
  scoring_table_managers?: Maybe<
    Array<Competitions_Playing_Team_Scoring_Table>
  >;
  /** An array relationship */
  stadium_managers?: Maybe<Array<Competitions_Playing_Team_Stadium_Manager>>;
  status?: Maybe<Scalars['Boolean']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
};

/** columns and relationships of "competitions.playing_team_info" */
export type Competitions_Playing_Team_InfoBar_ManagersArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Bar_Manager_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Bar_Manager_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
};

/** columns and relationships of "competitions.playing_team_info" */
export type Competitions_Playing_Team_InfoJobsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Job_Order_By>>;
  where?: InputMaybe<Tasks_Job_Bool_Exp>;
};

/** columns and relationships of "competitions.playing_team_info" */
export type Competitions_Playing_Team_InfoRefereesArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Referee_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Referee_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
};

/** columns and relationships of "competitions.playing_team_info" */
export type Competitions_Playing_Team_InfoScoring_Table_ManagersArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Scoring_Table_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Playing_Team_Scoring_Table_Order_By>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
};

/** columns and relationships of "competitions.playing_team_info" */
export type Competitions_Playing_Team_InfoStadium_ManagersArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Stadium_Manager_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Playing_Team_Stadium_Manager_Order_By>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
};

/** aggregated selection of "competitions.playing_team_info" */
export type Competitions_Playing_Team_Info_Aggregate = {
  __typename: 'competitions_playing_team_info_aggregate';
  aggregate?: Maybe<Competitions_Playing_Team_Info_Aggregate_Fields>;
  nodes?: Maybe<Array<Competitions_Playing_Team_Info>>;
};

/** aggregate fields of "competitions.playing_team_info" */
export type Competitions_Playing_Team_Info_Aggregate_Fields = {
  __typename: 'competitions_playing_team_info_aggregate_fields';
  avg?: Maybe<Competitions_Playing_Team_Info_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Competitions_Playing_Team_Info_Max_Fields>;
  min?: Maybe<Competitions_Playing_Team_Info_Min_Fields>;
  stddev?: Maybe<Competitions_Playing_Team_Info_Stddev_Fields>;
  stddev_pop?: Maybe<Competitions_Playing_Team_Info_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Competitions_Playing_Team_Info_Stddev_Samp_Fields>;
  sum?: Maybe<Competitions_Playing_Team_Info_Sum_Fields>;
  var_pop?: Maybe<Competitions_Playing_Team_Info_Var_Pop_Fields>;
  var_samp?: Maybe<Competitions_Playing_Team_Info_Var_Samp_Fields>;
  variance?: Maybe<Competitions_Playing_Team_Info_Variance_Fields>;
};

/** aggregate fields of "competitions.playing_team_info" */
export type Competitions_Playing_Team_Info_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Competitions_Playing_Team_Info_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Competitions_Playing_Team_Info_Avg_Fields = {
  __typename: 'competitions_playing_team_info_avg_fields';
  equipment_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  playing_team_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.playing_team_info". All fields are combined with a logical 'AND'. */
export type Competitions_Playing_Team_Info_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Playing_Team_Info_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Playing_Team_Info_Bool_Exp>>;
  bar_managers?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  equipment?: InputMaybe<Places_Equipment_Bool_Exp>;
  equipment_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  jobs?: InputMaybe<Tasks_Job_Bool_Exp>;
  meeting_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  playing_team?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
  playing_team_id?: InputMaybe<Int_Comparison_Exp>;
  private_comment?: InputMaybe<String_Comparison_Exp>;
  public_comment?: InputMaybe<String_Comparison_Exp>;
  referees?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
  scoring_table_managers?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
  stadium_managers?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
  time?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Competitions_Playing_Team_Info_Max_Fields = {
  __typename: 'competitions_playing_team_info_max_fields';
  date?: Maybe<Scalars['date']['output']>;
  equipment_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  meeting_time?: Maybe<Scalars['timestamptz']['output']>;
  playing_team_id?: Maybe<Scalars['Int']['output']>;
  private_comment?: Maybe<Scalars['String']['output']>;
  public_comment?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Competitions_Playing_Team_Info_Min_Fields = {
  __typename: 'competitions_playing_team_info_min_fields';
  date?: Maybe<Scalars['date']['output']>;
  equipment_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  meeting_time?: Maybe<Scalars['timestamptz']['output']>;
  playing_team_id?: Maybe<Scalars['Int']['output']>;
  private_comment?: Maybe<Scalars['String']['output']>;
  public_comment?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "competitions.playing_team_info". */
export type Competitions_Playing_Team_Info_Order_By = {
  bar_managers_aggregate?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Aggregate_Order_By>;
  date?: InputMaybe<Order_By>;
  equipment?: InputMaybe<Places_Equipment_Order_By>;
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Tasks_Job_Aggregate_Order_By>;
  meeting_time?: InputMaybe<Order_By>;
  playing_team?: InputMaybe<Competitions_Playing_Team_Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  private_comment?: InputMaybe<Order_By>;
  public_comment?: InputMaybe<Order_By>;
  referees_aggregate?: InputMaybe<Competitions_Playing_Team_Referee_Aggregate_Order_By>;
  scoring_table_managers_aggregate?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Aggregate_Order_By>;
  stadium_managers_aggregate?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.playing_team_info" */
export enum Competitions_Playing_Team_Info_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  EquipmentId = 'equipment_id',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingTime = 'meeting_time',
  /** column name */
  PlayingTeamId = 'playing_team_id',
  /** column name */
  PrivateComment = 'private_comment',
  /** column name */
  PublicComment = 'public_comment',
  /** column name */
  Status = 'status',
  /** column name */
  Time = 'time',
}

/** aggregate stddev on columns */
export type Competitions_Playing_Team_Info_Stddev_Fields = {
  __typename: 'competitions_playing_team_info_stddev_fields';
  equipment_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  playing_team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Competitions_Playing_Team_Info_Stddev_Pop_Fields = {
  __typename: 'competitions_playing_team_info_stddev_pop_fields';
  equipment_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  playing_team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Competitions_Playing_Team_Info_Stddev_Samp_Fields = {
  __typename: 'competitions_playing_team_info_stddev_samp_fields';
  equipment_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  playing_team_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "competitions_playing_team_info" */
export type Competitions_Playing_Team_Info_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Playing_Team_Info_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Playing_Team_Info_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']['input']>;
  equipment_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  meeting_time?: InputMaybe<Scalars['timestamptz']['input']>;
  playing_team_id?: InputMaybe<Scalars['Int']['input']>;
  private_comment?: InputMaybe<Scalars['String']['input']>;
  public_comment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Competitions_Playing_Team_Info_Sum_Fields = {
  __typename: 'competitions_playing_team_info_sum_fields';
  equipment_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  playing_team_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Competitions_Playing_Team_Info_Var_Pop_Fields = {
  __typename: 'competitions_playing_team_info_var_pop_fields';
  equipment_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  playing_team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Competitions_Playing_Team_Info_Var_Samp_Fields = {
  __typename: 'competitions_playing_team_info_var_samp_fields';
  equipment_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  playing_team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Competitions_Playing_Team_Info_Variance_Fields = {
  __typename: 'competitions_playing_team_info_variance_fields';
  equipment_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  playing_team_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate max on columns */
export type Competitions_Playing_Team_Max_Fields = {
  __typename: 'competitions_playing_team_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  share_image_url?: Maybe<Scalars['String']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  share_image_url?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Playing_Team_Min_Fields = {
  __typename: 'competitions_playing_team_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  role?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
  share_image_url?: Maybe<Scalars['String']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  share_image_url?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions.playing_team". */
export type Competitions_Playing_Team_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  event?: InputMaybe<Competitions_Event_Order_By>;
  event_detail_aggregate?: InputMaybe<Competitions_Event_Detail_Aggregate_Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  info?: InputMaybe<Competitions_Playing_Team_Info_Order_By>;
  role?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  share_image_url?: InputMaybe<Order_By>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee = {
  __typename: 'competitions_playing_team_referee';
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message?: Maybe<Emails_Message>;
  message_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message_sms?: Maybe<Sms_Message>;
  /** An object relationship */
  playing_team_info?: Maybe<Competitions_Playing_Team_Info>;
  playing_team_info_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Playing_Team_Referee_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Playing_Team_Referee_Max_Order_By>;
  min?: InputMaybe<Competitions_Playing_Team_Referee_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Playing_Team_Referee_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Playing_Team_Referee_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Playing_Team_Referee_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Playing_Team_Referee_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Playing_Team_Referee_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Playing_Team_Referee_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Playing_Team_Referee_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Arr_Rel_Insert_Input = {
  data: Array<Competitions_Playing_Team_Referee_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Competitions_Playing_Team_Referee_On_Conflict>;
};

/** order by avg() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.playing_team_referee". All fields are combined with a logical 'AND'. */
export type Competitions_Playing_Team_Referee_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Playing_Team_Referee_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Playing_Team_Referee_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<Emails_Message_Bool_Exp>;
  message_id?: InputMaybe<Int_Comparison_Exp>;
  message_sms?: InputMaybe<Sms_Message_Bool_Exp>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
  playing_team_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "competitions.playing_team_referee" */
export enum Competitions_Playing_Team_Referee_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlayingTeamRefereePkey = 'playing_team_referee_pkey',
  /** unique or primary key constraint on columns "member_id", "playing_team_info_id" */
  PlayingTeamRefereePlayingTeamInfoIdMemE602b45cUniq = 'playing_team_referee_playing_team_info_id_mem_e602b45c_uniq',
}

/** input type for incrementing numeric columns in table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Inc_Input = {
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Insert_Input = {
  member?: InputMaybe<Members_Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Mutation_Response = {
  __typename: 'competitions_playing_team_referee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Competitions_Playing_Team_Referee>>;
};

/** on_conflict condition type for table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_On_Conflict = {
  constraint: Competitions_Playing_Team_Referee_Constraint;
  update_columns?: Array<Competitions_Playing_Team_Referee_Update_Column>;
  where?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions.playing_team_referee". */
export type Competitions_Playing_Team_Referee_Order_By = {
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  message?: InputMaybe<Emails_Message_Order_By>;
  message_id?: InputMaybe<Order_By>;
  message_sms?: InputMaybe<Sms_Message_Order_By>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions.playing_team_referee */
export type Competitions_Playing_Team_Referee_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "competitions.playing_team_referee" */
export enum Competitions_Playing_Team_Referee_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  PlayingTeamInfoId = 'playing_team_info_id',
}

/** input type for updating data in table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_playing_team_referee" */
export type Competitions_Playing_Team_Referee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Playing_Team_Referee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Playing_Team_Referee_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  message_id?: InputMaybe<Scalars['Int']['input']>;
  playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "competitions.playing_team_referee" */
export enum Competitions_Playing_Team_Referee_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  MemberId = 'member_id',
}

export type Competitions_Playing_Team_Referee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Competitions_Playing_Team_Referee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Competitions_Playing_Team_Referee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Competitions_Playing_Team_Referee_Bool_Exp;
};

/** order by var_pop() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.playing_team_referee" */
export type Competitions_Playing_Team_Referee_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table = {
  __typename: 'competitions_playing_team_scoring_table';
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message?: Maybe<Emails_Message>;
  message_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message_sms?: Maybe<Sms_Message>;
  /** An object relationship */
  playing_team_info?: Maybe<Competitions_Playing_Team_Info>;
  playing_team_info_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Max_Order_By>;
  min?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Arr_Rel_Insert_Input = {
  data: Array<Competitions_Playing_Team_Scoring_Table_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Competitions_Playing_Team_Scoring_Table_On_Conflict>;
};

/** order by avg() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.playing_team_scoring_table". All fields are combined with a logical 'AND'. */
export type Competitions_Playing_Team_Scoring_Table_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Playing_Team_Scoring_Table_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Playing_Team_Scoring_Table_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<Emails_Message_Bool_Exp>;
  message_id?: InputMaybe<Int_Comparison_Exp>;
  message_sms?: InputMaybe<Sms_Message_Bool_Exp>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
  playing_team_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "competitions.playing_team_scoring_table" */
export enum Competitions_Playing_Team_Scoring_Table_Constraint {
  /** unique or primary key constraint on columns "member_id", "playing_team_info_id" */
  PlayingTeamScoringTabPlayingTeamInfoIdMem_354b6609Uniq = 'playing_team_scoring_tab_playing_team_info_id_mem_354b6609_uniq',
  /** unique or primary key constraint on columns "id" */
  PlayingTeamScoringTablePkey = 'playing_team_scoring_table_pkey',
}

/** input type for incrementing numeric columns in table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Inc_Input = {
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Insert_Input = {
  member?: InputMaybe<Members_Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Mutation_Response = {
  __typename: 'competitions_playing_team_scoring_table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Competitions_Playing_Team_Scoring_Table>>;
};

/** on_conflict condition type for table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_On_Conflict = {
  constraint: Competitions_Playing_Team_Scoring_Table_Constraint;
  update_columns?: Array<Competitions_Playing_Team_Scoring_Table_Update_Column>;
  where?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions.playing_team_scoring_table". */
export type Competitions_Playing_Team_Scoring_Table_Order_By = {
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  message?: InputMaybe<Emails_Message_Order_By>;
  message_id?: InputMaybe<Order_By>;
  message_sms?: InputMaybe<Sms_Message_Order_By>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions.playing_team_scoring_table */
export type Competitions_Playing_Team_Scoring_Table_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "competitions.playing_team_scoring_table" */
export enum Competitions_Playing_Team_Scoring_Table_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  PlayingTeamInfoId = 'playing_team_info_id',
}

/** input type for updating data in table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Playing_Team_Scoring_Table_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Playing_Team_Scoring_Table_Stream_Cursor_Value_Input =
  {
    id?: InputMaybe<Scalars['Int']['input']>;
    member_id?: InputMaybe<Scalars['Int']['input']>;
    message_id?: InputMaybe<Scalars['Int']['input']>;
    playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
  };

/** order by sum() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "competitions.playing_team_scoring_table" */
export enum Competitions_Playing_Team_Scoring_Table_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  MemberId = 'member_id',
}

export type Competitions_Playing_Team_Scoring_Table_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Set_Input>;
  /** filter the rows which have to be updated */
  where: Competitions_Playing_Team_Scoring_Table_Bool_Exp;
};

/** order by var_pop() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.playing_team_scoring_table" */
export type Competitions_Playing_Team_Scoring_Table_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.playing_team" */
export enum Competitions_Playing_Team_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Data = 'data',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  Score = 'score',
  /** column name */
  ShareImageUrl = 'share_image_url',
  /** column name */
  TeamInSeasonId = 'team_in_season_id',
}

/** columns and relationships of "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager = {
  __typename: 'competitions_playing_team_stadium_manager';
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message?: Maybe<Emails_Message>;
  message_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message_sms?: Maybe<Sms_Message>;
  /** An object relationship */
  playing_team_info?: Maybe<Competitions_Playing_Team_Info>;
  playing_team_info_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Max_Order_By>;
  min?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Arr_Rel_Insert_Input = {
  data: Array<Competitions_Playing_Team_Stadium_Manager_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_On_Conflict>;
};

/** order by avg() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.playing_team_stadium_manager". All fields are combined with a logical 'AND'. */
export type Competitions_Playing_Team_Stadium_Manager_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<Emails_Message_Bool_Exp>;
  message_id?: InputMaybe<Int_Comparison_Exp>;
  message_sms?: InputMaybe<Sms_Message_Bool_Exp>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
  playing_team_info_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "competitions.playing_team_stadium_manager" */
export enum Competitions_Playing_Team_Stadium_Manager_Constraint {
  /** unique or primary key constraint on columns "member_id", "playing_team_info_id" */
  PlayingTeamStadiumManPlayingTeamInfoIdMem_835838faUniq = 'playing_team_stadium_man_playing_team_info_id_mem_835838fa_uniq',
  /** unique or primary key constraint on columns "id" */
  PlayingTeamStadiumManagerPkey = 'playing_team_stadium_manager_pkey',
}

/** input type for incrementing numeric columns in table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Inc_Input = {
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Insert_Input = {
  member?: InputMaybe<Members_Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Mutation_Response = {
  __typename: 'competitions_playing_team_stadium_manager_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Competitions_Playing_Team_Stadium_Manager>>;
};

/** on_conflict condition type for table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_On_Conflict = {
  constraint: Competitions_Playing_Team_Stadium_Manager_Constraint;
  update_columns?: Array<Competitions_Playing_Team_Stadium_Manager_Update_Column>;
  where?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
};

/** Ordering options when selecting data from "competitions.playing_team_stadium_manager". */
export type Competitions_Playing_Team_Stadium_Manager_Order_By = {
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  message?: InputMaybe<Emails_Message_Order_By>;
  message_id?: InputMaybe<Order_By>;
  message_sms?: InputMaybe<Sms_Message_Order_By>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competitions.playing_team_stadium_manager */
export type Competitions_Playing_Team_Stadium_Manager_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "competitions.playing_team_stadium_manager" */
export enum Competitions_Playing_Team_Stadium_Manager_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  PlayingTeamInfoId = 'playing_team_info_id',
}

/** input type for updating data in table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Playing_Team_Stadium_Manager_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Playing_Team_Stadium_Manager_Stream_Cursor_Value_Input =
  {
    id?: InputMaybe<Scalars['Int']['input']>;
    member_id?: InputMaybe<Scalars['Int']['input']>;
    message_id?: InputMaybe<Scalars['Int']['input']>;
    playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
  };

/** order by sum() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** update columns of table "competitions.playing_team_stadium_manager" */
export enum Competitions_Playing_Team_Stadium_Manager_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  MemberId = 'member_id',
}

export type Competitions_Playing_Team_Stadium_Manager_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Set_Input>;
  /** filter the rows which have to be updated */
  where: Competitions_Playing_Team_Stadium_Manager_Bool_Exp;
};

/** order by var_pop() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competitions.playing_team_stadium_manager" */
export type Competitions_Playing_Team_Stadium_Manager_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Competitions_Playing_Team_Stddev_Fields = {
  __typename: 'competitions_playing_team_stddev_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Stddev_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Competitions_Playing_Team_Stddev_Pop_Fields = {
  __typename: 'competitions_playing_team_stddev_pop_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Stddev_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Competitions_Playing_Team_Stddev_Samp_Fields = {
  __typename: 'competitions_playing_team_stddev_samp_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Stddev_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_playing_team" */
export type Competitions_Playing_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Playing_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Playing_Team_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  share_image_url?: InputMaybe<Scalars['String']['input']>;
  team_in_season_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Competitions_Playing_Team_Sum_Fields = {
  __typename: 'competitions_playing_team_sum_fields';
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  score?: Maybe<Scalars['Int']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Sum_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Competitions_Playing_Team_Var_Pop_Fields = {
  __typename: 'competitions_playing_team_var_pop_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Var_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Competitions_Playing_Team_Var_Samp_Fields = {
  __typename: 'competitions_playing_team_var_samp_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Var_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Competitions_Playing_Team_Variance_Fields = {
  __typename: 'competitions_playing_team_variance_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "competitions.playing_team" */
export type Competitions_Playing_Team_Variance_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitions.ranking" */
export type Competitions_Ranking = {
  __typename: 'competitions_ranking';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  even?: Maybe<Scalars['smallint']['output']>;
  ga?: Maybe<Scalars['smallint']['output']>;
  gd?: Maybe<Scalars['smallint']['output']>;
  gf?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  lost?: Maybe<Scalars['smallint']['output']>;
  played?: Maybe<Scalars['smallint']['output']>;
  pts?: Maybe<Scalars['smallint']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  ratio?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  team?: Maybe<Competitions_Competition_Has_Teams>;
  team_in_competition_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  teams?: Maybe<Competitions_Competition_Has_Teams>;
  win?: Maybe<Scalars['smallint']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.ranking". All fields are combined with a logical 'AND'. */
export type Competitions_Ranking_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Ranking_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Ranking_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Ranking_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  even?: InputMaybe<Smallint_Comparison_Exp>;
  ga?: InputMaybe<Smallint_Comparison_Exp>;
  gd?: InputMaybe<Smallint_Comparison_Exp>;
  gf?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lost?: InputMaybe<Smallint_Comparison_Exp>;
  played?: InputMaybe<Smallint_Comparison_Exp>;
  pts?: InputMaybe<Smallint_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  ratio?: InputMaybe<Float8_Comparison_Exp>;
  team?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
  team_in_competition_id?: InputMaybe<Int_Comparison_Exp>;
  teams?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
  win?: InputMaybe<Smallint_Comparison_Exp>;
};

/** columns and relationships of "competitions.ranking_detail" */
export type Competitions_Ranking_Detail = {
  __typename: 'competitions_ranking_detail';
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  club_slug?: Maybe<Scalars['String']['output']>;
  even?: Maybe<Scalars['smallint']['output']>;
  ga?: Maybe<Scalars['smallint']['output']>;
  gd?: Maybe<Scalars['smallint']['output']>;
  gf?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  lost?: Maybe<Scalars['smallint']['output']>;
  name_in_club?: Maybe<Scalars['String']['output']>;
  name_in_competition?: Maybe<Scalars['String']['output']>;
  played?: Maybe<Scalars['smallint']['output']>;
  pts?: Maybe<Scalars['smallint']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  ratio?: Maybe<Scalars['float8']['output']>;
  serie?: Maybe<Array<Scalars['Int']['output']>>;
  sport_slug?: Maybe<Scalars['String']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
  team_slug?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  win?: Maybe<Scalars['smallint']['output']>;
};

/** Boolean expression to filter rows from the table "competitions.ranking_detail". All fields are combined with a logical 'AND'. */
export type Competitions_Ranking_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Ranking_Detail_Bool_Exp>>;
  _not?: InputMaybe<Competitions_Ranking_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Ranking_Detail_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  club_slug?: InputMaybe<String_Comparison_Exp>;
  even?: InputMaybe<Smallint_Comparison_Exp>;
  ga?: InputMaybe<Smallint_Comparison_Exp>;
  gd?: InputMaybe<Smallint_Comparison_Exp>;
  gf?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  lost?: InputMaybe<Smallint_Comparison_Exp>;
  name_in_club?: InputMaybe<String_Comparison_Exp>;
  name_in_competition?: InputMaybe<String_Comparison_Exp>;
  played?: InputMaybe<Smallint_Comparison_Exp>;
  pts?: InputMaybe<Smallint_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  ratio?: InputMaybe<Float8_Comparison_Exp>;
  serie?: InputMaybe<Int_Array_Comparison_Exp>;
  sport_slug?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  team_in_season_id?: InputMaybe<Int_Comparison_Exp>;
  team_slug?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  win?: InputMaybe<Smallint_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitions.ranking_detail". */
export type Competitions_Ranking_Detail_Order_By = {
  _updated?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  club_slug?: InputMaybe<Order_By>;
  even?: InputMaybe<Order_By>;
  ga?: InputMaybe<Order_By>;
  gd?: InputMaybe<Order_By>;
  gf?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  lost?: InputMaybe<Order_By>;
  name_in_club?: InputMaybe<Order_By>;
  name_in_competition?: InputMaybe<Order_By>;
  played?: InputMaybe<Order_By>;
  pts?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
  serie?: InputMaybe<Order_By>;
  sport_slug?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
  team_slug?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  win?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.ranking_detail" */
export enum Competitions_Ranking_Detail_Select_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  ClubSlug = 'club_slug',
  /** column name */
  Even = 'even',
  /** column name */
  Ga = 'ga',
  /** column name */
  Gd = 'gd',
  /** column name */
  Gf = 'gf',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Lost = 'lost',
  /** column name */
  NameInClub = 'name_in_club',
  /** column name */
  NameInCompetition = 'name_in_competition',
  /** column name */
  Played = 'played',
  /** column name */
  Pts = 'pts',
  /** column name */
  Rank = 'rank',
  /** column name */
  Ratio = 'ratio',
  /** column name */
  Serie = 'serie',
  /** column name */
  SportSlug = 'sport_slug',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamInSeasonId = 'team_in_season_id',
  /** column name */
  TeamSlug = 'team_slug',
  /** column name */
  Url = 'url',
  /** column name */
  Win = 'win',
}

/** Streaming cursor of the table "competitions_ranking_detail" */
export type Competitions_Ranking_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Ranking_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Ranking_Detail_Stream_Cursor_Value_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  club_slug?: InputMaybe<Scalars['String']['input']>;
  even?: InputMaybe<Scalars['smallint']['input']>;
  ga?: InputMaybe<Scalars['smallint']['input']>;
  gd?: InputMaybe<Scalars['smallint']['input']>;
  gf?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  lost?: InputMaybe<Scalars['smallint']['input']>;
  name_in_club?: InputMaybe<Scalars['String']['input']>;
  name_in_competition?: InputMaybe<Scalars['String']['input']>;
  played?: InputMaybe<Scalars['smallint']['input']>;
  pts?: InputMaybe<Scalars['smallint']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  ratio?: InputMaybe<Scalars['float8']['input']>;
  serie?: InputMaybe<Array<Scalars['Int']['input']>>;
  sport_slug?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_in_season_id?: InputMaybe<Scalars['Int']['input']>;
  team_slug?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  win?: InputMaybe<Scalars['smallint']['input']>;
};

/** Ordering options when selecting data from "competitions.ranking". */
export type Competitions_Ranking_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  even?: InputMaybe<Order_By>;
  ga?: InputMaybe<Order_By>;
  gd?: InputMaybe<Order_By>;
  gf?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lost?: InputMaybe<Order_By>;
  played?: InputMaybe<Order_By>;
  pts?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
  team?: InputMaybe<Competitions_Competition_Has_Teams_Order_By>;
  team_in_competition_id?: InputMaybe<Order_By>;
  teams?: InputMaybe<Competitions_Competition_Has_Teams_Order_By>;
  win?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.ranking" */
export enum Competitions_Ranking_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Even = 'even',
  /** column name */
  Ga = 'ga',
  /** column name */
  Gd = 'gd',
  /** column name */
  Gf = 'gf',
  /** column name */
  Id = 'id',
  /** column name */
  Lost = 'lost',
  /** column name */
  Played = 'played',
  /** column name */
  Pts = 'pts',
  /** column name */
  Rank = 'rank',
  /** column name */
  Ratio = 'ratio',
  /** column name */
  TeamInCompetitionId = 'team_in_competition_id',
  /** column name */
  Win = 'win',
}

/** Streaming cursor of the table "competitions_ranking" */
export type Competitions_Ranking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Ranking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Ranking_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  even?: InputMaybe<Scalars['smallint']['input']>;
  ga?: InputMaybe<Scalars['smallint']['input']>;
  gd?: InputMaybe<Scalars['smallint']['input']>;
  gf?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lost?: InputMaybe<Scalars['smallint']['input']>;
  played?: InputMaybe<Scalars['smallint']['input']>;
  pts?: InputMaybe<Scalars['smallint']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  ratio?: InputMaybe<Scalars['float8']['input']>;
  team_in_competition_id?: InputMaybe<Scalars['Int']['input']>;
  win?: InputMaybe<Scalars['smallint']['input']>;
};

/** columns and relationships of "competitions.round" */
export type Competitions_Round = {
  __typename: 'competitions_round';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  competition?: Maybe<Competitions_Competition>;
  /** A computed field, executes function "competitions.competition_detail_by_round_id" */
  competition_detail?: Maybe<Array<Competitions_Competition_Detail>>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  events?: Maybe<Array<Competitions_Event>>;
  /** An aggregate relationship */
  events_aggregate?: Maybe<Competitions_Event_Aggregate>;
  fede_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  official_date?: Maybe<Scalars['date']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
  to_delete?: Maybe<Scalars['Boolean']['output']>;
};

/** columns and relationships of "competitions.round" */
export type Competitions_RoundCompetition_DetailArgs = {
  args?: InputMaybe<Competition_Detail_Competitions_Round_Args>;
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

/** columns and relationships of "competitions.round" */
export type Competitions_RoundEventsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Order_By>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

/** columns and relationships of "competitions.round" */
export type Competitions_RoundEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Order_By>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

/** aggregated selection of "competitions.round" */
export type Competitions_Round_Aggregate = {
  __typename: 'competitions_round_aggregate';
  aggregate?: Maybe<Competitions_Round_Aggregate_Fields>;
  nodes?: Maybe<Array<Competitions_Round>>;
};

export type Competitions_Round_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Competitions_Round_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Competitions_Round_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Competitions_Round_Aggregate_Bool_Exp_Count>;
};

export type Competitions_Round_Aggregate_Bool_Exp_Bool_And = {
  arguments: Competitions_Round_Select_Column_Competitions_Round_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Competitions_Round_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Competitions_Round_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Competitions_Round_Select_Column_Competitions_Round_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Competitions_Round_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Competitions_Round_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Competitions_Round_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "competitions.round" */
export type Competitions_Round_Aggregate_Fields = {
  __typename: 'competitions_round_aggregate_fields';
  avg?: Maybe<Competitions_Round_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Competitions_Round_Max_Fields>;
  min?: Maybe<Competitions_Round_Min_Fields>;
  stddev?: Maybe<Competitions_Round_Stddev_Fields>;
  stddev_pop?: Maybe<Competitions_Round_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Competitions_Round_Stddev_Samp_Fields>;
  sum?: Maybe<Competitions_Round_Sum_Fields>;
  var_pop?: Maybe<Competitions_Round_Var_Pop_Fields>;
  var_samp?: Maybe<Competitions_Round_Var_Samp_Fields>;
  variance?: Maybe<Competitions_Round_Variance_Fields>;
};

/** aggregate fields of "competitions.round" */
export type Competitions_Round_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "competitions.round" */
export type Competitions_Round_Aggregate_Order_By = {
  avg?: InputMaybe<Competitions_Round_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competitions_Round_Max_Order_By>;
  min?: InputMaybe<Competitions_Round_Min_Order_By>;
  stddev?: InputMaybe<Competitions_Round_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competitions_Round_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competitions_Round_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competitions_Round_Sum_Order_By>;
  var_pop?: InputMaybe<Competitions_Round_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competitions_Round_Var_Samp_Order_By>;
  variance?: InputMaybe<Competitions_Round_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Competitions_Round_Avg_Fields = {
  __typename: 'competitions_round_avg_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "competitions.round" */
export type Competitions_Round_Avg_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competitions.round". All fields are combined with a logical 'AND'. */
export type Competitions_Round_Bool_Exp = {
  _and?: InputMaybe<Array<Competitions_Round_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Competitions_Round_Bool_Exp>;
  _or?: InputMaybe<Array<Competitions_Round_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  competition?: InputMaybe<Competitions_Competition_Bool_Exp>;
  competition_id?: InputMaybe<Int_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  events?: InputMaybe<Competitions_Event_Bool_Exp>;
  events_aggregate?: InputMaybe<Competitions_Event_Aggregate_Bool_Exp>;
  fede_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  official_date?: InputMaybe<Date_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  scraping_id?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  to_delete?: InputMaybe<Boolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type Competitions_Round_Max_Fields = {
  __typename: 'competitions_round_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  fede_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  official_date?: Maybe<Scalars['date']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
};

/** order by max() on columns of table "competitions.round" */
export type Competitions_Round_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fede_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  official_date?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Competitions_Round_Min_Fields = {
  __typename: 'competitions_round_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  fede_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  official_date?: Maybe<Scalars['date']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
};

/** order by min() on columns of table "competitions.round" */
export type Competitions_Round_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fede_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  official_date?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "competitions.round". */
export type Competitions_Round_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition?: InputMaybe<Competitions_Competition_Order_By>;
  competition_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  events_aggregate?: InputMaybe<Competitions_Event_Aggregate_Order_By>;
  fede_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  official_date?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  to_delete?: InputMaybe<Order_By>;
};

/** select columns of table "competitions.round" */
export enum Competitions_Round_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FedeUrl = 'fede_url',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OfficialDate = 'official_date',
  /** column name */
  Rank = 'rank',
  /** column name */
  ScrapingId = 'scraping_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  ToDelete = 'to_delete',
}

/** select "competitions_round_aggregate_bool_exp_bool_and_arguments_columns" columns of table "competitions.round" */
export enum Competitions_Round_Select_Column_Competitions_Round_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ToDelete = 'to_delete',
}

/** select "competitions_round_aggregate_bool_exp_bool_or_arguments_columns" columns of table "competitions.round" */
export enum Competitions_Round_Select_Column_Competitions_Round_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ToDelete = 'to_delete',
}

/** aggregate stddev on columns */
export type Competitions_Round_Stddev_Fields = {
  __typename: 'competitions_round_stddev_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "competitions.round" */
export type Competitions_Round_Stddev_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Competitions_Round_Stddev_Pop_Fields = {
  __typename: 'competitions_round_stddev_pop_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "competitions.round" */
export type Competitions_Round_Stddev_Pop_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Competitions_Round_Stddev_Samp_Fields = {
  __typename: 'competitions_round_stddev_samp_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "competitions.round" */
export type Competitions_Round_Stddev_Samp_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competitions_round" */
export type Competitions_Round_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitions_Round_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitions_Round_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  competition_id?: InputMaybe<Scalars['Int']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  fede_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  official_date?: InputMaybe<Scalars['date']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  scraping_id?: InputMaybe<Scalars['Int']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  to_delete?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type Competitions_Round_Sum_Fields = {
  __typename: 'competitions_round_sum_fields';
  competition_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  rank?: Maybe<Scalars['smallint']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "competitions.round" */
export type Competitions_Round_Sum_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Competitions_Round_Var_Pop_Fields = {
  __typename: 'competitions_round_var_pop_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "competitions.round" */
export type Competitions_Round_Var_Pop_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Competitions_Round_Var_Samp_Fields = {
  __typename: 'competitions_round_var_samp_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "competitions.round" */
export type Competitions_Round_Var_Samp_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Competitions_Round_Variance_Fields = {
  __typename: 'competitions_round_variance_fields';
  competition_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
  scraping_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "competitions.round" */
export type Competitions_Round_Variance_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

export type Customer_Response = {
  __typename: 'customer_response';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pennylane_id?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

export type Detail_Competitions_Competition_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Detail_Competitions_Event_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Detail_Competitions_League_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Detail_Competitions_Level_For_Season_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "emails.event" */
export type Emails_Event = {
  __typename: 'emails_event';
  date?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  message_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "emails.event" */
export type Emails_Event_Aggregate_Order_By = {
  avg?: InputMaybe<Emails_Event_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Emails_Event_Max_Order_By>;
  min?: InputMaybe<Emails_Event_Min_Order_By>;
  stddev?: InputMaybe<Emails_Event_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Emails_Event_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Emails_Event_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Emails_Event_Sum_Order_By>;
  var_pop?: InputMaybe<Emails_Event_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Emails_Event_Var_Samp_Order_By>;
  variance?: InputMaybe<Emails_Event_Variance_Order_By>;
};

/** order by avg() on columns of table "emails.event" */
export type Emails_Event_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "emails.event". All fields are combined with a logical 'AND'. */
export type Emails_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Emails_Event_Bool_Exp>>;
  _not?: InputMaybe<Emails_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Emails_Event_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "emails.event" */
export type Emails_Event_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "emails.event" */
export type Emails_Event_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "emails.event". */
export type Emails_Event_Order_By = {
  date?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** select columns of table "emails.event" */
export enum Emails_Event_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Email = 'email',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
}

/** order by stddev() on columns of table "emails.event" */
export type Emails_Event_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "emails.event" */
export type Emails_Event_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "emails.event" */
export type Emails_Event_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "emails_event" */
export type Emails_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Emails_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Emails_Event_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "emails.event" */
export type Emails_Event_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "emails.event" */
export type Emails_Event_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "emails.event" */
export type Emails_Event_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "emails.event" */
export type Emails_Event_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "emails.message" */
export type Emails_Message = {
  __typename: 'emails_message';
  /** An array relationship */
  events?: Maybe<Array<Emails_Event>>;
  has_error?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
};

/** columns and relationships of "emails.message" */
export type Emails_MessageEventsArgs = {
  distinct_on?: InputMaybe<Array<Emails_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Emails_Event_Order_By>>;
  where?: InputMaybe<Emails_Event_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "emails.message". All fields are combined with a logical 'AND'. */
export type Emails_Message_Bool_Exp = {
  _and?: InputMaybe<Array<Emails_Message_Bool_Exp>>;
  _not?: InputMaybe<Emails_Message_Bool_Exp>;
  _or?: InputMaybe<Array<Emails_Message_Bool_Exp>>;
  events?: InputMaybe<Emails_Event_Bool_Exp>;
  has_error?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "emails.message". */
export type Emails_Message_Order_By = {
  events_aggregate?: InputMaybe<Emails_Event_Aggregate_Order_By>;
  has_error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "emails.message" */
export enum Emails_Message_Select_Column {
  /** column name */
  HasError = 'has_error',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "emails_message" */
export type Emails_Message_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Emails_Message_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Emails_Message_Stream_Cursor_Value_Input = {
  has_error?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Estimates_Response = {
  __typename: 'estimates_response';
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  estimate_number?: Maybe<Scalars['String']['output']>;
  file_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type Event_Detail_Lives_Live_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

export type Id_Response = {
  __typename: 'id_response';
  id: Scalars['Int']['output'];
  post?: Maybe<Lives_Post>;
};

export type Invoices_Response = {
  __typename: 'invoices_response';
  amount?: Maybe<Scalars['Float']['output']>;
  billing_subscription_id?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  file_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoice_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

export type Last_Competition_With_Ranking_Teams_Team_In_Season_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type League_Response = {
  __typename: 'league_response';
  id: Scalars['Int']['output'];
  levels?: Maybe<Array<Level_Response>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Level_Response = {
  __typename: 'level_response';
  championships?: Maybe<Array<Championship_Response_With_Exceptions>>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Live_Hit_Count_Response = {
  __typename: 'live_hit_count_response';
  event_id?: Maybe<Scalars['Int']['output']>;
  hit_count?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "lives.action" */
export type Lives_Action = {
  __typename: 'lives_action';
  action_type?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['jsonb']['output']>;
  depends_on_id?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  mandatory_fields?: Maybe<Scalars['jsonb']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optional_fields?: Maybe<Scalars['jsonb']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  sport?: Maybe<Clubs_Sport>;
  sport_id?: Maybe<Scalars['Int']['output']>;
  translation_key?: Maybe<Scalars['String']['output']>;
  unique?: Maybe<Scalars['Boolean']['output']>;
};

/** columns and relationships of "lives.action" */
export type Lives_ActionDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "lives.action" */
export type Lives_ActionMandatory_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "lives.action" */
export type Lives_ActionOptional_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "lives.action". All fields are combined with a logical 'AND'. */
export type Lives_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Action_Bool_Exp>>;
  _not?: InputMaybe<Lives_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Action_Bool_Exp>>;
  action_type?: InputMaybe<String_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  depends_on_id?: InputMaybe<Int_Comparison_Exp>;
  icon?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  mandatory_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  optional_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  sport?: InputMaybe<Clubs_Sport_Bool_Exp>;
  sport_id?: InputMaybe<Int_Comparison_Exp>;
  unique?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "lives.action". */
export type Lives_Action_Order_By = {
  action_type?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  depends_on_id?: InputMaybe<Order_By>;
  icon?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mandatory_fields?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  optional_fields?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sport?: InputMaybe<Clubs_Sport_Order_By>;
  sport_id?: InputMaybe<Order_By>;
  unique?: InputMaybe<Order_By>;
};

/** select columns of table "lives.action" */
export enum Lives_Action_Select_Column {
  /** column name */
  ActionType = 'action_type',
  /** column name */
  Data = 'data',
  /** column name */
  DependsOnId = 'depends_on_id',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  MandatoryFields = 'mandatory_fields',
  /** column name */
  Name = 'name',
  /** column name */
  OptionalFields = 'optional_fields',
  /** column name */
  Rank = 'rank',
  /** column name */
  SportId = 'sport_id',
  /** column name */
  Unique = 'unique',
}

/** Streaming cursor of the table "lives_action" */
export type Lives_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Action_Stream_Cursor_Value_Input = {
  action_type?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  depends_on_id?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mandatory_fields?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optional_fields?: InputMaybe<Scalars['jsonb']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "lives.current_reporters" */
export type Lives_Current_Reporters = {
  __typename: 'lives_current_reporters';
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  live_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  spectator?: Maybe<Lives_Spectator>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "lives.current_reporters" */
export type Lives_Current_Reporters_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Current_Reporters_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Current_Reporters_Max_Order_By>;
  min?: InputMaybe<Lives_Current_Reporters_Min_Order_By>;
  stddev?: InputMaybe<Lives_Current_Reporters_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Current_Reporters_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Current_Reporters_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Current_Reporters_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Current_Reporters_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Current_Reporters_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Current_Reporters_Variance_Order_By>;
};

/** order by avg() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Avg_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.current_reporters". All fields are combined with a logical 'AND'. */
export type Lives_Current_Reporters_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Current_Reporters_Bool_Exp>>;
  _not?: InputMaybe<Lives_Current_Reporters_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Current_Reporters_Bool_Exp>>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  spectator?: InputMaybe<Lives_Spectator_Bool_Exp>;
  spectator_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Max_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Min_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "lives.current_reporters". */
export type Lives_Current_Reporters_Order_By = {
  live?: InputMaybe<Lives_Live_Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator?: InputMaybe<Lives_Spectator_Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.current_reporters" */
export enum Lives_Current_Reporters_Select_Column {
  /** column name */
  LiveId = 'live_id',
  /** column name */
  SpectatorId = 'spectator_id',
}

/** order by stddev() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Stddev_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Stddev_Pop_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Stddev_Samp_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_current_reporters" */
export type Lives_Current_Reporters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Current_Reporters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Current_Reporters_Stream_Cursor_Value_Input = {
  live_id?: InputMaybe<Scalars['Int']['input']>;
  spectator_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Sum_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Var_Pop_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Var_Samp_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.current_reporters" */
export type Lives_Current_Reporters_Variance_Order_By = {
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lives.live" */
export type Lives_Live = {
  __typename: 'lives_live';
  /** An array relationship */
  current_reporters?: Maybe<Array<Lives_Current_Reporters>>;
  /** An object relationship */
  event?: Maybe<Competitions_Event>;
  /** A computed field, executes function "lives.live_event_detail" */
  event_detail?: Maybe<Array<Competitions_Event_Detail>>;
  event_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  hit_counts?: Maybe<Lives_Live_Hit_Count>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  media_reporters?: Maybe<Array<Lives_Media_Official_Reporter>>;
  nb_thanks?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  posts?: Maybe<Array<Lives_Post>>;
  /** An aggregate relationship */
  posts_aggregate?: Maybe<Lives_Post_Aggregate>;
  /** An array relationship */
  potential_team_reporters?: Maybe<Array<Lives_Potential_Team_Reporters>>;
  /** An array relationship */
  reporters?: Maybe<Array<Lives_Live_Has_Reporter>>;
  /** An array relationship */
  stats?: Maybe<Array<Stats_Action>>;
  status?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  team_reporters?: Maybe<Array<Lives_Team_Official_Reporter>>;
  /** An array relationship */
  teams?: Maybe<Array<Lives_Live_Team>>;
  /** An array relationship */
  thanks?: Maybe<Array<Lives_Thank_You>>;
  /** An aggregate relationship */
  thanks_aggregate?: Maybe<Lives_Thank_You_Aggregate>;
  /** An array relationship */
  tournament_reporters?: Maybe<Array<Lives_Tournament_Reporter>>;
  /** An array relationship */
  used_actions?: Maybe<Array<Lives_Used_Action>>;
  video?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveCurrent_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Current_Reporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Current_Reporters_Order_By>>;
  where?: InputMaybe<Lives_Current_Reporters_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveEvent_DetailArgs = {
  args?: InputMaybe<Event_Detail_Lives_Live_Args>;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveMedia_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Media_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Media_Official_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Media_Official_Reporter_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LivePostsArgs = {
  distinct_on?: InputMaybe<Array<Lives_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Post_Order_By>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LivePosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Post_Order_By>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LivePotential_Team_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Potential_Team_Reporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Potential_Team_Reporters_Order_By>>;
  where?: InputMaybe<Lives_Potential_Team_Reporters_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Has_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Has_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Live_Has_Reporter_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveStatsArgs = {
  distinct_on?: InputMaybe<Array<Stats_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stats_Action_Order_By>>;
  where?: InputMaybe<Stats_Action_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveTeam_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Team_Official_Reporter_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveTeamsArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Team_Order_By>>;
  where?: InputMaybe<Lives_Live_Team_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveThanksArgs = {
  distinct_on?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Thank_You_Order_By>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveThanks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Thank_You_Order_By>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveTournament_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Tournament_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Tournament_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Tournament_Reporter_Bool_Exp>;
};

/** columns and relationships of "lives.live" */
export type Lives_LiveUsed_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Lives_Used_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Used_Action_Order_By>>;
  where?: InputMaybe<Lives_Used_Action_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "lives.live". All fields are combined with a logical 'AND'. */
export type Lives_Live_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Live_Bool_Exp>>;
  _not?: InputMaybe<Lives_Live_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Live_Bool_Exp>>;
  current_reporters?: InputMaybe<Lives_Current_Reporters_Bool_Exp>;
  event?: InputMaybe<Competitions_Event_Bool_Exp>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  hit_counts?: InputMaybe<Lives_Live_Hit_Count_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  media_reporters?: InputMaybe<Lives_Media_Official_Reporter_Bool_Exp>;
  nb_thanks?: InputMaybe<Int_Comparison_Exp>;
  posts?: InputMaybe<Lives_Post_Bool_Exp>;
  posts_aggregate?: InputMaybe<Lives_Post_Aggregate_Bool_Exp>;
  potential_team_reporters?: InputMaybe<Lives_Potential_Team_Reporters_Bool_Exp>;
  reporters?: InputMaybe<Lives_Live_Has_Reporter_Bool_Exp>;
  stats?: InputMaybe<Stats_Action_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team_reporters?: InputMaybe<Lives_Team_Official_Reporter_Bool_Exp>;
  teams?: InputMaybe<Lives_Live_Team_Bool_Exp>;
  thanks?: InputMaybe<Lives_Thank_You_Bool_Exp>;
  thanks_aggregate?: InputMaybe<Lives_Thank_You_Aggregate_Bool_Exp>;
  tournament_reporters?: InputMaybe<Lives_Tournament_Reporter_Bool_Exp>;
  used_actions?: InputMaybe<Lives_Used_Action_Bool_Exp>;
  video?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lives.live" */
export enum Lives_Live_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  LiveEventIdKey = 'live_event_id_key',
  /** unique or primary key constraint on columns "id" */
  LivePkey = 'live_pkey',
}

/** columns and relationships of "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter = {
  __typename: 'lives_live_has_reporter';
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  live_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  reporter?: Maybe<Lives_Official_Reporter>;
  reporter_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Live_Has_Reporter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Live_Has_Reporter_Max_Order_By>;
  min?: InputMaybe<Lives_Live_Has_Reporter_Min_Order_By>;
  stddev?: InputMaybe<Lives_Live_Has_Reporter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Live_Has_Reporter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Live_Has_Reporter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Live_Has_Reporter_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Live_Has_Reporter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Live_Has_Reporter_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Live_Has_Reporter_Variance_Order_By>;
};

/** order by avg() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Avg_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.live_has_reporter". All fields are combined with a logical 'AND'. */
export type Lives_Live_Has_Reporter_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Live_Has_Reporter_Bool_Exp>>;
  _not?: InputMaybe<Lives_Live_Has_Reporter_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Live_Has_Reporter_Bool_Exp>>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  reporter?: InputMaybe<Lives_Official_Reporter_Bool_Exp>;
  reporter_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Max_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Min_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "lives.live_has_reporter". */
export type Lives_Live_Has_Reporter_Order_By = {
  live?: InputMaybe<Lives_Live_Order_By>;
  live_id?: InputMaybe<Order_By>;
  reporter?: InputMaybe<Lives_Official_Reporter_Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.live_has_reporter" */
export enum Lives_Live_Has_Reporter_Select_Column {
  /** column name */
  LiveId = 'live_id',
  /** column name */
  ReporterId = 'reporter_id',
}

/** order by stddev() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Stddev_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Stddev_Pop_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Stddev_Samp_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_live_has_reporter" */
export type Lives_Live_Has_Reporter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Live_Has_Reporter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Live_Has_Reporter_Stream_Cursor_Value_Input = {
  live_id?: InputMaybe<Scalars['Int']['input']>;
  reporter_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Sum_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Var_Pop_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Var_Samp_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.live_has_reporter" */
export type Lives_Live_Has_Reporter_Variance_Order_By = {
  live_id?: InputMaybe<Order_By>;
  reporter_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lives.live_hit" */
export type Lives_Live_Hit = {
  __typename: 'lives_live_hit';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
};

/** order by aggregate values of table "lives.live_hit" */
export type Lives_Live_Hit_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Live_Hit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Live_Hit_Max_Order_By>;
  min?: InputMaybe<Lives_Live_Hit_Min_Order_By>;
  stddev?: InputMaybe<Lives_Live_Hit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Live_Hit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Live_Hit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Live_Hit_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Live_Hit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Live_Hit_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Live_Hit_Variance_Order_By>;
};

/** order by avg() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Avg_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.live_hit". All fields are combined with a logical 'AND'. */
export type Lives_Live_Hit_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Live_Hit_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Lives_Live_Hit_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Live_Hit_Bool_Exp>>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "lives.live_hit_count" */
export type Lives_Live_Hit_Count = {
  __typename: 'lives_live_hit_count';
  event_id?: Maybe<Scalars['Int']['output']>;
  hit_count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
};

/** Boolean expression to filter rows from the table "lives.live_hit_count". All fields are combined with a logical 'AND'. */
export type Lives_Live_Hit_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Live_Hit_Count_Bool_Exp>>;
  _not?: InputMaybe<Lives_Live_Hit_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Live_Hit_Count_Bool_Exp>>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  hit_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "lives.live_hit_count". */
export type Lives_Live_Hit_Count_Order_By = {
  event_id?: InputMaybe<Order_By>;
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.live_hit_count" */
export enum Lives_Live_Hit_Count_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  HitCount = 'hit_count',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "lives_live_hit_count" */
export type Lives_Live_Hit_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Live_Hit_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Live_Hit_Count_Stream_Cursor_Value_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
  hit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "lives.live_hit". */
export type Lives_Live_Hit_Order_By = {
  _created?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.live_hit" */
export enum Lives_Live_Hit_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
}

/** order by stddev() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Stddev_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Stddev_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Stddev_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_live_hit" */
export type Lives_Live_Hit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Live_Hit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Live_Hit_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Sum_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Var_Pop_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Var_Samp_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.live_hit" */
export type Lives_Live_Hit_Variance_Order_By = {
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "lives.live" */
export type Lives_Live_Inc_Input = {
  nb_thanks?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "lives.live" */
export type Lives_Live_Insert_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
  posts?: InputMaybe<Lives_Post_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Lives_Live_Team_Arr_Rel_Insert_Input>;
  thanks?: InputMaybe<Lives_Thank_You_Arr_Rel_Insert_Input>;
  video?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "lives.live_last_update" */
export type Lives_Live_Last_Update = {
  __typename: 'lives_live_last_update';
  last_post_update?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  live_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "lives.live_last_update". All fields are combined with a logical 'AND'. */
export type Lives_Live_Last_Update_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Live_Last_Update_Bool_Exp>>;
  _not?: InputMaybe<Lives_Live_Last_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Live_Last_Update_Bool_Exp>>;
  last_post_update?: InputMaybe<Timestamptz_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "lives.live_last_update". */
export type Lives_Live_Last_Update_Order_By = {
  last_post_update?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.live_last_update" */
export enum Lives_Live_Last_Update_Select_Column {
  /** column name */
  LastPostUpdate = 'last_post_update',
  /** column name */
  LiveId = 'live_id',
}

/** Streaming cursor of the table "lives_live_last_update" */
export type Lives_Live_Last_Update_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Live_Last_Update_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Live_Last_Update_Stream_Cursor_Value_Input = {
  last_post_update?: InputMaybe<Scalars['timestamptz']['input']>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "lives.live" */
export type Lives_Live_Mutation_Response = {
  __typename: 'lives_live_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Lives_Live>>;
};

/** input type for inserting object relation for remote table "lives.live" */
export type Lives_Live_Obj_Rel_Insert_Input = {
  data: Lives_Live_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lives_Live_On_Conflict>;
};

/** on_conflict condition type for table "lives.live" */
export type Lives_Live_On_Conflict = {
  constraint: Lives_Live_Constraint;
  update_columns?: Array<Lives_Live_Update_Column>;
  where?: InputMaybe<Lives_Live_Bool_Exp>;
};

/** Ordering options when selecting data from "lives.live". */
export type Lives_Live_Order_By = {
  current_reporters_aggregate?: InputMaybe<Lives_Current_Reporters_Aggregate_Order_By>;
  event?: InputMaybe<Competitions_Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  hit_counts?: InputMaybe<Lives_Live_Hit_Count_Order_By>;
  id?: InputMaybe<Order_By>;
  media_reporters_aggregate?: InputMaybe<Lives_Media_Official_Reporter_Aggregate_Order_By>;
  nb_thanks?: InputMaybe<Order_By>;
  posts_aggregate?: InputMaybe<Lives_Post_Aggregate_Order_By>;
  potential_team_reporters_aggregate?: InputMaybe<Lives_Potential_Team_Reporters_Aggregate_Order_By>;
  reporters_aggregate?: InputMaybe<Lives_Live_Has_Reporter_Aggregate_Order_By>;
  stats_aggregate?: InputMaybe<Stats_Action_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  team_reporters_aggregate?: InputMaybe<Lives_Team_Official_Reporter_Aggregate_Order_By>;
  teams_aggregate?: InputMaybe<Lives_Live_Team_Aggregate_Order_By>;
  thanks_aggregate?: InputMaybe<Lives_Thank_You_Aggregate_Order_By>;
  tournament_reporters_aggregate?: InputMaybe<Lives_Tournament_Reporter_Aggregate_Order_By>;
  used_actions_aggregate?: InputMaybe<Lives_Used_Action_Aggregate_Order_By>;
  video?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lives.live */
export type Lives_Live_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "lives.live_player" */
export type Lives_Live_Player = {
  __typename: 'lives_live_player';
  captain?: Maybe<Scalars['Boolean']['output']>;
  current_player?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  live_team_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  player?: Maybe<Teams_Team_In_Season_Has_Member>;
  player_id?: Maybe<Scalars['Int']['output']>;
  player_picture?: Maybe<Scalars['String']['output']>;
  starting_player?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  team?: Maybe<Lives_Live_Team>;
};

/** order by aggregate values of table "lives.live_player" */
export type Lives_Live_Player_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Live_Player_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Live_Player_Max_Order_By>;
  min?: InputMaybe<Lives_Live_Player_Min_Order_By>;
  stddev?: InputMaybe<Lives_Live_Player_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Live_Player_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Live_Player_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Live_Player_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Live_Player_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Live_Player_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Live_Player_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lives.live_player" */
export type Lives_Live_Player_Arr_Rel_Insert_Input = {
  data: Array<Lives_Live_Player_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lives_Live_Player_On_Conflict>;
};

/** order by avg() on columns of table "lives.live_player" */
export type Lives_Live_Player_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.live_player". All fields are combined with a logical 'AND'. */
export type Lives_Live_Player_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Live_Player_Bool_Exp>>;
  _not?: InputMaybe<Lives_Live_Player_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Live_Player_Bool_Exp>>;
  captain?: InputMaybe<Boolean_Comparison_Exp>;
  current_player?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live_team_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<Smallint_Comparison_Exp>;
  player?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
  player_id?: InputMaybe<Int_Comparison_Exp>;
  player_picture?: InputMaybe<String_Comparison_Exp>;
  starting_player?: InputMaybe<Boolean_Comparison_Exp>;
  team?: InputMaybe<Lives_Live_Team_Bool_Exp>;
};

/** unique or primary key constraints on table "lives.live_player" */
export enum Lives_Live_Player_Constraint {
  /** unique or primary key constraint on columns "live_team_id", "name" */
  LivePlayerLiveTeamIdName_723f3d36Uniq = 'live_player_live_team_id_name_723f3d36_uniq',
  /** unique or primary key constraint on columns "player_id", "live_team_id" */
  LivePlayerLiveTeamIdPlayerIdCcf70d96Uniq = 'live_player_live_team_id_player_id_ccf70d96_uniq',
  /** unique or primary key constraint on columns "id" */
  LivePlayerPkey = 'live_player_pkey',
}

/** input type for incrementing numeric columns in table "lives.live_player" */
export type Lives_Live_Player_Inc_Input = {
  number?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "lives.live_player" */
export type Lives_Live_Player_Insert_Input = {
  captain?: InputMaybe<Scalars['Boolean']['input']>;
  current_player?: InputMaybe<Scalars['Boolean']['input']>;
  live_team_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['smallint']['input']>;
  player_id?: InputMaybe<Scalars['Int']['input']>;
  player_picture?: InputMaybe<Scalars['String']['input']>;
  starting_player?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<Lives_Live_Team_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "lives.live_player" */
export type Lives_Live_Player_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
  player_picture?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.live_player" */
export type Lives_Live_Player_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
  player_picture?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lives.live_player" */
export type Lives_Live_Player_Mutation_Response = {
  __typename: 'lives_live_player_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Lives_Live_Player>>;
};

/** on_conflict condition type for table "lives.live_player" */
export type Lives_Live_Player_On_Conflict = {
  constraint: Lives_Live_Player_Constraint;
  update_columns?: Array<Lives_Live_Player_Update_Column>;
  where?: InputMaybe<Lives_Live_Player_Bool_Exp>;
};

/** Ordering options when selecting data from "lives.live_player". */
export type Lives_Live_Player_Order_By = {
  captain?: InputMaybe<Order_By>;
  current_player?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player?: InputMaybe<Teams_Team_In_Season_Has_Member_Order_By>;
  player_id?: InputMaybe<Order_By>;
  player_picture?: InputMaybe<Order_By>;
  starting_player?: InputMaybe<Order_By>;
  team?: InputMaybe<Lives_Live_Team_Order_By>;
};

/** primary key columns input for table: lives.live_player */
export type Lives_Live_Player_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "lives.live_player" */
export enum Lives_Live_Player_Select_Column {
  /** column name */
  Captain = 'captain',
  /** column name */
  CurrentPlayer = 'current_player',
  /** column name */
  Id = 'id',
  /** column name */
  LiveTeamId = 'live_team_id',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  PlayerId = 'player_id',
  /** column name */
  PlayerPicture = 'player_picture',
  /** column name */
  StartingPlayer = 'starting_player',
}

/** input type for updating data in table "lives.live_player" */
export type Lives_Live_Player_Set_Input = {
  captain?: InputMaybe<Scalars['Boolean']['input']>;
  current_player?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['smallint']['input']>;
  player_picture?: InputMaybe<Scalars['String']['input']>;
  starting_player?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by stddev() on columns of table "lives.live_player" */
export type Lives_Live_Player_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.live_player" */
export type Lives_Live_Player_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.live_player" */
export type Lives_Live_Player_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_live_player" */
export type Lives_Live_Player_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Live_Player_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Live_Player_Stream_Cursor_Value_Input = {
  captain?: InputMaybe<Scalars['Boolean']['input']>;
  current_player?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  live_team_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['smallint']['input']>;
  player_id?: InputMaybe<Scalars['Int']['input']>;
  player_picture?: InputMaybe<Scalars['String']['input']>;
  starting_player?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by sum() on columns of table "lives.live_player" */
export type Lives_Live_Player_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
};

/** update columns of table "lives.live_player" */
export enum Lives_Live_Player_Update_Column {
  /** column name */
  Captain = 'captain',
  /** column name */
  CurrentPlayer = 'current_player',
  /** column name */
  Name = 'name',
  /** column name */
  Number = 'number',
  /** column name */
  PlayerPicture = 'player_picture',
  /** column name */
  StartingPlayer = 'starting_player',
}

export type Lives_Live_Player_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lives_Live_Player_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lives_Live_Player_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lives_Live_Player_Bool_Exp;
};

/** order by var_pop() on columns of table "lives.live_player" */
export type Lives_Live_Player_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.live_player" */
export type Lives_Live_Player_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.live_player" */
export type Lives_Live_Player_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  live_team_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  player_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.live" */
export enum Lives_Live_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  NbThanks = 'nb_thanks',
  /** column name */
  Status = 'status',
  /** column name */
  Video = 'video',
}

/** input type for updating data in table "lives.live" */
export type Lives_Live_Set_Input = {
  nb_thanks?: InputMaybe<Scalars['Int']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "lives_live" */
export type Lives_Live_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Live_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Live_Stream_Cursor_Value_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  nb_thanks?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "lives.live_team" */
export type Lives_Live_Team = {
  __typename: 'lives_live_team';
  data?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  live_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  players?: Maybe<Array<Lives_Live_Player>>;
  /** An object relationship */
  playing_team?: Maybe<Competitions_Playing_Team>;
  playing_team_id?: Maybe<Scalars['Int']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "lives.live_team" */
export type Lives_Live_TeamDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "lives.live_team" */
export type Lives_Live_TeamPlayersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Player_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Player_Order_By>>;
  where?: InputMaybe<Lives_Live_Player_Bool_Exp>;
};

/** order by aggregate values of table "lives.live_team" */
export type Lives_Live_Team_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Live_Team_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Live_Team_Max_Order_By>;
  min?: InputMaybe<Lives_Live_Team_Min_Order_By>;
  stddev?: InputMaybe<Lives_Live_Team_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Live_Team_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Live_Team_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Live_Team_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Live_Team_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Live_Team_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Live_Team_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lives.live_team" */
export type Lives_Live_Team_Arr_Rel_Insert_Input = {
  data: Array<Lives_Live_Team_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lives_Live_Team_On_Conflict>;
};

/** order by avg() on columns of table "lives.live_team" */
export type Lives_Live_Team_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.live_team". All fields are combined with a logical 'AND'. */
export type Lives_Live_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Live_Team_Bool_Exp>>;
  _not?: InputMaybe<Lives_Live_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Live_Team_Bool_Exp>>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  players?: InputMaybe<Lives_Live_Player_Bool_Exp>;
  playing_team?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
  playing_team_id?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lives.live_team" */
export enum Lives_Live_Team_Constraint {
  /** unique or primary key constraint on columns "live_id", "role" */
  LiveTeamLiveIdRole_5dff4157Uniq = 'live_team_live_id_role_5dff4157_uniq',
  /** unique or primary key constraint on columns "id" */
  LiveTeamPkey = 'live_team_pkey',
  /** unique or primary key constraint on columns "playing_team_id" */
  LiveTeamPlayingTeamIdKey = 'live_team_playing_team_id_key',
}

/** input type for incrementing numeric columns in table "lives.live_team" */
export type Lives_Live_Team_Inc_Input = {
  score?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "lives.live_team" */
export type Lives_Live_Team_Insert_Input = {
  live?: InputMaybe<Lives_Live_Obj_Rel_Insert_Input>;
  players?: InputMaybe<Lives_Live_Player_Arr_Rel_Insert_Input>;
  playing_team_id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "lives.live_team" */
export type Lives_Live_Team_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.live_team" */
export type Lives_Live_Team_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lives.live_team" */
export type Lives_Live_Team_Mutation_Response = {
  __typename: 'lives_live_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Lives_Live_Team>>;
};

/** input type for inserting object relation for remote table "lives.live_team" */
export type Lives_Live_Team_Obj_Rel_Insert_Input = {
  data: Lives_Live_Team_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lives_Live_Team_On_Conflict>;
};

/** on_conflict condition type for table "lives.live_team" */
export type Lives_Live_Team_On_Conflict = {
  constraint: Lives_Live_Team_Constraint;
  update_columns?: Array<Lives_Live_Team_Update_Column>;
  where?: InputMaybe<Lives_Live_Team_Bool_Exp>;
};

/** Ordering options when selecting data from "lives.live_team". */
export type Lives_Live_Team_Order_By = {
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  live_id?: InputMaybe<Order_By>;
  players_aggregate?: InputMaybe<Lives_Live_Player_Aggregate_Order_By>;
  playing_team?: InputMaybe<Competitions_Playing_Team_Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lives.live_team */
export type Lives_Live_Team_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "lives.live_team" */
export enum Lives_Live_Team_Select_Column {
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  LiveId = 'live_id',
  /** column name */
  PlayingTeamId = 'playing_team_id',
  /** column name */
  Role = 'role',
  /** column name */
  Score = 'score',
}

/** input type for updating data in table "lives.live_team" */
export type Lives_Live_Team_Set_Input = {
  score?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "lives.live_team" */
export type Lives_Live_Team_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.live_team" */
export type Lives_Live_Team_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.live_team" */
export type Lives_Live_Team_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_live_team" */
export type Lives_Live_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Live_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Live_Team_Stream_Cursor_Value_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
  playing_team_id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.live_team" */
export type Lives_Live_Team_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** update columns of table "lives.live_team" */
export enum Lives_Live_Team_Update_Column {
  /** column name */
  Score = 'score',
}

export type Lives_Live_Team_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lives_Live_Team_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lives_Live_Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lives_Live_Team_Bool_Exp;
};

/** order by var_pop() on columns of table "lives.live_team" */
export type Lives_Live_Team_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.live_team" */
export type Lives_Live_Team_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.live_team" */
export type Lives_Live_Team_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  playing_team_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** update columns of table "lives.live" */
export enum Lives_Live_Update_Column {
  /** column name */
  NbThanks = 'nb_thanks',
  /** column name */
  Video = 'video',
}

export type Lives_Live_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lives_Live_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lives_Live_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lives_Live_Bool_Exp;
};

/** columns and relationships of "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter = {
  __typename: 'lives_media_official_reporter';
  id: Scalars['Int']['output'];
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  /** An object relationship */
  media_official?: Maybe<Medias_Official_Reporter>;
  media_official_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Media_Official_Reporter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Media_Official_Reporter_Max_Order_By>;
  min?: InputMaybe<Lives_Media_Official_Reporter_Min_Order_By>;
  stddev?: InputMaybe<Lives_Media_Official_Reporter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Media_Official_Reporter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Media_Official_Reporter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Media_Official_Reporter_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Media_Official_Reporter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Media_Official_Reporter_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Media_Official_Reporter_Variance_Order_By>;
};

/** order by avg() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.media_official_reporter". All fields are combined with a logical 'AND'. */
export type Lives_Media_Official_Reporter_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Media_Official_Reporter_Bool_Exp>>;
  _not?: InputMaybe<Lives_Media_Official_Reporter_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Media_Official_Reporter_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  media_official?: InputMaybe<Medias_Official_Reporter_Bool_Exp>;
  media_official_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Mutation_Response = {
  __typename: 'lives_media_official_reporter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Lives_Media_Official_Reporter>>;
};

/** Ordering options when selecting data from "lives.media_official_reporter". */
export type Lives_Media_Official_Reporter_Order_By = {
  id?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  media_official?: InputMaybe<Medias_Official_Reporter_Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lives.media_official_reporter */
export type Lives_Media_Official_Reporter_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "lives.media_official_reporter" */
export enum Lives_Media_Official_Reporter_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MediaOfficialId = 'media_official_id',
}

/** input type for updating data in table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by stddev() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_media_official_reporter" */
export type Lives_Media_Official_Reporter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Media_Official_Reporter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Media_Official_Reporter_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  media_official_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

export type Lives_Media_Official_Reporter_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lives_Media_Official_Reporter_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lives_Media_Official_Reporter_Bool_Exp;
};

/** order by var_pop() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.media_official_reporter" */
export type Lives_Media_Official_Reporter_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  media_official_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lives.official_reporter" */
export type Lives_Official_Reporter = {
  __typename: 'lives_official_reporter';
  id: Scalars['Int']['output'];
  /** An object relationship */
  team_official?: Maybe<Teams_Official>;
  team_official_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "lives.official_reporter". All fields are combined with a logical 'AND'. */
export type Lives_Official_Reporter_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Official_Reporter_Bool_Exp>>;
  _not?: InputMaybe<Lives_Official_Reporter_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Official_Reporter_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  team_official?: InputMaybe<Teams_Official_Bool_Exp>;
  team_official_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "lives.official_reporter". */
export type Lives_Official_Reporter_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official?: InputMaybe<Teams_Official_Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.official_reporter" */
export enum Lives_Official_Reporter_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamOfficialId = 'team_official_id',
}

/** Streaming cursor of the table "lives_official_reporter" */
export type Lives_Official_Reporter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Official_Reporter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Official_Reporter_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_official_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "lives.post" */
export type Lives_Post = {
  __typename: 'lives_post';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  action?: Maybe<Lives_Action>;
  action_id?: Maybe<Scalars['Int']['output']>;
  data?: Maybe<Scalars['jsonb']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  live_id?: Maybe<Scalars['Int']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  spectator?: Maybe<Lives_Spectator>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team_official_reporter?: Maybe<Lives_Team_Official_Reporter>;
  team_official_reporter_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "lives.post" */
export type Lives_PostDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "lives.post" */
export type Lives_Post_Aggregate = {
  __typename: 'lives_post_aggregate';
  aggregate?: Maybe<Lives_Post_Aggregate_Fields>;
  nodes?: Maybe<Array<Lives_Post>>;
};

export type Lives_Post_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lives_Post_Aggregate_Bool_Exp_Count>;
};

export type Lives_Post_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lives_Post_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lives_Post_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lives.post" */
export type Lives_Post_Aggregate_Fields = {
  __typename: 'lives_post_aggregate_fields';
  avg?: Maybe<Lives_Post_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Lives_Post_Max_Fields>;
  min?: Maybe<Lives_Post_Min_Fields>;
  stddev?: Maybe<Lives_Post_Stddev_Fields>;
  stddev_pop?: Maybe<Lives_Post_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lives_Post_Stddev_Samp_Fields>;
  sum?: Maybe<Lives_Post_Sum_Fields>;
  var_pop?: Maybe<Lives_Post_Var_Pop_Fields>;
  var_samp?: Maybe<Lives_Post_Var_Samp_Fields>;
  variance?: Maybe<Lives_Post_Variance_Fields>;
};

/** aggregate fields of "lives.post" */
export type Lives_Post_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lives_Post_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "lives.post" */
export type Lives_Post_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Post_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Post_Max_Order_By>;
  min?: InputMaybe<Lives_Post_Min_Order_By>;
  stddev?: InputMaybe<Lives_Post_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Post_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Post_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Post_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Post_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Post_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Post_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Lives_Post_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "lives.post" */
export type Lives_Post_Arr_Rel_Insert_Input = {
  data: Array<Lives_Post_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lives_Post_On_Conflict>;
};

/** aggregate avg on columns */
export type Lives_Post_Avg_Fields = {
  __typename: 'lives_post_avg_fields';
  action_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "lives.post" */
export type Lives_Post_Avg_Order_By = {
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.post". All fields are combined with a logical 'AND'. */
export type Lives_Post_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Post_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Lives_Post_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Post_Bool_Exp>>;
  action?: InputMaybe<Lives_Action_Bool_Exp>;
  action_id?: InputMaybe<Int_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  picture?: InputMaybe<String_Comparison_Exp>;
  spectator?: InputMaybe<Lives_Spectator_Bool_Exp>;
  spectator_id?: InputMaybe<Int_Comparison_Exp>;
  team_official_reporter?: InputMaybe<Lives_Team_Official_Reporter_Bool_Exp>;
  team_official_reporter_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lives.post" */
export enum Lives_Post_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostPkey = 'post_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Lives_Post_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Lives_Post_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Lives_Post_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "lives.post" */
export type Lives_Post_Insert_Input = {
  action_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  live?: InputMaybe<Lives_Live_Obj_Rel_Insert_Input>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  spectator?: InputMaybe<Lives_Spectator_Obj_Rel_Insert_Input>;
  team_official_reporter_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Lives_Post_Max_Fields = {
  __typename: 'lives_post_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  action_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "lives.post" */
export type Lives_Post_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lives_Post_Min_Fields = {
  __typename: 'lives_post_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  action_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "lives.post" */
export type Lives_Post_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lives.post" */
export type Lives_Post_Mutation_Response = {
  __typename: 'lives_post_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Lives_Post>>;
};

/** on_conflict condition type for table "lives.post" */
export type Lives_Post_On_Conflict = {
  constraint: Lives_Post_Constraint;
  update_columns?: Array<Lives_Post_Update_Column>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

/** Ordering options when selecting data from "lives.post". */
export type Lives_Post_Order_By = {
  _created?: InputMaybe<Order_By>;
  action?: InputMaybe<Lives_Action_Order_By>;
  action_id?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  live_id?: InputMaybe<Order_By>;
  picture?: InputMaybe<Order_By>;
  spectator?: InputMaybe<Lives_Spectator_Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter?: InputMaybe<Lives_Team_Official_Reporter_Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lives.post */
export type Lives_Post_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Lives_Post_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "lives.post" */
export enum Lives_Post_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  ActionId = 'action_id',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  LiveId = 'live_id',
  /** column name */
  Picture = 'picture',
  /** column name */
  SpectatorId = 'spectator_id',
  /** column name */
  TeamOfficialReporterId = 'team_official_reporter_id',
}

/** input type for updating data in table "lives.post" */
export type Lives_Post_Set_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Lives_Post_Stddev_Fields = {
  __typename: 'lives_post_stddev_fields';
  action_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "lives.post" */
export type Lives_Post_Stddev_Order_By = {
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lives_Post_Stddev_Pop_Fields = {
  __typename: 'lives_post_stddev_pop_fields';
  action_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "lives.post" */
export type Lives_Post_Stddev_Pop_Order_By = {
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lives_Post_Stddev_Samp_Fields = {
  __typename: 'lives_post_stddev_samp_fields';
  action_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "lives.post" */
export type Lives_Post_Stddev_Samp_Order_By = {
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_post" */
export type Lives_Post_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Post_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Post_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  action_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  spectator_id?: InputMaybe<Scalars['Int']['input']>;
  team_official_reporter_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Lives_Post_Sum_Fields = {
  __typename: 'lives_post_sum_fields';
  action_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "lives.post" */
export type Lives_Post_Sum_Order_By = {
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** update columns of table "lives.post" */
export enum Lives_Post_Update_Column {
  /** column name */
  Data = 'data',
  /** column name */
  Picture = 'picture',
}

export type Lives_Post_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Lives_Post_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Lives_Post_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Lives_Post_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Lives_Post_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Lives_Post_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lives_Post_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lives_Post_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lives_Post_Var_Pop_Fields = {
  __typename: 'lives_post_var_pop_fields';
  action_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "lives.post" */
export type Lives_Post_Var_Pop_Order_By = {
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lives_Post_Var_Samp_Fields = {
  __typename: 'lives_post_var_samp_fields';
  action_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "lives.post" */
export type Lives_Post_Var_Samp_Order_By = {
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lives_Post_Variance_Fields = {
  __typename: 'lives_post_variance_fields';
  action_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
  team_official_reporter_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "lives.post" */
export type Lives_Post_Variance_Order_By = {
  action_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
  team_official_reporter_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters = {
  __typename: 'lives_potential_team_reporters';
  live_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team_official?: Maybe<Teams_Team_Official>;
  team_official_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Potential_Team_Reporters_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Potential_Team_Reporters_Max_Order_By>;
  min?: InputMaybe<Lives_Potential_Team_Reporters_Min_Order_By>;
  stddev?: InputMaybe<Lives_Potential_Team_Reporters_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Potential_Team_Reporters_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Potential_Team_Reporters_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Potential_Team_Reporters_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Potential_Team_Reporters_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Potential_Team_Reporters_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Potential_Team_Reporters_Variance_Order_By>;
};

/** order by avg() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Avg_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.potential_team_reporters". All fields are combined with a logical 'AND'. */
export type Lives_Potential_Team_Reporters_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Potential_Team_Reporters_Bool_Exp>>;
  _not?: InputMaybe<Lives_Potential_Team_Reporters_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Potential_Team_Reporters_Bool_Exp>>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  team_official?: InputMaybe<Teams_Team_Official_Bool_Exp>;
  team_official_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Max_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Min_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "lives.potential_team_reporters". */
export type Lives_Potential_Team_Reporters_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official?: InputMaybe<Teams_Team_Official_Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.potential_team_reporters" */
export enum Lives_Potential_Team_Reporters_Select_Column {
  /** column name */
  LiveId = 'live_id',
  /** column name */
  TeamOfficialId = 'team_official_id',
}

/** order by stddev() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Stddev_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Stddev_Pop_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Stddev_Samp_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Potential_Team_Reporters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Potential_Team_Reporters_Stream_Cursor_Value_Input = {
  live_id?: InputMaybe<Scalars['Int']['input']>;
  team_official_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Sum_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Var_Pop_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Var_Samp_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.potential_team_reporters" */
export type Lives_Potential_Team_Reporters_Variance_Order_By = {
  live_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lives.spectator" */
export type Lives_Spectator = {
  __typename: 'lives_spectator';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  lives?: Maybe<Array<Lives_Current_Reporters>>;
  name?: Maybe<Scalars['String']['output']>;
  picture_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  posts?: Maybe<Array<Lives_Post>>;
  /** An aggregate relationship */
  posts_aggregate?: Maybe<Lives_Post_Aggregate>;
  /** An array relationship */
  thanks?: Maybe<Array<Lives_Thank_You>>;
  /** An aggregate relationship */
  thanks_aggregate?: Maybe<Lives_Thank_You_Aggregate>;
  /** An object relationship */
  user?: Maybe<Users_User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "lives.spectator" */
export type Lives_SpectatorLivesArgs = {
  distinct_on?: InputMaybe<Array<Lives_Current_Reporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Current_Reporters_Order_By>>;
  where?: InputMaybe<Lives_Current_Reporters_Bool_Exp>;
};

/** columns and relationships of "lives.spectator" */
export type Lives_SpectatorPostsArgs = {
  distinct_on?: InputMaybe<Array<Lives_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Post_Order_By>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

/** columns and relationships of "lives.spectator" */
export type Lives_SpectatorPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Post_Order_By>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

/** columns and relationships of "lives.spectator" */
export type Lives_SpectatorThanksArgs = {
  distinct_on?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Thank_You_Order_By>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

/** columns and relationships of "lives.spectator" */
export type Lives_SpectatorThanks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Thank_You_Order_By>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "lives.spectator". All fields are combined with a logical 'AND'. */
export type Lives_Spectator_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Spectator_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Lives_Spectator_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Spectator_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lives?: InputMaybe<Lives_Current_Reporters_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  picture_url?: InputMaybe<String_Comparison_Exp>;
  posts?: InputMaybe<Lives_Post_Bool_Exp>;
  posts_aggregate?: InputMaybe<Lives_Post_Aggregate_Bool_Exp>;
  thanks?: InputMaybe<Lives_Thank_You_Bool_Exp>;
  thanks_aggregate?: InputMaybe<Lives_Thank_You_Aggregate_Bool_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lives.spectator" */
export enum Lives_Spectator_Constraint {
  /** unique or primary key constraint on columns "id" */
  SpectatorPkey = 'spectator_pkey',
  /** unique or primary key constraint on columns "user_id" */
  SpectatorUserIdKey = 'spectator_user_id_key',
}

/** input type for inserting data into table "lives.spectator" */
export type Lives_Spectator_Insert_Input = {
  posts?: InputMaybe<Lives_Post_Arr_Rel_Insert_Input>;
  thanks?: InputMaybe<Lives_Thank_You_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "lives.spectator" */
export type Lives_Spectator_Mutation_Response = {
  __typename: 'lives_spectator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Lives_Spectator>>;
};

/** input type for inserting object relation for remote table "lives.spectator" */
export type Lives_Spectator_Obj_Rel_Insert_Input = {
  data: Lives_Spectator_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Lives_Spectator_On_Conflict>;
};

/** on_conflict condition type for table "lives.spectator" */
export type Lives_Spectator_On_Conflict = {
  constraint: Lives_Spectator_Constraint;
  update_columns?: Array<Lives_Spectator_Update_Column>;
  where?: InputMaybe<Lives_Spectator_Bool_Exp>;
};

/** Ordering options when selecting data from "lives.spectator". */
export type Lives_Spectator_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lives_aggregate?: InputMaybe<Lives_Current_Reporters_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
  posts_aggregate?: InputMaybe<Lives_Post_Aggregate_Order_By>;
  thanks_aggregate?: InputMaybe<Lives_Thank_You_Aggregate_Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lives.spectator */
export type Lives_Spectator_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "lives.spectator" */
export enum Lives_Spectator_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PictureUrl = 'picture_url',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "lives.spectator" */
export type Lives_Spectator_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "lives_spectator" */
export type Lives_Spectator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Spectator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Spectator_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture_url?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "lives.spectator" */
export enum Lives_Spectator_Update_Column {
  /** column name */
  Updated = '_updated',
}

export type Lives_Spectator_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lives_Spectator_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lives_Spectator_Bool_Exp;
};

/** columns and relationships of "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter = {
  __typename: 'lives_team_official_reporter';
  id: Scalars['Int']['output'];
  is_present?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  /** An object relationship */
  team_official?: Maybe<Teams_Team_Official>;
  team_official_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Team_Official_Reporter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Team_Official_Reporter_Max_Order_By>;
  min?: InputMaybe<Lives_Team_Official_Reporter_Min_Order_By>;
  stddev?: InputMaybe<Lives_Team_Official_Reporter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Team_Official_Reporter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Team_Official_Reporter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Team_Official_Reporter_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Team_Official_Reporter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Team_Official_Reporter_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Team_Official_Reporter_Variance_Order_By>;
};

/** order by avg() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.team_official_reporter". All fields are combined with a logical 'AND'. */
export type Lives_Team_Official_Reporter_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Team_Official_Reporter_Bool_Exp>>;
  _not?: InputMaybe<Lives_Team_Official_Reporter_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Team_Official_Reporter_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_present?: InputMaybe<Boolean_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  team_official?: InputMaybe<Teams_Team_Official_Bool_Exp>;
  team_official_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "lives.team_official_reporter". */
export type Lives_Team_Official_Reporter_Order_By = {
  id?: InputMaybe<Order_By>;
  is_present?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  team_official?: InputMaybe<Teams_Team_Official_Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.team_official_reporter" */
export enum Lives_Team_Official_Reporter_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsPresent = 'is_present',
  /** column name */
  TeamOfficialId = 'team_official_id',
}

/** order by stddev() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_team_official_reporter" */
export type Lives_Team_Official_Reporter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Team_Official_Reporter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Team_Official_Reporter_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_present?: InputMaybe<Scalars['Boolean']['input']>;
  team_official_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.team_official_reporter" */
export type Lives_Team_Official_Reporter_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lives.thank_you" */
export type Lives_Thank_You = {
  __typename: 'lives_thank_you';
  id: Scalars['Int']['output'];
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  live_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  spectator?: Maybe<Lives_Spectator>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "lives.thank_you" */
export type Lives_Thank_You_Aggregate = {
  __typename: 'lives_thank_you_aggregate';
  aggregate?: Maybe<Lives_Thank_You_Aggregate_Fields>;
  nodes?: Maybe<Array<Lives_Thank_You>>;
};

export type Lives_Thank_You_Aggregate_Bool_Exp = {
  count?: InputMaybe<Lives_Thank_You_Aggregate_Bool_Exp_Count>;
};

export type Lives_Thank_You_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Lives_Thank_You_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lives.thank_you" */
export type Lives_Thank_You_Aggregate_Fields = {
  __typename: 'lives_thank_you_aggregate_fields';
  avg?: Maybe<Lives_Thank_You_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Lives_Thank_You_Max_Fields>;
  min?: Maybe<Lives_Thank_You_Min_Fields>;
  stddev?: Maybe<Lives_Thank_You_Stddev_Fields>;
  stddev_pop?: Maybe<Lives_Thank_You_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lives_Thank_You_Stddev_Samp_Fields>;
  sum?: Maybe<Lives_Thank_You_Sum_Fields>;
  var_pop?: Maybe<Lives_Thank_You_Var_Pop_Fields>;
  var_samp?: Maybe<Lives_Thank_You_Var_Samp_Fields>;
  variance?: Maybe<Lives_Thank_You_Variance_Fields>;
};

/** aggregate fields of "lives.thank_you" */
export type Lives_Thank_You_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "lives.thank_you" */
export type Lives_Thank_You_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Thank_You_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Thank_You_Max_Order_By>;
  min?: InputMaybe<Lives_Thank_You_Min_Order_By>;
  stddev?: InputMaybe<Lives_Thank_You_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Thank_You_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Thank_You_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Thank_You_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Thank_You_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Thank_You_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Thank_You_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lives.thank_you" */
export type Lives_Thank_You_Arr_Rel_Insert_Input = {
  data: Array<Lives_Thank_You_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lives_Thank_You_On_Conflict>;
};

/** aggregate avg on columns */
export type Lives_Thank_You_Avg_Fields = {
  __typename: 'lives_thank_you_avg_fields';
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.thank_you". All fields are combined with a logical 'AND'. */
export type Lives_Thank_You_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Thank_You_Bool_Exp>>;
  _not?: InputMaybe<Lives_Thank_You_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Thank_You_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  spectator?: InputMaybe<Lives_Spectator_Bool_Exp>;
  spectator_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "lives.thank_you" */
export enum Lives_Thank_You_Constraint {
  /** unique or primary key constraint on columns "live_id", "spectator_id" */
  ThankYouLiveIdSpectatorIdE0047715Uniq = 'thank_you_live_id_spectator_id_e0047715_uniq',
  /** unique or primary key constraint on columns "id" */
  ThankYouPkey = 'thank_you_pkey',
}

/** input type for inserting data into table "lives.thank_you" */
export type Lives_Thank_You_Insert_Input = {
  live?: InputMaybe<Lives_Live_Obj_Rel_Insert_Input>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
  spectator?: InputMaybe<Lives_Spectator_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Lives_Thank_You_Max_Fields = {
  __typename: 'lives_thank_you_max_fields';
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lives_Thank_You_Min_Fields = {
  __typename: 'lives_thank_you_min_fields';
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lives.thank_you" */
export type Lives_Thank_You_Mutation_Response = {
  __typename: 'lives_thank_you_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Lives_Thank_You>>;
};

/** on_conflict condition type for table "lives.thank_you" */
export type Lives_Thank_You_On_Conflict = {
  constraint: Lives_Thank_You_Constraint;
  update_columns?: Array<Lives_Thank_You_Update_Column>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

/** Ordering options when selecting data from "lives.thank_you". */
export type Lives_Thank_You_Order_By = {
  id?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator?: InputMaybe<Lives_Spectator_Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lives.thank_you */
export type Lives_Thank_You_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "lives.thank_you" */
export enum Lives_Thank_You_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LiveId = 'live_id',
  /** column name */
  SpectatorId = 'spectator_id',
}

/** input type for updating data in table "lives.thank_you" */
export type Lives_Thank_You_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Lives_Thank_You_Stddev_Fields = {
  __typename: 'lives_thank_you_stddev_fields';
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lives_Thank_You_Stddev_Pop_Fields = {
  __typename: 'lives_thank_you_stddev_pop_fields';
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lives_Thank_You_Stddev_Samp_Fields = {
  __typename: 'lives_thank_you_stddev_samp_fields';
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_thank_you" */
export type Lives_Thank_You_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Thank_You_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Thank_You_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
  spectator_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Lives_Thank_You_Sum_Fields = {
  __typename: 'lives_thank_you_sum_fields';
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  spectator_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** update columns of table "lives.thank_you" */
export enum Lives_Thank_You_Update_Column {
  /** column name */
  Updated = '_updated',
}

export type Lives_Thank_You_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lives_Thank_You_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lives_Thank_You_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lives_Thank_You_Var_Pop_Fields = {
  __typename: 'lives_thank_you_var_pop_fields';
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lives_Thank_You_Var_Samp_Fields = {
  __typename: 'lives_thank_you_var_samp_fields';
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lives_Thank_You_Variance_Fields = {
  __typename: 'lives_thank_you_variance_fields';
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  spectator_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "lives.thank_you" */
export type Lives_Thank_You_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  spectator_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lives.tournament_reporter" */
export type Lives_Tournament_Reporter = {
  __typename: 'lives_tournament_reporter';
  id: Scalars['Int']['output'];
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  live_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tournament_reporter?: Maybe<Tournaments_Official_Reporter_V2>;
  tournament_reporter_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Tournament_Reporter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Tournament_Reporter_Max_Order_By>;
  min?: InputMaybe<Lives_Tournament_Reporter_Min_Order_By>;
  stddev?: InputMaybe<Lives_Tournament_Reporter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Tournament_Reporter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Tournament_Reporter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Tournament_Reporter_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Tournament_Reporter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Tournament_Reporter_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Tournament_Reporter_Variance_Order_By>;
};

/** order by avg() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.tournament_reporter". All fields are combined with a logical 'AND'. */
export type Lives_Tournament_Reporter_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Tournament_Reporter_Bool_Exp>>;
  _not?: InputMaybe<Lives_Tournament_Reporter_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Tournament_Reporter_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  tournament_reporter?: InputMaybe<Tournaments_Official_Reporter_V2_Bool_Exp>;
  tournament_reporter_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "lives.tournament_reporter". */
export type Lives_Tournament_Reporter_Order_By = {
  id?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter?: InputMaybe<Tournaments_Official_Reporter_V2_Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.tournament_reporter" */
export enum Lives_Tournament_Reporter_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LiveId = 'live_id',
  /** column name */
  TournamentReporterId = 'tournament_reporter_id',
}

/** order by stddev() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_tournament_reporter" */
export type Lives_Tournament_Reporter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Tournament_Reporter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Tournament_Reporter_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
  tournament_reporter_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.tournament_reporter" */
export type Lives_Tournament_Reporter_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  tournament_reporter_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lives.used_action" */
export type Lives_Used_Action = {
  __typename: 'lives_used_action';
  action_id?: Maybe<Scalars['Int']['output']>;
  live_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "lives.used_action" */
export type Lives_Used_Action_Aggregate_Order_By = {
  avg?: InputMaybe<Lives_Used_Action_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lives_Used_Action_Max_Order_By>;
  min?: InputMaybe<Lives_Used_Action_Min_Order_By>;
  stddev?: InputMaybe<Lives_Used_Action_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lives_Used_Action_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lives_Used_Action_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lives_Used_Action_Sum_Order_By>;
  var_pop?: InputMaybe<Lives_Used_Action_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lives_Used_Action_Var_Samp_Order_By>;
  variance?: InputMaybe<Lives_Used_Action_Variance_Order_By>;
};

/** order by avg() on columns of table "lives.used_action" */
export type Lives_Used_Action_Avg_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lives.used_action". All fields are combined with a logical 'AND'. */
export type Lives_Used_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Lives_Used_Action_Bool_Exp>>;
  _not?: InputMaybe<Lives_Used_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Lives_Used_Action_Bool_Exp>>;
  action_id?: InputMaybe<Int_Comparison_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "lives.used_action" */
export type Lives_Used_Action_Max_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "lives.used_action" */
export type Lives_Used_Action_Min_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "lives.used_action". */
export type Lives_Used_Action_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** select columns of table "lives.used_action" */
export enum Lives_Used_Action_Select_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  LiveId = 'live_id',
}

/** order by stddev() on columns of table "lives.used_action" */
export type Lives_Used_Action_Stddev_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "lives.used_action" */
export type Lives_Used_Action_Stddev_Pop_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "lives.used_action" */
export type Lives_Used_Action_Stddev_Samp_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lives_used_action" */
export type Lives_Used_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lives_Used_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lives_Used_Action_Stream_Cursor_Value_Input = {
  action_id?: InputMaybe<Scalars['Int']['input']>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "lives.used_action" */
export type Lives_Used_Action_Sum_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "lives.used_action" */
export type Lives_Used_Action_Var_Pop_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "lives.used_action" */
export type Lives_Used_Action_Var_Samp_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "lives.used_action" */
export type Lives_Used_Action_Variance_Order_By = {
  action_id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "medias.media" */
export type Medias_Media = {
  __typename: 'medias_media';
  id: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "medias.media". All fields are combined with a logical 'AND'. */
export type Medias_Media_Bool_Exp = {
  _and?: InputMaybe<Array<Medias_Media_Bool_Exp>>;
  _not?: InputMaybe<Medias_Media_Bool_Exp>;
  _or?: InputMaybe<Array<Medias_Media_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "medias.media". */
export type Medias_Media_Order_By = {
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "medias.media" */
export enum Medias_Media_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "medias_media" */
export type Medias_Media_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medias_Media_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medias_Media_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "medias.official_reporter" */
export type Medias_Official_Reporter = {
  __typename: 'medias_official_reporter';
  id: Scalars['Int']['output'];
  /** An object relationship */
  media?: Maybe<Medias_Media>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "medias.official_reporter". All fields are combined with a logical 'AND'. */
export type Medias_Official_Reporter_Bool_Exp = {
  _and?: InputMaybe<Array<Medias_Official_Reporter_Bool_Exp>>;
  _not?: InputMaybe<Medias_Official_Reporter_Bool_Exp>;
  _or?: InputMaybe<Array<Medias_Official_Reporter_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  media?: InputMaybe<Medias_Media_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "medias.official_reporter". */
export type Medias_Official_Reporter_Order_By = {
  id?: InputMaybe<Order_By>;
  media?: InputMaybe<Medias_Media_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "medias.official_reporter" */
export enum Medias_Official_Reporter_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "medias_official_reporter" */
export type Medias_Official_Reporter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Medias_Official_Reporter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Medias_Official_Reporter_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "members.export" */
export type Members_Export = {
  __typename: 'members_export';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  admin_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  end_of_season?: Maybe<Scalars['Boolean']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

/** columns and relationships of "members.export" */
export type Members_ExportAdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "members.export". All fields are combined with a logical 'AND'. */
export type Members_Export_Bool_Exp = {
  _and?: InputMaybe<Array<Members_Export_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Members_Export_Bool_Exp>;
  _or?: InputMaybe<Array<Members_Export_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  admin_id?: InputMaybe<Int_Comparison_Exp>;
  admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  end_of_season?: InputMaybe<Boolean_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "members.export" */
export enum Members_Export_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExportPkey = 'export_pkey',
}

/** input type for inserting data into table "members.export" */
export type Members_Export_Insert_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "members.export" */
export type Members_Export_Mutation_Response = {
  __typename: 'members_export_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Members_Export>>;
};

/** on_conflict condition type for table "members.export" */
export type Members_Export_On_Conflict = {
  constraint: Members_Export_Constraint;
  update_columns?: Array<Members_Export_Update_Column>;
  where?: InputMaybe<Members_Export_Bool_Exp>;
};

/** Ordering options when selecting data from "members.export". */
export type Members_Export_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  admin_id?: InputMaybe<Order_By>;
  admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  end_of_season?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "members.export" */
export enum Members_Export_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AdminId = 'admin_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  EndOfSeason = 'end_of_season',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "members_export" */
export type Members_Export_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Members_Export_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Members_Export_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  admin_id?: InputMaybe<Scalars['Int']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  end_of_season?: InputMaybe<Scalars['Boolean']['input']>;
  file_path?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** placeholder for update columns of table "members.export" (current role has no relevant permissions) */
export enum Members_Export_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "members.group" */
export type Members_Group = {
  __typename: 'members_group';
  /** An array relationship */
  admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  members?: Maybe<Array<Members_Member_In_Group>>;
  name?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "members.group" */
export type Members_GroupAdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "members.group" */
export type Members_GroupMembersArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_In_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_In_Group_Order_By>>;
  where?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "members.group". All fields are combined with a logical 'AND'. */
export type Members_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Members_Group_Bool_Exp>>;
  _not?: InputMaybe<Members_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Members_Group_Bool_Exp>>;
  admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  emoji?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  members?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "members.group" */
export enum Members_Group_Constraint {
  /** unique or primary key constraint on columns "name", "club_id" */
  GroupClubIdName_17bf146dUniq = 'group_club_id_name_17bf146d_uniq',
  /** unique or primary key constraint on columns "id" */
  GroupPkey = 'group_pkey',
}

/** input type for inserting data into table "members.group" */
export type Members_Group_Insert_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<Members_Member_In_Group_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "members.group" */
export type Members_Group_Mutation_Response = {
  __typename: 'members_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Members_Group>>;
};

/** input type for inserting object relation for remote table "members.group" */
export type Members_Group_Obj_Rel_Insert_Input = {
  data: Members_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Members_Group_On_Conflict>;
};

/** on_conflict condition type for table "members.group" */
export type Members_Group_On_Conflict = {
  constraint: Members_Group_Constraint;
  update_columns?: Array<Members_Group_Update_Column>;
  where?: InputMaybe<Members_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "members.group". */
export type Members_Group_Order_By = {
  admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Members_Member_In_Group_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: members.group */
export type Members_Group_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "members.group" */
export enum Members_Group_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Color = 'color',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "members.group" */
export type Members_Group_Set_Input = {
  color?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "members_group" */
export type Members_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Members_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Members_Group_Stream_Cursor_Value_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "members.group" */
export enum Members_Group_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  Name = 'name',
}

export type Members_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Members_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Members_Group_Bool_Exp;
};

/** columns and relationships of "members.member" */
export type Members_Member = {
  __typename: 'members_member';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  added_by_admin_id?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  allow_to_share_picture?: Maybe<Scalars['Boolean']['output']>;
  bar_manager_count?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  bar_managers?: Maybe<Array<Competitions_Playing_Team_Bar_Manager>>;
  birth_date?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  /** An array relationship */
  club_admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_2?: Maybe<Scalars['String']['output']>;
  email_3?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  groups?: Maybe<Array<Members_Member_In_Group>>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  invitation?: Maybe<Users_Member_Invitation>;
  is_admin?: Maybe<Scalars['Boolean']['output']>;
  is_currently_in_the_club?: Maybe<Scalars['Boolean']['output']>;
  is_exempt_volunteer?: Maybe<Scalars['Boolean']['output']>;
  is_reporter?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  licence?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  official_reporter_teams?: Maybe<Array<Members_Team_Official_Reporter>>;
  /** An aggregate relationship */
  official_reporter_teams_aggregate?: Maybe<Members_Team_Official_Reporter_Aggregate>;
  /** A computed field, executes function "permissions.member_permissions" */
  permissions?: Maybe<Array<Permissions_Member_Permission>>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_2?: Maybe<Scalars['String']['output']>;
  phone_3?: Maybe<Scalars['String']['output']>;
  picture_url?: Maybe<Scalars['String']['output']>;
  receive_club_emails?: Maybe<Scalars['Boolean']['output']>;
  receive_club_sms?: Maybe<Scalars['Boolean']['output']>;
  referee_count?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  referees?: Maybe<Array<Competitions_Playing_Team_Referee>>;
  scoring_table_count?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  scoring_tables?: Maybe<Array<Competitions_Playing_Team_Scoring_Table>>;
  stadium_manager_count?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  stadium_managers?: Maybe<Array<Competitions_Playing_Team_Stadium_Manager>>;
  /** An array relationship */
  tasks_counts?: Maybe<Array<Tasks_Member_Task_Count>>;
  /** An array relationship */
  teams?: Maybe<Array<Teams_Team_In_Season_Has_Member>>;
  /** An aggregate relationship */
  teams_aggregate?: Maybe<Teams_Team_In_Season_Has_Member_Aggregate>;
  upgrade_type?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  user?: Maybe<Users_User>;
  /** An array relationship */
  user_emails?: Maybe<Array<Users_User>>;
  user_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  volunteers?: Maybe<Array<Tasks_Volunteer>>;
};

/** columns and relationships of "members.member" */
export type Members_MemberBar_ManagersArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Bar_Manager_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Bar_Manager_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberClub_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberGroupsArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_In_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_In_Group_Order_By>>;
  where?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberOfficial_Reporter_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Members_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberOfficial_Reporter_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Members_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Member_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Member_Permission_Order_By>>;
  where?: InputMaybe<Permissions_Member_Permission_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberRefereesArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Referee_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Referee_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberScoring_TablesArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Scoring_Table_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Playing_Team_Scoring_Table_Order_By>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberStadium_ManagersArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Stadium_Manager_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Playing_Team_Stadium_Manager_Order_By>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberTasks_CountsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Member_Task_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Member_Task_Count_Order_By>>;
  where?: InputMaybe<Tasks_Member_Task_Count_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberTeamsArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Has_Member_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberTeams_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Has_Member_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberUser_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Users_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_User_Order_By>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};

/** columns and relationships of "members.member" */
export type Members_MemberVolunteersArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Volunteer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Volunteer_Order_By>>;
  where?: InputMaybe<Tasks_Volunteer_Bool_Exp>;
};

/** aggregated selection of "members.member" */
export type Members_Member_Aggregate = {
  __typename: 'members_member_aggregate';
  aggregate?: Maybe<Members_Member_Aggregate_Fields>;
  nodes?: Maybe<Array<Members_Member>>;
};

export type Members_Member_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Members_Member_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Members_Member_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Members_Member_Aggregate_Bool_Exp_Count>;
};

export type Members_Member_Aggregate_Bool_Exp_Bool_And = {
  arguments: Members_Member_Select_Column_Members_Member_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Members_Member_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Members_Member_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Members_Member_Select_Column_Members_Member_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Members_Member_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Members_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Members_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Members_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "members.member" */
export type Members_Member_Aggregate_Fields = {
  __typename: 'members_member_aggregate_fields';
  avg?: Maybe<Members_Member_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Members_Member_Max_Fields>;
  min?: Maybe<Members_Member_Min_Fields>;
  stddev?: Maybe<Members_Member_Stddev_Fields>;
  stddev_pop?: Maybe<Members_Member_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Members_Member_Stddev_Samp_Fields>;
  sum?: Maybe<Members_Member_Sum_Fields>;
  var_pop?: Maybe<Members_Member_Var_Pop_Fields>;
  var_samp?: Maybe<Members_Member_Var_Samp_Fields>;
  variance?: Maybe<Members_Member_Variance_Fields>;
};

/** aggregate fields of "members.member" */
export type Members_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Members_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "members.member" */
export type Members_Member_Aggregate_Order_By = {
  avg?: InputMaybe<Members_Member_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Members_Member_Max_Order_By>;
  min?: InputMaybe<Members_Member_Min_Order_By>;
  stddev?: InputMaybe<Members_Member_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Members_Member_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Members_Member_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Members_Member_Sum_Order_By>;
  var_pop?: InputMaybe<Members_Member_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Members_Member_Var_Samp_Order_By>;
  variance?: InputMaybe<Members_Member_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Members_Member_Avg_Fields = {
  __typename: 'members_member_avg_fields';
  added_by_admin_id?: Maybe<Scalars['Float']['output']>;
  bar_manager_count?: Maybe<Scalars['Float']['output']>;
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  referee_count?: Maybe<Scalars['Float']['output']>;
  scoring_table_count?: Maybe<Scalars['Float']['output']>;
  stadium_manager_count?: Maybe<Scalars['Float']['output']>;
  upgrade_type?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "members.member" */
export type Members_Member_Avg_Order_By = {
  added_by_admin_id?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "members.member". All fields are combined with a logical 'AND'. */
export type Members_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Members_Member_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Members_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Members_Member_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  added_by_admin_id?: InputMaybe<Int_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  allow_to_share_picture?: InputMaybe<Boolean_Comparison_Exp>;
  bar_manager_count?: InputMaybe<Smallint_Comparison_Exp>;
  bar_managers?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
  birth_date?: InputMaybe<Date_Comparison_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_2?: InputMaybe<String_Comparison_Exp>;
  email_3?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  groups?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invitation?: InputMaybe<Users_Member_Invitation_Bool_Exp>;
  is_admin?: InputMaybe<Boolean_Comparison_Exp>;
  is_currently_in_the_club?: InputMaybe<Boolean_Comparison_Exp>;
  is_exempt_volunteer?: InputMaybe<Boolean_Comparison_Exp>;
  is_reporter?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  licence?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  official_reporter_teams?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
  official_reporter_teams_aggregate?: InputMaybe<Members_Team_Official_Reporter_Aggregate_Bool_Exp>;
  permissions?: InputMaybe<Permissions_Member_Permission_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  phone_2?: InputMaybe<String_Comparison_Exp>;
  phone_3?: InputMaybe<String_Comparison_Exp>;
  picture_url?: InputMaybe<String_Comparison_Exp>;
  receive_club_emails?: InputMaybe<Boolean_Comparison_Exp>;
  receive_club_sms?: InputMaybe<Boolean_Comparison_Exp>;
  referee_count?: InputMaybe<Smallint_Comparison_Exp>;
  referees?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
  scoring_table_count?: InputMaybe<Smallint_Comparison_Exp>;
  scoring_tables?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
  stadium_manager_count?: InputMaybe<Smallint_Comparison_Exp>;
  stadium_managers?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
  tasks_counts?: InputMaybe<Tasks_Member_Task_Count_Bool_Exp>;
  teams?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
  teams_aggregate?: InputMaybe<Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp>;
  upgrade_type?: InputMaybe<Smallint_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_emails?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  volunteers?: InputMaybe<Tasks_Volunteer_Bool_Exp>;
};

/** unique or primary key constraints on table "members.member" */
export enum Members_Member_Constraint {
  /** unique or primary key constraint on columns "id" */
  MemberPkey = 'member_pkey',
}

/** columns and relationships of "members.member_in_group" */
export type Members_Member_In_Group = {
  __typename: 'members_member_in_group';
  /** An object relationship */
  group?: Maybe<Members_Group>;
  group_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "members.member_in_group" */
export type Members_Member_In_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Members_Member_In_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Members_Member_In_Group_Max_Order_By>;
  min?: InputMaybe<Members_Member_In_Group_Min_Order_By>;
  stddev?: InputMaybe<Members_Member_In_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Members_Member_In_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Members_Member_In_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Members_Member_In_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Members_Member_In_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Members_Member_In_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Members_Member_In_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "members.member_in_group" */
export type Members_Member_In_Group_Arr_Rel_Insert_Input = {
  data: Array<Members_Member_In_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Members_Member_In_Group_On_Conflict>;
};

/** order by avg() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "members.member_in_group". All fields are combined with a logical 'AND'. */
export type Members_Member_In_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Members_Member_In_Group_Bool_Exp>>;
  _not?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Members_Member_In_Group_Bool_Exp>>;
  group?: InputMaybe<Members_Group_Bool_Exp>;
  group_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "members.member_in_group" */
export enum Members_Member_In_Group_Constraint {
  /** unique or primary key constraint on columns "member_id", "group_id" */
  MemberInGroupMemberIdGroupId_4bd7a055Uniq = 'member_in_group_member_id_group_id_4bd7a055_uniq',
  /** unique or primary key constraint on columns "id" */
  MemberInGroupPkey = 'member_in_group_pkey',
}

/** input type for inserting data into table "members.member_in_group" */
export type Members_Member_In_Group_Insert_Input = {
  group?: InputMaybe<Members_Group_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['Int']['input']>;
  member?: InputMaybe<Members_Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "members.member_in_group" */
export type Members_Member_In_Group_Mutation_Response = {
  __typename: 'members_member_in_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Members_Member_In_Group>>;
};

/** on_conflict condition type for table "members.member_in_group" */
export type Members_Member_In_Group_On_Conflict = {
  constraint: Members_Member_In_Group_Constraint;
  update_columns?: Array<Members_Member_In_Group_Update_Column>;
  where?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "members.member_in_group". */
export type Members_Member_In_Group_Order_By = {
  group?: InputMaybe<Members_Group_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: members.member_in_group */
export type Members_Member_In_Group_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "members.member_in_group" */
export enum Members_Member_In_Group_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
}

/** input type for updating data in table "members.member_in_group" */
export type Members_Member_In_Group_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by stddev() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "members_member_in_group" */
export type Members_Member_In_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Members_Member_In_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Members_Member_In_Group_Stream_Cursor_Value_Input = {
  group_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** update columns of table "members.member_in_group" */
export enum Members_Member_In_Group_Update_Column {
  /** column name */
  Updated = '_updated',
}

export type Members_Member_In_Group_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Members_Member_In_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Members_Member_In_Group_Bool_Exp;
};

/** order by var_pop() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "members.member_in_group" */
export type Members_Member_In_Group_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "members.member" */
export type Members_Member_Inc_Input = {
  bar_manager_count?: InputMaybe<Scalars['smallint']['input']>;
  referee_count?: InputMaybe<Scalars['smallint']['input']>;
  scoring_table_count?: InputMaybe<Scalars['smallint']['input']>;
  stadium_manager_count?: InputMaybe<Scalars['smallint']['input']>;
  upgrade_type?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "members.member" */
export type Members_Member_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  allow_to_share_picture?: InputMaybe<Scalars['Boolean']['input']>;
  bar_managers?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Arr_Rel_Insert_Input>;
  birth_date?: InputMaybe<Scalars['date']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_2?: InputMaybe<Scalars['String']['input']>;
  email_3?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Members_Member_In_Group_Arr_Rel_Insert_Input>;
  is_admin?: InputMaybe<Scalars['Boolean']['input']>;
  is_currently_in_the_club?: InputMaybe<Scalars['Boolean']['input']>;
  is_exempt_volunteer?: InputMaybe<Scalars['Boolean']['input']>;
  is_reporter?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  licence?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_2?: InputMaybe<Scalars['String']['input']>;
  phone_3?: InputMaybe<Scalars['String']['input']>;
  picture_url?: InputMaybe<Scalars['String']['input']>;
  receive_club_emails?: InputMaybe<Scalars['Boolean']['input']>;
  receive_club_sms?: InputMaybe<Scalars['Boolean']['input']>;
  referees?: InputMaybe<Competitions_Playing_Team_Referee_Arr_Rel_Insert_Input>;
  scoring_tables?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Arr_Rel_Insert_Input>;
  stadium_managers?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Arr_Rel_Insert_Input>;
  tasks_counts?: InputMaybe<Tasks_Member_Task_Count_Arr_Rel_Insert_Input>;
  teams?: InputMaybe<Teams_Team_In_Season_Has_Member_Arr_Rel_Insert_Input>;
  upgrade_type?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate max on columns */
export type Members_Member_Max_Fields = {
  __typename: 'members_member_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  added_by_admin_id?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  bar_manager_count?: Maybe<Scalars['smallint']['output']>;
  birth_date?: Maybe<Scalars['date']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_2?: Maybe<Scalars['String']['output']>;
  email_3?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  licence?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_2?: Maybe<Scalars['String']['output']>;
  phone_3?: Maybe<Scalars['String']['output']>;
  picture_url?: Maybe<Scalars['String']['output']>;
  referee_count?: Maybe<Scalars['smallint']['output']>;
  scoring_table_count?: Maybe<Scalars['smallint']['output']>;
  stadium_manager_count?: Maybe<Scalars['smallint']['output']>;
  upgrade_type?: Maybe<Scalars['smallint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "members.member" */
export type Members_Member_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  added_by_admin_id?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  birth_date?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_2?: InputMaybe<Order_By>;
  email_3?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  licence?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  phone_2?: InputMaybe<Order_By>;
  phone_3?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Members_Member_Min_Fields = {
  __typename: 'members_member_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  added_by_admin_id?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  bar_manager_count?: Maybe<Scalars['smallint']['output']>;
  birth_date?: Maybe<Scalars['date']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_2?: Maybe<Scalars['String']['output']>;
  email_3?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  licence?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_2?: Maybe<Scalars['String']['output']>;
  phone_3?: Maybe<Scalars['String']['output']>;
  picture_url?: Maybe<Scalars['String']['output']>;
  referee_count?: Maybe<Scalars['smallint']['output']>;
  scoring_table_count?: Maybe<Scalars['smallint']['output']>;
  stadium_manager_count?: Maybe<Scalars['smallint']['output']>;
  upgrade_type?: Maybe<Scalars['smallint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "members.member" */
export type Members_Member_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  added_by_admin_id?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  birth_date?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_2?: InputMaybe<Order_By>;
  email_3?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  licence?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  phone_2?: InputMaybe<Order_By>;
  phone_3?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "members.member" */
export type Members_Member_Mutation_Response = {
  __typename: 'members_member_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Members_Member>>;
};

/** input type for inserting object relation for remote table "members.member" */
export type Members_Member_Obj_Rel_Insert_Input = {
  data: Members_Member_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Members_Member_On_Conflict>;
};

/** on_conflict condition type for table "members.member" */
export type Members_Member_On_Conflict = {
  constraint: Members_Member_Constraint;
  update_columns?: Array<Members_Member_Update_Column>;
  where?: InputMaybe<Members_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "members.member". */
export type Members_Member_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  added_by_admin_id?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  allow_to_share_picture?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  bar_managers_aggregate?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Aggregate_Order_By>;
  birth_date?: InputMaybe<Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_2?: InputMaybe<Order_By>;
  email_3?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Members_Member_In_Group_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invitation?: InputMaybe<Users_Member_Invitation_Order_By>;
  is_admin?: InputMaybe<Order_By>;
  is_currently_in_the_club?: InputMaybe<Order_By>;
  is_exempt_volunteer?: InputMaybe<Order_By>;
  is_reporter?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  licence?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  official_reporter_teams_aggregate?: InputMaybe<Members_Team_Official_Reporter_Aggregate_Order_By>;
  permissions_aggregate?: InputMaybe<Permissions_Member_Permission_Aggregate_Order_By>;
  phone?: InputMaybe<Order_By>;
  phone_2?: InputMaybe<Order_By>;
  phone_3?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
  receive_club_emails?: InputMaybe<Order_By>;
  receive_club_sms?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  referees_aggregate?: InputMaybe<Competitions_Playing_Team_Referee_Aggregate_Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  scoring_tables_aggregate?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Aggregate_Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  stadium_managers_aggregate?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Aggregate_Order_By>;
  tasks_counts_aggregate?: InputMaybe<Tasks_Member_Task_Count_Aggregate_Order_By>;
  teams_aggregate?: InputMaybe<Teams_Team_In_Season_Has_Member_Aggregate_Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_emails_aggregate?: InputMaybe<Users_User_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
  volunteers_aggregate?: InputMaybe<Tasks_Volunteer_Aggregate_Order_By>;
};

/** primary key columns input for table: members.member */
export type Members_Member_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "members.member" */
export enum Members_Member_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AddedByAdminId = 'added_by_admin_id',
  /** column name */
  Address = 'address',
  /** column name */
  AllowToSharePicture = 'allow_to_share_picture',
  /** column name */
  BarManagerCount = 'bar_manager_count',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Email = 'email',
  /** column name */
  Email_2 = 'email_2',
  /** column name */
  Email_3 = 'email_3',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsCurrentlyInTheClub = 'is_currently_in_the_club',
  /** column name */
  IsExemptVolunteer = 'is_exempt_volunteer',
  /** column name */
  IsReporter = 'is_reporter',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Licence = 'licence',
  /** column name */
  Note = 'note',
  /** column name */
  Phone = 'phone',
  /** column name */
  Phone_2 = 'phone_2',
  /** column name */
  Phone_3 = 'phone_3',
  /** column name */
  PictureUrl = 'picture_url',
  /** column name */
  ReceiveClubEmails = 'receive_club_emails',
  /** column name */
  ReceiveClubSms = 'receive_club_sms',
  /** column name */
  RefereeCount = 'referee_count',
  /** column name */
  ScoringTableCount = 'scoring_table_count',
  /** column name */
  StadiumManagerCount = 'stadium_manager_count',
  /** column name */
  UpgradeType = 'upgrade_type',
  /** column name */
  UserId = 'user_id',
}

/** select "members_member_aggregate_bool_exp_bool_and_arguments_columns" columns of table "members.member" */
export enum Members_Member_Select_Column_Members_Member_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowToSharePicture = 'allow_to_share_picture',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsCurrentlyInTheClub = 'is_currently_in_the_club',
  /** column name */
  IsExemptVolunteer = 'is_exempt_volunteer',
  /** column name */
  IsReporter = 'is_reporter',
  /** column name */
  ReceiveClubEmails = 'receive_club_emails',
  /** column name */
  ReceiveClubSms = 'receive_club_sms',
}

/** select "members_member_aggregate_bool_exp_bool_or_arguments_columns" columns of table "members.member" */
export enum Members_Member_Select_Column_Members_Member_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowToSharePicture = 'allow_to_share_picture',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsCurrentlyInTheClub = 'is_currently_in_the_club',
  /** column name */
  IsExemptVolunteer = 'is_exempt_volunteer',
  /** column name */
  IsReporter = 'is_reporter',
  /** column name */
  ReceiveClubEmails = 'receive_club_emails',
  /** column name */
  ReceiveClubSms = 'receive_club_sms',
}

/** input type for updating data in table "members.member" */
export type Members_Member_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  allow_to_share_picture?: InputMaybe<Scalars['Boolean']['input']>;
  bar_manager_count?: InputMaybe<Scalars['smallint']['input']>;
  birth_date?: InputMaybe<Scalars['date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_2?: InputMaybe<Scalars['String']['input']>;
  email_3?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  is_admin?: InputMaybe<Scalars['Boolean']['input']>;
  is_currently_in_the_club?: InputMaybe<Scalars['Boolean']['input']>;
  is_exempt_volunteer?: InputMaybe<Scalars['Boolean']['input']>;
  is_reporter?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  licence?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_2?: InputMaybe<Scalars['String']['input']>;
  phone_3?: InputMaybe<Scalars['String']['input']>;
  picture_url?: InputMaybe<Scalars['String']['input']>;
  receive_club_emails?: InputMaybe<Scalars['Boolean']['input']>;
  receive_club_sms?: InputMaybe<Scalars['Boolean']['input']>;
  referee_count?: InputMaybe<Scalars['smallint']['input']>;
  scoring_table_count?: InputMaybe<Scalars['smallint']['input']>;
  stadium_manager_count?: InputMaybe<Scalars['smallint']['input']>;
  upgrade_type?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate stddev on columns */
export type Members_Member_Stddev_Fields = {
  __typename: 'members_member_stddev_fields';
  added_by_admin_id?: Maybe<Scalars['Float']['output']>;
  bar_manager_count?: Maybe<Scalars['Float']['output']>;
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  referee_count?: Maybe<Scalars['Float']['output']>;
  scoring_table_count?: Maybe<Scalars['Float']['output']>;
  stadium_manager_count?: Maybe<Scalars['Float']['output']>;
  upgrade_type?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "members.member" */
export type Members_Member_Stddev_Order_By = {
  added_by_admin_id?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Members_Member_Stddev_Pop_Fields = {
  __typename: 'members_member_stddev_pop_fields';
  added_by_admin_id?: Maybe<Scalars['Float']['output']>;
  bar_manager_count?: Maybe<Scalars['Float']['output']>;
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  referee_count?: Maybe<Scalars['Float']['output']>;
  scoring_table_count?: Maybe<Scalars['Float']['output']>;
  stadium_manager_count?: Maybe<Scalars['Float']['output']>;
  upgrade_type?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "members.member" */
export type Members_Member_Stddev_Pop_Order_By = {
  added_by_admin_id?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Members_Member_Stddev_Samp_Fields = {
  __typename: 'members_member_stddev_samp_fields';
  added_by_admin_id?: Maybe<Scalars['Float']['output']>;
  bar_manager_count?: Maybe<Scalars['Float']['output']>;
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  referee_count?: Maybe<Scalars['Float']['output']>;
  scoring_table_count?: Maybe<Scalars['Float']['output']>;
  stadium_manager_count?: Maybe<Scalars['Float']['output']>;
  upgrade_type?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "members.member" */
export type Members_Member_Stddev_Samp_Order_By = {
  added_by_admin_id?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "members_member" */
export type Members_Member_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Members_Member_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Members_Member_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  added_by_admin_id?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  allow_to_share_picture?: InputMaybe<Scalars['Boolean']['input']>;
  bar_manager_count?: InputMaybe<Scalars['smallint']['input']>;
  birth_date?: InputMaybe<Scalars['date']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_2?: InputMaybe<Scalars['String']['input']>;
  email_3?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_admin?: InputMaybe<Scalars['Boolean']['input']>;
  is_currently_in_the_club?: InputMaybe<Scalars['Boolean']['input']>;
  is_exempt_volunteer?: InputMaybe<Scalars['Boolean']['input']>;
  is_reporter?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  licence?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_2?: InputMaybe<Scalars['String']['input']>;
  phone_3?: InputMaybe<Scalars['String']['input']>;
  picture_url?: InputMaybe<Scalars['String']['input']>;
  receive_club_emails?: InputMaybe<Scalars['Boolean']['input']>;
  receive_club_sms?: InputMaybe<Scalars['Boolean']['input']>;
  referee_count?: InputMaybe<Scalars['smallint']['input']>;
  scoring_table_count?: InputMaybe<Scalars['smallint']['input']>;
  stadium_manager_count?: InputMaybe<Scalars['smallint']['input']>;
  upgrade_type?: InputMaybe<Scalars['smallint']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Members_Member_Sum_Fields = {
  __typename: 'members_member_sum_fields';
  added_by_admin_id?: Maybe<Scalars['Int']['output']>;
  bar_manager_count?: Maybe<Scalars['smallint']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  referee_count?: Maybe<Scalars['smallint']['output']>;
  scoring_table_count?: Maybe<Scalars['smallint']['output']>;
  stadium_manager_count?: Maybe<Scalars['smallint']['output']>;
  upgrade_type?: Maybe<Scalars['smallint']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "members.member" */
export type Members_Member_Sum_Order_By = {
  added_by_admin_id?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "members.member" */
export enum Members_Member_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AllowToSharePicture = 'allow_to_share_picture',
  /** column name */
  BarManagerCount = 'bar_manager_count',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  Email = 'email',
  /** column name */
  Email_2 = 'email_2',
  /** column name */
  Email_3 = 'email_3',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsCurrentlyInTheClub = 'is_currently_in_the_club',
  /** column name */
  IsExemptVolunteer = 'is_exempt_volunteer',
  /** column name */
  IsReporter = 'is_reporter',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Licence = 'licence',
  /** column name */
  Note = 'note',
  /** column name */
  Phone = 'phone',
  /** column name */
  Phone_2 = 'phone_2',
  /** column name */
  Phone_3 = 'phone_3',
  /** column name */
  PictureUrl = 'picture_url',
  /** column name */
  ReceiveClubEmails = 'receive_club_emails',
  /** column name */
  ReceiveClubSms = 'receive_club_sms',
  /** column name */
  RefereeCount = 'referee_count',
  /** column name */
  ScoringTableCount = 'scoring_table_count',
  /** column name */
  StadiumManagerCount = 'stadium_manager_count',
  /** column name */
  UpgradeType = 'upgrade_type',
}

export type Members_Member_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Members_Member_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Members_Member_Set_Input>;
  /** filter the rows which have to be updated */
  where: Members_Member_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Members_Member_Var_Pop_Fields = {
  __typename: 'members_member_var_pop_fields';
  added_by_admin_id?: Maybe<Scalars['Float']['output']>;
  bar_manager_count?: Maybe<Scalars['Float']['output']>;
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  referee_count?: Maybe<Scalars['Float']['output']>;
  scoring_table_count?: Maybe<Scalars['Float']['output']>;
  stadium_manager_count?: Maybe<Scalars['Float']['output']>;
  upgrade_type?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "members.member" */
export type Members_Member_Var_Pop_Order_By = {
  added_by_admin_id?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Members_Member_Var_Samp_Fields = {
  __typename: 'members_member_var_samp_fields';
  added_by_admin_id?: Maybe<Scalars['Float']['output']>;
  bar_manager_count?: Maybe<Scalars['Float']['output']>;
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  referee_count?: Maybe<Scalars['Float']['output']>;
  scoring_table_count?: Maybe<Scalars['Float']['output']>;
  stadium_manager_count?: Maybe<Scalars['Float']['output']>;
  upgrade_type?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "members.member" */
export type Members_Member_Var_Samp_Order_By = {
  added_by_admin_id?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Members_Member_Variance_Fields = {
  __typename: 'members_member_variance_fields';
  added_by_admin_id?: Maybe<Scalars['Float']['output']>;
  bar_manager_count?: Maybe<Scalars['Float']['output']>;
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  referee_count?: Maybe<Scalars['Float']['output']>;
  scoring_table_count?: Maybe<Scalars['Float']['output']>;
  stadium_manager_count?: Maybe<Scalars['Float']['output']>;
  upgrade_type?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "members.member" */
export type Members_Member_Variance_Order_By = {
  added_by_admin_id?: InputMaybe<Order_By>;
  bar_manager_count?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referee_count?: InputMaybe<Order_By>;
  scoring_table_count?: InputMaybe<Order_By>;
  stadium_manager_count?: InputMaybe<Order_By>;
  upgrade_type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "members.team_official_reporter" */
export type Members_Team_Official_Reporter = {
  __typename: 'members_team_official_reporter';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team_official?: Maybe<Teams_Team_Official>;
  team_official_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Aggregate = {
  __typename: 'members_team_official_reporter_aggregate';
  aggregate?: Maybe<Members_Team_Official_Reporter_Aggregate_Fields>;
  nodes?: Maybe<Array<Members_Team_Official_Reporter>>;
};

export type Members_Team_Official_Reporter_Aggregate_Bool_Exp = {
  count?: InputMaybe<Members_Team_Official_Reporter_Aggregate_Bool_Exp_Count>;
};

export type Members_Team_Official_Reporter_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Members_Team_Official_Reporter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Aggregate_Fields = {
  __typename: 'members_team_official_reporter_aggregate_fields';
  avg?: Maybe<Members_Team_Official_Reporter_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Members_Team_Official_Reporter_Max_Fields>;
  min?: Maybe<Members_Team_Official_Reporter_Min_Fields>;
  stddev?: Maybe<Members_Team_Official_Reporter_Stddev_Fields>;
  stddev_pop?: Maybe<Members_Team_Official_Reporter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Members_Team_Official_Reporter_Stddev_Samp_Fields>;
  sum?: Maybe<Members_Team_Official_Reporter_Sum_Fields>;
  var_pop?: Maybe<Members_Team_Official_Reporter_Var_Pop_Fields>;
  var_samp?: Maybe<Members_Team_Official_Reporter_Var_Samp_Fields>;
  variance?: Maybe<Members_Team_Official_Reporter_Variance_Fields>;
};

/** aggregate fields of "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Members_Team_Official_Reporter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Aggregate_Order_By = {
  avg?: InputMaybe<Members_Team_Official_Reporter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Members_Team_Official_Reporter_Max_Order_By>;
  min?: InputMaybe<Members_Team_Official_Reporter_Min_Order_By>;
  stddev?: InputMaybe<Members_Team_Official_Reporter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Members_Team_Official_Reporter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Members_Team_Official_Reporter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Members_Team_Official_Reporter_Sum_Order_By>;
  var_pop?: InputMaybe<Members_Team_Official_Reporter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Members_Team_Official_Reporter_Var_Samp_Order_By>;
  variance?: InputMaybe<Members_Team_Official_Reporter_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Members_Team_Official_Reporter_Avg_Fields = {
  __typename: 'members_team_official_reporter_avg_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  team_official_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "members.team_official_reporter". All fields are combined with a logical 'AND'. */
export type Members_Team_Official_Reporter_Bool_Exp = {
  _and?: InputMaybe<Array<Members_Team_Official_Reporter_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
  _or?: InputMaybe<Array<Members_Team_Official_Reporter_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  team_official?: InputMaybe<Teams_Team_Official_Bool_Exp>;
  team_official_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Members_Team_Official_Reporter_Max_Fields = {
  __typename: 'members_team_official_reporter_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  member_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  team_official_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Members_Team_Official_Reporter_Min_Fields = {
  __typename: 'members_team_official_reporter_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  member_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  team_official_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "members.team_official_reporter". */
export type Members_Team_Official_Reporter_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official?: InputMaybe<Teams_Team_Official_Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** select columns of table "members.team_official_reporter" */
export enum Members_Team_Official_Reporter_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamOfficialId = 'team_official_id',
}

/** aggregate stddev on columns */
export type Members_Team_Official_Reporter_Stddev_Fields = {
  __typename: 'members_team_official_reporter_stddev_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  team_official_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Members_Team_Official_Reporter_Stddev_Pop_Fields = {
  __typename: 'members_team_official_reporter_stddev_pop_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  team_official_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Members_Team_Official_Reporter_Stddev_Samp_Fields = {
  __typename: 'members_team_official_reporter_stddev_samp_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  team_official_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "members_team_official_reporter" */
export type Members_Team_Official_Reporter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Members_Team_Official_Reporter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Members_Team_Official_Reporter_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_official_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Members_Team_Official_Reporter_Sum_Fields = {
  __typename: 'members_team_official_reporter_sum_fields';
  id: Scalars['Int']['output'];
  member_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  team_official_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Members_Team_Official_Reporter_Var_Pop_Fields = {
  __typename: 'members_team_official_reporter_var_pop_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  team_official_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Members_Team_Official_Reporter_Var_Samp_Fields = {
  __typename: 'members_team_official_reporter_var_samp_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  team_official_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Members_Team_Official_Reporter_Variance_Fields = {
  __typename: 'members_team_official_reporter_variance_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  team_official_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "members.team_official_reporter" */
export type Members_Team_Official_Reporter_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_official_id?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename: 'mutation_root';
  /** Crée un client */
  billing_create_club_customer?: Maybe<Affected_Rows_Response>;
  /** Crée une devis pour un client */
  billing_create_customer_estimate?: Maybe<Estimates_Response>;
  /** Crée une facture pour un client */
  billing_create_customer_invoice?: Maybe<Invoices_Response>;
  /** Crée une facture pour un client à partir d'un devis */
  billing_create_customer_invoice_from_estimate?: Maybe<Invoices_Response>;
  /** Met à jour un client */
  billing_update_club_customer?: Maybe<Affected_Rows_Response>;
  clubhouse_add_screen?: Maybe<Affected_Rows_Mutation_Response>;
  competitions_add_fan?: Maybe<Competitions_Add_Fan_Response>;
  /** Ajoute un match amical entre 2 équipes */
  competitions_create_exhibition_event?: Maybe<Id_Response>;
  competitions_delete_fan?: Maybe<Competitions_Delete_Fan_Response>;
  /** Relance le scraping sur les matchs pour lesquels il manque des résultats les 7 derniers jours pour un club donné */
  competitions_missing_results_last_week_for_club?: Maybe<Affected_Rows_Response>;
  /** Action permettant de valider les informations d'un match dans le planificateur */
  competitions_playing_team_validate_event?: Maybe<Id_Response>;
  /** Relance le scraping sur les matchs de la plage de date indiquée pour un club donné */
  competitions_synchronize_events_this_week_for_club?: Maybe<Affected_Rows_Response>;
  /** Permet de faire le lien entre un club et une exception dans le scraping, et relancer la synchronisation avec la fédé lorsqu'il n'y a plus d'erreurs pour le championnat */
  crawler_link_club_to_exception?: Maybe<Affected_Rows_Response>;
  /** Permet de lancer la récupération des clubs liés à un championnat auprès de la fédération */
  crawlers_crawl_club_spider?: Maybe<Affected_Rows_Response>;
  /** Permet de relancer le scraping sur la compétition */
  crawlers_crawl_competition_spider?: Maybe<Affected_Rows_Response>;
  /** Permet de relancer le processus de correction d'une compétition pas encore synchronisée */
  crawlers_fix_competition_not_synchronized_spider?: Maybe<Affected_Rows_Response>;
  createConfigurationLocalization?: Maybe<ConfigurationEntityResponse>;
  createMenusMenu?: Maybe<MenusMenuEntityResponse>;
  createMenusMenuItem?: Maybe<MenusMenuItemEntityResponse>;
  createPagesChHomeLocalization?: Maybe<PagesChHomeEntityResponse>;
  createPagesClub?: Maybe<PagesClubEntityResponse>;
  createPagesHomeLocalization?: Maybe<PagesHomeEntityResponse>;
  createPagesLanding?: Maybe<PagesLandingEntityResponse>;
  createPagesLandingLocalization?: Maybe<PagesLandingEntityResponse>;
  createPagesLive?: Maybe<PagesLiveEntityResponse>;
  createPagesLiveLocalization?: Maybe<PagesLiveEntityResponse>;
  createPagesSport?: Maybe<PagesSportEntityResponse>;
  createPagesSportLocalization?: Maybe<PagesSportEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteConfiguration?: Maybe<ConfigurationEntityResponse>;
  deleteMenusMenu?: Maybe<MenusMenuEntityResponse>;
  deleteMenusMenuItem?: Maybe<MenusMenuItemEntityResponse>;
  deletePagesChHome?: Maybe<PagesChHomeEntityResponse>;
  deletePagesClub?: Maybe<PagesClubEntityResponse>;
  deletePagesHome?: Maybe<PagesHomeEntityResponse>;
  deletePagesLanding?: Maybe<PagesLandingEntityResponse>;
  deletePagesLive?: Maybe<PagesLiveEntityResponse>;
  deletePagesSport?: Maybe<PagesSportEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** delete data from the table: "clubhouse.club_house" */
  delete_clubhouse_club_house?: Maybe<Clubhouse_Club_House_Mutation_Response>;
  /** delete single row from the table: "clubhouse.club_house" */
  delete_clubhouse_club_house_by_pk?: Maybe<Clubhouse_Club_House>;
  /** delete data from the table: "clubhouse.screen" */
  delete_clubhouse_screen?: Maybe<Clubhouse_Screen_Mutation_Response>;
  /** delete single row from the table: "clubhouse.screen" */
  delete_clubhouse_screen_by_pk?: Maybe<Clubhouse_Screen>;
  /** delete data from the table: "competitions.event_referee" */
  delete_competitions_event_referee?: Maybe<Competitions_Event_Referee_Mutation_Response>;
  /** delete single row from the table: "competitions.event_referee" */
  delete_competitions_event_referee_by_pk?: Maybe<Competitions_Event_Referee>;
  /** delete data from the table: "competitions.playing_team_bar_manager" */
  delete_competitions_playing_team_bar_manager?: Maybe<Competitions_Playing_Team_Bar_Manager_Mutation_Response>;
  /** delete single row from the table: "competitions.playing_team_bar_manager" */
  delete_competitions_playing_team_bar_manager_by_pk?: Maybe<Competitions_Playing_Team_Bar_Manager>;
  /** delete data from the table: "competitions.playing_team_referee" */
  delete_competitions_playing_team_referee?: Maybe<Competitions_Playing_Team_Referee_Mutation_Response>;
  /** delete single row from the table: "competitions.playing_team_referee" */
  delete_competitions_playing_team_referee_by_pk?: Maybe<Competitions_Playing_Team_Referee>;
  /** delete data from the table: "competitions.playing_team_scoring_table" */
  delete_competitions_playing_team_scoring_table?: Maybe<Competitions_Playing_Team_Scoring_Table_Mutation_Response>;
  /** delete single row from the table: "competitions.playing_team_scoring_table" */
  delete_competitions_playing_team_scoring_table_by_pk?: Maybe<Competitions_Playing_Team_Scoring_Table>;
  /** delete data from the table: "competitions.playing_team_stadium_manager" */
  delete_competitions_playing_team_stadium_manager?: Maybe<Competitions_Playing_Team_Stadium_Manager_Mutation_Response>;
  /** delete single row from the table: "competitions.playing_team_stadium_manager" */
  delete_competitions_playing_team_stadium_manager_by_pk?: Maybe<Competitions_Playing_Team_Stadium_Manager>;
  /** delete data from the table: "lives.live_player" */
  delete_lives_live_player?: Maybe<Lives_Live_Player_Mutation_Response>;
  /** delete single row from the table: "lives.live_player" */
  delete_lives_live_player_by_pk?: Maybe<Lives_Live_Player>;
  /** delete data from the table: "lives.post" */
  delete_lives_post?: Maybe<Lives_Post_Mutation_Response>;
  /** delete single row from the table: "lives.post" */
  delete_lives_post_by_pk?: Maybe<Lives_Post>;
  /** delete data from the table: "members.group" */
  delete_members_group?: Maybe<Members_Group_Mutation_Response>;
  /** delete single row from the table: "members.group" */
  delete_members_group_by_pk?: Maybe<Members_Group>;
  /** delete data from the table: "members.member" */
  delete_members_member?: Maybe<Members_Member_Mutation_Response>;
  /** delete single row from the table: "members.member" */
  delete_members_member_by_pk?: Maybe<Members_Member>;
  /** delete data from the table: "members.member_in_group" */
  delete_members_member_in_group?: Maybe<Members_Member_In_Group_Mutation_Response>;
  /** delete single row from the table: "members.member_in_group" */
  delete_members_member_in_group_by_pk?: Maybe<Members_Member_In_Group>;
  /** delete data from the table: "news.news" */
  delete_news_news?: Maybe<News_News_Mutation_Response>;
  /** delete single row from the table: "news.news" */
  delete_news_news_by_pk?: Maybe<News_News>;
  /** delete data from the table: "permissions.member_role" */
  delete_permissions_member_role?: Maybe<Permissions_Member_Role_Mutation_Response>;
  /** delete single row from the table: "permissions.member_role" */
  delete_permissions_member_role_by_pk?: Maybe<Permissions_Member_Role>;
  /** delete data from the table: "permissions.role" */
  delete_permissions_role?: Maybe<Permissions_Role_Mutation_Response>;
  /** delete single row from the table: "permissions.role" */
  delete_permissions_role_by_pk?: Maybe<Permissions_Role>;
  /** delete data from the table: "permissions.role_permission" */
  delete_permissions_role_permission?: Maybe<Permissions_Role_Permission_Mutation_Response>;
  /** delete single row from the table: "permissions.role_permission" */
  delete_permissions_role_permission_by_pk?: Maybe<Permissions_Role_Permission>;
  /** delete data from the table: "places.equipment" */
  delete_places_equipment?: Maybe<Places_Equipment_Mutation_Response>;
  /** delete single row from the table: "places.equipment" */
  delete_places_equipment_by_pk?: Maybe<Places_Equipment>;
  /** delete data from the table: "sponsors.category" */
  delete_sponsors_category?: Maybe<Sponsors_Category_Mutation_Response>;
  /** delete single row from the table: "sponsors.category" */
  delete_sponsors_category_by_pk?: Maybe<Sponsors_Category>;
  /** delete data from the table: "sponsors.sponsor" */
  delete_sponsors_sponsor?: Maybe<Sponsors_Sponsor_Mutation_Response>;
  /** delete single row from the table: "sponsors.sponsor" */
  delete_sponsors_sponsor_by_pk?: Maybe<Sponsors_Sponsor>;
  /** delete data from the table: "stats.action" */
  delete_stats_action?: Maybe<Stats_Action_Mutation_Response>;
  /** delete single row from the table: "stats.action" */
  delete_stats_action_by_pk?: Maybe<Stats_Action>;
  /** delete data from the table: "tasks.task" */
  delete_tasks_task?: Maybe<Tasks_Task_Mutation_Response>;
  /** delete single row from the table: "tasks.task" */
  delete_tasks_task_by_pk?: Maybe<Tasks_Task>;
  /** delete data from the table: "teams.authorized_group" */
  delete_teams_authorized_group?: Maybe<Teams_Authorized_Group_Mutation_Response>;
  /** delete single row from the table: "teams.authorized_group" */
  delete_teams_authorized_group_by_pk?: Maybe<Teams_Authorized_Group>;
  /** delete data from the table: "teams.authorized_team" */
  delete_teams_authorized_team?: Maybe<Teams_Authorized_Team_Mutation_Response>;
  /** delete single row from the table: "teams.authorized_team" */
  delete_teams_authorized_team_by_pk?: Maybe<Teams_Authorized_Team>;
  /** delete data from the table: "teams.club_group" */
  delete_teams_club_group?: Maybe<Teams_Club_Group_Mutation_Response>;
  /** delete single row from the table: "teams.club_group" */
  delete_teams_club_group_by_pk?: Maybe<Teams_Club_Group>;
  /** delete data from the table: "teams.official" */
  delete_teams_official?: Maybe<Teams_Official_Mutation_Response>;
  /** delete single row from the table: "teams.official" */
  delete_teams_official_by_pk?: Maybe<Teams_Official>;
  /** delete data from the table: "teams.team_action_permission" */
  delete_teams_team_action_permission?: Maybe<Teams_Team_Action_Permission_Mutation_Response>;
  /** delete single row from the table: "teams.team_action_permission" */
  delete_teams_team_action_permission_by_pk?: Maybe<Teams_Team_Action_Permission>;
  /** delete data from the table: "teams.team_in_season" */
  delete_teams_team_in_season?: Maybe<Teams_Team_In_Season_Mutation_Response>;
  /** delete single row from the table: "teams.team_in_season" */
  delete_teams_team_in_season_by_pk?: Maybe<Teams_Team_In_Season>;
  /** delete data from the table: "teams.team_in_season_has_member" */
  delete_teams_team_in_season_has_member?: Maybe<Teams_Team_In_Season_Has_Member_Mutation_Response>;
  /** delete single row from the table: "teams.team_in_season_has_member" */
  delete_teams_team_in_season_has_member_by_pk?: Maybe<Teams_Team_In_Season_Has_Member>;
  /** delete data from the table: "teams.team_official" */
  delete_teams_team_official?: Maybe<Teams_Team_Official_Mutation_Response>;
  /** delete single row from the table: "teams.team_official" */
  delete_teams_team_official_by_pk?: Maybe<Teams_Team_Official>;
  /** delete data from the table: "tournaments.challenge" */
  delete_tournaments_challenge?: Maybe<Tournaments_Challenge_Mutation_Response>;
  /** delete single row from the table: "tournaments.challenge" */
  delete_tournaments_challenge_by_pk?: Maybe<Tournaments_Challenge>;
  /** delete data from the table: "tournaments.challenge_rule" */
  delete_tournaments_challenge_rule?: Maybe<Tournaments_Challenge_Rule_Mutation_Response>;
  /** delete single row from the table: "tournaments.challenge_rule" */
  delete_tournaments_challenge_rule_by_pk?: Maybe<Tournaments_Challenge_Rule>;
  /** delete data from the table: "tournaments.official_reporter_v2" */
  delete_tournaments_official_reporter_v2?: Maybe<Tournaments_Official_Reporter_V2_Mutation_Response>;
  /** delete single row from the table: "tournaments.official_reporter_v2" */
  delete_tournaments_official_reporter_v2_by_pk?: Maybe<Tournaments_Official_Reporter_V2>;
  /** delete data from the table: "tournaments.place" */
  delete_tournaments_place?: Maybe<Tournaments_Place_Mutation_Response>;
  /** delete single row from the table: "tournaments.place" */
  delete_tournaments_place_by_pk?: Maybe<Tournaments_Place>;
  /** delete data from the table: "tournaments.ranking_points" */
  delete_tournaments_ranking_points?: Maybe<Tournaments_Ranking_Points_Mutation_Response>;
  /** delete single row from the table: "tournaments.ranking_points" */
  delete_tournaments_ranking_points_by_pk?: Maybe<Tournaments_Ranking_Points>;
  /** delete data from the table: "tournaments.ranking_rule" */
  delete_tournaments_ranking_rule?: Maybe<Tournaments_Ranking_Rule_Mutation_Response>;
  /** delete single row from the table: "tournaments.ranking_rule" */
  delete_tournaments_ranking_rule_by_pk?: Maybe<Tournaments_Ranking_Rule>;
  /** delete data from the table: "tournaments.team" */
  delete_tournaments_team?: Maybe<Tournaments_Team_Mutation_Response>;
  /** delete single row from the table: "tournaments.team" */
  delete_tournaments_team_by_pk?: Maybe<Tournaments_Team>;
  /** delete data from the table: "tournaments.tournament" */
  delete_tournaments_tournament?: Maybe<Tournaments_Tournament_Mutation_Response>;
  /** delete single row from the table: "tournaments.tournament" */
  delete_tournaments_tournament_by_pk?: Maybe<Tournaments_Tournament>;
  /** delete data from the table: "users.member_invitation" */
  delete_users_member_invitation?: Maybe<Users_Member_Invitation_Mutation_Response>;
  /** delete single row from the table: "users.member_invitation" */
  delete_users_member_invitation_by_pk?: Maybe<Users_Member_Invitation>;
  /** delete data from the table: "widgets.widget" */
  delete_widgets_widget?: Maybe<Widgets_Widget_Mutation_Response>;
  /** delete single row from the table: "widgets.widget" */
  delete_widgets_widget_by_pk?: Maybe<Widgets_Widget>;
  /** delete data from the table: "widgets.widget_has_competition" */
  delete_widgets_widget_has_competition?: Maybe<Widgets_Widget_Has_Competition_Mutation_Response>;
  /** delete single row from the table: "widgets.widget_has_competition" */
  delete_widgets_widget_has_competition_by_pk?: Maybe<Widgets_Widget_Has_Competition>;
  /** delete data from the table: "widgets.widget_has_team" */
  delete_widgets_widget_has_team?: Maybe<Widgets_Widget_Has_Team_Mutation_Response>;
  /** delete single row from the table: "widgets.widget_has_team" */
  delete_widgets_widget_has_team_by_pk?: Maybe<Widgets_Widget_Has_Team>;
  /** delete data from the table: "widgets.widget_live" */
  delete_widgets_widget_live?: Maybe<Widgets_Widget_Live_Mutation_Response>;
  /** delete single row from the table: "widgets.widget_live" */
  delete_widgets_widget_live_by_pk?: Maybe<Widgets_Widget_Live>;
  /** delete data from the table: "widgets.widget_team" */
  delete_widgets_widget_team?: Maybe<Widgets_Widget_Team_Mutation_Response>;
  /** delete single row from the table: "widgets.widget_team" */
  delete_widgets_widget_team_by_pk?: Maybe<Widgets_Widget_Team>;
  /** Met à jour les attributes Brevo d'un contact */
  emails_update_contact_attributes?: Maybe<Affected_Rows_Response>;
  /** insert data into the table: "billing.club_customer" */
  insert_billing_club_customer?: Maybe<Billing_Club_Customer_Mutation_Response>;
  /** insert a single row into the table: "billing.club_customer" */
  insert_billing_club_customer_one?: Maybe<Billing_Club_Customer>;
  /** insert data into the table: "billing.customer" */
  insert_billing_customer?: Maybe<Billing_Customer_Mutation_Response>;
  /** insert a single row into the table: "billing.customer" */
  insert_billing_customer_one?: Maybe<Billing_Customer>;
  /** insert data into the table: "clubhouse.club_house" */
  insert_clubhouse_club_house?: Maybe<Clubhouse_Club_House_Mutation_Response>;
  /** insert a single row into the table: "clubhouse.club_house" */
  insert_clubhouse_club_house_one?: Maybe<Clubhouse_Club_House>;
  /** insert data into the table: "competitions.event_referee" */
  insert_competitions_event_referee?: Maybe<Competitions_Event_Referee_Mutation_Response>;
  /** insert a single row into the table: "competitions.event_referee" */
  insert_competitions_event_referee_one?: Maybe<Competitions_Event_Referee>;
  /** insert data into the table: "competitions.playing_team_bar_manager" */
  insert_competitions_playing_team_bar_manager?: Maybe<Competitions_Playing_Team_Bar_Manager_Mutation_Response>;
  /** insert a single row into the table: "competitions.playing_team_bar_manager" */
  insert_competitions_playing_team_bar_manager_one?: Maybe<Competitions_Playing_Team_Bar_Manager>;
  /** insert data into the table: "competitions.playing_team_referee" */
  insert_competitions_playing_team_referee?: Maybe<Competitions_Playing_Team_Referee_Mutation_Response>;
  /** insert a single row into the table: "competitions.playing_team_referee" */
  insert_competitions_playing_team_referee_one?: Maybe<Competitions_Playing_Team_Referee>;
  /** insert data into the table: "competitions.playing_team_scoring_table" */
  insert_competitions_playing_team_scoring_table?: Maybe<Competitions_Playing_Team_Scoring_Table_Mutation_Response>;
  /** insert a single row into the table: "competitions.playing_team_scoring_table" */
  insert_competitions_playing_team_scoring_table_one?: Maybe<Competitions_Playing_Team_Scoring_Table>;
  /** insert data into the table: "competitions.playing_team_stadium_manager" */
  insert_competitions_playing_team_stadium_manager?: Maybe<Competitions_Playing_Team_Stadium_Manager_Mutation_Response>;
  /** insert a single row into the table: "competitions.playing_team_stadium_manager" */
  insert_competitions_playing_team_stadium_manager_one?: Maybe<Competitions_Playing_Team_Stadium_Manager>;
  /** insert data into the table: "lives.live" */
  insert_lives_live?: Maybe<Lives_Live_Mutation_Response>;
  /** insert a single row into the table: "lives.live" */
  insert_lives_live_one?: Maybe<Lives_Live>;
  /** insert data into the table: "lives.live_player" */
  insert_lives_live_player?: Maybe<Lives_Live_Player_Mutation_Response>;
  /** insert a single row into the table: "lives.live_player" */
  insert_lives_live_player_one?: Maybe<Lives_Live_Player>;
  /** insert data into the table: "lives.live_team" */
  insert_lives_live_team?: Maybe<Lives_Live_Team_Mutation_Response>;
  /** insert a single row into the table: "lives.live_team" */
  insert_lives_live_team_one?: Maybe<Lives_Live_Team>;
  /** insert data into the table: "lives.post" */
  insert_lives_post?: Maybe<Lives_Post_Mutation_Response>;
  /** insert a single row into the table: "lives.post" */
  insert_lives_post_one?: Maybe<Lives_Post>;
  /** insert data into the table: "lives.spectator" */
  insert_lives_spectator?: Maybe<Lives_Spectator_Mutation_Response>;
  /** insert a single row into the table: "lives.spectator" */
  insert_lives_spectator_one?: Maybe<Lives_Spectator>;
  /** insert data into the table: "lives.thank_you" */
  insert_lives_thank_you?: Maybe<Lives_Thank_You_Mutation_Response>;
  /** insert a single row into the table: "lives.thank_you" */
  insert_lives_thank_you_one?: Maybe<Lives_Thank_You>;
  /** insert data into the table: "members.export" */
  insert_members_export?: Maybe<Members_Export_Mutation_Response>;
  /** insert a single row into the table: "members.export" */
  insert_members_export_one?: Maybe<Members_Export>;
  /** insert data into the table: "members.group" */
  insert_members_group?: Maybe<Members_Group_Mutation_Response>;
  /** insert a single row into the table: "members.group" */
  insert_members_group_one?: Maybe<Members_Group>;
  /** insert data into the table: "members.member" */
  insert_members_member?: Maybe<Members_Member_Mutation_Response>;
  /** insert data into the table: "members.member_in_group" */
  insert_members_member_in_group?: Maybe<Members_Member_In_Group_Mutation_Response>;
  /** insert a single row into the table: "members.member_in_group" */
  insert_members_member_in_group_one?: Maybe<Members_Member_In_Group>;
  /** insert a single row into the table: "members.member" */
  insert_members_member_one?: Maybe<Members_Member>;
  /** insert data into the table: "news.news" */
  insert_news_news?: Maybe<News_News_Mutation_Response>;
  /** insert a single row into the table: "news.news" */
  insert_news_news_one?: Maybe<News_News>;
  /** insert data into the table: "newsletters.contact" */
  insert_newsletters_contact?: Maybe<Newsletters_Contact_Mutation_Response>;
  /** insert data into the table: "notifications.endpoint" */
  insert_notifications_endpoint?: Maybe<Notifications_Endpoint_Mutation_Response>;
  /** insert a single row into the table: "notifications.endpoint" */
  insert_notifications_endpoint_one?: Maybe<Notifications_Endpoint>;
  /** insert data into the table: "notifications.user" */
  insert_notifications_user?: Maybe<Notifications_User_Mutation_Response>;
  /** insert data into the table: "permissions.member_role" */
  insert_permissions_member_role?: Maybe<Permissions_Member_Role_Mutation_Response>;
  /** insert a single row into the table: "permissions.member_role" */
  insert_permissions_member_role_one?: Maybe<Permissions_Member_Role>;
  /** insert data into the table: "permissions.role" */
  insert_permissions_role?: Maybe<Permissions_Role_Mutation_Response>;
  /** insert a single row into the table: "permissions.role" */
  insert_permissions_role_one?: Maybe<Permissions_Role>;
  /** insert data into the table: "permissions.role_permission" */
  insert_permissions_role_permission?: Maybe<Permissions_Role_Permission_Mutation_Response>;
  /** insert a single row into the table: "permissions.role_permission" */
  insert_permissions_role_permission_one?: Maybe<Permissions_Role_Permission>;
  /** insert data into the table: "places.city" */
  insert_places_city?: Maybe<Places_City_Mutation_Response>;
  /** insert a single row into the table: "places.city" */
  insert_places_city_one?: Maybe<Places_City>;
  /** insert data into the table: "places.equipment" */
  insert_places_equipment?: Maybe<Places_Equipment_Mutation_Response>;
  /** insert a single row into the table: "places.equipment" */
  insert_places_equipment_one?: Maybe<Places_Equipment>;
  /** insert data into the table: "places.place" */
  insert_places_place?: Maybe<Places_Place_Mutation_Response>;
  /** insert a single row into the table: "places.place" */
  insert_places_place_one?: Maybe<Places_Place>;
  /** insert data into the table: "sponsors.category" */
  insert_sponsors_category?: Maybe<Sponsors_Category_Mutation_Response>;
  /** insert a single row into the table: "sponsors.category" */
  insert_sponsors_category_one?: Maybe<Sponsors_Category>;
  /** insert data into the table: "sponsors.sponsor" */
  insert_sponsors_sponsor?: Maybe<Sponsors_Sponsor_Mutation_Response>;
  /** insert a single row into the table: "sponsors.sponsor" */
  insert_sponsors_sponsor_one?: Maybe<Sponsors_Sponsor>;
  /** insert data into the table: "stats.action" */
  insert_stats_action?: Maybe<Stats_Action_Mutation_Response>;
  /** insert a single row into the table: "stats.action" */
  insert_stats_action_one?: Maybe<Stats_Action>;
  /** insert data into the table: "tasks.member_task_count" */
  insert_tasks_member_task_count?: Maybe<Tasks_Member_Task_Count_Mutation_Response>;
  /** insert a single row into the table: "tasks.member_task_count" */
  insert_tasks_member_task_count_one?: Maybe<Tasks_Member_Task_Count>;
  /** insert data into the table: "tasks.task" */
  insert_tasks_task?: Maybe<Tasks_Task_Mutation_Response>;
  /** insert a single row into the table: "tasks.task" */
  insert_tasks_task_one?: Maybe<Tasks_Task>;
  /** insert data into the table: "teams.authorized_group" */
  insert_teams_authorized_group?: Maybe<Teams_Authorized_Group_Mutation_Response>;
  /** insert a single row into the table: "teams.authorized_group" */
  insert_teams_authorized_group_one?: Maybe<Teams_Authorized_Group>;
  /** insert data into the table: "teams.authorized_team" */
  insert_teams_authorized_team?: Maybe<Teams_Authorized_Team_Mutation_Response>;
  /** insert a single row into the table: "teams.authorized_team" */
  insert_teams_authorized_team_one?: Maybe<Teams_Authorized_Team>;
  /** insert data into the table: "teams.club_group" */
  insert_teams_club_group?: Maybe<Teams_Club_Group_Mutation_Response>;
  /** insert a single row into the table: "teams.club_group" */
  insert_teams_club_group_one?: Maybe<Teams_Club_Group>;
  /** insert data into the table: "teams.official" */
  insert_teams_official?: Maybe<Teams_Official_Mutation_Response>;
  /** insert a single row into the table: "teams.official" */
  insert_teams_official_one?: Maybe<Teams_Official>;
  /** insert data into the table: "teams.team_action_permission" */
  insert_teams_team_action_permission?: Maybe<Teams_Team_Action_Permission_Mutation_Response>;
  /** insert a single row into the table: "teams.team_action_permission" */
  insert_teams_team_action_permission_one?: Maybe<Teams_Team_Action_Permission>;
  /** insert data into the table: "teams.team_in_season_has_member" */
  insert_teams_team_in_season_has_member?: Maybe<Teams_Team_In_Season_Has_Member_Mutation_Response>;
  /** insert a single row into the table: "teams.team_in_season_has_member" */
  insert_teams_team_in_season_has_member_one?: Maybe<Teams_Team_In_Season_Has_Member>;
  /** insert data into the table: "teams.team_official" */
  insert_teams_team_official?: Maybe<Teams_Team_Official_Mutation_Response>;
  /** insert a single row into the table: "teams.team_official" */
  insert_teams_team_official_one?: Maybe<Teams_Team_Official>;
  /** insert data into the table: "tournaments.challenge" */
  insert_tournaments_challenge?: Maybe<Tournaments_Challenge_Mutation_Response>;
  /** insert a single row into the table: "tournaments.challenge" */
  insert_tournaments_challenge_one?: Maybe<Tournaments_Challenge>;
  /** insert data into the table: "tournaments.challenge_rule" */
  insert_tournaments_challenge_rule?: Maybe<Tournaments_Challenge_Rule_Mutation_Response>;
  /** insert a single row into the table: "tournaments.challenge_rule" */
  insert_tournaments_challenge_rule_one?: Maybe<Tournaments_Challenge_Rule>;
  /** insert data into the table: "tournaments.official_reporter_v2" */
  insert_tournaments_official_reporter_v2?: Maybe<Tournaments_Official_Reporter_V2_Mutation_Response>;
  /** insert a single row into the table: "tournaments.official_reporter_v2" */
  insert_tournaments_official_reporter_v2_one?: Maybe<Tournaments_Official_Reporter_V2>;
  /** insert data into the table: "tournaments.place" */
  insert_tournaments_place?: Maybe<Tournaments_Place_Mutation_Response>;
  /** insert a single row into the table: "tournaments.place" */
  insert_tournaments_place_one?: Maybe<Tournaments_Place>;
  /** insert data into the table: "tournaments.ranking_points" */
  insert_tournaments_ranking_points?: Maybe<Tournaments_Ranking_Points_Mutation_Response>;
  /** insert a single row into the table: "tournaments.ranking_points" */
  insert_tournaments_ranking_points_one?: Maybe<Tournaments_Ranking_Points>;
  /** insert data into the table: "tournaments.ranking_rule" */
  insert_tournaments_ranking_rule?: Maybe<Tournaments_Ranking_Rule_Mutation_Response>;
  /** insert a single row into the table: "tournaments.ranking_rule" */
  insert_tournaments_ranking_rule_one?: Maybe<Tournaments_Ranking_Rule>;
  /** insert data into the table: "tournaments.team" */
  insert_tournaments_team?: Maybe<Tournaments_Team_Mutation_Response>;
  /** insert a single row into the table: "tournaments.team" */
  insert_tournaments_team_one?: Maybe<Tournaments_Team>;
  /** insert data into the table: "tournaments.tournament" */
  insert_tournaments_tournament?: Maybe<Tournaments_Tournament_Mutation_Response>;
  /** insert a single row into the table: "tournaments.tournament" */
  insert_tournaments_tournament_one?: Maybe<Tournaments_Tournament>;
  /** insert data into the table: "widgets.club_settings" */
  insert_widgets_club_settings?: Maybe<Widgets_Club_Settings_Mutation_Response>;
  /** insert a single row into the table: "widgets.club_settings" */
  insert_widgets_club_settings_one?: Maybe<Widgets_Club_Settings>;
  /** insert data into the table: "widgets.convocation_settings" */
  insert_widgets_convocation_settings?: Maybe<Widgets_Convocation_Settings_Mutation_Response>;
  /** insert a single row into the table: "widgets.convocation_settings" */
  insert_widgets_convocation_settings_one?: Maybe<Widgets_Convocation_Settings>;
  /** insert data into the table: "widgets.widget" */
  insert_widgets_widget?: Maybe<Widgets_Widget_Mutation_Response>;
  /** insert data into the table: "widgets.widget_has_competition" */
  insert_widgets_widget_has_competition?: Maybe<Widgets_Widget_Has_Competition_Mutation_Response>;
  /** insert a single row into the table: "widgets.widget_has_competition" */
  insert_widgets_widget_has_competition_one?: Maybe<Widgets_Widget_Has_Competition>;
  /** insert data into the table: "widgets.widget_has_team" */
  insert_widgets_widget_has_team?: Maybe<Widgets_Widget_Has_Team_Mutation_Response>;
  /** insert a single row into the table: "widgets.widget_has_team" */
  insert_widgets_widget_has_team_one?: Maybe<Widgets_Widget_Has_Team>;
  /** insert data into the table: "widgets.widget_live" */
  insert_widgets_widget_live?: Maybe<Widgets_Widget_Live_Mutation_Response>;
  /** insert a single row into the table: "widgets.widget_live" */
  insert_widgets_widget_live_one?: Maybe<Widgets_Widget_Live>;
  /** insert a single row into the table: "widgets.widget" */
  insert_widgets_widget_one?: Maybe<Widgets_Widget>;
  /** insert data into the table: "widgets.widget_team" */
  insert_widgets_widget_team?: Maybe<Widgets_Widget_Team_Mutation_Response>;
  /** insert a single row into the table: "widgets.widget_team" */
  insert_widgets_widget_team_one?: Maybe<Widgets_Widget_Team>;
  lives_add_post?: Maybe<Id_Response>;
  /** Importe les joueurs d'une équipe sur un live depuis l'effectif enregistré pour la saison, ou depuis le dernier match */
  lives_import_players?: Maybe<Affected_Rows_Response>;
  lives_new_hit?: Maybe<Live_Hit_Count_Response>;
  /** Indique si un reporter officiel est présent sur un live ou pas */
  lives_update_official_reporter?: Maybe<Id_Response>;
  /** Supprime un membre et toutes ses données associées */
  members_delete_member?: Maybe<Affected_Rows_Response>;
  /** Met à jour la liste des équipes pour lesquelles le membre est reporter officiel */
  members_update_official_reporter_teams?: Maybe<Affected_Rows_Response>;
  multipleUpload?: Maybe<Array<Maybe<UploadFileEntityResponse>>>;
  places_get_place_id_from_address?: Maybe<Id_Response>;
  removeFile?: Maybe<UploadFileEntityResponse>;
  scraping_get_new_competitions?: Maybe<Scraping_Get_New_Competitions_Mutation_Response>;
  scraping_get_new_rounds?: Maybe<Scraping_Get_New_Rounds_Mutation_Response>;
  scraping_synchronize_competition?: Maybe<Scraping_Synchronize_Competition_Mutation_Response>;
  scraping_synchronize_event?: Maybe<Scraping_Launched_Mutation_Response>;
  /** Permet de lancer le scraping sur tous les prochaines journées ou tours d'un club */
  scraping_update_next_rounds?: Maybe<Affected_Rows_Response>;
  sponsors_update_teams?: Maybe<Sponsors_Update_Teams_Mutation_Response>;
  /** Supprime une tâche et tous les objets qui en dépendent */
  tasks_delete_task?: Maybe<Affected_Rows_Response>;
  /** Action permettant de mettre à jour la liste équipes ou groupes candidats pour faire une tâche de bénévolat pour une équipe */
  tasks_insert_or_update_permissions_for_team?: Maybe<Affected_Rows_Response>;
  /** Action permettant de mettre à jour la liste des bénévoles désignés sur une tâche pour un match */
  tasks_insert_or_update_volunteers_for_jobs?: Maybe<Affected_Rows_Response>;
  /** Action permettant d'ajouter l'utilisateur de la session en tant que fan d'une équipe */
  teams_add_fan?: Maybe<Teams_Add_Fan_Response>;
  teams_add_team?: Maybe<Teams_Add_Team_Response>;
  teams_delete_fan?: Maybe<Teams_Delete_Fan_Response>;
  teams_delete_team_in_season?: Maybe<Affected_Rows_Response>;
  /** Permet de migrer une équipe vers une nouvelle saison : copie l'équipe avec tous ses paramètres, ainsi que la liste des joueurs */
  teams_migrate_to_season?: Maybe<Teams_Migrate_To_Season_Response>;
  /** Remet le rang par défaut à toutes les équipes du club */
  teams_set_default_rank?: Maybe<Affected_Rows_Response>;
  /** Assigne un nouveau rang à toutes les équipes du club */
  teams_set_rank?: Maybe<Affected_Rows_Response>;
  teams_update_team_name?: Maybe<Teams_Update_Team_Name_Mutation_Response>;
  /** Crée un tour de coupe au sein d'une phase de tournoi */
  tournaments_create_cup?: Maybe<New_Pool_Or_Cup_Response>;
  /** Ajoute une nouvelle phase au sein d'un challenge de tournoi */
  tournaments_create_phase?: Maybe<New_Phase_Response>;
  /** Ajoute une nouvelle poule au sein d'une phase de tournoi */
  tournaments_create_pool?: Maybe<New_Pool_Or_Cup_Response>;
  /** Crée un tournoi de test */
  tournaments_create_test?: Maybe<Id_Response>;
  /** Supprime un challenge et tous les matchs associés */
  tournaments_delete_challenge?: Maybe<Affected_Rows_Response>;
  /** Supprime un tour de coupe et tous les matchs associés au sein d'une phase de tournoi */
  tournaments_delete_cup_or_pool?: Maybe<Affected_Rows_Response>;
  /** Supprime une phase (challenge_rule) et tous les matchs associés au sein d'un challenge de tournoi */
  tournaments_delete_phase?: Maybe<Affected_Rows_Response>;
  /** Duplique un tournoi */
  tournaments_duplicate?: Maybe<Id_Response>;
  /** Duplique un challenge */
  tournaments_duplicate_challenge?: Maybe<Id_Response>;
  /** Enregistre une visite sur la page du tournoi */
  tournaments_new_hit?: Maybe<Scalars['uuid']['output']>;
  /** Duplique les horaires d'une phase de tournoi vers d'autres */
  tournaments_phase_copy_times?: Maybe<Affected_Rows_Response>;
  updateConfiguration?: Maybe<ConfigurationEntityResponse>;
  updateFileInfo?: Maybe<UploadFileEntityResponse>;
  updateMenusMenu?: Maybe<MenusMenuEntityResponse>;
  updateMenusMenuItem?: Maybe<MenusMenuItemEntityResponse>;
  updatePagesChHome?: Maybe<PagesChHomeEntityResponse>;
  updatePagesClub?: Maybe<PagesClubEntityResponse>;
  updatePagesHome?: Maybe<PagesHomeEntityResponse>;
  updatePagesLanding?: Maybe<PagesLandingEntityResponse>;
  updatePagesLive?: Maybe<PagesLiveEntityResponse>;
  updatePagesSport?: Maybe<PagesSportEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** update data of the table: "billing.club_customer" */
  update_billing_club_customer?: Maybe<Billing_Club_Customer_Mutation_Response>;
  /** update single row of the table: "billing.club_customer" */
  update_billing_club_customer_by_pk?: Maybe<Billing_Club_Customer>;
  /** update multiples rows of table: "billing.club_customer" */
  update_billing_club_customer_many?: Maybe<
    Array<Maybe<Billing_Club_Customer_Mutation_Response>>
  >;
  /** update data of the table: "billing.customer" */
  update_billing_customer?: Maybe<Billing_Customer_Mutation_Response>;
  /** update single row of the table: "billing.customer" */
  update_billing_customer_by_pk?: Maybe<Billing_Customer>;
  /** update multiples rows of table: "billing.customer" */
  update_billing_customer_many?: Maybe<
    Array<Maybe<Billing_Customer_Mutation_Response>>
  >;
  /** update data of the table: "clubhouse.club_house" */
  update_clubhouse_club_house?: Maybe<Clubhouse_Club_House_Mutation_Response>;
  /** update single row of the table: "clubhouse.club_house" */
  update_clubhouse_club_house_by_pk?: Maybe<Clubhouse_Club_House>;
  /** update multiples rows of table: "clubhouse.club_house" */
  update_clubhouse_club_house_many?: Maybe<
    Array<Maybe<Clubhouse_Club_House_Mutation_Response>>
  >;
  /** update data of the table: "clubhouse.screen" */
  update_clubhouse_screen?: Maybe<Clubhouse_Screen_Mutation_Response>;
  /** update single row of the table: "clubhouse.screen" */
  update_clubhouse_screen_by_pk?: Maybe<Clubhouse_Screen>;
  /** update multiples rows of table: "clubhouse.screen" */
  update_clubhouse_screen_many?: Maybe<
    Array<Maybe<Clubhouse_Screen_Mutation_Response>>
  >;
  /** update data of the table: "clubs.club" */
  update_clubs_club?: Maybe<Clubs_Club_Mutation_Response>;
  /** update single row of the table: "clubs.club" */
  update_clubs_club_by_pk?: Maybe<Clubs_Club>;
  /** update multiples rows of table: "clubs.club" */
  update_clubs_club_many?: Maybe<Array<Maybe<Clubs_Club_Mutation_Response>>>;
  /** update data of the table: "clubs.planner_settings" */
  update_clubs_planner_settings?: Maybe<Clubs_Planner_Settings_Mutation_Response>;
  /** update single row of the table: "clubs.planner_settings" */
  update_clubs_planner_settings_by_pk?: Maybe<Clubs_Planner_Settings>;
  /** update multiples rows of table: "clubs.planner_settings" */
  update_clubs_planner_settings_many?: Maybe<
    Array<Maybe<Clubs_Planner_Settings_Mutation_Response>>
  >;
  /** update data of the table: "competitions.competition_has_teams" */
  update_competitions_competition_has_teams?: Maybe<Competitions_Competition_Has_Teams_Mutation_Response>;
  /** update single row of the table: "competitions.competition_has_teams" */
  update_competitions_competition_has_teams_by_pk?: Maybe<Competitions_Competition_Has_Teams>;
  /** update multiples rows of table: "competitions.competition_has_teams" */
  update_competitions_competition_has_teams_many?: Maybe<
    Array<Maybe<Competitions_Competition_Has_Teams_Mutation_Response>>
  >;
  /** update data of the table: "competitions.event" */
  update_competitions_event?: Maybe<Competitions_Event_Mutation_Response>;
  /** update single row of the table: "competitions.event" */
  update_competitions_event_by_pk?: Maybe<Competitions_Event>;
  /** update multiples rows of table: "competitions.event" */
  update_competitions_event_many?: Maybe<
    Array<Maybe<Competitions_Event_Mutation_Response>>
  >;
  /** update data of the table: "competitions.event_referee" */
  update_competitions_event_referee?: Maybe<Competitions_Event_Referee_Mutation_Response>;
  /** update single row of the table: "competitions.event_referee" */
  update_competitions_event_referee_by_pk?: Maybe<Competitions_Event_Referee>;
  /** update multiples rows of table: "competitions.event_referee" */
  update_competitions_event_referee_many?: Maybe<
    Array<Maybe<Competitions_Event_Referee_Mutation_Response>>
  >;
  /** update data of the table: "competitions.playing_team_bar_manager" */
  update_competitions_playing_team_bar_manager?: Maybe<Competitions_Playing_Team_Bar_Manager_Mutation_Response>;
  /** update single row of the table: "competitions.playing_team_bar_manager" */
  update_competitions_playing_team_bar_manager_by_pk?: Maybe<Competitions_Playing_Team_Bar_Manager>;
  /** update multiples rows of table: "competitions.playing_team_bar_manager" */
  update_competitions_playing_team_bar_manager_many?: Maybe<
    Array<Maybe<Competitions_Playing_Team_Bar_Manager_Mutation_Response>>
  >;
  /** update data of the table: "competitions.playing_team_referee" */
  update_competitions_playing_team_referee?: Maybe<Competitions_Playing_Team_Referee_Mutation_Response>;
  /** update single row of the table: "competitions.playing_team_referee" */
  update_competitions_playing_team_referee_by_pk?: Maybe<Competitions_Playing_Team_Referee>;
  /** update multiples rows of table: "competitions.playing_team_referee" */
  update_competitions_playing_team_referee_many?: Maybe<
    Array<Maybe<Competitions_Playing_Team_Referee_Mutation_Response>>
  >;
  /** update data of the table: "competitions.playing_team_scoring_table" */
  update_competitions_playing_team_scoring_table?: Maybe<Competitions_Playing_Team_Scoring_Table_Mutation_Response>;
  /** update single row of the table: "competitions.playing_team_scoring_table" */
  update_competitions_playing_team_scoring_table_by_pk?: Maybe<Competitions_Playing_Team_Scoring_Table>;
  /** update multiples rows of table: "competitions.playing_team_scoring_table" */
  update_competitions_playing_team_scoring_table_many?: Maybe<
    Array<Maybe<Competitions_Playing_Team_Scoring_Table_Mutation_Response>>
  >;
  /** update data of the table: "competitions.playing_team_stadium_manager" */
  update_competitions_playing_team_stadium_manager?: Maybe<Competitions_Playing_Team_Stadium_Manager_Mutation_Response>;
  /** update single row of the table: "competitions.playing_team_stadium_manager" */
  update_competitions_playing_team_stadium_manager_by_pk?: Maybe<Competitions_Playing_Team_Stadium_Manager>;
  /** update multiples rows of table: "competitions.playing_team_stadium_manager" */
  update_competitions_playing_team_stadium_manager_many?: Maybe<
    Array<Maybe<Competitions_Playing_Team_Stadium_Manager_Mutation_Response>>
  >;
  /** update data of the table: "lives.live" */
  update_lives_live?: Maybe<Lives_Live_Mutation_Response>;
  /** update single row of the table: "lives.live" */
  update_lives_live_by_pk?: Maybe<Lives_Live>;
  /** update multiples rows of table: "lives.live" */
  update_lives_live_many?: Maybe<Array<Maybe<Lives_Live_Mutation_Response>>>;
  /** update data of the table: "lives.live_player" */
  update_lives_live_player?: Maybe<Lives_Live_Player_Mutation_Response>;
  /** update single row of the table: "lives.live_player" */
  update_lives_live_player_by_pk?: Maybe<Lives_Live_Player>;
  /** update multiples rows of table: "lives.live_player" */
  update_lives_live_player_many?: Maybe<
    Array<Maybe<Lives_Live_Player_Mutation_Response>>
  >;
  /** update data of the table: "lives.live_team" */
  update_lives_live_team?: Maybe<Lives_Live_Team_Mutation_Response>;
  /** update single row of the table: "lives.live_team" */
  update_lives_live_team_by_pk?: Maybe<Lives_Live_Team>;
  /** update multiples rows of table: "lives.live_team" */
  update_lives_live_team_many?: Maybe<
    Array<Maybe<Lives_Live_Team_Mutation_Response>>
  >;
  /** update data of the table: "lives.media_official_reporter" */
  update_lives_media_official_reporter?: Maybe<Lives_Media_Official_Reporter_Mutation_Response>;
  /** update single row of the table: "lives.media_official_reporter" */
  update_lives_media_official_reporter_by_pk?: Maybe<Lives_Media_Official_Reporter>;
  /** update multiples rows of table: "lives.media_official_reporter" */
  update_lives_media_official_reporter_many?: Maybe<
    Array<Maybe<Lives_Media_Official_Reporter_Mutation_Response>>
  >;
  /** update data of the table: "lives.post" */
  update_lives_post?: Maybe<Lives_Post_Mutation_Response>;
  /** update single row of the table: "lives.post" */
  update_lives_post_by_pk?: Maybe<Lives_Post>;
  /** update multiples rows of table: "lives.post" */
  update_lives_post_many?: Maybe<Array<Maybe<Lives_Post_Mutation_Response>>>;
  /** update data of the table: "lives.spectator" */
  update_lives_spectator?: Maybe<Lives_Spectator_Mutation_Response>;
  /** update single row of the table: "lives.spectator" */
  update_lives_spectator_by_pk?: Maybe<Lives_Spectator>;
  /** update multiples rows of table: "lives.spectator" */
  update_lives_spectator_many?: Maybe<
    Array<Maybe<Lives_Spectator_Mutation_Response>>
  >;
  /** update data of the table: "lives.thank_you" */
  update_lives_thank_you?: Maybe<Lives_Thank_You_Mutation_Response>;
  /** update single row of the table: "lives.thank_you" */
  update_lives_thank_you_by_pk?: Maybe<Lives_Thank_You>;
  /** update multiples rows of table: "lives.thank_you" */
  update_lives_thank_you_many?: Maybe<
    Array<Maybe<Lives_Thank_You_Mutation_Response>>
  >;
  /** update data of the table: "members.group" */
  update_members_group?: Maybe<Members_Group_Mutation_Response>;
  /** update single row of the table: "members.group" */
  update_members_group_by_pk?: Maybe<Members_Group>;
  /** update multiples rows of table: "members.group" */
  update_members_group_many?: Maybe<
    Array<Maybe<Members_Group_Mutation_Response>>
  >;
  /** update data of the table: "members.member" */
  update_members_member?: Maybe<Members_Member_Mutation_Response>;
  /** update single row of the table: "members.member" */
  update_members_member_by_pk?: Maybe<Members_Member>;
  /** update data of the table: "members.member_in_group" */
  update_members_member_in_group?: Maybe<Members_Member_In_Group_Mutation_Response>;
  /** update single row of the table: "members.member_in_group" */
  update_members_member_in_group_by_pk?: Maybe<Members_Member_In_Group>;
  /** update multiples rows of table: "members.member_in_group" */
  update_members_member_in_group_many?: Maybe<
    Array<Maybe<Members_Member_In_Group_Mutation_Response>>
  >;
  /** update multiples rows of table: "members.member" */
  update_members_member_many?: Maybe<
    Array<Maybe<Members_Member_Mutation_Response>>
  >;
  /** update data of the table: "news.news" */
  update_news_news?: Maybe<News_News_Mutation_Response>;
  /** update single row of the table: "news.news" */
  update_news_news_by_pk?: Maybe<News_News>;
  /** update multiples rows of table: "news.news" */
  update_news_news_many?: Maybe<Array<Maybe<News_News_Mutation_Response>>>;
  /** update data of the table: "notifications.endpoint" */
  update_notifications_endpoint?: Maybe<Notifications_Endpoint_Mutation_Response>;
  /** update single row of the table: "notifications.endpoint" */
  update_notifications_endpoint_by_pk?: Maybe<Notifications_Endpoint>;
  /** update multiples rows of table: "notifications.endpoint" */
  update_notifications_endpoint_many?: Maybe<
    Array<Maybe<Notifications_Endpoint_Mutation_Response>>
  >;
  /** update data of the table: "notifications.user" */
  update_notifications_user?: Maybe<Notifications_User_Mutation_Response>;
  /** update multiples rows of table: "notifications.user" */
  update_notifications_user_many?: Maybe<
    Array<Maybe<Notifications_User_Mutation_Response>>
  >;
  /** update data of the table: "permissions.role" */
  update_permissions_role?: Maybe<Permissions_Role_Mutation_Response>;
  /** update single row of the table: "permissions.role" */
  update_permissions_role_by_pk?: Maybe<Permissions_Role>;
  /** update multiples rows of table: "permissions.role" */
  update_permissions_role_many?: Maybe<
    Array<Maybe<Permissions_Role_Mutation_Response>>
  >;
  /** update data of the table: "permissions.role_permission" */
  update_permissions_role_permission?: Maybe<Permissions_Role_Permission_Mutation_Response>;
  /** update single row of the table: "permissions.role_permission" */
  update_permissions_role_permission_by_pk?: Maybe<Permissions_Role_Permission>;
  /** update multiples rows of table: "permissions.role_permission" */
  update_permissions_role_permission_many?: Maybe<
    Array<Maybe<Permissions_Role_Permission_Mutation_Response>>
  >;
  /** update data of the table: "places.equipment" */
  update_places_equipment?: Maybe<Places_Equipment_Mutation_Response>;
  /** update single row of the table: "places.equipment" */
  update_places_equipment_by_pk?: Maybe<Places_Equipment>;
  /** update multiples rows of table: "places.equipment" */
  update_places_equipment_many?: Maybe<
    Array<Maybe<Places_Equipment_Mutation_Response>>
  >;
  /** update data of the table: "sponsors.category" */
  update_sponsors_category?: Maybe<Sponsors_Category_Mutation_Response>;
  /** update single row of the table: "sponsors.category" */
  update_sponsors_category_by_pk?: Maybe<Sponsors_Category>;
  /** update multiples rows of table: "sponsors.category" */
  update_sponsors_category_many?: Maybe<
    Array<Maybe<Sponsors_Category_Mutation_Response>>
  >;
  /** update data of the table: "sponsors.sponsor" */
  update_sponsors_sponsor?: Maybe<Sponsors_Sponsor_Mutation_Response>;
  /** update single row of the table: "sponsors.sponsor" */
  update_sponsors_sponsor_by_pk?: Maybe<Sponsors_Sponsor>;
  /** update multiples rows of table: "sponsors.sponsor" */
  update_sponsors_sponsor_many?: Maybe<
    Array<Maybe<Sponsors_Sponsor_Mutation_Response>>
  >;
  /** update data of the table: "stats.action" */
  update_stats_action?: Maybe<Stats_Action_Mutation_Response>;
  /** update single row of the table: "stats.action" */
  update_stats_action_by_pk?: Maybe<Stats_Action>;
  /** update multiples rows of table: "stats.action" */
  update_stats_action_many?: Maybe<
    Array<Maybe<Stats_Action_Mutation_Response>>
  >;
  /** update data of the table: "tasks.member_task_count" */
  update_tasks_member_task_count?: Maybe<Tasks_Member_Task_Count_Mutation_Response>;
  /** update single row of the table: "tasks.member_task_count" */
  update_tasks_member_task_count_by_pk?: Maybe<Tasks_Member_Task_Count>;
  /** update multiples rows of table: "tasks.member_task_count" */
  update_tasks_member_task_count_many?: Maybe<
    Array<Maybe<Tasks_Member_Task_Count_Mutation_Response>>
  >;
  /** update data of the table: "tasks.task" */
  update_tasks_task?: Maybe<Tasks_Task_Mutation_Response>;
  /** update single row of the table: "tasks.task" */
  update_tasks_task_by_pk?: Maybe<Tasks_Task>;
  /** update multiples rows of table: "tasks.task" */
  update_tasks_task_many?: Maybe<Array<Maybe<Tasks_Task_Mutation_Response>>>;
  /** update data of the table: "teams.authorized_group" */
  update_teams_authorized_group?: Maybe<Teams_Authorized_Group_Mutation_Response>;
  /** update single row of the table: "teams.authorized_group" */
  update_teams_authorized_group_by_pk?: Maybe<Teams_Authorized_Group>;
  /** update multiples rows of table: "teams.authorized_group" */
  update_teams_authorized_group_many?: Maybe<
    Array<Maybe<Teams_Authorized_Group_Mutation_Response>>
  >;
  /** update data of the table: "teams.authorized_team" */
  update_teams_authorized_team?: Maybe<Teams_Authorized_Team_Mutation_Response>;
  /** update single row of the table: "teams.authorized_team" */
  update_teams_authorized_team_by_pk?: Maybe<Teams_Authorized_Team>;
  /** update multiples rows of table: "teams.authorized_team" */
  update_teams_authorized_team_many?: Maybe<
    Array<Maybe<Teams_Authorized_Team_Mutation_Response>>
  >;
  /** update data of the table: "teams.club_group" */
  update_teams_club_group?: Maybe<Teams_Club_Group_Mutation_Response>;
  /** update single row of the table: "teams.club_group" */
  update_teams_club_group_by_pk?: Maybe<Teams_Club_Group>;
  /** update multiples rows of table: "teams.club_group" */
  update_teams_club_group_many?: Maybe<
    Array<Maybe<Teams_Club_Group_Mutation_Response>>
  >;
  /** update data of the table: "teams.team" */
  update_teams_team?: Maybe<Teams_Team_Mutation_Response>;
  /** update data of the table: "teams.team_action_permission" */
  update_teams_team_action_permission?: Maybe<Teams_Team_Action_Permission_Mutation_Response>;
  /** update single row of the table: "teams.team_action_permission" */
  update_teams_team_action_permission_by_pk?: Maybe<Teams_Team_Action_Permission>;
  /** update multiples rows of table: "teams.team_action_permission" */
  update_teams_team_action_permission_many?: Maybe<
    Array<Maybe<Teams_Team_Action_Permission_Mutation_Response>>
  >;
  /** update single row of the table: "teams.team" */
  update_teams_team_by_pk?: Maybe<Teams_Team>;
  /** update data of the table: "teams.team_in_season" */
  update_teams_team_in_season?: Maybe<Teams_Team_In_Season_Mutation_Response>;
  /** update single row of the table: "teams.team_in_season" */
  update_teams_team_in_season_by_pk?: Maybe<Teams_Team_In_Season>;
  /** update data of the table: "teams.team_in_season_has_member" */
  update_teams_team_in_season_has_member?: Maybe<Teams_Team_In_Season_Has_Member_Mutation_Response>;
  /** update single row of the table: "teams.team_in_season_has_member" */
  update_teams_team_in_season_has_member_by_pk?: Maybe<Teams_Team_In_Season_Has_Member>;
  /** update multiples rows of table: "teams.team_in_season_has_member" */
  update_teams_team_in_season_has_member_many?: Maybe<
    Array<Maybe<Teams_Team_In_Season_Has_Member_Mutation_Response>>
  >;
  /** update multiples rows of table: "teams.team_in_season" */
  update_teams_team_in_season_many?: Maybe<
    Array<Maybe<Teams_Team_In_Season_Mutation_Response>>
  >;
  /** update multiples rows of table: "teams.team" */
  update_teams_team_many?: Maybe<Array<Maybe<Teams_Team_Mutation_Response>>>;
  /** update data of the table: "tournaments.challenge" */
  update_tournaments_challenge?: Maybe<Tournaments_Challenge_Mutation_Response>;
  /** update single row of the table: "tournaments.challenge" */
  update_tournaments_challenge_by_pk?: Maybe<Tournaments_Challenge>;
  /** update multiples rows of table: "tournaments.challenge" */
  update_tournaments_challenge_many?: Maybe<
    Array<Maybe<Tournaments_Challenge_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.challenge_rule" */
  update_tournaments_challenge_rule?: Maybe<Tournaments_Challenge_Rule_Mutation_Response>;
  /** update single row of the table: "tournaments.challenge_rule" */
  update_tournaments_challenge_rule_by_pk?: Maybe<Tournaments_Challenge_Rule>;
  /** update multiples rows of table: "tournaments.challenge_rule" */
  update_tournaments_challenge_rule_many?: Maybe<
    Array<Maybe<Tournaments_Challenge_Rule_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.engagement" */
  update_tournaments_engagement?: Maybe<Tournaments_Engagement_Mutation_Response>;
  /** update single row of the table: "tournaments.engagement" */
  update_tournaments_engagement_by_pk?: Maybe<Tournaments_Engagement>;
  /** update multiples rows of table: "tournaments.engagement" */
  update_tournaments_engagement_many?: Maybe<
    Array<Maybe<Tournaments_Engagement_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.game" */
  update_tournaments_game?: Maybe<Tournaments_Game_Mutation_Response>;
  /** update single row of the table: "tournaments.game" */
  update_tournaments_game_by_pk?: Maybe<Tournaments_Game>;
  /** update multiples rows of table: "tournaments.game" */
  update_tournaments_game_many?: Maybe<
    Array<Maybe<Tournaments_Game_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.phase" */
  update_tournaments_phase?: Maybe<Tournaments_Phase_Mutation_Response>;
  /** update single row of the table: "tournaments.phase" */
  update_tournaments_phase_by_pk?: Maybe<Tournaments_Phase>;
  /** update multiples rows of table: "tournaments.phase" */
  update_tournaments_phase_many?: Maybe<
    Array<Maybe<Tournaments_Phase_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.place" */
  update_tournaments_place?: Maybe<Tournaments_Place_Mutation_Response>;
  /** update single row of the table: "tournaments.place" */
  update_tournaments_place_by_pk?: Maybe<Tournaments_Place>;
  /** update multiples rows of table: "tournaments.place" */
  update_tournaments_place_many?: Maybe<
    Array<Maybe<Tournaments_Place_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.ranking_points" */
  update_tournaments_ranking_points?: Maybe<Tournaments_Ranking_Points_Mutation_Response>;
  /** update single row of the table: "tournaments.ranking_points" */
  update_tournaments_ranking_points_by_pk?: Maybe<Tournaments_Ranking_Points>;
  /** update multiples rows of table: "tournaments.ranking_points" */
  update_tournaments_ranking_points_many?: Maybe<
    Array<Maybe<Tournaments_Ranking_Points_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.ranking_rule" */
  update_tournaments_ranking_rule?: Maybe<Tournaments_Ranking_Rule_Mutation_Response>;
  /** update single row of the table: "tournaments.ranking_rule" */
  update_tournaments_ranking_rule_by_pk?: Maybe<Tournaments_Ranking_Rule>;
  /** update multiples rows of table: "tournaments.ranking_rule" */
  update_tournaments_ranking_rule_many?: Maybe<
    Array<Maybe<Tournaments_Ranking_Rule_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.team" */
  update_tournaments_team?: Maybe<Tournaments_Team_Mutation_Response>;
  /** update single row of the table: "tournaments.team" */
  update_tournaments_team_by_pk?: Maybe<Tournaments_Team>;
  /** update multiples rows of table: "tournaments.team" */
  update_tournaments_team_many?: Maybe<
    Array<Maybe<Tournaments_Team_Mutation_Response>>
  >;
  /** update data of the table: "tournaments.tournament" */
  update_tournaments_tournament?: Maybe<Tournaments_Tournament_Mutation_Response>;
  /** update single row of the table: "tournaments.tournament" */
  update_tournaments_tournament_by_pk?: Maybe<Tournaments_Tournament>;
  /** update multiples rows of table: "tournaments.tournament" */
  update_tournaments_tournament_many?: Maybe<
    Array<Maybe<Tournaments_Tournament_Mutation_Response>>
  >;
  /** update data of the table: "users.convocation_progress" */
  update_users_convocation_progress?: Maybe<Users_Convocation_Progress_Mutation_Response>;
  /** update single row of the table: "users.convocation_progress" */
  update_users_convocation_progress_by_pk?: Maybe<Users_Convocation_Progress>;
  /** update multiples rows of table: "users.convocation_progress" */
  update_users_convocation_progress_many?: Maybe<
    Array<Maybe<Users_Convocation_Progress_Mutation_Response>>
  >;
  /** update data of the table: "users.user" */
  update_users_user?: Maybe<Users_User_Mutation_Response>;
  /** update single row of the table: "users.user" */
  update_users_user_by_pk?: Maybe<Users_User>;
  /** update multiples rows of table: "users.user" */
  update_users_user_many?: Maybe<Array<Maybe<Users_User_Mutation_Response>>>;
  /** update data of the table: "widgets.club_settings" */
  update_widgets_club_settings?: Maybe<Widgets_Club_Settings_Mutation_Response>;
  /** update single row of the table: "widgets.club_settings" */
  update_widgets_club_settings_by_pk?: Maybe<Widgets_Club_Settings>;
  /** update multiples rows of table: "widgets.club_settings" */
  update_widgets_club_settings_many?: Maybe<
    Array<Maybe<Widgets_Club_Settings_Mutation_Response>>
  >;
  /** update data of the table: "widgets.convocation_settings" */
  update_widgets_convocation_settings?: Maybe<Widgets_Convocation_Settings_Mutation_Response>;
  /** update single row of the table: "widgets.convocation_settings" */
  update_widgets_convocation_settings_by_pk?: Maybe<Widgets_Convocation_Settings>;
  /** update multiples rows of table: "widgets.convocation_settings" */
  update_widgets_convocation_settings_many?: Maybe<
    Array<Maybe<Widgets_Convocation_Settings_Mutation_Response>>
  >;
  /** update data of the table: "widgets.widget" */
  update_widgets_widget?: Maybe<Widgets_Widget_Mutation_Response>;
  /** update single row of the table: "widgets.widget" */
  update_widgets_widget_by_pk?: Maybe<Widgets_Widget>;
  /** update data of the table: "widgets.widget_has_competition" */
  update_widgets_widget_has_competition?: Maybe<Widgets_Widget_Has_Competition_Mutation_Response>;
  /** update single row of the table: "widgets.widget_has_competition" */
  update_widgets_widget_has_competition_by_pk?: Maybe<Widgets_Widget_Has_Competition>;
  /** update multiples rows of table: "widgets.widget_has_competition" */
  update_widgets_widget_has_competition_many?: Maybe<
    Array<Maybe<Widgets_Widget_Has_Competition_Mutation_Response>>
  >;
  /** update data of the table: "widgets.widget_has_team" */
  update_widgets_widget_has_team?: Maybe<Widgets_Widget_Has_Team_Mutation_Response>;
  /** update single row of the table: "widgets.widget_has_team" */
  update_widgets_widget_has_team_by_pk?: Maybe<Widgets_Widget_Has_Team>;
  /** update multiples rows of table: "widgets.widget_has_team" */
  update_widgets_widget_has_team_many?: Maybe<
    Array<Maybe<Widgets_Widget_Has_Team_Mutation_Response>>
  >;
  /** update data of the table: "widgets.widget_live" */
  update_widgets_widget_live?: Maybe<Widgets_Widget_Live_Mutation_Response>;
  /** update single row of the table: "widgets.widget_live" */
  update_widgets_widget_live_by_pk?: Maybe<Widgets_Widget_Live>;
  /** update multiples rows of table: "widgets.widget_live" */
  update_widgets_widget_live_many?: Maybe<
    Array<Maybe<Widgets_Widget_Live_Mutation_Response>>
  >;
  /** update multiples rows of table: "widgets.widget" */
  update_widgets_widget_many?: Maybe<
    Array<Maybe<Widgets_Widget_Mutation_Response>>
  >;
  /** update data of the table: "widgets.widget_team" */
  update_widgets_widget_team?: Maybe<Widgets_Widget_Team_Mutation_Response>;
  /** update single row of the table: "widgets.widget_team" */
  update_widgets_widget_team_by_pk?: Maybe<Widgets_Widget_Team>;
  /** update multiples rows of table: "widgets.widget_team" */
  update_widgets_widget_team_many?: Maybe<
    Array<Maybe<Widgets_Widget_Team_Mutation_Response>>
  >;
  upload?: Maybe<UploadFileEntityResponse>;
  users_check_invitation?: Maybe<Users_Check_Invitation_Response>;
  users_create_club_admin?: Maybe<Affected_Rows_Response>;
  users_delete_user?: Maybe<Affected_Rows_Response>;
  /** Envoie un code par SMS à l'utilisateur pour vérifier son téléphone */
  users_send_verification?: Maybe<Users_Send_Verification_Response>;
  /** Vérifie le code reçu par téléphone d'un utilisateur */
  users_verify_user?: Maybe<Users_Verify_User_Response>;
};

/** mutation root */
export type Mutation_RootBilling_Create_Club_CustomerArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  club_id: Scalars['Int']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postal_code: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootBilling_Create_Customer_EstimateArgs = {
  customer_id: Scalars['String']['input'];
  products: Array<PennylaneProducts>;
};

/** mutation root */
export type Mutation_RootBilling_Create_Customer_InvoiceArgs = {
  customer_id: Scalars['String']['input'];
  products: Array<PennylaneProducts>;
};

/** mutation root */
export type Mutation_RootBilling_Create_Customer_Invoice_From_EstimateArgs = {
  estimate_id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootBilling_Update_Club_CustomerArgs = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  club_customer_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postal_code: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootClubhouse_Add_ScreenArgs = {
  screens: Array<Clubhouse_Add_Screen_Insert_Input>;
};

/** mutation root */
export type Mutation_RootCompetitions_Add_FanArgs = {
  competition_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootCompetitions_Create_Exhibition_EventArgs = {
  away_id: Scalars['Int']['input'];
  home_id: Scalars['Int']['input'];
  sport_id: Scalars['Int']['input'];
  time: Scalars['timetz']['input'];
};

/** mutation root */
export type Mutation_RootCompetitions_Delete_FanArgs = {
  competition_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootCompetitions_Missing_Results_Last_Week_For_ClubArgs = {
  club_id: Scalars['Int']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootCompetitions_Playing_Team_Validate_EventArgs = {
  bar_managers_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  equipment_id?: InputMaybe<Scalars['Int']['input']>;
  jobs?: InputMaybe<Array<TaskMembers>>;
  match_date?: InputMaybe<Scalars['date']['input']>;
  match_time?: InputMaybe<Scalars['timestamptz']['input']>;
  meeting_time?: InputMaybe<Scalars['timestamptz']['input']>;
  playing_team_id: Scalars['Int']['input'];
  private_comment?: InputMaybe<Scalars['String']['input']>;
  public_comment?: InputMaybe<Scalars['String']['input']>;
  referees_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  scoring_table_managers_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  stadium_managers_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
};

/** mutation root */
export type Mutation_RootCompetitions_Synchronize_Events_This_Week_For_ClubArgs =
  {
    club_id: Scalars['Int']['input'];
    end_date?: InputMaybe<Scalars['String']['input']>;
    start_date?: InputMaybe<Scalars['String']['input']>;
  };

/** mutation root */
export type Mutation_RootCrawler_Link_Club_To_ExceptionArgs = {
  championship_id: Scalars['Int']['input'];
  club_id: Scalars['Int']['input'];
  exception_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootCrawlers_Crawl_Club_SpiderArgs = {
  championship_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootCrawlers_Crawl_Competition_SpiderArgs = {
  championship_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootCrawlers_Fix_Competition_Not_Synchronized_SpiderArgs =
  {
    championship_id: Scalars['Int']['input'];
  };

/** mutation root */
export type Mutation_RootCreateConfigurationLocalizationArgs = {
  data?: InputMaybe<ConfigurationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreateMenusMenuArgs = {
  data: MenusMenuInput;
};

/** mutation root */
export type Mutation_RootCreateMenusMenuItemArgs = {
  data: MenusMenuItemInput;
};

/** mutation root */
export type Mutation_RootCreatePagesChHomeLocalizationArgs = {
  data?: InputMaybe<PagesChHomeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreatePagesClubArgs = {
  data: PagesClubInput;
};

/** mutation root */
export type Mutation_RootCreatePagesHomeLocalizationArgs = {
  data?: InputMaybe<PagesHomeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreatePagesLandingArgs = {
  data: PagesLandingInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreatePagesLandingLocalizationArgs = {
  data?: InputMaybe<PagesLandingInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreatePagesLiveArgs = {
  data: PagesLiveInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreatePagesLiveLocalizationArgs = {
  data?: InputMaybe<PagesLiveInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreatePagesSportArgs = {
  data: PagesSportInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreatePagesSportLocalizationArgs = {
  data?: InputMaybe<PagesSportInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootCreateUploadFileArgs = {
  data: UploadFileInput;
};

/** mutation root */
export type Mutation_RootCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

/** mutation root */
export type Mutation_RootDeleteConfigurationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootDeleteMenusMenuArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootDeleteMenusMenuItemArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootDeletePagesChHomeArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootDeletePagesClubArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootDeletePagesHomeArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootDeletePagesLandingArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootDeletePagesLiveArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootDeletePagesSportArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Clubhouse_Club_HouseArgs = {
  where: Clubhouse_Club_House_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Clubhouse_Club_House_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Clubhouse_ScreenArgs = {
  where: Clubhouse_Screen_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Clubhouse_Screen_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Competitions_Event_RefereeArgs = {
  where: Competitions_Event_Referee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Competitions_Event_Referee_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Competitions_Playing_Team_Bar_ManagerArgs = {
  where: Competitions_Playing_Team_Bar_Manager_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Competitions_Playing_Team_Bar_Manager_By_PkArgs =
  {
    id: Scalars['Int']['input'];
  };

/** mutation root */
export type Mutation_RootDelete_Competitions_Playing_Team_RefereeArgs = {
  where: Competitions_Playing_Team_Referee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Competitions_Playing_Team_Referee_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Competitions_Playing_Team_Scoring_TableArgs = {
  where: Competitions_Playing_Team_Scoring_Table_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Competitions_Playing_Team_Scoring_Table_By_PkArgs =
  {
    id: Scalars['Int']['input'];
  };

/** mutation root */
export type Mutation_RootDelete_Competitions_Playing_Team_Stadium_ManagerArgs =
  {
    where: Competitions_Playing_Team_Stadium_Manager_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootDelete_Competitions_Playing_Team_Stadium_Manager_By_PkArgs =
  {
    id: Scalars['Int']['input'];
  };

/** mutation root */
export type Mutation_RootDelete_Lives_Live_PlayerArgs = {
  where: Lives_Live_Player_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Lives_Live_Player_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Lives_PostArgs = {
  where: Lives_Post_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Lives_Post_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Members_GroupArgs = {
  where: Members_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Members_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Members_MemberArgs = {
  where: Members_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Members_Member_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Members_Member_In_GroupArgs = {
  where: Members_Member_In_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Members_Member_In_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_News_NewsArgs = {
  where: News_News_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_News_News_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Permissions_Member_RoleArgs = {
  where: Permissions_Member_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Permissions_Member_Role_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Permissions_RoleArgs = {
  where: Permissions_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Permissions_Role_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Permissions_Role_PermissionArgs = {
  where: Permissions_Role_Permission_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Permissions_Role_Permission_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Places_EquipmentArgs = {
  where: Places_Equipment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Places_Equipment_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Sponsors_CategoryArgs = {
  where: Sponsors_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sponsors_Category_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Sponsors_SponsorArgs = {
  where: Sponsors_Sponsor_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sponsors_Sponsor_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Stats_ActionArgs = {
  where: Stats_Action_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stats_Action_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tasks_TaskArgs = {
  where: Tasks_Task_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_Task_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Teams_Authorized_GroupArgs = {
  where: Teams_Authorized_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Teams_Authorized_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Teams_Authorized_TeamArgs = {
  where: Teams_Authorized_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Teams_Authorized_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Teams_Club_GroupArgs = {
  where: Teams_Club_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Teams_Club_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Teams_OfficialArgs = {
  where: Teams_Official_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Teams_Official_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Teams_Team_Action_PermissionArgs = {
  where: Teams_Team_Action_Permission_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Teams_Team_Action_Permission_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Teams_Team_In_SeasonArgs = {
  where: Teams_Team_In_Season_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Teams_Team_In_Season_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Teams_Team_In_Season_Has_MemberArgs = {
  where: Teams_Team_In_Season_Has_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Teams_Team_In_Season_Has_Member_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Teams_Team_OfficialArgs = {
  where: Teams_Team_Official_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Teams_Team_Official_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_ChallengeArgs = {
  where: Tournaments_Challenge_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Challenge_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Challenge_RuleArgs = {
  where: Tournaments_Challenge_Rule_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Challenge_Rule_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Official_Reporter_V2Args = {
  where: Tournaments_Official_Reporter_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Official_Reporter_V2_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_PlaceArgs = {
  where: Tournaments_Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Place_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Ranking_PointsArgs = {
  where: Tournaments_Ranking_Points_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Ranking_Points_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Ranking_RuleArgs = {
  where: Tournaments_Ranking_Rule_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Ranking_Rule_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_TeamArgs = {
  where: Tournaments_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_TournamentArgs = {
  where: Tournaments_Tournament_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tournaments_Tournament_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Users_Member_InvitationArgs = {
  where: Users_Member_Invitation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_Member_Invitation_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Widgets_WidgetArgs = {
  where: Widgets_Widget_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_Has_CompetitionArgs = {
  where: Widgets_Widget_Has_Competition_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_Has_Competition_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_Has_TeamArgs = {
  where: Widgets_Widget_Has_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_Has_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_LiveArgs = {
  where: Widgets_Widget_Live_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_Live_By_PkArgs = {
  widget_ptr_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_TeamArgs = {
  where: Widgets_Widget_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Widgets_Widget_Team_By_PkArgs = {
  widget_ptr_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootEmails_Update_Contact_AttributesArgs = {
  contact_attributes: Scalars['jsonb']['input'];
  email: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootInsert_Billing_Club_CustomerArgs = {
  objects: Array<Billing_Club_Customer_Insert_Input>;
  on_conflict?: InputMaybe<Billing_Club_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Billing_Club_Customer_OneArgs = {
  object: Billing_Club_Customer_Insert_Input;
  on_conflict?: InputMaybe<Billing_Club_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Billing_CustomerArgs = {
  objects: Array<Billing_Customer_Insert_Input>;
  on_conflict?: InputMaybe<Billing_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Billing_Customer_OneArgs = {
  object: Billing_Customer_Insert_Input;
  on_conflict?: InputMaybe<Billing_Customer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clubhouse_Club_HouseArgs = {
  objects: Array<Clubhouse_Club_House_Insert_Input>;
  on_conflict?: InputMaybe<Clubhouse_Club_House_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clubhouse_Club_House_OneArgs = {
  object: Clubhouse_Club_House_Insert_Input;
  on_conflict?: InputMaybe<Clubhouse_Club_House_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Competitions_Event_RefereeArgs = {
  objects: Array<Competitions_Event_Referee_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_Event_Referee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Competitions_Event_Referee_OneArgs = {
  object: Competitions_Event_Referee_Insert_Input;
  on_conflict?: InputMaybe<Competitions_Event_Referee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Competitions_Playing_Team_Bar_ManagerArgs = {
  objects: Array<Competitions_Playing_Team_Bar_Manager_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_Playing_Team_Bar_Manager_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Competitions_Playing_Team_Bar_Manager_OneArgs =
  {
    object: Competitions_Playing_Team_Bar_Manager_Insert_Input;
    on_conflict?: InputMaybe<Competitions_Playing_Team_Bar_Manager_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Competitions_Playing_Team_RefereeArgs = {
  objects: Array<Competitions_Playing_Team_Referee_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_Playing_Team_Referee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Competitions_Playing_Team_Referee_OneArgs = {
  object: Competitions_Playing_Team_Referee_Insert_Input;
  on_conflict?: InputMaybe<Competitions_Playing_Team_Referee_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Competitions_Playing_Team_Scoring_TableArgs = {
  objects: Array<Competitions_Playing_Team_Scoring_Table_Insert_Input>;
  on_conflict?: InputMaybe<Competitions_Playing_Team_Scoring_Table_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Competitions_Playing_Team_Scoring_Table_OneArgs =
  {
    object: Competitions_Playing_Team_Scoring_Table_Insert_Input;
    on_conflict?: InputMaybe<Competitions_Playing_Team_Scoring_Table_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Competitions_Playing_Team_Stadium_ManagerArgs =
  {
    objects: Array<Competitions_Playing_Team_Stadium_Manager_Insert_Input>;
    on_conflict?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Competitions_Playing_Team_Stadium_Manager_OneArgs =
  {
    object: Competitions_Playing_Team_Stadium_Manager_Insert_Input;
    on_conflict?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_On_Conflict>;
  };

/** mutation root */
export type Mutation_RootInsert_Lives_LiveArgs = {
  objects: Array<Lives_Live_Insert_Input>;
  on_conflict?: InputMaybe<Lives_Live_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Live_OneArgs = {
  object: Lives_Live_Insert_Input;
  on_conflict?: InputMaybe<Lives_Live_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Live_PlayerArgs = {
  objects: Array<Lives_Live_Player_Insert_Input>;
  on_conflict?: InputMaybe<Lives_Live_Player_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Live_Player_OneArgs = {
  object: Lives_Live_Player_Insert_Input;
  on_conflict?: InputMaybe<Lives_Live_Player_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Live_TeamArgs = {
  objects: Array<Lives_Live_Team_Insert_Input>;
  on_conflict?: InputMaybe<Lives_Live_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Live_Team_OneArgs = {
  object: Lives_Live_Team_Insert_Input;
  on_conflict?: InputMaybe<Lives_Live_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_PostArgs = {
  objects: Array<Lives_Post_Insert_Input>;
  on_conflict?: InputMaybe<Lives_Post_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Post_OneArgs = {
  object: Lives_Post_Insert_Input;
  on_conflict?: InputMaybe<Lives_Post_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_SpectatorArgs = {
  objects: Array<Lives_Spectator_Insert_Input>;
  on_conflict?: InputMaybe<Lives_Spectator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Spectator_OneArgs = {
  object: Lives_Spectator_Insert_Input;
  on_conflict?: InputMaybe<Lives_Spectator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Thank_YouArgs = {
  objects: Array<Lives_Thank_You_Insert_Input>;
  on_conflict?: InputMaybe<Lives_Thank_You_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lives_Thank_You_OneArgs = {
  object: Lives_Thank_You_Insert_Input;
  on_conflict?: InputMaybe<Lives_Thank_You_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Members_ExportArgs = {
  objects: Array<Members_Export_Insert_Input>;
  on_conflict?: InputMaybe<Members_Export_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Members_Export_OneArgs = {
  object: Members_Export_Insert_Input;
  on_conflict?: InputMaybe<Members_Export_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Members_GroupArgs = {
  objects: Array<Members_Group_Insert_Input>;
  on_conflict?: InputMaybe<Members_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Members_Group_OneArgs = {
  object: Members_Group_Insert_Input;
  on_conflict?: InputMaybe<Members_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Members_MemberArgs = {
  objects: Array<Members_Member_Insert_Input>;
  on_conflict?: InputMaybe<Members_Member_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Members_Member_In_GroupArgs = {
  objects: Array<Members_Member_In_Group_Insert_Input>;
  on_conflict?: InputMaybe<Members_Member_In_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Members_Member_In_Group_OneArgs = {
  object: Members_Member_In_Group_Insert_Input;
  on_conflict?: InputMaybe<Members_Member_In_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Members_Member_OneArgs = {
  object: Members_Member_Insert_Input;
  on_conflict?: InputMaybe<Members_Member_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_News_NewsArgs = {
  objects: Array<News_News_Insert_Input>;
  on_conflict?: InputMaybe<News_News_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_News_News_OneArgs = {
  object: News_News_Insert_Input;
  on_conflict?: InputMaybe<News_News_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Newsletters_ContactArgs = {
  objects: Array<Newsletters_Contact_Insert_Input>;
  on_conflict?: InputMaybe<Newsletters_Contact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notifications_EndpointArgs = {
  objects: Array<Notifications_Endpoint_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_Endpoint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notifications_Endpoint_OneArgs = {
  object: Notifications_Endpoint_Insert_Input;
  on_conflict?: InputMaybe<Notifications_Endpoint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notifications_UserArgs = {
  objects: Array<Notifications_User_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permissions_Member_RoleArgs = {
  objects: Array<Permissions_Member_Role_Insert_Input>;
  on_conflict?: InputMaybe<Permissions_Member_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permissions_Member_Role_OneArgs = {
  object: Permissions_Member_Role_Insert_Input;
  on_conflict?: InputMaybe<Permissions_Member_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permissions_RoleArgs = {
  objects: Array<Permissions_Role_Insert_Input>;
  on_conflict?: InputMaybe<Permissions_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permissions_Role_OneArgs = {
  object: Permissions_Role_Insert_Input;
  on_conflict?: InputMaybe<Permissions_Role_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permissions_Role_PermissionArgs = {
  objects: Array<Permissions_Role_Permission_Insert_Input>;
  on_conflict?: InputMaybe<Permissions_Role_Permission_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permissions_Role_Permission_OneArgs = {
  object: Permissions_Role_Permission_Insert_Input;
  on_conflict?: InputMaybe<Permissions_Role_Permission_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Places_CityArgs = {
  objects: Array<Places_City_Insert_Input>;
  on_conflict?: InputMaybe<Places_City_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Places_City_OneArgs = {
  object: Places_City_Insert_Input;
  on_conflict?: InputMaybe<Places_City_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Places_EquipmentArgs = {
  objects: Array<Places_Equipment_Insert_Input>;
  on_conflict?: InputMaybe<Places_Equipment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Places_Equipment_OneArgs = {
  object: Places_Equipment_Insert_Input;
  on_conflict?: InputMaybe<Places_Equipment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Places_PlaceArgs = {
  objects: Array<Places_Place_Insert_Input>;
  on_conflict?: InputMaybe<Places_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Places_Place_OneArgs = {
  object: Places_Place_Insert_Input;
  on_conflict?: InputMaybe<Places_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sponsors_CategoryArgs = {
  objects: Array<Sponsors_Category_Insert_Input>;
  on_conflict?: InputMaybe<Sponsors_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sponsors_Category_OneArgs = {
  object: Sponsors_Category_Insert_Input;
  on_conflict?: InputMaybe<Sponsors_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sponsors_SponsorArgs = {
  objects: Array<Sponsors_Sponsor_Insert_Input>;
  on_conflict?: InputMaybe<Sponsors_Sponsor_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sponsors_Sponsor_OneArgs = {
  object: Sponsors_Sponsor_Insert_Input;
  on_conflict?: InputMaybe<Sponsors_Sponsor_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stats_ActionArgs = {
  objects: Array<Stats_Action_Insert_Input>;
  on_conflict?: InputMaybe<Stats_Action_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stats_Action_OneArgs = {
  object: Stats_Action_Insert_Input;
  on_conflict?: InputMaybe<Stats_Action_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Member_Task_CountArgs = {
  objects: Array<Tasks_Member_Task_Count_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Member_Task_Count_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Member_Task_Count_OneArgs = {
  object: Tasks_Member_Task_Count_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Member_Task_Count_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_TaskArgs = {
  objects: Array<Tasks_Task_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_Task_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_Task_OneArgs = {
  object: Tasks_Task_Insert_Input;
  on_conflict?: InputMaybe<Tasks_Task_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Authorized_GroupArgs = {
  objects: Array<Teams_Authorized_Group_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Authorized_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Authorized_Group_OneArgs = {
  object: Teams_Authorized_Group_Insert_Input;
  on_conflict?: InputMaybe<Teams_Authorized_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Authorized_TeamArgs = {
  objects: Array<Teams_Authorized_Team_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Authorized_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Authorized_Team_OneArgs = {
  object: Teams_Authorized_Team_Insert_Input;
  on_conflict?: InputMaybe<Teams_Authorized_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Club_GroupArgs = {
  objects: Array<Teams_Club_Group_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Club_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Club_Group_OneArgs = {
  object: Teams_Club_Group_Insert_Input;
  on_conflict?: InputMaybe<Teams_Club_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_OfficialArgs = {
  objects: Array<Teams_Official_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Official_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Official_OneArgs = {
  object: Teams_Official_Insert_Input;
  on_conflict?: InputMaybe<Teams_Official_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Team_Action_PermissionArgs = {
  objects: Array<Teams_Team_Action_Permission_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Team_Action_Permission_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Team_Action_Permission_OneArgs = {
  object: Teams_Team_Action_Permission_Insert_Input;
  on_conflict?: InputMaybe<Teams_Team_Action_Permission_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Team_In_Season_Has_MemberArgs = {
  objects: Array<Teams_Team_In_Season_Has_Member_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Team_In_Season_Has_Member_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Team_In_Season_Has_Member_OneArgs = {
  object: Teams_Team_In_Season_Has_Member_Insert_Input;
  on_conflict?: InputMaybe<Teams_Team_In_Season_Has_Member_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Team_OfficialArgs = {
  objects: Array<Teams_Team_Official_Insert_Input>;
  on_conflict?: InputMaybe<Teams_Team_Official_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Teams_Team_Official_OneArgs = {
  object: Teams_Team_Official_Insert_Input;
  on_conflict?: InputMaybe<Teams_Team_Official_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_ChallengeArgs = {
  objects: Array<Tournaments_Challenge_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_Challenge_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Challenge_OneArgs = {
  object: Tournaments_Challenge_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_Challenge_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Challenge_RuleArgs = {
  objects: Array<Tournaments_Challenge_Rule_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_Challenge_Rule_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Challenge_Rule_OneArgs = {
  object: Tournaments_Challenge_Rule_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_Challenge_Rule_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Official_Reporter_V2Args = {
  objects: Array<Tournaments_Official_Reporter_V2_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_Official_Reporter_V2_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Official_Reporter_V2_OneArgs = {
  object: Tournaments_Official_Reporter_V2_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_Official_Reporter_V2_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_PlaceArgs = {
  objects: Array<Tournaments_Place_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Place_OneArgs = {
  object: Tournaments_Place_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Ranking_PointsArgs = {
  objects: Array<Tournaments_Ranking_Points_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_Ranking_Points_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Ranking_Points_OneArgs = {
  object: Tournaments_Ranking_Points_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_Ranking_Points_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Ranking_RuleArgs = {
  objects: Array<Tournaments_Ranking_Rule_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_Ranking_Rule_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Ranking_Rule_OneArgs = {
  object: Tournaments_Ranking_Rule_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_Ranking_Rule_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_TeamArgs = {
  objects: Array<Tournaments_Team_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Team_OneArgs = {
  object: Tournaments_Team_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_TournamentArgs = {
  objects: Array<Tournaments_Tournament_Insert_Input>;
  on_conflict?: InputMaybe<Tournaments_Tournament_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tournaments_Tournament_OneArgs = {
  object: Tournaments_Tournament_Insert_Input;
  on_conflict?: InputMaybe<Tournaments_Tournament_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Club_SettingsArgs = {
  objects: Array<Widgets_Club_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Widgets_Club_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Club_Settings_OneArgs = {
  object: Widgets_Club_Settings_Insert_Input;
  on_conflict?: InputMaybe<Widgets_Club_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Convocation_SettingsArgs = {
  objects: Array<Widgets_Convocation_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Widgets_Convocation_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Convocation_Settings_OneArgs = {
  object: Widgets_Convocation_Settings_Insert_Input;
  on_conflict?: InputMaybe<Widgets_Convocation_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_WidgetArgs = {
  objects: Array<Widgets_Widget_Insert_Input>;
  on_conflict?: InputMaybe<Widgets_Widget_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_Has_CompetitionArgs = {
  objects: Array<Widgets_Widget_Has_Competition_Insert_Input>;
  on_conflict?: InputMaybe<Widgets_Widget_Has_Competition_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_Has_Competition_OneArgs = {
  object: Widgets_Widget_Has_Competition_Insert_Input;
  on_conflict?: InputMaybe<Widgets_Widget_Has_Competition_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_Has_TeamArgs = {
  objects: Array<Widgets_Widget_Has_Team_Insert_Input>;
  on_conflict?: InputMaybe<Widgets_Widget_Has_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_Has_Team_OneArgs = {
  object: Widgets_Widget_Has_Team_Insert_Input;
  on_conflict?: InputMaybe<Widgets_Widget_Has_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_LiveArgs = {
  objects: Array<Widgets_Widget_Live_Insert_Input>;
  on_conflict?: InputMaybe<Widgets_Widget_Live_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_Live_OneArgs = {
  object: Widgets_Widget_Live_Insert_Input;
  on_conflict?: InputMaybe<Widgets_Widget_Live_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_OneArgs = {
  object: Widgets_Widget_Insert_Input;
  on_conflict?: InputMaybe<Widgets_Widget_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_TeamArgs = {
  objects: Array<Widgets_Widget_Team_Insert_Input>;
  on_conflict?: InputMaybe<Widgets_Widget_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Widgets_Widget_Team_OneArgs = {
  object: Widgets_Widget_Team_Insert_Input;
  on_conflict?: InputMaybe<Widgets_Widget_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootLives_Add_PostArgs = {
  action_id: Scalars['Int']['input'];
  add_post?: InputMaybe<Scalars['Boolean']['input']>;
  data: Scalars['jsonb']['input'];
  live_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootLives_Import_PlayersArgs = {
  live_team_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootLives_New_HitArgs = {
  event_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootLives_Update_Official_ReporterArgs = {
  is_present: Scalars['Boolean']['input'];
  live_id: Scalars['Int']['input'];
  team_official_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootMembers_Delete_MemberArgs = {
  member_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootMembers_Update_Official_Reporter_TeamsArgs = {
  member_id: Scalars['Int']['input'];
  team_ids: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

/** mutation root */
export type Mutation_RootPlaces_Get_Place_Id_From_AddressArgs = {
  address: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootScraping_Get_New_CompetitionsArgs = {
  club_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootScraping_Get_New_RoundsArgs = {
  competition_id: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootScraping_Synchronize_CompetitionArgs = {
  competition_id: Array<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootScraping_Synchronize_EventArgs = {
  event_id: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootScraping_Update_Next_RoundsArgs = {
  club_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootSponsors_Update_TeamsArgs = {
  sponsor_id: Scalars['Int']['input'];
  team_ids: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootTasks_Delete_TaskArgs = {
  task_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTasks_Insert_Or_Update_Permissions_For_TeamArgs = {
  group_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  officials?: InputMaybe<Scalars['Boolean']['input']>;
  task_id: Scalars['Int']['input'];
  team_id: Scalars['Int']['input'];
  team_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** mutation root */
export type Mutation_RootTasks_Insert_Or_Update_Volunteers_For_JobsArgs = {
  jobs?: InputMaybe<Array<TaskMembers>>;
  playing_team_info_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTeams_Add_FanArgs = {
  team_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTeams_Add_TeamArgs = {
  age_category: Scalars['String']['input'];
  club_id: Scalars['Int']['input'];
  gender: Scalars['String']['input'];
  number: Scalars['smallint']['input'];
  sport_sub_category_id?: InputMaybe<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootTeams_Delete_FanArgs = {
  team_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTeams_Delete_Team_In_SeasonArgs = {
  team_in_season_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTeams_Migrate_To_SeasonArgs = {
  season_id?: InputMaybe<Scalars['Int']['input']>;
  team_in_season_ids: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootTeams_Set_Default_RankArgs = {
  club_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTeams_Set_RankArgs = {
  club_id: Scalars['Int']['input'];
  team_ids: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootTeams_Update_Team_NameArgs = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  name_in_club?: InputMaybe<Scalars['String']['input']>;
  name_in_competition?: InputMaybe<Scalars['String']['input']>;
  team_in_season_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Create_CupArgs = {
  double?: InputMaybe<Scalars['Boolean']['input']>;
  nb_teams: Scalars['Int']['input'];
  pool_rank: Scalars['Int']['input'];
  ranking_games?: InputMaybe<Scalars['Boolean']['input']>;
  rule_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Create_PhaseArgs = {
  challenge_id: Scalars['Int']['input'];
  phase_rank: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Create_PoolArgs = {
  double?: InputMaybe<Scalars['Boolean']['input']>;
  nb_teams: Scalars['Int']['input'];
  pool_rank: Scalars['Int']['input'];
  rule_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Create_TestArgs = {
  club_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Delete_ChallengeArgs = {
  challenge_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Delete_Cup_Or_PoolArgs = {
  phase_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Delete_PhaseArgs = {
  rule_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_DuplicateArgs = {
  tournament_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Duplicate_ChallengeArgs = {
  challenge_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_New_HitArgs = {
  challenge_id?: InputMaybe<Scalars['Int']['input']>;
  source: Scalars['String']['input'];
  tournament_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTournaments_Phase_Copy_TimesArgs = {
  from_phase_id: Scalars['Int']['input'];
  to_phases_ids: Array<Scalars['Int']['input']>;
};

/** mutation root */
export type Mutation_RootUpdateConfigurationArgs = {
  data: ConfigurationInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

/** mutation root */
export type Mutation_RootUpdateMenusMenuArgs = {
  data: MenusMenuInput;
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootUpdateMenusMenuItemArgs = {
  data: MenusMenuItemInput;
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootUpdatePagesChHomeArgs = {
  data: PagesChHomeInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootUpdatePagesClubArgs = {
  data: PagesClubInput;
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootUpdatePagesHomeArgs = {
  data: PagesHomeInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootUpdatePagesLandingArgs = {
  data: PagesLandingInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootUpdatePagesLiveArgs = {
  data: PagesLiveInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootUpdatePagesSportArgs = {
  data: PagesSportInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

/** mutation root */
export type Mutation_RootUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

/** mutation root */
export type Mutation_RootUpdate_Billing_Club_CustomerArgs = {
  _inc?: InputMaybe<Billing_Club_Customer_Inc_Input>;
  _set?: InputMaybe<Billing_Club_Customer_Set_Input>;
  where: Billing_Club_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Billing_Club_Customer_By_PkArgs = {
  _inc?: InputMaybe<Billing_Club_Customer_Inc_Input>;
  _set?: InputMaybe<Billing_Club_Customer_Set_Input>;
  pk_columns: Billing_Club_Customer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Billing_Club_Customer_ManyArgs = {
  updates: Array<Billing_Club_Customer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Billing_CustomerArgs = {
  _set?: InputMaybe<Billing_Customer_Set_Input>;
  where: Billing_Customer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Billing_Customer_By_PkArgs = {
  _set?: InputMaybe<Billing_Customer_Set_Input>;
  pk_columns: Billing_Customer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Billing_Customer_ManyArgs = {
  updates: Array<Billing_Customer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clubhouse_Club_HouseArgs = {
  _set?: InputMaybe<Clubhouse_Club_House_Set_Input>;
  where: Clubhouse_Club_House_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clubhouse_Club_House_By_PkArgs = {
  _set?: InputMaybe<Clubhouse_Club_House_Set_Input>;
  pk_columns: Clubhouse_Club_House_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clubhouse_Club_House_ManyArgs = {
  updates: Array<Clubhouse_Club_House_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clubhouse_ScreenArgs = {
  _inc?: InputMaybe<Clubhouse_Screen_Inc_Input>;
  _set?: InputMaybe<Clubhouse_Screen_Set_Input>;
  where: Clubhouse_Screen_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clubhouse_Screen_By_PkArgs = {
  _inc?: InputMaybe<Clubhouse_Screen_Inc_Input>;
  _set?: InputMaybe<Clubhouse_Screen_Set_Input>;
  pk_columns: Clubhouse_Screen_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clubhouse_Screen_ManyArgs = {
  updates: Array<Clubhouse_Screen_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clubs_ClubArgs = {
  _inc?: InputMaybe<Clubs_Club_Inc_Input>;
  _set?: InputMaybe<Clubs_Club_Set_Input>;
  where: Clubs_Club_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clubs_Club_By_PkArgs = {
  _inc?: InputMaybe<Clubs_Club_Inc_Input>;
  _set?: InputMaybe<Clubs_Club_Set_Input>;
  pk_columns: Clubs_Club_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clubs_Club_ManyArgs = {
  updates: Array<Clubs_Club_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clubs_Planner_SettingsArgs = {
  _inc?: InputMaybe<Clubs_Planner_Settings_Inc_Input>;
  _set?: InputMaybe<Clubs_Planner_Settings_Set_Input>;
  where: Clubs_Planner_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clubs_Planner_Settings_By_PkArgs = {
  _inc?: InputMaybe<Clubs_Planner_Settings_Inc_Input>;
  _set?: InputMaybe<Clubs_Planner_Settings_Set_Input>;
  pk_columns: Clubs_Planner_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clubs_Planner_Settings_ManyArgs = {
  updates: Array<Clubs_Planner_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Competition_Has_TeamsArgs = {
  _inc?: InputMaybe<Competitions_Competition_Has_Teams_Inc_Input>;
  _set?: InputMaybe<Competitions_Competition_Has_Teams_Set_Input>;
  where: Competitions_Competition_Has_Teams_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Competition_Has_Teams_By_PkArgs = {
  _inc?: InputMaybe<Competitions_Competition_Has_Teams_Inc_Input>;
  _set?: InputMaybe<Competitions_Competition_Has_Teams_Set_Input>;
  pk_columns: Competitions_Competition_Has_Teams_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Competition_Has_Teams_ManyArgs = {
  updates: Array<Competitions_Competition_Has_Teams_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_EventArgs = {
  _inc?: InputMaybe<Competitions_Event_Inc_Input>;
  _set?: InputMaybe<Competitions_Event_Set_Input>;
  where: Competitions_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Event_By_PkArgs = {
  _inc?: InputMaybe<Competitions_Event_Inc_Input>;
  _set?: InputMaybe<Competitions_Event_Set_Input>;
  pk_columns: Competitions_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Event_ManyArgs = {
  updates: Array<Competitions_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Event_RefereeArgs = {
  _inc?: InputMaybe<Competitions_Event_Referee_Inc_Input>;
  _set?: InputMaybe<Competitions_Event_Referee_Set_Input>;
  where: Competitions_Event_Referee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Event_Referee_By_PkArgs = {
  _inc?: InputMaybe<Competitions_Event_Referee_Inc_Input>;
  _set?: InputMaybe<Competitions_Event_Referee_Set_Input>;
  pk_columns: Competitions_Event_Referee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Event_Referee_ManyArgs = {
  updates: Array<Competitions_Event_Referee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Bar_ManagerArgs = {
  _inc?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Inc_Input>;
  _set?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Set_Input>;
  where: Competitions_Playing_Team_Bar_Manager_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Bar_Manager_By_PkArgs =
  {
    _inc?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Inc_Input>;
    _set?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Set_Input>;
    pk_columns: Competitions_Playing_Team_Bar_Manager_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Bar_Manager_ManyArgs =
  {
    updates: Array<Competitions_Playing_Team_Bar_Manager_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_RefereeArgs = {
  _inc?: InputMaybe<Competitions_Playing_Team_Referee_Inc_Input>;
  _set?: InputMaybe<Competitions_Playing_Team_Referee_Set_Input>;
  where: Competitions_Playing_Team_Referee_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Referee_By_PkArgs = {
  _inc?: InputMaybe<Competitions_Playing_Team_Referee_Inc_Input>;
  _set?: InputMaybe<Competitions_Playing_Team_Referee_Set_Input>;
  pk_columns: Competitions_Playing_Team_Referee_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Referee_ManyArgs = {
  updates: Array<Competitions_Playing_Team_Referee_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Scoring_TableArgs = {
  _inc?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Inc_Input>;
  _set?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Set_Input>;
  where: Competitions_Playing_Team_Scoring_Table_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Scoring_Table_By_PkArgs =
  {
    _inc?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Inc_Input>;
    _set?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Set_Input>;
    pk_columns: Competitions_Playing_Team_Scoring_Table_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Scoring_Table_ManyArgs =
  {
    updates: Array<Competitions_Playing_Team_Scoring_Table_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Stadium_ManagerArgs =
  {
    _inc?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Inc_Input>;
    _set?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Set_Input>;
    where: Competitions_Playing_Team_Stadium_Manager_Bool_Exp;
  };

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Stadium_Manager_By_PkArgs =
  {
    _inc?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Inc_Input>;
    _set?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Set_Input>;
    pk_columns: Competitions_Playing_Team_Stadium_Manager_Pk_Columns_Input;
  };

/** mutation root */
export type Mutation_RootUpdate_Competitions_Playing_Team_Stadium_Manager_ManyArgs =
  {
    updates: Array<Competitions_Playing_Team_Stadium_Manager_Updates>;
  };

/** mutation root */
export type Mutation_RootUpdate_Lives_LiveArgs = {
  _inc?: InputMaybe<Lives_Live_Inc_Input>;
  _set?: InputMaybe<Lives_Live_Set_Input>;
  where: Lives_Live_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Live_By_PkArgs = {
  _inc?: InputMaybe<Lives_Live_Inc_Input>;
  _set?: InputMaybe<Lives_Live_Set_Input>;
  pk_columns: Lives_Live_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Live_ManyArgs = {
  updates: Array<Lives_Live_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Live_PlayerArgs = {
  _inc?: InputMaybe<Lives_Live_Player_Inc_Input>;
  _set?: InputMaybe<Lives_Live_Player_Set_Input>;
  where: Lives_Live_Player_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Live_Player_By_PkArgs = {
  _inc?: InputMaybe<Lives_Live_Player_Inc_Input>;
  _set?: InputMaybe<Lives_Live_Player_Set_Input>;
  pk_columns: Lives_Live_Player_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Live_Player_ManyArgs = {
  updates: Array<Lives_Live_Player_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Live_TeamArgs = {
  _inc?: InputMaybe<Lives_Live_Team_Inc_Input>;
  _set?: InputMaybe<Lives_Live_Team_Set_Input>;
  where: Lives_Live_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Live_Team_By_PkArgs = {
  _inc?: InputMaybe<Lives_Live_Team_Inc_Input>;
  _set?: InputMaybe<Lives_Live_Team_Set_Input>;
  pk_columns: Lives_Live_Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Live_Team_ManyArgs = {
  updates: Array<Lives_Live_Team_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Media_Official_ReporterArgs = {
  _set?: InputMaybe<Lives_Media_Official_Reporter_Set_Input>;
  where: Lives_Media_Official_Reporter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Media_Official_Reporter_By_PkArgs = {
  _set?: InputMaybe<Lives_Media_Official_Reporter_Set_Input>;
  pk_columns: Lives_Media_Official_Reporter_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Media_Official_Reporter_ManyArgs = {
  updates: Array<Lives_Media_Official_Reporter_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_PostArgs = {
  _append?: InputMaybe<Lives_Post_Append_Input>;
  _delete_at_path?: InputMaybe<Lives_Post_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lives_Post_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lives_Post_Delete_Key_Input>;
  _prepend?: InputMaybe<Lives_Post_Prepend_Input>;
  _set?: InputMaybe<Lives_Post_Set_Input>;
  where: Lives_Post_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Post_By_PkArgs = {
  _append?: InputMaybe<Lives_Post_Append_Input>;
  _delete_at_path?: InputMaybe<Lives_Post_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lives_Post_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lives_Post_Delete_Key_Input>;
  _prepend?: InputMaybe<Lives_Post_Prepend_Input>;
  _set?: InputMaybe<Lives_Post_Set_Input>;
  pk_columns: Lives_Post_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Post_ManyArgs = {
  updates: Array<Lives_Post_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_SpectatorArgs = {
  _set?: InputMaybe<Lives_Spectator_Set_Input>;
  where: Lives_Spectator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Spectator_By_PkArgs = {
  _set?: InputMaybe<Lives_Spectator_Set_Input>;
  pk_columns: Lives_Spectator_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Spectator_ManyArgs = {
  updates: Array<Lives_Spectator_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Thank_YouArgs = {
  _set?: InputMaybe<Lives_Thank_You_Set_Input>;
  where: Lives_Thank_You_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Thank_You_By_PkArgs = {
  _set?: InputMaybe<Lives_Thank_You_Set_Input>;
  pk_columns: Lives_Thank_You_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lives_Thank_You_ManyArgs = {
  updates: Array<Lives_Thank_You_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Members_GroupArgs = {
  _set?: InputMaybe<Members_Group_Set_Input>;
  where: Members_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Members_Group_By_PkArgs = {
  _set?: InputMaybe<Members_Group_Set_Input>;
  pk_columns: Members_Group_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Members_Group_ManyArgs = {
  updates: Array<Members_Group_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Members_MemberArgs = {
  _inc?: InputMaybe<Members_Member_Inc_Input>;
  _set?: InputMaybe<Members_Member_Set_Input>;
  where: Members_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Members_Member_By_PkArgs = {
  _inc?: InputMaybe<Members_Member_Inc_Input>;
  _set?: InputMaybe<Members_Member_Set_Input>;
  pk_columns: Members_Member_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Members_Member_In_GroupArgs = {
  _set?: InputMaybe<Members_Member_In_Group_Set_Input>;
  where: Members_Member_In_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Members_Member_In_Group_By_PkArgs = {
  _set?: InputMaybe<Members_Member_In_Group_Set_Input>;
  pk_columns: Members_Member_In_Group_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Members_Member_In_Group_ManyArgs = {
  updates: Array<Members_Member_In_Group_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Members_Member_ManyArgs = {
  updates: Array<Members_Member_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_News_NewsArgs = {
  _inc?: InputMaybe<News_News_Inc_Input>;
  _set?: InputMaybe<News_News_Set_Input>;
  where: News_News_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_News_News_By_PkArgs = {
  _inc?: InputMaybe<News_News_Inc_Input>;
  _set?: InputMaybe<News_News_Set_Input>;
  pk_columns: News_News_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_News_News_ManyArgs = {
  updates: Array<News_News_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_EndpointArgs = {
  _set?: InputMaybe<Notifications_Endpoint_Set_Input>;
  where: Notifications_Endpoint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_Endpoint_By_PkArgs = {
  _set?: InputMaybe<Notifications_Endpoint_Set_Input>;
  pk_columns: Notifications_Endpoint_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_Endpoint_ManyArgs = {
  updates: Array<Notifications_Endpoint_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_UserArgs = {
  _set?: InputMaybe<Notifications_User_Set_Input>;
  where: Notifications_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_User_ManyArgs = {
  updates: Array<Notifications_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Permissions_RoleArgs = {
  _set?: InputMaybe<Permissions_Role_Set_Input>;
  where: Permissions_Role_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Permissions_Role_By_PkArgs = {
  _set?: InputMaybe<Permissions_Role_Set_Input>;
  pk_columns: Permissions_Role_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Permissions_Role_ManyArgs = {
  updates: Array<Permissions_Role_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Permissions_Role_PermissionArgs = {
  _set?: InputMaybe<Permissions_Role_Permission_Set_Input>;
  where: Permissions_Role_Permission_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Permissions_Role_Permission_By_PkArgs = {
  _set?: InputMaybe<Permissions_Role_Permission_Set_Input>;
  pk_columns: Permissions_Role_Permission_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Permissions_Role_Permission_ManyArgs = {
  updates: Array<Permissions_Role_Permission_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Places_EquipmentArgs = {
  _inc?: InputMaybe<Places_Equipment_Inc_Input>;
  _set?: InputMaybe<Places_Equipment_Set_Input>;
  where: Places_Equipment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Places_Equipment_By_PkArgs = {
  _inc?: InputMaybe<Places_Equipment_Inc_Input>;
  _set?: InputMaybe<Places_Equipment_Set_Input>;
  pk_columns: Places_Equipment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Places_Equipment_ManyArgs = {
  updates: Array<Places_Equipment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Sponsors_CategoryArgs = {
  _inc?: InputMaybe<Sponsors_Category_Inc_Input>;
  _set?: InputMaybe<Sponsors_Category_Set_Input>;
  where: Sponsors_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sponsors_Category_By_PkArgs = {
  _inc?: InputMaybe<Sponsors_Category_Inc_Input>;
  _set?: InputMaybe<Sponsors_Category_Set_Input>;
  pk_columns: Sponsors_Category_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sponsors_Category_ManyArgs = {
  updates: Array<Sponsors_Category_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Sponsors_SponsorArgs = {
  _inc?: InputMaybe<Sponsors_Sponsor_Inc_Input>;
  _set?: InputMaybe<Sponsors_Sponsor_Set_Input>;
  where: Sponsors_Sponsor_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sponsors_Sponsor_By_PkArgs = {
  _inc?: InputMaybe<Sponsors_Sponsor_Inc_Input>;
  _set?: InputMaybe<Sponsors_Sponsor_Set_Input>;
  pk_columns: Sponsors_Sponsor_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sponsors_Sponsor_ManyArgs = {
  updates: Array<Sponsors_Sponsor_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stats_ActionArgs = {
  _inc?: InputMaybe<Stats_Action_Inc_Input>;
  _set?: InputMaybe<Stats_Action_Set_Input>;
  where: Stats_Action_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stats_Action_By_PkArgs = {
  _inc?: InputMaybe<Stats_Action_Inc_Input>;
  _set?: InputMaybe<Stats_Action_Set_Input>;
  pk_columns: Stats_Action_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stats_Action_ManyArgs = {
  updates: Array<Stats_Action_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Member_Task_CountArgs = {
  _inc?: InputMaybe<Tasks_Member_Task_Count_Inc_Input>;
  _set?: InputMaybe<Tasks_Member_Task_Count_Set_Input>;
  where: Tasks_Member_Task_Count_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Member_Task_Count_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Member_Task_Count_Inc_Input>;
  _set?: InputMaybe<Tasks_Member_Task_Count_Set_Input>;
  pk_columns: Tasks_Member_Task_Count_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Member_Task_Count_ManyArgs = {
  updates: Array<Tasks_Member_Task_Count_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_TaskArgs = {
  _inc?: InputMaybe<Tasks_Task_Inc_Input>;
  _set?: InputMaybe<Tasks_Task_Set_Input>;
  where: Tasks_Task_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Task_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Task_Inc_Input>;
  _set?: InputMaybe<Tasks_Task_Set_Input>;
  pk_columns: Tasks_Task_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_Task_ManyArgs = {
  updates: Array<Tasks_Task_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Authorized_GroupArgs = {
  _inc?: InputMaybe<Teams_Authorized_Group_Inc_Input>;
  _set?: InputMaybe<Teams_Authorized_Group_Set_Input>;
  where: Teams_Authorized_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Authorized_Group_By_PkArgs = {
  _inc?: InputMaybe<Teams_Authorized_Group_Inc_Input>;
  _set?: InputMaybe<Teams_Authorized_Group_Set_Input>;
  pk_columns: Teams_Authorized_Group_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Authorized_Group_ManyArgs = {
  updates: Array<Teams_Authorized_Group_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Authorized_TeamArgs = {
  _inc?: InputMaybe<Teams_Authorized_Team_Inc_Input>;
  _set?: InputMaybe<Teams_Authorized_Team_Set_Input>;
  where: Teams_Authorized_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Authorized_Team_By_PkArgs = {
  _inc?: InputMaybe<Teams_Authorized_Team_Inc_Input>;
  _set?: InputMaybe<Teams_Authorized_Team_Set_Input>;
  pk_columns: Teams_Authorized_Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Authorized_Team_ManyArgs = {
  updates: Array<Teams_Authorized_Team_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Club_GroupArgs = {
  _inc?: InputMaybe<Teams_Club_Group_Inc_Input>;
  _set?: InputMaybe<Teams_Club_Group_Set_Input>;
  where: Teams_Club_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Club_Group_By_PkArgs = {
  _inc?: InputMaybe<Teams_Club_Group_Inc_Input>;
  _set?: InputMaybe<Teams_Club_Group_Set_Input>;
  pk_columns: Teams_Club_Group_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Club_Group_ManyArgs = {
  updates: Array<Teams_Club_Group_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_TeamArgs = {
  _inc?: InputMaybe<Teams_Team_Inc_Input>;
  _set?: InputMaybe<Teams_Team_Set_Input>;
  where: Teams_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_Action_PermissionArgs = {
  _set?: InputMaybe<Teams_Team_Action_Permission_Set_Input>;
  where: Teams_Team_Action_Permission_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_Action_Permission_By_PkArgs = {
  _set?: InputMaybe<Teams_Team_Action_Permission_Set_Input>;
  pk_columns: Teams_Team_Action_Permission_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_Action_Permission_ManyArgs = {
  updates: Array<Teams_Team_Action_Permission_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_By_PkArgs = {
  _inc?: InputMaybe<Teams_Team_Inc_Input>;
  _set?: InputMaybe<Teams_Team_Set_Input>;
  pk_columns: Teams_Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_In_SeasonArgs = {
  _inc?: InputMaybe<Teams_Team_In_Season_Inc_Input>;
  _set?: InputMaybe<Teams_Team_In_Season_Set_Input>;
  where: Teams_Team_In_Season_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_In_Season_By_PkArgs = {
  _inc?: InputMaybe<Teams_Team_In_Season_Inc_Input>;
  _set?: InputMaybe<Teams_Team_In_Season_Set_Input>;
  pk_columns: Teams_Team_In_Season_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_In_Season_Has_MemberArgs = {
  _inc?: InputMaybe<Teams_Team_In_Season_Has_Member_Inc_Input>;
  _set?: InputMaybe<Teams_Team_In_Season_Has_Member_Set_Input>;
  where: Teams_Team_In_Season_Has_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_In_Season_Has_Member_By_PkArgs = {
  _inc?: InputMaybe<Teams_Team_In_Season_Has_Member_Inc_Input>;
  _set?: InputMaybe<Teams_Team_In_Season_Has_Member_Set_Input>;
  pk_columns: Teams_Team_In_Season_Has_Member_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_In_Season_Has_Member_ManyArgs = {
  updates: Array<Teams_Team_In_Season_Has_Member_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_In_Season_ManyArgs = {
  updates: Array<Teams_Team_In_Season_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Teams_Team_ManyArgs = {
  updates: Array<Teams_Team_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_ChallengeArgs = {
  _inc?: InputMaybe<Tournaments_Challenge_Inc_Input>;
  _set?: InputMaybe<Tournaments_Challenge_Set_Input>;
  where: Tournaments_Challenge_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Challenge_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Challenge_Inc_Input>;
  _set?: InputMaybe<Tournaments_Challenge_Set_Input>;
  pk_columns: Tournaments_Challenge_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Challenge_ManyArgs = {
  updates: Array<Tournaments_Challenge_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Challenge_RuleArgs = {
  _inc?: InputMaybe<Tournaments_Challenge_Rule_Inc_Input>;
  _set?: InputMaybe<Tournaments_Challenge_Rule_Set_Input>;
  where: Tournaments_Challenge_Rule_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Challenge_Rule_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Challenge_Rule_Inc_Input>;
  _set?: InputMaybe<Tournaments_Challenge_Rule_Set_Input>;
  pk_columns: Tournaments_Challenge_Rule_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Challenge_Rule_ManyArgs = {
  updates: Array<Tournaments_Challenge_Rule_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_EngagementArgs = {
  _inc?: InputMaybe<Tournaments_Engagement_Inc_Input>;
  _set?: InputMaybe<Tournaments_Engagement_Set_Input>;
  where: Tournaments_Engagement_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Engagement_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Engagement_Inc_Input>;
  _set?: InputMaybe<Tournaments_Engagement_Set_Input>;
  pk_columns: Tournaments_Engagement_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Engagement_ManyArgs = {
  updates: Array<Tournaments_Engagement_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_GameArgs = {
  _inc?: InputMaybe<Tournaments_Game_Inc_Input>;
  _set?: InputMaybe<Tournaments_Game_Set_Input>;
  where: Tournaments_Game_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Game_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Game_Inc_Input>;
  _set?: InputMaybe<Tournaments_Game_Set_Input>;
  pk_columns: Tournaments_Game_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Game_ManyArgs = {
  updates: Array<Tournaments_Game_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_PhaseArgs = {
  _inc?: InputMaybe<Tournaments_Phase_Inc_Input>;
  _set?: InputMaybe<Tournaments_Phase_Set_Input>;
  where: Tournaments_Phase_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Phase_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Phase_Inc_Input>;
  _set?: InputMaybe<Tournaments_Phase_Set_Input>;
  pk_columns: Tournaments_Phase_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Phase_ManyArgs = {
  updates: Array<Tournaments_Phase_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_PlaceArgs = {
  _inc?: InputMaybe<Tournaments_Place_Inc_Input>;
  _set?: InputMaybe<Tournaments_Place_Set_Input>;
  where: Tournaments_Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Place_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Place_Inc_Input>;
  _set?: InputMaybe<Tournaments_Place_Set_Input>;
  pk_columns: Tournaments_Place_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Place_ManyArgs = {
  updates: Array<Tournaments_Place_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Ranking_PointsArgs = {
  _inc?: InputMaybe<Tournaments_Ranking_Points_Inc_Input>;
  _set?: InputMaybe<Tournaments_Ranking_Points_Set_Input>;
  where: Tournaments_Ranking_Points_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Ranking_Points_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Ranking_Points_Inc_Input>;
  _set?: InputMaybe<Tournaments_Ranking_Points_Set_Input>;
  pk_columns: Tournaments_Ranking_Points_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Ranking_Points_ManyArgs = {
  updates: Array<Tournaments_Ranking_Points_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Ranking_RuleArgs = {
  _inc?: InputMaybe<Tournaments_Ranking_Rule_Inc_Input>;
  _set?: InputMaybe<Tournaments_Ranking_Rule_Set_Input>;
  where: Tournaments_Ranking_Rule_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Ranking_Rule_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Ranking_Rule_Inc_Input>;
  _set?: InputMaybe<Tournaments_Ranking_Rule_Set_Input>;
  pk_columns: Tournaments_Ranking_Rule_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Ranking_Rule_ManyArgs = {
  updates: Array<Tournaments_Ranking_Rule_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_TeamArgs = {
  _inc?: InputMaybe<Tournaments_Team_Inc_Input>;
  _set?: InputMaybe<Tournaments_Team_Set_Input>;
  where: Tournaments_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Team_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Team_Inc_Input>;
  _set?: InputMaybe<Tournaments_Team_Set_Input>;
  pk_columns: Tournaments_Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Team_ManyArgs = {
  updates: Array<Tournaments_Team_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_TournamentArgs = {
  _inc?: InputMaybe<Tournaments_Tournament_Inc_Input>;
  _set?: InputMaybe<Tournaments_Tournament_Set_Input>;
  where: Tournaments_Tournament_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Tournament_By_PkArgs = {
  _inc?: InputMaybe<Tournaments_Tournament_Inc_Input>;
  _set?: InputMaybe<Tournaments_Tournament_Set_Input>;
  pk_columns: Tournaments_Tournament_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tournaments_Tournament_ManyArgs = {
  updates: Array<Tournaments_Tournament_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Convocation_ProgressArgs = {
  _inc?: InputMaybe<Users_Convocation_Progress_Inc_Input>;
  _set?: InputMaybe<Users_Convocation_Progress_Set_Input>;
  where: Users_Convocation_Progress_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Convocation_Progress_By_PkArgs = {
  _inc?: InputMaybe<Users_Convocation_Progress_Inc_Input>;
  _set?: InputMaybe<Users_Convocation_Progress_Set_Input>;
  pk_columns: Users_Convocation_Progress_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Convocation_Progress_ManyArgs = {
  updates: Array<Users_Convocation_Progress_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_UserArgs = {
  _append?: InputMaybe<Users_User_Append_Input>;
  _delete_at_path?: InputMaybe<Users_User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_User_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_User_Prepend_Input>;
  _set?: InputMaybe<Users_User_Set_Input>;
  where: Users_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_User_By_PkArgs = {
  _append?: InputMaybe<Users_User_Append_Input>;
  _delete_at_path?: InputMaybe<Users_User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_User_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_User_Prepend_Input>;
  _set?: InputMaybe<Users_User_Set_Input>;
  pk_columns: Users_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_User_ManyArgs = {
  updates: Array<Users_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Club_SettingsArgs = {
  _set?: InputMaybe<Widgets_Club_Settings_Set_Input>;
  where: Widgets_Club_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Club_Settings_By_PkArgs = {
  _set?: InputMaybe<Widgets_Club_Settings_Set_Input>;
  pk_columns: Widgets_Club_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Club_Settings_ManyArgs = {
  updates: Array<Widgets_Club_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Convocation_SettingsArgs = {
  _append?: InputMaybe<Widgets_Convocation_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Widgets_Convocation_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Widgets_Convocation_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Widgets_Convocation_Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<Widgets_Convocation_Settings_Prepend_Input>;
  _set?: InputMaybe<Widgets_Convocation_Settings_Set_Input>;
  where: Widgets_Convocation_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Convocation_Settings_By_PkArgs = {
  _append?: InputMaybe<Widgets_Convocation_Settings_Append_Input>;
  _delete_at_path?: InputMaybe<Widgets_Convocation_Settings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Widgets_Convocation_Settings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Widgets_Convocation_Settings_Delete_Key_Input>;
  _prepend?: InputMaybe<Widgets_Convocation_Settings_Prepend_Input>;
  _set?: InputMaybe<Widgets_Convocation_Settings_Set_Input>;
  pk_columns: Widgets_Convocation_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Convocation_Settings_ManyArgs = {
  updates: Array<Widgets_Convocation_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_WidgetArgs = {
  _inc?: InputMaybe<Widgets_Widget_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Set_Input>;
  where: Widgets_Widget_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_By_PkArgs = {
  _inc?: InputMaybe<Widgets_Widget_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Set_Input>;
  pk_columns: Widgets_Widget_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Has_CompetitionArgs = {
  _inc?: InputMaybe<Widgets_Widget_Has_Competition_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Has_Competition_Set_Input>;
  where: Widgets_Widget_Has_Competition_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Has_Competition_By_PkArgs = {
  _inc?: InputMaybe<Widgets_Widget_Has_Competition_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Has_Competition_Set_Input>;
  pk_columns: Widgets_Widget_Has_Competition_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Has_Competition_ManyArgs = {
  updates: Array<Widgets_Widget_Has_Competition_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Has_TeamArgs = {
  _inc?: InputMaybe<Widgets_Widget_Has_Team_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Has_Team_Set_Input>;
  where: Widgets_Widget_Has_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Has_Team_By_PkArgs = {
  _inc?: InputMaybe<Widgets_Widget_Has_Team_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Has_Team_Set_Input>;
  pk_columns: Widgets_Widget_Has_Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Has_Team_ManyArgs = {
  updates: Array<Widgets_Widget_Has_Team_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_LiveArgs = {
  _inc?: InputMaybe<Widgets_Widget_Live_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Live_Set_Input>;
  where: Widgets_Widget_Live_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Live_By_PkArgs = {
  _inc?: InputMaybe<Widgets_Widget_Live_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Live_Set_Input>;
  pk_columns: Widgets_Widget_Live_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Live_ManyArgs = {
  updates: Array<Widgets_Widget_Live_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_ManyArgs = {
  updates: Array<Widgets_Widget_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_TeamArgs = {
  _inc?: InputMaybe<Widgets_Widget_Team_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Team_Set_Input>;
  where: Widgets_Widget_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Team_By_PkArgs = {
  _inc?: InputMaybe<Widgets_Widget_Team_Inc_Input>;
  _set?: InputMaybe<Widgets_Widget_Team_Set_Input>;
  pk_columns: Widgets_Widget_Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Widgets_Widget_Team_ManyArgs = {
  updates: Array<Widgets_Widget_Team_Updates>;
};

/** mutation root */
export type Mutation_RootUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

/** mutation root */
export type Mutation_RootUsers_Check_InvitationArgs = {
  code: Scalars['String']['input'];
  invitation_id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootUsers_Create_Club_AdminArgs = {
  club_id: Scalars['Int']['input'];
  role: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootUsers_Send_VerificationArgs = {
  phone_number: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootUsers_Verify_UserArgs = {
  code: Scalars['Int']['input'];
};

export type New_Phase_Response = {
  __typename: 'new_phase_response';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
};

export type New_Pool_Or_Cup_Response = {
  __typename: 'new_pool_or_cup_response';
  double?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nb_teams?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['Int']['output']>;
  ranking_games?: Maybe<Scalars['Boolean']['output']>;
};

export type News_Insert_Input = {
  image?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "news.news" */
export type News_News = {
  __typename: 'news_news';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  /** An array relationship */
  club_admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "news.news" */
export type News_NewsClub_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** aggregated selection of "news.news" */
export type News_News_Aggregate = {
  __typename: 'news_news_aggregate';
  aggregate?: Maybe<News_News_Aggregate_Fields>;
  nodes?: Maybe<Array<News_News>>;
};

/** aggregate fields of "news.news" */
export type News_News_Aggregate_Fields = {
  __typename: 'news_news_aggregate_fields';
  avg?: Maybe<News_News_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<News_News_Max_Fields>;
  min?: Maybe<News_News_Min_Fields>;
  stddev?: Maybe<News_News_Stddev_Fields>;
  stddev_pop?: Maybe<News_News_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<News_News_Stddev_Samp_Fields>;
  sum?: Maybe<News_News_Sum_Fields>;
  var_pop?: Maybe<News_News_Var_Pop_Fields>;
  var_samp?: Maybe<News_News_Var_Samp_Fields>;
  variance?: Maybe<News_News_Variance_Fields>;
};

/** aggregate fields of "news.news" */
export type News_News_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<News_News_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type News_News_Avg_Fields = {
  __typename: 'news_news_avg_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
};

/** Boolean expression to filter rows from the table "news.news". All fields are combined with a logical 'AND'. */
export type News_News_Bool_Exp = {
  _and?: InputMaybe<Array<News_News_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<News_News_Bool_Exp>;
  _or?: InputMaybe<Array<News_News_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "news.news" */
export enum News_News_Constraint {
  /** unique or primary key constraint on columns "id" */
  NewsPkey = 'news_pkey',
}

/** input type for incrementing numeric columns in table "news.news" */
export type News_News_Inc_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "news.news" */
export type News_News_Insert_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type News_News_Max_Fields = {
  __typename: 'news_news_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type News_News_Min_Fields = {
  __typename: 'news_news_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "news.news" */
export type News_News_Mutation_Response = {
  __typename: 'news_news_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<News_News>>;
};

/** on_conflict condition type for table "news.news" */
export type News_News_On_Conflict = {
  constraint: News_News_Constraint;
  update_columns?: Array<News_News_Update_Column>;
  where?: InputMaybe<News_News_Bool_Exp>;
};

/** Ordering options when selecting data from "news.news". */
export type News_News_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: news.news */
export type News_News_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "news.news" */
export enum News_News_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
}

/** input type for updating data in table "news.news" */
export type News_News_Set_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type News_News_Stddev_Fields = {
  __typename: 'news_news_stddev_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
};

/** aggregate stddev_pop on columns */
export type News_News_Stddev_Pop_Fields = {
  __typename: 'news_news_stddev_pop_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
};

/** aggregate stddev_samp on columns */
export type News_News_Stddev_Samp_Fields = {
  __typename: 'news_news_stddev_samp_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
};

/** Streaming cursor of the table "news_news" */
export type News_News_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: News_News_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type News_News_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type News_News_Sum_Fields = {
  __typename: 'news_news_sum_fields';
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
};

/** update columns of table "news.news" */
export enum News_News_Update_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
}

export type News_News_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<News_News_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<News_News_Set_Input>;
  /** filter the rows which have to be updated */
  where: News_News_Bool_Exp;
};

/** aggregate var_pop on columns */
export type News_News_Var_Pop_Fields = {
  __typename: 'news_news_var_pop_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
};

/** aggregate var_samp on columns */
export type News_News_Var_Samp_Fields = {
  __typename: 'news_news_var_samp_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
};

/** aggregate variance on columns */
export type News_News_Variance_Fields = {
  __typename: 'news_news_variance_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
};

/** Boolean expression to filter rows from the table "newsletters.contact". All fields are combined with a logical 'AND'. */
export type Newsletters_Contact_Bool_Exp = {
  _and?: InputMaybe<Array<Newsletters_Contact_Bool_Exp>>;
  _not?: InputMaybe<Newsletters_Contact_Bool_Exp>;
  _or?: InputMaybe<Array<Newsletters_Contact_Bool_Exp>>;
};

/** unique or primary key constraints on table "newsletters.contact" */
export enum Newsletters_Contact_Constraint {
  /** unique or primary key constraint on columns "email" */
  ContactEmailE78729a1Uniq = 'contact_email_e78729a1_uniq',
  /** unique or primary key constraint on columns "id" */
  ContactPkey = 'contact_pkey',
}

/** input type for inserting data into table "newsletters.contact" */
export type Newsletters_Contact_Insert_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "newsletters.contact" */
export type Newsletters_Contact_Mutation_Response = {
  __typename: 'newsletters_contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
};

/** on_conflict condition type for table "newsletters.contact" */
export type Newsletters_Contact_On_Conflict = {
  constraint: Newsletters_Contact_Constraint;
  update_columns?: Array<Newsletters_Contact_Update_Column>;
  where?: InputMaybe<Newsletters_Contact_Bool_Exp>;
};

/** placeholder for update columns of table "newsletters.contact" (current role has no relevant permissions) */
export enum Newsletters_Contact_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "notifications.endpoint" */
export type Notifications_Endpoint = {
  __typename: 'notifications_endpoint';
  device_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  registration_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting array relation for remote table "notifications.endpoint" */
export type Notifications_Endpoint_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Endpoint_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_Endpoint_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notifications.endpoint". All fields are combined with a logical 'AND'. */
export type Notifications_Endpoint_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Endpoint_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Endpoint_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Endpoint_Bool_Exp>>;
  device_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  registration_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications.endpoint" */
export enum Notifications_Endpoint_Constraint {
  /** unique or primary key constraint on columns "id" */
  EndpointPkey = 'endpoint_pkey',
  /** unique or primary key constraint on columns "user_id", "type", "device_id" */
  EndpointUserIdTypeDeviceIdE7f874bbUniq = 'endpoint_user_id_type_device_id_e7f874bb_uniq',
}

/** input type for inserting data into table "notifications.endpoint" */
export type Notifications_Endpoint_Insert_Input = {
  device_id?: InputMaybe<Scalars['String']['input']>;
  registration_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Notifications_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "notifications.endpoint" */
export type Notifications_Endpoint_Mutation_Response = {
  __typename: 'notifications_endpoint_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Notifications_Endpoint>>;
};

/** on_conflict condition type for table "notifications.endpoint" */
export type Notifications_Endpoint_On_Conflict = {
  constraint: Notifications_Endpoint_Constraint;
  update_columns?: Array<Notifications_Endpoint_Update_Column>;
  where?: InputMaybe<Notifications_Endpoint_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications.endpoint". */
export type Notifications_Endpoint_Order_By = {
  device_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  registration_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications.endpoint */
export type Notifications_Endpoint_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "notifications.endpoint" */
export enum Notifications_Endpoint_Select_Column {
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  Id = 'id',
  /** column name */
  RegistrationId = 'registration_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "notifications.endpoint" */
export type Notifications_Endpoint_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  device_id?: InputMaybe<Scalars['String']['input']>;
  registration_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notifications_endpoint" */
export type Notifications_Endpoint_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Endpoint_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Endpoint_Stream_Cursor_Value_Input = {
  device_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  registration_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "notifications.endpoint" */
export enum Notifications_Endpoint_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
  /** column name */
  Updated = '_updated',
  /** column name */
  DeviceId = 'device_id',
  /** column name */
  RegistrationId = 'registration_id',
  /** column name */
  Type = 'type',
}

export type Notifications_Endpoint_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Endpoint_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Endpoint_Bool_Exp;
};

/** Boolean expression to filter rows from the table "notifications.user". All fields are combined with a logical 'AND'. */
export type Notifications_User_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_User_Bool_Exp>>;
  _not?: InputMaybe<Notifications_User_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_User_Bool_Exp>>;
};

/** unique or primary key constraints on table "notifications.user" */
export enum Notifications_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
}

/** input type for inserting data into table "notifications.user" */
export type Notifications_User_Insert_Input = {
  endpoints?: InputMaybe<Notifications_Endpoint_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "notifications.user" */
export type Notifications_User_Mutation_Response = {
  __typename: 'notifications_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
};

/** input type for inserting object relation for remote table "notifications.user" */
export type Notifications_User_Obj_Rel_Insert_Input = {
  data: Notifications_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_User_On_Conflict>;
};

/** on_conflict condition type for table "notifications.user" */
export type Notifications_User_On_Conflict = {
  constraint: Notifications_User_Constraint;
  update_columns?: Array<Notifications_User_Update_Column>;
  where?: InputMaybe<Notifications_User_Bool_Exp>;
};

/** input type for updating data in table "notifications.user" */
export type Notifications_User_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "notifications.user" */
export enum Notifications_User_Update_Column {
  /** column name */
  Updated = '_updated',
}

export type Notifications_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_User_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type Permission = {
  __typename: 'permission';
  add?: Maybe<Scalars['String']['output']>;
  delete?: Maybe<Scalars['String']['output']>;
  edit?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  read?: Maybe<Scalars['String']['output']>;
};

export type Permission_Categories = {
  __typename: 'permission_categories';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Permission>>;
};

/** columns and relationships of "permissions.member_permission" */
export type Permissions_Member_Permission = {
  __typename: 'permissions_member_permission';
  add?: Maybe<Scalars['Boolean']['output']>;
  codename?: Maybe<Scalars['String']['output']>;
  delete?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  read?: Maybe<Scalars['Boolean']['output']>;
};

/** order by aggregate values of table "permissions.member_permission" */
export type Permissions_Member_Permission_Aggregate_Order_By = {
  avg?: InputMaybe<Permissions_Member_Permission_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Permissions_Member_Permission_Max_Order_By>;
  min?: InputMaybe<Permissions_Member_Permission_Min_Order_By>;
  stddev?: InputMaybe<Permissions_Member_Permission_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Permissions_Member_Permission_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Permissions_Member_Permission_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Permissions_Member_Permission_Sum_Order_By>;
  var_pop?: InputMaybe<Permissions_Member_Permission_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Permissions_Member_Permission_Var_Samp_Order_By>;
  variance?: InputMaybe<Permissions_Member_Permission_Variance_Order_By>;
};

/** order by avg() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "permissions.member_permission". All fields are combined with a logical 'AND'. */
export type Permissions_Member_Permission_Bool_Exp = {
  _and?: InputMaybe<Array<Permissions_Member_Permission_Bool_Exp>>;
  _not?: InputMaybe<Permissions_Member_Permission_Bool_Exp>;
  _or?: InputMaybe<Array<Permissions_Member_Permission_Bool_Exp>>;
  add?: InputMaybe<Boolean_Comparison_Exp>;
  codename?: InputMaybe<String_Comparison_Exp>;
  delete?: InputMaybe<Boolean_Comparison_Exp>;
  edit?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  read?: InputMaybe<Boolean_Comparison_Exp>;
};

/** order by max() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Max_Order_By = {
  codename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Min_Order_By = {
  codename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "permissions.member_permission". */
export type Permissions_Member_Permission_Order_By = {
  add?: InputMaybe<Order_By>;
  codename?: InputMaybe<Order_By>;
  delete?: InputMaybe<Order_By>;
  edit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  read?: InputMaybe<Order_By>;
};

/** select columns of table "permissions.member_permission" */
export enum Permissions_Member_Permission_Select_Column {
  /** column name */
  Add = 'add',
  /** column name */
  Codename = 'codename',
  /** column name */
  Delete = 'delete',
  /** column name */
  Edit = 'edit',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
}

/** order by stddev() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "permissions_member_permission" */
export type Permissions_Member_Permission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Permissions_Member_Permission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Permissions_Member_Permission_Stream_Cursor_Value_Input = {
  add?: InputMaybe<Scalars['Boolean']['input']>;
  codename?: InputMaybe<Scalars['String']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  edit?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by sum() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "permissions.member_permission" */
export type Permissions_Member_Permission_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "permissions.member_role" */
export type Permissions_Member_Role = {
  __typename: 'permissions_member_role';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  role?: Maybe<Permissions_Role>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "permissions.member_role". All fields are combined with a logical 'AND'. */
export type Permissions_Member_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Permissions_Member_Role_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Permissions_Member_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Permissions_Member_Role_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  role?: InputMaybe<Permissions_Role_Bool_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "permissions.member_role" */
export enum Permissions_Member_Role_Constraint {
  /** unique or primary key constraint on columns "member_id", "role_id" */
  MemberRoleMemberIdRoleIdEfbda8feUniq = 'member_role_member_id_role_id_efbda8fe_uniq',
  /** unique or primary key constraint on columns "id" */
  MemberRolePkey = 'member_role_pkey',
}

/** input type for inserting data into table "permissions.member_role" */
export type Permissions_Member_Role_Insert_Input = {
  member?: InputMaybe<Members_Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<Permissions_Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "permissions.member_role" */
export type Permissions_Member_Role_Mutation_Response = {
  __typename: 'permissions_member_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Permissions_Member_Role>>;
};

/** on_conflict condition type for table "permissions.member_role" */
export type Permissions_Member_Role_On_Conflict = {
  constraint: Permissions_Member_Role_Constraint;
  update_columns?: Array<Permissions_Member_Role_Update_Column>;
  where?: InputMaybe<Permissions_Member_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "permissions.member_role". */
export type Permissions_Member_Role_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Permissions_Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** select columns of table "permissions.member_role" */
export enum Permissions_Member_Role_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  RoleId = 'role_id',
}

/** Streaming cursor of the table "permissions_member_role" */
export type Permissions_Member_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Permissions_Member_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Permissions_Member_Role_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** placeholder for update columns of table "permissions.member_role" (current role has no relevant permissions) */
export enum Permissions_Member_Role_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "permissions.role" */
export type Permissions_Role = {
  __typename: 'permissions_role';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  club_admins?: Maybe<Clubs_Club_Admin>;
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "permissions.role". All fields are combined with a logical 'AND'. */
export type Permissions_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Permissions_Role_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Permissions_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Permissions_Role_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "permissions.role" */
export enum Permissions_Role_Constraint {
  /** unique or primary key constraint on columns "name", "club_id" */
  RoleNameClubId_65c8d5ecUniq = 'role_name_club_id_65c8d5ec_uniq',
  /** unique or primary key constraint on columns "id" */
  RolePkey = 'role_pkey',
}

/** input type for inserting data into table "permissions.role" */
export type Permissions_Role_Insert_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "permissions.role" */
export type Permissions_Role_Mutation_Response = {
  __typename: 'permissions_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Permissions_Role>>;
};

/** input type for inserting object relation for remote table "permissions.role" */
export type Permissions_Role_Obj_Rel_Insert_Input = {
  data: Permissions_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Permissions_Role_On_Conflict>;
};

/** on_conflict condition type for table "permissions.role" */
export type Permissions_Role_On_Conflict = {
  constraint: Permissions_Role_Constraint;
  update_columns?: Array<Permissions_Role_Update_Column>;
  where?: InputMaybe<Permissions_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "permissions.role". */
export type Permissions_Role_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** columns and relationships of "permissions.role_permission" */
export type Permissions_Role_Permission = {
  __typename: 'permissions_role_permission';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  add?: Maybe<Scalars['Boolean']['output']>;
  delete?: Maybe<Scalars['Boolean']['output']>;
  edit?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  permission_id?: Maybe<Scalars['Int']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  role?: Maybe<Permissions_Role>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "permissions.role_permission". All fields are combined with a logical 'AND'. */
export type Permissions_Role_Permission_Bool_Exp = {
  _and?: InputMaybe<Array<Permissions_Role_Permission_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Permissions_Role_Permission_Bool_Exp>;
  _or?: InputMaybe<Array<Permissions_Role_Permission_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  add?: InputMaybe<Boolean_Comparison_Exp>;
  delete?: InputMaybe<Boolean_Comparison_Exp>;
  edit?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  permission_id?: InputMaybe<Int_Comparison_Exp>;
  read?: InputMaybe<Boolean_Comparison_Exp>;
  role?: InputMaybe<Permissions_Role_Bool_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "permissions.role_permission" */
export enum Permissions_Role_Permission_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolePermissionPkey = 'role_permission_pkey',
  /** unique or primary key constraint on columns "permission_id", "role_id" */
  RolePermissionRoleIdPermissionId_0a48a767Uniq = 'role_permission_role_id_permission_id_0a48a767_uniq',
}

/** input type for inserting data into table "permissions.role_permission" */
export type Permissions_Role_Permission_Insert_Input = {
  add?: InputMaybe<Scalars['Boolean']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  edit?: InputMaybe<Scalars['Boolean']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Permissions_Role_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "permissions.role_permission" */
export type Permissions_Role_Permission_Mutation_Response = {
  __typename: 'permissions_role_permission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Permissions_Role_Permission>>;
};

/** on_conflict condition type for table "permissions.role_permission" */
export type Permissions_Role_Permission_On_Conflict = {
  constraint: Permissions_Role_Permission_Constraint;
  update_columns?: Array<Permissions_Role_Permission_Update_Column>;
  where?: InputMaybe<Permissions_Role_Permission_Bool_Exp>;
};

/** Ordering options when selecting data from "permissions.role_permission". */
export type Permissions_Role_Permission_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  add?: InputMaybe<Order_By>;
  delete?: InputMaybe<Order_By>;
  edit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  read?: InputMaybe<Order_By>;
  role?: InputMaybe<Permissions_Role_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: permissions.role_permission */
export type Permissions_Role_Permission_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "permissions.role_permission" */
export enum Permissions_Role_Permission_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Add = 'add',
  /** column name */
  Delete = 'delete',
  /** column name */
  Edit = 'edit',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  Read = 'read',
  /** column name */
  RoleId = 'role_id',
}

/** input type for updating data in table "permissions.role_permission" */
export type Permissions_Role_Permission_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  add?: InputMaybe<Scalars['Boolean']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  edit?: InputMaybe<Scalars['Boolean']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "permissions_role_permission" */
export type Permissions_Role_Permission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Permissions_Role_Permission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Permissions_Role_Permission_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  add?: InputMaybe<Scalars['Boolean']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  edit?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "permissions.role_permission" */
export enum Permissions_Role_Permission_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  Add = 'add',
  /** column name */
  Delete = 'delete',
  /** column name */
  Edit = 'edit',
  /** column name */
  Read = 'read',
}

export type Permissions_Role_Permission_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Permissions_Role_Permission_Set_Input>;
  /** filter the rows which have to be updated */
  where: Permissions_Role_Permission_Bool_Exp;
};

/** primary key columns input for table: permissions.role */
export type Permissions_Role_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "permissions.role" */
export enum Permissions_Role_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "permissions.role" */
export type Permissions_Role_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "permissions_role" */
export type Permissions_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Permissions_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Permissions_Role_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "permissions.role" */
export enum Permissions_Role_Update_Column {
  /** column name */
  Name = 'name',
}

export type Permissions_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Permissions_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Permissions_Role_Bool_Exp;
};

/** columns and relationships of "places.city" */
export type Places_City = {
  __typename: 'places_city';
  /** An object relationship */
  department?: Maybe<Places_Department>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "places.city". All fields are combined with a logical 'AND'. */
export type Places_City_Bool_Exp = {
  _and?: InputMaybe<Array<Places_City_Bool_Exp>>;
  _not?: InputMaybe<Places_City_Bool_Exp>;
  _or?: InputMaybe<Array<Places_City_Bool_Exp>>;
  department?: InputMaybe<Places_Department_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "places.city" */
export enum Places_City_Constraint {
  /** unique or primary key constraint on columns "department_id", "normalized" */
  CityNormalizedDepartmentId_365a9d1fUniq = 'city_normalized_department_id_365a9d1f_uniq',
  /** unique or primary key constraint on columns "id" */
  PlacesCityPkey = 'places_city_pkey',
}

/** input type for inserting data into table "places.city" */
export type Places_City_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "places.city" */
export type Places_City_Mutation_Response = {
  __typename: 'places_city_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Places_City>>;
};

/** input type for inserting object relation for remote table "places.city" */
export type Places_City_Obj_Rel_Insert_Input = {
  data: Places_City_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Places_City_On_Conflict>;
};

/** on_conflict condition type for table "places.city" */
export type Places_City_On_Conflict = {
  constraint: Places_City_Constraint;
  update_columns?: Array<Places_City_Update_Column>;
  where?: InputMaybe<Places_City_Bool_Exp>;
};

/** Ordering options when selecting data from "places.city". */
export type Places_City_Order_By = {
  department?: InputMaybe<Places_Department_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "places.city" */
export enum Places_City_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "places_city" */
export type Places_City_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Places_City_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Places_City_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "places.city" (current role has no relevant permissions) */
export enum Places_City_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "places.country" */
export type Places_Country = {
  __typename: 'places_country';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "places.country". All fields are combined with a logical 'AND'. */
export type Places_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Places_Country_Bool_Exp>>;
  _not?: InputMaybe<Places_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Places_Country_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "places.country". */
export type Places_Country_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "places.country" */
export enum Places_Country_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "places_country" */
export type Places_Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Places_Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Places_Country_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "places.department" */
export type Places_Department = {
  __typename: 'places_department';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  region?: Maybe<Places_Region>;
  region_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "places.department" */
export type Places_Department_Aggregate_Order_By = {
  avg?: InputMaybe<Places_Department_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Places_Department_Max_Order_By>;
  min?: InputMaybe<Places_Department_Min_Order_By>;
  stddev?: InputMaybe<Places_Department_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Places_Department_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Places_Department_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Places_Department_Sum_Order_By>;
  var_pop?: InputMaybe<Places_Department_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Places_Department_Var_Samp_Order_By>;
  variance?: InputMaybe<Places_Department_Variance_Order_By>;
};

/** order by avg() on columns of table "places.department" */
export type Places_Department_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "places.department". All fields are combined with a logical 'AND'. */
export type Places_Department_Bool_Exp = {
  _and?: InputMaybe<Array<Places_Department_Bool_Exp>>;
  _not?: InputMaybe<Places_Department_Bool_Exp>;
  _or?: InputMaybe<Array<Places_Department_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<Places_Region_Bool_Exp>;
  region_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "places.department" */
export type Places_Department_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "places.department" */
export type Places_Department_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "places.department". */
export type Places_Department_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region?: InputMaybe<Places_Region_Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** select columns of table "places.department" */
export enum Places_Department_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'region_id',
}

/** order by stddev() on columns of table "places.department" */
export type Places_Department_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "places.department" */
export type Places_Department_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "places.department" */
export type Places_Department_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "places_department" */
export type Places_Department_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Places_Department_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Places_Department_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  region_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "places.department" */
export type Places_Department_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "places.department" */
export type Places_Department_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "places.department" */
export type Places_Department_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "places.department" */
export type Places_Department_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "places.equipment" */
export type Places_Equipment = {
  __typename: 'places_equipment';
  address?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  admins?: Maybe<Array<Clubs_Club_Admin>>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  club_id?: Maybe<Scalars['Int']['output']>;
  equipment_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  place?: Maybe<Places_Place>;
  place_id?: Maybe<Scalars['Int']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** columns and relationships of "places.equipment" */
export type Places_EquipmentAdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** order by aggregate values of table "places.equipment" */
export type Places_Equipment_Aggregate_Order_By = {
  avg?: InputMaybe<Places_Equipment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Places_Equipment_Max_Order_By>;
  min?: InputMaybe<Places_Equipment_Min_Order_By>;
  stddev?: InputMaybe<Places_Equipment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Places_Equipment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Places_Equipment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Places_Equipment_Sum_Order_By>;
  var_pop?: InputMaybe<Places_Equipment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Places_Equipment_Var_Samp_Order_By>;
  variance?: InputMaybe<Places_Equipment_Variance_Order_By>;
};

/** order by avg() on columns of table "places.equipment" */
export type Places_Equipment_Avg_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "places.equipment". All fields are combined with a logical 'AND'. */
export type Places_Equipment_Bool_Exp = {
  _and?: InputMaybe<Array<Places_Equipment_Bool_Exp>>;
  _not?: InputMaybe<Places_Equipment_Bool_Exp>;
  _or?: InputMaybe<Array<Places_Equipment_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  equipment_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  place?: InputMaybe<Places_Place_Bool_Exp>;
  place_id?: InputMaybe<Int_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "places.equipment" */
export enum Places_Equipment_Constraint {
  /** unique or primary key constraint on columns "address", "name", "club_id" */
  EquipmentClubIdAddressNameFb3d2ddaUniq = 'equipment_club_id_address_name_fb3d2dda_uniq',
  /** unique or primary key constraint on columns "id" */
  EquipmentPkey = 'equipment_pkey',
}

/** input type for incrementing numeric columns in table "places.equipment" */
export type Places_Equipment_Inc_Input = {
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "places.equipment" */
export type Places_Equipment_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  equipment_type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Places_Place_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "places.equipment" */
export type Places_Equipment_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  equipment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "places.equipment" */
export type Places_Equipment_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  equipment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "places.equipment" */
export type Places_Equipment_Mutation_Response = {
  __typename: 'places_equipment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Places_Equipment>>;
};

/** on_conflict condition type for table "places.equipment" */
export type Places_Equipment_On_Conflict = {
  constraint: Places_Equipment_Constraint;
  update_columns?: Array<Places_Equipment_Update_Column>;
  where?: InputMaybe<Places_Equipment_Bool_Exp>;
};

/** Ordering options when selecting data from "places.equipment". */
export type Places_Equipment_Order_By = {
  address?: InputMaybe<Order_By>;
  admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_id?: InputMaybe<Order_By>;
  equipment_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  place?: InputMaybe<Places_Place_Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** primary key columns input for table: places.equipment */
export type Places_Equipment_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "places.equipment" */
export enum Places_Equipment_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  EquipmentType = 'equipment_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlaceId = 'place_id',
  /** column name */
  Rank = 'rank',
}

/** input type for updating data in table "places.equipment" */
export type Places_Equipment_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  equipment_type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by stddev() on columns of table "places.equipment" */
export type Places_Equipment_Stddev_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "places.equipment" */
export type Places_Equipment_Stddev_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "places.equipment" */
export type Places_Equipment_Stddev_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "places_equipment" */
export type Places_Equipment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Places_Equipment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Places_Equipment_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  equipment_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  place_id?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by sum() on columns of table "places.equipment" */
export type Places_Equipment_Sum_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** update columns of table "places.equipment" */
export enum Places_Equipment_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  Address = 'address',
  /** column name */
  EquipmentType = 'equipment_type',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
}

export type Places_Equipment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Places_Equipment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Places_Equipment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Places_Equipment_Bool_Exp;
};

/** order by var_pop() on columns of table "places.equipment" */
export type Places_Equipment_Var_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "places.equipment" */
export type Places_Equipment_Var_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "places.equipment" */
export type Places_Equipment_Variance_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  place_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** columns and relationships of "places.place" */
export type Places_Place = {
  __typename: 'places_place';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  city?: Maybe<Places_City>;
  city_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  lat?: Maybe<Scalars['String']['output']>;
  lon?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  normalized?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "places.place". All fields are combined with a logical 'AND'. */
export type Places_Place_Bool_Exp = {
  _and?: InputMaybe<Array<Places_Place_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Places_Place_Bool_Exp>;
  _or?: InputMaybe<Array<Places_Place_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<Places_City_Bool_Exp>;
  city_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lat?: InputMaybe<String_Comparison_Exp>;
  lon?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  normalized?: InputMaybe<String_Comparison_Exp>;
  postal_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "places.place" */
export enum Places_Place_Constraint {
  /** unique or primary key constraint on columns "normalized" */
  PlacesPlaceNormalizedKey = 'places_place_normalized_key',
  /** unique or primary key constraint on columns "id" */
  PlacesPlacePkey = 'places_place_pkey',
}

/** input type for inserting data into table "places.place" */
export type Places_Place_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Places_City_Obj_Rel_Insert_Input>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  lat?: InputMaybe<Scalars['String']['input']>;
  lon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  normalized?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "places.place" */
export type Places_Place_Mutation_Response = {
  __typename: 'places_place_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Places_Place>>;
};

/** input type for inserting object relation for remote table "places.place" */
export type Places_Place_Obj_Rel_Insert_Input = {
  data: Places_Place_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Places_Place_On_Conflict>;
};

/** on_conflict condition type for table "places.place" */
export type Places_Place_On_Conflict = {
  constraint: Places_Place_Constraint;
  update_columns?: Array<Places_Place_Update_Column>;
  where?: InputMaybe<Places_Place_Bool_Exp>;
};

/** Ordering options when selecting data from "places.place". */
export type Places_Place_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Places_City_Order_By>;
  city_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lon?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  normalized?: InputMaybe<Order_By>;
  postal_code?: InputMaybe<Order_By>;
};

/** select columns of table "places.place" */
export enum Places_Place_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Address = 'address',
  /** column name */
  CityId = 'city_id',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lon = 'lon',
  /** column name */
  Name = 'name',
  /** column name */
  Normalized = 'normalized',
  /** column name */
  PostalCode = 'postal_code',
}

/** Streaming cursor of the table "places_place" */
export type Places_Place_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Places_Place_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Places_Place_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  city_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lat?: InputMaybe<Scalars['String']['input']>;
  lon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  normalized?: InputMaybe<Scalars['String']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
};

/** placeholder for update columns of table "places.place" (current role has no relevant permissions) */
export enum Places_Place_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "places.region" */
export type Places_Region = {
  __typename: 'places_region';
  /** An object relationship */
  country?: Maybe<Places_Country>;
  country_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  departments?: Maybe<Array<Places_Department>>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  normalized?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "places.region" */
export type Places_RegionDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<Places_Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Department_Order_By>>;
  where?: InputMaybe<Places_Department_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "places.region". All fields are combined with a logical 'AND'. */
export type Places_Region_Bool_Exp = {
  _and?: InputMaybe<Array<Places_Region_Bool_Exp>>;
  _not?: InputMaybe<Places_Region_Bool_Exp>;
  _or?: InputMaybe<Array<Places_Region_Bool_Exp>>;
  country?: InputMaybe<Places_Country_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  departments?: InputMaybe<Places_Department_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  normalized?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "places.region". */
export type Places_Region_Order_By = {
  country?: InputMaybe<Places_Country_Order_By>;
  country_id?: InputMaybe<Order_By>;
  departments_aggregate?: InputMaybe<Places_Department_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  normalized?: InputMaybe<Order_By>;
};

/** select columns of table "places.region" */
export enum Places_Region_Select_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Normalized = 'normalized',
}

/** Streaming cursor of the table "places_region" */
export type Places_Region_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Places_Region_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Places_Region_Stream_Cursor_Value_Input = {
  country_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  normalized?: InputMaybe<Scalars['String']['input']>;
};

export type Query_Root = {
  __typename: 'query_root';
  /** fetch data from the table: "apps.version" */
  apps_version?: Maybe<Array<Apps_Version>>;
  /** fetch data from the table: "apps.version" using primary key columns */
  apps_version_by_pk?: Maybe<Apps_Version>;
  /** fetch data from the table: "billing.club_customer" */
  billing_club_customer?: Maybe<Array<Billing_Club_Customer>>;
  /** fetch data from the table: "billing.club_customer" using primary key columns */
  billing_club_customer_by_pk?: Maybe<Billing_Club_Customer>;
  /** fetch data from the table: "billing.customer" */
  billing_customer?: Maybe<Array<Billing_Customer>>;
  /** fetch data from the table: "billing.customer" using primary key columns */
  billing_customer_by_pk?: Maybe<Billing_Customer>;
  /** Récupère les clients d'un club */
  billing_get_club_customers?: Maybe<Customer_Response>;
  /** Récupère les devis d'un client */
  billing_get_customer_estimates?: Maybe<Array<Estimates_Response>>;
  /** Récupère les factures d'un client */
  billing_get_customer_invoices?: Maybe<Array<Invoices_Response>>;
  /** fetch data from the table: "clubhouse.club_house" */
  clubhouse_club_house?: Maybe<Array<Clubhouse_Club_House>>;
  /** fetch data from the table: "clubhouse.club_house" using primary key columns */
  clubhouse_club_house_by_pk?: Maybe<Clubhouse_Club_House>;
  /** fetch data from the table: "clubhouse.screen" */
  clubhouse_screen?: Maybe<Array<Clubhouse_Screen>>;
  /** fetch aggregated fields from the table: "clubhouse.screen" */
  clubhouse_screen_aggregate?: Maybe<Clubhouse_Screen_Aggregate>;
  /** fetch data from the table: "clubhouse.screen" using primary key columns */
  clubhouse_screen_by_pk?: Maybe<Clubhouse_Screen>;
  /** fetch data from the table: "clubhouse.template" */
  clubhouse_template?: Maybe<Array<Clubhouse_Template>>;
  /** fetch data from the table: "clubhouse.template" using primary key columns */
  clubhouse_template_by_pk?: Maybe<Clubhouse_Template>;
  /** fetch data from the table: "clubs.admin" */
  clubs_admin?: Maybe<Array<Clubs_Admin>>;
  /** fetch data from the table: "clubs.admin" using primary key columns */
  clubs_admin_by_pk?: Maybe<Clubs_Admin>;
  /** fetch data from the table: "clubs.club" */
  clubs_club?: Maybe<Array<Clubs_Club>>;
  /** fetch data from the table: "clubs.club_admin" */
  clubs_club_admin?: Maybe<Array<Clubs_Club_Admin>>;
  /** fetch data from the table: "clubs.club_admin" using primary key columns */
  clubs_club_admin_by_pk?: Maybe<Clubs_Club_Admin>;
  /** fetch aggregated fields from the table: "clubs.club" */
  clubs_club_aggregate?: Maybe<Clubs_Club_Aggregate>;
  /** fetch data from the table: "clubs.club" using primary key columns */
  clubs_club_by_pk?: Maybe<Clubs_Club>;
  /** fetch data from the table: "clubs.club_detail" */
  clubs_club_detail?: Maybe<Array<Clubs_Club_Detail>>;
  /** fetch data from the table: "clubs.club_detail" using primary key columns */
  clubs_club_detail_by_pk?: Maybe<Clubs_Club_Detail>;
  /** execute function "clubs.get_club" which returns "clubs.club_detail" */
  clubs_get_club?: Maybe<Array<Clubs_Club_Detail>>;
  /** fetch data from the table: "clubs.organization" */
  clubs_organization?: Maybe<Array<Clubs_Organization>>;
  /** fetch data from the table: "clubs.organization" using primary key columns */
  clubs_organization_by_pk?: Maybe<Clubs_Organization>;
  /** fetch data from the table: "clubs.planner_settings" */
  clubs_planner_settings?: Maybe<Array<Clubs_Planner_Settings>>;
  /** fetch data from the table: "clubs.planner_settings" using primary key columns */
  clubs_planner_settings_by_pk?: Maybe<Clubs_Planner_Settings>;
  /** Récupère la liste des championnats pour lesquels ce club a des problèmes de synchronisation avec la fédé */
  clubs_scraping_championships_with_exceptions?: Maybe<
    Array<Championship_Response>
  >;
  /** Récupère la liste des compétitions pas encore synchronisées depuis le scraping pour les ligues associées à un club */
  clubs_scraping_competitions_not_synchronized?: Maybe<Array<League_Response>>;
  /** Permet de récupérer la liste des compétitions qui sont déjà récupérées dans le scraping mais pas encore synchronisées avec le backend */
  clubs_scraping_missing_competitions?: Maybe<Array<Championship_Response>>;
  /** fetch data from the table: "clubs.season" */
  clubs_season?: Maybe<Array<Clubs_Season>>;
  /** fetch data from the table: "clubs.season" using primary key columns */
  clubs_season_by_pk?: Maybe<Clubs_Season>;
  /** fetch data from the table: "clubs.sport" */
  clubs_sport?: Maybe<Array<Clubs_Sport>>;
  /** fetch data from the table: "clubs.sport" using primary key columns */
  clubs_sport_by_pk?: Maybe<Clubs_Sport>;
  competitions_age_categories?: Maybe<
    Array<Competitions_Age_Category_Response>
  >;
  /** fetch data from the table: "competitions.closest_event_with_same_teams" */
  competitions_closest_event_with_same_teams?: Maybe<
    Array<Competitions_Closest_Event_With_Same_Teams>
  >;
  /** fetch data from the table: "competitions.competition" */
  competitions_competition?: Maybe<Array<Competitions_Competition>>;
  /** fetch data from the table: "competitions.competition" using primary key columns */
  competitions_competition_by_pk?: Maybe<Competitions_Competition>;
  /** fetch data from the table: "competitions.competition_detail" */
  competitions_competition_detail?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** execute function "competitions.competition_detail_by_id" which returns "competitions.competition_detail" */
  competitions_competition_detail_by_id?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** execute function "competitions.competition_detail_by_level_for_season_id" which returns "competitions.competition_detail" */
  competitions_competition_detail_by_level_for_season_id?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** execute function "competitions.competition_detail_by_level_id" which returns "competitions.competition_detail" */
  competitions_competition_detail_by_level_id?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** fetch data from the table: "competitions.competition_detail" using primary key columns */
  competitions_competition_detail_by_pk?: Maybe<Competitions_Competition_Detail>;
  /** fetch data from the table: "competitions.competition_has_fans" */
  competitions_competition_has_fans?: Maybe<
    Array<Competitions_Competition_Has_Fans>
  >;
  /** fetch data from the table: "competitions.competition_has_fans" using primary key columns */
  competitions_competition_has_fans_by_pk?: Maybe<Competitions_Competition_Has_Fans>;
  /** fetch data from the table: "competitions.competition_has_teams" */
  competitions_competition_has_teams?: Maybe<
    Array<Competitions_Competition_Has_Teams>
  >;
  /** fetch data from the table: "competitions.competition_has_teams" using primary key columns */
  competitions_competition_has_teams_by_pk?: Maybe<Competitions_Competition_Has_Teams>;
  /** fetch data from the table: "competitions.competition_statistics" */
  competitions_competition_statistics?: Maybe<
    Array<Competitions_Competition_Statistics>
  >;
  /** fetch data from the table: "competitions.event" */
  competitions_event?: Maybe<Array<Competitions_Event>>;
  /** fetch aggregated fields from the table: "competitions.event" */
  competitions_event_aggregate?: Maybe<Competitions_Event_Aggregate>;
  /** fetch data from the table: "competitions.event" using primary key columns */
  competitions_event_by_pk?: Maybe<Competitions_Event>;
  /** fetch data from the table: "competitions.event_closest_date" */
  competitions_event_closest_date?: Maybe<
    Array<Competitions_Event_Closest_Date>
  >;
  /** fetch data from the table: "competitions.event_detail" */
  competitions_event_detail?: Maybe<Array<Competitions_Event_Detail>>;
  /** fetch aggregated fields from the table: "competitions.event_detail" */
  competitions_event_detail_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_club_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_club_id?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_club_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_club_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_club_id_between_dates" which returns "competitions.event_detail" */
  competitions_event_detail_by_club_id_between_dates?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_club_id_between_dates" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_club_id_between_dates_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_club_id_between_dates_order_by_rank" which returns "competitions.event_detail" */
  competitions_event_detail_by_club_id_between_dates_order_by_rank?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_club_id_between_dates_order_by_rank" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_club_id_between_dates_order_by_rank_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_id?: Maybe<Array<Competitions_Event_Detail>>;
  /** execute function "competitions.event_detail_by_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_live_rating" which returns "competitions.event_detail" */
  competitions_event_detail_by_live_rating?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_live_rating" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_live_rating_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_live_rating_and_sport" which returns "competitions.event_detail" */
  competitions_event_detail_by_live_rating_and_sport?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_live_rating_and_sport" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_live_rating_and_sport_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** fetch data from the table: "competitions.event_detail" using primary key columns */
  competitions_event_detail_by_pk?: Maybe<Competitions_Event_Detail>;
  /** execute function "competitions.event_detail_by_round_day_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_round_day_id?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_round_day_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_round_day_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_team_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_team_id?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_team_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_team_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_team_in_season_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_team_in_season_id?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_team_in_season_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_team_in_season_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_user_favorite_teams" which returns "competitions.event_detail" */
  competitions_event_detail_by_user_favorite_teams?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_user_favorite_teams" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_user_favorite_teams_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** fetch data from the table: "competitions.event_last_update" */
  competitions_event_last_update?: Maybe<Array<Competitions_Event_Last_Update>>;
  /** fetch data from the table: "competitions.event_referee" */
  competitions_event_referee?: Maybe<Array<Competitions_Event_Referee>>;
  /** fetch data from the table: "competitions.event_referee" using primary key columns */
  competitions_event_referee_by_pk?: Maybe<Competitions_Event_Referee>;
  /** fetch data from the table: "competitions.event_with_same_teams" */
  competitions_event_with_same_teams?: Maybe<
    Array<Competitions_Event_With_Same_Teams>
  >;
  /** fetch data from the table: "competitions.event_with_same_teams" using primary key columns */
  competitions_event_with_same_teams_by_pk?: Maybe<Competitions_Event_With_Same_Teams>;
  /** fetch data from the table: "competitions.fan" */
  competitions_fan?: Maybe<Array<Competitions_Fan>>;
  /** fetch data from the table: "competitions.fan" using primary key columns */
  competitions_fan_by_pk?: Maybe<Competitions_Fan>;
  competitions_genders?: Maybe<Array<Competitions_Gender_Response>>;
  /** Récupère les liens permettant d'ajouter cet évènement à son calendrier */
  competitions_get_event_calendar?: Maybe<Calendar_Links_Response>;
  /** fetch data from the table: "competitions.league" */
  competitions_league?: Maybe<Array<Competitions_League>>;
  /** fetch data from the table: "competitions.league" using primary key columns */
  competitions_league_by_pk?: Maybe<Competitions_League>;
  /** fetch data from the table: "competitions.league_detail" */
  competitions_league_detail?: Maybe<Array<Competitions_League_Detail>>;
  /** execute function "competitions.league_detail_by_id" which returns "competitions.league_detail" */
  competitions_league_detail_by_id?: Maybe<Array<Competitions_League_Detail>>;
  /** fetch data from the table: "competitions.league_detail" using primary key columns */
  competitions_league_detail_by_pk?: Maybe<Competitions_League_Detail>;
  /** execute function "competitions.league_detail_by_sport_slug" which returns "competitions.league_detail" */
  competitions_league_detail_by_sport_slug?: Maybe<
    Array<Competitions_League_Detail>
  >;
  /** fetch data from the table: "competitions.level" */
  competitions_level?: Maybe<Array<Competitions_Level>>;
  /** fetch data from the table: "competitions.level" using primary key columns */
  competitions_level_by_pk?: Maybe<Competitions_Level>;
  /** fetch data from the table: "competitions.level_for_season" */
  competitions_level_for_season?: Maybe<Array<Competitions_Level_For_Season>>;
  /** fetch data from the table: "competitions.level_for_season" using primary key columns */
  competitions_level_for_season_by_pk?: Maybe<Competitions_Level_For_Season>;
  /** fetch data from the table: "competitions.level_for_season_detail" */
  competitions_level_for_season_detail?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** execute function "competitions.level_for_season_detail_by_id" which returns "competitions.level_for_season_detail" */
  competitions_level_for_season_detail_by_id?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** execute function "competitions.level_for_season_detail_by_league_id" which returns "competitions.level_for_season_detail" */
  competitions_level_for_season_detail_by_league_id?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** execute function "competitions.level_for_season_detail_by_level_and_season" which returns "competitions.level_for_season_detail" */
  competitions_level_for_season_detail_by_level_and_season?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** fetch data from the table: "competitions.level_for_season_detail" using primary key columns */
  competitions_level_for_season_detail_by_pk?: Maybe<Competitions_Level_For_Season_Detail>;
  /** fetch data from the table: "competitions.playing_team" */
  competitions_playing_team?: Maybe<Array<Competitions_Playing_Team>>;
  /** fetch aggregated fields from the table: "competitions.playing_team" */
  competitions_playing_team_aggregate?: Maybe<Competitions_Playing_Team_Aggregate>;
  /** fetch data from the table: "competitions.playing_team_bar_manager" */
  competitions_playing_team_bar_manager?: Maybe<
    Array<Competitions_Playing_Team_Bar_Manager>
  >;
  /** fetch data from the table: "competitions.playing_team_bar_manager" using primary key columns */
  competitions_playing_team_bar_manager_by_pk?: Maybe<Competitions_Playing_Team_Bar_Manager>;
  /** fetch data from the table: "competitions.playing_team" using primary key columns */
  competitions_playing_team_by_pk?: Maybe<Competitions_Playing_Team>;
  /** fetch data from the table: "competitions.playing_team_info" */
  competitions_playing_team_info?: Maybe<Array<Competitions_Playing_Team_Info>>;
  /** fetch aggregated fields from the table: "competitions.playing_team_info" */
  competitions_playing_team_info_aggregate?: Maybe<Competitions_Playing_Team_Info_Aggregate>;
  /** fetch data from the table: "competitions.playing_team_info" using primary key columns */
  competitions_playing_team_info_by_pk?: Maybe<Competitions_Playing_Team_Info>;
  /** fetch data from the table: "competitions.playing_team_referee" */
  competitions_playing_team_referee?: Maybe<
    Array<Competitions_Playing_Team_Referee>
  >;
  /** fetch data from the table: "competitions.playing_team_referee" using primary key columns */
  competitions_playing_team_referee_by_pk?: Maybe<Competitions_Playing_Team_Referee>;
  /** fetch data from the table: "competitions.playing_team_scoring_table" */
  competitions_playing_team_scoring_table?: Maybe<
    Array<Competitions_Playing_Team_Scoring_Table>
  >;
  /** fetch data from the table: "competitions.playing_team_scoring_table" using primary key columns */
  competitions_playing_team_scoring_table_by_pk?: Maybe<Competitions_Playing_Team_Scoring_Table>;
  /** fetch data from the table: "competitions.playing_team_stadium_manager" */
  competitions_playing_team_stadium_manager?: Maybe<
    Array<Competitions_Playing_Team_Stadium_Manager>
  >;
  /** fetch data from the table: "competitions.playing_team_stadium_manager" using primary key columns */
  competitions_playing_team_stadium_manager_by_pk?: Maybe<Competitions_Playing_Team_Stadium_Manager>;
  /** fetch data from the table: "competitions.ranking" */
  competitions_ranking?: Maybe<Array<Competitions_Ranking>>;
  /** fetch data from the table: "competitions.ranking" using primary key columns */
  competitions_ranking_by_pk?: Maybe<Competitions_Ranking>;
  /** fetch data from the table: "competitions.ranking_detail" */
  competitions_ranking_detail?: Maybe<Array<Competitions_Ranking_Detail>>;
  /** fetch data from the table: "competitions.ranking_detail" using primary key columns */
  competitions_ranking_detail_by_pk?: Maybe<Competitions_Ranking_Detail>;
  /** fetch data from the table: "competitions.round" */
  competitions_round?: Maybe<Array<Competitions_Round>>;
  /** fetch aggregated fields from the table: "competitions.round" */
  competitions_round_aggregate?: Maybe<Competitions_Round_Aggregate>;
  /** fetch data from the table: "competitions.round" using primary key columns */
  competitions_round_by_pk?: Maybe<Competitions_Round>;
  configuration?: Maybe<ConfigurationEntityResponse>;
  /** fetch data from the table: "emails.event" */
  emails_event?: Maybe<Array<Emails_Event>>;
  /** fetch data from the table: "emails.event" using primary key columns */
  emails_event_by_pk?: Maybe<Emails_Event>;
  /** fetch data from the table: "emails.message" */
  emails_message?: Maybe<Array<Emails_Message>>;
  /** fetch data from the table: "emails.message" using primary key columns */
  emails_message_by_pk?: Maybe<Emails_Message>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  /** fetch data from the table: "lives.action" */
  lives_action?: Maybe<Array<Lives_Action>>;
  /** fetch data from the table: "lives.action" using primary key columns */
  lives_action_by_pk?: Maybe<Lives_Action>;
  /** fetch data from the table: "lives.current_reporters" */
  lives_current_reporters?: Maybe<Array<Lives_Current_Reporters>>;
  /** Récupère la liste des meilleurs reporters pour une période */
  lives_get_bests_reporters?: Maybe<Array<Best_Reporters_Response>>;
  /** fetch data from the table: "lives.live" */
  lives_live?: Maybe<Array<Lives_Live>>;
  /** fetch data from the table: "lives.live" using primary key columns */
  lives_live_by_pk?: Maybe<Lives_Live>;
  /** fetch data from the table: "lives.live_has_reporter" */
  lives_live_has_reporter?: Maybe<Array<Lives_Live_Has_Reporter>>;
  /** fetch data from the table: "lives.live_hit" */
  lives_live_hit?: Maybe<Array<Lives_Live_Hit>>;
  /** fetch data from the table: "lives.live_hit" using primary key columns */
  lives_live_hit_by_pk?: Maybe<Lives_Live_Hit>;
  /** fetch data from the table: "lives.live_hit_count" */
  lives_live_hit_count?: Maybe<Array<Lives_Live_Hit_Count>>;
  /** fetch data from the table: "lives.live_hit_count" using primary key columns */
  lives_live_hit_count_by_pk?: Maybe<Lives_Live_Hit_Count>;
  /** fetch data from the table: "lives.live_last_update" */
  lives_live_last_update?: Maybe<Array<Lives_Live_Last_Update>>;
  /** fetch data from the table: "lives.live_player" */
  lives_live_player?: Maybe<Array<Lives_Live_Player>>;
  /** fetch data from the table: "lives.live_player" using primary key columns */
  lives_live_player_by_pk?: Maybe<Lives_Live_Player>;
  /** fetch data from the table: "lives.live_team" */
  lives_live_team?: Maybe<Array<Lives_Live_Team>>;
  /** fetch data from the table: "lives.live_team" using primary key columns */
  lives_live_team_by_pk?: Maybe<Lives_Live_Team>;
  /** fetch data from the table: "lives.media_official_reporter" */
  lives_media_official_reporter?: Maybe<Array<Lives_Media_Official_Reporter>>;
  /** fetch data from the table: "lives.media_official_reporter" using primary key columns */
  lives_media_official_reporter_by_pk?: Maybe<Lives_Media_Official_Reporter>;
  /** fetch data from the table: "lives.official_reporter" */
  lives_official_reporter?: Maybe<Array<Lives_Official_Reporter>>;
  /** fetch data from the table: "lives.official_reporter" using primary key columns */
  lives_official_reporter_by_pk?: Maybe<Lives_Official_Reporter>;
  /** fetch data from the table: "lives.post" */
  lives_post?: Maybe<Array<Lives_Post>>;
  /** fetch aggregated fields from the table: "lives.post" */
  lives_post_aggregate?: Maybe<Lives_Post_Aggregate>;
  /** fetch data from the table: "lives.post" using primary key columns */
  lives_post_by_pk?: Maybe<Lives_Post>;
  /** fetch data from the table: "lives.potential_team_reporters" */
  lives_potential_team_reporters?: Maybe<Array<Lives_Potential_Team_Reporters>>;
  /** fetch data from the table: "lives.spectator" */
  lives_spectator?: Maybe<Array<Lives_Spectator>>;
  /** fetch data from the table: "lives.spectator" using primary key columns */
  lives_spectator_by_pk?: Maybe<Lives_Spectator>;
  /** fetch data from the table: "lives.team_official_reporter" */
  lives_team_official_reporter?: Maybe<Array<Lives_Team_Official_Reporter>>;
  /** fetch data from the table: "lives.team_official_reporter" using primary key columns */
  lives_team_official_reporter_by_pk?: Maybe<Lives_Team_Official_Reporter>;
  /** fetch data from the table: "lives.thank_you" */
  lives_thank_you?: Maybe<Array<Lives_Thank_You>>;
  /** fetch aggregated fields from the table: "lives.thank_you" */
  lives_thank_you_aggregate?: Maybe<Lives_Thank_You_Aggregate>;
  /** fetch data from the table: "lives.thank_you" using primary key columns */
  lives_thank_you_by_pk?: Maybe<Lives_Thank_You>;
  /** fetch data from the table: "lives.tournament_reporter" */
  lives_tournament_reporter?: Maybe<Array<Lives_Tournament_Reporter>>;
  /** fetch data from the table: "lives.tournament_reporter" using primary key columns */
  lives_tournament_reporter_by_pk?: Maybe<Lives_Tournament_Reporter>;
  /** fetch data from the table: "lives.used_action" */
  lives_used_action?: Maybe<Array<Lives_Used_Action>>;
  /** fetch data from the table: "medias.media" */
  medias_media?: Maybe<Array<Medias_Media>>;
  /** fetch data from the table: "medias.media" using primary key columns */
  medias_media_by_pk?: Maybe<Medias_Media>;
  /** fetch data from the table: "medias.official_reporter" */
  medias_official_reporter?: Maybe<Array<Medias_Official_Reporter>>;
  /** fetch data from the table: "medias.official_reporter" using primary key columns */
  medias_official_reporter_by_pk?: Maybe<Medias_Official_Reporter>;
  /** fetch data from the table: "members.export" */
  members_export?: Maybe<Array<Members_Export>>;
  /** fetch data from the table: "members.export" using primary key columns */
  members_export_by_pk?: Maybe<Members_Export>;
  /** fetch data from the table: "members.group" */
  members_group?: Maybe<Array<Members_Group>>;
  /** fetch data from the table: "members.group" using primary key columns */
  members_group_by_pk?: Maybe<Members_Group>;
  /** fetch data from the table: "members.member" */
  members_member?: Maybe<Array<Members_Member>>;
  /** fetch aggregated fields from the table: "members.member" */
  members_member_aggregate?: Maybe<Members_Member_Aggregate>;
  /** fetch data from the table: "members.member" using primary key columns */
  members_member_by_pk?: Maybe<Members_Member>;
  /** fetch data from the table: "members.member_in_group" */
  members_member_in_group?: Maybe<Array<Members_Member_In_Group>>;
  /** fetch data from the table: "members.member_in_group" using primary key columns */
  members_member_in_group_by_pk?: Maybe<Members_Member_In_Group>;
  /** fetch data from the table: "members.team_official_reporter" */
  members_team_official_reporter?: Maybe<Array<Members_Team_Official_Reporter>>;
  /** fetch aggregated fields from the table: "members.team_official_reporter" */
  members_team_official_reporter_aggregate?: Maybe<Members_Team_Official_Reporter_Aggregate>;
  /** fetch data from the table: "members.team_official_reporter" using primary key columns */
  members_team_official_reporter_by_pk?: Maybe<Members_Team_Official_Reporter>;
  menusMenu?: Maybe<MenusMenuEntityResponse>;
  menusMenuItem?: Maybe<MenusMenuItemEntityResponse>;
  menusMenuItems?: Maybe<MenusMenuItemEntityResponseCollection>;
  menusMenus?: Maybe<MenusMenuEntityResponseCollection>;
  /** fetch data from the table: "news.news" */
  news_news?: Maybe<Array<News_News>>;
  /** fetch aggregated fields from the table: "news.news" */
  news_news_aggregate?: Maybe<News_News_Aggregate>;
  /** fetch data from the table: "news.news" using primary key columns */
  news_news_by_pk?: Maybe<News_News>;
  /** fetch data from the table: "notifications.endpoint" */
  notifications_endpoint?: Maybe<Array<Notifications_Endpoint>>;
  /** fetch data from the table: "notifications.endpoint" using primary key columns */
  notifications_endpoint_by_pk?: Maybe<Notifications_Endpoint>;
  pagesChHome?: Maybe<PagesChHomeEntityResponse>;
  pagesClub?: Maybe<PagesClubEntityResponse>;
  pagesClubs?: Maybe<PagesClubEntityResponseCollection>;
  pagesHome?: Maybe<PagesHomeEntityResponse>;
  pagesLanding?: Maybe<PagesLandingEntityResponse>;
  pagesLandings?: Maybe<PagesLandingEntityResponseCollection>;
  pagesLive?: Maybe<PagesLiveEntityResponse>;
  pagesLives?: Maybe<PagesLiveEntityResponseCollection>;
  pagesSport?: Maybe<PagesSportEntityResponse>;
  pagesSports?: Maybe<PagesSportEntityResponseCollection>;
  /** Get list of categories and permissions for a role */
  permissions_get_permissions_for_role?: Maybe<Array<Permission_Categories>>;
  /** fetch data from the table: "permissions.member_permission" */
  permissions_member_permission?: Maybe<Array<Permissions_Member_Permission>>;
  /** fetch data from the table: "permissions.member_permission" using primary key columns */
  permissions_member_permission_by_pk?: Maybe<Permissions_Member_Permission>;
  /** fetch data from the table: "permissions.member_role" */
  permissions_member_role?: Maybe<Array<Permissions_Member_Role>>;
  /** fetch data from the table: "permissions.member_role" using primary key columns */
  permissions_member_role_by_pk?: Maybe<Permissions_Member_Role>;
  /** fetch data from the table: "permissions.role" */
  permissions_role?: Maybe<Array<Permissions_Role>>;
  /** fetch data from the table: "permissions.role" using primary key columns */
  permissions_role_by_pk?: Maybe<Permissions_Role>;
  /** fetch data from the table: "permissions.role_permission" */
  permissions_role_permission?: Maybe<Array<Permissions_Role_Permission>>;
  /** fetch data from the table: "permissions.role_permission" using primary key columns */
  permissions_role_permission_by_pk?: Maybe<Permissions_Role_Permission>;
  /** fetch data from the table: "places.city" */
  places_city?: Maybe<Array<Places_City>>;
  /** fetch data from the table: "places.city" using primary key columns */
  places_city_by_pk?: Maybe<Places_City>;
  /** fetch data from the table: "places.country" */
  places_country?: Maybe<Array<Places_Country>>;
  /** fetch data from the table: "places.country" using primary key columns */
  places_country_by_pk?: Maybe<Places_Country>;
  /** fetch data from the table: "places.department" */
  places_department?: Maybe<Array<Places_Department>>;
  /** fetch data from the table: "places.department" using primary key columns */
  places_department_by_pk?: Maybe<Places_Department>;
  /** fetch data from the table: "places.equipment" */
  places_equipment?: Maybe<Array<Places_Equipment>>;
  /** fetch data from the table: "places.equipment" using primary key columns */
  places_equipment_by_pk?: Maybe<Places_Equipment>;
  /** fetch data from the table: "places.place" */
  places_place?: Maybe<Array<Places_Place>>;
  /** fetch data from the table: "places.place" using primary key columns */
  places_place_by_pk?: Maybe<Places_Place>;
  /** fetch data from the table: "places.region" */
  places_region?: Maybe<Array<Places_Region>>;
  /** fetch data from the table: "places.region" using primary key columns */
  places_region_by_pk?: Maybe<Places_Region>;
  /** fetch data from the table: "scraping.championships_clubchampionshipexception" */
  scraping_championships_clubchampionshipexception?: Maybe<
    Array<Scraping_Championships_Clubchampionshipexception>
  >;
  /** fetch data from the table: "scraping.championships_clubchampionshipexception" using primary key columns */
  scraping_championships_clubchampionshipexception_by_pk?: Maybe<Scraping_Championships_Clubchampionshipexception>;
  /** fetch data from the table: "scraping.championships_clubchampionshipexception_championships" */
  scraping_championships_clubchampionshipexception_championships?: Maybe<
    Array<Scraping_Championships_Clubchampionshipexception_Championships>
  >;
  /** fetch data from the table: "sms.club_sms_credit" */
  sms_club_sms_credit?: Maybe<Array<Sms_Club_Sms_Credit>>;
  /** fetch data from the table: "sms.club_sms_credit" using primary key columns */
  sms_club_sms_credit_by_pk?: Maybe<Sms_Club_Sms_Credit>;
  /** fetch data from the table: "sms.event" */
  sms_event?: Maybe<Array<Sms_Event>>;
  /** fetch data from the table: "sms.event" using primary key columns */
  sms_event_by_pk?: Maybe<Sms_Event>;
  /** fetch data from the table: "sms.message" */
  sms_message?: Maybe<Array<Sms_Message>>;
  /** fetch data from the table: "sms.message" using primary key columns */
  sms_message_by_pk?: Maybe<Sms_Message>;
  /** fetch data from the table: "socials.facebook" */
  socials_facebook?: Maybe<Array<Socials_Facebook>>;
  /** fetch data from the table: "socials.facebook" using primary key columns */
  socials_facebook_by_pk?: Maybe<Socials_Facebook>;
  /** fetch data from the table: "socials.post" */
  socials_post?: Maybe<Array<Socials_Post>>;
  /** fetch data from the table: "socials.post" using primary key columns */
  socials_post_by_pk?: Maybe<Socials_Post>;
  /** fetch data from the table: "sponsors.category" */
  sponsors_category?: Maybe<Array<Sponsors_Category>>;
  /** fetch aggregated fields from the table: "sponsors.category" */
  sponsors_category_aggregate?: Maybe<Sponsors_Category_Aggregate>;
  /** fetch data from the table: "sponsors.category" using primary key columns */
  sponsors_category_by_pk?: Maybe<Sponsors_Category>;
  /** fetch data from the table: "sponsors.sponsor" */
  sponsors_sponsor?: Maybe<Array<Sponsors_Sponsor>>;
  /** fetch data from the table: "sponsors.sponsor" using primary key columns */
  sponsors_sponsor_by_pk?: Maybe<Sponsors_Sponsor>;
  /** fetch data from the table: "sponsors.team_sponsor" */
  sponsors_team_sponsor?: Maybe<Array<Sponsors_Team_Sponsor>>;
  /** fetch data from the table: "sponsors.team_sponsor" using primary key columns */
  sponsors_team_sponsor_by_pk?: Maybe<Sponsors_Team_Sponsor>;
  /** fetch data from the table: "stats.action" */
  stats_action?: Maybe<Array<Stats_Action>>;
  /** fetch aggregated fields from the table: "stats.action" */
  stats_action_aggregate?: Maybe<Stats_Action_Aggregate>;
  /** fetch data from the table: "stats.action" using primary key columns */
  stats_action_by_pk?: Maybe<Stats_Action>;
  stats_after_match_graph?: Maybe<Array<Stats_After_Match_Graph_Response>>;
  stats_after_match_passe_direction?: Maybe<
    Array<Stats_After_Match_Passe_Direction_Response>
  >;
  stats_after_match_zone?: Maybe<Array<Stats_After_Match_Zone_Response>>;
  stats_collective_statistics?: Maybe<
    Array<Stats_Collective_Statistics_Response>
  >;
  /** fetch data from the table: "tasks.candidate_group" */
  tasks_candidate_group?: Maybe<Array<Tasks_Candidate_Group>>;
  /** fetch data from the table: "tasks.candidate_group" using primary key columns */
  tasks_candidate_group_by_pk?: Maybe<Tasks_Candidate_Group>;
  /** fetch data from the table: "tasks.candidate_team" */
  tasks_candidate_team?: Maybe<Array<Tasks_Candidate_Team>>;
  /** fetch data from the table: "tasks.candidate_team" using primary key columns */
  tasks_candidate_team_by_pk?: Maybe<Tasks_Candidate_Team>;
  /** fetch data from the table: "tasks.job" */
  tasks_job?: Maybe<Array<Tasks_Job>>;
  /** fetch data from the table: "tasks.job" using primary key columns */
  tasks_job_by_pk?: Maybe<Tasks_Job>;
  /** fetch data from the table: "tasks.member_task_count" */
  tasks_member_task_count?: Maybe<Array<Tasks_Member_Task_Count>>;
  /** fetch data from the table: "tasks.member_task_count" using primary key columns */
  tasks_member_task_count_by_pk?: Maybe<Tasks_Member_Task_Count>;
  /** fetch data from the table: "tasks.task" */
  tasks_task?: Maybe<Array<Tasks_Task>>;
  /** fetch aggregated fields from the table: "tasks.task" */
  tasks_task_aggregate?: Maybe<Tasks_Task_Aggregate>;
  /** fetch data from the table: "tasks.task" using primary key columns */
  tasks_task_by_pk?: Maybe<Tasks_Task>;
  /** fetch data from the table: "tasks.team_task" */
  tasks_team_task?: Maybe<Array<Tasks_Team_Task>>;
  /** fetch data from the table: "tasks.team_task" using primary key columns */
  tasks_team_task_by_pk?: Maybe<Tasks_Team_Task>;
  /** fetch data from the table: "tasks.volunteer" */
  tasks_volunteer?: Maybe<Array<Tasks_Volunteer>>;
  /** fetch data from the table: "tasks.volunteer" using primary key columns */
  tasks_volunteer_by_pk?: Maybe<Tasks_Volunteer>;
  /** fetch data from the table: "teams.team_detail" using primary key columns */
  team_detail_by_pk_deprecated?: Maybe<Teams_Team_Detail>;
  /** fetch data from the table: "teams.authorized_group" */
  teams_authorized_group?: Maybe<Array<Teams_Authorized_Group>>;
  /** fetch data from the table: "teams.authorized_group" using primary key columns */
  teams_authorized_group_by_pk?: Maybe<Teams_Authorized_Group>;
  /** fetch data from the table: "teams.authorized_team" */
  teams_authorized_team?: Maybe<Array<Teams_Authorized_Team>>;
  /** fetch data from the table: "teams.authorized_team" using primary key columns */
  teams_authorized_team_by_pk?: Maybe<Teams_Authorized_Team>;
  /** fetch data from the table: "teams.club_group" */
  teams_club_group?: Maybe<Array<Teams_Club_Group>>;
  /** fetch data from the table: "teams.club_group" using primary key columns */
  teams_club_group_by_pk?: Maybe<Teams_Club_Group>;
  /** fetch data from the table: "teams.fan" */
  teams_fan?: Maybe<Array<Teams_Fan>>;
  /** fetch data from the table: "teams.fan" using primary key columns */
  teams_fan_by_pk?: Maybe<Teams_Fan>;
  /** fetch data from the table: "teams.level" */
  teams_level?: Maybe<Array<Teams_Level>>;
  /** fetch data from the table: "teams.level" using primary key columns */
  teams_level_by_pk?: Maybe<Teams_Level>;
  /** fetch data from the table: "teams.official" */
  teams_official?: Maybe<Array<Teams_Official>>;
  /** fetch data from the table: "teams.official" using primary key columns */
  teams_official_by_pk?: Maybe<Teams_Official>;
  /** fetch data from the table: "teams.team" */
  teams_team?: Maybe<Array<Teams_Team>>;
  /** fetch data from the table: "teams.team_action_permission" */
  teams_team_action_permission?: Maybe<Array<Teams_Team_Action_Permission>>;
  /** fetch data from the table: "teams.team_action_permission" using primary key columns */
  teams_team_action_permission_by_pk?: Maybe<Teams_Team_Action_Permission>;
  /** fetch data from the table: "teams.team" using primary key columns */
  teams_team_by_pk?: Maybe<Teams_Team>;
  /** fetch data from the table: "teams.team_detail" */
  teams_team_detail?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_by_club_id" which returns "teams.team_detail" */
  teams_team_detail_by_club_id?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_by_member_id" which returns "teams.team_detail" */
  teams_team_detail_by_member_id?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_by_pk" which returns "teams.team_detail" */
  teams_team_detail_by_pk?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_by_team_id" which returns "teams.team_detail" */
  teams_team_detail_by_team_id?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_suggested" which returns "teams.team_detail" */
  teams_team_detail_suggested?: Maybe<Array<Teams_Team_Detail>>;
  /** fetch data from the table: "teams.team_has_fans" */
  teams_team_has_fans?: Maybe<Array<Teams_Team_Has_Fans>>;
  /** fetch data from the table: "teams.team_has_fans" using primary key columns */
  teams_team_has_fans_by_pk?: Maybe<Teams_Team_Has_Fans>;
  /** fetch data from the table: "teams.team_in_season" */
  teams_team_in_season?: Maybe<Array<Teams_Team_In_Season>>;
  /** fetch data from the table: "teams.team_in_season" using primary key columns */
  teams_team_in_season_by_pk?: Maybe<Teams_Team_In_Season>;
  /** fetch data from the table: "teams.team_in_season_has_member" */
  teams_team_in_season_has_member?: Maybe<
    Array<Teams_Team_In_Season_Has_Member>
  >;
  /** fetch aggregated fields from the table: "teams.team_in_season_has_member" */
  teams_team_in_season_has_member_aggregate?: Maybe<Teams_Team_In_Season_Has_Member_Aggregate>;
  /** fetch data from the table: "teams.team_in_season_has_member" using primary key columns */
  teams_team_in_season_has_member_by_pk?: Maybe<Teams_Team_In_Season_Has_Member>;
  /** fetch data from the table: "teams.team_in_season_translation" */
  teams_team_in_season_translation?: Maybe<
    Array<Teams_Team_In_Season_Translation>
  >;
  /** fetch data from the table: "teams.team_in_season_translation" using primary key columns */
  teams_team_in_season_translation_by_pk?: Maybe<Teams_Team_In_Season_Translation>;
  /** fetch data from the table: "teams.team_official" */
  teams_team_official?: Maybe<Array<Teams_Team_Official>>;
  /** fetch data from the table: "teams.team_official" using primary key columns */
  teams_team_official_by_pk?: Maybe<Teams_Team_Official>;
  /** fetch data from the table: "tournaments.challenge" */
  tournaments_challenge?: Maybe<Array<Tournaments_Challenge>>;
  /** fetch data from the table: "tournaments.challenge" using primary key columns */
  tournaments_challenge_by_pk?: Maybe<Tournaments_Challenge>;
  /** fetch data from the table: "tournaments.challenge_rule" */
  tournaments_challenge_rule?: Maybe<Array<Tournaments_Challenge_Rule>>;
  /** fetch data from the table: "tournaments.challenge_rule" using primary key columns */
  tournaments_challenge_rule_by_pk?: Maybe<Tournaments_Challenge_Rule>;
  /** fetch data from the table: "tournaments.engagement" */
  tournaments_engagement?: Maybe<Array<Tournaments_Engagement>>;
  /** fetch aggregated fields from the table: "tournaments.engagement" */
  tournaments_engagement_aggregate?: Maybe<Tournaments_Engagement_Aggregate>;
  /** fetch data from the table: "tournaments.engagement" using primary key columns */
  tournaments_engagement_by_pk?: Maybe<Tournaments_Engagement>;
  /** fetch data from the table: "tournaments.game" */
  tournaments_game?: Maybe<Array<Tournaments_Game>>;
  /** fetch data from the table: "tournaments.game" using primary key columns */
  tournaments_game_by_pk?: Maybe<Tournaments_Game>;
  /** fetch data from the table: "tournaments.games_in_phase" */
  tournaments_games_in_phase?: Maybe<Array<Tournaments_Games_In_Phase>>;
  /** Enregistre une visite sur la page du tournoi */
  tournaments_new_hit?: Maybe<Tournaments_New_Hit>;
  /** fetch data from the table: "tournaments.official_reporter_v2" */
  tournaments_official_reporter_v2?: Maybe<
    Array<Tournaments_Official_Reporter_V2>
  >;
  /** fetch data from the table: "tournaments.official_reporter_v2" using primary key columns */
  tournaments_official_reporter_v2_by_pk?: Maybe<Tournaments_Official_Reporter_V2>;
  /** fetch data from the table: "tournaments.phase" */
  tournaments_phase?: Maybe<Array<Tournaments_Phase>>;
  /** fetch aggregated fields from the table: "tournaments.phase" */
  tournaments_phase_aggregate?: Maybe<Tournaments_Phase_Aggregate>;
  /** fetch data from the table: "tournaments.phase" using primary key columns */
  tournaments_phase_by_pk?: Maybe<Tournaments_Phase>;
  /** fetch data from the table: "tournaments.place" */
  tournaments_place?: Maybe<Array<Tournaments_Place>>;
  /** fetch data from the table: "tournaments.place" using primary key columns */
  tournaments_place_by_pk?: Maybe<Tournaments_Place>;
  /** fetch data from the table: "tournaments.ranking" */
  tournaments_ranking?: Maybe<Array<Tournaments_Ranking>>;
  /** fetch data from the table: "tournaments.ranking" using primary key columns */
  tournaments_ranking_by_pk?: Maybe<Tournaments_Ranking>;
  /** fetch data from the table: "tournaments.ranking_points" */
  tournaments_ranking_points?: Maybe<Array<Tournaments_Ranking_Points>>;
  /** fetch data from the table: "tournaments.ranking_points" using primary key columns */
  tournaments_ranking_points_by_pk?: Maybe<Tournaments_Ranking_Points>;
  /** fetch data from the table: "tournaments.ranking_rule" */
  tournaments_ranking_rule?: Maybe<Array<Tournaments_Ranking_Rule>>;
  /** fetch data from the table: "tournaments.ranking_rule" using primary key columns */
  tournaments_ranking_rule_by_pk?: Maybe<Tournaments_Ranking_Rule>;
  /** fetch data from the table: "tournaments.team" */
  tournaments_team?: Maybe<Array<Tournaments_Team>>;
  /** fetch data from the table: "tournaments.team" using primary key columns */
  tournaments_team_by_pk?: Maybe<Tournaments_Team>;
  /** fetch data from the table: "tournaments.tournament" */
  tournaments_tournament?: Maybe<Array<Tournaments_Tournament>>;
  /** fetch data from the table: "tournaments.tournament" using primary key columns */
  tournaments_tournament_by_pk?: Maybe<Tournaments_Tournament>;
  /** fetch data from the table: "tournaments.tournament_place" */
  tournaments_tournament_place?: Maybe<Array<Tournaments_Tournament_Place>>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  /** execute function "urls.club_by_direct_score_url" which returns "urls.url_detail" */
  urls_club_by_direct_score_url?: Maybe<Array<Urls_Url_Detail>>;
  /** execute function "urls.event_by_direct_score_same_teams_url" which returns "urls.url_detail" */
  urls_event_by_direct_score_same_teams_url?: Maybe<Array<Urls_Url_Detail>>;
  /** execute function "urls.event_by_direct_score_url" which returns "urls.url_detail" */
  urls_event_by_direct_score_url?: Maybe<Array<Urls_Url_Detail>>;
  /** execute function "urls.team_in_season_by_direct_score_url" which returns "urls.url_detail" */
  urls_team_in_season_by_direct_score_url?: Maybe<Array<Urls_Url_Detail>>;
  /** fetch data from the table: "urls.url_detail" */
  urls_url_detail?: Maybe<Array<Urls_Url_Detail>>;
  /** fetch data from the table: "urls.url_detail" using primary key columns */
  urls_url_detail_by_pk?: Maybe<Urls_Url_Detail>;
  /** fetch data from the table: "users.convocation_progress" */
  users_convocation_progress?: Maybe<Array<Users_Convocation_Progress>>;
  /** fetch data from the table: "users.convocation_progress" using primary key columns */
  users_convocation_progress_by_pk?: Maybe<Users_Convocation_Progress>;
  /** fetch data from the table: "users.member_invitation" */
  users_member_invitation?: Maybe<Array<Users_Member_Invitation>>;
  /** fetch data from the table: "users.member_invitation" using primary key columns */
  users_member_invitation_by_pk?: Maybe<Users_Member_Invitation>;
  /** fetch data from the table: "users.user" */
  users_user?: Maybe<Array<Users_User>>;
  /** fetch data from the table: "users.user" using primary key columns */
  users_user_by_pk?: Maybe<Users_User>;
  /** fetch data from the table: "widgets.club_settings" */
  widgets_club_settings?: Maybe<Array<Widgets_Club_Settings>>;
  /** fetch data from the table: "widgets.club_settings" using primary key columns */
  widgets_club_settings_by_pk?: Maybe<Widgets_Club_Settings>;
  /** fetch data from the table: "widgets.convocation_settings" */
  widgets_convocation_settings?: Maybe<Array<Widgets_Convocation_Settings>>;
  /** fetch data from the table: "widgets.convocation_settings" using primary key columns */
  widgets_convocation_settings_by_pk?: Maybe<Widgets_Convocation_Settings>;
  /** fetch data from the table: "widgets.widget" */
  widgets_widget?: Maybe<Array<Widgets_Widget>>;
  /** fetch data from the table: "widgets.widget" using primary key columns */
  widgets_widget_by_pk?: Maybe<Widgets_Widget>;
  /** fetch data from the table: "widgets.widget_has_competition" */
  widgets_widget_has_competition?: Maybe<Array<Widgets_Widget_Has_Competition>>;
  /** fetch data from the table: "widgets.widget_has_competition" using primary key columns */
  widgets_widget_has_competition_by_pk?: Maybe<Widgets_Widget_Has_Competition>;
  /** fetch data from the table: "widgets.widget_has_team" */
  widgets_widget_has_team?: Maybe<Array<Widgets_Widget_Has_Team>>;
  /** fetch data from the table: "widgets.widget_has_team" using primary key columns */
  widgets_widget_has_team_by_pk?: Maybe<Widgets_Widget_Has_Team>;
  /** fetch data from the table: "widgets.widget_live" */
  widgets_widget_live?: Maybe<Array<Widgets_Widget_Live>>;
  /** fetch data from the table: "widgets.widget_live" using primary key columns */
  widgets_widget_live_by_pk?: Maybe<Widgets_Widget_Live>;
  /** fetch data from the table: "widgets.widget_team" */
  widgets_widget_team?: Maybe<Array<Widgets_Widget_Team>>;
  /** fetch data from the table: "widgets.widget_team" using primary key columns */
  widgets_widget_team_by_pk?: Maybe<Widgets_Widget_Team>;
};

export type Query_RootApps_VersionArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};

export type Query_RootApps_Version_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootBilling_Club_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Billing_Club_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Club_Customer_Order_By>>;
  where?: InputMaybe<Billing_Club_Customer_Bool_Exp>;
};

export type Query_RootBilling_Club_Customer_By_PkArgs = {
  customer_ptr_id: Scalars['Int']['input'];
};

export type Query_RootBilling_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Billing_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Customer_Order_By>>;
  where?: InputMaybe<Billing_Customer_Bool_Exp>;
};

export type Query_RootBilling_Customer_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootBilling_Get_Club_CustomersArgs = {
  club_id: Scalars['Int']['input'];
};

export type Query_RootBilling_Get_Customer_EstimatesArgs = {
  customer_id: Scalars['String']['input'];
};

export type Query_RootBilling_Get_Customer_InvoicesArgs = {
  customer_id: Scalars['String']['input'];
};

export type Query_RootClubhouse_Club_HouseArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Club_House_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Club_House_Order_By>>;
  where?: InputMaybe<Clubhouse_Club_House_Bool_Exp>;
};

export type Query_RootClubhouse_Club_House_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubhouse_ScreenArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Screen_Order_By>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

export type Query_RootClubhouse_Screen_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Screen_Order_By>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

export type Query_RootClubhouse_Screen_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubhouse_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Template_Order_By>>;
  where?: InputMaybe<Clubhouse_Template_Bool_Exp>;
};

export type Query_RootClubhouse_Template_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubs_AdminArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Admin_Bool_Exp>;
};

export type Query_RootClubs_Admin_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubs_ClubArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Order_By>>;
  where?: InputMaybe<Clubs_Club_Bool_Exp>;
};

export type Query_RootClubs_Club_AdminArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

export type Query_RootClubs_Club_Admin_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubs_Club_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Order_By>>;
  where?: InputMaybe<Clubs_Club_Bool_Exp>;
};

export type Query_RootClubs_Club_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubs_Club_DetailArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Detail_Order_By>>;
  where?: InputMaybe<Clubs_Club_Detail_Bool_Exp>;
};

export type Query_RootClubs_Club_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubs_Get_ClubArgs = {
  args: Clubs_Get_Club_Args;
  distinct_on?: InputMaybe<Array<Clubs_Club_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Detail_Order_By>>;
  where?: InputMaybe<Clubs_Club_Detail_Bool_Exp>;
};

export type Query_RootClubs_OrganizationArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Organization_Order_By>>;
  where?: InputMaybe<Clubs_Organization_Bool_Exp>;
};

export type Query_RootClubs_Organization_By_PkArgs = {
  club_ptr_id: Scalars['Int']['input'];
};

export type Query_RootClubs_Planner_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Planner_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Planner_Settings_Order_By>>;
  where?: InputMaybe<Clubs_Planner_Settings_Bool_Exp>;
};

export type Query_RootClubs_Planner_Settings_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubs_Scraping_Championships_With_ExceptionsArgs = {
  club_id: Scalars['Int']['input'];
};

export type Query_RootClubs_Scraping_Competitions_Not_SynchronizedArgs = {
  club_id: Scalars['Int']['input'];
};

export type Query_RootClubs_Scraping_Missing_CompetitionsArgs = {
  club_id: Scalars['Int']['input'];
};

export type Query_RootClubs_SeasonArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Season_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Season_Order_By>>;
  where?: InputMaybe<Clubs_Season_Bool_Exp>;
};

export type Query_RootClubs_Season_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootClubs_SportArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Sport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Sport_Order_By>>;
  where?: InputMaybe<Clubs_Sport_Bool_Exp>;
};

export type Query_RootClubs_Sport_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Closest_Event_With_Same_TeamsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Closest_Event_With_Same_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Closest_Event_With_Same_Teams_Order_By>
  >;
  where?: InputMaybe<Competitions_Closest_Event_With_Same_Teams_Bool_Exp>;
};

export type Query_RootCompetitions_CompetitionArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Competition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Bool_Exp>;
};

export type Query_RootCompetitions_Competition_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Competition_DetailArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Competition_Detail_By_IdArgs = {
  args: Competitions_Competition_Detail_By_Id_Args;
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Competition_Detail_By_Level_For_Season_IdArgs =
  {
    args: Competitions_Competition_Detail_By_Level_For_Season_Id_Args;
    distinct_on?: InputMaybe<
      Array<Competitions_Competition_Detail_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Competition_Detail_By_Level_IdArgs = {
  args: Competitions_Competition_Detail_By_Level_Id_Args;
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Competition_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Competition_Has_FansArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Has_Fans_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Has_Fans_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Has_Fans_Bool_Exp>;
};

export type Query_RootCompetitions_Competition_Has_Fans_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Competition_Has_TeamsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Has_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Has_Teams_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
};

export type Query_RootCompetitions_Competition_Has_Teams_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Competition_StatisticsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Statistics_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Statistics_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Statistics_Bool_Exp>;
};

export type Query_RootCompetitions_EventArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Order_By>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

export type Query_RootCompetitions_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Order_By>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

export type Query_RootCompetitions_Event_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Event_Closest_DateArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Event_Closest_Date_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Closest_Date_Order_By>>;
  where?: InputMaybe<Competitions_Event_Closest_Date_Bool_Exp>;
};

export type Query_RootCompetitions_Event_DetailArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Club_IdArgs = {
  args: Competitions_Event_Detail_By_Club_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Club_Id_AggregateArgs = {
  args: Competitions_Event_Detail_By_Club_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Club_Id_Between_DatesArgs = {
  args: Competitions_Event_Detail_By_Club_Id_Between_Dates_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Club_Id_Between_Dates_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Club_Id_Between_Dates_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_RankArgs =
  {
    args: Competitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_Rank_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_Rank_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_Rank_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Event_Detail_By_IdArgs = {
  args: Competitions_Event_Detail_By_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Id_AggregateArgs = {
  args: Competitions_Event_Detail_By_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Live_RatingArgs = {
  args: Competitions_Event_Detail_By_Live_Rating_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Live_Rating_AggregateArgs = {
  args: Competitions_Event_Detail_By_Live_Rating_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Live_Rating_And_SportArgs = {
  args: Competitions_Event_Detail_By_Live_Rating_And_Sport_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Live_Rating_And_Sport_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Live_Rating_And_Sport_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Event_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Event_Detail_By_Round_Day_IdArgs = {
  args: Competitions_Event_Detail_By_Round_Day_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Round_Day_Id_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Round_Day_Id_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Event_Detail_By_Team_IdArgs = {
  args: Competitions_Event_Detail_By_Team_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Team_Id_AggregateArgs = {
  args: Competitions_Event_Detail_By_Team_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Team_In_Season_IdArgs = {
  args: Competitions_Event_Detail_By_Team_In_Season_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_Team_In_Season_Id_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Team_In_Season_Id_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Event_Detail_By_User_Favorite_TeamsArgs = {
  args: Competitions_Event_Detail_By_User_Favorite_Teams_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Detail_By_User_Favorite_Teams_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_User_Favorite_Teams_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Event_Last_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Last_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Last_Update_Order_By>>;
  where?: InputMaybe<Competitions_Event_Last_Update_Bool_Exp>;
};

export type Query_RootCompetitions_Event_RefereeArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Referee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Referee_Order_By>>;
  where?: InputMaybe<Competitions_Event_Referee_Bool_Exp>;
};

export type Query_RootCompetitions_Event_Referee_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Event_With_Same_TeamsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Event_With_Same_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_With_Same_Teams_Order_By>>;
  where?: InputMaybe<Competitions_Event_With_Same_Teams_Bool_Exp>;
};

export type Query_RootCompetitions_Event_With_Same_Teams_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_FanArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Fan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Fan_Order_By>>;
  where?: InputMaybe<Competitions_Fan_Bool_Exp>;
};

export type Query_RootCompetitions_Fan_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Get_Event_CalendarArgs = {
  event_id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_LeagueArgs = {
  distinct_on?: InputMaybe<Array<Competitions_League_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Order_By>>;
  where?: InputMaybe<Competitions_League_Bool_Exp>;
};

export type Query_RootCompetitions_League_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_League_DetailArgs = {
  distinct_on?: InputMaybe<Array<Competitions_League_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Detail_Order_By>>;
  where?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_League_Detail_By_IdArgs = {
  args: Competitions_League_Detail_By_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_League_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Detail_Order_By>>;
  where?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_League_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_League_Detail_By_Sport_SlugArgs = {
  args: Competitions_League_Detail_By_Sport_Slug_Args;
  distinct_on?: InputMaybe<Array<Competitions_League_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Detail_Order_By>>;
  where?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_LevelArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_Order_By>>;
  where?: InputMaybe<Competitions_Level_Bool_Exp>;
};

export type Query_RootCompetitions_Level_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Level_For_SeasonArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Level_For_Season_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Bool_Exp>;
};

export type Query_RootCompetitions_Level_For_Season_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Level_For_Season_DetailArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Level_For_Season_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Level_For_Season_Detail_By_IdArgs = {
  args: Competitions_Level_For_Season_Detail_By_Id_Args;
  distinct_on?: InputMaybe<
    Array<Competitions_Level_For_Season_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Level_For_Season_Detail_By_League_IdArgs = {
  args: Competitions_Level_For_Season_Detail_By_League_Id_Args;
  distinct_on?: InputMaybe<
    Array<Competitions_Level_For_Season_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Level_For_Season_Detail_By_Level_And_SeasonArgs =
  {
    args: Competitions_Level_For_Season_Detail_By_Level_And_Season_Args;
    distinct_on?: InputMaybe<
      Array<Competitions_Level_For_Season_Detail_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
  };

export type Query_RootCompetitions_Level_For_Season_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Playing_TeamArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

export type Query_RootCompetitions_Playing_Team_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

export type Query_RootCompetitions_Playing_Team_Bar_ManagerArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Bar_Manager_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Bar_Manager_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
};

export type Query_RootCompetitions_Playing_Team_Bar_Manager_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Playing_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Playing_Team_InfoArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Info_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
};

export type Query_RootCompetitions_Playing_Team_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Info_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
};

export type Query_RootCompetitions_Playing_Team_Info_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Playing_Team_RefereeArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Referee_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Referee_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
};

export type Query_RootCompetitions_Playing_Team_Referee_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Playing_Team_Scoring_TableArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Scoring_Table_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Playing_Team_Scoring_Table_Order_By>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
};

export type Query_RootCompetitions_Playing_Team_Scoring_Table_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Playing_Team_Stadium_ManagerArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Stadium_Manager_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Playing_Team_Stadium_Manager_Order_By>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
};

export type Query_RootCompetitions_Playing_Team_Stadium_Manager_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_RankingArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Ranking_Order_By>>;
  where?: InputMaybe<Competitions_Ranking_Bool_Exp>;
};

export type Query_RootCompetitions_Ranking_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_Ranking_DetailArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Ranking_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Ranking_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Ranking_Detail_Bool_Exp>;
};

export type Query_RootCompetitions_Ranking_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCompetitions_RoundArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

export type Query_RootCompetitions_Round_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

export type Query_RootCompetitions_Round_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootConfigurationArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type Query_RootEmails_EventArgs = {
  distinct_on?: InputMaybe<Array<Emails_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Emails_Event_Order_By>>;
  where?: InputMaybe<Emails_Event_Bool_Exp>;
};

export type Query_RootEmails_Event_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootEmails_MessageArgs = {
  distinct_on?: InputMaybe<Array<Emails_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Emails_Message_Order_By>>;
  where?: InputMaybe<Emails_Message_Bool_Exp>;
};

export type Query_RootEmails_Message_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_RootI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootLives_ActionArgs = {
  distinct_on?: InputMaybe<Array<Lives_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Action_Order_By>>;
  where?: InputMaybe<Lives_Action_Bool_Exp>;
};

export type Query_RootLives_Action_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Current_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Current_Reporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Current_Reporters_Order_By>>;
  where?: InputMaybe<Lives_Current_Reporters_Bool_Exp>;
};

export type Query_RootLives_Get_Bests_ReportersArgs = {
  end_date: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  start_date: Scalars['String']['input'];
};

export type Query_RootLives_LiveArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Order_By>>;
  where?: InputMaybe<Lives_Live_Bool_Exp>;
};

export type Query_RootLives_Live_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Live_Has_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Has_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Has_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Live_Has_Reporter_Bool_Exp>;
};

export type Query_RootLives_Live_HitArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Hit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Hit_Order_By>>;
  where?: InputMaybe<Lives_Live_Hit_Bool_Exp>;
};

export type Query_RootLives_Live_Hit_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Live_Hit_CountArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Hit_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Hit_Count_Order_By>>;
  where?: InputMaybe<Lives_Live_Hit_Count_Bool_Exp>;
};

export type Query_RootLives_Live_Hit_Count_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Live_Last_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Last_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Last_Update_Order_By>>;
  where?: InputMaybe<Lives_Live_Last_Update_Bool_Exp>;
};

export type Query_RootLives_Live_PlayerArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Player_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Player_Order_By>>;
  where?: InputMaybe<Lives_Live_Player_Bool_Exp>;
};

export type Query_RootLives_Live_Player_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Live_TeamArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Team_Order_By>>;
  where?: InputMaybe<Lives_Live_Team_Bool_Exp>;
};

export type Query_RootLives_Live_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Media_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Media_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Media_Official_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Media_Official_Reporter_Bool_Exp>;
};

export type Query_RootLives_Media_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Official_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Official_Reporter_Bool_Exp>;
};

export type Query_RootLives_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_PostArgs = {
  distinct_on?: InputMaybe<Array<Lives_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Post_Order_By>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

export type Query_RootLives_Post_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Post_Order_By>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

export type Query_RootLives_Post_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Potential_Team_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Potential_Team_Reporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Potential_Team_Reporters_Order_By>>;
  where?: InputMaybe<Lives_Potential_Team_Reporters_Bool_Exp>;
};

export type Query_RootLives_SpectatorArgs = {
  distinct_on?: InputMaybe<Array<Lives_Spectator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Spectator_Order_By>>;
  where?: InputMaybe<Lives_Spectator_Bool_Exp>;
};

export type Query_RootLives_Spectator_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Team_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Team_Official_Reporter_Bool_Exp>;
};

export type Query_RootLives_Team_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Thank_YouArgs = {
  distinct_on?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Thank_You_Order_By>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

export type Query_RootLives_Thank_You_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Thank_You_Order_By>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

export type Query_RootLives_Thank_You_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Tournament_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Tournament_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Tournament_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Tournament_Reporter_Bool_Exp>;
};

export type Query_RootLives_Tournament_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootLives_Used_ActionArgs = {
  distinct_on?: InputMaybe<Array<Lives_Used_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Used_Action_Order_By>>;
  where?: InputMaybe<Lives_Used_Action_Bool_Exp>;
};

export type Query_RootMedias_MediaArgs = {
  distinct_on?: InputMaybe<Array<Medias_Media_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Medias_Media_Order_By>>;
  where?: InputMaybe<Medias_Media_Bool_Exp>;
};

export type Query_RootMedias_Media_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootMedias_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Medias_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Medias_Official_Reporter_Order_By>>;
  where?: InputMaybe<Medias_Official_Reporter_Bool_Exp>;
};

export type Query_RootMedias_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootMembers_ExportArgs = {
  distinct_on?: InputMaybe<Array<Members_Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Export_Order_By>>;
  where?: InputMaybe<Members_Export_Bool_Exp>;
};

export type Query_RootMembers_Export_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootMembers_GroupArgs = {
  distinct_on?: InputMaybe<Array<Members_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Group_Order_By>>;
  where?: InputMaybe<Members_Group_Bool_Exp>;
};

export type Query_RootMembers_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootMembers_MemberArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_Order_By>>;
  where?: InputMaybe<Members_Member_Bool_Exp>;
};

export type Query_RootMembers_Member_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_Order_By>>;
  where?: InputMaybe<Members_Member_Bool_Exp>;
};

export type Query_RootMembers_Member_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootMembers_Member_In_GroupArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_In_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_In_Group_Order_By>>;
  where?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
};

export type Query_RootMembers_Member_In_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootMembers_Team_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Members_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
};

export type Query_RootMembers_Team_Official_Reporter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Members_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
};

export type Query_RootMembers_Team_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootMenusMenuArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_RootMenusMenuItemArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_RootMenusMenuItemsArgs = {
  filters?: InputMaybe<MenusMenuItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootMenusMenusArgs = {
  filters?: InputMaybe<MenusMenuFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootNews_NewsArgs = {
  distinct_on?: InputMaybe<Array<News_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<News_News_Order_By>>;
  where?: InputMaybe<News_News_Bool_Exp>;
};

export type Query_RootNews_News_AggregateArgs = {
  distinct_on?: InputMaybe<Array<News_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<News_News_Order_By>>;
  where?: InputMaybe<News_News_Bool_Exp>;
};

export type Query_RootNews_News_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootNotifications_EndpointArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Endpoint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Endpoint_Order_By>>;
  where?: InputMaybe<Notifications_Endpoint_Bool_Exp>;
};

export type Query_RootNotifications_Endpoint_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPagesChHomeArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type Query_RootPagesClubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_RootPagesClubsArgs = {
  filters?: InputMaybe<PagesClubFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootPagesHomeArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  publicationState?: InputMaybe<PublicationState>;
};

export type Query_RootPagesLandingArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type Query_RootPagesLandingsArgs = {
  filters?: InputMaybe<PagesLandingFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootPagesLiveArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type Query_RootPagesLivesArgs = {
  filters?: InputMaybe<PagesLiveFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootPagesSportArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type Query_RootPagesSportsArgs = {
  filters?: InputMaybe<PagesSportFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootPermissions_Get_Permissions_For_RoleArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPermissions_Member_PermissionArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Member_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Member_Permission_Order_By>>;
  where?: InputMaybe<Permissions_Member_Permission_Bool_Exp>;
};

export type Query_RootPermissions_Member_Permission_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPermissions_Member_RoleArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Member_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Member_Role_Order_By>>;
  where?: InputMaybe<Permissions_Member_Role_Bool_Exp>;
};

export type Query_RootPermissions_Member_Role_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPermissions_RoleArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Role_Order_By>>;
  where?: InputMaybe<Permissions_Role_Bool_Exp>;
};

export type Query_RootPermissions_Role_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPermissions_Role_PermissionArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Role_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Role_Permission_Order_By>>;
  where?: InputMaybe<Permissions_Role_Permission_Bool_Exp>;
};

export type Query_RootPermissions_Role_Permission_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPlaces_CityArgs = {
  distinct_on?: InputMaybe<Array<Places_City_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_City_Order_By>>;
  where?: InputMaybe<Places_City_Bool_Exp>;
};

export type Query_RootPlaces_City_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPlaces_CountryArgs = {
  distinct_on?: InputMaybe<Array<Places_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Country_Order_By>>;
  where?: InputMaybe<Places_Country_Bool_Exp>;
};

export type Query_RootPlaces_Country_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPlaces_DepartmentArgs = {
  distinct_on?: InputMaybe<Array<Places_Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Department_Order_By>>;
  where?: InputMaybe<Places_Department_Bool_Exp>;
};

export type Query_RootPlaces_Department_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPlaces_EquipmentArgs = {
  distinct_on?: InputMaybe<Array<Places_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Equipment_Order_By>>;
  where?: InputMaybe<Places_Equipment_Bool_Exp>;
};

export type Query_RootPlaces_Equipment_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPlaces_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Places_Place_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Place_Order_By>>;
  where?: InputMaybe<Places_Place_Bool_Exp>;
};

export type Query_RootPlaces_Place_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPlaces_RegionArgs = {
  distinct_on?: InputMaybe<Array<Places_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Region_Order_By>>;
  where?: InputMaybe<Places_Region_Bool_Exp>;
};

export type Query_RootPlaces_Region_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootScraping_Championships_ClubchampionshipexceptionArgs = {
  distinct_on?: InputMaybe<
    Array<Scraping_Championships_Clubchampionshipexception_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Scraping_Championships_Clubchampionshipexception_Order_By>
  >;
  where?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Bool_Exp>;
};

export type Query_RootScraping_Championships_Clubchampionshipexception_By_PkArgs =
  {
    id: Scalars['Int']['input'];
  };

export type Query_RootScraping_Championships_Clubchampionshipexception_ChampionshipsArgs =
  {
    distinct_on?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Championships_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Championships_Order_By>
    >;
    where?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp>;
  };

export type Query_RootSms_Club_Sms_CreditArgs = {
  distinct_on?: InputMaybe<Array<Sms_Club_Sms_Credit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sms_Club_Sms_Credit_Order_By>>;
  where?: InputMaybe<Sms_Club_Sms_Credit_Bool_Exp>;
};

export type Query_RootSms_Club_Sms_Credit_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootSms_EventArgs = {
  distinct_on?: InputMaybe<Array<Sms_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sms_Event_Order_By>>;
  where?: InputMaybe<Sms_Event_Bool_Exp>;
};

export type Query_RootSms_Event_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootSms_MessageArgs = {
  distinct_on?: InputMaybe<Array<Sms_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sms_Message_Order_By>>;
  where?: InputMaybe<Sms_Message_Bool_Exp>;
};

export type Query_RootSms_Message_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootSocials_FacebookArgs = {
  distinct_on?: InputMaybe<Array<Socials_Facebook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Socials_Facebook_Order_By>>;
  where?: InputMaybe<Socials_Facebook_Bool_Exp>;
};

export type Query_RootSocials_Facebook_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootSocials_PostArgs = {
  distinct_on?: InputMaybe<Array<Socials_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Socials_Post_Order_By>>;
  where?: InputMaybe<Socials_Post_Bool_Exp>;
};

export type Query_RootSocials_Post_By_PkArgs = {
  post_id: Scalars['String']['input'];
};

export type Query_RootSponsors_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Category_Order_By>>;
  where?: InputMaybe<Sponsors_Category_Bool_Exp>;
};

export type Query_RootSponsors_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Category_Order_By>>;
  where?: InputMaybe<Sponsors_Category_Bool_Exp>;
};

export type Query_RootSponsors_Category_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootSponsors_SponsorArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Sponsor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Sponsor_Order_By>>;
  where?: InputMaybe<Sponsors_Sponsor_Bool_Exp>;
};

export type Query_RootSponsors_Sponsor_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootSponsors_Team_SponsorArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Team_Sponsor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Team_Sponsor_Order_By>>;
  where?: InputMaybe<Sponsors_Team_Sponsor_Bool_Exp>;
};

export type Query_RootSponsors_Team_Sponsor_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootStats_ActionArgs = {
  distinct_on?: InputMaybe<Array<Stats_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stats_Action_Order_By>>;
  where?: InputMaybe<Stats_Action_Bool_Exp>;
};

export type Query_RootStats_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stats_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stats_Action_Order_By>>;
  where?: InputMaybe<Stats_Action_Bool_Exp>;
};

export type Query_RootStats_Action_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootStats_After_Match_GraphArgs = {
  live_id: Scalars['Int']['input'];
};

export type Query_RootStats_After_Match_Passe_DirectionArgs = {
  live_id: Scalars['Int']['input'];
};

export type Query_RootStats_After_Match_ZoneArgs = {
  live_id: Scalars['Int']['input'];
};

export type Query_RootStats_Collective_StatisticsArgs = {
  live_id: Scalars['Int']['input'];
};

export type Query_RootTasks_Candidate_GroupArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Candidate_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Candidate_Group_Order_By>>;
  where?: InputMaybe<Tasks_Candidate_Group_Bool_Exp>;
};

export type Query_RootTasks_Candidate_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTasks_Candidate_TeamArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Candidate_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Candidate_Team_Order_By>>;
  where?: InputMaybe<Tasks_Candidate_Team_Bool_Exp>;
};

export type Query_RootTasks_Candidate_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTasks_JobArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Job_Order_By>>;
  where?: InputMaybe<Tasks_Job_Bool_Exp>;
};

export type Query_RootTasks_Job_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTasks_Member_Task_CountArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Member_Task_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Member_Task_Count_Order_By>>;
  where?: InputMaybe<Tasks_Member_Task_Count_Bool_Exp>;
};

export type Query_RootTasks_Member_Task_Count_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTasks_TaskArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Task_Order_By>>;
  where?: InputMaybe<Tasks_Task_Bool_Exp>;
};

export type Query_RootTasks_Task_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Task_Order_By>>;
  where?: InputMaybe<Tasks_Task_Bool_Exp>;
};

export type Query_RootTasks_Task_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTasks_Team_TaskArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Team_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Team_Task_Order_By>>;
  where?: InputMaybe<Tasks_Team_Task_Bool_Exp>;
};

export type Query_RootTasks_Team_Task_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTasks_VolunteerArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Volunteer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Volunteer_Order_By>>;
  where?: InputMaybe<Tasks_Volunteer_Bool_Exp>;
};

export type Query_RootTasks_Volunteer_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeam_Detail_By_Pk_DeprecatedArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Authorized_GroupArgs = {
  distinct_on?: InputMaybe<Array<Teams_Authorized_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Authorized_Group_Order_By>>;
  where?: InputMaybe<Teams_Authorized_Group_Bool_Exp>;
};

export type Query_RootTeams_Authorized_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Authorized_TeamArgs = {
  distinct_on?: InputMaybe<Array<Teams_Authorized_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Authorized_Team_Order_By>>;
  where?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
};

export type Query_RootTeams_Authorized_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Club_GroupArgs = {
  distinct_on?: InputMaybe<Array<Teams_Club_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Club_Group_Order_By>>;
  where?: InputMaybe<Teams_Club_Group_Bool_Exp>;
};

export type Query_RootTeams_Club_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_FanArgs = {
  distinct_on?: InputMaybe<Array<Teams_Fan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Fan_Order_By>>;
  where?: InputMaybe<Teams_Fan_Bool_Exp>;
};

export type Query_RootTeams_Fan_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_LevelArgs = {
  distinct_on?: InputMaybe<Array<Teams_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Level_Order_By>>;
  where?: InputMaybe<Teams_Level_Bool_Exp>;
};

export type Query_RootTeams_Level_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_OfficialArgs = {
  distinct_on?: InputMaybe<Array<Teams_Official_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Official_Order_By>>;
  where?: InputMaybe<Teams_Official_Bool_Exp>;
};

export type Query_RootTeams_Official_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_TeamArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Order_By>>;
  where?: InputMaybe<Teams_Team_Bool_Exp>;
};

export type Query_RootTeams_Team_Action_PermissionArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Action_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Action_Permission_Order_By>>;
  where?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
};

export type Query_RootTeams_Team_Action_Permission_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Team_DetailArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Query_RootTeams_Team_Detail_By_Club_IdArgs = {
  args: Teams_Team_Detail_By_Club_Id_Args;
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Query_RootTeams_Team_Detail_By_Member_IdArgs = {
  args: Teams_Team_Detail_By_Member_Id_Args;
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Query_RootTeams_Team_Detail_By_PkArgs = {
  args: Teams_Team_Detail_By_Pk_Args;
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Query_RootTeams_Team_Detail_By_Team_IdArgs = {
  args: Teams_Team_Detail_By_Team_Id_Args;
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Query_RootTeams_Team_Detail_SuggestedArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Query_RootTeams_Team_Has_FansArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Has_Fans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Has_Fans_Order_By>>;
  where?: InputMaybe<Teams_Team_Has_Fans_Bool_Exp>;
};

export type Query_RootTeams_Team_Has_Fans_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Team_In_SeasonArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_In_Season_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
};

export type Query_RootTeams_Team_In_Season_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Team_In_Season_Has_MemberArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Has_Member_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

export type Query_RootTeams_Team_In_Season_Has_Member_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Has_Member_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

export type Query_RootTeams_Team_In_Season_Has_Member_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Team_In_Season_TranslationArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Translation_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Translation_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Translation_Bool_Exp>;
};

export type Query_RootTeams_Team_In_Season_Translation_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTeams_Team_OfficialArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Official_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Official_Order_By>>;
  where?: InputMaybe<Teams_Team_Official_Bool_Exp>;
};

export type Query_RootTeams_Team_Official_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_ChallengeArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Challenge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Challenge_Order_By>>;
  where?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
};

export type Query_RootTournaments_Challenge_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_Challenge_RuleArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Challenge_Rule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Challenge_Rule_Order_By>>;
  where?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
};

export type Query_RootTournaments_Challenge_Rule_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_EngagementArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Engagement_Order_By>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

export type Query_RootTournaments_Engagement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Engagement_Order_By>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

export type Query_RootTournaments_Engagement_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_GameArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Game_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Game_Order_By>>;
  where?: InputMaybe<Tournaments_Game_Bool_Exp>;
};

export type Query_RootTournaments_Game_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_Games_In_PhaseArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Games_In_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Games_In_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Games_In_Phase_Bool_Exp>;
};

export type Query_RootTournaments_New_HitArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTournaments_Official_Reporter_V2Args = {
  distinct_on?: InputMaybe<
    Array<Tournaments_Official_Reporter_V2_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Official_Reporter_V2_Order_By>>;
  where?: InputMaybe<Tournaments_Official_Reporter_V2_Bool_Exp>;
};

export type Query_RootTournaments_Official_Reporter_V2_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_PhaseArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Phase_Bool_Exp>;
};

export type Query_RootTournaments_Phase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Phase_Bool_Exp>;
};

export type Query_RootTournaments_Phase_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Place_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Place_Order_By>>;
  where?: InputMaybe<Tournaments_Place_Bool_Exp>;
};

export type Query_RootTournaments_Place_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_RankingArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Ranking_Order_By>>;
  where?: InputMaybe<Tournaments_Ranking_Bool_Exp>;
};

export type Query_RootTournaments_Ranking_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_Ranking_PointsArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Ranking_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Ranking_Points_Order_By>>;
  where?: InputMaybe<Tournaments_Ranking_Points_Bool_Exp>;
};

export type Query_RootTournaments_Ranking_Points_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_Ranking_RuleArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Ranking_Rule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Ranking_Rule_Order_By>>;
  where?: InputMaybe<Tournaments_Ranking_Rule_Bool_Exp>;
};

export type Query_RootTournaments_Ranking_Rule_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_TeamArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Team_Order_By>>;
  where?: InputMaybe<Tournaments_Team_Bool_Exp>;
};

export type Query_RootTournaments_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_TournamentArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Tournament_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Tournament_Order_By>>;
  where?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
};

export type Query_RootTournaments_Tournament_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootTournaments_Tournament_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Tournament_Place_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Tournament_Place_Order_By>>;
  where?: InputMaybe<Tournaments_Tournament_Place_Bool_Exp>;
};

export type Query_RootUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_RootUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Query_RootUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Query_RootUrls_Club_By_Direct_Score_UrlArgs = {
  args: Urls_Club_By_Direct_Score_Url_Args;
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Query_RootUrls_Event_By_Direct_Score_Same_Teams_UrlArgs = {
  args: Urls_Event_By_Direct_Score_Same_Teams_Url_Args;
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Query_RootUrls_Event_By_Direct_Score_UrlArgs = {
  args: Urls_Event_By_Direct_Score_Url_Args;
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Query_RootUrls_Team_In_Season_By_Direct_Score_UrlArgs = {
  args: Urls_Team_In_Season_By_Direct_Score_Url_Args;
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Query_RootUrls_Url_DetailArgs = {
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Query_RootUrls_Url_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootUsers_Convocation_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Users_Convocation_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Convocation_Progress_Order_By>>;
  where?: InputMaybe<Users_Convocation_Progress_Bool_Exp>;
};

export type Query_RootUsers_Convocation_Progress_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootUsers_Member_InvitationArgs = {
  distinct_on?: InputMaybe<Array<Users_Member_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Member_Invitation_Order_By>>;
  where?: InputMaybe<Users_Member_Invitation_Bool_Exp>;
};

export type Query_RootUsers_Member_Invitation_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootUsers_UserArgs = {
  distinct_on?: InputMaybe<Array<Users_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_User_Order_By>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};

export type Query_RootUsers_User_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootWidgets_Club_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Club_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Club_Settings_Order_By>>;
  where?: InputMaybe<Widgets_Club_Settings_Bool_Exp>;
};

export type Query_RootWidgets_Club_Settings_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootWidgets_Convocation_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Convocation_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Convocation_Settings_Order_By>>;
  where?: InputMaybe<Widgets_Convocation_Settings_Bool_Exp>;
};

export type Query_RootWidgets_Convocation_Settings_By_PkArgs = {
  widget_id: Scalars['Int']['input'];
};

export type Query_RootWidgets_WidgetArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Bool_Exp>;
};

export type Query_RootWidgets_Widget_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootWidgets_Widget_Has_CompetitionArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Has_Competition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Has_Competition_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Has_Competition_Bool_Exp>;
};

export type Query_RootWidgets_Widget_Has_Competition_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootWidgets_Widget_Has_TeamArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Has_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Has_Team_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Has_Team_Bool_Exp>;
};

export type Query_RootWidgets_Widget_Has_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootWidgets_Widget_LiveArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Live_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Live_Bool_Exp>;
};

export type Query_RootWidgets_Widget_Live_By_PkArgs = {
  widget_ptr_id: Scalars['Int']['input'];
};

export type Query_RootWidgets_Widget_TeamArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Team_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Team_Bool_Exp>;
};

export type Query_RootWidgets_Widget_Team_By_PkArgs = {
  widget_ptr_id: Scalars['Int']['input'];
};

export type Rankings_Competitions_Competition_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Rankings_Competitions_Competition_Detail_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "scraping.championships_clubchampionshipexception" */
export type Scraping_Championships_Clubchampionshipexception = {
  __typename: 'scraping_championships_clubchampionshipexception';
  /** An array relationship */
  championships?: Maybe<
    Array<Scraping_Championships_Clubchampionshipexception_Championships>
  >;
  club_id?: Maybe<Scalars['Int']['output']>;
  external_source_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

/** columns and relationships of "scraping.championships_clubchampionshipexception" */
export type Scraping_Championships_ClubchampionshipexceptionChampionshipsArgs =
  {
    distinct_on?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Championships_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Championships_Order_By>
    >;
    where?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp>;
  };

/** Boolean expression to filter rows from the table "scraping.championships_clubchampionshipexception". All fields are combined with a logical 'AND'. */
export type Scraping_Championships_Clubchampionshipexception_Bool_Exp = {
  _and?: InputMaybe<
    Array<Scraping_Championships_Clubchampionshipexception_Bool_Exp>
  >;
  _not?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Bool_Exp>;
  _or?: InputMaybe<
    Array<Scraping_Championships_Clubchampionshipexception_Bool_Exp>
  >;
  championships?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  external_source_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships = {
  __typename: 'scraping_championships_clubchampionshipexception_championships';
  championship_id?: Maybe<Scalars['Int']['output']>;
  clubchampionshipexception_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  exception?: Maybe<Scraping_Championships_Clubchampionshipexception>;
};

/** order by aggregate values of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Aggregate_Order_By =
  {
    avg?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Avg_Order_By>;
    count?: InputMaybe<Order_By>;
    max?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Max_Order_By>;
    min?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Min_Order_By>;
    stddev?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Stddev_Order_By>;
    stddev_pop?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Stddev_Pop_Order_By>;
    stddev_samp?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Stddev_Samp_Order_By>;
    sum?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Sum_Order_By>;
    var_pop?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Var_Pop_Order_By>;
    var_samp?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Var_Samp_Order_By>;
    variance?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Variance_Order_By>;
  };

/** order by avg() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Avg_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** Boolean expression to filter rows from the table "scraping.championships_clubchampionshipexception_championships". All fields are combined with a logical 'AND'. */
export type Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp =
  {
    _and?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp>
    >;
    _not?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp>;
    _or?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp>
    >;
    championship_id?: InputMaybe<Int_Comparison_Exp>;
    clubchampionshipexception_id?: InputMaybe<Int_Comparison_Exp>;
    exception?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Bool_Exp>;
  };

/** order by max() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Max_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** order by min() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Min_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** Ordering options when selecting data from "scraping.championships_clubchampionshipexception_championships". */
export type Scraping_Championships_Clubchampionshipexception_Championships_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
    exception?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Order_By>;
  };

/** select columns of table "scraping.championships_clubchampionshipexception_championships" */
export enum Scraping_Championships_Clubchampionshipexception_Championships_Select_Column {
  /** column name */
  ChampionshipId = 'championship_id',
  /** column name */
  ClubchampionshipexceptionId = 'clubchampionshipexception_id',
}

/** order by stddev() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Stddev_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** order by stddev_pop() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Stddev_Pop_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** order by stddev_samp() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Stddev_Samp_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** Streaming cursor of the table "scraping_championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Stream_Cursor_Input =
  {
    /** Stream column input with initial value */
    initial_value: Scraping_Championships_Clubchampionshipexception_Championships_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
  };

/** Initial value of the column from where the streaming should start */
export type Scraping_Championships_Clubchampionshipexception_Championships_Stream_Cursor_Value_Input =
  {
    championship_id?: InputMaybe<Scalars['Int']['input']>;
    clubchampionshipexception_id?: InputMaybe<Scalars['Int']['input']>;
  };

/** order by sum() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Sum_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** order by var_pop() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Var_Pop_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** order by var_samp() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Var_Samp_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** order by variance() on columns of table "scraping.championships_clubchampionshipexception_championships" */
export type Scraping_Championships_Clubchampionshipexception_Championships_Variance_Order_By =
  {
    championship_id?: InputMaybe<Order_By>;
    clubchampionshipexception_id?: InputMaybe<Order_By>;
  };

/** Ordering options when selecting data from "scraping.championships_clubchampionshipexception". */
export type Scraping_Championships_Clubchampionshipexception_Order_By = {
  championships_aggregate?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  external_source_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "scraping.championships_clubchampionshipexception" */
export enum Scraping_Championships_Clubchampionshipexception_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  ExternalSourceId = 'external_source_id',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "scraping_championships_clubchampionshipexception" */
export type Scraping_Championships_Clubchampionshipexception_Stream_Cursor_Input =
  {
    /** Stream column input with initial value */
    initial_value: Scraping_Championships_Clubchampionshipexception_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
  };

/** Initial value of the column from where the streaming should start */
export type Scraping_Championships_Clubchampionshipexception_Stream_Cursor_Value_Input =
  {
    club_id?: InputMaybe<Scalars['Int']['input']>;
    external_source_id?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['Int']['input']>;
  };

export type Scraping_Get_New_Competitions_Mutation_Response = {
  __typename: 'scraping_get_new_competitions_mutation_response';
  scraping_launched?: Maybe<Scalars['smallint']['output']>;
};

export type Scraping_Get_New_Rounds_Mutation_Response = {
  __typename: 'scraping_get_new_rounds_mutation_response';
  scraping_launched?: Maybe<Scalars['smallint']['output']>;
};

export type Scraping_Launched_Mutation_Response = {
  __typename: 'scraping_launched_mutation_response';
  scraping_launched?: Maybe<Scalars['smallint']['output']>;
};

export type Scraping_Synchronize_Competition_Mutation_Response = {
  __typename: 'scraping_synchronize_competition_mutation_response';
  scraping_launched?: Maybe<Scalars['smallint']['output']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']['input']>;
  _gt?: InputMaybe<Scalars['smallint']['input']>;
  _gte?: InputMaybe<Scalars['smallint']['input']>;
  _in?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['smallint']['input']>;
  _lte?: InputMaybe<Scalars['smallint']['input']>;
  _neq?: InputMaybe<Scalars['smallint']['input']>;
  _nin?: InputMaybe<Array<Scalars['smallint']['input']>>;
};

/** columns and relationships of "sms.club_sms_credit" */
export type Sms_Club_Sms_Credit = {
  __typename: 'sms_club_sms_credit';
  club_id?: Maybe<Scalars['Int']['output']>;
  credit?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  total_sent?: Maybe<Scalars['Int']['output']>;
  unlimited?: Maybe<Scalars['Boolean']['output']>;
};

/** Boolean expression to filter rows from the table "sms.club_sms_credit". All fields are combined with a logical 'AND'. */
export type Sms_Club_Sms_Credit_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Club_Sms_Credit_Bool_Exp>>;
  _not?: InputMaybe<Sms_Club_Sms_Credit_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Club_Sms_Credit_Bool_Exp>>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  credit?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  total_sent?: InputMaybe<Int_Comparison_Exp>;
  unlimited?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "sms.club_sms_credit". */
export type Sms_Club_Sms_Credit_Order_By = {
  club_id?: InputMaybe<Order_By>;
  credit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_sent?: InputMaybe<Order_By>;
  unlimited?: InputMaybe<Order_By>;
};

/** select columns of table "sms.club_sms_credit" */
export enum Sms_Club_Sms_Credit_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Credit = 'credit',
  /** column name */
  Id = 'id',
  /** column name */
  TotalSent = 'total_sent',
  /** column name */
  Unlimited = 'unlimited',
}

/** Streaming cursor of the table "sms_club_sms_credit" */
export type Sms_Club_Sms_Credit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Club_Sms_Credit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Club_Sms_Credit_Stream_Cursor_Value_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  credit?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  total_sent?: InputMaybe<Scalars['Int']['input']>;
  unlimited?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "sms.event" */
export type Sms_Event = {
  __typename: 'sms_event';
  date?: Maybe<Scalars['timestamptz']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  message_id?: Maybe<Scalars['Int']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "sms.event" */
export type Sms_Event_Aggregate_Order_By = {
  avg?: InputMaybe<Sms_Event_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sms_Event_Max_Order_By>;
  min?: InputMaybe<Sms_Event_Min_Order_By>;
  stddev?: InputMaybe<Sms_Event_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sms_Event_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sms_Event_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sms_Event_Sum_Order_By>;
  var_pop?: InputMaybe<Sms_Event_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sms_Event_Var_Samp_Order_By>;
  variance?: InputMaybe<Sms_Event_Variance_Order_By>;
};

/** order by avg() on columns of table "sms.event" */
export type Sms_Event_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sms.event". All fields are combined with a logical 'AND'. */
export type Sms_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Event_Bool_Exp>>;
  _not?: InputMaybe<Sms_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Event_Bool_Exp>>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message_id?: InputMaybe<Int_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "sms.event" */
export type Sms_Event_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "sms.event" */
export type Sms_Event_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "sms.event". */
export type Sms_Event_Order_By = {
  date?: InputMaybe<Order_By>;
  event?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
};

/** select columns of table "sms.event" */
export enum Sms_Event_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  PhoneNumber = 'phone_number',
}

/** order by stddev() on columns of table "sms.event" */
export type Sms_Event_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "sms.event" */
export type Sms_Event_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "sms.event" */
export type Sms_Event_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sms_event" */
export type Sms_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Event_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message_id?: InputMaybe<Scalars['Int']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "sms.event" */
export type Sms_Event_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "sms.event" */
export type Sms_Event_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "sms.event" */
export type Sms_Event_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "sms.event" */
export type Sms_Event_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  message_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "sms.message" */
export type Sms_Message = {
  __typename: 'sms_message';
  /** An array relationship */
  events?: Maybe<Array<Sms_Event>>;
  has_error?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
};

/** columns and relationships of "sms.message" */
export type Sms_MessageEventsArgs = {
  distinct_on?: InputMaybe<Array<Sms_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sms_Event_Order_By>>;
  where?: InputMaybe<Sms_Event_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "sms.message". All fields are combined with a logical 'AND'. */
export type Sms_Message_Bool_Exp = {
  _and?: InputMaybe<Array<Sms_Message_Bool_Exp>>;
  _not?: InputMaybe<Sms_Message_Bool_Exp>;
  _or?: InputMaybe<Array<Sms_Message_Bool_Exp>>;
  events?: InputMaybe<Sms_Event_Bool_Exp>;
  has_error?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "sms.message". */
export type Sms_Message_Order_By = {
  events_aggregate?: InputMaybe<Sms_Event_Aggregate_Order_By>;
  has_error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "sms.message" */
export enum Sms_Message_Select_Column {
  /** column name */
  HasError = 'has_error',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "sms_message" */
export type Sms_Message_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sms_Message_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sms_Message_Stream_Cursor_Value_Input = {
  has_error?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "socials.facebook" */
export type Socials_Facebook = {
  __typename: 'socials_facebook';
  club_id?: Maybe<Scalars['Int']['output']>;
  feed_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  page?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "socials.facebook". All fields are combined with a logical 'AND'. */
export type Socials_Facebook_Bool_Exp = {
  _and?: InputMaybe<Array<Socials_Facebook_Bool_Exp>>;
  _not?: InputMaybe<Socials_Facebook_Bool_Exp>;
  _or?: InputMaybe<Array<Socials_Facebook_Bool_Exp>>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  feed_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  page?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "socials.facebook". */
export type Socials_Facebook_Order_By = {
  club_id?: InputMaybe<Order_By>;
  feed_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  page?: InputMaybe<Order_By>;
};

/** select columns of table "socials.facebook" */
export enum Socials_Facebook_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  FeedId = 'feed_id',
  /** column name */
  Id = 'id',
  /** column name */
  Page = 'page',
}

/** Streaming cursor of the table "socials_facebook" */
export type Socials_Facebook_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Socials_Facebook_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Socials_Facebook_Stream_Cursor_Value_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  feed_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "socials.post" */
export type Socials_Post = {
  __typename: 'socials_post';
  creation_index?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  facebook?: Maybe<Socials_Facebook>;
  feed_id?: Maybe<Scalars['String']['output']>;
  image_height?: Maybe<Scalars['Int']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  image_width?: Maybe<Scalars['Int']['output']>;
  post_additional?: Maybe<Scalars['jsonb']['output']>;
  post_id?: Maybe<Scalars['String']['output']>;
  post_permalink?: Maybe<Scalars['String']['output']>;
  post_status?: Maybe<Scalars['String']['output']>;
  post_text?: Maybe<Scalars['String']['output']>;
  post_timestamp?: Maybe<Scalars['Int']['output']>;
  post_type?: Maybe<Scalars['String']['output']>;
  user_counts_followed_by?: Maybe<Scalars['Int']['output']>;
  user_counts_follows?: Maybe<Scalars['Int']['output']>;
  user_counts_media?: Maybe<Scalars['Int']['output']>;
  user_link?: Maybe<Scalars['String']['output']>;
  user_nickname?: Maybe<Scalars['String']['output']>;
  user_pic?: Maybe<Scalars['String']['output']>;
  user_screenname?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "socials.post" */
export type Socials_PostPost_AdditionalArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "socials.post". All fields are combined with a logical 'AND'. */
export type Socials_Post_Bool_Exp = {
  _and?: InputMaybe<Array<Socials_Post_Bool_Exp>>;
  _not?: InputMaybe<Socials_Post_Bool_Exp>;
  _or?: InputMaybe<Array<Socials_Post_Bool_Exp>>;
  creation_index?: InputMaybe<Int_Comparison_Exp>;
  facebook?: InputMaybe<Socials_Facebook_Bool_Exp>;
  feed_id?: InputMaybe<String_Comparison_Exp>;
  image_height?: InputMaybe<Int_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  image_width?: InputMaybe<Int_Comparison_Exp>;
  post_additional?: InputMaybe<Jsonb_Comparison_Exp>;
  post_id?: InputMaybe<String_Comparison_Exp>;
  post_permalink?: InputMaybe<String_Comparison_Exp>;
  post_status?: InputMaybe<String_Comparison_Exp>;
  post_text?: InputMaybe<String_Comparison_Exp>;
  post_timestamp?: InputMaybe<Int_Comparison_Exp>;
  post_type?: InputMaybe<String_Comparison_Exp>;
  user_counts_followed_by?: InputMaybe<Int_Comparison_Exp>;
  user_counts_follows?: InputMaybe<Int_Comparison_Exp>;
  user_counts_media?: InputMaybe<Int_Comparison_Exp>;
  user_link?: InputMaybe<String_Comparison_Exp>;
  user_nickname?: InputMaybe<String_Comparison_Exp>;
  user_pic?: InputMaybe<String_Comparison_Exp>;
  user_screenname?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "socials.post". */
export type Socials_Post_Order_By = {
  creation_index?: InputMaybe<Order_By>;
  facebook?: InputMaybe<Socials_Facebook_Order_By>;
  feed_id?: InputMaybe<Order_By>;
  image_height?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  image_width?: InputMaybe<Order_By>;
  post_additional?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  post_permalink?: InputMaybe<Order_By>;
  post_status?: InputMaybe<Order_By>;
  post_text?: InputMaybe<Order_By>;
  post_timestamp?: InputMaybe<Order_By>;
  post_type?: InputMaybe<Order_By>;
  user_counts_followed_by?: InputMaybe<Order_By>;
  user_counts_follows?: InputMaybe<Order_By>;
  user_counts_media?: InputMaybe<Order_By>;
  user_link?: InputMaybe<Order_By>;
  user_nickname?: InputMaybe<Order_By>;
  user_pic?: InputMaybe<Order_By>;
  user_screenname?: InputMaybe<Order_By>;
};

/** select columns of table "socials.post" */
export enum Socials_Post_Select_Column {
  /** column name */
  CreationIndex = 'creation_index',
  /** column name */
  FeedId = 'feed_id',
  /** column name */
  ImageHeight = 'image_height',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  ImageWidth = 'image_width',
  /** column name */
  PostAdditional = 'post_additional',
  /** column name */
  PostId = 'post_id',
  /** column name */
  PostPermalink = 'post_permalink',
  /** column name */
  PostStatus = 'post_status',
  /** column name */
  PostText = 'post_text',
  /** column name */
  PostTimestamp = 'post_timestamp',
  /** column name */
  PostType = 'post_type',
  /** column name */
  UserCountsFollowedBy = 'user_counts_followed_by',
  /** column name */
  UserCountsFollows = 'user_counts_follows',
  /** column name */
  UserCountsMedia = 'user_counts_media',
  /** column name */
  UserLink = 'user_link',
  /** column name */
  UserNickname = 'user_nickname',
  /** column name */
  UserPic = 'user_pic',
  /** column name */
  UserScreenname = 'user_screenname',
}

/** Streaming cursor of the table "socials_post" */
export type Socials_Post_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Socials_Post_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Socials_Post_Stream_Cursor_Value_Input = {
  creation_index?: InputMaybe<Scalars['Int']['input']>;
  feed_id?: InputMaybe<Scalars['String']['input']>;
  image_height?: InputMaybe<Scalars['Int']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  image_width?: InputMaybe<Scalars['Int']['input']>;
  post_additional?: InputMaybe<Scalars['jsonb']['input']>;
  post_id?: InputMaybe<Scalars['String']['input']>;
  post_permalink?: InputMaybe<Scalars['String']['input']>;
  post_status?: InputMaybe<Scalars['String']['input']>;
  post_text?: InputMaybe<Scalars['String']['input']>;
  post_timestamp?: InputMaybe<Scalars['Int']['input']>;
  post_type?: InputMaybe<Scalars['String']['input']>;
  user_counts_followed_by?: InputMaybe<Scalars['Int']['input']>;
  user_counts_follows?: InputMaybe<Scalars['Int']['input']>;
  user_counts_media?: InputMaybe<Scalars['Int']['input']>;
  user_link?: InputMaybe<Scalars['String']['input']>;
  user_nickname?: InputMaybe<Scalars['String']['input']>;
  user_pic?: InputMaybe<Scalars['String']['input']>;
  user_screenname?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "sponsors.category" */
export type Sponsors_Category = {
  __typename: 'sponsors_category';
  /** An array relationship */
  admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  sponsors?: Maybe<Array<Sponsors_Sponsor>>;
};

/** columns and relationships of "sponsors.category" */
export type Sponsors_CategoryAdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "sponsors.category" */
export type Sponsors_CategorySponsorsArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Sponsor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Sponsor_Order_By>>;
  where?: InputMaybe<Sponsors_Sponsor_Bool_Exp>;
};

/** aggregated selection of "sponsors.category" */
export type Sponsors_Category_Aggregate = {
  __typename: 'sponsors_category_aggregate';
  aggregate?: Maybe<Sponsors_Category_Aggregate_Fields>;
  nodes?: Maybe<Array<Sponsors_Category>>;
};

/** aggregate fields of "sponsors.category" */
export type Sponsors_Category_Aggregate_Fields = {
  __typename: 'sponsors_category_aggregate_fields';
  avg?: Maybe<Sponsors_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Sponsors_Category_Max_Fields>;
  min?: Maybe<Sponsors_Category_Min_Fields>;
  stddev?: Maybe<Sponsors_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Sponsors_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sponsors_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Sponsors_Category_Sum_Fields>;
  var_pop?: Maybe<Sponsors_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Sponsors_Category_Var_Samp_Fields>;
  variance?: Maybe<Sponsors_Category_Variance_Fields>;
};

/** aggregate fields of "sponsors.category" */
export type Sponsors_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sponsors_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Sponsors_Category_Avg_Fields = {
  __typename: 'sponsors_category_avg_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sponsors.category". All fields are combined with a logical 'AND'. */
export type Sponsors_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Sponsors_Category_Bool_Exp>>;
  _not?: InputMaybe<Sponsors_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Sponsors_Category_Bool_Exp>>;
  admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  sponsors?: InputMaybe<Sponsors_Sponsor_Bool_Exp>;
};

/** unique or primary key constraints on table "sponsors.category" */
export enum Sponsors_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  CategoryPkey = 'category_pkey',
}

/** input type for incrementing numeric columns in table "sponsors.category" */
export type Sponsors_Category_Inc_Input = {
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "sponsors.category" */
export type Sponsors_Category_Insert_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  sponsors?: InputMaybe<Sponsors_Sponsor_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Sponsors_Category_Max_Fields = {
  __typename: 'sponsors_category_max_fields';
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** aggregate min on columns */
export type Sponsors_Category_Min_Fields = {
  __typename: 'sponsors_category_min_fields';
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** response of any mutation on the table "sponsors.category" */
export type Sponsors_Category_Mutation_Response = {
  __typename: 'sponsors_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Sponsors_Category>>;
};

/** input type for inserting object relation for remote table "sponsors.category" */
export type Sponsors_Category_Obj_Rel_Insert_Input = {
  data: Sponsors_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sponsors_Category_On_Conflict>;
};

/** on_conflict condition type for table "sponsors.category" */
export type Sponsors_Category_On_Conflict = {
  constraint: Sponsors_Category_Constraint;
  update_columns?: Array<Sponsors_Category_Update_Column>;
  where?: InputMaybe<Sponsors_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "sponsors.category". */
export type Sponsors_Category_Order_By = {
  admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  sponsors_aggregate?: InputMaybe<Sponsors_Sponsor_Aggregate_Order_By>;
};

/** primary key columns input for table: sponsors.category */
export type Sponsors_Category_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "sponsors.category" */
export enum Sponsors_Category_Select_Column {
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
}

/** input type for updating data in table "sponsors.category" */
export type Sponsors_Category_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate stddev on columns */
export type Sponsors_Category_Stddev_Fields = {
  __typename: 'sponsors_category_stddev_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Sponsors_Category_Stddev_Pop_Fields = {
  __typename: 'sponsors_category_stddev_pop_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Sponsors_Category_Stddev_Samp_Fields = {
  __typename: 'sponsors_category_stddev_samp_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "sponsors_category" */
export type Sponsors_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sponsors_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sponsors_Category_Stream_Cursor_Value_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate sum on columns */
export type Sponsors_Category_Sum_Fields = {
  __typename: 'sponsors_category_sum_fields';
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** update columns of table "sponsors.category" */
export enum Sponsors_Category_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
}

export type Sponsors_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sponsors_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sponsors_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sponsors_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sponsors_Category_Var_Pop_Fields = {
  __typename: 'sponsors_category_var_pop_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Sponsors_Category_Var_Samp_Fields = {
  __typename: 'sponsors_category_var_samp_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Sponsors_Category_Variance_Fields = {
  __typename: 'sponsors_category_variance_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "sponsors.sponsor" */
export type Sponsors_Sponsor = {
  __typename: 'sponsors_sponsor';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  admins?: Maybe<Array<Clubs_Club_Admin>>;
  /** An object relationship */
  category?: Maybe<Sponsors_Category>;
  category_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  club_id?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_major?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  teams?: Maybe<Array<Sponsors_Team_Sponsor>>;
  url?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "sponsors.sponsor" */
export type Sponsors_SponsorAdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "sponsors.sponsor" */
export type Sponsors_SponsorTeamsArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Team_Sponsor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Team_Sponsor_Order_By>>;
  where?: InputMaybe<Sponsors_Team_Sponsor_Bool_Exp>;
};

/** order by aggregate values of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Aggregate_Order_By = {
  avg?: InputMaybe<Sponsors_Sponsor_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sponsors_Sponsor_Max_Order_By>;
  min?: InputMaybe<Sponsors_Sponsor_Min_Order_By>;
  stddev?: InputMaybe<Sponsors_Sponsor_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sponsors_Sponsor_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sponsors_Sponsor_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sponsors_Sponsor_Sum_Order_By>;
  var_pop?: InputMaybe<Sponsors_Sponsor_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sponsors_Sponsor_Var_Samp_Order_By>;
  variance?: InputMaybe<Sponsors_Sponsor_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "sponsors.sponsor" */
export type Sponsors_Sponsor_Arr_Rel_Insert_Input = {
  data: Array<Sponsors_Sponsor_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sponsors_Sponsor_On_Conflict>;
};

/** order by avg() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sponsors.sponsor". All fields are combined with a logical 'AND'. */
export type Sponsors_Sponsor_Bool_Exp = {
  _and?: InputMaybe<Array<Sponsors_Sponsor_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Sponsors_Sponsor_Bool_Exp>;
  _or?: InputMaybe<Array<Sponsors_Sponsor_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  category?: InputMaybe<Sponsors_Category_Bool_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_major?: InputMaybe<Boolean_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  teams?: InputMaybe<Sponsors_Team_Sponsor_Bool_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sponsors.sponsor" */
export enum Sponsors_Sponsor_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScreenPkey = 'screen_pkey',
}

/** input type for incrementing numeric columns in table "sponsors.sponsor" */
export type Sponsors_Sponsor_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "sponsors.sponsor" */
export type Sponsors_Sponsor_Insert_Input = {
  category?: InputMaybe<Sponsors_Category_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  is_major?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sponsors.sponsor" */
export type Sponsors_Sponsor_Mutation_Response = {
  __typename: 'sponsors_sponsor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Sponsors_Sponsor>>;
};

/** on_conflict condition type for table "sponsors.sponsor" */
export type Sponsors_Sponsor_On_Conflict = {
  constraint: Sponsors_Sponsor_Constraint;
  update_columns?: Array<Sponsors_Sponsor_Update_Column>;
  where?: InputMaybe<Sponsors_Sponsor_Bool_Exp>;
};

/** Ordering options when selecting data from "sponsors.sponsor". */
export type Sponsors_Sponsor_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  category?: InputMaybe<Sponsors_Category_Order_By>;
  category_id?: InputMaybe<Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_major?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Sponsors_Team_Sponsor_Aggregate_Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sponsors.sponsor */
export type Sponsors_Sponsor_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "sponsors.sponsor" */
export enum Sponsors_Sponsor_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsMajor = 'is_major',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "sponsors.sponsor" */
export type Sponsors_Sponsor_Set_Input = {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  is_major?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sponsors_sponsor" */
export type Sponsors_Sponsor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sponsors_Sponsor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sponsors_Sponsor_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_major?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "sponsors.sponsor" */
export enum Sponsors_Sponsor_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Description = 'description',
  /** column name */
  IsMajor = 'is_major',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url',
}

export type Sponsors_Sponsor_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sponsors_Sponsor_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sponsors_Sponsor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sponsors_Sponsor_Bool_Exp;
};

/** order by var_pop() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "sponsors.sponsor" */
export type Sponsors_Sponsor_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor = {
  __typename: 'sponsors_team_sponsor';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  sponsor?: Maybe<Sponsors_Sponsor>;
  sponsor_id?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Aggregate_Order_By = {
  avg?: InputMaybe<Sponsors_Team_Sponsor_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sponsors_Team_Sponsor_Max_Order_By>;
  min?: InputMaybe<Sponsors_Team_Sponsor_Min_Order_By>;
  stddev?: InputMaybe<Sponsors_Team_Sponsor_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sponsors_Team_Sponsor_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sponsors_Team_Sponsor_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sponsors_Team_Sponsor_Sum_Order_By>;
  var_pop?: InputMaybe<Sponsors_Team_Sponsor_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sponsors_Team_Sponsor_Var_Samp_Order_By>;
  variance?: InputMaybe<Sponsors_Team_Sponsor_Variance_Order_By>;
};

/** order by avg() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sponsors.team_sponsor". All fields are combined with a logical 'AND'. */
export type Sponsors_Team_Sponsor_Bool_Exp = {
  _and?: InputMaybe<Array<Sponsors_Team_Sponsor_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Sponsors_Team_Sponsor_Bool_Exp>;
  _or?: InputMaybe<Array<Sponsors_Team_Sponsor_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  sponsor?: InputMaybe<Sponsors_Sponsor_Bool_Exp>;
  sponsor_id?: InputMaybe<Int_Comparison_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "sponsors.team_sponsor". */
export type Sponsors_Team_Sponsor_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sponsor?: InputMaybe<Sponsors_Sponsor_Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** select columns of table "sponsors.team_sponsor" */
export enum Sponsors_Team_Sponsor_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  SponsorId = 'sponsor_id',
  /** column name */
  TeamId = 'team_id',
}

/** order by stddev() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sponsors_team_sponsor" */
export type Sponsors_Team_Sponsor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sponsors_Team_Sponsor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sponsors_Team_Sponsor_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  sponsor_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "sponsors.team_sponsor" */
export type Sponsors_Team_Sponsor_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  sponsor_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

export type Sponsors_Update_Teams_Mutation_Response = {
  __typename: 'sponsors_update_teams_mutation_response';
  nb_teams_added?: Maybe<Scalars['smallint']['output']>;
  nb_teams_deleted?: Maybe<Scalars['smallint']['output']>;
  nb_teams_updated?: Maybe<Scalars['smallint']['output']>;
  sponsor_id?: Maybe<Scalars['Int']['output']>;
};

/** Customize client schema */
export type Stats_Action = {
  __typename: 'stats_action';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  action_type?: Maybe<Scalars['smallint']['output']>;
  direction?: Maybe<Scalars['smallint']['output']>;
  feature?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  live?: Maybe<Lives_Live>;
  live_id?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['smallint']['output']>;
  result?: Maybe<Scalars['smallint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user?: Maybe<Users_User>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone?: Maybe<Scalars['smallint']['output']>;
};

/** aggregated selection of "stats.action" */
export type Stats_Action_Aggregate = {
  __typename: 'stats_action_aggregate';
  aggregate?: Maybe<Stats_Action_Aggregate_Fields>;
  nodes?: Maybe<Array<Stats_Action>>;
};

/** aggregate fields of "stats.action" */
export type Stats_Action_Aggregate_Fields = {
  __typename: 'stats_action_aggregate_fields';
  avg?: Maybe<Stats_Action_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Stats_Action_Max_Fields>;
  min?: Maybe<Stats_Action_Min_Fields>;
  stddev?: Maybe<Stats_Action_Stddev_Fields>;
  stddev_pop?: Maybe<Stats_Action_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stats_Action_Stddev_Samp_Fields>;
  sum?: Maybe<Stats_Action_Sum_Fields>;
  var_pop?: Maybe<Stats_Action_Var_Pop_Fields>;
  var_samp?: Maybe<Stats_Action_Var_Samp_Fields>;
  variance?: Maybe<Stats_Action_Variance_Fields>;
};

/** aggregate fields of "stats.action" */
export type Stats_Action_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stats_Action_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "stats.action" */
export type Stats_Action_Aggregate_Order_By = {
  avg?: InputMaybe<Stats_Action_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stats_Action_Max_Order_By>;
  min?: InputMaybe<Stats_Action_Min_Order_By>;
  stddev?: InputMaybe<Stats_Action_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stats_Action_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stats_Action_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stats_Action_Sum_Order_By>;
  var_pop?: InputMaybe<Stats_Action_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stats_Action_Var_Samp_Order_By>;
  variance?: InputMaybe<Stats_Action_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Stats_Action_Avg_Fields = {
  __typename: 'stats_action_avg_fields';
  action_type?: Maybe<Scalars['Float']['output']>;
  direction?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  result?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "stats.action" */
export type Stats_Action_Avg_Order_By = {
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stats.action". All fields are combined with a logical 'AND'. */
export type Stats_Action_Bool_Exp = {
  _and?: InputMaybe<Array<Stats_Action_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Stats_Action_Bool_Exp>;
  _or?: InputMaybe<Array<Stats_Action_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  action_type?: InputMaybe<Smallint_Comparison_Exp>;
  direction?: InputMaybe<Smallint_Comparison_Exp>;
  feature?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live?: InputMaybe<Lives_Live_Bool_Exp>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  period?: InputMaybe<Smallint_Comparison_Exp>;
  result?: InputMaybe<Smallint_Comparison_Exp>;
  team?: InputMaybe<String_Comparison_Exp>;
  time?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  zone?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "stats.action" */
export enum Stats_Action_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActionPkey = 'action_pkey',
}

/** input type for incrementing numeric columns in table "stats.action" */
export type Stats_Action_Inc_Input = {
  action_type?: InputMaybe<Scalars['smallint']['input']>;
  direction?: InputMaybe<Scalars['smallint']['input']>;
  feature?: InputMaybe<Scalars['smallint']['input']>;
  period?: InputMaybe<Scalars['smallint']['input']>;
  result?: InputMaybe<Scalars['smallint']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "stats.action" */
export type Stats_Action_Insert_Input = {
  action_type?: InputMaybe<Scalars['smallint']['input']>;
  direction?: InputMaybe<Scalars['smallint']['input']>;
  feature?: InputMaybe<Scalars['smallint']['input']>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['smallint']['input']>;
  result?: InputMaybe<Scalars['smallint']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate max on columns */
export type Stats_Action_Max_Fields = {
  __typename: 'stats_action_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  action_type?: Maybe<Scalars['smallint']['output']>;
  direction?: Maybe<Scalars['smallint']['output']>;
  feature?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['smallint']['output']>;
  result?: Maybe<Scalars['smallint']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone?: Maybe<Scalars['smallint']['output']>;
};

/** order by max() on columns of table "stats.action" */
export type Stats_Action_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  team?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stats_Action_Min_Fields = {
  __typename: 'stats_action_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  action_type?: Maybe<Scalars['smallint']['output']>;
  direction?: Maybe<Scalars['smallint']['output']>;
  feature?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['smallint']['output']>;
  result?: Maybe<Scalars['smallint']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone?: Maybe<Scalars['smallint']['output']>;
};

/** order by min() on columns of table "stats.action" */
export type Stats_Action_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  team?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stats.action" */
export type Stats_Action_Mutation_Response = {
  __typename: 'stats_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Stats_Action>>;
};

/** on_conflict condition type for table "stats.action" */
export type Stats_Action_On_Conflict = {
  constraint: Stats_Action_Constraint;
  update_columns?: Array<Stats_Action_Update_Column>;
  where?: InputMaybe<Stats_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "stats.action". */
export type Stats_Action_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live?: InputMaybe<Lives_Live_Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  team?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stats.action */
export type Stats_Action_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "stats.action" */
export enum Stats_Action_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ActionType = 'action_type',
  /** column name */
  Direction = 'direction',
  /** column name */
  Feature = 'feature',
  /** column name */
  Id = 'id',
  /** column name */
  LiveId = 'live_id',
  /** column name */
  Period = 'period',
  /** column name */
  Result = 'result',
  /** column name */
  Team = 'team',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Zone = 'zone',
}

/** input type for updating data in table "stats.action" */
export type Stats_Action_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  action_type?: InputMaybe<Scalars['smallint']['input']>;
  direction?: InputMaybe<Scalars['smallint']['input']>;
  feature?: InputMaybe<Scalars['smallint']['input']>;
  period?: InputMaybe<Scalars['smallint']['input']>;
  result?: InputMaybe<Scalars['smallint']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate stddev on columns */
export type Stats_Action_Stddev_Fields = {
  __typename: 'stats_action_stddev_fields';
  action_type?: Maybe<Scalars['Float']['output']>;
  direction?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  result?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "stats.action" */
export type Stats_Action_Stddev_Order_By = {
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stats_Action_Stddev_Pop_Fields = {
  __typename: 'stats_action_stddev_pop_fields';
  action_type?: Maybe<Scalars['Float']['output']>;
  direction?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  result?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "stats.action" */
export type Stats_Action_Stddev_Pop_Order_By = {
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stats_Action_Stddev_Samp_Fields = {
  __typename: 'stats_action_stddev_samp_fields';
  action_type?: Maybe<Scalars['Float']['output']>;
  direction?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  result?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "stats.action" */
export type Stats_Action_Stddev_Samp_Order_By = {
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "stats_action" */
export type Stats_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stats_Action_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stats_Action_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  action_type?: InputMaybe<Scalars['smallint']['input']>;
  direction?: InputMaybe<Scalars['smallint']['input']>;
  feature?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  live_id?: InputMaybe<Scalars['Int']['input']>;
  period?: InputMaybe<Scalars['smallint']['input']>;
  result?: InputMaybe<Scalars['smallint']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate sum on columns */
export type Stats_Action_Sum_Fields = {
  __typename: 'stats_action_sum_fields';
  action_type?: Maybe<Scalars['smallint']['output']>;
  direction?: Maybe<Scalars['smallint']['output']>;
  feature?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  live_id?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['smallint']['output']>;
  result?: Maybe<Scalars['smallint']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "stats.action" */
export type Stats_Action_Sum_Order_By = {
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** update columns of table "stats.action" */
export enum Stats_Action_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  ActionType = 'action_type',
  /** column name */
  Direction = 'direction',
  /** column name */
  Feature = 'feature',
  /** column name */
  Period = 'period',
  /** column name */
  Result = 'result',
  /** column name */
  Team = 'team',
  /** column name */
  Time = 'time',
  /** column name */
  Zone = 'zone',
}

export type Stats_Action_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stats_Action_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stats_Action_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stats_Action_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stats_Action_Var_Pop_Fields = {
  __typename: 'stats_action_var_pop_fields';
  action_type?: Maybe<Scalars['Float']['output']>;
  direction?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  result?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "stats.action" */
export type Stats_Action_Var_Pop_Order_By = {
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stats_Action_Var_Samp_Fields = {
  __typename: 'stats_action_var_samp_fields';
  action_type?: Maybe<Scalars['Float']['output']>;
  direction?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  result?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "stats.action" */
export type Stats_Action_Var_Samp_Order_By = {
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stats_Action_Variance_Fields = {
  __typename: 'stats_action_variance_fields';
  action_type?: Maybe<Scalars['Float']['output']>;
  direction?: Maybe<Scalars['Float']['output']>;
  feature?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  live_id?: Maybe<Scalars['Float']['output']>;
  period?: Maybe<Scalars['Float']['output']>;
  result?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "stats.action" */
export type Stats_Action_Variance_Order_By = {
  action_type?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
  result?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

export type Stats_After_Match_Graph_Response = {
  __typename: 'stats_after_match_graph_response';
  buts?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  period?: Maybe<Scalars['String']['output']>;
  taux_reussite?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  tirs_bloques?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  tirs_cadres?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  tirs_hors_cadres?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type Stats_After_Match_Passe_Direction_Response = {
  __typename: 'stats_after_match_passe_direction_response';
  direction?: Maybe<Scalars['Int']['output']>;
  passes?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  passes_reussis?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  period?: Maybe<Scalars['String']['output']>;
  precision_au_passe?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  total_passes?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  total_passes_reussis?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  total_precision_au_passe?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type Stats_After_Match_Zone_Response = {
  __typename: 'stats_after_match_zone_response';
  arrets?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  dribbles?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  dribbles_reussis?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  duels_defensifs?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  duels_defensifs_gagnes?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  duels_offensifs?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  duels_offensifs_gagnes?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  interceptions?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  period?: Maybe<Scalars['String']['output']>;
  pourcentage_arrets?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  pourcentage_dribbles_reussis?: Maybe<
    Array<Maybe<Scalars['Float']['output']>>
  >;
  pourcentage_duels_defensifs_gagnes?: Maybe<
    Array<Maybe<Scalars['Float']['output']>>
  >;
  pourcentage_duels_offensifs_gagnes?: Maybe<
    Array<Maybe<Scalars['Float']['output']>>
  >;
  pourcentage_interceptions?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  precision_tir?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  tirs?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  tirs_cadres?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  tirs_cadres_recus?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  total_interceptions?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  zone?: Maybe<Scalars['Int']['output']>;
};

export type Stats_Collective_Statistics_Response = {
  __typename: 'stats_collective_statistics_response';
  ballons_perdus?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  cartons_blancs?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  cartons_jaunes?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  cartons_rouges?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  corners?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  coups_francs?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  dribbles_reussis?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  duels_gagnes?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  fautes?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  hors_jeu?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  interceptions?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  penalty_manques?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  penalty_reussis?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  period?: Maybe<Scalars['String']['output']>;
  possession?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  precision_passe?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  precision_tir?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  taux_reussite?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type Subscription_Root = {
  __typename: 'subscription_root';
  /** fetch data from the table: "apps.version" */
  apps_version?: Maybe<Array<Apps_Version>>;
  /** fetch data from the table: "apps.version" using primary key columns */
  apps_version_by_pk?: Maybe<Apps_Version>;
  /** fetch data from the table in a streaming manner: "apps.version" */
  apps_version_stream?: Maybe<Array<Apps_Version>>;
  /** fetch data from the table: "billing.club_customer" */
  billing_club_customer?: Maybe<Array<Billing_Club_Customer>>;
  /** fetch data from the table: "billing.club_customer" using primary key columns */
  billing_club_customer_by_pk?: Maybe<Billing_Club_Customer>;
  /** fetch data from the table in a streaming manner: "billing.club_customer" */
  billing_club_customer_stream?: Maybe<Array<Billing_Club_Customer>>;
  /** fetch data from the table: "billing.customer" */
  billing_customer?: Maybe<Array<Billing_Customer>>;
  /** fetch data from the table: "billing.customer" using primary key columns */
  billing_customer_by_pk?: Maybe<Billing_Customer>;
  /** fetch data from the table in a streaming manner: "billing.customer" */
  billing_customer_stream?: Maybe<Array<Billing_Customer>>;
  /** fetch data from the table: "clubhouse.club_house" */
  clubhouse_club_house?: Maybe<Array<Clubhouse_Club_House>>;
  /** fetch data from the table: "clubhouse.club_house" using primary key columns */
  clubhouse_club_house_by_pk?: Maybe<Clubhouse_Club_House>;
  /** fetch data from the table in a streaming manner: "clubhouse.club_house" */
  clubhouse_club_house_stream?: Maybe<Array<Clubhouse_Club_House>>;
  /** fetch data from the table: "clubhouse.screen" */
  clubhouse_screen?: Maybe<Array<Clubhouse_Screen>>;
  /** fetch aggregated fields from the table: "clubhouse.screen" */
  clubhouse_screen_aggregate?: Maybe<Clubhouse_Screen_Aggregate>;
  /** fetch data from the table: "clubhouse.screen" using primary key columns */
  clubhouse_screen_by_pk?: Maybe<Clubhouse_Screen>;
  /** fetch data from the table in a streaming manner: "clubhouse.screen" */
  clubhouse_screen_stream?: Maybe<Array<Clubhouse_Screen>>;
  /** fetch data from the table: "clubhouse.template" */
  clubhouse_template?: Maybe<Array<Clubhouse_Template>>;
  /** fetch data from the table: "clubhouse.template" using primary key columns */
  clubhouse_template_by_pk?: Maybe<Clubhouse_Template>;
  /** fetch data from the table in a streaming manner: "clubhouse.template" */
  clubhouse_template_stream?: Maybe<Array<Clubhouse_Template>>;
  /** fetch data from the table: "clubs.admin" */
  clubs_admin?: Maybe<Array<Clubs_Admin>>;
  /** fetch data from the table: "clubs.admin" using primary key columns */
  clubs_admin_by_pk?: Maybe<Clubs_Admin>;
  /** fetch data from the table in a streaming manner: "clubs.admin" */
  clubs_admin_stream?: Maybe<Array<Clubs_Admin>>;
  /** fetch data from the table: "clubs.club" */
  clubs_club?: Maybe<Array<Clubs_Club>>;
  /** fetch data from the table: "clubs.club_admin" */
  clubs_club_admin?: Maybe<Array<Clubs_Club_Admin>>;
  /** fetch data from the table: "clubs.club_admin" using primary key columns */
  clubs_club_admin_by_pk?: Maybe<Clubs_Club_Admin>;
  /** fetch data from the table in a streaming manner: "clubs.club_admin" */
  clubs_club_admin_stream?: Maybe<Array<Clubs_Club_Admin>>;
  /** fetch aggregated fields from the table: "clubs.club" */
  clubs_club_aggregate?: Maybe<Clubs_Club_Aggregate>;
  /** fetch data from the table: "clubs.club" using primary key columns */
  clubs_club_by_pk?: Maybe<Clubs_Club>;
  /** fetch data from the table: "clubs.club_detail" */
  clubs_club_detail?: Maybe<Array<Clubs_Club_Detail>>;
  /** fetch data from the table: "clubs.club_detail" using primary key columns */
  clubs_club_detail_by_pk?: Maybe<Clubs_Club_Detail>;
  /** fetch data from the table in a streaming manner: "clubs.club_detail" */
  clubs_club_detail_stream?: Maybe<Array<Clubs_Club_Detail>>;
  /** fetch data from the table in a streaming manner: "clubs.club" */
  clubs_club_stream?: Maybe<Array<Clubs_Club>>;
  /** execute function "clubs.get_club" which returns "clubs.club_detail" */
  clubs_get_club?: Maybe<Array<Clubs_Club_Detail>>;
  /** fetch data from the table: "clubs.organization" */
  clubs_organization?: Maybe<Array<Clubs_Organization>>;
  /** fetch data from the table: "clubs.organization" using primary key columns */
  clubs_organization_by_pk?: Maybe<Clubs_Organization>;
  /** fetch data from the table in a streaming manner: "clubs.organization" */
  clubs_organization_stream?: Maybe<Array<Clubs_Organization>>;
  /** fetch data from the table: "clubs.planner_settings" */
  clubs_planner_settings?: Maybe<Array<Clubs_Planner_Settings>>;
  /** fetch data from the table: "clubs.planner_settings" using primary key columns */
  clubs_planner_settings_by_pk?: Maybe<Clubs_Planner_Settings>;
  /** fetch data from the table in a streaming manner: "clubs.planner_settings" */
  clubs_planner_settings_stream?: Maybe<Array<Clubs_Planner_Settings>>;
  /** fetch data from the table: "clubs.season" */
  clubs_season?: Maybe<Array<Clubs_Season>>;
  /** fetch data from the table: "clubs.season" using primary key columns */
  clubs_season_by_pk?: Maybe<Clubs_Season>;
  /** fetch data from the table in a streaming manner: "clubs.season" */
  clubs_season_stream?: Maybe<Array<Clubs_Season>>;
  /** fetch data from the table: "clubs.sport" */
  clubs_sport?: Maybe<Array<Clubs_Sport>>;
  /** fetch data from the table: "clubs.sport" using primary key columns */
  clubs_sport_by_pk?: Maybe<Clubs_Sport>;
  /** fetch data from the table in a streaming manner: "clubs.sport" */
  clubs_sport_stream?: Maybe<Array<Clubs_Sport>>;
  /** fetch data from the table: "competitions.closest_event_with_same_teams" */
  competitions_closest_event_with_same_teams?: Maybe<
    Array<Competitions_Closest_Event_With_Same_Teams>
  >;
  /** fetch data from the table in a streaming manner: "competitions.closest_event_with_same_teams" */
  competitions_closest_event_with_same_teams_stream?: Maybe<
    Array<Competitions_Closest_Event_With_Same_Teams>
  >;
  /** fetch data from the table: "competitions.competition" */
  competitions_competition?: Maybe<Array<Competitions_Competition>>;
  /** fetch data from the table: "competitions.competition" using primary key columns */
  competitions_competition_by_pk?: Maybe<Competitions_Competition>;
  /** fetch data from the table: "competitions.competition_detail" */
  competitions_competition_detail?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** execute function "competitions.competition_detail_by_id" which returns "competitions.competition_detail" */
  competitions_competition_detail_by_id?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** execute function "competitions.competition_detail_by_level_for_season_id" which returns "competitions.competition_detail" */
  competitions_competition_detail_by_level_for_season_id?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** execute function "competitions.competition_detail_by_level_id" which returns "competitions.competition_detail" */
  competitions_competition_detail_by_level_id?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** fetch data from the table: "competitions.competition_detail" using primary key columns */
  competitions_competition_detail_by_pk?: Maybe<Competitions_Competition_Detail>;
  /** fetch data from the table in a streaming manner: "competitions.competition_detail" */
  competitions_competition_detail_stream?: Maybe<
    Array<Competitions_Competition_Detail>
  >;
  /** fetch data from the table: "competitions.competition_has_fans" */
  competitions_competition_has_fans?: Maybe<
    Array<Competitions_Competition_Has_Fans>
  >;
  /** fetch data from the table: "competitions.competition_has_fans" using primary key columns */
  competitions_competition_has_fans_by_pk?: Maybe<Competitions_Competition_Has_Fans>;
  /** fetch data from the table in a streaming manner: "competitions.competition_has_fans" */
  competitions_competition_has_fans_stream?: Maybe<
    Array<Competitions_Competition_Has_Fans>
  >;
  /** fetch data from the table: "competitions.competition_has_teams" */
  competitions_competition_has_teams?: Maybe<
    Array<Competitions_Competition_Has_Teams>
  >;
  /** fetch data from the table: "competitions.competition_has_teams" using primary key columns */
  competitions_competition_has_teams_by_pk?: Maybe<Competitions_Competition_Has_Teams>;
  /** fetch data from the table in a streaming manner: "competitions.competition_has_teams" */
  competitions_competition_has_teams_stream?: Maybe<
    Array<Competitions_Competition_Has_Teams>
  >;
  /** fetch data from the table: "competitions.competition_statistics" */
  competitions_competition_statistics?: Maybe<
    Array<Competitions_Competition_Statistics>
  >;
  /** fetch data from the table in a streaming manner: "competitions.competition_statistics" */
  competitions_competition_statistics_stream?: Maybe<
    Array<Competitions_Competition_Statistics>
  >;
  /** fetch data from the table in a streaming manner: "competitions.competition" */
  competitions_competition_stream?: Maybe<Array<Competitions_Competition>>;
  /** fetch data from the table: "competitions.event" */
  competitions_event?: Maybe<Array<Competitions_Event>>;
  /** fetch aggregated fields from the table: "competitions.event" */
  competitions_event_aggregate?: Maybe<Competitions_Event_Aggregate>;
  /** fetch data from the table: "competitions.event" using primary key columns */
  competitions_event_by_pk?: Maybe<Competitions_Event>;
  /** fetch data from the table: "competitions.event_closest_date" */
  competitions_event_closest_date?: Maybe<
    Array<Competitions_Event_Closest_Date>
  >;
  /** fetch data from the table in a streaming manner: "competitions.event_closest_date" */
  competitions_event_closest_date_stream?: Maybe<
    Array<Competitions_Event_Closest_Date>
  >;
  /** fetch data from the table: "competitions.event_detail" */
  competitions_event_detail?: Maybe<Array<Competitions_Event_Detail>>;
  /** fetch aggregated fields from the table: "competitions.event_detail" */
  competitions_event_detail_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_club_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_club_id?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_club_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_club_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_club_id_between_dates" which returns "competitions.event_detail" */
  competitions_event_detail_by_club_id_between_dates?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_club_id_between_dates" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_club_id_between_dates_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_club_id_between_dates_order_by_rank" which returns "competitions.event_detail" */
  competitions_event_detail_by_club_id_between_dates_order_by_rank?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_club_id_between_dates_order_by_rank" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_club_id_between_dates_order_by_rank_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_id?: Maybe<Array<Competitions_Event_Detail>>;
  /** execute function "competitions.event_detail_by_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_live_rating" which returns "competitions.event_detail" */
  competitions_event_detail_by_live_rating?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_live_rating" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_live_rating_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_live_rating_and_sport" which returns "competitions.event_detail" */
  competitions_event_detail_by_live_rating_and_sport?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_live_rating_and_sport" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_live_rating_and_sport_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** fetch data from the table: "competitions.event_detail" using primary key columns */
  competitions_event_detail_by_pk?: Maybe<Competitions_Event_Detail>;
  /** execute function "competitions.event_detail_by_round_day_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_round_day_id?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_round_day_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_round_day_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_team_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_team_id?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_team_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_team_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_team_in_season_id" which returns "competitions.event_detail" */
  competitions_event_detail_by_team_in_season_id?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_team_in_season_id" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_team_in_season_id_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** execute function "competitions.event_detail_by_user_favorite_teams" which returns "competitions.event_detail" */
  competitions_event_detail_by_user_favorite_teams?: Maybe<
    Array<Competitions_Event_Detail>
  >;
  /** execute function "competitions.event_detail_by_user_favorite_teams" and query aggregates on result of table type "competitions.event_detail" */
  competitions_event_detail_by_user_favorite_teams_aggregate?: Maybe<Competitions_Event_Detail_Aggregate>;
  /** fetch data from the table in a streaming manner: "competitions.event_detail" */
  competitions_event_detail_stream?: Maybe<Array<Competitions_Event_Detail>>;
  /** fetch data from the table: "competitions.event_last_update" */
  competitions_event_last_update?: Maybe<Array<Competitions_Event_Last_Update>>;
  /** fetch data from the table in a streaming manner: "competitions.event_last_update" */
  competitions_event_last_update_stream?: Maybe<
    Array<Competitions_Event_Last_Update>
  >;
  /** fetch data from the table: "competitions.event_referee" */
  competitions_event_referee?: Maybe<Array<Competitions_Event_Referee>>;
  /** fetch data from the table: "competitions.event_referee" using primary key columns */
  competitions_event_referee_by_pk?: Maybe<Competitions_Event_Referee>;
  /** fetch data from the table in a streaming manner: "competitions.event_referee" */
  competitions_event_referee_stream?: Maybe<Array<Competitions_Event_Referee>>;
  /** fetch data from the table in a streaming manner: "competitions.event" */
  competitions_event_stream?: Maybe<Array<Competitions_Event>>;
  /** fetch data from the table: "competitions.event_with_same_teams" */
  competitions_event_with_same_teams?: Maybe<
    Array<Competitions_Event_With_Same_Teams>
  >;
  /** fetch data from the table: "competitions.event_with_same_teams" using primary key columns */
  competitions_event_with_same_teams_by_pk?: Maybe<Competitions_Event_With_Same_Teams>;
  /** fetch data from the table in a streaming manner: "competitions.event_with_same_teams" */
  competitions_event_with_same_teams_stream?: Maybe<
    Array<Competitions_Event_With_Same_Teams>
  >;
  /** fetch data from the table: "competitions.fan" */
  competitions_fan?: Maybe<Array<Competitions_Fan>>;
  /** fetch data from the table: "competitions.fan" using primary key columns */
  competitions_fan_by_pk?: Maybe<Competitions_Fan>;
  /** fetch data from the table in a streaming manner: "competitions.fan" */
  competitions_fan_stream?: Maybe<Array<Competitions_Fan>>;
  /** fetch data from the table: "competitions.league" */
  competitions_league?: Maybe<Array<Competitions_League>>;
  /** fetch data from the table: "competitions.league" using primary key columns */
  competitions_league_by_pk?: Maybe<Competitions_League>;
  /** fetch data from the table: "competitions.league_detail" */
  competitions_league_detail?: Maybe<Array<Competitions_League_Detail>>;
  /** execute function "competitions.league_detail_by_id" which returns "competitions.league_detail" */
  competitions_league_detail_by_id?: Maybe<Array<Competitions_League_Detail>>;
  /** fetch data from the table: "competitions.league_detail" using primary key columns */
  competitions_league_detail_by_pk?: Maybe<Competitions_League_Detail>;
  /** execute function "competitions.league_detail_by_sport_slug" which returns "competitions.league_detail" */
  competitions_league_detail_by_sport_slug?: Maybe<
    Array<Competitions_League_Detail>
  >;
  /** fetch data from the table in a streaming manner: "competitions.league_detail" */
  competitions_league_detail_stream?: Maybe<Array<Competitions_League_Detail>>;
  /** fetch data from the table in a streaming manner: "competitions.league" */
  competitions_league_stream?: Maybe<Array<Competitions_League>>;
  /** fetch data from the table: "competitions.level" */
  competitions_level?: Maybe<Array<Competitions_Level>>;
  /** fetch data from the table: "competitions.level" using primary key columns */
  competitions_level_by_pk?: Maybe<Competitions_Level>;
  /** fetch data from the table: "competitions.level_for_season" */
  competitions_level_for_season?: Maybe<Array<Competitions_Level_For_Season>>;
  /** fetch data from the table: "competitions.level_for_season" using primary key columns */
  competitions_level_for_season_by_pk?: Maybe<Competitions_Level_For_Season>;
  /** fetch data from the table: "competitions.level_for_season_detail" */
  competitions_level_for_season_detail?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** execute function "competitions.level_for_season_detail_by_id" which returns "competitions.level_for_season_detail" */
  competitions_level_for_season_detail_by_id?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** execute function "competitions.level_for_season_detail_by_league_id" which returns "competitions.level_for_season_detail" */
  competitions_level_for_season_detail_by_league_id?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** execute function "competitions.level_for_season_detail_by_level_and_season" which returns "competitions.level_for_season_detail" */
  competitions_level_for_season_detail_by_level_and_season?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** fetch data from the table: "competitions.level_for_season_detail" using primary key columns */
  competitions_level_for_season_detail_by_pk?: Maybe<Competitions_Level_For_Season_Detail>;
  /** fetch data from the table in a streaming manner: "competitions.level_for_season_detail" */
  competitions_level_for_season_detail_stream?: Maybe<
    Array<Competitions_Level_For_Season_Detail>
  >;
  /** fetch data from the table in a streaming manner: "competitions.level_for_season" */
  competitions_level_for_season_stream?: Maybe<
    Array<Competitions_Level_For_Season>
  >;
  /** fetch data from the table in a streaming manner: "competitions.level" */
  competitions_level_stream?: Maybe<Array<Competitions_Level>>;
  /** fetch data from the table: "competitions.playing_team" */
  competitions_playing_team?: Maybe<Array<Competitions_Playing_Team>>;
  /** fetch aggregated fields from the table: "competitions.playing_team" */
  competitions_playing_team_aggregate?: Maybe<Competitions_Playing_Team_Aggregate>;
  /** fetch data from the table: "competitions.playing_team_bar_manager" */
  competitions_playing_team_bar_manager?: Maybe<
    Array<Competitions_Playing_Team_Bar_Manager>
  >;
  /** fetch data from the table: "competitions.playing_team_bar_manager" using primary key columns */
  competitions_playing_team_bar_manager_by_pk?: Maybe<Competitions_Playing_Team_Bar_Manager>;
  /** fetch data from the table in a streaming manner: "competitions.playing_team_bar_manager" */
  competitions_playing_team_bar_manager_stream?: Maybe<
    Array<Competitions_Playing_Team_Bar_Manager>
  >;
  /** fetch data from the table: "competitions.playing_team" using primary key columns */
  competitions_playing_team_by_pk?: Maybe<Competitions_Playing_Team>;
  /** fetch data from the table: "competitions.playing_team_info" */
  competitions_playing_team_info?: Maybe<Array<Competitions_Playing_Team_Info>>;
  /** fetch aggregated fields from the table: "competitions.playing_team_info" */
  competitions_playing_team_info_aggregate?: Maybe<Competitions_Playing_Team_Info_Aggregate>;
  /** fetch data from the table: "competitions.playing_team_info" using primary key columns */
  competitions_playing_team_info_by_pk?: Maybe<Competitions_Playing_Team_Info>;
  /** fetch data from the table in a streaming manner: "competitions.playing_team_info" */
  competitions_playing_team_info_stream?: Maybe<
    Array<Competitions_Playing_Team_Info>
  >;
  /** fetch data from the table: "competitions.playing_team_referee" */
  competitions_playing_team_referee?: Maybe<
    Array<Competitions_Playing_Team_Referee>
  >;
  /** fetch data from the table: "competitions.playing_team_referee" using primary key columns */
  competitions_playing_team_referee_by_pk?: Maybe<Competitions_Playing_Team_Referee>;
  /** fetch data from the table in a streaming manner: "competitions.playing_team_referee" */
  competitions_playing_team_referee_stream?: Maybe<
    Array<Competitions_Playing_Team_Referee>
  >;
  /** fetch data from the table: "competitions.playing_team_scoring_table" */
  competitions_playing_team_scoring_table?: Maybe<
    Array<Competitions_Playing_Team_Scoring_Table>
  >;
  /** fetch data from the table: "competitions.playing_team_scoring_table" using primary key columns */
  competitions_playing_team_scoring_table_by_pk?: Maybe<Competitions_Playing_Team_Scoring_Table>;
  /** fetch data from the table in a streaming manner: "competitions.playing_team_scoring_table" */
  competitions_playing_team_scoring_table_stream?: Maybe<
    Array<Competitions_Playing_Team_Scoring_Table>
  >;
  /** fetch data from the table: "competitions.playing_team_stadium_manager" */
  competitions_playing_team_stadium_manager?: Maybe<
    Array<Competitions_Playing_Team_Stadium_Manager>
  >;
  /** fetch data from the table: "competitions.playing_team_stadium_manager" using primary key columns */
  competitions_playing_team_stadium_manager_by_pk?: Maybe<Competitions_Playing_Team_Stadium_Manager>;
  /** fetch data from the table in a streaming manner: "competitions.playing_team_stadium_manager" */
  competitions_playing_team_stadium_manager_stream?: Maybe<
    Array<Competitions_Playing_Team_Stadium_Manager>
  >;
  /** fetch data from the table in a streaming manner: "competitions.playing_team" */
  competitions_playing_team_stream?: Maybe<Array<Competitions_Playing_Team>>;
  /** fetch data from the table: "competitions.ranking" */
  competitions_ranking?: Maybe<Array<Competitions_Ranking>>;
  /** fetch data from the table: "competitions.ranking" using primary key columns */
  competitions_ranking_by_pk?: Maybe<Competitions_Ranking>;
  /** fetch data from the table: "competitions.ranking_detail" */
  competitions_ranking_detail?: Maybe<Array<Competitions_Ranking_Detail>>;
  /** fetch data from the table: "competitions.ranking_detail" using primary key columns */
  competitions_ranking_detail_by_pk?: Maybe<Competitions_Ranking_Detail>;
  /** fetch data from the table in a streaming manner: "competitions.ranking_detail" */
  competitions_ranking_detail_stream?: Maybe<
    Array<Competitions_Ranking_Detail>
  >;
  /** fetch data from the table in a streaming manner: "competitions.ranking" */
  competitions_ranking_stream?: Maybe<Array<Competitions_Ranking>>;
  /** fetch data from the table: "competitions.round" */
  competitions_round?: Maybe<Array<Competitions_Round>>;
  /** fetch aggregated fields from the table: "competitions.round" */
  competitions_round_aggregate?: Maybe<Competitions_Round_Aggregate>;
  /** fetch data from the table: "competitions.round" using primary key columns */
  competitions_round_by_pk?: Maybe<Competitions_Round>;
  /** fetch data from the table in a streaming manner: "competitions.round" */
  competitions_round_stream?: Maybe<Array<Competitions_Round>>;
  /** fetch data from the table: "emails.event" */
  emails_event?: Maybe<Array<Emails_Event>>;
  /** fetch data from the table: "emails.event" using primary key columns */
  emails_event_by_pk?: Maybe<Emails_Event>;
  /** fetch data from the table in a streaming manner: "emails.event" */
  emails_event_stream?: Maybe<Array<Emails_Event>>;
  /** fetch data from the table: "emails.message" */
  emails_message?: Maybe<Array<Emails_Message>>;
  /** fetch data from the table: "emails.message" using primary key columns */
  emails_message_by_pk?: Maybe<Emails_Message>;
  /** fetch data from the table in a streaming manner: "emails.message" */
  emails_message_stream?: Maybe<Array<Emails_Message>>;
  /** fetch data from the table: "lives.action" */
  lives_action?: Maybe<Array<Lives_Action>>;
  /** fetch data from the table: "lives.action" using primary key columns */
  lives_action_by_pk?: Maybe<Lives_Action>;
  /** fetch data from the table in a streaming manner: "lives.action" */
  lives_action_stream?: Maybe<Array<Lives_Action>>;
  /** fetch data from the table: "lives.current_reporters" */
  lives_current_reporters?: Maybe<Array<Lives_Current_Reporters>>;
  /** fetch data from the table in a streaming manner: "lives.current_reporters" */
  lives_current_reporters_stream?: Maybe<Array<Lives_Current_Reporters>>;
  /** fetch data from the table: "lives.live" */
  lives_live?: Maybe<Array<Lives_Live>>;
  /** fetch data from the table: "lives.live" using primary key columns */
  lives_live_by_pk?: Maybe<Lives_Live>;
  /** fetch data from the table: "lives.live_has_reporter" */
  lives_live_has_reporter?: Maybe<Array<Lives_Live_Has_Reporter>>;
  /** fetch data from the table in a streaming manner: "lives.live_has_reporter" */
  lives_live_has_reporter_stream?: Maybe<Array<Lives_Live_Has_Reporter>>;
  /** fetch data from the table: "lives.live_hit" */
  lives_live_hit?: Maybe<Array<Lives_Live_Hit>>;
  /** fetch data from the table: "lives.live_hit" using primary key columns */
  lives_live_hit_by_pk?: Maybe<Lives_Live_Hit>;
  /** fetch data from the table: "lives.live_hit_count" */
  lives_live_hit_count?: Maybe<Array<Lives_Live_Hit_Count>>;
  /** fetch data from the table: "lives.live_hit_count" using primary key columns */
  lives_live_hit_count_by_pk?: Maybe<Lives_Live_Hit_Count>;
  /** fetch data from the table in a streaming manner: "lives.live_hit_count" */
  lives_live_hit_count_stream?: Maybe<Array<Lives_Live_Hit_Count>>;
  /** fetch data from the table in a streaming manner: "lives.live_hit" */
  lives_live_hit_stream?: Maybe<Array<Lives_Live_Hit>>;
  /** fetch data from the table: "lives.live_last_update" */
  lives_live_last_update?: Maybe<Array<Lives_Live_Last_Update>>;
  /** fetch data from the table in a streaming manner: "lives.live_last_update" */
  lives_live_last_update_stream?: Maybe<Array<Lives_Live_Last_Update>>;
  /** fetch data from the table: "lives.live_player" */
  lives_live_player?: Maybe<Array<Lives_Live_Player>>;
  /** fetch data from the table: "lives.live_player" using primary key columns */
  lives_live_player_by_pk?: Maybe<Lives_Live_Player>;
  /** fetch data from the table in a streaming manner: "lives.live_player" */
  lives_live_player_stream?: Maybe<Array<Lives_Live_Player>>;
  /** fetch data from the table in a streaming manner: "lives.live" */
  lives_live_stream?: Maybe<Array<Lives_Live>>;
  /** fetch data from the table: "lives.live_team" */
  lives_live_team?: Maybe<Array<Lives_Live_Team>>;
  /** fetch data from the table: "lives.live_team" using primary key columns */
  lives_live_team_by_pk?: Maybe<Lives_Live_Team>;
  /** fetch data from the table in a streaming manner: "lives.live_team" */
  lives_live_team_stream?: Maybe<Array<Lives_Live_Team>>;
  /** fetch data from the table: "lives.media_official_reporter" */
  lives_media_official_reporter?: Maybe<Array<Lives_Media_Official_Reporter>>;
  /** fetch data from the table: "lives.media_official_reporter" using primary key columns */
  lives_media_official_reporter_by_pk?: Maybe<Lives_Media_Official_Reporter>;
  /** fetch data from the table in a streaming manner: "lives.media_official_reporter" */
  lives_media_official_reporter_stream?: Maybe<
    Array<Lives_Media_Official_Reporter>
  >;
  /** fetch data from the table: "lives.official_reporter" */
  lives_official_reporter?: Maybe<Array<Lives_Official_Reporter>>;
  /** fetch data from the table: "lives.official_reporter" using primary key columns */
  lives_official_reporter_by_pk?: Maybe<Lives_Official_Reporter>;
  /** fetch data from the table in a streaming manner: "lives.official_reporter" */
  lives_official_reporter_stream?: Maybe<Array<Lives_Official_Reporter>>;
  /** fetch data from the table: "lives.post" */
  lives_post?: Maybe<Array<Lives_Post>>;
  /** fetch aggregated fields from the table: "lives.post" */
  lives_post_aggregate?: Maybe<Lives_Post_Aggregate>;
  /** fetch data from the table: "lives.post" using primary key columns */
  lives_post_by_pk?: Maybe<Lives_Post>;
  /** fetch data from the table in a streaming manner: "lives.post" */
  lives_post_stream?: Maybe<Array<Lives_Post>>;
  /** fetch data from the table: "lives.potential_team_reporters" */
  lives_potential_team_reporters?: Maybe<Array<Lives_Potential_Team_Reporters>>;
  /** fetch data from the table in a streaming manner: "lives.potential_team_reporters" */
  lives_potential_team_reporters_stream?: Maybe<
    Array<Lives_Potential_Team_Reporters>
  >;
  /** fetch data from the table: "lives.spectator" */
  lives_spectator?: Maybe<Array<Lives_Spectator>>;
  /** fetch data from the table: "lives.spectator" using primary key columns */
  lives_spectator_by_pk?: Maybe<Lives_Spectator>;
  /** fetch data from the table in a streaming manner: "lives.spectator" */
  lives_spectator_stream?: Maybe<Array<Lives_Spectator>>;
  /** fetch data from the table: "lives.team_official_reporter" */
  lives_team_official_reporter?: Maybe<Array<Lives_Team_Official_Reporter>>;
  /** fetch data from the table: "lives.team_official_reporter" using primary key columns */
  lives_team_official_reporter_by_pk?: Maybe<Lives_Team_Official_Reporter>;
  /** fetch data from the table in a streaming manner: "lives.team_official_reporter" */
  lives_team_official_reporter_stream?: Maybe<
    Array<Lives_Team_Official_Reporter>
  >;
  /** fetch data from the table: "lives.thank_you" */
  lives_thank_you?: Maybe<Array<Lives_Thank_You>>;
  /** fetch aggregated fields from the table: "lives.thank_you" */
  lives_thank_you_aggregate?: Maybe<Lives_Thank_You_Aggregate>;
  /** fetch data from the table: "lives.thank_you" using primary key columns */
  lives_thank_you_by_pk?: Maybe<Lives_Thank_You>;
  /** fetch data from the table in a streaming manner: "lives.thank_you" */
  lives_thank_you_stream?: Maybe<Array<Lives_Thank_You>>;
  /** fetch data from the table: "lives.tournament_reporter" */
  lives_tournament_reporter?: Maybe<Array<Lives_Tournament_Reporter>>;
  /** fetch data from the table: "lives.tournament_reporter" using primary key columns */
  lives_tournament_reporter_by_pk?: Maybe<Lives_Tournament_Reporter>;
  /** fetch data from the table in a streaming manner: "lives.tournament_reporter" */
  lives_tournament_reporter_stream?: Maybe<Array<Lives_Tournament_Reporter>>;
  /** fetch data from the table: "lives.used_action" */
  lives_used_action?: Maybe<Array<Lives_Used_Action>>;
  /** fetch data from the table in a streaming manner: "lives.used_action" */
  lives_used_action_stream?: Maybe<Array<Lives_Used_Action>>;
  /** fetch data from the table: "medias.media" */
  medias_media?: Maybe<Array<Medias_Media>>;
  /** fetch data from the table: "medias.media" using primary key columns */
  medias_media_by_pk?: Maybe<Medias_Media>;
  /** fetch data from the table in a streaming manner: "medias.media" */
  medias_media_stream?: Maybe<Array<Medias_Media>>;
  /** fetch data from the table: "medias.official_reporter" */
  medias_official_reporter?: Maybe<Array<Medias_Official_Reporter>>;
  /** fetch data from the table: "medias.official_reporter" using primary key columns */
  medias_official_reporter_by_pk?: Maybe<Medias_Official_Reporter>;
  /** fetch data from the table in a streaming manner: "medias.official_reporter" */
  medias_official_reporter_stream?: Maybe<Array<Medias_Official_Reporter>>;
  /** fetch data from the table: "members.export" */
  members_export?: Maybe<Array<Members_Export>>;
  /** fetch data from the table: "members.export" using primary key columns */
  members_export_by_pk?: Maybe<Members_Export>;
  /** fetch data from the table in a streaming manner: "members.export" */
  members_export_stream?: Maybe<Array<Members_Export>>;
  /** fetch data from the table: "members.group" */
  members_group?: Maybe<Array<Members_Group>>;
  /** fetch data from the table: "members.group" using primary key columns */
  members_group_by_pk?: Maybe<Members_Group>;
  /** fetch data from the table in a streaming manner: "members.group" */
  members_group_stream?: Maybe<Array<Members_Group>>;
  /** fetch data from the table: "members.member" */
  members_member?: Maybe<Array<Members_Member>>;
  /** fetch aggregated fields from the table: "members.member" */
  members_member_aggregate?: Maybe<Members_Member_Aggregate>;
  /** fetch data from the table: "members.member" using primary key columns */
  members_member_by_pk?: Maybe<Members_Member>;
  /** fetch data from the table: "members.member_in_group" */
  members_member_in_group?: Maybe<Array<Members_Member_In_Group>>;
  /** fetch data from the table: "members.member_in_group" using primary key columns */
  members_member_in_group_by_pk?: Maybe<Members_Member_In_Group>;
  /** fetch data from the table in a streaming manner: "members.member_in_group" */
  members_member_in_group_stream?: Maybe<Array<Members_Member_In_Group>>;
  /** fetch data from the table in a streaming manner: "members.member" */
  members_member_stream?: Maybe<Array<Members_Member>>;
  /** fetch data from the table: "members.team_official_reporter" */
  members_team_official_reporter?: Maybe<Array<Members_Team_Official_Reporter>>;
  /** fetch aggregated fields from the table: "members.team_official_reporter" */
  members_team_official_reporter_aggregate?: Maybe<Members_Team_Official_Reporter_Aggregate>;
  /** fetch data from the table: "members.team_official_reporter" using primary key columns */
  members_team_official_reporter_by_pk?: Maybe<Members_Team_Official_Reporter>;
  /** fetch data from the table in a streaming manner: "members.team_official_reporter" */
  members_team_official_reporter_stream?: Maybe<
    Array<Members_Team_Official_Reporter>
  >;
  /** fetch data from the table: "news.news" */
  news_news?: Maybe<Array<News_News>>;
  /** fetch aggregated fields from the table: "news.news" */
  news_news_aggregate?: Maybe<News_News_Aggregate>;
  /** fetch data from the table: "news.news" using primary key columns */
  news_news_by_pk?: Maybe<News_News>;
  /** fetch data from the table in a streaming manner: "news.news" */
  news_news_stream?: Maybe<Array<News_News>>;
  /** fetch data from the table: "notifications.endpoint" */
  notifications_endpoint?: Maybe<Array<Notifications_Endpoint>>;
  /** fetch data from the table: "notifications.endpoint" using primary key columns */
  notifications_endpoint_by_pk?: Maybe<Notifications_Endpoint>;
  /** fetch data from the table in a streaming manner: "notifications.endpoint" */
  notifications_endpoint_stream?: Maybe<Array<Notifications_Endpoint>>;
  /** fetch data from the table: "permissions.member_permission" */
  permissions_member_permission?: Maybe<Array<Permissions_Member_Permission>>;
  /** fetch data from the table: "permissions.member_permission" using primary key columns */
  permissions_member_permission_by_pk?: Maybe<Permissions_Member_Permission>;
  /** fetch data from the table in a streaming manner: "permissions.member_permission" */
  permissions_member_permission_stream?: Maybe<
    Array<Permissions_Member_Permission>
  >;
  /** fetch data from the table: "permissions.member_role" */
  permissions_member_role?: Maybe<Array<Permissions_Member_Role>>;
  /** fetch data from the table: "permissions.member_role" using primary key columns */
  permissions_member_role_by_pk?: Maybe<Permissions_Member_Role>;
  /** fetch data from the table in a streaming manner: "permissions.member_role" */
  permissions_member_role_stream?: Maybe<Array<Permissions_Member_Role>>;
  /** fetch data from the table: "permissions.role" */
  permissions_role?: Maybe<Array<Permissions_Role>>;
  /** fetch data from the table: "permissions.role" using primary key columns */
  permissions_role_by_pk?: Maybe<Permissions_Role>;
  /** fetch data from the table: "permissions.role_permission" */
  permissions_role_permission?: Maybe<Array<Permissions_Role_Permission>>;
  /** fetch data from the table: "permissions.role_permission" using primary key columns */
  permissions_role_permission_by_pk?: Maybe<Permissions_Role_Permission>;
  /** fetch data from the table in a streaming manner: "permissions.role_permission" */
  permissions_role_permission_stream?: Maybe<
    Array<Permissions_Role_Permission>
  >;
  /** fetch data from the table in a streaming manner: "permissions.role" */
  permissions_role_stream?: Maybe<Array<Permissions_Role>>;
  /** fetch data from the table: "places.city" */
  places_city?: Maybe<Array<Places_City>>;
  /** fetch data from the table: "places.city" using primary key columns */
  places_city_by_pk?: Maybe<Places_City>;
  /** fetch data from the table in a streaming manner: "places.city" */
  places_city_stream?: Maybe<Array<Places_City>>;
  /** fetch data from the table: "places.country" */
  places_country?: Maybe<Array<Places_Country>>;
  /** fetch data from the table: "places.country" using primary key columns */
  places_country_by_pk?: Maybe<Places_Country>;
  /** fetch data from the table in a streaming manner: "places.country" */
  places_country_stream?: Maybe<Array<Places_Country>>;
  /** fetch data from the table: "places.department" */
  places_department?: Maybe<Array<Places_Department>>;
  /** fetch data from the table: "places.department" using primary key columns */
  places_department_by_pk?: Maybe<Places_Department>;
  /** fetch data from the table in a streaming manner: "places.department" */
  places_department_stream?: Maybe<Array<Places_Department>>;
  /** fetch data from the table: "places.equipment" */
  places_equipment?: Maybe<Array<Places_Equipment>>;
  /** fetch data from the table: "places.equipment" using primary key columns */
  places_equipment_by_pk?: Maybe<Places_Equipment>;
  /** fetch data from the table in a streaming manner: "places.equipment" */
  places_equipment_stream?: Maybe<Array<Places_Equipment>>;
  /** fetch data from the table: "places.place" */
  places_place?: Maybe<Array<Places_Place>>;
  /** fetch data from the table: "places.place" using primary key columns */
  places_place_by_pk?: Maybe<Places_Place>;
  /** fetch data from the table in a streaming manner: "places.place" */
  places_place_stream?: Maybe<Array<Places_Place>>;
  /** fetch data from the table: "places.region" */
  places_region?: Maybe<Array<Places_Region>>;
  /** fetch data from the table: "places.region" using primary key columns */
  places_region_by_pk?: Maybe<Places_Region>;
  /** fetch data from the table in a streaming manner: "places.region" */
  places_region_stream?: Maybe<Array<Places_Region>>;
  /** fetch data from the table: "scraping.championships_clubchampionshipexception" */
  scraping_championships_clubchampionshipexception?: Maybe<
    Array<Scraping_Championships_Clubchampionshipexception>
  >;
  /** fetch data from the table: "scraping.championships_clubchampionshipexception" using primary key columns */
  scraping_championships_clubchampionshipexception_by_pk?: Maybe<Scraping_Championships_Clubchampionshipexception>;
  /** fetch data from the table: "scraping.championships_clubchampionshipexception_championships" */
  scraping_championships_clubchampionshipexception_championships?: Maybe<
    Array<Scraping_Championships_Clubchampionshipexception_Championships>
  >;
  /** fetch data from the table in a streaming manner: "scraping.championships_clubchampionshipexception_championships" */
  scraping_championships_clubchampionshipexception_championships_stream?: Maybe<
    Array<Scraping_Championships_Clubchampionshipexception_Championships>
  >;
  /** fetch data from the table in a streaming manner: "scraping.championships_clubchampionshipexception" */
  scraping_championships_clubchampionshipexception_stream?: Maybe<
    Array<Scraping_Championships_Clubchampionshipexception>
  >;
  /** fetch data from the table: "sms.club_sms_credit" */
  sms_club_sms_credit?: Maybe<Array<Sms_Club_Sms_Credit>>;
  /** fetch data from the table: "sms.club_sms_credit" using primary key columns */
  sms_club_sms_credit_by_pk?: Maybe<Sms_Club_Sms_Credit>;
  /** fetch data from the table in a streaming manner: "sms.club_sms_credit" */
  sms_club_sms_credit_stream?: Maybe<Array<Sms_Club_Sms_Credit>>;
  /** fetch data from the table: "sms.event" */
  sms_event?: Maybe<Array<Sms_Event>>;
  /** fetch data from the table: "sms.event" using primary key columns */
  sms_event_by_pk?: Maybe<Sms_Event>;
  /** fetch data from the table in a streaming manner: "sms.event" */
  sms_event_stream?: Maybe<Array<Sms_Event>>;
  /** fetch data from the table: "sms.message" */
  sms_message?: Maybe<Array<Sms_Message>>;
  /** fetch data from the table: "sms.message" using primary key columns */
  sms_message_by_pk?: Maybe<Sms_Message>;
  /** fetch data from the table in a streaming manner: "sms.message" */
  sms_message_stream?: Maybe<Array<Sms_Message>>;
  /** fetch data from the table: "socials.facebook" */
  socials_facebook?: Maybe<Array<Socials_Facebook>>;
  /** fetch data from the table: "socials.facebook" using primary key columns */
  socials_facebook_by_pk?: Maybe<Socials_Facebook>;
  /** fetch data from the table in a streaming manner: "socials.facebook" */
  socials_facebook_stream?: Maybe<Array<Socials_Facebook>>;
  /** fetch data from the table: "socials.post" */
  socials_post?: Maybe<Array<Socials_Post>>;
  /** fetch data from the table: "socials.post" using primary key columns */
  socials_post_by_pk?: Maybe<Socials_Post>;
  /** fetch data from the table in a streaming manner: "socials.post" */
  socials_post_stream?: Maybe<Array<Socials_Post>>;
  /** fetch data from the table: "sponsors.category" */
  sponsors_category?: Maybe<Array<Sponsors_Category>>;
  /** fetch aggregated fields from the table: "sponsors.category" */
  sponsors_category_aggregate?: Maybe<Sponsors_Category_Aggregate>;
  /** fetch data from the table: "sponsors.category" using primary key columns */
  sponsors_category_by_pk?: Maybe<Sponsors_Category>;
  /** fetch data from the table in a streaming manner: "sponsors.category" */
  sponsors_category_stream?: Maybe<Array<Sponsors_Category>>;
  /** fetch data from the table: "sponsors.sponsor" */
  sponsors_sponsor?: Maybe<Array<Sponsors_Sponsor>>;
  /** fetch data from the table: "sponsors.sponsor" using primary key columns */
  sponsors_sponsor_by_pk?: Maybe<Sponsors_Sponsor>;
  /** fetch data from the table in a streaming manner: "sponsors.sponsor" */
  sponsors_sponsor_stream?: Maybe<Array<Sponsors_Sponsor>>;
  /** fetch data from the table: "sponsors.team_sponsor" */
  sponsors_team_sponsor?: Maybe<Array<Sponsors_Team_Sponsor>>;
  /** fetch data from the table: "sponsors.team_sponsor" using primary key columns */
  sponsors_team_sponsor_by_pk?: Maybe<Sponsors_Team_Sponsor>;
  /** fetch data from the table in a streaming manner: "sponsors.team_sponsor" */
  sponsors_team_sponsor_stream?: Maybe<Array<Sponsors_Team_Sponsor>>;
  /** fetch data from the table: "stats.action" */
  stats_action?: Maybe<Array<Stats_Action>>;
  /** fetch aggregated fields from the table: "stats.action" */
  stats_action_aggregate?: Maybe<Stats_Action_Aggregate>;
  /** fetch data from the table: "stats.action" using primary key columns */
  stats_action_by_pk?: Maybe<Stats_Action>;
  /** fetch data from the table in a streaming manner: "stats.action" */
  stats_action_stream?: Maybe<Array<Stats_Action>>;
  /** fetch data from the table: "tasks.candidate_group" */
  tasks_candidate_group?: Maybe<Array<Tasks_Candidate_Group>>;
  /** fetch data from the table: "tasks.candidate_group" using primary key columns */
  tasks_candidate_group_by_pk?: Maybe<Tasks_Candidate_Group>;
  /** fetch data from the table in a streaming manner: "tasks.candidate_group" */
  tasks_candidate_group_stream?: Maybe<Array<Tasks_Candidate_Group>>;
  /** fetch data from the table: "tasks.candidate_team" */
  tasks_candidate_team?: Maybe<Array<Tasks_Candidate_Team>>;
  /** fetch data from the table: "tasks.candidate_team" using primary key columns */
  tasks_candidate_team_by_pk?: Maybe<Tasks_Candidate_Team>;
  /** fetch data from the table in a streaming manner: "tasks.candidate_team" */
  tasks_candidate_team_stream?: Maybe<Array<Tasks_Candidate_Team>>;
  /** fetch data from the table: "tasks.job" */
  tasks_job?: Maybe<Array<Tasks_Job>>;
  /** fetch data from the table: "tasks.job" using primary key columns */
  tasks_job_by_pk?: Maybe<Tasks_Job>;
  /** fetch data from the table in a streaming manner: "tasks.job" */
  tasks_job_stream?: Maybe<Array<Tasks_Job>>;
  /** fetch data from the table: "tasks.member_task_count" */
  tasks_member_task_count?: Maybe<Array<Tasks_Member_Task_Count>>;
  /** fetch data from the table: "tasks.member_task_count" using primary key columns */
  tasks_member_task_count_by_pk?: Maybe<Tasks_Member_Task_Count>;
  /** fetch data from the table in a streaming manner: "tasks.member_task_count" */
  tasks_member_task_count_stream?: Maybe<Array<Tasks_Member_Task_Count>>;
  /** fetch data from the table: "tasks.task" */
  tasks_task?: Maybe<Array<Tasks_Task>>;
  /** fetch aggregated fields from the table: "tasks.task" */
  tasks_task_aggregate?: Maybe<Tasks_Task_Aggregate>;
  /** fetch data from the table: "tasks.task" using primary key columns */
  tasks_task_by_pk?: Maybe<Tasks_Task>;
  /** fetch data from the table in a streaming manner: "tasks.task" */
  tasks_task_stream?: Maybe<Array<Tasks_Task>>;
  /** fetch data from the table: "tasks.team_task" */
  tasks_team_task?: Maybe<Array<Tasks_Team_Task>>;
  /** fetch data from the table: "tasks.team_task" using primary key columns */
  tasks_team_task_by_pk?: Maybe<Tasks_Team_Task>;
  /** fetch data from the table in a streaming manner: "tasks.team_task" */
  tasks_team_task_stream?: Maybe<Array<Tasks_Team_Task>>;
  /** fetch data from the table: "tasks.volunteer" */
  tasks_volunteer?: Maybe<Array<Tasks_Volunteer>>;
  /** fetch data from the table: "tasks.volunteer" using primary key columns */
  tasks_volunteer_by_pk?: Maybe<Tasks_Volunteer>;
  /** fetch data from the table in a streaming manner: "tasks.volunteer" */
  tasks_volunteer_stream?: Maybe<Array<Tasks_Volunteer>>;
  /** fetch data from the table: "teams.team_detail" using primary key columns */
  team_detail_by_pk_deprecated?: Maybe<Teams_Team_Detail>;
  /** fetch data from the table: "teams.authorized_group" */
  teams_authorized_group?: Maybe<Array<Teams_Authorized_Group>>;
  /** fetch data from the table: "teams.authorized_group" using primary key columns */
  teams_authorized_group_by_pk?: Maybe<Teams_Authorized_Group>;
  /** fetch data from the table in a streaming manner: "teams.authorized_group" */
  teams_authorized_group_stream?: Maybe<Array<Teams_Authorized_Group>>;
  /** fetch data from the table: "teams.authorized_team" */
  teams_authorized_team?: Maybe<Array<Teams_Authorized_Team>>;
  /** fetch data from the table: "teams.authorized_team" using primary key columns */
  teams_authorized_team_by_pk?: Maybe<Teams_Authorized_Team>;
  /** fetch data from the table in a streaming manner: "teams.authorized_team" */
  teams_authorized_team_stream?: Maybe<Array<Teams_Authorized_Team>>;
  /** fetch data from the table: "teams.club_group" */
  teams_club_group?: Maybe<Array<Teams_Club_Group>>;
  /** fetch data from the table: "teams.club_group" using primary key columns */
  teams_club_group_by_pk?: Maybe<Teams_Club_Group>;
  /** fetch data from the table in a streaming manner: "teams.club_group" */
  teams_club_group_stream?: Maybe<Array<Teams_Club_Group>>;
  /** fetch data from the table: "teams.fan" */
  teams_fan?: Maybe<Array<Teams_Fan>>;
  /** fetch data from the table: "teams.fan" using primary key columns */
  teams_fan_by_pk?: Maybe<Teams_Fan>;
  /** fetch data from the table in a streaming manner: "teams.fan" */
  teams_fan_stream?: Maybe<Array<Teams_Fan>>;
  /** fetch data from the table: "teams.level" */
  teams_level?: Maybe<Array<Teams_Level>>;
  /** fetch data from the table: "teams.level" using primary key columns */
  teams_level_by_pk?: Maybe<Teams_Level>;
  /** fetch data from the table in a streaming manner: "teams.level" */
  teams_level_stream?: Maybe<Array<Teams_Level>>;
  /** fetch data from the table: "teams.official" */
  teams_official?: Maybe<Array<Teams_Official>>;
  /** fetch data from the table: "teams.official" using primary key columns */
  teams_official_by_pk?: Maybe<Teams_Official>;
  /** fetch data from the table in a streaming manner: "teams.official" */
  teams_official_stream?: Maybe<Array<Teams_Official>>;
  /** fetch data from the table: "teams.team" */
  teams_team?: Maybe<Array<Teams_Team>>;
  /** fetch data from the table: "teams.team_action_permission" */
  teams_team_action_permission?: Maybe<Array<Teams_Team_Action_Permission>>;
  /** fetch data from the table: "teams.team_action_permission" using primary key columns */
  teams_team_action_permission_by_pk?: Maybe<Teams_Team_Action_Permission>;
  /** fetch data from the table in a streaming manner: "teams.team_action_permission" */
  teams_team_action_permission_stream?: Maybe<
    Array<Teams_Team_Action_Permission>
  >;
  /** fetch data from the table: "teams.team" using primary key columns */
  teams_team_by_pk?: Maybe<Teams_Team>;
  /** fetch data from the table: "teams.team_detail" */
  teams_team_detail?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_by_club_id" which returns "teams.team_detail" */
  teams_team_detail_by_club_id?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_by_member_id" which returns "teams.team_detail" */
  teams_team_detail_by_member_id?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_by_pk" which returns "teams.team_detail" */
  teams_team_detail_by_pk?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_by_team_id" which returns "teams.team_detail" */
  teams_team_detail_by_team_id?: Maybe<Array<Teams_Team_Detail>>;
  /** fetch data from the table in a streaming manner: "teams.team_detail" */
  teams_team_detail_stream?: Maybe<Array<Teams_Team_Detail>>;
  /** execute function "teams.team_detail_suggested" which returns "teams.team_detail" */
  teams_team_detail_suggested?: Maybe<Array<Teams_Team_Detail>>;
  /** fetch data from the table: "teams.team_has_fans" */
  teams_team_has_fans?: Maybe<Array<Teams_Team_Has_Fans>>;
  /** fetch data from the table: "teams.team_has_fans" using primary key columns */
  teams_team_has_fans_by_pk?: Maybe<Teams_Team_Has_Fans>;
  /** fetch data from the table in a streaming manner: "teams.team_has_fans" */
  teams_team_has_fans_stream?: Maybe<Array<Teams_Team_Has_Fans>>;
  /** fetch data from the table: "teams.team_in_season" */
  teams_team_in_season?: Maybe<Array<Teams_Team_In_Season>>;
  /** fetch data from the table: "teams.team_in_season" using primary key columns */
  teams_team_in_season_by_pk?: Maybe<Teams_Team_In_Season>;
  /** fetch data from the table: "teams.team_in_season_has_member" */
  teams_team_in_season_has_member?: Maybe<
    Array<Teams_Team_In_Season_Has_Member>
  >;
  /** fetch aggregated fields from the table: "teams.team_in_season_has_member" */
  teams_team_in_season_has_member_aggregate?: Maybe<Teams_Team_In_Season_Has_Member_Aggregate>;
  /** fetch data from the table: "teams.team_in_season_has_member" using primary key columns */
  teams_team_in_season_has_member_by_pk?: Maybe<Teams_Team_In_Season_Has_Member>;
  /** fetch data from the table in a streaming manner: "teams.team_in_season_has_member" */
  teams_team_in_season_has_member_stream?: Maybe<
    Array<Teams_Team_In_Season_Has_Member>
  >;
  /** fetch data from the table in a streaming manner: "teams.team_in_season" */
  teams_team_in_season_stream?: Maybe<Array<Teams_Team_In_Season>>;
  /** fetch data from the table: "teams.team_in_season_translation" */
  teams_team_in_season_translation?: Maybe<
    Array<Teams_Team_In_Season_Translation>
  >;
  /** fetch data from the table: "teams.team_in_season_translation" using primary key columns */
  teams_team_in_season_translation_by_pk?: Maybe<Teams_Team_In_Season_Translation>;
  /** fetch data from the table in a streaming manner: "teams.team_in_season_translation" */
  teams_team_in_season_translation_stream?: Maybe<
    Array<Teams_Team_In_Season_Translation>
  >;
  /** fetch data from the table: "teams.team_official" */
  teams_team_official?: Maybe<Array<Teams_Team_Official>>;
  /** fetch data from the table: "teams.team_official" using primary key columns */
  teams_team_official_by_pk?: Maybe<Teams_Team_Official>;
  /** fetch data from the table in a streaming manner: "teams.team_official" */
  teams_team_official_stream?: Maybe<Array<Teams_Team_Official>>;
  /** fetch data from the table in a streaming manner: "teams.team" */
  teams_team_stream?: Maybe<Array<Teams_Team>>;
  /** fetch data from the table: "tournaments.challenge" */
  tournaments_challenge?: Maybe<Array<Tournaments_Challenge>>;
  /** fetch data from the table: "tournaments.challenge" using primary key columns */
  tournaments_challenge_by_pk?: Maybe<Tournaments_Challenge>;
  /** fetch data from the table: "tournaments.challenge_rule" */
  tournaments_challenge_rule?: Maybe<Array<Tournaments_Challenge_Rule>>;
  /** fetch data from the table: "tournaments.challenge_rule" using primary key columns */
  tournaments_challenge_rule_by_pk?: Maybe<Tournaments_Challenge_Rule>;
  /** fetch data from the table in a streaming manner: "tournaments.challenge_rule" */
  tournaments_challenge_rule_stream?: Maybe<Array<Tournaments_Challenge_Rule>>;
  /** fetch data from the table in a streaming manner: "tournaments.challenge" */
  tournaments_challenge_stream?: Maybe<Array<Tournaments_Challenge>>;
  /** fetch data from the table: "tournaments.engagement" */
  tournaments_engagement?: Maybe<Array<Tournaments_Engagement>>;
  /** fetch aggregated fields from the table: "tournaments.engagement" */
  tournaments_engagement_aggregate?: Maybe<Tournaments_Engagement_Aggregate>;
  /** fetch data from the table: "tournaments.engagement" using primary key columns */
  tournaments_engagement_by_pk?: Maybe<Tournaments_Engagement>;
  /** fetch data from the table in a streaming manner: "tournaments.engagement" */
  tournaments_engagement_stream?: Maybe<Array<Tournaments_Engagement>>;
  /** fetch data from the table: "tournaments.game" */
  tournaments_game?: Maybe<Array<Tournaments_Game>>;
  /** fetch data from the table: "tournaments.game" using primary key columns */
  tournaments_game_by_pk?: Maybe<Tournaments_Game>;
  /** fetch data from the table in a streaming manner: "tournaments.game" */
  tournaments_game_stream?: Maybe<Array<Tournaments_Game>>;
  /** fetch data from the table: "tournaments.games_in_phase" */
  tournaments_games_in_phase?: Maybe<Array<Tournaments_Games_In_Phase>>;
  /** fetch data from the table in a streaming manner: "tournaments.games_in_phase" */
  tournaments_games_in_phase_stream?: Maybe<Array<Tournaments_Games_In_Phase>>;
  /** Enregistre une visite sur la page du tournoi */
  tournaments_new_hit?: Maybe<Tournaments_New_Hit>;
  /** fetch data from the table: "tournaments.official_reporter_v2" */
  tournaments_official_reporter_v2?: Maybe<
    Array<Tournaments_Official_Reporter_V2>
  >;
  /** fetch data from the table: "tournaments.official_reporter_v2" using primary key columns */
  tournaments_official_reporter_v2_by_pk?: Maybe<Tournaments_Official_Reporter_V2>;
  /** fetch data from the table in a streaming manner: "tournaments.official_reporter_v2" */
  tournaments_official_reporter_v2_stream?: Maybe<
    Array<Tournaments_Official_Reporter_V2>
  >;
  /** fetch data from the table: "tournaments.phase" */
  tournaments_phase?: Maybe<Array<Tournaments_Phase>>;
  /** fetch aggregated fields from the table: "tournaments.phase" */
  tournaments_phase_aggregate?: Maybe<Tournaments_Phase_Aggregate>;
  /** fetch data from the table: "tournaments.phase" using primary key columns */
  tournaments_phase_by_pk?: Maybe<Tournaments_Phase>;
  /** fetch data from the table in a streaming manner: "tournaments.phase" */
  tournaments_phase_stream?: Maybe<Array<Tournaments_Phase>>;
  /** fetch data from the table: "tournaments.place" */
  tournaments_place?: Maybe<Array<Tournaments_Place>>;
  /** fetch data from the table: "tournaments.place" using primary key columns */
  tournaments_place_by_pk?: Maybe<Tournaments_Place>;
  /** fetch data from the table in a streaming manner: "tournaments.place" */
  tournaments_place_stream?: Maybe<Array<Tournaments_Place>>;
  /** fetch data from the table: "tournaments.ranking" */
  tournaments_ranking?: Maybe<Array<Tournaments_Ranking>>;
  /** fetch data from the table: "tournaments.ranking" using primary key columns */
  tournaments_ranking_by_pk?: Maybe<Tournaments_Ranking>;
  /** fetch data from the table: "tournaments.ranking_points" */
  tournaments_ranking_points?: Maybe<Array<Tournaments_Ranking_Points>>;
  /** fetch data from the table: "tournaments.ranking_points" using primary key columns */
  tournaments_ranking_points_by_pk?: Maybe<Tournaments_Ranking_Points>;
  /** fetch data from the table in a streaming manner: "tournaments.ranking_points" */
  tournaments_ranking_points_stream?: Maybe<Array<Tournaments_Ranking_Points>>;
  /** fetch data from the table: "tournaments.ranking_rule" */
  tournaments_ranking_rule?: Maybe<Array<Tournaments_Ranking_Rule>>;
  /** fetch data from the table: "tournaments.ranking_rule" using primary key columns */
  tournaments_ranking_rule_by_pk?: Maybe<Tournaments_Ranking_Rule>;
  /** fetch data from the table in a streaming manner: "tournaments.ranking_rule" */
  tournaments_ranking_rule_stream?: Maybe<Array<Tournaments_Ranking_Rule>>;
  /** fetch data from the table in a streaming manner: "tournaments.ranking" */
  tournaments_ranking_stream?: Maybe<Array<Tournaments_Ranking>>;
  /** fetch data from the table: "tournaments.team" */
  tournaments_team?: Maybe<Array<Tournaments_Team>>;
  /** fetch data from the table: "tournaments.team" using primary key columns */
  tournaments_team_by_pk?: Maybe<Tournaments_Team>;
  /** fetch data from the table in a streaming manner: "tournaments.team" */
  tournaments_team_stream?: Maybe<Array<Tournaments_Team>>;
  /** fetch data from the table: "tournaments.tournament" */
  tournaments_tournament?: Maybe<Array<Tournaments_Tournament>>;
  /** fetch data from the table: "tournaments.tournament" using primary key columns */
  tournaments_tournament_by_pk?: Maybe<Tournaments_Tournament>;
  /** fetch data from the table: "tournaments.tournament_place" */
  tournaments_tournament_place?: Maybe<Array<Tournaments_Tournament_Place>>;
  /** fetch data from the table in a streaming manner: "tournaments.tournament_place" */
  tournaments_tournament_place_stream?: Maybe<
    Array<Tournaments_Tournament_Place>
  >;
  /** fetch data from the table in a streaming manner: "tournaments.tournament" */
  tournaments_tournament_stream?: Maybe<Array<Tournaments_Tournament>>;
  /** execute function "urls.club_by_direct_score_url" which returns "urls.url_detail" */
  urls_club_by_direct_score_url?: Maybe<Array<Urls_Url_Detail>>;
  /** execute function "urls.event_by_direct_score_same_teams_url" which returns "urls.url_detail" */
  urls_event_by_direct_score_same_teams_url?: Maybe<Array<Urls_Url_Detail>>;
  /** execute function "urls.event_by_direct_score_url" which returns "urls.url_detail" */
  urls_event_by_direct_score_url?: Maybe<Array<Urls_Url_Detail>>;
  /** execute function "urls.team_in_season_by_direct_score_url" which returns "urls.url_detail" */
  urls_team_in_season_by_direct_score_url?: Maybe<Array<Urls_Url_Detail>>;
  /** fetch data from the table: "urls.url_detail" */
  urls_url_detail?: Maybe<Array<Urls_Url_Detail>>;
  /** fetch data from the table: "urls.url_detail" using primary key columns */
  urls_url_detail_by_pk?: Maybe<Urls_Url_Detail>;
  /** fetch data from the table in a streaming manner: "urls.url_detail" */
  urls_url_detail_stream?: Maybe<Array<Urls_Url_Detail>>;
  /** fetch data from the table: "users.convocation_progress" */
  users_convocation_progress?: Maybe<Array<Users_Convocation_Progress>>;
  /** fetch data from the table: "users.convocation_progress" using primary key columns */
  users_convocation_progress_by_pk?: Maybe<Users_Convocation_Progress>;
  /** fetch data from the table in a streaming manner: "users.convocation_progress" */
  users_convocation_progress_stream?: Maybe<Array<Users_Convocation_Progress>>;
  /** fetch data from the table: "users.member_invitation" */
  users_member_invitation?: Maybe<Array<Users_Member_Invitation>>;
  /** fetch data from the table: "users.member_invitation" using primary key columns */
  users_member_invitation_by_pk?: Maybe<Users_Member_Invitation>;
  /** fetch data from the table in a streaming manner: "users.member_invitation" */
  users_member_invitation_stream?: Maybe<Array<Users_Member_Invitation>>;
  /** fetch data from the table: "users.user" */
  users_user?: Maybe<Array<Users_User>>;
  /** fetch data from the table: "users.user" using primary key columns */
  users_user_by_pk?: Maybe<Users_User>;
  /** fetch data from the table in a streaming manner: "users.user" */
  users_user_stream?: Maybe<Array<Users_User>>;
  /** fetch data from the table: "widgets.club_settings" */
  widgets_club_settings?: Maybe<Array<Widgets_Club_Settings>>;
  /** fetch data from the table: "widgets.club_settings" using primary key columns */
  widgets_club_settings_by_pk?: Maybe<Widgets_Club_Settings>;
  /** fetch data from the table in a streaming manner: "widgets.club_settings" */
  widgets_club_settings_stream?: Maybe<Array<Widgets_Club_Settings>>;
  /** fetch data from the table: "widgets.convocation_settings" */
  widgets_convocation_settings?: Maybe<Array<Widgets_Convocation_Settings>>;
  /** fetch data from the table: "widgets.convocation_settings" using primary key columns */
  widgets_convocation_settings_by_pk?: Maybe<Widgets_Convocation_Settings>;
  /** fetch data from the table in a streaming manner: "widgets.convocation_settings" */
  widgets_convocation_settings_stream?: Maybe<
    Array<Widgets_Convocation_Settings>
  >;
  /** fetch data from the table: "widgets.widget" */
  widgets_widget?: Maybe<Array<Widgets_Widget>>;
  /** fetch data from the table: "widgets.widget" using primary key columns */
  widgets_widget_by_pk?: Maybe<Widgets_Widget>;
  /** fetch data from the table: "widgets.widget_has_competition" */
  widgets_widget_has_competition?: Maybe<Array<Widgets_Widget_Has_Competition>>;
  /** fetch data from the table: "widgets.widget_has_competition" using primary key columns */
  widgets_widget_has_competition_by_pk?: Maybe<Widgets_Widget_Has_Competition>;
  /** fetch data from the table in a streaming manner: "widgets.widget_has_competition" */
  widgets_widget_has_competition_stream?: Maybe<
    Array<Widgets_Widget_Has_Competition>
  >;
  /** fetch data from the table: "widgets.widget_has_team" */
  widgets_widget_has_team?: Maybe<Array<Widgets_Widget_Has_Team>>;
  /** fetch data from the table: "widgets.widget_has_team" using primary key columns */
  widgets_widget_has_team_by_pk?: Maybe<Widgets_Widget_Has_Team>;
  /** fetch data from the table in a streaming manner: "widgets.widget_has_team" */
  widgets_widget_has_team_stream?: Maybe<Array<Widgets_Widget_Has_Team>>;
  /** fetch data from the table: "widgets.widget_live" */
  widgets_widget_live?: Maybe<Array<Widgets_Widget_Live>>;
  /** fetch data from the table: "widgets.widget_live" using primary key columns */
  widgets_widget_live_by_pk?: Maybe<Widgets_Widget_Live>;
  /** fetch data from the table in a streaming manner: "widgets.widget_live" */
  widgets_widget_live_stream?: Maybe<Array<Widgets_Widget_Live>>;
  /** fetch data from the table in a streaming manner: "widgets.widget" */
  widgets_widget_stream?: Maybe<Array<Widgets_Widget>>;
  /** fetch data from the table: "widgets.widget_team" */
  widgets_widget_team?: Maybe<Array<Widgets_Widget_Team>>;
  /** fetch data from the table: "widgets.widget_team" using primary key columns */
  widgets_widget_team_by_pk?: Maybe<Widgets_Widget_Team>;
  /** fetch data from the table in a streaming manner: "widgets.widget_team" */
  widgets_widget_team_stream?: Maybe<Array<Widgets_Widget_Team>>;
};

export type Subscription_RootApps_VersionArgs = {
  distinct_on?: InputMaybe<Array<Apps_Version_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Apps_Version_Order_By>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};

export type Subscription_RootApps_Version_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootApps_Version_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Apps_Version_Stream_Cursor_Input>>;
  where?: InputMaybe<Apps_Version_Bool_Exp>;
};

export type Subscription_RootBilling_Club_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Billing_Club_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Club_Customer_Order_By>>;
  where?: InputMaybe<Billing_Club_Customer_Bool_Exp>;
};

export type Subscription_RootBilling_Club_Customer_By_PkArgs = {
  customer_ptr_id: Scalars['Int']['input'];
};

export type Subscription_RootBilling_Club_Customer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Billing_Club_Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_Club_Customer_Bool_Exp>;
};

export type Subscription_RootBilling_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Billing_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Billing_Customer_Order_By>>;
  where?: InputMaybe<Billing_Customer_Bool_Exp>;
};

export type Subscription_RootBilling_Customer_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootBilling_Customer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Billing_Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_Customer_Bool_Exp>;
};

export type Subscription_RootClubhouse_Club_HouseArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Club_House_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Club_House_Order_By>>;
  where?: InputMaybe<Clubhouse_Club_House_Bool_Exp>;
};

export type Subscription_RootClubhouse_Club_House_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubhouse_Club_House_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubhouse_Club_House_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubhouse_Club_House_Bool_Exp>;
};

export type Subscription_RootClubhouse_ScreenArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Screen_Order_By>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

export type Subscription_RootClubhouse_Screen_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Screen_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Screen_Order_By>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

export type Subscription_RootClubhouse_Screen_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubhouse_Screen_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubhouse_Screen_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubhouse_Screen_Bool_Exp>;
};

export type Subscription_RootClubhouse_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Clubhouse_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubhouse_Template_Order_By>>;
  where?: InputMaybe<Clubhouse_Template_Bool_Exp>;
};

export type Subscription_RootClubhouse_Template_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubhouse_Template_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubhouse_Template_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubhouse_Template_Bool_Exp>;
};

export type Subscription_RootClubs_AdminArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Admin_Bool_Exp>;
};

export type Subscription_RootClubs_Admin_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubs_Admin_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubs_Admin_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Admin_Bool_Exp>;
};

export type Subscription_RootClubs_ClubArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Order_By>>;
  where?: InputMaybe<Clubs_Club_Bool_Exp>;
};

export type Subscription_RootClubs_Club_AdminArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

export type Subscription_RootClubs_Club_Admin_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubs_Club_Admin_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubs_Club_Admin_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

export type Subscription_RootClubs_Club_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Order_By>>;
  where?: InputMaybe<Clubs_Club_Bool_Exp>;
};

export type Subscription_RootClubs_Club_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubs_Club_DetailArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Detail_Order_By>>;
  where?: InputMaybe<Clubs_Club_Detail_Bool_Exp>;
};

export type Subscription_RootClubs_Club_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubs_Club_Detail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubs_Club_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Club_Detail_Bool_Exp>;
};

export type Subscription_RootClubs_Club_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubs_Club_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Club_Bool_Exp>;
};

export type Subscription_RootClubs_Get_ClubArgs = {
  args: Clubs_Get_Club_Args;
  distinct_on?: InputMaybe<Array<Clubs_Club_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Detail_Order_By>>;
  where?: InputMaybe<Clubs_Club_Detail_Bool_Exp>;
};

export type Subscription_RootClubs_OrganizationArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Organization_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Organization_Order_By>>;
  where?: InputMaybe<Clubs_Organization_Bool_Exp>;
};

export type Subscription_RootClubs_Organization_By_PkArgs = {
  club_ptr_id: Scalars['Int']['input'];
};

export type Subscription_RootClubs_Organization_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubs_Organization_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Organization_Bool_Exp>;
};

export type Subscription_RootClubs_Planner_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Planner_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Planner_Settings_Order_By>>;
  where?: InputMaybe<Clubs_Planner_Settings_Bool_Exp>;
};

export type Subscription_RootClubs_Planner_Settings_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubs_Planner_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubs_Planner_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Planner_Settings_Bool_Exp>;
};

export type Subscription_RootClubs_SeasonArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Season_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Season_Order_By>>;
  where?: InputMaybe<Clubs_Season_Bool_Exp>;
};

export type Subscription_RootClubs_Season_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubs_Season_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubs_Season_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Season_Bool_Exp>;
};

export type Subscription_RootClubs_SportArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Sport_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Sport_Order_By>>;
  where?: InputMaybe<Clubs_Sport_Bool_Exp>;
};

export type Subscription_RootClubs_Sport_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootClubs_Sport_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Clubs_Sport_Stream_Cursor_Input>>;
  where?: InputMaybe<Clubs_Sport_Bool_Exp>;
};

export type Subscription_RootCompetitions_Closest_Event_With_Same_TeamsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Closest_Event_With_Same_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Closest_Event_With_Same_Teams_Order_By>
  >;
  where?: InputMaybe<Competitions_Closest_Event_With_Same_Teams_Bool_Exp>;
};

export type Subscription_RootCompetitions_Closest_Event_With_Same_Teams_StreamArgs =
  {
    batch_size: Scalars['Int']['input'];
    cursor: Array<
      InputMaybe<Competitions_Closest_Event_With_Same_Teams_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Competitions_Closest_Event_With_Same_Teams_Bool_Exp>;
  };

export type Subscription_RootCompetitions_CompetitionArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Competition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Competition_DetailArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_Detail_By_IdArgs = {
  args: Competitions_Competition_Detail_By_Id_Args;
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_Detail_By_Level_For_Season_IdArgs =
  {
    args: Competitions_Competition_Detail_By_Level_For_Season_Id_Args;
    distinct_on?: InputMaybe<
      Array<Competitions_Competition_Detail_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Competition_Detail_By_Level_IdArgs = {
  args: Competitions_Competition_Detail_By_Level_Id_Args;
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Competition_Detail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Competitions_Competition_Detail_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_Has_FansArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Has_Fans_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Has_Fans_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Has_Fans_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_Has_Fans_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Competition_Has_Fans_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Competitions_Competition_Has_Fans_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Competitions_Competition_Has_Fans_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_Has_TeamsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Has_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Has_Teams_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_Has_Teams_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Competition_Has_Teams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Competitions_Competition_Has_Teams_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_StatisticsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Statistics_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Statistics_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Statistics_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_Statistics_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Competitions_Competition_Statistics_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Competitions_Competition_Statistics_Bool_Exp>;
};

export type Subscription_RootCompetitions_Competition_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Competition_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Competition_Bool_Exp>;
};

export type Subscription_RootCompetitions_EventArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Order_By>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Order_By>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Event_Closest_DateArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Event_Closest_Date_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Closest_Date_Order_By>>;
  where?: InputMaybe<Competitions_Event_Closest_Date_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Closest_Date_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Competitions_Event_Closest_Date_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Competitions_Event_Closest_Date_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_DetailArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Detail_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Detail_By_Club_IdArgs = {
  args: Competitions_Event_Detail_By_Club_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Detail_By_Club_Id_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Club_Id_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Club_Id_Between_DatesArgs =
  {
    args: Competitions_Event_Detail_By_Club_Id_Between_Dates_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Club_Id_Between_Dates_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Club_Id_Between_Dates_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_RankArgs =
  {
    args: Competitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_Rank_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_Rank_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Club_Id_Between_Dates_Order_By_Rank_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_IdArgs = {
  args: Competitions_Event_Detail_By_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Detail_By_Id_AggregateArgs = {
  args: Competitions_Event_Detail_By_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Detail_By_Live_RatingArgs = {
  args: Competitions_Event_Detail_By_Live_Rating_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Detail_By_Live_Rating_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Live_Rating_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Live_Rating_And_SportArgs =
  {
    args: Competitions_Event_Detail_By_Live_Rating_And_Sport_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Live_Rating_And_Sport_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Live_Rating_And_Sport_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Event_Detail_By_Round_Day_IdArgs = {
  args: Competitions_Event_Detail_By_Round_Day_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Detail_By_Round_Day_Id_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Round_Day_Id_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Team_IdArgs = {
  args: Competitions_Event_Detail_By_Team_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Detail_By_Team_Id_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Team_Id_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Team_In_Season_IdArgs =
  {
    args: Competitions_Event_Detail_By_Team_In_Season_Id_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_Team_In_Season_Id_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_Team_In_Season_Id_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_User_Favorite_TeamsArgs =
  {
    args: Competitions_Event_Detail_By_User_Favorite_Teams_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_By_User_Favorite_Teams_AggregateArgs =
  {
    args: Competitions_Event_Detail_By_User_Favorite_Teams_Args;
    distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Event_Detail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Event_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Last_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Last_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Last_Update_Order_By>>;
  where?: InputMaybe<Competitions_Event_Last_Update_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Last_Update_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Event_Last_Update_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Event_Last_Update_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_RefereeArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Referee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Referee_Order_By>>;
  where?: InputMaybe<Competitions_Event_Referee_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_Referee_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Event_Referee_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Event_Referee_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Event_Referee_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Event_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_With_Same_TeamsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Event_With_Same_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_With_Same_Teams_Order_By>>;
  where?: InputMaybe<Competitions_Event_With_Same_Teams_Bool_Exp>;
};

export type Subscription_RootCompetitions_Event_With_Same_Teams_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Event_With_Same_Teams_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Competitions_Event_With_Same_Teams_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Competitions_Event_With_Same_Teams_Bool_Exp>;
};

export type Subscription_RootCompetitions_FanArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Fan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Fan_Order_By>>;
  where?: InputMaybe<Competitions_Fan_Bool_Exp>;
};

export type Subscription_RootCompetitions_Fan_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Fan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Fan_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Fan_Bool_Exp>;
};

export type Subscription_RootCompetitions_LeagueArgs = {
  distinct_on?: InputMaybe<Array<Competitions_League_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Order_By>>;
  where?: InputMaybe<Competitions_League_Bool_Exp>;
};

export type Subscription_RootCompetitions_League_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_League_DetailArgs = {
  distinct_on?: InputMaybe<Array<Competitions_League_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Detail_Order_By>>;
  where?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_League_Detail_By_IdArgs = {
  args: Competitions_League_Detail_By_Id_Args;
  distinct_on?: InputMaybe<Array<Competitions_League_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Detail_Order_By>>;
  where?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_League_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_League_Detail_By_Sport_SlugArgs = {
  args: Competitions_League_Detail_By_Sport_Slug_Args;
  distinct_on?: InputMaybe<Array<Competitions_League_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_League_Detail_Order_By>>;
  where?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_League_Detail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_League_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_League_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_League_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_League_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_League_Bool_Exp>;
};

export type Subscription_RootCompetitions_LevelArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_Order_By>>;
  where?: InputMaybe<Competitions_Level_Bool_Exp>;
};

export type Subscription_RootCompetitions_Level_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Level_For_SeasonArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Level_For_Season_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Bool_Exp>;
};

export type Subscription_RootCompetitions_Level_For_Season_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Level_For_Season_DetailArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Level_For_Season_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Level_For_Season_Detail_By_IdArgs = {
  args: Competitions_Level_For_Season_Detail_By_Id_Args;
  distinct_on?: InputMaybe<
    Array<Competitions_Level_For_Season_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Level_For_Season_Detail_By_League_IdArgs =
  {
    args: Competitions_Level_For_Season_Detail_By_League_Id_Args;
    distinct_on?: InputMaybe<
      Array<Competitions_Level_For_Season_Detail_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Level_For_Season_Detail_By_Level_And_SeasonArgs =
  {
    args: Competitions_Level_For_Season_Detail_By_Level_And_Season_Args;
    distinct_on?: InputMaybe<
      Array<Competitions_Level_For_Season_Detail_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Competitions_Level_For_Season_Detail_Order_By>>;
    where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Level_For_Season_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Level_For_Season_Detail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Competitions_Level_For_Season_Detail_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Competitions_Level_For_Season_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Level_For_Season_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Level_For_Season_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Level_For_Season_Bool_Exp>;
};

export type Subscription_RootCompetitions_Level_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Level_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Level_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_TeamArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_Bar_ManagerArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Bar_Manager_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Bar_Manager_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_Bar_Manager_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Playing_Team_Bar_Manager_StreamArgs =
  {
    batch_size: Scalars['Int']['input'];
    cursor: Array<
      InputMaybe<Competitions_Playing_Team_Bar_Manager_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Competitions_Playing_Team_Bar_Manager_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Playing_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Playing_Team_InfoArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Info_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_Info_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Info_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Info_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_Info_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Playing_Team_Info_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Playing_Team_Info_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_RefereeArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Referee_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Referee_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_Referee_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Playing_Team_Referee_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Competitions_Playing_Team_Referee_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Referee_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_Scoring_TableArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Scoring_Table_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Playing_Team_Scoring_Table_Order_By>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_Scoring_Table_By_PkArgs =
  {
    id: Scalars['Int']['input'];
  };

export type Subscription_RootCompetitions_Playing_Team_Scoring_Table_StreamArgs =
  {
    batch_size: Scalars['Int']['input'];
    cursor: Array<
      InputMaybe<Competitions_Playing_Team_Scoring_Table_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Competitions_Playing_Team_Scoring_Table_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Playing_Team_Stadium_ManagerArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Playing_Team_Stadium_Manager_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<
    Array<Competitions_Playing_Team_Stadium_Manager_Order_By>
  >;
  where?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
};

export type Subscription_RootCompetitions_Playing_Team_Stadium_Manager_By_PkArgs =
  {
    id: Scalars['Int']['input'];
  };

export type Subscription_RootCompetitions_Playing_Team_Stadium_Manager_StreamArgs =
  {
    batch_size: Scalars['Int']['input'];
    cursor: Array<
      InputMaybe<Competitions_Playing_Team_Stadium_Manager_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Competitions_Playing_Team_Stadium_Manager_Bool_Exp>;
  };

export type Subscription_RootCompetitions_Playing_Team_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Playing_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

export type Subscription_RootCompetitions_RankingArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Ranking_Order_By>>;
  where?: InputMaybe<Competitions_Ranking_Bool_Exp>;
};

export type Subscription_RootCompetitions_Ranking_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Ranking_DetailArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Ranking_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Ranking_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Ranking_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Ranking_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Ranking_Detail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Ranking_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Ranking_Detail_Bool_Exp>;
};

export type Subscription_RootCompetitions_Ranking_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Ranking_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Ranking_Bool_Exp>;
};

export type Subscription_RootCompetitions_RoundArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

export type Subscription_RootCompetitions_Round_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Round_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Round_Order_By>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

export type Subscription_RootCompetitions_Round_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCompetitions_Round_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Competitions_Round_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitions_Round_Bool_Exp>;
};

export type Subscription_RootEmails_EventArgs = {
  distinct_on?: InputMaybe<Array<Emails_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Emails_Event_Order_By>>;
  where?: InputMaybe<Emails_Event_Bool_Exp>;
};

export type Subscription_RootEmails_Event_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootEmails_Event_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Emails_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Emails_Event_Bool_Exp>;
};

export type Subscription_RootEmails_MessageArgs = {
  distinct_on?: InputMaybe<Array<Emails_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Emails_Message_Order_By>>;
  where?: InputMaybe<Emails_Message_Bool_Exp>;
};

export type Subscription_RootEmails_Message_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootEmails_Message_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Emails_Message_Stream_Cursor_Input>>;
  where?: InputMaybe<Emails_Message_Bool_Exp>;
};

export type Subscription_RootLives_ActionArgs = {
  distinct_on?: InputMaybe<Array<Lives_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Action_Order_By>>;
  where?: InputMaybe<Lives_Action_Bool_Exp>;
};

export type Subscription_RootLives_Action_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Action_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Action_Bool_Exp>;
};

export type Subscription_RootLives_Current_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Current_Reporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Current_Reporters_Order_By>>;
  where?: InputMaybe<Lives_Current_Reporters_Bool_Exp>;
};

export type Subscription_RootLives_Current_Reporters_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Current_Reporters_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Current_Reporters_Bool_Exp>;
};

export type Subscription_RootLives_LiveArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Order_By>>;
  where?: InputMaybe<Lives_Live_Bool_Exp>;
};

export type Subscription_RootLives_Live_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Live_Has_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Has_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Has_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Live_Has_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Live_Has_Reporter_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Live_Has_Reporter_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Live_Has_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Live_HitArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Hit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Hit_Order_By>>;
  where?: InputMaybe<Lives_Live_Hit_Bool_Exp>;
};

export type Subscription_RootLives_Live_Hit_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Live_Hit_CountArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Hit_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Hit_Count_Order_By>>;
  where?: InputMaybe<Lives_Live_Hit_Count_Bool_Exp>;
};

export type Subscription_RootLives_Live_Hit_Count_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Live_Hit_Count_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Live_Hit_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Live_Hit_Count_Bool_Exp>;
};

export type Subscription_RootLives_Live_Hit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Live_Hit_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Live_Hit_Bool_Exp>;
};

export type Subscription_RootLives_Live_Last_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Last_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Last_Update_Order_By>>;
  where?: InputMaybe<Lives_Live_Last_Update_Bool_Exp>;
};

export type Subscription_RootLives_Live_Last_Update_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Live_Last_Update_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Live_Last_Update_Bool_Exp>;
};

export type Subscription_RootLives_Live_PlayerArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Player_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Player_Order_By>>;
  where?: InputMaybe<Lives_Live_Player_Bool_Exp>;
};

export type Subscription_RootLives_Live_Player_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Live_Player_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Live_Player_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Live_Player_Bool_Exp>;
};

export type Subscription_RootLives_Live_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Live_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Live_Bool_Exp>;
};

export type Subscription_RootLives_Live_TeamArgs = {
  distinct_on?: InputMaybe<Array<Lives_Live_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Live_Team_Order_By>>;
  where?: InputMaybe<Lives_Live_Team_Bool_Exp>;
};

export type Subscription_RootLives_Live_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Live_Team_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Live_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Live_Team_Bool_Exp>;
};

export type Subscription_RootLives_Media_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Media_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Media_Official_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Media_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Media_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Media_Official_Reporter_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Media_Official_Reporter_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Media_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Official_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Official_Reporter_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Official_Reporter_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_PostArgs = {
  distinct_on?: InputMaybe<Array<Lives_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Post_Order_By>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

export type Subscription_RootLives_Post_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Post_Order_By>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

export type Subscription_RootLives_Post_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Post_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Post_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Post_Bool_Exp>;
};

export type Subscription_RootLives_Potential_Team_ReportersArgs = {
  distinct_on?: InputMaybe<Array<Lives_Potential_Team_Reporters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Potential_Team_Reporters_Order_By>>;
  where?: InputMaybe<Lives_Potential_Team_Reporters_Bool_Exp>;
};

export type Subscription_RootLives_Potential_Team_Reporters_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Potential_Team_Reporters_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Potential_Team_Reporters_Bool_Exp>;
};

export type Subscription_RootLives_SpectatorArgs = {
  distinct_on?: InputMaybe<Array<Lives_Spectator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Spectator_Order_By>>;
  where?: InputMaybe<Lives_Spectator_Bool_Exp>;
};

export type Subscription_RootLives_Spectator_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Spectator_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Spectator_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Spectator_Bool_Exp>;
};

export type Subscription_RootLives_Team_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Team_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Team_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Team_Official_Reporter_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Team_Official_Reporter_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Team_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Thank_YouArgs = {
  distinct_on?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Thank_You_Order_By>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

export type Subscription_RootLives_Thank_You_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lives_Thank_You_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Thank_You_Order_By>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

export type Subscription_RootLives_Thank_You_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Thank_You_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Thank_You_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Thank_You_Bool_Exp>;
};

export type Subscription_RootLives_Tournament_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Lives_Tournament_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Tournament_Reporter_Order_By>>;
  where?: InputMaybe<Lives_Tournament_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Tournament_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootLives_Tournament_Reporter_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Tournament_Reporter_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Tournament_Reporter_Bool_Exp>;
};

export type Subscription_RootLives_Used_ActionArgs = {
  distinct_on?: InputMaybe<Array<Lives_Used_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Lives_Used_Action_Order_By>>;
  where?: InputMaybe<Lives_Used_Action_Bool_Exp>;
};

export type Subscription_RootLives_Used_Action_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Lives_Used_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Lives_Used_Action_Bool_Exp>;
};

export type Subscription_RootMedias_MediaArgs = {
  distinct_on?: InputMaybe<Array<Medias_Media_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Medias_Media_Order_By>>;
  where?: InputMaybe<Medias_Media_Bool_Exp>;
};

export type Subscription_RootMedias_Media_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootMedias_Media_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Medias_Media_Stream_Cursor_Input>>;
  where?: InputMaybe<Medias_Media_Bool_Exp>;
};

export type Subscription_RootMedias_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Medias_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Medias_Official_Reporter_Order_By>>;
  where?: InputMaybe<Medias_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootMedias_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootMedias_Official_Reporter_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Medias_Official_Reporter_Stream_Cursor_Input>>;
  where?: InputMaybe<Medias_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootMembers_ExportArgs = {
  distinct_on?: InputMaybe<Array<Members_Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Export_Order_By>>;
  where?: InputMaybe<Members_Export_Bool_Exp>;
};

export type Subscription_RootMembers_Export_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootMembers_Export_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Members_Export_Stream_Cursor_Input>>;
  where?: InputMaybe<Members_Export_Bool_Exp>;
};

export type Subscription_RootMembers_GroupArgs = {
  distinct_on?: InputMaybe<Array<Members_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Group_Order_By>>;
  where?: InputMaybe<Members_Group_Bool_Exp>;
};

export type Subscription_RootMembers_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootMembers_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Members_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Members_Group_Bool_Exp>;
};

export type Subscription_RootMembers_MemberArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_Order_By>>;
  where?: InputMaybe<Members_Member_Bool_Exp>;
};

export type Subscription_RootMembers_Member_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_Order_By>>;
  where?: InputMaybe<Members_Member_Bool_Exp>;
};

export type Subscription_RootMembers_Member_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootMembers_Member_In_GroupArgs = {
  distinct_on?: InputMaybe<Array<Members_Member_In_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Member_In_Group_Order_By>>;
  where?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
};

export type Subscription_RootMembers_Member_In_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootMembers_Member_In_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Members_Member_In_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Members_Member_In_Group_Bool_Exp>;
};

export type Subscription_RootMembers_Member_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Members_Member_Stream_Cursor_Input>>;
  where?: InputMaybe<Members_Member_Bool_Exp>;
};

export type Subscription_RootMembers_Team_Official_ReporterArgs = {
  distinct_on?: InputMaybe<Array<Members_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootMembers_Team_Official_Reporter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Members_Team_Official_Reporter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Members_Team_Official_Reporter_Order_By>>;
  where?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootMembers_Team_Official_Reporter_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootMembers_Team_Official_Reporter_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Members_Team_Official_Reporter_Stream_Cursor_Input>>;
  where?: InputMaybe<Members_Team_Official_Reporter_Bool_Exp>;
};

export type Subscription_RootNews_NewsArgs = {
  distinct_on?: InputMaybe<Array<News_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<News_News_Order_By>>;
  where?: InputMaybe<News_News_Bool_Exp>;
};

export type Subscription_RootNews_News_AggregateArgs = {
  distinct_on?: InputMaybe<Array<News_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<News_News_Order_By>>;
  where?: InputMaybe<News_News_Bool_Exp>;
};

export type Subscription_RootNews_News_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootNews_News_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<News_News_Stream_Cursor_Input>>;
  where?: InputMaybe<News_News_Bool_Exp>;
};

export type Subscription_RootNotifications_EndpointArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Endpoint_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Endpoint_Order_By>>;
  where?: InputMaybe<Notifications_Endpoint_Bool_Exp>;
};

export type Subscription_RootNotifications_Endpoint_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootNotifications_Endpoint_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notifications_Endpoint_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Endpoint_Bool_Exp>;
};

export type Subscription_RootPermissions_Member_PermissionArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Member_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Member_Permission_Order_By>>;
  where?: InputMaybe<Permissions_Member_Permission_Bool_Exp>;
};

export type Subscription_RootPermissions_Member_Permission_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPermissions_Member_Permission_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Permissions_Member_Permission_Stream_Cursor_Input>>;
  where?: InputMaybe<Permissions_Member_Permission_Bool_Exp>;
};

export type Subscription_RootPermissions_Member_RoleArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Member_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Member_Role_Order_By>>;
  where?: InputMaybe<Permissions_Member_Role_Bool_Exp>;
};

export type Subscription_RootPermissions_Member_Role_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPermissions_Member_Role_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Permissions_Member_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Permissions_Member_Role_Bool_Exp>;
};

export type Subscription_RootPermissions_RoleArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Role_Order_By>>;
  where?: InputMaybe<Permissions_Role_Bool_Exp>;
};

export type Subscription_RootPermissions_Role_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPermissions_Role_PermissionArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Role_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Role_Permission_Order_By>>;
  where?: InputMaybe<Permissions_Role_Permission_Bool_Exp>;
};

export type Subscription_RootPermissions_Role_Permission_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPermissions_Role_Permission_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Permissions_Role_Permission_Stream_Cursor_Input>>;
  where?: InputMaybe<Permissions_Role_Permission_Bool_Exp>;
};

export type Subscription_RootPermissions_Role_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Permissions_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Permissions_Role_Bool_Exp>;
};

export type Subscription_RootPlaces_CityArgs = {
  distinct_on?: InputMaybe<Array<Places_City_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_City_Order_By>>;
  where?: InputMaybe<Places_City_Bool_Exp>;
};

export type Subscription_RootPlaces_City_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPlaces_City_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Places_City_Stream_Cursor_Input>>;
  where?: InputMaybe<Places_City_Bool_Exp>;
};

export type Subscription_RootPlaces_CountryArgs = {
  distinct_on?: InputMaybe<Array<Places_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Country_Order_By>>;
  where?: InputMaybe<Places_Country_Bool_Exp>;
};

export type Subscription_RootPlaces_Country_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPlaces_Country_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Places_Country_Stream_Cursor_Input>>;
  where?: InputMaybe<Places_Country_Bool_Exp>;
};

export type Subscription_RootPlaces_DepartmentArgs = {
  distinct_on?: InputMaybe<Array<Places_Department_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Department_Order_By>>;
  where?: InputMaybe<Places_Department_Bool_Exp>;
};

export type Subscription_RootPlaces_Department_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPlaces_Department_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Places_Department_Stream_Cursor_Input>>;
  where?: InputMaybe<Places_Department_Bool_Exp>;
};

export type Subscription_RootPlaces_EquipmentArgs = {
  distinct_on?: InputMaybe<Array<Places_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Equipment_Order_By>>;
  where?: InputMaybe<Places_Equipment_Bool_Exp>;
};

export type Subscription_RootPlaces_Equipment_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPlaces_Equipment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Places_Equipment_Stream_Cursor_Input>>;
  where?: InputMaybe<Places_Equipment_Bool_Exp>;
};

export type Subscription_RootPlaces_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Places_Place_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Place_Order_By>>;
  where?: InputMaybe<Places_Place_Bool_Exp>;
};

export type Subscription_RootPlaces_Place_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPlaces_Place_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Places_Place_Stream_Cursor_Input>>;
  where?: InputMaybe<Places_Place_Bool_Exp>;
};

export type Subscription_RootPlaces_RegionArgs = {
  distinct_on?: InputMaybe<Array<Places_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Places_Region_Order_By>>;
  where?: InputMaybe<Places_Region_Bool_Exp>;
};

export type Subscription_RootPlaces_Region_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPlaces_Region_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Places_Region_Stream_Cursor_Input>>;
  where?: InputMaybe<Places_Region_Bool_Exp>;
};

export type Subscription_RootScraping_Championships_ClubchampionshipexceptionArgs =
  {
    distinct_on?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Order_By>
    >;
    where?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Bool_Exp>;
  };

export type Subscription_RootScraping_Championships_Clubchampionshipexception_By_PkArgs =
  {
    id: Scalars['Int']['input'];
  };

export type Subscription_RootScraping_Championships_Clubchampionshipexception_ChampionshipsArgs =
  {
    distinct_on?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Championships_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<
      Array<Scraping_Championships_Clubchampionshipexception_Championships_Order_By>
    >;
    where?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp>;
  };

export type Subscription_RootScraping_Championships_Clubchampionshipexception_Championships_StreamArgs =
  {
    batch_size: Scalars['Int']['input'];
    cursor: Array<
      InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Championships_Bool_Exp>;
  };

export type Subscription_RootScraping_Championships_Clubchampionshipexception_StreamArgs =
  {
    batch_size: Scalars['Int']['input'];
    cursor: Array<
      InputMaybe<Scraping_Championships_Clubchampionshipexception_Stream_Cursor_Input>
    >;
    where?: InputMaybe<Scraping_Championships_Clubchampionshipexception_Bool_Exp>;
  };

export type Subscription_RootSms_Club_Sms_CreditArgs = {
  distinct_on?: InputMaybe<Array<Sms_Club_Sms_Credit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sms_Club_Sms_Credit_Order_By>>;
  where?: InputMaybe<Sms_Club_Sms_Credit_Bool_Exp>;
};

export type Subscription_RootSms_Club_Sms_Credit_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootSms_Club_Sms_Credit_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sms_Club_Sms_Credit_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Club_Sms_Credit_Bool_Exp>;
};

export type Subscription_RootSms_EventArgs = {
  distinct_on?: InputMaybe<Array<Sms_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sms_Event_Order_By>>;
  where?: InputMaybe<Sms_Event_Bool_Exp>;
};

export type Subscription_RootSms_Event_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootSms_Event_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sms_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Event_Bool_Exp>;
};

export type Subscription_RootSms_MessageArgs = {
  distinct_on?: InputMaybe<Array<Sms_Message_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sms_Message_Order_By>>;
  where?: InputMaybe<Sms_Message_Bool_Exp>;
};

export type Subscription_RootSms_Message_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootSms_Message_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sms_Message_Stream_Cursor_Input>>;
  where?: InputMaybe<Sms_Message_Bool_Exp>;
};

export type Subscription_RootSocials_FacebookArgs = {
  distinct_on?: InputMaybe<Array<Socials_Facebook_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Socials_Facebook_Order_By>>;
  where?: InputMaybe<Socials_Facebook_Bool_Exp>;
};

export type Subscription_RootSocials_Facebook_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootSocials_Facebook_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Socials_Facebook_Stream_Cursor_Input>>;
  where?: InputMaybe<Socials_Facebook_Bool_Exp>;
};

export type Subscription_RootSocials_PostArgs = {
  distinct_on?: InputMaybe<Array<Socials_Post_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Socials_Post_Order_By>>;
  where?: InputMaybe<Socials_Post_Bool_Exp>;
};

export type Subscription_RootSocials_Post_By_PkArgs = {
  post_id: Scalars['String']['input'];
};

export type Subscription_RootSocials_Post_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Socials_Post_Stream_Cursor_Input>>;
  where?: InputMaybe<Socials_Post_Bool_Exp>;
};

export type Subscription_RootSponsors_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Category_Order_By>>;
  where?: InputMaybe<Sponsors_Category_Bool_Exp>;
};

export type Subscription_RootSponsors_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Category_Order_By>>;
  where?: InputMaybe<Sponsors_Category_Bool_Exp>;
};

export type Subscription_RootSponsors_Category_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootSponsors_Category_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sponsors_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Sponsors_Category_Bool_Exp>;
};

export type Subscription_RootSponsors_SponsorArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Sponsor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Sponsor_Order_By>>;
  where?: InputMaybe<Sponsors_Sponsor_Bool_Exp>;
};

export type Subscription_RootSponsors_Sponsor_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootSponsors_Sponsor_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sponsors_Sponsor_Stream_Cursor_Input>>;
  where?: InputMaybe<Sponsors_Sponsor_Bool_Exp>;
};

export type Subscription_RootSponsors_Team_SponsorArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Team_Sponsor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Team_Sponsor_Order_By>>;
  where?: InputMaybe<Sponsors_Team_Sponsor_Bool_Exp>;
};

export type Subscription_RootSponsors_Team_Sponsor_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootSponsors_Team_Sponsor_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Sponsors_Team_Sponsor_Stream_Cursor_Input>>;
  where?: InputMaybe<Sponsors_Team_Sponsor_Bool_Exp>;
};

export type Subscription_RootStats_ActionArgs = {
  distinct_on?: InputMaybe<Array<Stats_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stats_Action_Order_By>>;
  where?: InputMaybe<Stats_Action_Bool_Exp>;
};

export type Subscription_RootStats_Action_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stats_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stats_Action_Order_By>>;
  where?: InputMaybe<Stats_Action_Bool_Exp>;
};

export type Subscription_RootStats_Action_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootStats_Action_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stats_Action_Stream_Cursor_Input>>;
  where?: InputMaybe<Stats_Action_Bool_Exp>;
};

export type Subscription_RootTasks_Candidate_GroupArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Candidate_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Candidate_Group_Order_By>>;
  where?: InputMaybe<Tasks_Candidate_Group_Bool_Exp>;
};

export type Subscription_RootTasks_Candidate_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTasks_Candidate_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tasks_Candidate_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Candidate_Group_Bool_Exp>;
};

export type Subscription_RootTasks_Candidate_TeamArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Candidate_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Candidate_Team_Order_By>>;
  where?: InputMaybe<Tasks_Candidate_Team_Bool_Exp>;
};

export type Subscription_RootTasks_Candidate_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTasks_Candidate_Team_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tasks_Candidate_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Candidate_Team_Bool_Exp>;
};

export type Subscription_RootTasks_JobArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Job_Order_By>>;
  where?: InputMaybe<Tasks_Job_Bool_Exp>;
};

export type Subscription_RootTasks_Job_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTasks_Job_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tasks_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Job_Bool_Exp>;
};

export type Subscription_RootTasks_Member_Task_CountArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Member_Task_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Member_Task_Count_Order_By>>;
  where?: InputMaybe<Tasks_Member_Task_Count_Bool_Exp>;
};

export type Subscription_RootTasks_Member_Task_Count_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTasks_Member_Task_Count_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tasks_Member_Task_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Member_Task_Count_Bool_Exp>;
};

export type Subscription_RootTasks_TaskArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Task_Order_By>>;
  where?: InputMaybe<Tasks_Task_Bool_Exp>;
};

export type Subscription_RootTasks_Task_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Task_Order_By>>;
  where?: InputMaybe<Tasks_Task_Bool_Exp>;
};

export type Subscription_RootTasks_Task_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTasks_Task_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tasks_Task_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Task_Bool_Exp>;
};

export type Subscription_RootTasks_Team_TaskArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Team_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Team_Task_Order_By>>;
  where?: InputMaybe<Tasks_Team_Task_Bool_Exp>;
};

export type Subscription_RootTasks_Team_Task_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTasks_Team_Task_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tasks_Team_Task_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Team_Task_Bool_Exp>;
};

export type Subscription_RootTasks_VolunteerArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Volunteer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Volunteer_Order_By>>;
  where?: InputMaybe<Tasks_Volunteer_Bool_Exp>;
};

export type Subscription_RootTasks_Volunteer_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTasks_Volunteer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tasks_Volunteer_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Volunteer_Bool_Exp>;
};

export type Subscription_RootTeam_Detail_By_Pk_DeprecatedArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Authorized_GroupArgs = {
  distinct_on?: InputMaybe<Array<Teams_Authorized_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Authorized_Group_Order_By>>;
  where?: InputMaybe<Teams_Authorized_Group_Bool_Exp>;
};

export type Subscription_RootTeams_Authorized_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Authorized_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Authorized_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Authorized_Group_Bool_Exp>;
};

export type Subscription_RootTeams_Authorized_TeamArgs = {
  distinct_on?: InputMaybe<Array<Teams_Authorized_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Authorized_Team_Order_By>>;
  where?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
};

export type Subscription_RootTeams_Authorized_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Authorized_Team_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Authorized_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
};

export type Subscription_RootTeams_Club_GroupArgs = {
  distinct_on?: InputMaybe<Array<Teams_Club_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Club_Group_Order_By>>;
  where?: InputMaybe<Teams_Club_Group_Bool_Exp>;
};

export type Subscription_RootTeams_Club_Group_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Club_Group_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Club_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Club_Group_Bool_Exp>;
};

export type Subscription_RootTeams_FanArgs = {
  distinct_on?: InputMaybe<Array<Teams_Fan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Fan_Order_By>>;
  where?: InputMaybe<Teams_Fan_Bool_Exp>;
};

export type Subscription_RootTeams_Fan_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Fan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Fan_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Fan_Bool_Exp>;
};

export type Subscription_RootTeams_LevelArgs = {
  distinct_on?: InputMaybe<Array<Teams_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Level_Order_By>>;
  where?: InputMaybe<Teams_Level_Bool_Exp>;
};

export type Subscription_RootTeams_Level_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Level_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Level_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Level_Bool_Exp>;
};

export type Subscription_RootTeams_OfficialArgs = {
  distinct_on?: InputMaybe<Array<Teams_Official_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Official_Order_By>>;
  where?: InputMaybe<Teams_Official_Bool_Exp>;
};

export type Subscription_RootTeams_Official_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Official_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Official_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Official_Bool_Exp>;
};

export type Subscription_RootTeams_TeamArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Order_By>>;
  where?: InputMaybe<Teams_Team_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Action_PermissionArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Action_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Action_Permission_Order_By>>;
  where?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Action_Permission_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Team_Action_Permission_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Team_Action_Permission_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
};

export type Subscription_RootTeams_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Team_DetailArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Detail_By_Club_IdArgs = {
  args: Teams_Team_Detail_By_Club_Id_Args;
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Detail_By_Member_IdArgs = {
  args: Teams_Team_Detail_By_Member_Id_Args;
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Detail_By_PkArgs = {
  args: Teams_Team_Detail_By_Pk_Args;
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Detail_By_Team_IdArgs = {
  args: Teams_Team_Detail_By_Team_Id_Args;
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Detail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Team_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Detail_SuggestedArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Has_FansArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Has_Fans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Has_Fans_Order_By>>;
  where?: InputMaybe<Teams_Team_Has_Fans_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Has_Fans_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Team_Has_Fans_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Team_Has_Fans_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Team_Has_Fans_Bool_Exp>;
};

export type Subscription_RootTeams_Team_In_SeasonArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_In_Season_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
};

export type Subscription_RootTeams_Team_In_Season_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Team_In_Season_Has_MemberArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Has_Member_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

export type Subscription_RootTeams_Team_In_Season_Has_Member_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Has_Member_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

export type Subscription_RootTeams_Team_In_Season_Has_Member_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Team_In_Season_Has_Member_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Teams_Team_In_Season_Has_Member_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

export type Subscription_RootTeams_Team_In_Season_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Team_In_Season_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
};

export type Subscription_RootTeams_Team_In_Season_TranslationArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Translation_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Translation_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Translation_Bool_Exp>;
};

export type Subscription_RootTeams_Team_In_Season_Translation_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Team_In_Season_Translation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Teams_Team_In_Season_Translation_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Teams_Team_In_Season_Translation_Bool_Exp>;
};

export type Subscription_RootTeams_Team_OfficialArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Official_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Official_Order_By>>;
  where?: InputMaybe<Teams_Team_Official_Bool_Exp>;
};

export type Subscription_RootTeams_Team_Official_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTeams_Team_Official_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Team_Official_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Team_Official_Bool_Exp>;
};

export type Subscription_RootTeams_Team_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Teams_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Teams_Team_Bool_Exp>;
};

export type Subscription_RootTournaments_ChallengeArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Challenge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Challenge_Order_By>>;
  where?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
};

export type Subscription_RootTournaments_Challenge_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Challenge_RuleArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Challenge_Rule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Challenge_Rule_Order_By>>;
  where?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
};

export type Subscription_RootTournaments_Challenge_Rule_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Challenge_Rule_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Challenge_Rule_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
};

export type Subscription_RootTournaments_Challenge_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Challenge_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
};

export type Subscription_RootTournaments_EngagementArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Engagement_Order_By>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

export type Subscription_RootTournaments_Engagement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Engagement_Order_By>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

export type Subscription_RootTournaments_Engagement_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Engagement_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Engagement_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

export type Subscription_RootTournaments_GameArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Game_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Game_Order_By>>;
  where?: InputMaybe<Tournaments_Game_Bool_Exp>;
};

export type Subscription_RootTournaments_Game_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Game_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Game_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Game_Bool_Exp>;
};

export type Subscription_RootTournaments_Games_In_PhaseArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Games_In_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Games_In_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Games_In_Phase_Bool_Exp>;
};

export type Subscription_RootTournaments_Games_In_Phase_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Games_In_Phase_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Games_In_Phase_Bool_Exp>;
};

export type Subscription_RootTournaments_New_HitArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTournaments_Official_Reporter_V2Args = {
  distinct_on?: InputMaybe<
    Array<Tournaments_Official_Reporter_V2_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Official_Reporter_V2_Order_By>>;
  where?: InputMaybe<Tournaments_Official_Reporter_V2_Bool_Exp>;
};

export type Subscription_RootTournaments_Official_Reporter_V2_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Official_Reporter_V2_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<
    InputMaybe<Tournaments_Official_Reporter_V2_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Tournaments_Official_Reporter_V2_Bool_Exp>;
};

export type Subscription_RootTournaments_PhaseArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Phase_Bool_Exp>;
};

export type Subscription_RootTournaments_Phase_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Phase_Bool_Exp>;
};

export type Subscription_RootTournaments_Phase_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Phase_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Phase_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Phase_Bool_Exp>;
};

export type Subscription_RootTournaments_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Place_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Place_Order_By>>;
  where?: InputMaybe<Tournaments_Place_Bool_Exp>;
};

export type Subscription_RootTournaments_Place_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Place_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Place_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Place_Bool_Exp>;
};

export type Subscription_RootTournaments_RankingArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Ranking_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Ranking_Order_By>>;
  where?: InputMaybe<Tournaments_Ranking_Bool_Exp>;
};

export type Subscription_RootTournaments_Ranking_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Ranking_PointsArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Ranking_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Ranking_Points_Order_By>>;
  where?: InputMaybe<Tournaments_Ranking_Points_Bool_Exp>;
};

export type Subscription_RootTournaments_Ranking_Points_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Ranking_Points_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Ranking_Points_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Ranking_Points_Bool_Exp>;
};

export type Subscription_RootTournaments_Ranking_RuleArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Ranking_Rule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Ranking_Rule_Order_By>>;
  where?: InputMaybe<Tournaments_Ranking_Rule_Bool_Exp>;
};

export type Subscription_RootTournaments_Ranking_Rule_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Ranking_Rule_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Ranking_Rule_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Ranking_Rule_Bool_Exp>;
};

export type Subscription_RootTournaments_Ranking_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Ranking_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Ranking_Bool_Exp>;
};

export type Subscription_RootTournaments_TeamArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Team_Order_By>>;
  where?: InputMaybe<Tournaments_Team_Bool_Exp>;
};

export type Subscription_RootTournaments_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Team_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Team_Bool_Exp>;
};

export type Subscription_RootTournaments_TournamentArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Tournament_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Tournament_Order_By>>;
  where?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
};

export type Subscription_RootTournaments_Tournament_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootTournaments_Tournament_PlaceArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Tournament_Place_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Tournament_Place_Order_By>>;
  where?: InputMaybe<Tournaments_Tournament_Place_Bool_Exp>;
};

export type Subscription_RootTournaments_Tournament_Place_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Tournament_Place_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Tournament_Place_Bool_Exp>;
};

export type Subscription_RootTournaments_Tournament_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tournaments_Tournament_Stream_Cursor_Input>>;
  where?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
};

export type Subscription_RootUrls_Club_By_Direct_Score_UrlArgs = {
  args: Urls_Club_By_Direct_Score_Url_Args;
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Subscription_RootUrls_Event_By_Direct_Score_Same_Teams_UrlArgs = {
  args: Urls_Event_By_Direct_Score_Same_Teams_Url_Args;
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Subscription_RootUrls_Event_By_Direct_Score_UrlArgs = {
  args: Urls_Event_By_Direct_Score_Url_Args;
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Subscription_RootUrls_Team_In_Season_By_Direct_Score_UrlArgs = {
  args: Urls_Team_In_Season_By_Direct_Score_Url_Args;
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Subscription_RootUrls_Url_DetailArgs = {
  distinct_on?: InputMaybe<Array<Urls_Url_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Urls_Url_Detail_Order_By>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Subscription_RootUrls_Url_Detail_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootUrls_Url_Detail_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Urls_Url_Detail_Stream_Cursor_Input>>;
  where?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
};

export type Subscription_RootUsers_Convocation_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Users_Convocation_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Convocation_Progress_Order_By>>;
  where?: InputMaybe<Users_Convocation_Progress_Bool_Exp>;
};

export type Subscription_RootUsers_Convocation_Progress_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootUsers_Convocation_Progress_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Convocation_Progress_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Convocation_Progress_Bool_Exp>;
};

export type Subscription_RootUsers_Member_InvitationArgs = {
  distinct_on?: InputMaybe<Array<Users_Member_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Member_Invitation_Order_By>>;
  where?: InputMaybe<Users_Member_Invitation_Bool_Exp>;
};

export type Subscription_RootUsers_Member_Invitation_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootUsers_Member_Invitation_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Member_Invitation_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Member_Invitation_Bool_Exp>;
};

export type Subscription_RootUsers_UserArgs = {
  distinct_on?: InputMaybe<Array<Users_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_User_Order_By>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};

export type Subscription_RootUsers_User_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootUsers_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};

export type Subscription_RootWidgets_Club_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Club_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Club_Settings_Order_By>>;
  where?: InputMaybe<Widgets_Club_Settings_Bool_Exp>;
};

export type Subscription_RootWidgets_Club_Settings_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootWidgets_Club_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Widgets_Club_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Widgets_Club_Settings_Bool_Exp>;
};

export type Subscription_RootWidgets_Convocation_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Convocation_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Convocation_Settings_Order_By>>;
  where?: InputMaybe<Widgets_Convocation_Settings_Bool_Exp>;
};

export type Subscription_RootWidgets_Convocation_Settings_By_PkArgs = {
  widget_id: Scalars['Int']['input'];
};

export type Subscription_RootWidgets_Convocation_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Widgets_Convocation_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Widgets_Convocation_Settings_Bool_Exp>;
};

export type Subscription_RootWidgets_WidgetArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootWidgets_Widget_Has_CompetitionArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Has_Competition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Has_Competition_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Has_Competition_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_Has_Competition_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootWidgets_Widget_Has_Competition_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Widgets_Widget_Has_Competition_Stream_Cursor_Input>>;
  where?: InputMaybe<Widgets_Widget_Has_Competition_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_Has_TeamArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Has_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Has_Team_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Has_Team_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_Has_Team_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootWidgets_Widget_Has_Team_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Widgets_Widget_Has_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Widgets_Widget_Has_Team_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_LiveArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Live_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Live_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Live_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_Live_By_PkArgs = {
  widget_ptr_id: Scalars['Int']['input'];
};

export type Subscription_RootWidgets_Widget_Live_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Widgets_Widget_Live_Stream_Cursor_Input>>;
  where?: InputMaybe<Widgets_Widget_Live_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Widgets_Widget_Stream_Cursor_Input>>;
  where?: InputMaybe<Widgets_Widget_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_TeamArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Team_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Team_Bool_Exp>;
};

export type Subscription_RootWidgets_Widget_Team_By_PkArgs = {
  widget_ptr_id: Scalars['Int']['input'];
};

export type Subscription_RootWidgets_Widget_Team_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Widgets_Widget_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Widgets_Widget_Team_Bool_Exp>;
};

/** columns and relationships of "tasks.candidate_group" */
export type Tasks_Candidate_Group = {
  __typename: 'tasks_candidate_group';
  /** An object relationship */
  group?: Maybe<Members_Group>;
  group_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  team_task_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Candidate_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Candidate_Group_Max_Order_By>;
  min?: InputMaybe<Tasks_Candidate_Group_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Candidate_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Candidate_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Candidate_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Candidate_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Candidate_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Candidate_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Candidate_Group_Variance_Order_By>;
};

/** order by avg() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks.candidate_group". All fields are combined with a logical 'AND'. */
export type Tasks_Candidate_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Candidate_Group_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Candidate_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Candidate_Group_Bool_Exp>>;
  group?: InputMaybe<Members_Group_Bool_Exp>;
  group_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  team_task_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tasks.candidate_group". */
export type Tasks_Candidate_Group_Order_By = {
  group?: InputMaybe<Members_Group_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** select columns of table "tasks.candidate_group" */
export enum Tasks_Candidate_Group_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamTaskId = 'team_task_id',
}

/** order by stddev() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_candidate_group" */
export type Tasks_Candidate_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Candidate_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Candidate_Group_Stream_Cursor_Value_Input = {
  group_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  team_task_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tasks.candidate_group" */
export type Tasks_Candidate_Group_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tasks.candidate_team" */
export type Tasks_Candidate_Team = {
  __typename: 'tasks_candidate_team';
  id: Scalars['Int']['output'];
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
  team_task_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Candidate_Team_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Candidate_Team_Max_Order_By>;
  min?: InputMaybe<Tasks_Candidate_Team_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Candidate_Team_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Candidate_Team_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Candidate_Team_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Candidate_Team_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Candidate_Team_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Candidate_Team_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Candidate_Team_Variance_Order_By>;
};

/** order by avg() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks.candidate_team". All fields are combined with a logical 'AND'. */
export type Tasks_Candidate_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Candidate_Team_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Candidate_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Candidate_Team_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  team_task_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tasks.candidate_team". */
export type Tasks_Candidate_Team_Order_By = {
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** select columns of table "tasks.candidate_team" */
export enum Tasks_Candidate_Team_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TeamTaskId = 'team_task_id',
}

/** order by stddev() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_candidate_team" */
export type Tasks_Candidate_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Candidate_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Candidate_Team_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  team_task_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tasks.candidate_team" */
export type Tasks_Candidate_Team_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_task_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tasks.job" */
export type Tasks_Job = {
  __typename: 'tasks_job';
  id: Scalars['Int']['output'];
  /** An object relationship */
  playing_team_info?: Maybe<Competitions_Playing_Team_Info>;
  playing_team_info_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  task?: Maybe<Tasks_Task>;
  task_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  volunteers?: Maybe<Array<Tasks_Volunteer>>;
};

/** columns and relationships of "tasks.job" */
export type Tasks_JobVolunteersArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Volunteer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Volunteer_Order_By>>;
  where?: InputMaybe<Tasks_Volunteer_Bool_Exp>;
};

/** order by aggregate values of table "tasks.job" */
export type Tasks_Job_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Job_Max_Order_By>;
  min?: InputMaybe<Tasks_Job_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Job_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Job_Variance_Order_By>;
};

/** order by avg() on columns of table "tasks.job" */
export type Tasks_Job_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks.job". All fields are combined with a logical 'AND'. */
export type Tasks_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Job_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Job_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Bool_Exp>;
  playing_team_info_id?: InputMaybe<Int_Comparison_Exp>;
  task?: InputMaybe<Tasks_Task_Bool_Exp>;
  task_id?: InputMaybe<Int_Comparison_Exp>;
  volunteers?: InputMaybe<Tasks_Volunteer_Bool_Exp>;
};

/** order by max() on columns of table "tasks.job" */
export type Tasks_Job_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tasks.job" */
export type Tasks_Job_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tasks.job". */
export type Tasks_Job_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info?: InputMaybe<Competitions_Playing_Team_Info_Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
  volunteers_aggregate?: InputMaybe<Tasks_Volunteer_Aggregate_Order_By>;
};

/** select columns of table "tasks.job" */
export enum Tasks_Job_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PlayingTeamInfoId = 'playing_team_info_id',
  /** column name */
  TaskId = 'task_id',
}

/** order by stddev() on columns of table "tasks.job" */
export type Tasks_Job_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tasks.job" */
export type Tasks_Job_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tasks.job" */
export type Tasks_Job_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_job" */
export type Tasks_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Job_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  playing_team_info_id?: InputMaybe<Scalars['Int']['input']>;
  task_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tasks.job" */
export type Tasks_Job_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "tasks.job" */
export type Tasks_Job_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tasks.job" */
export type Tasks_Job_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tasks.job" */
export type Tasks_Job_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  playing_team_info_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tasks.member_task_count" */
export type Tasks_Member_Task_Count = {
  __typename: 'tasks_member_task_count';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  task?: Maybe<Tasks_Task>;
  task_count?: Maybe<Scalars['smallint']['output']>;
  task_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Member_Task_Count_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Member_Task_Count_Max_Order_By>;
  min?: InputMaybe<Tasks_Member_Task_Count_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Member_Task_Count_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Member_Task_Count_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Member_Task_Count_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Member_Task_Count_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Member_Task_Count_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Member_Task_Count_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Member_Task_Count_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Member_Task_Count_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Member_Task_Count_On_Conflict>;
};

/** order by avg() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks.member_task_count". All fields are combined with a logical 'AND'. */
export type Tasks_Member_Task_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Member_Task_Count_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tasks_Member_Task_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Member_Task_Count_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  task?: InputMaybe<Tasks_Task_Bool_Exp>;
  task_count?: InputMaybe<Smallint_Comparison_Exp>;
  task_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks.member_task_count" */
export enum Tasks_Member_Task_Count_Constraint {
  /** unique or primary key constraint on columns "member_id", "task_id" */
  MemberTaskCountMemberIdTaskId_79f3288dUniq = 'member_task_count_member_id_task_id_79f3288d_uniq',
  /** unique or primary key constraint on columns "id" */
  MemberTaskCountPkey = 'member_task_count_pkey',
}

/** input type for incrementing numeric columns in table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Inc_Input = {
  task_count?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Insert_Input = {
  member_id?: InputMaybe<Scalars['Int']['input']>;
  task?: InputMaybe<Tasks_Task_Obj_Rel_Insert_Input>;
  task_count?: InputMaybe<Scalars['smallint']['input']>;
  task_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Mutation_Response = {
  __typename: 'tasks_member_task_count_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tasks_Member_Task_Count>>;
};

/** on_conflict condition type for table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_On_Conflict = {
  constraint: Tasks_Member_Task_Count_Constraint;
  update_columns?: Array<Tasks_Member_Task_Count_Update_Column>;
  where?: InputMaybe<Tasks_Member_Task_Count_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks.member_task_count". */
export type Tasks_Member_Task_Count_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Task_Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks.member_task_count */
export type Tasks_Member_Task_Count_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tasks.member_task_count" */
export enum Tasks_Member_Task_Count_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  TaskCount = 'task_count',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Set_Input = {
  task_count?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by stddev() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_member_task_count" */
export type Tasks_Member_Task_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Member_Task_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Member_Task_Count_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  task_count?: InputMaybe<Scalars['smallint']['input']>;
  task_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** update columns of table "tasks.member_task_count" */
export enum Tasks_Member_Task_Count_Update_Column {
  /** column name */
  TaskCount = 'task_count',
}

export type Tasks_Member_Task_Count_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Member_Task_Count_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Member_Task_Count_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Member_Task_Count_Bool_Exp;
};

/** order by var_pop() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tasks.member_task_count" */
export type Tasks_Member_Task_Count_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  task_count?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tasks.task" */
export type Tasks_Task = {
  __typename: 'tasks_task';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  allow_officials?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  club_admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  game_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** columns and relationships of "tasks.task" */
export type Tasks_TaskClub_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** aggregated selection of "tasks.task" */
export type Tasks_Task_Aggregate = {
  __typename: 'tasks_task_aggregate';
  aggregate?: Maybe<Tasks_Task_Aggregate_Fields>;
  nodes?: Maybe<Array<Tasks_Task>>;
};

export type Tasks_Task_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tasks_Task_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tasks_Task_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tasks_Task_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Task_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tasks_Task_Select_Column_Tasks_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Task_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tasks_Task_Select_Column_Tasks_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Task_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tasks_Task_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Task_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Task_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks.task" */
export type Tasks_Task_Aggregate_Fields = {
  __typename: 'tasks_task_aggregate_fields';
  avg?: Maybe<Tasks_Task_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Tasks_Task_Max_Fields>;
  min?: Maybe<Tasks_Task_Min_Fields>;
  stddev?: Maybe<Tasks_Task_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Task_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Task_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Task_Sum_Fields>;
  var_pop?: Maybe<Tasks_Task_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Task_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Task_Variance_Fields>;
};

/** aggregate fields of "tasks.task" */
export type Tasks_Task_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Task_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tasks.task" */
export type Tasks_Task_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Task_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Task_Max_Order_By>;
  min?: InputMaybe<Tasks_Task_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Task_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Task_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Task_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Task_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Task_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Task_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Task_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tasks_Task_Avg_Fields = {
  __typename: 'tasks_task_avg_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tasks.task" */
export type Tasks_Task_Avg_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks.task". All fields are combined with a logical 'AND'. */
export type Tasks_Task_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Task_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tasks_Task_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Task_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  allow_officials?: InputMaybe<Boolean_Comparison_Exp>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  emoji?: InputMaybe<String_Comparison_Exp>;
  game_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks.task" */
export enum Tasks_Task_Constraint {
  /** unique or primary key constraint on columns "name", "club_id" */
  TaskClubIdName_51de15bbUniq = 'task_club_id_name_51de15bb_uniq',
  /** unique or primary key constraint on columns "id" */
  TaskPkey = 'task_pkey',
}

/** input type for incrementing numeric columns in table "tasks.task" */
export type Tasks_Task_Inc_Input = {
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "tasks.task" */
export type Tasks_Task_Insert_Input = {
  allow_officials?: InputMaybe<Scalars['Boolean']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  game_type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate max on columns */
export type Tasks_Task_Max_Fields = {
  __typename: 'tasks_task_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  game_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** order by max() on columns of table "tasks.task" */
export type Tasks_Task_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Task_Min_Fields = {
  __typename: 'tasks_task_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  game_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** order by min() on columns of table "tasks.task" */
export type Tasks_Task_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks.task" */
export type Tasks_Task_Mutation_Response = {
  __typename: 'tasks_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tasks_Task>>;
};

/** input type for inserting object relation for remote table "tasks.task" */
export type Tasks_Task_Obj_Rel_Insert_Input = {
  data: Tasks_Task_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_Task_On_Conflict>;
};

/** on_conflict condition type for table "tasks.task" */
export type Tasks_Task_On_Conflict = {
  constraint: Tasks_Task_Constraint;
  update_columns?: Array<Tasks_Task_Update_Column>;
  where?: InputMaybe<Tasks_Task_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks.task". */
export type Tasks_Task_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  allow_officials?: InputMaybe<Order_By>;
  club_admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  emoji?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks.task */
export type Tasks_Task_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tasks.task" */
export enum Tasks_Task_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AllowOfficials = 'allow_officials',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  GameType = 'game_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
}

/** select "tasks_task_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tasks.task" */
export enum Tasks_Task_Select_Column_Tasks_Task_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowOfficials = 'allow_officials',
}

/** select "tasks_task_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tasks.task" */
export enum Tasks_Task_Select_Column_Tasks_Task_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowOfficials = 'allow_officials',
}

/** input type for updating data in table "tasks.task" */
export type Tasks_Task_Set_Input = {
  allow_officials?: InputMaybe<Scalars['Boolean']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  game_type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate stddev on columns */
export type Tasks_Task_Stddev_Fields = {
  __typename: 'tasks_task_stddev_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tasks.task" */
export type Tasks_Task_Stddev_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Task_Stddev_Pop_Fields = {
  __typename: 'tasks_task_stddev_pop_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "tasks.task" */
export type Tasks_Task_Stddev_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Task_Stddev_Samp_Fields = {
  __typename: 'tasks_task_stddev_samp_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "tasks.task" */
export type Tasks_Task_Stddev_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_task" */
export type Tasks_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Task_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  allow_officials?: InputMaybe<Scalars['Boolean']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  game_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate sum on columns */
export type Tasks_Task_Sum_Fields = {
  __typename: 'tasks_task_sum_fields';
  club_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "tasks.task" */
export type Tasks_Task_Sum_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** update columns of table "tasks.task" */
export enum Tasks_Task_Update_Column {
  /** column name */
  AllowOfficials = 'allow_officials',
  /** column name */
  Emoji = 'emoji',
  /** column name */
  GameType = 'game_type',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
}

export type Tasks_Task_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Task_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Task_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Task_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Task_Var_Pop_Fields = {
  __typename: 'tasks_task_var_pop_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "tasks.task" */
export type Tasks_Task_Var_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Task_Var_Samp_Fields = {
  __typename: 'tasks_task_var_samp_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "tasks.task" */
export type Tasks_Task_Var_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Task_Variance_Fields = {
  __typename: 'tasks_task_variance_fields';
  club_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tasks.task" */
export type Tasks_Task_Variance_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** columns and relationships of "tasks.team_task" */
export type Tasks_Team_Task = {
  __typename: 'tasks_team_task';
  /** An array relationship */
  candidate_groups?: Maybe<Array<Tasks_Candidate_Group>>;
  /** An array relationship */
  candidate_teams?: Maybe<Array<Tasks_Candidate_Team>>;
  id: Scalars['Int']['output'];
  officials?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  task?: Maybe<Tasks_Task>;
  task_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "tasks.team_task" */
export type Tasks_Team_TaskCandidate_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Candidate_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Candidate_Group_Order_By>>;
  where?: InputMaybe<Tasks_Candidate_Group_Bool_Exp>;
};

/** columns and relationships of "tasks.team_task" */
export type Tasks_Team_TaskCandidate_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Candidate_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Candidate_Team_Order_By>>;
  where?: InputMaybe<Tasks_Candidate_Team_Bool_Exp>;
};

/** order by aggregate values of table "tasks.team_task" */
export type Tasks_Team_Task_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Team_Task_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Team_Task_Max_Order_By>;
  min?: InputMaybe<Tasks_Team_Task_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Team_Task_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Team_Task_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Team_Task_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Team_Task_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Team_Task_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Team_Task_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Team_Task_Variance_Order_By>;
};

/** order by avg() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks.team_task". All fields are combined with a logical 'AND'. */
export type Tasks_Team_Task_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Team_Task_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Team_Task_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Team_Task_Bool_Exp>>;
  candidate_groups?: InputMaybe<Tasks_Candidate_Group_Bool_Exp>;
  candidate_teams?: InputMaybe<Tasks_Candidate_Team_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  officials?: InputMaybe<Boolean_Comparison_Exp>;
  task?: InputMaybe<Tasks_Task_Bool_Exp>;
  task_id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tasks.team_task". */
export type Tasks_Team_Task_Order_By = {
  candidate_groups_aggregate?: InputMaybe<Tasks_Candidate_Group_Aggregate_Order_By>;
  candidate_teams_aggregate?: InputMaybe<Tasks_Candidate_Team_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  officials?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Task_Order_By>;
  task_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** select columns of table "tasks.team_task" */
export enum Tasks_Team_Task_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Officials = 'officials',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  TeamId = 'team_id',
}

/** order by stddev() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_team_task" */
export type Tasks_Team_Task_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Team_Task_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Team_Task_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  officials?: InputMaybe<Scalars['Boolean']['input']>;
  task_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tasks.team_task" */
export type Tasks_Team_Task_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tasks.volunteer" */
export type Tasks_Volunteer = {
  __typename: 'tasks_volunteer';
  id: Scalars['Int']['output'];
  /** An object relationship */
  job?: Maybe<Tasks_Job>;
  job_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  message?: Maybe<Emails_Message>;
  /** An object relationship */
  message_sms?: Maybe<Sms_Message>;
};

/** order by aggregate values of table "tasks.volunteer" */
export type Tasks_Volunteer_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Volunteer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Volunteer_Max_Order_By>;
  min?: InputMaybe<Tasks_Volunteer_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Volunteer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Volunteer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Volunteer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Volunteer_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Volunteer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Volunteer_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Volunteer_Variance_Order_By>;
};

/** order by avg() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks.volunteer". All fields are combined with a logical 'AND'. */
export type Tasks_Volunteer_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Volunteer_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Volunteer_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Volunteer_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  job?: InputMaybe<Tasks_Job_Bool_Exp>;
  job_id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<Emails_Message_Bool_Exp>;
  message_sms?: InputMaybe<Sms_Message_Bool_Exp>;
};

/** order by max() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tasks.volunteer". */
export type Tasks_Volunteer_Order_By = {
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Tasks_Job_Order_By>;
  job_id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  message?: InputMaybe<Emails_Message_Order_By>;
  message_sms?: InputMaybe<Sms_Message_Order_By>;
};

/** select columns of table "tasks.volunteer" */
export enum Tasks_Volunteer_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MemberId = 'member_id',
}

/** order by stddev() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks_volunteer" */
export type Tasks_Volunteer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Volunteer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Volunteer_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  job_id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tasks.volunteer" */
export type Tasks_Volunteer_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
};

export type Teams_Add_Fan_Response = {
  __typename: 'teams_add_fan_response';
  fan_id?: Maybe<Scalars['Int']['output']>;
  nb_fans?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

export type Teams_Add_Team_Response = {
  __typename: 'teams_add_team_response';
  team_id?: Maybe<Scalars['Int']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "teams.authorized_group" */
export type Teams_Authorized_Group = {
  __typename: 'teams_authorized_group';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  group?: Maybe<Members_Group>;
  group_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  permission?: Maybe<Teams_Team_Action_Permission>;
  permission_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "teams.authorized_group" */
export type Teams_Authorized_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Authorized_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Authorized_Group_Max_Order_By>;
  min?: InputMaybe<Teams_Authorized_Group_Min_Order_By>;
  stddev?: InputMaybe<Teams_Authorized_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Authorized_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Authorized_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Authorized_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Authorized_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Authorized_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Authorized_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teams.authorized_group" */
export type Teams_Authorized_Group_Arr_Rel_Insert_Input = {
  data: Array<Teams_Authorized_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Authorized_Group_On_Conflict>;
};

/** order by avg() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.authorized_group". All fields are combined with a logical 'AND'. */
export type Teams_Authorized_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Authorized_Group_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Authorized_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Authorized_Group_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Members_Group_Bool_Exp>;
  group_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  permission?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
  permission_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams.authorized_group" */
export enum Teams_Authorized_Group_Constraint {
  /** unique or primary key constraint on columns "permission_id", "group_id" */
  AuthorizedGroupPermissionIdGroupId_0470f9fdUniq = 'authorized_group_permission_id_group_id_0470f9fd_uniq',
  /** unique or primary key constraint on columns "id" */
  AuthorizedGroupPkey = 'authorized_group_pkey',
}

/** input type for incrementing numeric columns in table "teams.authorized_group" */
export type Teams_Authorized_Group_Inc_Input = {
  group_id?: InputMaybe<Scalars['Int']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "teams.authorized_group" */
export type Teams_Authorized_Group_Insert_Input = {
  group?: InputMaybe<Members_Group_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['Int']['input']>;
  permission?: InputMaybe<Teams_Team_Action_Permission_Obj_Rel_Insert_Input>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams.authorized_group" */
export type Teams_Authorized_Group_Mutation_Response = {
  __typename: 'teams_authorized_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Authorized_Group>>;
};

/** on_conflict condition type for table "teams.authorized_group" */
export type Teams_Authorized_Group_On_Conflict = {
  constraint: Teams_Authorized_Group_Constraint;
  update_columns?: Array<Teams_Authorized_Group_Update_Column>;
  where?: InputMaybe<Teams_Authorized_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "teams.authorized_group". */
export type Teams_Authorized_Group_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  group?: InputMaybe<Members_Group_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Teams_Team_Action_Permission_Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams.authorized_group */
export type Teams_Authorized_Group_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "teams.authorized_group" */
export enum Teams_Authorized_Group_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
}

/** input type for updating data in table "teams.authorized_group" */
export type Teams_Authorized_Group_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  group_id?: InputMaybe<Scalars['Int']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_authorized_group" */
export type Teams_Authorized_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Authorized_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Authorized_Group_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  group_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** update columns of table "teams.authorized_group" */
export enum Teams_Authorized_Group_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  PermissionId = 'permission_id',
}

export type Teams_Authorized_Group_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Teams_Authorized_Group_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Authorized_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Authorized_Group_Bool_Exp;
};

/** order by var_pop() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.authorized_group" */
export type Teams_Authorized_Group_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "teams.authorized_team" */
export type Teams_Authorized_Team = {
  __typename: 'teams_authorized_team';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  permission?: Maybe<Teams_Team_Action_Permission>;
  permission_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "teams.authorized_team" */
export type Teams_Authorized_Team_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Authorized_Team_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Authorized_Team_Max_Order_By>;
  min?: InputMaybe<Teams_Authorized_Team_Min_Order_By>;
  stddev?: InputMaybe<Teams_Authorized_Team_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Authorized_Team_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Authorized_Team_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Authorized_Team_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Authorized_Team_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Authorized_Team_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Authorized_Team_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teams.authorized_team" */
export type Teams_Authorized_Team_Arr_Rel_Insert_Input = {
  data: Array<Teams_Authorized_Team_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Authorized_Team_On_Conflict>;
};

/** order by avg() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.authorized_team". All fields are combined with a logical 'AND'. */
export type Teams_Authorized_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Authorized_Team_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Authorized_Team_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  permission?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
  permission_id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams.authorized_team" */
export enum Teams_Authorized_Team_Constraint {
  /** unique or primary key constraint on columns "permission_id", "team_id" */
  AuthorizedTeamPermissionIdTeamId_20f35476Uniq = 'authorized_team_permission_id_team_id_20f35476_uniq',
  /** unique or primary key constraint on columns "id" */
  AuthorizedTeamPkey = 'authorized_team_pkey',
}

/** input type for incrementing numeric columns in table "teams.authorized_team" */
export type Teams_Authorized_Team_Inc_Input = {
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "teams.authorized_team" */
export type Teams_Authorized_Team_Insert_Input = {
  permission?: InputMaybe<Teams_Team_Action_Permission_Obj_Rel_Insert_Input>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams.authorized_team" */
export type Teams_Authorized_Team_Mutation_Response = {
  __typename: 'teams_authorized_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Authorized_Team>>;
};

/** on_conflict condition type for table "teams.authorized_team" */
export type Teams_Authorized_Team_On_Conflict = {
  constraint: Teams_Authorized_Team_Constraint;
  update_columns?: Array<Teams_Authorized_Team_Update_Column>;
  where?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
};

/** Ordering options when selecting data from "teams.authorized_team". */
export type Teams_Authorized_Team_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Teams_Team_Action_Permission_Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams.authorized_team */
export type Teams_Authorized_Team_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "teams.authorized_team" */
export enum Teams_Authorized_Team_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  TeamId = 'team_id',
}

/** input type for updating data in table "teams.authorized_team" */
export type Teams_Authorized_Team_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_authorized_team" */
export type Teams_Authorized_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Authorized_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Authorized_Team_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "teams.authorized_team" */
export enum Teams_Authorized_Team_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  TeamId = 'team_id',
}

export type Teams_Authorized_Team_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Teams_Authorized_Team_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Authorized_Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Authorized_Team_Bool_Exp;
};

/** order by var_pop() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.authorized_team" */
export type Teams_Authorized_Team_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "teams.club_group" */
export type Teams_Club_Group = {
  __typename: 'teams_club_group';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  admins?: Maybe<Array<Clubs_Club_Admin>>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  club_id?: Maybe<Scalars['Int']['output']>;
  custom_name_in_club?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  rank?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "teams.club_group" */
export type Teams_Club_GroupAdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** order by aggregate values of table "teams.club_group" */
export type Teams_Club_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Club_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Club_Group_Max_Order_By>;
  min?: InputMaybe<Teams_Club_Group_Min_Order_By>;
  stddev?: InputMaybe<Teams_Club_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Club_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Club_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Club_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Club_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Club_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Club_Group_Variance_Order_By>;
};

/** order by avg() on columns of table "teams.club_group" */
export type Teams_Club_Group_Avg_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.club_group". All fields are combined with a logical 'AND'. */
export type Teams_Club_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Club_Group_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Club_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Club_Group_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  custom_name_in_club?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams.club_group" */
export enum Teams_Club_Group_Constraint {
  /** unique or primary key constraint on columns "team_id", "club_id" */
  ClubGroupClubIdTeamId_20eee238Uniq = 'club_group_club_id_team_id_20eee238_uniq',
  /** unique or primary key constraint on columns "id" */
  ClubGroupPkey = 'club_group_pkey',
}

/** input type for incrementing numeric columns in table "teams.club_group" */
export type Teams_Club_Group_Inc_Input = {
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "teams.club_group" */
export type Teams_Club_Group_Insert_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  custom_name_in_club?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "teams.club_group" */
export type Teams_Club_Group_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.club_group" */
export type Teams_Club_Group_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams.club_group" */
export type Teams_Club_Group_Mutation_Response = {
  __typename: 'teams_club_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Club_Group>>;
};

/** on_conflict condition type for table "teams.club_group" */
export type Teams_Club_Group_On_Conflict = {
  constraint: Teams_Club_Group_Constraint;
  update_columns?: Array<Teams_Club_Group_Update_Column>;
  where?: InputMaybe<Teams_Club_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "teams.club_group". */
export type Teams_Club_Group_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_id?: InputMaybe<Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams.club_group */
export type Teams_Club_Group_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "teams.club_group" */
export enum Teams_Club_Group_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CustomNameInClub = 'custom_name_in_club',
  /** column name */
  Id = 'id',
  /** column name */
  Rank = 'rank',
  /** column name */
  TeamId = 'team_id',
}

/** input type for updating data in table "teams.club_group" */
export type Teams_Club_Group_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_name_in_club?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by stddev() on columns of table "teams.club_group" */
export type Teams_Club_Group_Stddev_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.club_group" */
export type Teams_Club_Group_Stddev_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.club_group" */
export type Teams_Club_Group_Stddev_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_club_group" */
export type Teams_Club_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Club_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Club_Group_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  custom_name_in_club?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "teams.club_group" */
export type Teams_Club_Group_Sum_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "teams.club_group" */
export enum Teams_Club_Group_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  CustomNameInClub = 'custom_name_in_club',
  /** column name */
  Rank = 'rank',
}

export type Teams_Club_Group_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Teams_Club_Group_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Club_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Club_Group_Bool_Exp;
};

/** order by var_pop() on columns of table "teams.club_group" */
export type Teams_Club_Group_Var_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.club_group" */
export type Teams_Club_Group_Var_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.club_group" */
export type Teams_Club_Group_Variance_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

export type Teams_Delete_Fan_Response = {
  __typename: 'teams_delete_fan_response';
  nb_fans?: Maybe<Scalars['Int']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "teams.fan" */
export type Teams_Fan = {
  __typename: 'teams_fan';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  picture_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  teams?: Maybe<Array<Teams_Team_Has_Fans>>;
};

/** columns and relationships of "teams.fan" */
export type Teams_FanTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Has_Fans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Has_Fans_Order_By>>;
  where?: InputMaybe<Teams_Team_Has_Fans_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "teams.fan". All fields are combined with a logical 'AND'. */
export type Teams_Fan_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Fan_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Fan_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Fan_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  picture_url?: InputMaybe<String_Comparison_Exp>;
  teams?: InputMaybe<Teams_Team_Has_Fans_Bool_Exp>;
};

/** Ordering options when selecting data from "teams.fan". */
export type Teams_Fan_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Teams_Team_Has_Fans_Aggregate_Order_By>;
};

/** select columns of table "teams.fan" */
export enum Teams_Fan_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PictureUrl = 'picture_url',
}

/** Streaming cursor of the table "teams_fan" */
export type Teams_Fan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Fan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Fan_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  picture_url?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "teams.level" */
export type Teams_Level = {
  __typename: 'teams_level';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  area?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "teams.level". All fields are combined with a logical 'AND'. */
export type Teams_Level_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Level_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Level_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Level_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  area?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  scraping_id?: InputMaybe<Int_Comparison_Exp>;
  short_name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "teams.level". */
export type Teams_Level_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  area?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
};

/** select columns of table "teams.level" */
export enum Teams_Level_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Area = 'area',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ScrapingId = 'scraping_id',
  /** column name */
  ShortName = 'short_name',
}

/** Streaming cursor of the table "teams_level" */
export type Teams_Level_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Level_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Level_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  area?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  scraping_id?: InputMaybe<Scalars['Int']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
};

export type Teams_Migrate_To_Season_Response = {
  __typename: 'teams_migrate_to_season_response';
  nb_teams?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "teams.official" */
export type Teams_Official = {
  __typename: 'teams_official';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  teams?: Maybe<Array<Teams_Team_Official>>;
  /** An object relationship */
  user?: Maybe<Users_User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "teams.official" */
export type Teams_OfficialTeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Official_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Official_Order_By>>;
  where?: InputMaybe<Teams_Team_Official_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "teams.official". All fields are combined with a logical 'AND'. */
export type Teams_Official_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Official_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Official_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Official_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  teams?: InputMaybe<Teams_Team_Official_Bool_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams.official" */
export enum Teams_Official_Constraint {
  /** unique or primary key constraint on columns "id" */
  OfficialPkey = 'official_pkey',
  /** unique or primary key constraint on columns "user_id" */
  OfficialUserIdKey = 'official_user_id_key',
}

/** input type for inserting data into table "teams.official" */
export type Teams_Official_Insert_Input = {
  teams?: InputMaybe<Teams_Team_Official_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "teams.official" */
export type Teams_Official_Mutation_Response = {
  __typename: 'teams_official_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Official>>;
};

/** input type for inserting object relation for remote table "teams.official" */
export type Teams_Official_Obj_Rel_Insert_Input = {
  data: Teams_Official_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Official_On_Conflict>;
};

/** on_conflict condition type for table "teams.official" */
export type Teams_Official_On_Conflict = {
  constraint: Teams_Official_Constraint;
  update_columns?: Array<Teams_Official_Update_Column>;
  where?: InputMaybe<Teams_Official_Bool_Exp>;
};

/** Ordering options when selecting data from "teams.official". */
export type Teams_Official_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  teams_aggregate?: InputMaybe<Teams_Team_Official_Aggregate_Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "teams.official" */
export enum Teams_Official_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "teams_official" */
export type Teams_Official_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Official_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Official_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** placeholder for update columns of table "teams.official" (current role has no relevant permissions) */
export enum Teams_Official_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "teams.team" */
export type Teams_Team = {
  __typename: 'teams_team';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  action_permissions?: Maybe<Array<Teams_Team_Action_Permission>>;
  age_category?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  authorizations?: Maybe<Array<Teams_Authorized_Team>>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  /** An array relationship */
  club_admins?: Maybe<Array<Clubs_Club_Admin>>;
  /** An array relationship */
  club_groups?: Maybe<Array<Teams_Club_Group>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  /** Récupère l'équipe de la saison en cours */
  current_team_detail?: Maybe<Array<Teams_Team_Detail>>;
  custom_name_in_competition?: Maybe<Scalars['String']['output']>;
  custom_short_name_in_competition?: Maybe<Scalars['String']['output']>;
  default_equipment_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  fans?: Maybe<Array<Teams_Team_Has_Fans>>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Renvoie la liste des derniers évènements d'une équipe */
  last_events?: Maybe<Array<Competitions_Event_Detail>>;
  meeting_before_event?: Maybe<Scalars['smallint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nb_fans?: Maybe<Scalars['smallint']['output']>;
  /** Renvoie la liste des prochains évènements de cette équipe */
  next_events?: Maybe<Array<Competitions_Event_Detail>>;
  number?: Maybe<Scalars['smallint']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  referee_official?: Maybe<Scalars['Boolean']['output']>;
  scoring_table_official?: Maybe<Scalars['Boolean']['output']>;
  scraping_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  seasons?: Maybe<Array<Teams_Team_In_Season>>;
  share_picture?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  sport_sub_category?: Maybe<Clubs_Sport>;
  sport_sub_category_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  team_officials?: Maybe<Array<Teams_Team_Official>>;
  /** An array relationship */
  team_tasks?: Maybe<Array<Tasks_Team_Task>>;
  url?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamAction_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Action_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Action_Permission_Order_By>>;
  where?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamAuthorizationsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Authorized_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Authorized_Team_Order_By>>;
  where?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamClub_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamClub_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Club_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Club_Group_Order_By>>;
  where?: InputMaybe<Teams_Club_Group_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamCurrent_Team_DetailArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamFansArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Has_Fans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Has_Fans_Order_By>>;
  where?: InputMaybe<Teams_Team_Has_Fans_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamLast_EventsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamNext_EventsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Event_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Event_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamSeasonsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_In_Season_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamTeam_OfficialsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Official_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Official_Order_By>>;
  where?: InputMaybe<Teams_Team_Official_Bool_Exp>;
};

/** columns and relationships of "teams.team" */
export type Teams_TeamTeam_TasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Team_Task_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Team_Task_Order_By>>;
  where?: InputMaybe<Tasks_Team_Task_Bool_Exp>;
};

/** columns and relationships of "teams.team_action_permission" */
export type Teams_Team_Action_Permission = {
  __typename: 'teams_team_action_permission';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  authorized_groups?: Maybe<Array<Teams_Authorized_Group>>;
  /** An array relationship */
  authorized_teams?: Maybe<Array<Teams_Authorized_Team>>;
  id: Scalars['Int']['output'];
  permission_type?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "teams.team_action_permission" */
export type Teams_Team_Action_PermissionAuthorized_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Authorized_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Authorized_Group_Order_By>>;
  where?: InputMaybe<Teams_Authorized_Group_Bool_Exp>;
};

/** columns and relationships of "teams.team_action_permission" */
export type Teams_Team_Action_PermissionAuthorized_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Teams_Authorized_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Authorized_Team_Order_By>>;
  where?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
};

/** order by aggregate values of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Team_Action_Permission_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Team_Action_Permission_Max_Order_By>;
  min?: InputMaybe<Teams_Team_Action_Permission_Min_Order_By>;
  stddev?: InputMaybe<Teams_Team_Action_Permission_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Team_Action_Permission_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Team_Action_Permission_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Team_Action_Permission_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Team_Action_Permission_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Team_Action_Permission_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Team_Action_Permission_Variance_Order_By>;
};

/** order by avg() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.team_action_permission". All fields are combined with a logical 'AND'. */
export type Teams_Team_Action_Permission_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Team_Action_Permission_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Team_Action_Permission_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  authorized_groups?: InputMaybe<Teams_Authorized_Group_Bool_Exp>;
  authorized_teams?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  permission_type?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams.team_action_permission" */
export enum Teams_Team_Action_Permission_Constraint {
  /** unique or primary key constraint on columns "permission_type", "team_id" */
  TeamActionPermissionPermissionTypeTeamId_17bad34eUniq = 'team_action_permission_permission_type_team_id_17bad34e_uniq',
  /** unique or primary key constraint on columns "id" */
  TeamActionPermissionPkey = 'team_action_permission_pkey',
}

/** input type for inserting data into table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Insert_Input = {
  authorized_groups?: InputMaybe<Teams_Authorized_Group_Arr_Rel_Insert_Input>;
  authorized_teams?: InputMaybe<Teams_Authorized_Team_Arr_Rel_Insert_Input>;
  permission_type?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_type?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_type?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Mutation_Response = {
  __typename: 'teams_team_action_permission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Team_Action_Permission>>;
};

/** input type for inserting object relation for remote table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Obj_Rel_Insert_Input = {
  data: Teams_Team_Action_Permission_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Team_Action_Permission_On_Conflict>;
};

/** on_conflict condition type for table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_On_Conflict = {
  constraint: Teams_Team_Action_Permission_Constraint;
  update_columns?: Array<Teams_Team_Action_Permission_Update_Column>;
  where?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
};

/** Ordering options when selecting data from "teams.team_action_permission". */
export type Teams_Team_Action_Permission_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  authorized_groups_aggregate?: InputMaybe<Teams_Authorized_Group_Aggregate_Order_By>;
  authorized_teams_aggregate?: InputMaybe<Teams_Authorized_Team_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  permission_type?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams.team_action_permission */
export type Teams_Team_Action_Permission_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "teams.team_action_permission" */
export enum Teams_Team_Action_Permission_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionType = 'permission_type',
  /** column name */
  TeamId = 'team_id',
}

/** input type for updating data in table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  permission_type?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_team_action_permission" */
export type Teams_Team_Action_Permission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Team_Action_Permission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Team_Action_Permission_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  permission_type?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** update columns of table "teams.team_action_permission" */
export enum Teams_Team_Action_Permission_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  PermissionType = 'permission_type',
}

export type Teams_Team_Action_Permission_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Team_Action_Permission_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Team_Action_Permission_Bool_Exp;
};

/** order by var_pop() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.team_action_permission" */
export type Teams_Team_Action_Permission_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by aggregate values of table "teams.team" */
export type Teams_Team_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Team_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Team_Max_Order_By>;
  min?: InputMaybe<Teams_Team_Min_Order_By>;
  stddev?: InputMaybe<Teams_Team_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Team_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Team_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Team_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Team_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Team_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Team_Variance_Order_By>;
};

/** order by avg() on columns of table "teams.team" */
export type Teams_Team_Avg_Order_By = {
  club_id?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.team". All fields are combined with a logical 'AND'. */
export type Teams_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Team_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Team_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  action_permissions?: InputMaybe<Teams_Team_Action_Permission_Bool_Exp>;
  age_category?: InputMaybe<String_Comparison_Exp>;
  authorizations?: InputMaybe<Teams_Authorized_Team_Bool_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_groups?: InputMaybe<Teams_Club_Group_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  current_team_detail?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
  custom_name_in_competition?: InputMaybe<String_Comparison_Exp>;
  custom_short_name_in_competition?: InputMaybe<String_Comparison_Exp>;
  default_equipment_id?: InputMaybe<Int_Comparison_Exp>;
  fans?: InputMaybe<Teams_Team_Has_Fans_Bool_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_events?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  meeting_before_event?: InputMaybe<Smallint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_fans?: InputMaybe<Smallint_Comparison_Exp>;
  next_events?: InputMaybe<Competitions_Event_Detail_Bool_Exp>;
  number?: InputMaybe<Smallint_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  referee_official?: InputMaybe<Boolean_Comparison_Exp>;
  scoring_table_official?: InputMaybe<Boolean_Comparison_Exp>;
  scraping_id?: InputMaybe<Int_Comparison_Exp>;
  seasons?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  share_picture?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sport_sub_category?: InputMaybe<Clubs_Sport_Bool_Exp>;
  sport_sub_category_id?: InputMaybe<Int_Comparison_Exp>;
  team_officials?: InputMaybe<Teams_Team_Official_Bool_Exp>;
  team_tasks?: InputMaybe<Tasks_Team_Task_Bool_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "teams.team_detail" */
export type Teams_Team_Detail = {
  __typename: 'teams_team_detail';
  category?: Maybe<Scalars['String']['output']>;
  club?: Maybe<Array<Clubs_Club_Detail>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  competitions?: Maybe<Array<Competitions_Competition_Has_Teams>>;
  /** An array relationship */
  events?: Maybe<Array<Competitions_Playing_Team>>;
  /** An aggregate relationship */
  events_aggregate?: Maybe<Competitions_Playing_Team_Aggregate>;
  id: Scalars['Int']['output'];
  is_group_of_teams?: Maybe<Scalars['Boolean']['output']>;
  level_name?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name_in_club?: Maybe<Scalars['String']['output']>;
  name_in_competition?: Maybe<Scalars['String']['output']>;
  old_url?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  sponsors?: Maybe<Array<Sponsors_Team_Sponsor>>;
  sport_sub_category_name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team_in_season?: Maybe<Teams_Team_In_Season>;
  url?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "teams.team_detail" */
export type Teams_Team_DetailClubArgs = {
  args?: InputMaybe<Club_Teams_Team_Detail_Args>;
  distinct_on?: InputMaybe<Array<Clubs_Club_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Detail_Order_By>>;
  where?: InputMaybe<Clubs_Club_Detail_Bool_Exp>;
};

/** columns and relationships of "teams.team_detail" */
export type Teams_Team_DetailCompetitionsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Has_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Has_Teams_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
};

/** columns and relationships of "teams.team_detail" */
export type Teams_Team_DetailEventsArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

/** columns and relationships of "teams.team_detail" */
export type Teams_Team_DetailEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Competitions_Playing_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Playing_Team_Order_By>>;
  where?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
};

/** columns and relationships of "teams.team_detail" */
export type Teams_Team_DetailSponsorsArgs = {
  distinct_on?: InputMaybe<Array<Sponsors_Team_Sponsor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sponsors_Team_Sponsor_Order_By>>;
  where?: InputMaybe<Sponsors_Team_Sponsor_Bool_Exp>;
};

/** order by aggregate values of table "teams.team_detail" */
export type Teams_Team_Detail_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Team_Detail_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Team_Detail_Max_Order_By>;
  min?: InputMaybe<Teams_Team_Detail_Min_Order_By>;
  stddev?: InputMaybe<Teams_Team_Detail_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Team_Detail_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Team_Detail_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Team_Detail_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Team_Detail_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Team_Detail_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Team_Detail_Variance_Order_By>;
};

/** order by avg() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Avg_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.team_detail". All fields are combined with a logical 'AND'. */
export type Teams_Team_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Team_Detail_Bool_Exp>>;
  _not?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Team_Detail_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  competitions?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
  events?: InputMaybe<Competitions_Playing_Team_Bool_Exp>;
  events_aggregate?: InputMaybe<Competitions_Playing_Team_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_group_of_teams?: InputMaybe<Boolean_Comparison_Exp>;
  level_name?: InputMaybe<String_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name_in_club?: InputMaybe<String_Comparison_Exp>;
  name_in_competition?: InputMaybe<String_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  sponsors?: InputMaybe<Sponsors_Team_Sponsor_Bool_Exp>;
  sport_sub_category_name?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

export type Teams_Team_Detail_By_Club_Id_Args = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  season_slug?: InputMaybe<Scalars['String']['input']>;
};

export type Teams_Team_Detail_By_Member_Id_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  season_slug?: InputMaybe<Scalars['String']['input']>;
};

export type Teams_Team_Detail_By_Pk_Args = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type Teams_Team_Detail_By_Team_Id_Args = {
  locale?: InputMaybe<Scalars['String']['input']>;
  season_slug?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Max_Order_By = {
  category?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_name?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name_in_club?: InputMaybe<Order_By>;
  name_in_competition?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_sub_category_name?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Min_Order_By = {
  category?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level_name?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name_in_club?: InputMaybe<Order_By>;
  name_in_competition?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_sub_category_name?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "teams.team_detail". */
export type Teams_Team_Detail_Order_By = {
  category?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  competitions_aggregate?: InputMaybe<Competitions_Competition_Has_Teams_Aggregate_Order_By>;
  events_aggregate?: InputMaybe<Competitions_Playing_Team_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_group_of_teams?: InputMaybe<Order_By>;
  level_name?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name_in_club?: InputMaybe<Order_By>;
  name_in_competition?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sponsors_aggregate?: InputMaybe<Sponsors_Team_Sponsor_Aggregate_Order_By>;
  sport_sub_category_name?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "teams.team_detail" */
export enum Teams_Team_Detail_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsGroupOfTeams = 'is_group_of_teams',
  /** column name */
  LevelName = 'level_name',
  /** column name */
  Logo = 'logo',
  /** column name */
  NameInClub = 'name_in_club',
  /** column name */
  NameInCompetition = 'name_in_competition',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Rank = 'rank',
  /** column name */
  Slug = 'slug',
  /** column name */
  SportSubCategoryName = 'sport_sub_category_name',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  Url = 'url',
}

/** order by stddev() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Stddev_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Stddev_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Stddev_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_team_detail" */
export type Teams_Team_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Team_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Team_Detail_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_group_of_teams?: InputMaybe<Scalars['Boolean']['input']>;
  level_name?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name_in_club?: InputMaybe<Scalars['String']['input']>;
  name_in_competition?: InputMaybe<Scalars['String']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_sub_category_name?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Sum_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Var_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Var_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.team_detail" */
export type Teams_Team_Detail_Variance_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "teams.team_has_fans" */
export type Teams_Team_Has_Fans = {
  __typename: 'teams_team_has_fans';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  fan?: Maybe<Teams_Fan>;
  fan_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Team_Has_Fans_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Team_Has_Fans_Max_Order_By>;
  min?: InputMaybe<Teams_Team_Has_Fans_Min_Order_By>;
  stddev?: InputMaybe<Teams_Team_Has_Fans_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Team_Has_Fans_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Team_Has_Fans_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Team_Has_Fans_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Team_Has_Fans_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Team_Has_Fans_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Team_Has_Fans_Variance_Order_By>;
};

/** order by avg() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Avg_Order_By = {
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.team_has_fans". All fields are combined with a logical 'AND'. */
export type Teams_Team_Has_Fans_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Team_Has_Fans_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Team_Has_Fans_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Team_Has_Fans_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  fan?: InputMaybe<Teams_Fan_Bool_Exp>;
  fan_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "teams.team_has_fans". */
export type Teams_Team_Has_Fans_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  fan?: InputMaybe<Teams_Fan_Order_By>;
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** select columns of table "teams.team_has_fans" */
export enum Teams_Team_Has_Fans_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  FanId = 'fan_id',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
}

/** order by stddev() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Stddev_Order_By = {
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Stddev_Pop_Order_By = {
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Stddev_Samp_Order_By = {
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_team_has_fans" */
export type Teams_Team_Has_Fans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Team_Has_Fans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Team_Has_Fans_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  fan_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Sum_Order_By = {
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Var_Pop_Order_By = {
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Var_Samp_Order_By = {
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.team_has_fans" */
export type Teams_Team_Has_Fans_Variance_Order_By = {
  fan_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "teams.team_in_season" */
export type Teams_Team_In_Season = {
  __typename: 'teams_team_in_season';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  added_by_user_id?: Maybe<Scalars['Int']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  competitions?: Maybe<Array<Competitions_Competition_Has_Teams>>;
  custom_name_in_club?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  entente?: Maybe<Teams_Team_In_Season>;
  id: Scalars['Int']['output'];
  last_competition_with_ranking?: Maybe<Array<Competitions_Competition_Detail>>;
  logo?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  main_level?: Maybe<Teams_Level>;
  main_level_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  members?: Maybe<Array<Teams_Team_In_Season_Has_Member>>;
  /** An aggregate relationship */
  members_aggregate?: Maybe<Teams_Team_In_Season_Has_Member_Aggregate>;
  old_share_picture_background?: Maybe<Scalars['String']['output']>;
  old_squad_picture?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['Int']['output']>;
  read_logo?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  season?: Maybe<Clubs_Season>;
  season_id?: Maybe<Scalars['Int']['output']>;
  share_picture_background?: Maybe<Scalars['String']['output']>;
  short_name?: Maybe<Scalars['String']['output']>;
  squad_picture?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  translations?: Maybe<Array<Teams_Team_In_Season_Translation>>;
};

/** columns and relationships of "teams.team_in_season" */
export type Teams_Team_In_SeasonCompetitionsArgs = {
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Has_Teams_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Has_Teams_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
};

/** columns and relationships of "teams.team_in_season" */
export type Teams_Team_In_SeasonLast_Competition_With_RankingArgs = {
  args?: InputMaybe<Last_Competition_With_Ranking_Teams_Team_In_Season_Args>;
  distinct_on?: InputMaybe<
    Array<Competitions_Competition_Detail_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Competitions_Competition_Detail_Order_By>>;
  where?: InputMaybe<Competitions_Competition_Detail_Bool_Exp>;
};

/** columns and relationships of "teams.team_in_season" */
export type Teams_Team_In_SeasonMembersArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Has_Member_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

/** columns and relationships of "teams.team_in_season" */
export type Teams_Team_In_SeasonMembers_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Has_Member_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

/** columns and relationships of "teams.team_in_season" */
export type Teams_Team_In_SeasonTranslationsArgs = {
  distinct_on?: InputMaybe<
    Array<Teams_Team_In_Season_Translation_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_In_Season_Translation_Order_By>>;
  where?: InputMaybe<Teams_Team_In_Season_Translation_Bool_Exp>;
};

/** order by aggregate values of table "teams.team_in_season" */
export type Teams_Team_In_Season_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Team_In_Season_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Team_In_Season_Max_Order_By>;
  min?: InputMaybe<Teams_Team_In_Season_Min_Order_By>;
  stddev?: InputMaybe<Teams_Team_In_Season_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Team_In_Season_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Team_In_Season_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Team_In_Season_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Team_In_Season_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Team_In_Season_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Team_In_Season_Variance_Order_By>;
};

/** order by avg() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Avg_Order_By = {
  added_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.team_in_season". All fields are combined with a logical 'AND'. */
export type Teams_Team_In_Season_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Team_In_Season_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Team_In_Season_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  added_by_user_id?: InputMaybe<Int_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  competitions?: InputMaybe<Competitions_Competition_Has_Teams_Bool_Exp>;
  custom_name_in_club?: InputMaybe<String_Comparison_Exp>;
  entente?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  main_level?: InputMaybe<Teams_Level_Bool_Exp>;
  main_level_id?: InputMaybe<Int_Comparison_Exp>;
  members?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
  members_aggregate?: InputMaybe<Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp>;
  old_share_picture_background?: InputMaybe<String_Comparison_Exp>;
  old_squad_picture?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<Int_Comparison_Exp>;
  season?: InputMaybe<Clubs_Season_Bool_Exp>;
  season_id?: InputMaybe<Int_Comparison_Exp>;
  share_picture_background?: InputMaybe<String_Comparison_Exp>;
  short_name?: InputMaybe<String_Comparison_Exp>;
  squad_picture?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  translations?: InputMaybe<Teams_Team_In_Season_Translation_Bool_Exp>;
};

/** columns and relationships of "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member = {
  __typename: 'teams_team_in_season_has_member';
  captain?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  is_burned?: Maybe<Scalars['Boolean']['output']>;
  is_staff?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['smallint']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  public_name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  team_in_season?: Maybe<Teams_Team_In_Season>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Aggregate = {
  __typename: 'teams_team_in_season_has_member_aggregate';
  aggregate?: Maybe<Teams_Team_In_Season_Has_Member_Aggregate_Fields>;
  nodes?: Maybe<Array<Teams_Team_In_Season_Has_Member>>;
};

export type Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Count>;
};

export type Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Bool_And = {
  arguments: Teams_Team_In_Season_Has_Member_Select_Column_Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Teams_Team_In_Season_Has_Member_Select_Column_Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Aggregate_Fields = {
  __typename: 'teams_team_in_season_has_member_aggregate_fields';
  avg?: Maybe<Teams_Team_In_Season_Has_Member_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Teams_Team_In_Season_Has_Member_Max_Fields>;
  min?: Maybe<Teams_Team_In_Season_Has_Member_Min_Fields>;
  stddev?: Maybe<Teams_Team_In_Season_Has_Member_Stddev_Fields>;
  stddev_pop?: Maybe<Teams_Team_In_Season_Has_Member_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teams_Team_In_Season_Has_Member_Stddev_Samp_Fields>;
  sum?: Maybe<Teams_Team_In_Season_Has_Member_Sum_Fields>;
  var_pop?: Maybe<Teams_Team_In_Season_Has_Member_Var_Pop_Fields>;
  var_samp?: Maybe<Teams_Team_In_Season_Has_Member_Var_Samp_Fields>;
  variance?: Maybe<Teams_Team_In_Season_Has_Member_Variance_Fields>;
};

/** aggregate fields of "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Team_In_Season_Has_Member_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Team_In_Season_Has_Member_Max_Order_By>;
  min?: InputMaybe<Teams_Team_In_Season_Has_Member_Min_Order_By>;
  stddev?: InputMaybe<Teams_Team_In_Season_Has_Member_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Team_In_Season_Has_Member_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Team_In_Season_Has_Member_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Team_In_Season_Has_Member_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Team_In_Season_Has_Member_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Team_In_Season_Has_Member_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Team_In_Season_Has_Member_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Arr_Rel_Insert_Input = {
  data: Array<Teams_Team_In_Season_Has_Member_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Team_In_Season_Has_Member_On_Conflict>;
};

/** aggregate avg on columns */
export type Teams_Team_In_Season_Has_Member_Avg_Fields = {
  __typename: 'teams_team_in_season_has_member_avg_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.team_in_season_has_member". All fields are combined with a logical 'AND'. */
export type Teams_Team_In_Season_Has_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Bool_Exp>>;
  _not?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Team_In_Season_Has_Member_Bool_Exp>>;
  captain?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_burned?: InputMaybe<Boolean_Comparison_Exp>;
  is_staff?: InputMaybe<Boolean_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  number?: InputMaybe<Smallint_Comparison_Exp>;
  photo?: InputMaybe<String_Comparison_Exp>;
  public_name?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Bool_Exp>;
  team_in_season_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams.team_in_season_has_member" */
export enum Teams_Team_In_Season_Has_Member_Constraint {
  /** unique or primary key constraint on columns "member_id", "team_in_season_id", "is_staff" */
  TeamInSeasonHasMembeTeamInSeasonIdMember_9b92670bUniq = 'team_in_season_has_membe_team_in_season_id_member_9b92670b_uniq',
  /** unique or primary key constraint on columns "id" */
  TeamInSeasonHasMemberPkey = 'team_in_season_has_member_pkey',
}

/** input type for incrementing numeric columns in table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Inc_Input = {
  number?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Insert_Input = {
  captain?: InputMaybe<Scalars['Boolean']['input']>;
  is_burned?: InputMaybe<Scalars['Boolean']['input']>;
  is_staff?: InputMaybe<Scalars['Boolean']['input']>;
  member?: InputMaybe<Members_Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['smallint']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  public_name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  team_in_season_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Teams_Team_In_Season_Has_Member_Max_Fields = {
  __typename: 'teams_team_in_season_has_member_max_fields';
  id: Scalars['Int']['output'];
  member_id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['smallint']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  public_name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  public_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Teams_Team_In_Season_Has_Member_Min_Fields = {
  __typename: 'teams_team_in_season_has_member_min_fields';
  id: Scalars['Int']['output'];
  member_id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['smallint']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  public_name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  public_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Mutation_Response = {
  __typename: 'teams_team_in_season_has_member_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Team_In_Season_Has_Member>>;
};

/** on_conflict condition type for table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_On_Conflict = {
  constraint: Teams_Team_In_Season_Has_Member_Constraint;
  update_columns?: Array<Teams_Team_In_Season_Has_Member_Update_Column>;
  where?: InputMaybe<Teams_Team_In_Season_Has_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "teams.team_in_season_has_member". */
export type Teams_Team_In_Season_Has_Member_Order_By = {
  captain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_burned?: InputMaybe<Order_By>;
  is_staff?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  photo?: InputMaybe<Order_By>;
  public_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  team_in_season?: InputMaybe<Teams_Team_In_Season_Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams.team_in_season_has_member */
export type Teams_Team_In_Season_Has_Member_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "teams.team_in_season_has_member" */
export enum Teams_Team_In_Season_Has_Member_Select_Column {
  /** column name */
  Captain = 'captain',
  /** column name */
  Id = 'id',
  /** column name */
  IsBurned = 'is_burned',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  Number = 'number',
  /** column name */
  Photo = 'photo',
  /** column name */
  PublicName = 'public_name',
  /** column name */
  Role = 'role',
  /** column name */
  TeamInSeasonId = 'team_in_season_id',
}

/** select "teams_team_in_season_has_member_aggregate_bool_exp_bool_and_arguments_columns" columns of table "teams.team_in_season_has_member" */
export enum Teams_Team_In_Season_Has_Member_Select_Column_Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Captain = 'captain',
  /** column name */
  IsBurned = 'is_burned',
  /** column name */
  IsStaff = 'is_staff',
}

/** select "teams_team_in_season_has_member_aggregate_bool_exp_bool_or_arguments_columns" columns of table "teams.team_in_season_has_member" */
export enum Teams_Team_In_Season_Has_Member_Select_Column_Teams_Team_In_Season_Has_Member_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Captain = 'captain',
  /** column name */
  IsBurned = 'is_burned',
  /** column name */
  IsStaff = 'is_staff',
}

/** input type for updating data in table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Set_Input = {
  captain?: InputMaybe<Scalars['Boolean']['input']>;
  is_burned?: InputMaybe<Scalars['Boolean']['input']>;
  is_staff?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['smallint']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  public_name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Teams_Team_In_Season_Has_Member_Stddev_Fields = {
  __typename: 'teams_team_in_season_has_member_stddev_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Teams_Team_In_Season_Has_Member_Stddev_Pop_Fields = {
  __typename: 'teams_team_in_season_has_member_stddev_pop_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Teams_Team_In_Season_Has_Member_Stddev_Samp_Fields = {
  __typename: 'teams_team_in_season_has_member_stddev_samp_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Team_In_Season_Has_Member_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Team_In_Season_Has_Member_Stream_Cursor_Value_Input = {
  captain?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_burned?: InputMaybe<Scalars['Boolean']['input']>;
  is_staff?: InputMaybe<Scalars['Boolean']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  number?: InputMaybe<Scalars['smallint']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  public_name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  team_in_season_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Teams_Team_In_Season_Has_Member_Sum_Fields = {
  __typename: 'teams_team_in_season_has_member_sum_fields';
  id: Scalars['Int']['output'];
  member_id?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['smallint']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** update columns of table "teams.team_in_season_has_member" */
export enum Teams_Team_In_Season_Has_Member_Update_Column {
  /** column name */
  Captain = 'captain',
  /** column name */
  IsBurned = 'is_burned',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  Number = 'number',
  /** column name */
  Photo = 'photo',
  /** column name */
  PublicName = 'public_name',
  /** column name */
  Role = 'role',
}

export type Teams_Team_In_Season_Has_Member_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Teams_Team_In_Season_Has_Member_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Team_In_Season_Has_Member_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Team_In_Season_Has_Member_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Teams_Team_In_Season_Has_Member_Var_Pop_Fields = {
  __typename: 'teams_team_in_season_has_member_var_pop_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Teams_Team_In_Season_Has_Member_Var_Samp_Fields = {
  __typename: 'teams_team_in_season_has_member_var_samp_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Teams_Team_In_Season_Has_Member_Variance_Fields = {
  __typename: 'teams_team_in_season_has_member_variance_fields';
  id: Scalars['Float']['output'];
  member_id?: Maybe<Scalars['Float']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  team_in_season_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "teams.team_in_season_has_member" */
export type Teams_Team_In_Season_Has_Member_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "teams.team_in_season" */
export type Teams_Team_In_Season_Inc_Input = {
  parent_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  added_by_user_id?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  old_share_picture_background?: InputMaybe<Order_By>;
  old_squad_picture?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  share_picture_background?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  squad_picture?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  added_by_user_id?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  old_share_picture_background?: InputMaybe<Order_By>;
  old_squad_picture?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  share_picture_background?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  squad_picture?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams.team_in_season" */
export type Teams_Team_In_Season_Mutation_Response = {
  __typename: 'teams_team_in_season_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Team_In_Season>>;
};

/** Ordering options when selecting data from "teams.team_in_season". */
export type Teams_Team_In_Season_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  added_by_user_id?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  competitions_aggregate?: InputMaybe<Competitions_Competition_Has_Teams_Aggregate_Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  entente?: InputMaybe<Teams_Team_In_Season_Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  main_level?: InputMaybe<Teams_Level_Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Teams_Team_In_Season_Has_Member_Aggregate_Order_By>;
  old_share_picture_background?: InputMaybe<Order_By>;
  old_squad_picture?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season?: InputMaybe<Clubs_Season_Order_By>;
  season_id?: InputMaybe<Order_By>;
  share_picture_background?: InputMaybe<Order_By>;
  short_name?: InputMaybe<Order_By>;
  squad_picture?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  translations_aggregate?: InputMaybe<Teams_Team_In_Season_Translation_Aggregate_Order_By>;
};

/** primary key columns input for table: teams.team_in_season */
export type Teams_Team_In_Season_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "teams.team_in_season" */
export enum Teams_Team_In_Season_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AddedByUserId = 'added_by_user_id',
  /** column name */
  Category = 'category',
  /** column name */
  CustomNameInClub = 'custom_name_in_club',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  MainLevelId = 'main_level_id',
  /** column name */
  OldSharePictureBackground = 'old_share_picture_background',
  /** column name */
  OldSquadPicture = 'old_squad_picture',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  SeasonId = 'season_id',
  /** column name */
  SharePictureBackground = 'share_picture_background',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  SquadPicture = 'squad_picture',
  /** column name */
  TeamId = 'team_id',
}

/** input type for updating data in table "teams.team_in_season" */
export type Teams_Team_In_Season_Set_Input = {
  category?: InputMaybe<Scalars['String']['input']>;
  custom_name_in_club?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
  share_picture_background?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  squad_picture?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Stddev_Order_By = {
  added_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Stddev_Pop_Order_By = {
  added_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Stddev_Samp_Order_By = {
  added_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_team_in_season" */
export type Teams_Team_In_Season_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Team_In_Season_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Team_In_Season_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  added_by_user_id?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  custom_name_in_club?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  main_level_id?: InputMaybe<Scalars['Int']['input']>;
  old_share_picture_background?: InputMaybe<Scalars['String']['input']>;
  old_squad_picture?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
  season_id?: InputMaybe<Scalars['Int']['input']>;
  share_picture_background?: InputMaybe<Scalars['String']['input']>;
  short_name?: InputMaybe<Scalars['String']['input']>;
  squad_picture?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Sum_Order_By = {
  added_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation = {
  __typename: 'teams_team_in_season_translation';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  custom_name_in_club?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  team_in_season_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Team_In_Season_Translation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Team_In_Season_Translation_Max_Order_By>;
  min?: InputMaybe<Teams_Team_In_Season_Translation_Min_Order_By>;
  stddev?: InputMaybe<Teams_Team_In_Season_Translation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Team_In_Season_Translation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Team_In_Season_Translation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Team_In_Season_Translation_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Team_In_Season_Translation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Team_In_Season_Translation_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Team_In_Season_Translation_Variance_Order_By>;
};

/** order by avg() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.team_in_season_translation". All fields are combined with a logical 'AND'. */
export type Teams_Team_In_Season_Translation_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Team_In_Season_Translation_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Team_In_Season_Translation_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Team_In_Season_Translation_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_name_in_club?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  team_in_season_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "teams.team_in_season_translation". */
export type Teams_Team_In_Season_Translation_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  custom_name_in_club?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** select columns of table "teams.team_in_season_translation" */
export enum Teams_Team_In_Season_Translation_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  CustomNameInClub = 'custom_name_in_club',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  TeamInSeasonId = 'team_in_season_id',
}

/** order by stddev() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Team_In_Season_Translation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Team_In_Season_Translation_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_name_in_club?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  team_in_season_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.team_in_season_translation" */
export type Teams_Team_In_Season_Translation_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  team_in_season_id?: InputMaybe<Order_By>;
};

export type Teams_Team_In_Season_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Teams_Team_In_Season_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Team_In_Season_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Team_In_Season_Bool_Exp;
};

/** order by var_pop() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Var_Pop_Order_By = {
  added_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Var_Samp_Order_By = {
  added_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.team_in_season" */
export type Teams_Team_In_Season_Variance_Order_By = {
  added_by_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  main_level_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  season_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "teams.team" */
export type Teams_Team_Inc_Input = {
  default_equipment_id?: InputMaybe<Scalars['Int']['input']>;
  meeting_before_event?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by max() on columns of table "teams.team" */
export type Teams_Team_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_category?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  custom_name_in_competition?: InputMaybe<Order_By>;
  custom_short_name_in_competition?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  share_picture?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.team" */
export type Teams_Team_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_category?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  custom_name_in_competition?: InputMaybe<Order_By>;
  custom_short_name_in_competition?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  share_picture?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams.team" */
export type Teams_Team_Mutation_Response = {
  __typename: 'teams_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Team>>;
};

/** columns and relationships of "teams.team_official" */
export type Teams_Team_Official = {
  __typename: 'teams_team_official';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  official?: Maybe<Teams_Official>;
  official_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "teams.team_official" */
export type Teams_Team_Official_Aggregate_Order_By = {
  avg?: InputMaybe<Teams_Team_Official_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Teams_Team_Official_Max_Order_By>;
  min?: InputMaybe<Teams_Team_Official_Min_Order_By>;
  stddev?: InputMaybe<Teams_Team_Official_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Teams_Team_Official_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Teams_Team_Official_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Teams_Team_Official_Sum_Order_By>;
  var_pop?: InputMaybe<Teams_Team_Official_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Teams_Team_Official_Var_Samp_Order_By>;
  variance?: InputMaybe<Teams_Team_Official_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "teams.team_official" */
export type Teams_Team_Official_Arr_Rel_Insert_Input = {
  data: Array<Teams_Team_Official_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Teams_Team_Official_On_Conflict>;
};

/** order by avg() on columns of table "teams.team_official" */
export type Teams_Team_Official_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "teams.team_official". All fields are combined with a logical 'AND'. */
export type Teams_Team_Official_Bool_Exp = {
  _and?: InputMaybe<Array<Teams_Team_Official_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Teams_Team_Official_Bool_Exp>;
  _or?: InputMaybe<Array<Teams_Team_Official_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  official?: InputMaybe<Teams_Official_Bool_Exp>;
  official_id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teams.team_official" */
export enum Teams_Team_Official_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamOfficialPkey = 'team_official_pkey',
  /** unique or primary key constraint on columns "official_id", "team_id" */
  TeamOfficialTeamIdOfficialIdDcfeb5d7Uniq = 'team_official_team_id_official_id_dcfeb5d7_uniq',
}

/** input type for inserting data into table "teams.team_official" */
export type Teams_Team_Official_Insert_Input = {
  official?: InputMaybe<Teams_Official_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "teams.team_official" */
export type Teams_Team_Official_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "teams.team_official" */
export type Teams_Team_Official_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "teams.team_official" */
export type Teams_Team_Official_Mutation_Response = {
  __typename: 'teams_team_official_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Teams_Team_Official>>;
};

/** on_conflict condition type for table "teams.team_official" */
export type Teams_Team_Official_On_Conflict = {
  constraint: Teams_Team_Official_Constraint;
  update_columns?: Array<Teams_Team_Official_Update_Column>;
  where?: InputMaybe<Teams_Team_Official_Bool_Exp>;
};

/** Ordering options when selecting data from "teams.team_official". */
export type Teams_Team_Official_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  official?: InputMaybe<Teams_Official_Order_By>;
  official_id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** select columns of table "teams.team_official" */
export enum Teams_Team_Official_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  OfficialId = 'official_id',
  /** column name */
  TeamId = 'team_id',
}

/** order by stddev() on columns of table "teams.team_official" */
export type Teams_Team_Official_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.team_official" */
export type Teams_Team_Official_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.team_official" */
export type Teams_Team_Official_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_team_official" */
export type Teams_Team_Official_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Team_Official_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Team_Official_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  official_id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "teams.team_official" */
export type Teams_Team_Official_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "teams.team_official" (current role has no relevant permissions) */
export enum Teams_Team_Official_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "teams.team_official" */
export type Teams_Team_Official_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.team_official" */
export type Teams_Team_Official_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.team_official" */
export type Teams_Team_Official_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  official_id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "teams.team". */
export type Teams_Team_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  action_permissions_aggregate?: InputMaybe<Teams_Team_Action_Permission_Aggregate_Order_By>;
  age_category?: InputMaybe<Order_By>;
  authorizations_aggregate?: InputMaybe<Teams_Authorized_Team_Aggregate_Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_groups_aggregate?: InputMaybe<Teams_Club_Group_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  current_team_detail_aggregate?: InputMaybe<Teams_Team_Detail_Aggregate_Order_By>;
  custom_name_in_competition?: InputMaybe<Order_By>;
  custom_short_name_in_competition?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  fans_aggregate?: InputMaybe<Teams_Team_Has_Fans_Aggregate_Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_events_aggregate?: InputMaybe<Competitions_Event_Detail_Aggregate_Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  next_events_aggregate?: InputMaybe<Competitions_Event_Detail_Aggregate_Order_By>;
  number?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  referee_official?: InputMaybe<Order_By>;
  scoring_table_official?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  seasons_aggregate?: InputMaybe<Teams_Team_In_Season_Aggregate_Order_By>;
  share_picture?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  sport_sub_category?: InputMaybe<Clubs_Sport_Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
  team_officials_aggregate?: InputMaybe<Teams_Team_Official_Aggregate_Order_By>;
  team_tasks_aggregate?: InputMaybe<Tasks_Team_Task_Aggregate_Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: teams.team */
export type Teams_Team_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "teams.team" */
export enum Teams_Team_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AgeCategory = 'age_category',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CustomNameInCompetition = 'custom_name_in_competition',
  /** column name */
  CustomShortNameInCompetition = 'custom_short_name_in_competition',
  /** column name */
  DefaultEquipmentId = 'default_equipment_id',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingBeforeEvent = 'meeting_before_event',
  /** column name */
  Name = 'name',
  /** column name */
  NbFans = 'nb_fans',
  /** column name */
  Number = 'number',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  OldUrl = 'old_url',
  /** column name */
  Rank = 'rank',
  /** column name */
  RefereeOfficial = 'referee_official',
  /** column name */
  ScoringTableOfficial = 'scoring_table_official',
  /** column name */
  ScrapingId = 'scraping_id',
  /** column name */
  SharePicture = 'share_picture',
  /** column name */
  Slug = 'slug',
  /** column name */
  SportSubCategoryId = 'sport_sub_category_id',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "teams.team" */
export type Teams_Team_Set_Input = {
  custom_short_name_in_competition?: InputMaybe<Scalars['String']['input']>;
  default_equipment_id?: InputMaybe<Scalars['Int']['input']>;
  meeting_before_event?: InputMaybe<Scalars['smallint']['input']>;
  referee_official?: InputMaybe<Scalars['Boolean']['input']>;
  scoring_table_official?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by stddev() on columns of table "teams.team" */
export type Teams_Team_Stddev_Order_By = {
  club_id?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "teams.team" */
export type Teams_Team_Stddev_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "teams.team" */
export type Teams_Team_Stddev_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "teams_team" */
export type Teams_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Teams_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Teams_Team_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  age_category?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  custom_name_in_competition?: InputMaybe<Scalars['String']['input']>;
  custom_short_name_in_competition?: InputMaybe<Scalars['String']['input']>;
  default_equipment_id?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  meeting_before_event?: InputMaybe<Scalars['smallint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nb_fans?: InputMaybe<Scalars['smallint']['input']>;
  number?: InputMaybe<Scalars['smallint']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  old_url?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  referee_official?: InputMaybe<Scalars['Boolean']['input']>;
  scoring_table_official?: InputMaybe<Scalars['Boolean']['input']>;
  scraping_id?: InputMaybe<Scalars['Int']['input']>;
  share_picture?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sport_sub_category_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "teams.team" */
export type Teams_Team_Sum_Order_By = {
  club_id?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

export type Teams_Team_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Teams_Team_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Teams_Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Teams_Team_Bool_Exp;
};

/** order by var_pop() on columns of table "teams.team" */
export type Teams_Team_Var_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "teams.team" */
export type Teams_Team_Var_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "teams.team" */
export type Teams_Team_Variance_Order_By = {
  club_id?: InputMaybe<Order_By>;
  default_equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meeting_before_event?: InputMaybe<Order_By>;
  nb_fans?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scraping_id?: InputMaybe<Order_By>;
  sport_sub_category_id?: InputMaybe<Order_By>;
};

export type Teams_Update_Team_Name_Mutation_Response = {
  __typename: 'teams_update_team_name_mutation_response';
  team_updated?: Maybe<Scalars['smallint']['output']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "tournaments.challenge" */
export type Tournaments_Challenge = {
  __typename: 'tournaments_challenge';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  age_category?: Maybe<Scalars['String']['output']>;
  age_limit?: Maybe<Scalars['date']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hit_count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  max_nb_players?: Maybe<Scalars['smallint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  rules?: Maybe<Array<Tournaments_Challenge_Rule>>;
  /** An array relationship */
  teams?: Maybe<Array<Tournaments_Team>>;
  /** An object relationship */
  tournament?: Maybe<Tournaments_Tournament>;
  tournament_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "tournaments.challenge" */
export type Tournaments_ChallengeRulesArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Challenge_Rule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Challenge_Rule_Order_By>>;
  where?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
};

/** columns and relationships of "tournaments.challenge" */
export type Tournaments_ChallengeTeamsArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Team_Order_By>>;
  where?: InputMaybe<Tournaments_Team_Bool_Exp>;
};

/** order by aggregate values of table "tournaments.challenge" */
export type Tournaments_Challenge_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Challenge_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Challenge_Max_Order_By>;
  min?: InputMaybe<Tournaments_Challenge_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Challenge_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Challenge_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Challenge_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Challenge_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Challenge_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Challenge_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Challenge_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournaments.challenge" */
export type Tournaments_Challenge_Arr_Rel_Insert_Input = {
  data: Array<Tournaments_Challenge_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Challenge_On_Conflict>;
};

/** order by avg() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Avg_Order_By = {
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.challenge". All fields are combined with a logical 'AND'. */
export type Tournaments_Challenge_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Challenge_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Challenge_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  age_category?: InputMaybe<String_Comparison_Exp>;
  age_limit?: InputMaybe<Date_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  hit_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  max_nb_players?: InputMaybe<Smallint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  rules?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
  teams?: InputMaybe<Tournaments_Team_Bool_Exp>;
  tournament?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
  tournament_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments.challenge" */
export enum Tournaments_Challenge_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChallengePkey = 'challenge_pkey',
}

/** input type for incrementing numeric columns in table "tournaments.challenge" */
export type Tournaments_Challenge_Inc_Input = {
  max_nb_players?: InputMaybe<Scalars['smallint']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tournaments.challenge" */
export type Tournaments_Challenge_Insert_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  age_category?: InputMaybe<Scalars['String']['input']>;
  age_limit?: InputMaybe<Scalars['date']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  max_nb_players?: InputMaybe<Scalars['smallint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  rules?: InputMaybe<Tournaments_Challenge_Rule_Arr_Rel_Insert_Input>;
  teams?: InputMaybe<Tournaments_Team_Arr_Rel_Insert_Input>;
  tournament?: InputMaybe<Tournaments_Tournament_Obj_Rel_Insert_Input>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_category?: InputMaybe<Order_By>;
  age_limit?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_category?: InputMaybe<Order_By>;
  age_limit?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.challenge" */
export type Tournaments_Challenge_Mutation_Response = {
  __typename: 'tournaments_challenge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Challenge>>;
};

/** input type for inserting object relation for remote table "tournaments.challenge" */
export type Tournaments_Challenge_Obj_Rel_Insert_Input = {
  data: Tournaments_Challenge_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Challenge_On_Conflict>;
};

/** on_conflict condition type for table "tournaments.challenge" */
export type Tournaments_Challenge_On_Conflict = {
  constraint: Tournaments_Challenge_Constraint;
  update_columns?: Array<Tournaments_Challenge_Update_Column>;
  where?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments.challenge". */
export type Tournaments_Challenge_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_category?: InputMaybe<Order_By>;
  age_limit?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rules_aggregate?: InputMaybe<Tournaments_Challenge_Rule_Aggregate_Order_By>;
  teams_aggregate?: InputMaybe<Tournaments_Team_Aggregate_Order_By>;
  tournament?: InputMaybe<Tournaments_Tournament_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.challenge */
export type Tournaments_Challenge_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule = {
  __typename: 'tournaments_challenge_rule';
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  challenge?: Maybe<Tournaments_Challenge>;
  challenge_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nb_groups?: Maybe<Scalars['smallint']['output']>;
  nb_teams?: Maybe<Scalars['smallint']['output']>;
  nb_teams_in_final_phase?: Maybe<Scalars['smallint']['output']>;
  period_duration?: Maybe<Scalars['smallint']['output']>;
  period_nb?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  phases?: Maybe<Array<Tournaments_Phase>>;
  /** An aggregate relationship */
  phases_aggregate?: Maybe<Tournaments_Phase_Aggregate>;
  rank?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  ranking_point?: Maybe<Tournaments_Ranking_Points>;
  /** An array relationship */
  ranking_rules?: Maybe<Array<Tournaments_Ranking_Rule>>;
  rule_type?: Maybe<Scalars['smallint']['output']>;
  time_between_periods?: Maybe<Scalars['smallint']['output']>;
};

/** columns and relationships of "tournaments.challenge_rule" */
export type Tournaments_Challenge_RulePhasesArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Phase_Bool_Exp>;
};

/** columns and relationships of "tournaments.challenge_rule" */
export type Tournaments_Challenge_RulePhases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Phase_Bool_Exp>;
};

/** columns and relationships of "tournaments.challenge_rule" */
export type Tournaments_Challenge_RuleRanking_RulesArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Ranking_Rule_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Ranking_Rule_Order_By>>;
  where?: InputMaybe<Tournaments_Ranking_Rule_Bool_Exp>;
};

/** order by aggregate values of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Challenge_Rule_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Challenge_Rule_Max_Order_By>;
  min?: InputMaybe<Tournaments_Challenge_Rule_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Challenge_Rule_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Challenge_Rule_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Challenge_Rule_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Challenge_Rule_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Challenge_Rule_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Challenge_Rule_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Challenge_Rule_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Arr_Rel_Insert_Input = {
  data: Array<Tournaments_Challenge_Rule_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Challenge_Rule_On_Conflict>;
};

/** order by avg() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Avg_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.challenge_rule". All fields are combined with a logical 'AND'. */
export type Tournaments_Challenge_Rule_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Challenge_Rule_Bool_Exp>>;
  _not?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Challenge_Rule_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  challenge?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
  challenge_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_groups?: InputMaybe<Smallint_Comparison_Exp>;
  nb_teams?: InputMaybe<Smallint_Comparison_Exp>;
  nb_teams_in_final_phase?: InputMaybe<Smallint_Comparison_Exp>;
  period_duration?: InputMaybe<Smallint_Comparison_Exp>;
  period_nb?: InputMaybe<Smallint_Comparison_Exp>;
  phases?: InputMaybe<Tournaments_Phase_Bool_Exp>;
  phases_aggregate?: InputMaybe<Tournaments_Phase_Aggregate_Bool_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  ranking_point?: InputMaybe<Tournaments_Ranking_Points_Bool_Exp>;
  ranking_rules?: InputMaybe<Tournaments_Ranking_Rule_Bool_Exp>;
  rule_type?: InputMaybe<Smallint_Comparison_Exp>;
  time_between_periods?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments.challenge_rule" */
export enum Tournaments_Challenge_Rule_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChallengeRulePkey = 'challenge_rule_pkey',
}

/** input type for incrementing numeric columns in table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Inc_Input = {
  nb_groups?: InputMaybe<Scalars['smallint']['input']>;
  nb_teams?: InputMaybe<Scalars['smallint']['input']>;
  nb_teams_in_final_phase?: InputMaybe<Scalars['smallint']['input']>;
  period_duration?: InputMaybe<Scalars['smallint']['input']>;
  period_nb?: InputMaybe<Scalars['smallint']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  rule_type?: InputMaybe<Scalars['smallint']['input']>;
  time_between_periods?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Insert_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  challenge?: InputMaybe<Tournaments_Challenge_Obj_Rel_Insert_Input>;
  challenge_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nb_groups?: InputMaybe<Scalars['smallint']['input']>;
  nb_teams?: InputMaybe<Scalars['smallint']['input']>;
  nb_teams_in_final_phase?: InputMaybe<Scalars['smallint']['input']>;
  period_duration?: InputMaybe<Scalars['smallint']['input']>;
  period_nb?: InputMaybe<Scalars['smallint']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  ranking_point?: InputMaybe<Tournaments_Ranking_Points_Obj_Rel_Insert_Input>;
  ranking_rules?: InputMaybe<Tournaments_Ranking_Rule_Arr_Rel_Insert_Input>;
  rule_type?: InputMaybe<Scalars['smallint']['input']>;
  time_between_periods?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by max() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Max_Order_By = {
  _updated?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Min_Order_By = {
  _updated?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Mutation_Response = {
  __typename: 'tournaments_challenge_rule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Challenge_Rule>>;
};

/** input type for inserting object relation for remote table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Obj_Rel_Insert_Input = {
  data: Tournaments_Challenge_Rule_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Challenge_Rule_On_Conflict>;
};

/** on_conflict condition type for table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_On_Conflict = {
  constraint: Tournaments_Challenge_Rule_Constraint;
  update_columns?: Array<Tournaments_Challenge_Rule_Update_Column>;
  where?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments.challenge_rule". */
export type Tournaments_Challenge_Rule_Order_By = {
  _updated?: InputMaybe<Order_By>;
  challenge?: InputMaybe<Tournaments_Challenge_Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  phases_aggregate?: InputMaybe<Tournaments_Phase_Aggregate_Order_By>;
  rank?: InputMaybe<Order_By>;
  ranking_point?: InputMaybe<Tournaments_Ranking_Points_Order_By>;
  ranking_rules_aggregate?: InputMaybe<Tournaments_Ranking_Rule_Aggregate_Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.challenge_rule */
export type Tournaments_Challenge_Rule_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tournaments.challenge_rule" */
export enum Tournaments_Challenge_Rule_Select_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NbGroups = 'nb_groups',
  /** column name */
  NbTeams = 'nb_teams',
  /** column name */
  NbTeamsInFinalPhase = 'nb_teams_in_final_phase',
  /** column name */
  PeriodDuration = 'period_duration',
  /** column name */
  PeriodNb = 'period_nb',
  /** column name */
  Rank = 'rank',
  /** column name */
  RuleType = 'rule_type',
  /** column name */
  TimeBetweenPeriods = 'time_between_periods',
}

/** input type for updating data in table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nb_groups?: InputMaybe<Scalars['smallint']['input']>;
  nb_teams?: InputMaybe<Scalars['smallint']['input']>;
  nb_teams_in_final_phase?: InputMaybe<Scalars['smallint']['input']>;
  period_duration?: InputMaybe<Scalars['smallint']['input']>;
  period_nb?: InputMaybe<Scalars['smallint']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  rule_type?: InputMaybe<Scalars['smallint']['input']>;
  time_between_periods?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by stddev() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Stddev_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Stddev_Pop_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Stddev_Samp_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_challenge_rule" */
export type Tournaments_Challenge_Rule_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Challenge_Rule_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Challenge_Rule_Stream_Cursor_Value_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  challenge_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nb_groups?: InputMaybe<Scalars['smallint']['input']>;
  nb_teams?: InputMaybe<Scalars['smallint']['input']>;
  nb_teams_in_final_phase?: InputMaybe<Scalars['smallint']['input']>;
  period_duration?: InputMaybe<Scalars['smallint']['input']>;
  period_nb?: InputMaybe<Scalars['smallint']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  rule_type?: InputMaybe<Scalars['smallint']['input']>;
  time_between_periods?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by sum() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Sum_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** update columns of table "tournaments.challenge_rule" */
export enum Tournaments_Challenge_Rule_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  Name = 'name',
  /** column name */
  NbGroups = 'nb_groups',
  /** column name */
  NbTeams = 'nb_teams',
  /** column name */
  NbTeamsInFinalPhase = 'nb_teams_in_final_phase',
  /** column name */
  PeriodDuration = 'period_duration',
  /** column name */
  PeriodNb = 'period_nb',
  /** column name */
  Rank = 'rank',
  /** column name */
  RuleType = 'rule_type',
  /** column name */
  TimeBetweenPeriods = 'time_between_periods',
}

export type Tournaments_Challenge_Rule_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Challenge_Rule_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Challenge_Rule_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Challenge_Rule_Bool_Exp;
};

/** order by var_pop() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Var_Pop_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Var_Samp_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tournaments.challenge_rule" */
export type Tournaments_Challenge_Rule_Variance_Order_By = {
  challenge_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_groups?: InputMaybe<Order_By>;
  nb_teams?: InputMaybe<Order_By>;
  nb_teams_in_final_phase?: InputMaybe<Order_By>;
  period_duration?: InputMaybe<Order_By>;
  period_nb?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule_type?: InputMaybe<Order_By>;
  time_between_periods?: InputMaybe<Order_By>;
};

/** select columns of table "tournaments.challenge" */
export enum Tournaments_Challenge_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AgeCategory = 'age_category',
  /** column name */
  AgeLimit = 'age_limit',
  /** column name */
  Color = 'color',
  /** column name */
  Gender = 'gender',
  /** column name */
  HitCount = 'hit_count',
  /** column name */
  Id = 'id',
  /** column name */
  MaxNbPlayers = 'max_nb_players',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
  /** column name */
  TournamentId = 'tournament_id',
}

/** input type for updating data in table "tournaments.challenge" */
export type Tournaments_Challenge_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  age_category?: InputMaybe<Scalars['String']['input']>;
  age_limit?: InputMaybe<Scalars['date']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  max_nb_players?: InputMaybe<Scalars['smallint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Stddev_Order_By = {
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Stddev_Pop_Order_By = {
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Stddev_Samp_Order_By = {
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_challenge" */
export type Tournaments_Challenge_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Challenge_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Challenge_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  age_category?: InputMaybe<Scalars['String']['input']>;
  age_limit?: InputMaybe<Scalars['date']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  hit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  max_nb_players?: InputMaybe<Scalars['smallint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Sum_Order_By = {
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** update columns of table "tournaments.challenge" */
export enum Tournaments_Challenge_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  AgeCategory = 'age_category',
  /** column name */
  AgeLimit = 'age_limit',
  /** column name */
  Color = 'color',
  /** column name */
  Gender = 'gender',
  /** column name */
  MaxNbPlayers = 'max_nb_players',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
  /** column name */
  TournamentId = 'tournament_id',
}

export type Tournaments_Challenge_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Challenge_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Challenge_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Challenge_Bool_Exp;
};

/** order by var_pop() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Var_Pop_Order_By = {
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Var_Samp_Order_By = {
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tournaments.challenge" */
export type Tournaments_Challenge_Variance_Order_By = {
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  max_nb_players?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournaments.engagement" */
export type Tournaments_Engagement = {
  __typename: 'tournaments_engagement';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  initial_rank?: Maybe<Scalars['smallint']['output']>;
  loser_of_game_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  phase?: Maybe<Tournaments_Phase>;
  phase_id?: Maybe<Scalars['Int']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['smallint']['output']>;
  previous_phase_id?: Maybe<Scalars['Int']['output']>;
  previous_phase_rank?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  ranking?: Maybe<Tournaments_Ranking>;
  /** An object relationship */
  team?: Maybe<Tournaments_Team>;
  team_id?: Maybe<Scalars['Int']['output']>;
  winner_of_game_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "tournaments.engagement" */
export type Tournaments_Engagement_Aggregate = {
  __typename: 'tournaments_engagement_aggregate';
  aggregate?: Maybe<Tournaments_Engagement_Aggregate_Fields>;
  nodes?: Maybe<Array<Tournaments_Engagement>>;
};

export type Tournaments_Engagement_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tournaments_Engagement_Aggregate_Bool_Exp_Count>;
};

export type Tournaments_Engagement_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tournaments.engagement" */
export type Tournaments_Engagement_Aggregate_Fields = {
  __typename: 'tournaments_engagement_aggregate_fields';
  avg?: Maybe<Tournaments_Engagement_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Tournaments_Engagement_Max_Fields>;
  min?: Maybe<Tournaments_Engagement_Min_Fields>;
  stddev?: Maybe<Tournaments_Engagement_Stddev_Fields>;
  stddev_pop?: Maybe<Tournaments_Engagement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tournaments_Engagement_Stddev_Samp_Fields>;
  sum?: Maybe<Tournaments_Engagement_Sum_Fields>;
  var_pop?: Maybe<Tournaments_Engagement_Var_Pop_Fields>;
  var_samp?: Maybe<Tournaments_Engagement_Var_Samp_Fields>;
  variance?: Maybe<Tournaments_Engagement_Variance_Fields>;
};

/** aggregate fields of "tournaments.engagement" */
export type Tournaments_Engagement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tournaments.engagement" */
export type Tournaments_Engagement_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Engagement_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Engagement_Max_Order_By>;
  min?: InputMaybe<Tournaments_Engagement_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Engagement_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Engagement_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Engagement_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Engagement_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Engagement_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Engagement_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Engagement_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tournaments_Engagement_Avg_Fields = {
  __typename: 'tournaments_engagement_avg_fields';
  id: Scalars['Float']['output'];
  initial_rank?: Maybe<Scalars['Float']['output']>;
  loser_of_game_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['Float']['output']>;
  previous_phase_id?: Maybe<Scalars['Float']['output']>;
  previous_phase_rank?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  winner_of_game_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.engagement". All fields are combined with a logical 'AND'. */
export type Tournaments_Engagement_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Engagement_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Engagement_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  initial_rank?: InputMaybe<Smallint_Comparison_Exp>;
  loser_of_game_id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phase?: InputMaybe<Tournaments_Phase_Bool_Exp>;
  phase_id?: InputMaybe<Int_Comparison_Exp>;
  previous_challenge_rule_id?: InputMaybe<Int_Comparison_Exp>;
  previous_challenge_rule_rank?: InputMaybe<Smallint_Comparison_Exp>;
  previous_phase_id?: InputMaybe<Int_Comparison_Exp>;
  previous_phase_rank?: InputMaybe<Smallint_Comparison_Exp>;
  ranking?: InputMaybe<Tournaments_Ranking_Bool_Exp>;
  team?: InputMaybe<Tournaments_Team_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  winner_of_game_id?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "tournaments.engagement" */
export type Tournaments_Engagement_Inc_Input = {
  loser_of_game_id?: InputMaybe<Scalars['Int']['input']>;
  manual_rank?: InputMaybe<Scalars['smallint']['input']>;
  previous_challenge_rule_id?: InputMaybe<Scalars['Int']['input']>;
  previous_challenge_rule_rank?: InputMaybe<Scalars['smallint']['input']>;
  previous_phase_id?: InputMaybe<Scalars['Int']['input']>;
  previous_phase_rank?: InputMaybe<Scalars['smallint']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  winner_of_game_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Tournaments_Engagement_Max_Fields = {
  __typename: 'tournaments_engagement_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  initial_rank?: Maybe<Scalars['smallint']['output']>;
  loser_of_game_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phase_id?: Maybe<Scalars['Int']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['smallint']['output']>;
  previous_phase_id?: Maybe<Scalars['Int']['output']>;
  previous_phase_rank?: Maybe<Scalars['smallint']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  winner_of_game_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tournaments_Engagement_Min_Fields = {
  __typename: 'tournaments_engagement_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  initial_rank?: Maybe<Scalars['smallint']['output']>;
  loser_of_game_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phase_id?: Maybe<Scalars['Int']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['smallint']['output']>;
  previous_phase_id?: Maybe<Scalars['Int']['output']>;
  previous_phase_rank?: Maybe<Scalars['smallint']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  winner_of_game_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.engagement" */
export type Tournaments_Engagement_Mutation_Response = {
  __typename: 'tournaments_engagement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Engagement>>;
};

/** Ordering options when selecting data from "tournaments.engagement". */
export type Tournaments_Engagement_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phase?: InputMaybe<Tournaments_Phase_Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  ranking?: InputMaybe<Tournaments_Ranking_Order_By>;
  team?: InputMaybe<Tournaments_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.engagement */
export type Tournaments_Engagement_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tournaments.engagement" */
export enum Tournaments_Engagement_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  InitialRank = 'initial_rank',
  /** column name */
  LoserOfGameId = 'loser_of_game_id',
  /** column name */
  Name = 'name',
  /** column name */
  PhaseId = 'phase_id',
  /** column name */
  PreviousChallengeRuleId = 'previous_challenge_rule_id',
  /** column name */
  PreviousChallengeRuleRank = 'previous_challenge_rule_rank',
  /** column name */
  PreviousPhaseId = 'previous_phase_id',
  /** column name */
  PreviousPhaseRank = 'previous_phase_rank',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  WinnerOfGameId = 'winner_of_game_id',
}

/** input type for updating data in table "tournaments.engagement" */
export type Tournaments_Engagement_Set_Input = {
  loser_of_game_id?: InputMaybe<Scalars['Int']['input']>;
  manual_rank?: InputMaybe<Scalars['smallint']['input']>;
  previous_challenge_rule_id?: InputMaybe<Scalars['Int']['input']>;
  previous_challenge_rule_rank?: InputMaybe<Scalars['smallint']['input']>;
  previous_phase_id?: InputMaybe<Scalars['Int']['input']>;
  previous_phase_rank?: InputMaybe<Scalars['smallint']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  winner_of_game_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Tournaments_Engagement_Stddev_Fields = {
  __typename: 'tournaments_engagement_stddev_fields';
  id: Scalars['Float']['output'];
  initial_rank?: Maybe<Scalars['Float']['output']>;
  loser_of_game_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['Float']['output']>;
  previous_phase_id?: Maybe<Scalars['Float']['output']>;
  previous_phase_rank?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  winner_of_game_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tournaments_Engagement_Stddev_Pop_Fields = {
  __typename: 'tournaments_engagement_stddev_pop_fields';
  id: Scalars['Float']['output'];
  initial_rank?: Maybe<Scalars['Float']['output']>;
  loser_of_game_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['Float']['output']>;
  previous_phase_id?: Maybe<Scalars['Float']['output']>;
  previous_phase_rank?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  winner_of_game_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tournaments_Engagement_Stddev_Samp_Fields = {
  __typename: 'tournaments_engagement_stddev_samp_fields';
  id: Scalars['Float']['output'];
  initial_rank?: Maybe<Scalars['Float']['output']>;
  loser_of_game_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['Float']['output']>;
  previous_phase_id?: Maybe<Scalars['Float']['output']>;
  previous_phase_rank?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  winner_of_game_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_engagement" */
export type Tournaments_Engagement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Engagement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Engagement_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  initial_rank?: InputMaybe<Scalars['smallint']['input']>;
  loser_of_game_id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phase_id?: InputMaybe<Scalars['Int']['input']>;
  previous_challenge_rule_id?: InputMaybe<Scalars['Int']['input']>;
  previous_challenge_rule_rank?: InputMaybe<Scalars['smallint']['input']>;
  previous_phase_id?: InputMaybe<Scalars['Int']['input']>;
  previous_phase_rank?: InputMaybe<Scalars['smallint']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  winner_of_game_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Tournaments_Engagement_Sum_Fields = {
  __typename: 'tournaments_engagement_sum_fields';
  id: Scalars['Int']['output'];
  initial_rank?: Maybe<Scalars['smallint']['output']>;
  loser_of_game_id?: Maybe<Scalars['Int']['output']>;
  phase_id?: Maybe<Scalars['Int']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['smallint']['output']>;
  previous_phase_id?: Maybe<Scalars['Int']['output']>;
  previous_phase_rank?: Maybe<Scalars['smallint']['output']>;
  team_id?: Maybe<Scalars['Int']['output']>;
  winner_of_game_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

export type Tournaments_Engagement_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Engagement_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Engagement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Engagement_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tournaments_Engagement_Var_Pop_Fields = {
  __typename: 'tournaments_engagement_var_pop_fields';
  id: Scalars['Float']['output'];
  initial_rank?: Maybe<Scalars['Float']['output']>;
  loser_of_game_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['Float']['output']>;
  previous_phase_id?: Maybe<Scalars['Float']['output']>;
  previous_phase_rank?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  winner_of_game_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tournaments_Engagement_Var_Samp_Fields = {
  __typename: 'tournaments_engagement_var_samp_fields';
  id: Scalars['Float']['output'];
  initial_rank?: Maybe<Scalars['Float']['output']>;
  loser_of_game_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['Float']['output']>;
  previous_phase_id?: Maybe<Scalars['Float']['output']>;
  previous_phase_rank?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  winner_of_game_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tournaments_Engagement_Variance_Fields = {
  __typename: 'tournaments_engagement_variance_fields';
  id: Scalars['Float']['output'];
  initial_rank?: Maybe<Scalars['Float']['output']>;
  loser_of_game_id?: Maybe<Scalars['Float']['output']>;
  phase_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  previous_challenge_rule_rank?: Maybe<Scalars['Float']['output']>;
  previous_phase_id?: Maybe<Scalars['Float']['output']>;
  previous_phase_rank?: Maybe<Scalars['Float']['output']>;
  team_id?: Maybe<Scalars['Float']['output']>;
  winner_of_game_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tournaments.engagement" */
export type Tournaments_Engagement_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  initial_rank?: InputMaybe<Order_By>;
  loser_of_game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
  previous_challenge_rule_id?: InputMaybe<Order_By>;
  previous_challenge_rule_rank?: InputMaybe<Order_By>;
  previous_phase_id?: InputMaybe<Order_By>;
  previous_phase_rank?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  winner_of_game_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournaments.game" */
export type Tournaments_Game = {
  __typename: 'tournaments_game';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  away_engagement?: Maybe<Tournaments_Engagement>;
  away_id?: Maybe<Scalars['Int']['output']>;
  away_score?: Maybe<Scalars['Int']['output']>;
  away_shootout?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  event?: Maybe<Competitions_Event>;
  event_id?: Maybe<Scalars['Int']['output']>;
  game_type?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  home_engagement?: Maybe<Tournaments_Engagement>;
  home_id?: Maybe<Scalars['Int']['output']>;
  home_score?: Maybe<Scalars['Int']['output']>;
  home_shootout?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  place?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  tournament_place?: Maybe<Tournaments_Place>;
  tournament_place_id?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** order by aggregate values of table "tournaments.game" */
export type Tournaments_Game_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Game_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Game_Max_Order_By>;
  min?: InputMaybe<Tournaments_Game_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Game_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Game_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Game_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Game_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Game_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Game_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Game_Variance_Order_By>;
};

/** order by avg() on columns of table "tournaments.game" */
export type Tournaments_Game_Avg_Order_By = {
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.game". All fields are combined with a logical 'AND'. */
export type Tournaments_Game_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Game_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Game_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Game_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  away_engagement?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
  away_id?: InputMaybe<Int_Comparison_Exp>;
  away_score?: InputMaybe<Int_Comparison_Exp>;
  away_shootout?: InputMaybe<Int_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  event?: InputMaybe<Competitions_Event_Bool_Exp>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  game_type?: InputMaybe<Smallint_Comparison_Exp>;
  home_engagement?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
  home_id?: InputMaybe<Int_Comparison_Exp>;
  home_score?: InputMaybe<Int_Comparison_Exp>;
  home_shootout?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  place?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tournament_place?: InputMaybe<Tournaments_Place_Bool_Exp>;
  tournament_place_id?: InputMaybe<Int_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "tournaments.game" */
export type Tournaments_Game_Inc_Input = {
  away_id?: InputMaybe<Scalars['Int']['input']>;
  away_score?: InputMaybe<Scalars['Int']['input']>;
  home_id?: InputMaybe<Scalars['Int']['input']>;
  home_score?: InputMaybe<Scalars['Int']['input']>;
  tournament_place_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "tournaments.game" */
export type Tournaments_Game_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  place?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tournaments.game" */
export type Tournaments_Game_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  place?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.game" */
export type Tournaments_Game_Mutation_Response = {
  __typename: 'tournaments_game_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Game>>;
};

/** Ordering options when selecting data from "tournaments.game". */
export type Tournaments_Game_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  away_engagement?: InputMaybe<Tournaments_Engagement_Order_By>;
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event?: InputMaybe<Competitions_Event_Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_engagement?: InputMaybe<Tournaments_Engagement_Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  place?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tournament_place?: InputMaybe<Tournaments_Place_Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.game */
export type Tournaments_Game_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tournaments.game" */
export enum Tournaments_Game_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AwayId = 'away_id',
  /** column name */
  AwayScore = 'away_score',
  /** column name */
  AwayShootout = 'away_shootout',
  /** column name */
  Date = 'date',
  /** column name */
  EventId = 'event_id',
  /** column name */
  GameType = 'game_type',
  /** column name */
  HomeId = 'home_id',
  /** column name */
  HomeScore = 'home_score',
  /** column name */
  HomeShootout = 'home_shootout',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  Place = 'place',
  /** column name */
  Rank = 'rank',
  /** column name */
  Status = 'status',
  /** column name */
  TournamentPlaceId = 'tournament_place_id',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "tournaments.game" */
export type Tournaments_Game_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  away_id?: InputMaybe<Scalars['Int']['input']>;
  away_score?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  home_id?: InputMaybe<Scalars['Int']['input']>;
  home_score?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tournament_place_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "tournaments.game" */
export type Tournaments_Game_Stddev_Order_By = {
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tournaments.game" */
export type Tournaments_Game_Stddev_Pop_Order_By = {
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tournaments.game" */
export type Tournaments_Game_Stddev_Samp_Order_By = {
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_game" */
export type Tournaments_Game_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Game_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Game_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  away_id?: InputMaybe<Scalars['Int']['input']>;
  away_score?: InputMaybe<Scalars['Int']['input']>;
  away_shootout?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  game_type?: InputMaybe<Scalars['smallint']['input']>;
  home_id?: InputMaybe<Scalars['Int']['input']>;
  home_score?: InputMaybe<Scalars['Int']['input']>;
  home_shootout?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tournament_place_id?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "tournaments.game" */
export type Tournaments_Game_Sum_Order_By = {
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
};

export type Tournaments_Game_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Game_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Game_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Game_Bool_Exp;
};

/** order by var_pop() on columns of table "tournaments.game" */
export type Tournaments_Game_Var_Pop_Order_By = {
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tournaments.game" */
export type Tournaments_Game_Var_Samp_Order_By = {
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tournaments.game" */
export type Tournaments_Game_Variance_Order_By = {
  away_id?: InputMaybe<Order_By>;
  away_score?: InputMaybe<Order_By>;
  away_shootout?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  game_type?: InputMaybe<Order_By>;
  home_id?: InputMaybe<Order_By>;
  home_score?: InputMaybe<Order_By>;
  home_shootout?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  tournament_place_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase = {
  __typename: 'tournaments_games_in_phase';
  /** An object relationship */
  game?: Maybe<Tournaments_Game>;
  game_id?: Maybe<Scalars['Int']['output']>;
  phase_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Games_In_Phase_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Games_In_Phase_Max_Order_By>;
  min?: InputMaybe<Tournaments_Games_In_Phase_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Games_In_Phase_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Games_In_Phase_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Games_In_Phase_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Games_In_Phase_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Games_In_Phase_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Games_In_Phase_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Games_In_Phase_Variance_Order_By>;
};

/** order by avg() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Avg_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.games_in_phase". All fields are combined with a logical 'AND'. */
export type Tournaments_Games_In_Phase_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Games_In_Phase_Bool_Exp>>;
  _not?: InputMaybe<Tournaments_Games_In_Phase_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Games_In_Phase_Bool_Exp>>;
  game?: InputMaybe<Tournaments_Game_Bool_Exp>;
  game_id?: InputMaybe<Int_Comparison_Exp>;
  phase_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Max_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Min_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "tournaments.games_in_phase". */
export type Tournaments_Games_In_Phase_Order_By = {
  game?: InputMaybe<Tournaments_Game_Order_By>;
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** select columns of table "tournaments.games_in_phase" */
export enum Tournaments_Games_In_Phase_Select_Column {
  /** column name */
  GameId = 'game_id',
  /** column name */
  PhaseId = 'phase_id',
}

/** order by stddev() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Stddev_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Stddev_Pop_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Stddev_Samp_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_games_in_phase" */
export type Tournaments_Games_In_Phase_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Games_In_Phase_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Games_In_Phase_Stream_Cursor_Value_Input = {
  game_id?: InputMaybe<Scalars['Int']['input']>;
  phase_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Sum_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Var_Pop_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Var_Samp_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tournaments.games_in_phase" */
export type Tournaments_Games_In_Phase_Variance_Order_By = {
  game_id?: InputMaybe<Order_By>;
  phase_id?: InputMaybe<Order_By>;
};

/** fields of action: "tournaments_new_hit" */
export type Tournaments_New_Hit = {
  __typename: 'tournaments_new_hit';
  /** the time at which this action was created */
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']['output']>;
  /** the unique id of an action */
  id: Scalars['uuid']['output'];
  /** the output fields of this action */
  output?: Maybe<Affected_Rows_Response>;
};

/** columns and relationships of "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2 = {
  __typename: 'tournaments_official_reporter_v2';
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tournament?: Maybe<Tournaments_Tournament>;
  tournament_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Official_Reporter_V2_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Official_Reporter_V2_Max_Order_By>;
  min?: InputMaybe<Tournaments_Official_Reporter_V2_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Official_Reporter_V2_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Official_Reporter_V2_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Official_Reporter_V2_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Official_Reporter_V2_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Official_Reporter_V2_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Official_Reporter_V2_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Official_Reporter_V2_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Arr_Rel_Insert_Input = {
  data: Array<Tournaments_Official_Reporter_V2_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Official_Reporter_V2_On_Conflict>;
};

/** order by avg() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.official_reporter_v2". All fields are combined with a logical 'AND'. */
export type Tournaments_Official_Reporter_V2_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Official_Reporter_V2_Bool_Exp>>;
  _not?: InputMaybe<Tournaments_Official_Reporter_V2_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Official_Reporter_V2_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  tournament?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
  tournament_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments.official_reporter_v2" */
export enum Tournaments_Official_Reporter_V2_Constraint {
  /** unique or primary key constraint on columns "id" */
  OfficialReporterV2Pkey = 'official_reporter_v2_pkey',
  /** unique or primary key constraint on columns "member_id", "tournament_id" */
  OfficialReporterV2TournamentIdMemberIdCe419522Uniq = 'official_reporter_v2_tournament_id_member_id_ce419522_uniq',
}

/** input type for inserting data into table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Insert_Input = {
  member?: InputMaybe<Members_Member_Obj_Rel_Insert_Input>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  tournament?: InputMaybe<Tournaments_Tournament_Obj_Rel_Insert_Input>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Mutation_Response = {
  __typename: 'tournaments_official_reporter_v2_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Official_Reporter_V2>>;
};

/** on_conflict condition type for table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_On_Conflict = {
  constraint: Tournaments_Official_Reporter_V2_Constraint;
  update_columns?: Array<Tournaments_Official_Reporter_V2_Update_Column>;
  where?: InputMaybe<Tournaments_Official_Reporter_V2_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments.official_reporter_v2". */
export type Tournaments_Official_Reporter_V2_Order_By = {
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament?: InputMaybe<Tournaments_Tournament_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** select columns of table "tournaments.official_reporter_v2" */
export enum Tournaments_Official_Reporter_V2_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  TournamentId = 'tournament_id',
}

/** order by stddev() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Official_Reporter_V2_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Official_Reporter_V2_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "tournaments.official_reporter_v2" (current role has no relevant permissions) */
export enum Tournaments_Official_Reporter_V2_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tournaments.official_reporter_v2" */
export type Tournaments_Official_Reporter_V2_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  member_id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournaments.phase" */
export type Tournaments_Phase = {
  __typename: 'tournaments_phase';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  display_type?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  engagements?: Maybe<Array<Tournaments_Engagement>>;
  /** An aggregate relationship */
  engagements_aggregate?: Maybe<Tournaments_Engagement_Aggregate>;
  /** An array relationship */
  games?: Maybe<Array<Tournaments_Games_In_Phase>>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  rule?: Maybe<Tournaments_Challenge_Rule>;
  status?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "tournaments.phase" */
export type Tournaments_PhaseEngagementsArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Engagement_Order_By>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

/** columns and relationships of "tournaments.phase" */
export type Tournaments_PhaseEngagements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Engagement_Order_By>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

/** columns and relationships of "tournaments.phase" */
export type Tournaments_PhaseGamesArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Games_In_Phase_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Games_In_Phase_Order_By>>;
  where?: InputMaybe<Tournaments_Games_In_Phase_Bool_Exp>;
};

/** aggregated selection of "tournaments.phase" */
export type Tournaments_Phase_Aggregate = {
  __typename: 'tournaments_phase_aggregate';
  aggregate?: Maybe<Tournaments_Phase_Aggregate_Fields>;
  nodes?: Maybe<Array<Tournaments_Phase>>;
};

export type Tournaments_Phase_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tournaments_Phase_Aggregate_Bool_Exp_Count>;
};

export type Tournaments_Phase_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tournaments_Phase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tournaments_Phase_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tournaments.phase" */
export type Tournaments_Phase_Aggregate_Fields = {
  __typename: 'tournaments_phase_aggregate_fields';
  avg?: Maybe<Tournaments_Phase_Avg_Fields>;
  count?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Tournaments_Phase_Max_Fields>;
  min?: Maybe<Tournaments_Phase_Min_Fields>;
  stddev?: Maybe<Tournaments_Phase_Stddev_Fields>;
  stddev_pop?: Maybe<Tournaments_Phase_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tournaments_Phase_Stddev_Samp_Fields>;
  sum?: Maybe<Tournaments_Phase_Sum_Fields>;
  var_pop?: Maybe<Tournaments_Phase_Var_Pop_Fields>;
  var_samp?: Maybe<Tournaments_Phase_Var_Samp_Fields>;
  variance?: Maybe<Tournaments_Phase_Variance_Fields>;
};

/** aggregate fields of "tournaments.phase" */
export type Tournaments_Phase_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tournaments_Phase_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tournaments.phase" */
export type Tournaments_Phase_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Phase_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Phase_Max_Order_By>;
  min?: InputMaybe<Tournaments_Phase_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Phase_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Phase_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Phase_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Phase_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Phase_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Phase_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Phase_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tournaments_Phase_Avg_Fields = {
  __typename: 'tournaments_phase_avg_fields';
  challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Avg_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.phase". All fields are combined with a logical 'AND'. */
export type Tournaments_Phase_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Phase_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Phase_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Phase_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  challenge_rule_id?: InputMaybe<Int_Comparison_Exp>;
  display_type?: InputMaybe<String_Comparison_Exp>;
  engagements?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
  engagements_aggregate?: InputMaybe<Tournaments_Engagement_Aggregate_Bool_Exp>;
  games?: InputMaybe<Tournaments_Games_In_Phase_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  rule?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "tournaments.phase" */
export type Tournaments_Phase_Inc_Input = {
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate max on columns */
export type Tournaments_Phase_Max_Fields = {
  __typename: 'tournaments_phase_max_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  display_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  challenge_rule_id?: InputMaybe<Order_By>;
  display_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tournaments_Phase_Min_Fields = {
  __typename: 'tournaments_phase_min_fields';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  display_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  challenge_rule_id?: InputMaybe<Order_By>;
  display_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.phase" */
export type Tournaments_Phase_Mutation_Response = {
  __typename: 'tournaments_phase_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Phase>>;
};

/** Ordering options when selecting data from "tournaments.phase". */
export type Tournaments_Phase_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  challenge_rule_id?: InputMaybe<Order_By>;
  display_type?: InputMaybe<Order_By>;
  engagements_aggregate?: InputMaybe<Tournaments_Engagement_Aggregate_Order_By>;
  games_aggregate?: InputMaybe<Tournaments_Games_In_Phase_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  rule?: InputMaybe<Tournaments_Challenge_Rule_Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.phase */
export type Tournaments_Phase_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tournaments.phase" */
export enum Tournaments_Phase_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ChallengeRuleId = 'challenge_rule_id',
  /** column name */
  DisplayType = 'display_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  Rank = 'rank',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "tournaments.phase" */
export type Tournaments_Phase_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate stddev on columns */
export type Tournaments_Phase_Stddev_Fields = {
  __typename: 'tournaments_phase_stddev_fields';
  challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Stddev_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tournaments_Phase_Stddev_Pop_Fields = {
  __typename: 'tournaments_phase_stddev_pop_fields';
  challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Stddev_Pop_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tournaments_Phase_Stddev_Samp_Fields = {
  __typename: 'tournaments_phase_stddev_samp_fields';
  challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Stddev_Samp_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_phase" */
export type Tournaments_Phase_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Phase_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Phase_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  challenge_rule_id?: InputMaybe<Scalars['Int']['input']>;
  display_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Tournaments_Phase_Sum_Fields = {
  __typename: 'tournaments_phase_sum_fields';
  challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  rank?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Sum_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

export type Tournaments_Phase_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Phase_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Phase_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Phase_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tournaments_Phase_Var_Pop_Fields = {
  __typename: 'tournaments_phase_var_pop_fields';
  challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Var_Pop_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tournaments_Phase_Var_Samp_Fields = {
  __typename: 'tournaments_phase_var_samp_fields';
  challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Var_Samp_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tournaments_Phase_Variance_Fields = {
  __typename: 'tournaments_phase_variance_fields';
  challenge_rule_id?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  rank?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tournaments.phase" */
export type Tournaments_Phase_Variance_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournaments.place" */
export type Tournaments_Place = {
  __typename: 'tournaments_place';
  /** An array relationship */
  games?: Maybe<Array<Tournaments_Game>>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  tournament?: Maybe<Tournaments_Tournament>;
  tournament_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "tournaments.place" */
export type Tournaments_PlaceGamesArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Game_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Game_Order_By>>;
  where?: InputMaybe<Tournaments_Game_Bool_Exp>;
};

/** order by aggregate values of table "tournaments.place" */
export type Tournaments_Place_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Place_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Place_Max_Order_By>;
  min?: InputMaybe<Tournaments_Place_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Place_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Place_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Place_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Place_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Place_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Place_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Place_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournaments.place" */
export type Tournaments_Place_Arr_Rel_Insert_Input = {
  data: Array<Tournaments_Place_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Place_On_Conflict>;
};

/** order by avg() on columns of table "tournaments.place" */
export type Tournaments_Place_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.place". All fields are combined with a logical 'AND'. */
export type Tournaments_Place_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Place_Bool_Exp>>;
  _not?: InputMaybe<Tournaments_Place_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Place_Bool_Exp>>;
  games?: InputMaybe<Tournaments_Game_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tournament?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
  tournament_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments.place" */
export enum Tournaments_Place_Constraint {
  /** unique or primary key constraint on columns "tournament_id", "name" */
  PlaceNameTournamentIdE5979e9cUniq = 'place_name_tournament_id_e5979e9c_uniq',
  /** unique or primary key constraint on columns "id" */
  PlacePkey = 'place_pkey',
}

/** input type for incrementing numeric columns in table "tournaments.place" */
export type Tournaments_Place_Inc_Input = {
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tournaments.place" */
export type Tournaments_Place_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  tournament?: InputMaybe<Tournaments_Tournament_Obj_Rel_Insert_Input>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "tournaments.place" */
export type Tournaments_Place_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tournaments.place" */
export type Tournaments_Place_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.place" */
export type Tournaments_Place_Mutation_Response = {
  __typename: 'tournaments_place_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Place>>;
};

/** on_conflict condition type for table "tournaments.place" */
export type Tournaments_Place_On_Conflict = {
  constraint: Tournaments_Place_Constraint;
  update_columns?: Array<Tournaments_Place_Update_Column>;
  where?: InputMaybe<Tournaments_Place_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments.place". */
export type Tournaments_Place_Order_By = {
  games_aggregate?: InputMaybe<Tournaments_Game_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tournament?: InputMaybe<Tournaments_Tournament_Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.place */
export type Tournaments_Place_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tournaments.place" */
export enum Tournaments_Place_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TournamentId = 'tournament_id',
}

/** input type for updating data in table "tournaments.place" */
export type Tournaments_Place_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "tournaments.place" */
export type Tournaments_Place_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tournaments.place" */
export type Tournaments_Place_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tournaments.place" */
export type Tournaments_Place_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_place" */
export type Tournaments_Place_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Place_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Place_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tournaments.place" */
export type Tournaments_Place_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** update columns of table "tournaments.place" */
export enum Tournaments_Place_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  TournamentId = 'tournament_id',
}

export type Tournaments_Place_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Place_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Place_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Place_Bool_Exp;
};

/** order by var_pop() on columns of table "tournaments.place" */
export type Tournaments_Place_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tournaments.place" */
export type Tournaments_Place_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tournaments.place" */
export type Tournaments_Place_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournaments.ranking" */
export type Tournaments_Ranking = {
  __typename: 'tournaments_ranking';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  engagement_id?: Maybe<Scalars['Int']['output']>;
  even?: Maybe<Scalars['smallint']['output']>;
  ga?: Maybe<Scalars['smallint']['output']>;
  gd?: Maybe<Scalars['smallint']['output']>;
  gf?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  lost?: Maybe<Scalars['smallint']['output']>;
  played?: Maybe<Scalars['smallint']['output']>;
  pts?: Maybe<Scalars['float8']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  win?: Maybe<Scalars['smallint']['output']>;
};

/** Boolean expression to filter rows from the table "tournaments.ranking". All fields are combined with a logical 'AND'. */
export type Tournaments_Ranking_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Ranking_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Ranking_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Ranking_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  engagement_id?: InputMaybe<Int_Comparison_Exp>;
  even?: InputMaybe<Smallint_Comparison_Exp>;
  ga?: InputMaybe<Smallint_Comparison_Exp>;
  gd?: InputMaybe<Smallint_Comparison_Exp>;
  gf?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lost?: InputMaybe<Smallint_Comparison_Exp>;
  played?: InputMaybe<Smallint_Comparison_Exp>;
  pts?: InputMaybe<Float8_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  win?: InputMaybe<Smallint_Comparison_Exp>;
};

/** Ordering options when selecting data from "tournaments.ranking". */
export type Tournaments_Ranking_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  engagement_id?: InputMaybe<Order_By>;
  even?: InputMaybe<Order_By>;
  ga?: InputMaybe<Order_By>;
  gd?: InputMaybe<Order_By>;
  gf?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lost?: InputMaybe<Order_By>;
  played?: InputMaybe<Order_By>;
  pts?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  win?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournaments.ranking_points" */
export type Tournaments_Ranking_Points = {
  __typename: 'tournaments_ranking_points';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  challenge_rule?: Maybe<Tournaments_Challenge_Rule>;
  challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  even?: Maybe<Scalars['smallint']['output']>;
  id: Scalars['Int']['output'];
  lost?: Maybe<Scalars['smallint']['output']>;
  win?: Maybe<Scalars['smallint']['output']>;
};

/** Boolean expression to filter rows from the table "tournaments.ranking_points". All fields are combined with a logical 'AND'. */
export type Tournaments_Ranking_Points_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Ranking_Points_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Ranking_Points_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Ranking_Points_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  challenge_rule?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
  challenge_rule_id?: InputMaybe<Int_Comparison_Exp>;
  even?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lost?: InputMaybe<Smallint_Comparison_Exp>;
  win?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments.ranking_points" */
export enum Tournaments_Ranking_Points_Constraint {
  /** unique or primary key constraint on columns "challenge_rule_id" */
  RankingPointsChallengeRuleIdKey = 'ranking_points_challenge_rule_id_key',
  /** unique or primary key constraint on columns "id" */
  RankingPointsPkey = 'ranking_points_pkey',
}

/** input type for incrementing numeric columns in table "tournaments.ranking_points" */
export type Tournaments_Ranking_Points_Inc_Input = {
  even?: InputMaybe<Scalars['smallint']['input']>;
  lost?: InputMaybe<Scalars['smallint']['input']>;
  win?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "tournaments.ranking_points" */
export type Tournaments_Ranking_Points_Insert_Input = {
  challenge_rule?: InputMaybe<Tournaments_Challenge_Rule_Obj_Rel_Insert_Input>;
  challenge_rule_id?: InputMaybe<Scalars['Int']['input']>;
  even?: InputMaybe<Scalars['smallint']['input']>;
  lost?: InputMaybe<Scalars['smallint']['input']>;
  win?: InputMaybe<Scalars['smallint']['input']>;
};

/** response of any mutation on the table "tournaments.ranking_points" */
export type Tournaments_Ranking_Points_Mutation_Response = {
  __typename: 'tournaments_ranking_points_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Ranking_Points>>;
};

/** input type for inserting object relation for remote table "tournaments.ranking_points" */
export type Tournaments_Ranking_Points_Obj_Rel_Insert_Input = {
  data: Tournaments_Ranking_Points_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Ranking_Points_On_Conflict>;
};

/** on_conflict condition type for table "tournaments.ranking_points" */
export type Tournaments_Ranking_Points_On_Conflict = {
  constraint: Tournaments_Ranking_Points_Constraint;
  update_columns?: Array<Tournaments_Ranking_Points_Update_Column>;
  where?: InputMaybe<Tournaments_Ranking_Points_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments.ranking_points". */
export type Tournaments_Ranking_Points_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  challenge_rule?: InputMaybe<Tournaments_Challenge_Rule_Order_By>;
  challenge_rule_id?: InputMaybe<Order_By>;
  even?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lost?: InputMaybe<Order_By>;
  win?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.ranking_points */
export type Tournaments_Ranking_Points_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tournaments.ranking_points" */
export enum Tournaments_Ranking_Points_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ChallengeRuleId = 'challenge_rule_id',
  /** column name */
  Even = 'even',
  /** column name */
  Id = 'id',
  /** column name */
  Lost = 'lost',
  /** column name */
  Win = 'win',
}

/** input type for updating data in table "tournaments.ranking_points" */
export type Tournaments_Ranking_Points_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  even?: InputMaybe<Scalars['smallint']['input']>;
  lost?: InputMaybe<Scalars['smallint']['input']>;
  win?: InputMaybe<Scalars['smallint']['input']>;
};

/** Streaming cursor of the table "tournaments_ranking_points" */
export type Tournaments_Ranking_Points_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Ranking_Points_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Ranking_Points_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  challenge_rule_id?: InputMaybe<Scalars['Int']['input']>;
  even?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lost?: InputMaybe<Scalars['smallint']['input']>;
  win?: InputMaybe<Scalars['smallint']['input']>;
};

/** update columns of table "tournaments.ranking_points" */
export enum Tournaments_Ranking_Points_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  Even = 'even',
  /** column name */
  Lost = 'lost',
  /** column name */
  Win = 'win',
}

export type Tournaments_Ranking_Points_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Ranking_Points_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Ranking_Points_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Ranking_Points_Bool_Exp;
};

/** columns and relationships of "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule = {
  __typename: 'tournaments_ranking_rule';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  challenge_rule?: Maybe<Tournaments_Challenge_Rule>;
  challenge_rule_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  position?: Maybe<Scalars['smallint']['output']>;
  rule?: Maybe<Scalars['smallint']['output']>;
};

/** order by aggregate values of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Ranking_Rule_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Ranking_Rule_Max_Order_By>;
  min?: InputMaybe<Tournaments_Ranking_Rule_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Ranking_Rule_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Ranking_Rule_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Ranking_Rule_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Ranking_Rule_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Ranking_Rule_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Ranking_Rule_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Ranking_Rule_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Arr_Rel_Insert_Input = {
  data: Array<Tournaments_Ranking_Rule_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Ranking_Rule_On_Conflict>;
};

/** order by avg() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Avg_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.ranking_rule". All fields are combined with a logical 'AND'. */
export type Tournaments_Ranking_Rule_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Ranking_Rule_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Ranking_Rule_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Ranking_Rule_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  challenge_rule?: InputMaybe<Tournaments_Challenge_Rule_Bool_Exp>;
  challenge_rule_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  position?: InputMaybe<Smallint_Comparison_Exp>;
  rule?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments.ranking_rule" */
export enum Tournaments_Ranking_Rule_Constraint {
  /** unique or primary key constraint on columns "id" */
  RankingRulePkey = 'ranking_rule_pkey',
}

/** input type for incrementing numeric columns in table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Inc_Input = {
  position?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Insert_Input = {
  challenge_rule?: InputMaybe<Tournaments_Challenge_Rule_Obj_Rel_Insert_Input>;
  challenge_rule_id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['smallint']['input']>;
  rule?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by max() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Mutation_Response = {
  __typename: 'tournaments_ranking_rule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Ranking_Rule>>;
};

/** on_conflict condition type for table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_On_Conflict = {
  constraint: Tournaments_Ranking_Rule_Constraint;
  update_columns?: Array<Tournaments_Ranking_Rule_Update_Column>;
  where?: InputMaybe<Tournaments_Ranking_Rule_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments.ranking_rule". */
export type Tournaments_Ranking_Rule_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  challenge_rule?: InputMaybe<Tournaments_Challenge_Rule_Order_By>;
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.ranking_rule */
export type Tournaments_Ranking_Rule_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tournaments.ranking_rule" */
export enum Tournaments_Ranking_Rule_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ChallengeRuleId = 'challenge_rule_id',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  Rule = 'rule',
}

/** input type for updating data in table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  position?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by stddev() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Stddev_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Stddev_Pop_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Stddev_Samp_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_ranking_rule" */
export type Tournaments_Ranking_Rule_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Ranking_Rule_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Ranking_Rule_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  challenge_rule_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<Scalars['smallint']['input']>;
  rule?: InputMaybe<Scalars['smallint']['input']>;
};

/** order by sum() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Sum_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** update columns of table "tournaments.ranking_rule" */
export enum Tournaments_Ranking_Rule_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  Position = 'position',
}

export type Tournaments_Ranking_Rule_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Ranking_Rule_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Ranking_Rule_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Ranking_Rule_Bool_Exp;
};

/** order by var_pop() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Var_Pop_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Var_Samp_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tournaments.ranking_rule" */
export type Tournaments_Ranking_Rule_Variance_Order_By = {
  challenge_rule_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  rule?: InputMaybe<Order_By>;
};

/** select columns of table "tournaments.ranking" */
export enum Tournaments_Ranking_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  EngagementId = 'engagement_id',
  /** column name */
  Even = 'even',
  /** column name */
  Ga = 'ga',
  /** column name */
  Gd = 'gd',
  /** column name */
  Gf = 'gf',
  /** column name */
  Id = 'id',
  /** column name */
  Lost = 'lost',
  /** column name */
  Played = 'played',
  /** column name */
  Pts = 'pts',
  /** column name */
  Rank = 'rank',
  /** column name */
  Win = 'win',
}

/** Streaming cursor of the table "tournaments_ranking" */
export type Tournaments_Ranking_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Ranking_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Ranking_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  engagement_id?: InputMaybe<Scalars['Int']['input']>;
  even?: InputMaybe<Scalars['smallint']['input']>;
  ga?: InputMaybe<Scalars['smallint']['input']>;
  gd?: InputMaybe<Scalars['smallint']['input']>;
  gf?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lost?: InputMaybe<Scalars['smallint']['input']>;
  played?: InputMaybe<Scalars['smallint']['input']>;
  pts?: InputMaybe<Scalars['float8']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  win?: InputMaybe<Scalars['smallint']['input']>;
};

/** columns and relationships of "tournaments.team" */
export type Tournaments_Team = {
  __typename: 'tournaments_team';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  age_average?: Maybe<Scalars['smallint']['output']>;
  attack_points?: Maybe<Scalars['float8']['output']>;
  attack_rank?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  challenge?: Maybe<Tournaments_Challenge>;
  challenge_id?: Maybe<Scalars['Int']['output']>;
  color_1?: Maybe<Scalars['String']['output']>;
  color_2?: Maybe<Scalars['String']['output']>;
  defence_points?: Maybe<Scalars['float8']['output']>;
  defence_rank?: Maybe<Scalars['smallint']['output']>;
  /** An array relationship */
  engagements?: Maybe<Array<Tournaments_Engagement>>;
  /** An aggregate relationship */
  engagements_aggregate?: Maybe<Tournaments_Engagement_Aggregate>;
  id: Scalars['Int']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nb_games?: Maybe<Scalars['smallint']['output']>;
  old_scorenco_id?: Maybe<Scalars['uuid']['output']>;
  rank?: Maybe<Scalars['smallint']['output']>;
  scorenco_team_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "tournaments.team" */
export type Tournaments_TeamEngagementsArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Engagement_Order_By>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

/** columns and relationships of "tournaments.team" */
export type Tournaments_TeamEngagements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Engagement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Engagement_Order_By>>;
  where?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
};

/** order by aggregate values of table "tournaments.team" */
export type Tournaments_Team_Aggregate_Order_By = {
  avg?: InputMaybe<Tournaments_Team_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tournaments_Team_Max_Order_By>;
  min?: InputMaybe<Tournaments_Team_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Team_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Team_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Team_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Team_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Team_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Team_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Team_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournaments.team" */
export type Tournaments_Team_Arr_Rel_Insert_Input = {
  data: Array<Tournaments_Team_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Team_On_Conflict>;
};

/** order by avg() on columns of table "tournaments.team" */
export type Tournaments_Team_Avg_Order_By = {
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tournaments.team". All fields are combined with a logical 'AND'. */
export type Tournaments_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Team_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Team_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  age_average?: InputMaybe<Smallint_Comparison_Exp>;
  attack_points?: InputMaybe<Float8_Comparison_Exp>;
  attack_rank?: InputMaybe<Smallint_Comparison_Exp>;
  challenge?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
  challenge_id?: InputMaybe<Int_Comparison_Exp>;
  color_1?: InputMaybe<String_Comparison_Exp>;
  color_2?: InputMaybe<String_Comparison_Exp>;
  defence_points?: InputMaybe<Float8_Comparison_Exp>;
  defence_rank?: InputMaybe<Smallint_Comparison_Exp>;
  engagements?: InputMaybe<Tournaments_Engagement_Bool_Exp>;
  engagements_aggregate?: InputMaybe<Tournaments_Engagement_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nb_games?: InputMaybe<Smallint_Comparison_Exp>;
  old_scorenco_id?: InputMaybe<Uuid_Comparison_Exp>;
  rank?: InputMaybe<Smallint_Comparison_Exp>;
  scorenco_team_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments.team" */
export enum Tournaments_Team_Constraint {
  /** unique or primary key constraint on columns "challenge_id", "name" */
  TeamChallengeIdName_494721efUniq = 'team_challenge_id_name_494721ef_uniq',
  /** unique or primary key constraint on columns "id" */
  TeamPkey = 'team_pkey',
}

/** input type for incrementing numeric columns in table "tournaments.team" */
export type Tournaments_Team_Inc_Input = {
  age_average?: InputMaybe<Scalars['smallint']['input']>;
  scorenco_team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tournaments.team" */
export type Tournaments_Team_Insert_Input = {
  age_average?: InputMaybe<Scalars['smallint']['input']>;
  challenge?: InputMaybe<Tournaments_Challenge_Obj_Rel_Insert_Input>;
  challenge_id?: InputMaybe<Scalars['Int']['input']>;
  color_1?: InputMaybe<Scalars['String']['input']>;
  color_2?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  scorenco_team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "tournaments.team" */
export type Tournaments_Team_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  color_1?: InputMaybe<Order_By>;
  color_2?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tournaments.team" */
export type Tournaments_Team_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  color_1?: InputMaybe<Order_By>;
  color_2?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tournaments.team" */
export type Tournaments_Team_Mutation_Response = {
  __typename: 'tournaments_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Team>>;
};

/** on_conflict condition type for table "tournaments.team" */
export type Tournaments_Team_On_Conflict = {
  constraint: Tournaments_Team_Constraint;
  update_columns?: Array<Tournaments_Team_Update_Column>;
  where?: InputMaybe<Tournaments_Team_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments.team". */
export type Tournaments_Team_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge?: InputMaybe<Tournaments_Challenge_Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  color_1?: InputMaybe<Order_By>;
  color_2?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  engagements_aggregate?: InputMaybe<Tournaments_Engagement_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  old_scorenco_id?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.team */
export type Tournaments_Team_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "tournaments.team" */
export enum Tournaments_Team_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  AgeAverage = 'age_average',
  /** column name */
  AttackPoints = 'attack_points',
  /** column name */
  AttackRank = 'attack_rank',
  /** column name */
  ChallengeId = 'challenge_id',
  /** column name */
  Color_1 = 'color_1',
  /** column name */
  Color_2 = 'color_2',
  /** column name */
  DefencePoints = 'defence_points',
  /** column name */
  DefenceRank = 'defence_rank',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  NbGames = 'nb_games',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  Rank = 'rank',
  /** column name */
  ScorencoTeamId = 'scorenco_team_id',
}

/** input type for updating data in table "tournaments.team" */
export type Tournaments_Team_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  age_average?: InputMaybe<Scalars['smallint']['input']>;
  color_1?: InputMaybe<Scalars['String']['input']>;
  color_2?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  scorenco_team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "tournaments.team" */
export type Tournaments_Team_Stddev_Order_By = {
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "tournaments.team" */
export type Tournaments_Team_Stddev_Pop_Order_By = {
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "tournaments.team" */
export type Tournaments_Team_Stddev_Samp_Order_By = {
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tournaments_team" */
export type Tournaments_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Team_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  age_average?: InputMaybe<Scalars['smallint']['input']>;
  attack_points?: InputMaybe<Scalars['float8']['input']>;
  attack_rank?: InputMaybe<Scalars['smallint']['input']>;
  challenge_id?: InputMaybe<Scalars['Int']['input']>;
  color_1?: InputMaybe<Scalars['String']['input']>;
  color_2?: InputMaybe<Scalars['String']['input']>;
  defence_points?: InputMaybe<Scalars['float8']['input']>;
  defence_rank?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nb_games?: InputMaybe<Scalars['smallint']['input']>;
  old_scorenco_id?: InputMaybe<Scalars['uuid']['input']>;
  rank?: InputMaybe<Scalars['smallint']['input']>;
  scorenco_team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "tournaments.team" */
export type Tournaments_Team_Sum_Order_By = {
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** update columns of table "tournaments.team" */
export enum Tournaments_Team_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  AgeAverage = 'age_average',
  /** column name */
  Color_1 = 'color_1',
  /** column name */
  Color_2 = 'color_2',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  OldScorencoId = 'old_scorenco_id',
  /** column name */
  ScorencoTeamId = 'scorenco_team_id',
}

export type Tournaments_Team_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Team_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Team_Bool_Exp;
};

/** order by var_pop() on columns of table "tournaments.team" */
export type Tournaments_Team_Var_Pop_Order_By = {
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "tournaments.team" */
export type Tournaments_Team_Var_Samp_Order_By = {
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "tournaments.team" */
export type Tournaments_Team_Variance_Order_By = {
  age_average?: InputMaybe<Order_By>;
  attack_points?: InputMaybe<Order_By>;
  attack_rank?: InputMaybe<Order_By>;
  challenge_id?: InputMaybe<Order_By>;
  defence_points?: InputMaybe<Order_By>;
  defence_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_games?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  scorenco_team_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tournaments.tournament" */
export type Tournaments_Tournament = {
  __typename: 'tournaments_tournament';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  ad?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  challenges?: Maybe<Array<Tournaments_Challenge>>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  /** An array relationship */
  club_admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  end_date?: Maybe<Scalars['date']['output']>;
  hit_count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  official_reporter_v2?: Maybe<Array<Tournaments_Official_Reporter_V2>>;
  /** An array relationship */
  places?: Maybe<Array<Tournaments_Place>>;
  published?: Maybe<Scalars['Boolean']['output']>;
  show_attack_defence?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  sport?: Maybe<Clubs_Sport>;
  sport_id?: Maybe<Scalars['Int']['output']>;
  start_date?: Maybe<Scalars['date']['output']>;
};

/** columns and relationships of "tournaments.tournament" */
export type Tournaments_TournamentChallengesArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Challenge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Challenge_Order_By>>;
  where?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
};

/** columns and relationships of "tournaments.tournament" */
export type Tournaments_TournamentClub_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "tournaments.tournament" */
export type Tournaments_TournamentOfficial_Reporter_V2Args = {
  distinct_on?: InputMaybe<
    Array<Tournaments_Official_Reporter_V2_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Official_Reporter_V2_Order_By>>;
  where?: InputMaybe<Tournaments_Official_Reporter_V2_Bool_Exp>;
};

/** columns and relationships of "tournaments.tournament" */
export type Tournaments_TournamentPlacesArgs = {
  distinct_on?: InputMaybe<Array<Tournaments_Place_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tournaments_Place_Order_By>>;
  where?: InputMaybe<Tournaments_Place_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "tournaments.tournament". All fields are combined with a logical 'AND'. */
export type Tournaments_Tournament_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Tournament_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Tournament_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  ad?: InputMaybe<String_Comparison_Exp>;
  challenges?: InputMaybe<Tournaments_Challenge_Bool_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  hit_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  official_reporter_v2?: InputMaybe<Tournaments_Official_Reporter_V2_Bool_Exp>;
  places?: InputMaybe<Tournaments_Place_Bool_Exp>;
  published?: InputMaybe<Boolean_Comparison_Exp>;
  show_attack_defence?: InputMaybe<Boolean_Comparison_Exp>;
  sport?: InputMaybe<Clubs_Sport_Bool_Exp>;
  sport_id?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "tournaments.tournament" */
export enum Tournaments_Tournament_Constraint {
  /** unique or primary key constraint on columns "id" */
  TournamentPkey = 'tournament_pkey',
}

/** input type for incrementing numeric columns in table "tournaments.tournament" */
export type Tournaments_Tournament_Inc_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tournaments.tournament" */
export type Tournaments_Tournament_Insert_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  ad?: InputMaybe<Scalars['String']['input']>;
  challenges?: InputMaybe<Tournaments_Challenge_Arr_Rel_Insert_Input>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  official_reporter_v2?: InputMaybe<Tournaments_Official_Reporter_V2_Arr_Rel_Insert_Input>;
  places?: InputMaybe<Tournaments_Place_Arr_Rel_Insert_Input>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  show_attack_defence?: InputMaybe<Scalars['Boolean']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "tournaments.tournament" */
export type Tournaments_Tournament_Mutation_Response = {
  __typename: 'tournaments_tournament_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Tournaments_Tournament>>;
};

/** input type for inserting object relation for remote table "tournaments.tournament" */
export type Tournaments_Tournament_Obj_Rel_Insert_Input = {
  data: Tournaments_Tournament_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tournaments_Tournament_On_Conflict>;
};

/** on_conflict condition type for table "tournaments.tournament" */
export type Tournaments_Tournament_On_Conflict = {
  constraint: Tournaments_Tournament_Constraint;
  update_columns?: Array<Tournaments_Tournament_Update_Column>;
  where?: InputMaybe<Tournaments_Tournament_Bool_Exp>;
};

/** Ordering options when selecting data from "tournaments.tournament". */
export type Tournaments_Tournament_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  ad?: InputMaybe<Order_By>;
  challenges_aggregate?: InputMaybe<Tournaments_Challenge_Aggregate_Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  hit_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  official_reporter_v2_aggregate?: InputMaybe<Tournaments_Official_Reporter_V2_Aggregate_Order_By>;
  places_aggregate?: InputMaybe<Tournaments_Place_Aggregate_Order_By>;
  published?: InputMaybe<Order_By>;
  show_attack_defence?: InputMaybe<Order_By>;
  sport?: InputMaybe<Clubs_Sport_Order_By>;
  sport_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tournaments.tournament */
export type Tournaments_Tournament_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "tournaments.tournament_place" */
export type Tournaments_Tournament_Place = {
  __typename: 'tournaments_tournament_place';
  place?: Maybe<Scalars['String']['output']>;
  tournament_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "tournaments.tournament_place". All fields are combined with a logical 'AND'. */
export type Tournaments_Tournament_Place_Bool_Exp = {
  _and?: InputMaybe<Array<Tournaments_Tournament_Place_Bool_Exp>>;
  _not?: InputMaybe<Tournaments_Tournament_Place_Bool_Exp>;
  _or?: InputMaybe<Array<Tournaments_Tournament_Place_Bool_Exp>>;
  place?: InputMaybe<String_Comparison_Exp>;
  tournament_id?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "tournaments.tournament_place". */
export type Tournaments_Tournament_Place_Order_By = {
  place?: InputMaybe<Order_By>;
  tournament_id?: InputMaybe<Order_By>;
};

/** select columns of table "tournaments.tournament_place" */
export enum Tournaments_Tournament_Place_Select_Column {
  /** column name */
  Place = 'place',
  /** column name */
  TournamentId = 'tournament_id',
}

/** Streaming cursor of the table "tournaments_tournament_place" */
export type Tournaments_Tournament_Place_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Tournament_Place_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Tournament_Place_Stream_Cursor_Value_Input = {
  place?: InputMaybe<Scalars['String']['input']>;
  tournament_id?: InputMaybe<Scalars['Int']['input']>;
};

/** select columns of table "tournaments.tournament" */
export enum Tournaments_Tournament_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Ad = 'ad',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  HitCount = 'hit_count',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  Published = 'published',
  /** column name */
  ShowAttackDefence = 'show_attack_defence',
  /** column name */
  SportId = 'sport_id',
  /** column name */
  StartDate = 'start_date',
}

/** input type for updating data in table "tournaments.tournament" */
export type Tournaments_Tournament_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  ad?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  show_attack_defence?: InputMaybe<Scalars['Boolean']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
};

/** Streaming cursor of the table "tournaments_tournament" */
export type Tournaments_Tournament_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tournaments_Tournament_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tournaments_Tournament_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  ad?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  end_date?: InputMaybe<Scalars['date']['input']>;
  hit_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  show_attack_defence?: InputMaybe<Scalars['Boolean']['input']>;
  sport_id?: InputMaybe<Scalars['Int']['input']>;
  start_date?: InputMaybe<Scalars['date']['input']>;
};

/** update columns of table "tournaments.tournament" */
export enum Tournaments_Tournament_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  Ad = 'ad',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Location = 'location',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  Published = 'published',
  /** column name */
  ShowAttackDefence = 'show_attack_defence',
  /** column name */
  StartDate = 'start_date',
}

export type Tournaments_Tournament_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tournaments_Tournament_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tournaments_Tournament_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tournaments_Tournament_Bool_Exp;
};

export type Urls_Club_By_Direct_Score_Url_Args = {
  object_url?: InputMaybe<Scalars['String']['input']>;
};

export type Urls_Event_By_Direct_Score_Same_Teams_Url_Args = {
  object_url?: InputMaybe<Scalars['String']['input']>;
};

export type Urls_Event_By_Direct_Score_Url_Args = {
  object_url?: InputMaybe<Scalars['String']['input']>;
};

export type Urls_Team_In_Season_By_Direct_Score_Url_Args = {
  object_url?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "urls.url_detail" */
export type Urls_Url_Detail = {
  __typename: 'urls_url_detail';
  id: Scalars['Int']['output'];
  redirect_url?: Maybe<Scalars['String']['output']>;
};

/** Boolean expression to filter rows from the table "urls.url_detail". All fields are combined with a logical 'AND'. */
export type Urls_Url_Detail_Bool_Exp = {
  _and?: InputMaybe<Array<Urls_Url_Detail_Bool_Exp>>;
  _not?: InputMaybe<Urls_Url_Detail_Bool_Exp>;
  _or?: InputMaybe<Array<Urls_Url_Detail_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  redirect_url?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "urls.url_detail". */
export type Urls_Url_Detail_Order_By = {
  id?: InputMaybe<Order_By>;
  redirect_url?: InputMaybe<Order_By>;
};

/** select columns of table "urls.url_detail" */
export enum Urls_Url_Detail_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RedirectUrl = 'redirect_url',
}

/** Streaming cursor of the table "urls_url_detail" */
export type Urls_Url_Detail_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Urls_Url_Detail_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Urls_Url_Detail_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  redirect_url?: InputMaybe<Scalars['String']['input']>;
};

export type Users_Check_Invitation_Response = {
  __typename: 'users_check_invitation_response';
  affected_rows?: Maybe<Scalars['smallint']['output']>;
};

/** columns and relationships of "users.convocation_progress" */
export type Users_Convocation_Progress = {
  __typename: 'users_convocation_progress';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  club_has_send_sms?: Maybe<Scalars['Boolean']['output']>;
  club_has_sms_credits?: Maybe<Scalars['Boolean']['output']>;
  club_id?: Maybe<Scalars['Int']['output']>;
  has_admin?: Maybe<Scalars['Boolean']['output']>;
  has_member?: Maybe<Scalars['Boolean']['output']>;
  has_role?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  user?: Maybe<Users_User>;
  user_id?: Maybe<Scalars['Int']['output']>;
  validated_event?: Maybe<Scalars['Boolean']['output']>;
};

/** order by aggregate values of table "users.convocation_progress" */
export type Users_Convocation_Progress_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Convocation_Progress_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Convocation_Progress_Max_Order_By>;
  min?: InputMaybe<Users_Convocation_Progress_Min_Order_By>;
  stddev?: InputMaybe<Users_Convocation_Progress_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Convocation_Progress_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Convocation_Progress_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Convocation_Progress_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Convocation_Progress_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Convocation_Progress_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Convocation_Progress_Variance_Order_By>;
};

/** order by avg() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Avg_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users.convocation_progress". All fields are combined with a logical 'AND'. */
export type Users_Convocation_Progress_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Convocation_Progress_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Users_Convocation_Progress_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Convocation_Progress_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  club_has_send_sms?: InputMaybe<Boolean_Comparison_Exp>;
  club_has_sms_credits?: InputMaybe<Boolean_Comparison_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  has_admin?: InputMaybe<Boolean_Comparison_Exp>;
  has_member?: InputMaybe<Boolean_Comparison_Exp>;
  has_role?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
  validated_event?: InputMaybe<Boolean_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "users.convocation_progress" */
export type Users_Convocation_Progress_Inc_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users.convocation_progress" */
export type Users_Convocation_Progress_Mutation_Response = {
  __typename: 'users_convocation_progress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Users_Convocation_Progress>>;
};

/** Ordering options when selecting data from "users.convocation_progress". */
export type Users_Convocation_Progress_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  club_has_send_sms?: InputMaybe<Order_By>;
  club_has_sms_credits?: InputMaybe<Order_By>;
  club_id?: InputMaybe<Order_By>;
  has_admin?: InputMaybe<Order_By>;
  has_member?: InputMaybe<Order_By>;
  has_role?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  validated_event?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users.convocation_progress */
export type Users_Convocation_Progress_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "users.convocation_progress" */
export enum Users_Convocation_Progress_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ClubHasSendSms = 'club_has_send_sms',
  /** column name */
  ClubHasSmsCredits = 'club_has_sms_credits',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  HasAdmin = 'has_admin',
  /** column name */
  HasMember = 'has_member',
  /** column name */
  HasRole = 'has_role',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidatedEvent = 'validated_event',
}

/** input type for updating data in table "users.convocation_progress" */
export type Users_Convocation_Progress_Set_Input = {
  club_has_send_sms?: InputMaybe<Scalars['Boolean']['input']>;
  club_has_sms_credits?: InputMaybe<Scalars['Boolean']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  has_admin?: InputMaybe<Scalars['Boolean']['input']>;
  has_member?: InputMaybe<Scalars['Boolean']['input']>;
  has_role?: InputMaybe<Scalars['Boolean']['input']>;
  validated_event?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by stddev() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Stddev_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Stddev_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Stddev_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_convocation_progress" */
export type Users_Convocation_Progress_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Convocation_Progress_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Convocation_Progress_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  club_has_send_sms?: InputMaybe<Scalars['Boolean']['input']>;
  club_has_sms_credits?: InputMaybe<Scalars['Boolean']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  has_admin?: InputMaybe<Scalars['Boolean']['input']>;
  has_member?: InputMaybe<Scalars['Boolean']['input']>;
  has_role?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  validated_event?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by sum() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Sum_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

export type Users_Convocation_Progress_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Convocation_Progress_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Convocation_Progress_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Convocation_Progress_Bool_Exp;
};

/** order by var_pop() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Var_Pop_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Var_Samp_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "users.convocation_progress" */
export type Users_Convocation_Progress_Variance_Order_By = {
  club_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users.member_invitation" */
export type Users_Member_Invitation = {
  __typename: 'users_member_invitation';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  member?: Maybe<Members_Member>;
  member_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user?: Maybe<Users_User>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "users.member_invitation". All fields are combined with a logical 'AND'. */
export type Users_Member_Invitation_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Member_Invitation_Bool_Exp>>;
  _not?: InputMaybe<Users_Member_Invitation_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Member_Invitation_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  member?: InputMaybe<Members_Member_Bool_Exp>;
  member_id?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** response of any mutation on the table "users.member_invitation" */
export type Users_Member_Invitation_Mutation_Response = {
  __typename: 'users_member_invitation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Users_Member_Invitation>>;
};

/** Ordering options when selecting data from "users.member_invitation". */
export type Users_Member_Invitation_Order_By = {
  code?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Members_Member_Order_By>;
  member_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "users.member_invitation" */
export enum Users_Member_Invitation_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "users_member_invitation" */
export type Users_Member_Invitation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Member_Invitation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Member_Invitation_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  member_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type Users_Send_Verification_Response = {
  __typename: 'users_send_verification_response';
  expiration_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

/** columns and relationships of "users.user" */
export type Users_User = {
  __typename: 'users_user';
  birth_date?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  club_admin?: Maybe<Clubs_Admin>;
  /** An array relationship */
  convocation_progress?: Maybe<Array<Users_Convocation_Progress>>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  is_superuser?: Maybe<Scalars['Boolean']['output']>;
  last_login?: Maybe<Scalars['timestamptz']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  media_official?: Maybe<Medias_Official_Reporter>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_verified?: Maybe<Scalars['Boolean']['output']>;
  picture_url?: Maybe<Scalars['String']['output']>;
  planner_settings?: Maybe<Scalars['jsonb']['output']>;
  receive_newsletter?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  team_official?: Maybe<Teams_Official>;
  username?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "users.user" */
export type Users_UserConvocation_ProgressArgs = {
  distinct_on?: InputMaybe<Array<Users_Convocation_Progress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Convocation_Progress_Order_By>>;
  where?: InputMaybe<Users_Convocation_Progress_Bool_Exp>;
};

/** columns and relationships of "users.user" */
export type Users_UserPlanner_SettingsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "users.user" */
export type Users_User_Aggregate_Order_By = {
  avg?: InputMaybe<Users_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_User_Max_Order_By>;
  min?: InputMaybe<Users_User_Min_Order_By>;
  stddev?: InputMaybe<Users_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_User_Sum_Order_By>;
  var_pop?: InputMaybe<Users_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_User_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_User_Append_Input = {
  planner_settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** order by avg() on columns of table "users.user" */
export type Users_User_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users.user". All fields are combined with a logical 'AND'. */
export type Users_User_Bool_Exp = {
  _and?: InputMaybe<Array<Users_User_Bool_Exp>>;
  _not?: InputMaybe<Users_User_Bool_Exp>;
  _or?: InputMaybe<Array<Users_User_Bool_Exp>>;
  birth_date?: InputMaybe<Date_Comparison_Exp>;
  club_admin?: InputMaybe<Clubs_Admin_Bool_Exp>;
  convocation_progress?: InputMaybe<Users_Convocation_Progress_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_superuser?: InputMaybe<Boolean_Comparison_Exp>;
  last_login?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  media_official?: InputMaybe<Medias_Official_Reporter_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  phone_verified?: InputMaybe<Boolean_Comparison_Exp>;
  picture_url?: InputMaybe<String_Comparison_Exp>;
  planner_settings?: InputMaybe<Jsonb_Comparison_Exp>;
  receive_newsletter?: InputMaybe<Boolean_Comparison_Exp>;
  team_official?: InputMaybe<Teams_Official_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_User_Delete_At_Path_Input = {
  planner_settings?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_User_Delete_Elem_Input = {
  planner_settings?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_User_Delete_Key_Input = {
  planner_settings?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "users.user" */
export type Users_User_Max_Order_By = {
  birth_date?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "users.user" */
export type Users_User_Min_Order_By = {
  birth_date?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users.user" */
export type Users_User_Mutation_Response = {
  __typename: 'users_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Users_User>>;
};

/** Ordering options when selecting data from "users.user". */
export type Users_User_Order_By = {
  birth_date?: InputMaybe<Order_By>;
  club_admin?: InputMaybe<Clubs_Admin_Order_By>;
  convocation_progress_aggregate?: InputMaybe<Users_Convocation_Progress_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_superuser?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  media_official?: InputMaybe<Medias_Official_Reporter_Order_By>;
  phone?: InputMaybe<Order_By>;
  phone_verified?: InputMaybe<Order_By>;
  picture_url?: InputMaybe<Order_By>;
  planner_settings?: InputMaybe<Order_By>;
  receive_newsletter?: InputMaybe<Order_By>;
  team_official?: InputMaybe<Teams_Official_Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users.user */
export type Users_User_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_User_Prepend_Input = {
  planner_settings?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users.user" */
export enum Users_User_Select_Column {
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IsSuperuser = 'is_superuser',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locale = 'locale',
  /** column name */
  Phone = 'phone',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  PictureUrl = 'picture_url',
  /** column name */
  PlannerSettings = 'planner_settings',
  /** column name */
  ReceiveNewsletter = 'receive_newsletter',
  /** column name */
  Username = 'username',
}

/** input type for updating data in table "users.user" */
export type Users_User_Set_Input = {
  birth_date?: InputMaybe<Scalars['date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  picture_url?: InputMaybe<Scalars['String']['input']>;
  planner_settings?: InputMaybe<Scalars['jsonb']['input']>;
  receive_newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "users.user" */
export type Users_User_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "users.user" */
export type Users_User_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "users.user" */
export type Users_User_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_user" */
export type Users_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_User_Stream_Cursor_Value_Input = {
  birth_date?: InputMaybe<Scalars['date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_superuser?: InputMaybe<Scalars['Boolean']['input']>;
  last_login?: InputMaybe<Scalars['timestamptz']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  picture_url?: InputMaybe<Scalars['String']['input']>;
  planner_settings?: InputMaybe<Scalars['jsonb']['input']>;
  receive_newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "users.user" */
export type Users_User_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Users_User_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_User_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_User_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_User_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_User_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_User_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_User_Bool_Exp;
};

/** order by var_pop() on columns of table "users.user" */
export type Users_User_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "users.user" */
export type Users_User_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "users.user" */
export type Users_User_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Users_Verify_User_Response = {
  __typename: 'users_verify_user_response';
  affected_rows?: Maybe<Scalars['smallint']['output']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "widgets.club_settings" */
export type Widgets_Club_Settings = {
  __typename: 'widgets_club_settings';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  background_color?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  /** An array relationship */
  club_admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  font_color?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  theme?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "widgets.club_settings" */
export type Widgets_Club_SettingsClub_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "widgets.club_settings". All fields are combined with a logical 'AND'. */
export type Widgets_Club_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Widgets_Club_Settings_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Widgets_Club_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Widgets_Club_Settings_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  background_color?: InputMaybe<String_Comparison_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  font_color?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  theme?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "widgets.club_settings" */
export enum Widgets_Club_Settings_Constraint {
  /** unique or primary key constraint on columns "club_id" */
  ClubSettingsClubId_76b38f27Uniq = 'club_settings_club_id_76b38f27_uniq',
  /** unique or primary key constraint on columns "id" */
  ClubSettingsPkey = 'club_settings_pkey',
}

/** input type for inserting data into table "widgets.club_settings" */
export type Widgets_Club_Settings_Insert_Input = {
  background_color?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  font_color?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "widgets.club_settings" */
export type Widgets_Club_Settings_Mutation_Response = {
  __typename: 'widgets_club_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Widgets_Club_Settings>>;
};

/** input type for inserting object relation for remote table "widgets.club_settings" */
export type Widgets_Club_Settings_Obj_Rel_Insert_Input = {
  data: Widgets_Club_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Widgets_Club_Settings_On_Conflict>;
};

/** on_conflict condition type for table "widgets.club_settings" */
export type Widgets_Club_Settings_On_Conflict = {
  constraint: Widgets_Club_Settings_Constraint;
  update_columns?: Array<Widgets_Club_Settings_Update_Column>;
  where?: InputMaybe<Widgets_Club_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "widgets.club_settings". */
export type Widgets_Club_Settings_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  background_color?: InputMaybe<Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  font_color?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
};

/** primary key columns input for table: widgets.club_settings */
export type Widgets_Club_Settings_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "widgets.club_settings" */
export enum Widgets_Club_Settings_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  BackgroundColor = 'background_color',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  Color = 'color',
  /** column name */
  FontColor = 'font_color',
  /** column name */
  Id = 'id',
  /** column name */
  Theme = 'theme',
}

/** input type for updating data in table "widgets.club_settings" */
export type Widgets_Club_Settings_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  background_color?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  font_color?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "widgets_club_settings" */
export type Widgets_Club_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Widgets_Club_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Widgets_Club_Settings_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  background_color?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  font_color?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "widgets.club_settings" */
export enum Widgets_Club_Settings_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  BackgroundColor = 'background_color',
  /** column name */
  Color = 'color',
  /** column name */
  FontColor = 'font_color',
  /** column name */
  Theme = 'theme',
}

export type Widgets_Club_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Widgets_Club_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Widgets_Club_Settings_Bool_Exp;
};

/** columns and relationships of "widgets.convocation_settings" */
export type Widgets_Convocation_Settings = {
  __typename: 'widgets_convocation_settings';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  exclude_bar_manager?: Maybe<Scalars['Boolean']['output']>;
  exclude_referee?: Maybe<Scalars['Boolean']['output']>;
  exclude_scoring_table?: Maybe<Scalars['Boolean']['output']>;
  exclude_stadium_manager?: Maybe<Scalars['Boolean']['output']>;
  exclude_task_ids?: Maybe<Scalars['jsonb']['output']>;
  widget_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "widgets.convocation_settings" */
export type Widgets_Convocation_SettingsExclude_Task_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Widgets_Convocation_Settings_Append_Input = {
  exclude_task_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "widgets.convocation_settings". All fields are combined with a logical 'AND'. */
export type Widgets_Convocation_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Widgets_Convocation_Settings_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Widgets_Convocation_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Widgets_Convocation_Settings_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  exclude_bar_manager?: InputMaybe<Boolean_Comparison_Exp>;
  exclude_referee?: InputMaybe<Boolean_Comparison_Exp>;
  exclude_scoring_table?: InputMaybe<Boolean_Comparison_Exp>;
  exclude_stadium_manager?: InputMaybe<Boolean_Comparison_Exp>;
  exclude_task_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  widget_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "widgets.convocation_settings" */
export enum Widgets_Convocation_Settings_Constraint {
  /** unique or primary key constraint on columns "widget_id" */
  ConvocationSettingsWidgetId_225937dcPk = 'convocation_settings_widget_id_225937dc_pk',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Widgets_Convocation_Settings_Delete_At_Path_Input = {
  exclude_task_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Widgets_Convocation_Settings_Delete_Elem_Input = {
  exclude_task_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Widgets_Convocation_Settings_Delete_Key_Input = {
  exclude_task_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "widgets.convocation_settings" */
export type Widgets_Convocation_Settings_Insert_Input = {
  exclude_bar_manager?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_referee?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_scoring_table?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_stadium_manager?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_task_ids?: InputMaybe<Scalars['jsonb']['input']>;
  widget_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "widgets.convocation_settings" */
export type Widgets_Convocation_Settings_Mutation_Response = {
  __typename: 'widgets_convocation_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Widgets_Convocation_Settings>>;
};

/** input type for inserting object relation for remote table "widgets.convocation_settings" */
export type Widgets_Convocation_Settings_Obj_Rel_Insert_Input = {
  data: Widgets_Convocation_Settings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Widgets_Convocation_Settings_On_Conflict>;
};

/** on_conflict condition type for table "widgets.convocation_settings" */
export type Widgets_Convocation_Settings_On_Conflict = {
  constraint: Widgets_Convocation_Settings_Constraint;
  update_columns?: Array<Widgets_Convocation_Settings_Update_Column>;
  where?: InputMaybe<Widgets_Convocation_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "widgets.convocation_settings". */
export type Widgets_Convocation_Settings_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  exclude_bar_manager?: InputMaybe<Order_By>;
  exclude_referee?: InputMaybe<Order_By>;
  exclude_scoring_table?: InputMaybe<Order_By>;
  exclude_stadium_manager?: InputMaybe<Order_By>;
  exclude_task_ids?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: widgets.convocation_settings */
export type Widgets_Convocation_Settings_Pk_Columns_Input = {
  widget_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Widgets_Convocation_Settings_Prepend_Input = {
  exclude_task_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "widgets.convocation_settings" */
export enum Widgets_Convocation_Settings_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  ExcludeBarManager = 'exclude_bar_manager',
  /** column name */
  ExcludeReferee = 'exclude_referee',
  /** column name */
  ExcludeScoringTable = 'exclude_scoring_table',
  /** column name */
  ExcludeStadiumManager = 'exclude_stadium_manager',
  /** column name */
  ExcludeTaskIds = 'exclude_task_ids',
  /** column name */
  WidgetId = 'widget_id',
}

/** input type for updating data in table "widgets.convocation_settings" */
export type Widgets_Convocation_Settings_Set_Input = {
  exclude_bar_manager?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_referee?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_scoring_table?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_stadium_manager?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_task_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "widgets_convocation_settings" */
export type Widgets_Convocation_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Widgets_Convocation_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Widgets_Convocation_Settings_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  exclude_bar_manager?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_referee?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_scoring_table?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_stadium_manager?: InputMaybe<Scalars['Boolean']['input']>;
  exclude_task_ids?: InputMaybe<Scalars['jsonb']['input']>;
  widget_id?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "widgets.convocation_settings" */
export enum Widgets_Convocation_Settings_Update_Column {
  /** column name */
  ExcludeBarManager = 'exclude_bar_manager',
  /** column name */
  ExcludeReferee = 'exclude_referee',
  /** column name */
  ExcludeScoringTable = 'exclude_scoring_table',
  /** column name */
  ExcludeStadiumManager = 'exclude_stadium_manager',
  /** column name */
  ExcludeTaskIds = 'exclude_task_ids',
}

export type Widgets_Convocation_Settings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Widgets_Convocation_Settings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Widgets_Convocation_Settings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Widgets_Convocation_Settings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Widgets_Convocation_Settings_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Widgets_Convocation_Settings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Widgets_Convocation_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Widgets_Convocation_Settings_Bool_Exp;
};

/** columns and relationships of "widgets.widget" */
export type Widgets_Widget = {
  __typename: 'widgets_widget';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  club?: Maybe<Clubs_Club>;
  /** An array relationship */
  club_admins?: Maybe<Array<Clubs_Club_Admin>>;
  club_id?: Maybe<Scalars['Int']['output']>;
  content_club_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  convocation_settings?: Maybe<Widgets_Convocation_Settings>;
  custom_css?: Maybe<Scalars['String']['output']>;
  day_switch_tab?: Maybe<Scalars['String']['output']>;
  events_away_home?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  live_settings?: Maybe<Widgets_Widget_Live>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  settings?: Maybe<Widgets_Club_Settings>;
  sort?: Maybe<Scalars['String']['output']>;
  /** Liste des objets team_detail, triés par rang */
  team_detail_list?: Maybe<Array<Teams_Team_Detail>>;
  /** An object relationship */
  team_settings?: Maybe<Widgets_Widget_Team>;
  title?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  widget_competitions?: Maybe<Array<Widgets_Widget_Has_Competition>>;
  /** An array relationship */
  widget_teams?: Maybe<Array<Widgets_Widget_Has_Team>>;
};

/** columns and relationships of "widgets.widget" */
export type Widgets_WidgetClub_AdminsArgs = {
  distinct_on?: InputMaybe<Array<Clubs_Club_Admin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Clubs_Club_Admin_Order_By>>;
  where?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
};

/** columns and relationships of "widgets.widget" */
export type Widgets_WidgetTeam_Detail_ListArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

/** columns and relationships of "widgets.widget" */
export type Widgets_WidgetWidget_CompetitionsArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Has_Competition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Has_Competition_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Has_Competition_Bool_Exp>;
};

/** columns and relationships of "widgets.widget" */
export type Widgets_WidgetWidget_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Widgets_Widget_Has_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Widgets_Widget_Has_Team_Order_By>>;
  where?: InputMaybe<Widgets_Widget_Has_Team_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "widgets.widget". All fields are combined with a logical 'AND'. */
export type Widgets_Widget_Bool_Exp = {
  _and?: InputMaybe<Array<Widgets_Widget_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Widgets_Widget_Bool_Exp>;
  _or?: InputMaybe<Array<Widgets_Widget_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  club?: InputMaybe<Clubs_Club_Bool_Exp>;
  club_admins?: InputMaybe<Clubs_Club_Admin_Bool_Exp>;
  club_id?: InputMaybe<Int_Comparison_Exp>;
  content_club_id?: InputMaybe<Int_Comparison_Exp>;
  convocation_settings?: InputMaybe<Widgets_Convocation_Settings_Bool_Exp>;
  custom_css?: InputMaybe<String_Comparison_Exp>;
  day_switch_tab?: InputMaybe<String_Comparison_Exp>;
  events_away_home?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  live_settings?: InputMaybe<Widgets_Widget_Live_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  settings?: InputMaybe<Widgets_Club_Settings_Bool_Exp>;
  sort?: InputMaybe<String_Comparison_Exp>;
  team_detail_list?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
  team_settings?: InputMaybe<Widgets_Widget_Team_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  widget_competitions?: InputMaybe<Widgets_Widget_Has_Competition_Bool_Exp>;
  widget_teams?: InputMaybe<Widgets_Widget_Has_Team_Bool_Exp>;
};

/** unique or primary key constraints on table "widgets.widget" */
export enum Widgets_Widget_Constraint {
  /** unique or primary key constraint on columns "id" */
  WidgetPkey = 'widget_pkey',
}

/** columns and relationships of "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition = {
  __typename: 'widgets_widget_has_competition';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  competition?: Maybe<Competitions_Competition>;
  competition_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  widget?: Maybe<Widgets_Widget>;
  widget_id?: Maybe<Scalars['Int']['output']>;
};

/** order by aggregate values of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Aggregate_Order_By = {
  avg?: InputMaybe<Widgets_Widget_Has_Competition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Widgets_Widget_Has_Competition_Max_Order_By>;
  min?: InputMaybe<Widgets_Widget_Has_Competition_Min_Order_By>;
  stddev?: InputMaybe<Widgets_Widget_Has_Competition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Widgets_Widget_Has_Competition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Widgets_Widget_Has_Competition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Widgets_Widget_Has_Competition_Sum_Order_By>;
  var_pop?: InputMaybe<Widgets_Widget_Has_Competition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Widgets_Widget_Has_Competition_Var_Samp_Order_By>;
  variance?: InputMaybe<Widgets_Widget_Has_Competition_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Arr_Rel_Insert_Input = {
  data: Array<Widgets_Widget_Has_Competition_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Widgets_Widget_Has_Competition_On_Conflict>;
};

/** order by avg() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Avg_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "widgets.widget_has_competition". All fields are combined with a logical 'AND'. */
export type Widgets_Widget_Has_Competition_Bool_Exp = {
  _and?: InputMaybe<Array<Widgets_Widget_Has_Competition_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Widgets_Widget_Has_Competition_Bool_Exp>;
  _or?: InputMaybe<Array<Widgets_Widget_Has_Competition_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  competition?: InputMaybe<Competitions_Competition_Bool_Exp>;
  competition_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  widget?: InputMaybe<Widgets_Widget_Bool_Exp>;
  widget_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "widgets.widget_has_competition" */
export enum Widgets_Widget_Has_Competition_Constraint {
  /** unique or primary key constraint on columns "id" */
  WidgetHasCompetitionPkey = 'widget_has_competition_pkey',
  /** unique or primary key constraint on columns "competition_id", "widget_id" */
  WidgetHasCompetitionWidgetIdCompetitionId_8051d1c6Uniq = 'widget_has_competition_widget_id_competition_id_8051d1c6_uniq',
}

/** input type for incrementing numeric columns in table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Inc_Input = {
  competition_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Insert_Input = {
  competition_id?: InputMaybe<Scalars['Int']['input']>;
  widget?: InputMaybe<Widgets_Widget_Obj_Rel_Insert_Input>;
  widget_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Mutation_Response = {
  __typename: 'widgets_widget_has_competition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Widgets_Widget_Has_Competition>>;
};

/** on_conflict condition type for table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_On_Conflict = {
  constraint: Widgets_Widget_Has_Competition_Constraint;
  update_columns?: Array<Widgets_Widget_Has_Competition_Update_Column>;
  where?: InputMaybe<Widgets_Widget_Has_Competition_Bool_Exp>;
};

/** Ordering options when selecting data from "widgets.widget_has_competition". */
export type Widgets_Widget_Has_Competition_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  competition?: InputMaybe<Competitions_Competition_Order_By>;
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget?: InputMaybe<Widgets_Widget_Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: widgets.widget_has_competition */
export type Widgets_Widget_Has_Competition_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "widgets.widget_has_competition" */
export enum Widgets_Widget_Has_Competition_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  CompetitionId = 'competition_id',
  /** column name */
  Id = 'id',
  /** column name */
  WidgetId = 'widget_id',
}

/** input type for updating data in table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  competition_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Stddev_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Stddev_Pop_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Stddev_Samp_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "widgets_widget_has_competition" */
export type Widgets_Widget_Has_Competition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Widgets_Widget_Has_Competition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Widgets_Widget_Has_Competition_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  competition_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  widget_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Sum_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** update columns of table "widgets.widget_has_competition" */
export enum Widgets_Widget_Has_Competition_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  CompetitionId = 'competition_id',
}

export type Widgets_Widget_Has_Competition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Widgets_Widget_Has_Competition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Widgets_Widget_Has_Competition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Widgets_Widget_Has_Competition_Bool_Exp;
};

/** order by var_pop() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Var_Pop_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Var_Samp_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "widgets.widget_has_competition" */
export type Widgets_Widget_Has_Competition_Variance_Order_By = {
  competition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team = {
  __typename: 'widgets_widget_has_team';
  _created?: Maybe<Scalars['timestamptz']['output']>;
  _updated?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  team?: Maybe<Teams_Team>;
  team_detail?: Maybe<Array<Teams_Team_Detail>>;
  team_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  widget?: Maybe<Widgets_Widget>;
  widget_id?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "widgets.widget_has_team" */
export type Widgets_Widget_Has_TeamTeam_DetailArgs = {
  distinct_on?: InputMaybe<Array<Teams_Team_Detail_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Teams_Team_Detail_Order_By>>;
  where?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
};

/** order by aggregate values of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Aggregate_Order_By = {
  avg?: InputMaybe<Widgets_Widget_Has_Team_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Widgets_Widget_Has_Team_Max_Order_By>;
  min?: InputMaybe<Widgets_Widget_Has_Team_Min_Order_By>;
  stddev?: InputMaybe<Widgets_Widget_Has_Team_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Widgets_Widget_Has_Team_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Widgets_Widget_Has_Team_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Widgets_Widget_Has_Team_Sum_Order_By>;
  var_pop?: InputMaybe<Widgets_Widget_Has_Team_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Widgets_Widget_Has_Team_Var_Samp_Order_By>;
  variance?: InputMaybe<Widgets_Widget_Has_Team_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Arr_Rel_Insert_Input = {
  data: Array<Widgets_Widget_Has_Team_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Widgets_Widget_Has_Team_On_Conflict>;
};

/** order by avg() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "widgets.widget_has_team". All fields are combined with a logical 'AND'. */
export type Widgets_Widget_Has_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Widgets_Widget_Has_Team_Bool_Exp>>;
  _created?: InputMaybe<Timestamptz_Comparison_Exp>;
  _not?: InputMaybe<Widgets_Widget_Has_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Widgets_Widget_Has_Team_Bool_Exp>>;
  _updated?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  team?: InputMaybe<Teams_Team_Bool_Exp>;
  team_detail?: InputMaybe<Teams_Team_Detail_Bool_Exp>;
  team_id?: InputMaybe<Int_Comparison_Exp>;
  widget?: InputMaybe<Widgets_Widget_Bool_Exp>;
  widget_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "widgets.widget_has_team" */
export enum Widgets_Widget_Has_Team_Constraint {
  /** unique or primary key constraint on columns "id" */
  WidgetHasTeamPkey = 'widget_has_team_pkey',
  /** unique or primary key constraint on columns "widget_id", "team_id" */
  WidgetHasTeamWidgetIdTeamIdD2f59061Uniq = 'widget_has_team_widget_id_team_id_d2f59061_uniq',
}

/** input type for incrementing numeric columns in table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Inc_Input = {
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Insert_Input = {
  team_id?: InputMaybe<Scalars['Int']['input']>;
  widget?: InputMaybe<Widgets_Widget_Obj_Rel_Insert_Input>;
  widget_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by max() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Max_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Min_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Mutation_Response = {
  __typename: 'widgets_widget_has_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Widgets_Widget_Has_Team>>;
};

/** on_conflict condition type for table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_On_Conflict = {
  constraint: Widgets_Widget_Has_Team_Constraint;
  update_columns?: Array<Widgets_Widget_Has_Team_Update_Column>;
  where?: InputMaybe<Widgets_Widget_Has_Team_Bool_Exp>;
};

/** Ordering options when selecting data from "widgets.widget_has_team". */
export type Widgets_Widget_Has_Team_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  team?: InputMaybe<Teams_Team_Order_By>;
  team_detail_aggregate?: InputMaybe<Teams_Team_Detail_Aggregate_Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget?: InputMaybe<Widgets_Widget_Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: widgets.widget_has_team */
export type Widgets_Widget_Has_Team_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "widgets.widget_has_team" */
export enum Widgets_Widget_Has_Team_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Id = 'id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  WidgetId = 'widget_id',
}

/** input type for updating data in table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Set_Input = {
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by stddev() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "widgets_widget_has_team" */
export type Widgets_Widget_Has_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Widgets_Widget_Has_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Widgets_Widget_Has_Team_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  team_id?: InputMaybe<Scalars['Int']['input']>;
  widget_id?: InputMaybe<Scalars['Int']['input']>;
};

/** order by sum() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** update columns of table "widgets.widget_has_team" */
export enum Widgets_Widget_Has_Team_Update_Column {
  /** column name */
  Updated = '_updated',
  /** column name */
  TeamId = 'team_id',
}

export type Widgets_Widget_Has_Team_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Widgets_Widget_Has_Team_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Widgets_Widget_Has_Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Widgets_Widget_Has_Team_Bool_Exp;
};

/** order by var_pop() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "widgets.widget_has_team" */
export type Widgets_Widget_Has_Team_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  widget_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "widgets.widget" */
export type Widgets_Widget_Inc_Input = {
  club_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "widgets.widget" */
export type Widgets_Widget_Insert_Input = {
  category?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  content_club_id?: InputMaybe<Scalars['Int']['input']>;
  convocation_settings?: InputMaybe<Widgets_Convocation_Settings_Obj_Rel_Insert_Input>;
  custom_css?: InputMaybe<Scalars['String']['input']>;
  day_switch_tab?: InputMaybe<Scalars['String']['input']>;
  events_away_home?: InputMaybe<Scalars['String']['input']>;
  live_settings?: InputMaybe<Widgets_Widget_Live_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Widgets_Club_Settings_Obj_Rel_Insert_Input>;
  sort?: InputMaybe<Scalars['String']['input']>;
  team_settings?: InputMaybe<Widgets_Widget_Team_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']['input']>;
  widget_competitions?: InputMaybe<Widgets_Widget_Has_Competition_Arr_Rel_Insert_Input>;
  widget_teams?: InputMaybe<Widgets_Widget_Has_Team_Arr_Rel_Insert_Input>;
};

/** columns and relationships of "widgets.widget_live" */
export type Widgets_Widget_Live = {
  __typename: 'widgets_widget_live';
  live_id?: Maybe<Scalars['Int']['output']>;
  nb_days_update?: Maybe<Scalars['smallint']['output']>;
  /** An object relationship */
  widget?: Maybe<Widgets_Widget>;
  widget_ptr_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "widgets.widget_live". All fields are combined with a logical 'AND'. */
export type Widgets_Widget_Live_Bool_Exp = {
  _and?: InputMaybe<Array<Widgets_Widget_Live_Bool_Exp>>;
  _not?: InputMaybe<Widgets_Widget_Live_Bool_Exp>;
  _or?: InputMaybe<Array<Widgets_Widget_Live_Bool_Exp>>;
  live_id?: InputMaybe<Int_Comparison_Exp>;
  nb_days_update?: InputMaybe<Smallint_Comparison_Exp>;
  widget?: InputMaybe<Widgets_Widget_Bool_Exp>;
  widget_ptr_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "widgets.widget_live" */
export enum Widgets_Widget_Live_Constraint {
  /** unique or primary key constraint on columns "widget_ptr_id" */
  WidgetLivePkey = 'widget_live_pkey',
}

/** input type for incrementing numeric columns in table "widgets.widget_live" */
export type Widgets_Widget_Live_Inc_Input = {
  live_id?: InputMaybe<Scalars['Int']['input']>;
  nb_days_update?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "widgets.widget_live" */
export type Widgets_Widget_Live_Insert_Input = {
  live_id?: InputMaybe<Scalars['Int']['input']>;
  nb_days_update?: InputMaybe<Scalars['smallint']['input']>;
  widget?: InputMaybe<Widgets_Widget_Obj_Rel_Insert_Input>;
  widget_ptr_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "widgets.widget_live" */
export type Widgets_Widget_Live_Mutation_Response = {
  __typename: 'widgets_widget_live_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Widgets_Widget_Live>>;
};

/** input type for inserting object relation for remote table "widgets.widget_live" */
export type Widgets_Widget_Live_Obj_Rel_Insert_Input = {
  data: Widgets_Widget_Live_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Widgets_Widget_Live_On_Conflict>;
};

/** on_conflict condition type for table "widgets.widget_live" */
export type Widgets_Widget_Live_On_Conflict = {
  constraint: Widgets_Widget_Live_Constraint;
  update_columns?: Array<Widgets_Widget_Live_Update_Column>;
  where?: InputMaybe<Widgets_Widget_Live_Bool_Exp>;
};

/** Ordering options when selecting data from "widgets.widget_live". */
export type Widgets_Widget_Live_Order_By = {
  live_id?: InputMaybe<Order_By>;
  nb_days_update?: InputMaybe<Order_By>;
  widget?: InputMaybe<Widgets_Widget_Order_By>;
  widget_ptr_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: widgets.widget_live */
export type Widgets_Widget_Live_Pk_Columns_Input = {
  widget_ptr_id: Scalars['Int']['input'];
};

/** select columns of table "widgets.widget_live" */
export enum Widgets_Widget_Live_Select_Column {
  /** column name */
  LiveId = 'live_id',
  /** column name */
  NbDaysUpdate = 'nb_days_update',
  /** column name */
  WidgetPtrId = 'widget_ptr_id',
}

/** input type for updating data in table "widgets.widget_live" */
export type Widgets_Widget_Live_Set_Input = {
  live_id?: InputMaybe<Scalars['Int']['input']>;
  nb_days_update?: InputMaybe<Scalars['smallint']['input']>;
};

/** Streaming cursor of the table "widgets_widget_live" */
export type Widgets_Widget_Live_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Widgets_Widget_Live_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Widgets_Widget_Live_Stream_Cursor_Value_Input = {
  live_id?: InputMaybe<Scalars['Int']['input']>;
  nb_days_update?: InputMaybe<Scalars['smallint']['input']>;
  widget_ptr_id?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "widgets.widget_live" */
export enum Widgets_Widget_Live_Update_Column {
  /** column name */
  LiveId = 'live_id',
  /** column name */
  NbDaysUpdate = 'nb_days_update',
}

export type Widgets_Widget_Live_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Widgets_Widget_Live_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Widgets_Widget_Live_Set_Input>;
  /** filter the rows which have to be updated */
  where: Widgets_Widget_Live_Bool_Exp;
};

/** response of any mutation on the table "widgets.widget" */
export type Widgets_Widget_Mutation_Response = {
  __typename: 'widgets_widget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Widgets_Widget>>;
};

/** input type for inserting object relation for remote table "widgets.widget" */
export type Widgets_Widget_Obj_Rel_Insert_Input = {
  data: Widgets_Widget_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Widgets_Widget_On_Conflict>;
};

/** on_conflict condition type for table "widgets.widget" */
export type Widgets_Widget_On_Conflict = {
  constraint: Widgets_Widget_Constraint;
  update_columns?: Array<Widgets_Widget_Update_Column>;
  where?: InputMaybe<Widgets_Widget_Bool_Exp>;
};

/** Ordering options when selecting data from "widgets.widget". */
export type Widgets_Widget_Order_By = {
  _created?: InputMaybe<Order_By>;
  _updated?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  club?: InputMaybe<Clubs_Club_Order_By>;
  club_admins_aggregate?: InputMaybe<Clubs_Club_Admin_Aggregate_Order_By>;
  club_id?: InputMaybe<Order_By>;
  content_club_id?: InputMaybe<Order_By>;
  convocation_settings?: InputMaybe<Widgets_Convocation_Settings_Order_By>;
  custom_css?: InputMaybe<Order_By>;
  day_switch_tab?: InputMaybe<Order_By>;
  events_away_home?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_settings?: InputMaybe<Widgets_Widget_Live_Order_By>;
  name?: InputMaybe<Order_By>;
  settings?: InputMaybe<Widgets_Club_Settings_Order_By>;
  sort?: InputMaybe<Order_By>;
  team_detail_list_aggregate?: InputMaybe<Teams_Team_Detail_Aggregate_Order_By>;
  team_settings?: InputMaybe<Widgets_Widget_Team_Order_By>;
  title?: InputMaybe<Order_By>;
  widget_competitions_aggregate?: InputMaybe<Widgets_Widget_Has_Competition_Aggregate_Order_By>;
  widget_teams_aggregate?: InputMaybe<Widgets_Widget_Has_Team_Aggregate_Order_By>;
};

/** primary key columns input for table: widgets.widget */
export type Widgets_Widget_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "widgets.widget" */
export enum Widgets_Widget_Select_Column {
  /** column name */
  Created = '_created',
  /** column name */
  Updated = '_updated',
  /** column name */
  Category = 'category',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  ContentClubId = 'content_club_id',
  /** column name */
  CustomCss = 'custom_css',
  /** column name */
  DaySwitchTab = 'day_switch_tab',
  /** column name */
  EventsAwayHome = 'events_away_home',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sort = 'sort',
  /** column name */
  Title = 'title',
}

/** input type for updating data in table "widgets.widget" */
export type Widgets_Widget_Set_Input = {
  category?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  custom_css?: InputMaybe<Scalars['String']['input']>;
  day_switch_tab?: InputMaybe<Scalars['String']['input']>;
  events_away_home?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "widgets_widget" */
export type Widgets_Widget_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Widgets_Widget_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Widgets_Widget_Stream_Cursor_Value_Input = {
  _created?: InputMaybe<Scalars['timestamptz']['input']>;
  _updated?: InputMaybe<Scalars['timestamptz']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  club_id?: InputMaybe<Scalars['Int']['input']>;
  content_club_id?: InputMaybe<Scalars['Int']['input']>;
  custom_css?: InputMaybe<Scalars['String']['input']>;
  day_switch_tab?: InputMaybe<Scalars['String']['input']>;
  events_away_home?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "widgets.widget_team" */
export type Widgets_Widget_Team = {
  __typename: 'widgets_widget_team';
  display_priority?: Maybe<Scalars['String']['output']>;
  nb_events_to_show?: Maybe<Scalars['smallint']['output']>;
  show_events?: Maybe<Scalars['Boolean']['output']>;
  show_name_in_club?: Maybe<Scalars['Boolean']['output']>;
  show_ranking?: Maybe<Scalars['Boolean']['output']>;
  /** An object relationship */
  widget?: Maybe<Widgets_Widget>;
  widget_ptr_id?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "widgets.widget_team". All fields are combined with a logical 'AND'. */
export type Widgets_Widget_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Widgets_Widget_Team_Bool_Exp>>;
  _not?: InputMaybe<Widgets_Widget_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Widgets_Widget_Team_Bool_Exp>>;
  display_priority?: InputMaybe<String_Comparison_Exp>;
  nb_events_to_show?: InputMaybe<Smallint_Comparison_Exp>;
  show_events?: InputMaybe<Boolean_Comparison_Exp>;
  show_name_in_club?: InputMaybe<Boolean_Comparison_Exp>;
  show_ranking?: InputMaybe<Boolean_Comparison_Exp>;
  widget?: InputMaybe<Widgets_Widget_Bool_Exp>;
  widget_ptr_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "widgets.widget_team" */
export enum Widgets_Widget_Team_Constraint {
  /** unique or primary key constraint on columns "widget_ptr_id" */
  WidgetTeamPkey = 'widget_team_pkey',
}

/** input type for incrementing numeric columns in table "widgets.widget_team" */
export type Widgets_Widget_Team_Inc_Input = {
  nb_events_to_show?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "widgets.widget_team" */
export type Widgets_Widget_Team_Insert_Input = {
  display_priority?: InputMaybe<Scalars['String']['input']>;
  nb_events_to_show?: InputMaybe<Scalars['smallint']['input']>;
  show_events?: InputMaybe<Scalars['Boolean']['input']>;
  show_name_in_club?: InputMaybe<Scalars['Boolean']['input']>;
  show_ranking?: InputMaybe<Scalars['Boolean']['input']>;
  widget?: InputMaybe<Widgets_Widget_Obj_Rel_Insert_Input>;
  widget_ptr_id?: InputMaybe<Scalars['Int']['input']>;
};

/** response of any mutation on the table "widgets.widget_team" */
export type Widgets_Widget_Team_Mutation_Response = {
  __typename: 'widgets_widget_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows?: Maybe<Scalars['Int']['output']>;
  /** data from the rows affected by the mutation */
  returning?: Maybe<Array<Widgets_Widget_Team>>;
};

/** input type for inserting object relation for remote table "widgets.widget_team" */
export type Widgets_Widget_Team_Obj_Rel_Insert_Input = {
  data: Widgets_Widget_Team_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Widgets_Widget_Team_On_Conflict>;
};

/** on_conflict condition type for table "widgets.widget_team" */
export type Widgets_Widget_Team_On_Conflict = {
  constraint: Widgets_Widget_Team_Constraint;
  update_columns?: Array<Widgets_Widget_Team_Update_Column>;
  where?: InputMaybe<Widgets_Widget_Team_Bool_Exp>;
};

/** Ordering options when selecting data from "widgets.widget_team". */
export type Widgets_Widget_Team_Order_By = {
  display_priority?: InputMaybe<Order_By>;
  nb_events_to_show?: InputMaybe<Order_By>;
  show_events?: InputMaybe<Order_By>;
  show_name_in_club?: InputMaybe<Order_By>;
  show_ranking?: InputMaybe<Order_By>;
  widget?: InputMaybe<Widgets_Widget_Order_By>;
  widget_ptr_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: widgets.widget_team */
export type Widgets_Widget_Team_Pk_Columns_Input = {
  widget_ptr_id: Scalars['Int']['input'];
};

/** select columns of table "widgets.widget_team" */
export enum Widgets_Widget_Team_Select_Column {
  /** column name */
  DisplayPriority = 'display_priority',
  /** column name */
  NbEventsToShow = 'nb_events_to_show',
  /** column name */
  ShowEvents = 'show_events',
  /** column name */
  ShowNameInClub = 'show_name_in_club',
  /** column name */
  ShowRanking = 'show_ranking',
  /** column name */
  WidgetPtrId = 'widget_ptr_id',
}

/** input type for updating data in table "widgets.widget_team" */
export type Widgets_Widget_Team_Set_Input = {
  display_priority?: InputMaybe<Scalars['String']['input']>;
  nb_events_to_show?: InputMaybe<Scalars['smallint']['input']>;
  show_events?: InputMaybe<Scalars['Boolean']['input']>;
  show_name_in_club?: InputMaybe<Scalars['Boolean']['input']>;
  show_ranking?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "widgets_widget_team" */
export type Widgets_Widget_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Widgets_Widget_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Widgets_Widget_Team_Stream_Cursor_Value_Input = {
  display_priority?: InputMaybe<Scalars['String']['input']>;
  nb_events_to_show?: InputMaybe<Scalars['smallint']['input']>;
  show_events?: InputMaybe<Scalars['Boolean']['input']>;
  show_name_in_club?: InputMaybe<Scalars['Boolean']['input']>;
  show_ranking?: InputMaybe<Scalars['Boolean']['input']>;
  widget_ptr_id?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "widgets.widget_team" */
export enum Widgets_Widget_Team_Update_Column {
  /** column name */
  DisplayPriority = 'display_priority',
  /** column name */
  NbEventsToShow = 'nb_events_to_show',
  /** column name */
  ShowEvents = 'show_events',
  /** column name */
  ShowNameInClub = 'show_name_in_club',
  /** column name */
  ShowRanking = 'show_ranking',
}

export type Widgets_Widget_Team_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Widgets_Widget_Team_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Widgets_Widget_Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Widgets_Widget_Team_Bool_Exp;
};

/** update columns of table "widgets.widget" */
export enum Widgets_Widget_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  ClubId = 'club_id',
  /** column name */
  CustomCss = 'custom_css',
  /** column name */
  DaySwitchTab = 'day_switch_tab',
  /** column name */
  EventsAwayHome = 'events_away_home',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Sort = 'sort',
  /** column name */
  Title = 'title',
}

export type Widgets_Widget_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Widgets_Widget_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Widgets_Widget_Set_Input>;
  /** filter the rows which have to be updated */
  where: Widgets_Widget_Bool_Exp;
};
