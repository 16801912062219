export const Others = {
  Blog: 'others/blog' as const,
  Contact: 'others/contact' as const,
  Faq: 'others/faq' as const,
  Help: 'others/help' as const,
  HelpUs: 'others/help-us' as const,
  Opinion: 'others/opinion' as const,
  Partners: 'others/partners' as const,
  Press: 'others/press' as const,
  Pricing: 'others/pricing' as const,
  Rocket: 'others/rocket' as const,
  Scorenco: 'others/scorenco' as const,
};
