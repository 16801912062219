import { isChVersion } from '../../../utils';

import { AuthMenu as AuthMenuNew } from './AuthMenu';
import { AuthMenuCh } from './AuthMenuCh';

export const AuthMenu = () => {
  if (isChVersion()) {
    return <AuthMenuCh />;
  }

  /**
   * Ce menu redirige vers l'ancien site de Score'n'co pour s'authentifier.
   * A supprimer lorsque l'on migrera l'authentification sur la nouvelle
   * version.
   */
  return <AuthMenuNew />;
};
