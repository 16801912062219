import { useNavigationContext, usePageContext } from '@scorenco/next';

import { WebLayoutPageProps } from '..';

export const Configuration = () => {
  const { showMessage } = useNavigationContext();
  const pageContext = usePageContext<WebLayoutPageProps>();

  const configuration = pageContext.pageProps.configuration;

  if (!configuration?.mainMessage) {
    return null;
  }
  showMessage(configuration.mainMessage);

  return null;
};
