import { StrapiMenuGetQuery } from './StrapiMenu.query.generated';

const sortMenus = (
  { attributes: { order: orderA = 0 } = {} },
  { attributes: { order: orderB = 0 } = {} }
) => orderA - orderB;

export const normalize = (data?: StrapiMenuGetQuery) => {
  const menu = data?.menusMenus?.data?.[0];
  if (!menu) {
    return undefined;
  }

  return {
    id: menu.id,
    title: menu.attributes?.title,
    slug: menu.attributes?.slug,
    items: (menu.attributes?.items?.data || [])
      .filter((item) => !item.attributes?.parent?.data)
      .sort(sortMenus)
      .map((mainItem) => {
        const subItems = (menu.attributes?.items?.data || []).filter(
          (item) => item.attributes?.parent?.data?.id === mainItem.id
        );

        if (!subItems.length) {
          return { ...mainItem, items: undefined };
        }

        return {
          ...mainItem,
          items: subItems.sort(sortMenus),
        };
      }),
  };
};

export type StrapiMenu = ReturnType<typeof normalize>;
