import { PropsWithChildren, useMemo } from 'react';

import { css, Global } from '@emotion/react';
import { Drawer, Box } from '@mui/material';

import { useNavigationContext } from './NavigationContext';

type NavigationBurgerDrawerProps = PropsWithChildren<{ noPermanent?: boolean }>;

export const NavigationBurgerDrawer = ({
  children,
  noPermanent,
  ...rest
}: NavigationBurgerDrawerProps) => {
  const { drawerOpen, setDrawerOpen } = useNavigationContext();

  // Container est undefined lors du rendu côté serveur
  const container = useMemo(
    () =>
      typeof window !== 'undefined' ? () => window.document.body : undefined,
    []
  );

  return (
    <Box
      component="nav"
      css={(theme) => css`
        ${theme.breakpoints.up('md')} {
          width: var(--burger-menu-width);
          flex-shrink: 0;
        }
      `}
      {...rest}
    >
      <Global
        styles={(theme) => css`
          :root {
            --burger-menu-width: 17rem;

            ${theme.breakpoints.up('md')} {
              --burger-menu-width: 15rem;
            }
          }
        `}
      />

      <Drawer
        container={container}
        variant="temporary"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        css={(theme) => css`
          display: block;

          .MuiDrawer-paper {
            box-sizing: border-box;
            width: var(--burger-menu-width);
            overflow: overlay;
          }

          ${theme.breakpoints.up('md')} {
            display: none;
          }
        `}
      >
        {children}
      </Drawer>

      {!noPermanent && (
        <Drawer
          variant="permanent"
          css={(theme) => css`
            display: none;

            .MuiDrawer-paper {
              padding-top: var(--app-bar-height);
              box-sizing: border-box;
              width: var(--burger-menu-width);
              overflow: overlay;
              z-index: 2;
            }

            ${theme.breakpoints.up('md')} {
              display: block;
            }
          `}
          open
        >
          {children}
        </Drawer>
      )}
    </Box>
  );
};
