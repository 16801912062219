import { css } from '@emotion/react';
import {
  Button,
  Stack,
  Paper,
  Box,
  Typography,
  Menu,
  ButtonBase,
} from '@mui/material';
import { IconLoader } from '@scorenco/components';
import { RouteLink, Types } from '@scorenco/core';
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks';

type DesktopHeaderMenuItemProps = {
  item: Types.MenusMenuItemEntity & {
    items?: Types.MenusMenuItemEntity[];
  };
};

const MenuItem = ({
  title,
  subtitle,
  icon,
  url,
  target,
}: {
  title: string;
  subtitle?: string;
  url: string;
  target?: string;
  icon?: string;
}) => {
  return (
    <RouteLink passHref href={url}>
      <ButtonBase>
        <Box
          component="a"
          target={target ? '_' + target : undefined}
          p={2}
          css={(theme) => css`
            display: flex;
            gap: 1rem;
            border-radius: 0.5rem;
            color: ${theme.vars.palette.text.primary};
            text-decoration: none;
            background-color: white;
            width: 100%;

            &:hover {
              background-color: ${theme.vars.palette.background.default};
            }
          `}
          onContextMenu={(e) => e.stopPropagation()}
        >
          {icon && (
            <IconLoader
              css={css`
                width: 1.5rem;
                height: 1.5rem;
              `}
              icon={icon as any}
            />
          )}
          <Stack flex={1} justifyContent="center">
            <Typography>{title}</Typography>
            {subtitle && (
              <Typography variant="caption" sx={{ opacity: 0.7 }}>
                {subtitle}
              </Typography>
            )}
          </Stack>
        </Box>
      </ButtonBase>
    </RouteLink>
  );
};

export const DesktopHeaderMenuItem = ({ item }: DesktopHeaderMenuItemProps) => {
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'menu',
  });

  return (
    <Box>
      <RouteLink href={item.attributes?.url}>
        <Button
          sx={{
            fontWeight: 'normal',
            textTransform: 'none',
          }}
          {...bindHover(popupState)}
        >
          {item.attributes?.title}
        </Button>
      </RouteLink>

      {!!item.items?.length && (
        <Menu
          {...bindMenu(popupState)}
          sx={{ pointerEvents: 'none' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          PaperProps={{
            sx: {
              paddingTop: 1,
              pointerEvents: 'auto',
              background: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <Paper>
            <Stack
              css={css`
                min-width: 15rem;
                max-width: 20rem;
              `}
              p={1}
            >
              {item.items.map((subItem) => {
                return (
                  subItem.attributes?.title && (
                    <MenuItem
                      key={subItem.id}
                      title={subItem.attributes?.title}
                      subtitle={subItem.attributes?.main_text}
                      url={subItem.attributes?.url || '#'}
                      target={subItem.attributes?.target}
                      icon={subItem.attributes?.main_icon}
                    />
                  )
                );
              })}
            </Stack>
          </Paper>
        </Menu>
      )}
    </Box>
  );
};
