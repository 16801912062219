export const Sports = {
  Basket: 'sports/basket' as const,
  Football: 'sports/football' as const,
  Hand: 'sports/hand' as const,
  Hockey: 'sports/hockey-sur-glace' as const,
  Rugby: 'sports/rugby' as const,
  RollerHockey: 'sports/roller-hockey' as const,
  RinkHockey: 'sports/rink-hockey' as const,
  Volley: 'sports/volley' as const,
};
