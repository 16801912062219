import { Stack } from '@mui/system';
import { usePageContext } from '@scorenco/next';

import { WebLayoutPageProps } from '..';

import { DesktopHeaderMenuItem } from './DesktopHeaderMenuItem';

export const DesktopHeaderMenu = ({ ...rest }) => {
  const pageContext = usePageContext<WebLayoutPageProps>();

  const menu = pageContext.pageProps.menu;

  if (!menu?.items?.length) {
    return null;
  }

  return (
    <Stack direction="row" gap={0} {...rest}>
      {menu.items.map((item) => (
        <DesktopHeaderMenuItem key={item.id} item={item} />
      ))}
    </Stack>
  );
};
